/* eslint-disable no-debugger */
import cx from 'classnames';
import PubSub from 'pubsub-js';
import { memo, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPresetCameraAngleAPI } from '../../../../../api/camera/presetCamera';
import { getProjectCameraAngleAPI } from '../../../../../api/camera/projectCamera';
import { useToggleListItem } from '../../../../../hooks/useToggleListItem';
import {
  projectCameraAngleSelector,
  resetImportPressets,
  selectExpandedCameraId,
  selectImportPresestsLoading,
  setExpandedCameraId,
} from '../../../../../redux/slicers/camera/projectCameraAngleSlicer';
import Button from '../../../../Button/Button';
import { PreviewIcon } from '../../../../icons/PreviewIcon';
import { RenderIcon } from '../../../../icons/RenderIcon';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import { CuratorSidebarLayout } from '../../CuratorSidebarLayout/CuratorSidebarLayout';
import { PopupButtons } from '../lights/PopupButtons';
import { CameraItem } from './CameraItem';
import { CameraNameForm } from './CameraNameForm';
import { CameraPresetsPopup } from './CameraPresetsPopup';
import { PreviewAndRenderSidebarPopup } from './PreviewAndRenderSidebarPopup';
import { PreviewRenderForm } from './PreviewRenderForm';
import { useCameraActions } from './useCameraActions';
import { useSaveRenderPreview } from './useSaveRenderPreview';
import styles from './Camera.module.scss';
import { useUnityContext } from '../../../../container/unityContainer';
import { AddCameraForm } from './AddCameraForm';
import { AddToPresetsForm } from './AddToPresetsForm';
import { useCuratorData } from '../../../../../hooks/useCuratorData';
import { UnityEvent } from '../../../../../helpers/unityPubSub';
import { CURATOR_TYPE, PROJECT_TYPE } from '../../../../../constants/projectConstants';
import {
  DEFAULT_CAMERA_BLUR_INTENSITY,
  RP_TYPE,
} from '../../../../../config/constant/unityConstants';
import { selectIs2DMode } from '../../../../../redux/slicers/admin/curatorSlicer';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader/Loader';
import { ImportCameraPresetsConflicts } from './ImportCameraPresetsConflicts';
import { errorToast } from '../../../../../helpers/toastHelper';

export const Camera = memo(({ active }) => {
  const dispatch = useDispatch();
  const newCameraDataRef = useRef(null);
  const { type, roomId, isNewProject } = useCuratorData();
  const { handleSelectedCamera, handleCameraPopupClose } = useUnityContext();
  const { ids: selectedIds, toggleItem, resetSelectedItems } = useToggleListItem({ reset: active });
  const [addCameraFormVisible, setAddCameraFormVisible] = useState(false);
  const [presetsPopupOpen, setPresetsPopupOpen] = useState(false);
  const [addToPresetsConflict, setAddToPresetsConflict] = useState(null);
  const expandedId = useSelector(selectExpandedCameraId);
  const importPresetsLoading = useSelector(selectImportPresestsLoading);

  const [savePopupType, setSavePopupType] = useState(null);
  const { savePreview, saveRender } = useSaveRenderPreview({ selectedCameraList: [] });
  const { getProjectCameraAnglesList, saveToCamera } = useSelector(projectCameraAngleSelector);
  const { createCamera, deleteCamera, saveToPresets, updateCamera } = useCameraActions();
  const is2DMode = useSelector(selectIs2DMode);

  const setExpandedId = (id) => {
    const camera = cameraList.find((c) => c.id === id);

    if (id && camera.isdepthOfField && camera.selectedObjectName) {
      PubSub.subscribeOnce(UnityEvent.FocusObjectNameReceiver, (_, objectName) => {
        // debugger;
        // when we expanditem we want to check if there object selected for camera focus still exists or not
        if (!objectName) {
          updateCamera({
            id,
            selectedObjectName: '',
            isdepthOfField: false,
            blurIntensity: DEFAULT_CAMERA_BLUR_INTENSITY,
          });
        }
      });
    }

    dispatch(setExpandedCameraId(id));
  };

  useEffect(() => {
    // hide render preview form when we in 2d mode
    if (is2DMode) {
      setSavePopupType(null);
    }
  }, [is2DMode]);

  const cameraList = getProjectCameraAnglesList;
  useEffect(() => {
    if (!roomId) return;

    if (isNewProject) {
      dispatch(getProjectCameraAngleAPI(type, roomId));
    }

    dispatch(getPresetCameraAngleAPI({ type, roomId }));
  }, [roomId]);

  useEffect(() => {
    if (!active) {
      setExpandedId(null);
      handleCameraPopupClose();
      dispatch(resetImportPressets());
    }
  }, [active]);

  useEffect(() => {
    if (selectedIds.length === 0 && savePopupType) {
      setSavePopupType(null);
    }
  }, [selectedIds]);

  const handleSaveRenderPreview = (values) => {
    const selectedCameraList = cameraList.filter((c) => selectedIds.includes(c.id));
    const data = {
      data: values,
      cameraList: selectedCameraList,
      onSuccess: () => {
        resetSelectedItems();
        setSavePopupType(null);
      },
    };

    savePopupType === RP_TYPE.PREVIEW ? savePreview(data) : saveRender(data);
  };

  const closeSavePopup = () => {
    setSavePopupType(null);
  };

  const handleAddCamera = ({ name }) => {
    createCamera({
      name,
      onSuccess: () => {
        setAddCameraFormVisible(false);
      },
    });
  };

  const handleCameraDelete = (camera) => () => {
    deleteCamera(camera, () => {
      resetSelectedItems(camera.id);
    });
  };

  const handleSaveToPresets = (camera) => () => {
    saveToPresets({
      camera,
      onSuccess: () => {
        setAddToPresetsConflict(null);
      },
      onConflict: () => {
        setAddToPresetsConflict(camera.id);
      },
    });
  };

  const handleAddToPresetsRenameSubmit = (camera) => (values) => {
    const updatedCamera = { ...camera, cameraName: values.name };
    handleSaveToPresets(updatedCamera)();
  };

  const handleCameraUpdate = (camera) => (newData) => {
    updateCamera({
      camera,
      newData,
      onSuccess: () => {
        setExpandedId(null);
      },
    });
  };


  const handleSavePopupOpen = (type) => {
    if (is2DMode) {
      const message = RP_TYPE.PREVIEW
        ? 'Please switch to 3d view for creating previews'
        : 'Please switch to 3d view for creating render';
      errorToast(message, {
        toastId: 'SWITCH_TO_3D_VIEW_RENDER_PREVIEW',
      });
      return;
    }

    setSavePopupType(type);
  };

  const handleImportPresetsClick = () => {
    setPresetsPopupOpen(true);
    dispatch(resetImportPressets());
  };

  const handleEdit = (camera) => () => {
    if (!expandedId) {
      setExpandedId(camera.id)
      return;
    }

    const currentCamera = cameraList.find(c => c.id === expandedId);
    // timeout to run validation from update camera first, if camera validation fails timeout will be cancelled
    const timerId = setTimeout(() => {
      setExpandedId(camera.id)
    });

    updateCamera({
      camera: currentCamera,
      newData: newCameraDataRef.current,
      onValidationError: () => {
        clearTimeout(timerId);
      },
    });
  };

  const hasSelectedItems = selectedIds.length > 0;

  if (!active) {
    return null;
  }

  return (
    <>
      {!savePopupType && (
        <CuratorSidebarLayout.FixedHeader>
          <div className="d-flex gap-3 w-100">
            <Button
              className={cx(styles.button, styles.blueButton)}
              size="xlg"
              onClick={() => setAddCameraFormVisible(true)}>
              + Add Current View
            </Button>
            <Button
              className={styles.button}
              size="xlg"
              variant="outline"
              color="light"
              onClick={handleImportPresetsClick}>
              Import Camera Presets
            </Button>
          </div>
        </CuratorSidebarLayout.FixedHeader>
      )}

      <Loader
        loading={importPresetsLoading}
        position="absolute"
        backdrop
        className={styles.loader}
        backdropColor="dark"
      />

      {/* Add camera form  */}
      {addCameraFormVisible && !savePopupType && (
        <div className="mb-3">
          <AddCameraForm
            onCancel={() => setAddCameraFormVisible(false)}
            onSubmit={handleAddCamera}
          />
        </div>
      )}

      <ImportCameraPresetsConflicts />

      <CuratorAccordion hideArrows activeKey={expandedId}>
        {cameraList?.map((camera) => {
          const selected = selectedIds.includes(camera.id);
          const disabled = addCameraFormVisible || addToPresetsConflict || savePopupType;
          const editMode = expandedId === camera.id;

          if (addToPresetsConflict === camera.id) {
            return (
              <AddToPresetsForm
                key={camera.id}
                onCancel={() => setAddToPresetsConflict(null)}
                onSubmit={handleAddToPresetsRenameSubmit(camera)}
                name={camera.cameraName}
              />
            );
          }

          if (savePopupType && !selected) {
            return null;
          }

          return (
            <CameraItem
              dataRef={editMode ? newCameraDataRef : null}
              key={camera.id}
              camera={camera}
              expandable
              selected={selected}
              onEditToggle={handleEdit(camera)}
              onSelect={() => toggleItem(camera)}
              onDelete={handleCameraDelete(camera)}
              onSaveToPressets={handleSaveToPresets(camera)}
              onClick={() => handleSelectedCamera(camera)}
              disabled={disabled}
              editMode={editMode}
              onSave={handleCameraUpdate(camera)}
            />
          );
        })}
      </CuratorAccordion>

      {/* Presets popup */}
      <CameraPresetsPopup open={presetsPopupOpen} onClose={() => setPresetsPopupOpen(false)} />

      {/* Bottom save preview/render popup */}
      {savePopupType && (
        <PreviewAndRenderSidebarPopup onClose={closeSavePopup}>
          <PreviewRenderForm type={savePopupType} onSubmit={handleSaveRenderPreview} />
        </PreviewAndRenderSidebarPopup>
      )}

      <PopupButtons
        sticky
        className="mt-auto"
        cancelText="Preview"
        cancelIcon={<PreviewIcon />}
        cancelDisabled={!hasSelectedItems}
        submitDisable={!hasSelectedItems}
        submitIcon={<RenderIcon />}
        onCancel={() => handleSavePopupOpen(RP_TYPE.PREVIEW)}
        submitText="Render"
        onSubmit={() => handleSavePopupOpen(RP_TYPE.RENDER)}
        cancelProps={{
          variant: 'outline',
          color: 'warning',
        }}
      />
    </>
  );
});
