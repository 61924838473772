import { createSlice } from "@reduxjs/toolkit";

const organisation = createSlice({
  name: "organisation",
  initialState: {
    organisationData: {},
    myOragnisationData: {},
    myOragnisationDataLoading: false,
    selectedOrganisationId: null,
  },
  reducers: {
    setOrganisationData: (state, { payload }) => {
      state.organisationData = payload;
    },
    setMyOrganisationDataLoading: (state, { payload }) => {
      state.myOragnisationDataLoading = payload;
    },
    setMyOrganisationData: (state, { payload }) => {
      state.myOragnisationData = payload;
      state.myOragnisationDataLoading = false;
    },
    setSelectedOrganisationId: (state, { payload }) => {
      state.selectedOrganisationId = payload;
    },
  },
});

export const {
  setOrganisationData,
  setMyOrganisationData,
  setSelectedOrganisationId,
} = organisation.actions;
export const organisationSelector = (state) => state.organisation;
export const organisationReducer = organisation.reducer;
export default organisationReducer;
