import { useState } from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { UserCard, UniversalSearchForm } from '../common';
import Logo from '../assets/images/lf-logo.png';
import { MAIN_MENU } from '../constants/menu';
import {
  NOTIFICATONS_ICON,
  NOTIFICATONS_BARS,
  NOT_TICK,
  TICK,
  VISIBILITY,
  CLEARALL_ICON,
  DOWNARRW,
} from '../constants/assetConstants';
import { ADMIN_DASHBOARD } from '../constants/routePathConstants';

export default function CommonMenu() {
  const navigate = useNavigate();
  const [notification, setNotification] = useState(false);
  const [notifyOption, setNotifyOption] = useState(false);

  return (
    <>
      <Navbar expand="xl" className="page-nav common-nav" collapseOnSelect id="commonMenu">
        <Navbar.Brand>
          <Link to={ADMIN_DASHBOARD}>
            <Image src={Logo} style={{ width: '120px' }} responsive="true" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle area-controls="topNavBarToggle" />
        <Navbar.Collapse className="justify-content-between topMenuNav" id="topNavBarToggle">
          <Nav defaultActiveKey="/products" className="menu">
            {MAIN_MENU.map((item, index) => (
              <Nav.Link as="li" key={item.path} eventKey={`navLink${index}`}>                
                <NavLink as={Link} to={item.path} className="nav-link">
                  <Image src={item.icon} className="svg" />
                  {item.name}
                </NavLink>
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>

        <div className="header-right-section d-flex flex-row justify-content-between align-items-center">
          {/* form */}
          <UniversalSearchForm />

          {/* notifications */}
          <button className="notification">
            <Image src={NOTIFICATONS_ICON} onClick={() => setNotification(!notification)} />
          </button>

          {/* user card */}
          <UserCard />
        </div>
      </Navbar>
      {notification && (
        <div className="Notification-Myproject-side">
          <div className="close-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.62733 7.39409C6.83349 7.60025 7.17542 7.60025 7.38158 7.39409C7.58774 7.18793 7.58774 6.846 7.38158 6.63984L4.74174 4L7.38158 1.36016C7.58774 1.154 7.58774 0.812074 7.38158 0.605913C7.17542 0.399752 6.83349 0.399752 6.62733 0.605913L3.98749 3.24575L1.3476 0.605863C1.14144 0.399702 0.799518 0.399702 0.593357 0.605863C0.387196 0.812024 0.387196 1.15395 0.593357 1.36011L3.23325 4L0.593356 6.63989C0.387195 6.84605 0.387195 7.18798 0.593356 7.39414C0.799517 7.6003 1.14144 7.6003 1.3476 7.39414L3.98749 4.75425L6.62733 7.39409Z"
                fill="#5A5C61"
              />
            </svg>
          </div>
          <div className="notification-header d-flex justify-content-between">
            <div>
              <span>Notifications</span>
            </div>
            <div className="notification-bars">
              <div className="not-img" onClick={() => setNotifyOption(!notifyOption)}>
                <a id="not-bars" href="javascript:void(0)">
                  <img src={NOTIFICATONS_BARS} alt="" />
                </a>
              </div>

              {notifyOption && (
                <div className="notification-popup">
                  <ul>
                    <li>
                      <span>
                        <img src={VISIBILITY} alt="" />
                        Show unread only
                      </span>
                    </li>
                    <li>
                      <span>
                        <img src={VISIBILITY} alt="" />
                        Show muted
                      </span>
                    </li>
                    <li>
                      <span>
                        <img src={TICK} alt="" />
                        Mark all as read
                      </span>
                    </li>
                    <li>
                      <span>
                        <img src={CLEARALL_ICON} alt="" />
                        Clear all notifications
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="notification-content m-4">
            <div className="notification-dropdown d-flex">
              <div className="dropdown">
                <button
                  className="filter"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <div>Filter</div>
                  <div>
                    <img src={DOWNARRW} alt="" />
                  </div>
                </button>
                <ul
                  className="dropdown-menu w-25 border border-secondary mt-2"
                  aria-labelledby="dropdownMenuButton1">
                  <p>Recent first</p>
                  <li>
                    <a className="dropdown-item" href="#">
                      Oldest first
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Name: A-Z
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Name: Z-A
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dropdown">
                <button
                  className="sortby"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <div>Sort By</div>
                  <div>
                    <img src={DOWNARRW} alt="" />
                  </div>
                </button>
                <ul
                  className="dropdown-menu w-25 border border-secondary mt-2"
                  aria-labelledby="dropdownMenuButton1">
                  <p>Recent first</p>
                  <li>
                    <a className="dropdown-item" href="#">
                      Oldest first
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Name: A-Z
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Name: Z-A
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="status my-2">
              <span>All</span>
            </div>
            <div className="messages">
              <div className="card mt-2">
                <span>
                  <img src={NOT_TICK} alt="" />
                  The preview <span className="text-primary">Preview name</span> is ready!
                </span>
                <span className="hours">20h ago</span>
              </div>
              <div className="card mt-2">
                <span>
                  <img src={NOT_TICK} alt="" />
                  The preview <span className="text-primary">Preview name</span> is ready!
                </span>
                <span className="hours">20h ago</span>
              </div>
              <div className="card mt-2">
                <span>
                  <img src={NOT_TICK} alt="" />
                  Your requested product has been denied
                </span>
                <span className="hours">20h ago</span>
              </div>
              <div className="card2 mt-2">
                <span>
                  <img src={NOT_TICK} alt="" />
                  Your requested product has been denied
                </span>
                <span className="hours">20h ago</span>
              </div>
              <div className="card mt-2">
                <span>
                  <img src={NOT_TICK} alt="" />
                  Your requested product has been denied
                </span>
                <span className="hours">20h ago</span>
              </div>
              <div className="card mt-2">
                <span>
                  <img src={NOT_TICK} alt="" />
                  Gagan has shared <span className="text-primary"> Render 5</span> with you
                </span>
                <span className="hours">20h ago</span>
              </div>
              <div className="card mt-2">
                <span>
                  The preview <span className="text-primary">Preview name</span> is ready!
                </span>
                <span className="hours">20h ago</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
