/* eslint-disable no-debugger */
import { createSlice } from "@reduxjs/toolkit";

export const CURATOR_MENU_SECTION = {
  PRODUCTS: 'products',
  STYLE: 'style',
  CAMERAS: 'cameras',
  SETTINGS: 'settings',
  LIGHT: 'light',
  RENDERS_AND_PREVIEWS: 'images',
  AUTOMATION: 'automation',
  METADATA: 'metadata',
  MORE: 'more',
}

const curatorMenuPreferencesSlicer = createSlice({
  name: "curatorMenuPreferences",
  initialState: {
    userEnabledSections: [],
    enabledSections: [],
    config: [],
  },
  reducers: {
    toggleSection: (state, { payload }) => {
      state.enabledSections = state.enabledSections.includes(payload)
        ? state.enabledSections.filter(s => s !== payload)
        : [...state.enabledSections, payload]
    },
    setUserEnabledSections: (state, { payload }) => {
      state.userEnabledSections = payload;

      const enabledSections = payload.map(s => s.unique_name);
      state.enabledSections = enabledSections
    },
    setConfig: (state, { payload }) => {
      state.config = payload;
    },
  },
});

export const {
  toggleSection,
  setConfig,
  setUserEnabledSections,
} = curatorMenuPreferencesSlicer.actions;
export const actions = curatorMenuPreferencesSlicer.actions;

export const curatorMenuPreferencesSelector = (state) => state.curatorMenuPreferences;
export const curatorMenuPreferencesReducer = curatorMenuPreferencesSlicer.reducer;
export default curatorMenuPreferencesReducer;


export const selectEnabledSections = (state) => {
  return state.curatorMenuPreferences.enabledSections
}
export const selectSidebarDefaultConfig = (state) => state.curatorMenuPreferences.config;
export const selectEnabledSectionsIds = (state) => {
  const { enabledSections, config } = state.curatorMenuPreferences;
  
  const ids = [];
  config.forEach(configItem => {
    if (enabledSections.includes(configItem.unique_name)) {
      ids.push(configItem.id);
    }
  })

  return ids;
}