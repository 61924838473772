import { useSelector } from 'react-redux';
import { filterSelector } from '../redux/slicers/filters/filterSlicer';

export const useFilterDropdownOptions = ({ filterName, filterType, filterId }) => {
  const { filterData } = useSelector(filterSelector);
  const filters = filterData?.results || [];

  // TODO: confirm if we need to check name here !!!
  let filter = [];

  if (filterName && filterType) {
    filter = filters.find(
      (f) => f.type === filterType && f.label_name.toLowerCase() === filterName.toLowerCase()
    );
  } else if (filterId) {
    filter = filters.find((f) => f.id === filterId);
  }

  if (!filter?.options) {
    return [];
  }

  return filter.options.map((option) => ({
    value: option.id,
    label: option.name,
  }));
};
