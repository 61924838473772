import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadsData: {},
  data: {},
  list: [],
  page: 1,
  perPage: 10,
  loading: false,
};

const uploads = createSlice({
  name: "uploads",
  initialState: initialState,
  reducers: {
    fetchUploadsRequest: (state) => {
      state.loading = true;
    },
    fetchUploadsSuccess: (state, { payload }) => {
      state.page = payload.page;
      state.data = payload.data;
      state.list = payload.data.results;
      state.loading = false;
    },
    fetchUploadsFailure: (state) => {
      state.loading = false;
    },
  },
});

export const { fetchUploadsRequest, fetchUploadsSuccess, fetchUploadsFailure } =
  uploads.actions;
export const uploadsSelector = (state) => state.uploads;
export const uploadsReducer = uploads.reducer;
export default uploadsReducer;
