import { useDispatch, useSelector } from "react-redux";
import { HDRI_POPUP, toggleHdriPopup, curatorLightSelector } from "../../../../../redux/slicers/admin/curatorLightSlicer";
import { paginatedLightActions } from "../../../../../redux/slicers/admin/paginatedLightSlicer";
import Button from "../../../../Button/Button";
import { BrushIcon } from "../../../../icons/BrushIcon";
import FileUploadIcon from "../../../../icons/FileUploadIcon";

export const HDRIButtons = () => {
  const dispatch = useDispatch()
  const hdriPopup = useSelector(state => curatorLightSelector(state).hdriPopup)

  const handleButtonClick = (popupType) => () => {
    dispatch(paginatedLightActions.triggerRefetch())
    dispatch(toggleHdriPopup(popupType))
  }

  return (
    <div className="d-flex justify-content-between gap-4 mb-3">
      <Button
        variant="outline"
        color="light"
        iconBefore={<FileUploadIcon />}
        className="w-50"
        size="xlg"
        hoverStyle="secondary"
        active={hdriPopup === HDRI_POPUP.MY}
        onClick={handleButtonClick(HDRI_POPUP.MY)}
      >
        My HDRI
      </Button>

      <Button
        variant="outline"
        color="light"
        iconBefore={<BrushIcon />}
        className="w-50"
        size="xlg"
        hoverStyle="secondary"
        active={hdriPopup === HDRI_POPUP.PRESETS}
        onClick={handleButtonClick(HDRI_POPUP.PRESETS)}
      >
        HDRI Presets
      </Button>
    </div>
  );
};
