// filters value
export const Filter_By_Value = ''
export const Renders_Value = 'rendered'
export const Previews_Value = 'preview'
export const My_Projects_Value = 'my_projects'
export const Models_3d_Value = '3d_models'
export const Rooms_Value = 'rooms'
export const Textures_Value = 'textures'
export const Uploads_Value = 'uploads'
export const Collections_Value = 'collections'
export const Templates_Value = 'templates'
export const Props_Value = 'props'
export const Requests_Value = 'requests'
export const Shared_Files_Value = 'sharedFiles'
// filters label
export const Filter_By_Label = 'All'
export const Renders_Label = 'Renders'
export const Previews_Label = 'Previews'
export const My_Projects_Label = 'My Projects'
export const Models_3d_Label = '3d Models'
export const Rooms_Label = 'Rooms'
export const Textures_Label = 'Textures'
export const Uploads_Label = 'Uploads'
export const Collections_Label = 'Collections'
export const Templates_Label = 'Templates'
export const Props_Label = 'Props'
export const Requests_Label = 'Requests'
export const Shared_Files_Label = 'Shared Files'

// room type label
export const Room_Template_Value = 'room-template'
export const Lifestyle_Value = 'lifestyle'
export const Silo_Value = 'silo'
export const Vignette_Value = 'vignette'
export const Web_Viewer_360_Value = 'web-viewer-360'
export const Video_Value = 'video'
export const Configurator_Value = 'configurator'
export const Augmented_Reality_Value = 'augmented reality'

// room type label
export const Room_Template_Label = 'Room Template'
export const Lifestyle_Label = 'Lifestyle'
export const Silo_Label = 'Silo'
export const Vignette_Label = 'Vignette'
export const Web_Viewer_360_Label = '360 Web Viewer'
export const Video_Label = 'Video'
export const Configurator_Label = 'Configurator'
export const Augmented_Reality_Label = 'Augmented Reality'

// sort value
export const Sort_By_Value = ''
export const Recent_First_Value = '-created_at'
export const Oldest_First_Value = 'created_at'
export const A_Z_Value = 'name'
export const Z_A_Value = '-name'

// sort label
export const Sort_By_Label = 'All'
export const Recent_First_Label = 'Recent first'
export const Oldest_First_Label = 'Oldest first'
export const A_Z_Label = 'Name: A-Z'
export const Z_A_Label = 'Name: Z-A'


// shared by value
export const With_Me_Value = 'with_me'
export const With_Organisation_Value = 'with_organisation'
export const By_Me_Value = 'by_me'

// shared by label
export const With_Me_Label = 'With me'
export const With_Organisation_Label = 'With organisation'
export const By_Me_Label = 'By me'
export const TRASH_FILTER_BY =
    [
        {
            value: Filter_By_Value,
            label: Filter_By_Label
        },
        {
            value: My_Projects_Value,
            label: My_Projects_Label
        },
        {
            value: Previews_Value,
            label: Previews_Label
        },
        {
            value: Textures_Value,
            label: Textures_Label
        },
        {
            value: Uploads_Value,
            label: Uploads_Label
        },
        {
            value: Collections_Value,
            label: Collections_Label
        }
    ]
export const SHARED_FILES_FILTER_BY = [
    {
        value: Filter_By_Value,
        label: Filter_By_Label
    },
    {
        value: Renders_Value,
        label: Renders_Label
    },
    {
        value: Previews_Value,
        label: Previews_Label
    },
    {
        value: My_Projects_Value,
        label: My_Projects_Label
    },
    {
        value: Models_3d_Value,
        label: Models_3d_Label
    },
    {
        value: Rooms_Value,
        label: Rooms_Label
    },
    {
        value: Textures_Value,
        label: Textures_Label
    },
    {
        value: Uploads_Value,
        label: Uploads_Label
    },
    {
        value: Collections_Value,
        label: Collections_Label
    },
    {
        value: Templates_Value,
        label: Templates_Label
    },
    {
        value: Props_Value,
        label: Props_Label
    }
]

export const SORT_BY = [
    {
        value: Sort_By_Value,
        label: Sort_By_Label
    },
    {
        value: Recent_First_Value,
        label: Recent_First_Label
    },
    {
        value: Oldest_First_Value,
        label: Oldest_First_Label
    },
    {
        value: A_Z_Value,
        label: A_Z_Label
    },
    {
        value: Z_A_Value,
        label: Z_A_Label
    }
]


export const SHARED_FILES_FILTER = [
    {
        value: With_Me_Value,
        label: With_Me_Label
    },
    {
        value: With_Organisation_Value,
        label: With_Organisation_Label
    },
    {
        value: By_Me_Value,
        label: By_Me_Label
    }
]
export const RENDER_FILTER_OPTIONS = [
    {
        value: Filter_By_Value,
        label: Filter_By_Label
    },
    {
        value: Renders_Value,
        label: Renders_Label
    },
    {
        value: Previews_Value,
        label: Previews_Label
    },
    {
        value: Uploads_Value,
        label: Uploads_Label
    },

];
export const RENDER_SORT_BY = [
    { label: 'Sort by', value: '' },
    { label: 'Name:A-Z', value: 'name' },
    { label: 'Name:Z-A', value: '-name' },
    { label: 'Grouped', value: 'grouped' },
    { label: 'Ungrouped', value: 'ungrouped' },

]

export const ROOM_OPTIONS = [
    { label: Room_Template_Label, value: Room_Template_Value },
    { label: Lifestyle_Label, value: Lifestyle_Value },
    { label: Silo_Label, value: Silo_Value },
    { label: Vignette_Label, value: Vignette_Value },
    { label: Web_Viewer_360_Label, value: Web_Viewer_360_Value },
    { label: Video_Label, value: Video_Value },
    { label: Configurator_Label, value: Configurator_Value },
    { label: Augmented_Reality_Label, value: Augmented_Reality_Value },
  ];  

export const PROJECT_SORT_BY = [
    { label: "Sort by" },
    { label: "Newest first", value: "-created_at" },
    { label: "Oldest first", value: "created_at" },
    { label: "Name:A-Z", value: "name" },
    { label: "Name :Z-A", value: "-name" },
]

export const PROJECT_TYPE = {
    ROOM: '1',
    LIFESTYLE: '2',
    SILO: '3',
    VIGNETTE: '4',
    THREESIXTY: '5',
    VIDEO: '6',
    CONFIGURATOR: '7',
    AUGMENTED_REALITY: '8',
}
