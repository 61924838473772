import cx from "classnames";
import { useState } from "react";
import { FormLabel } from "react-bootstrap";
import { toast } from "react-toastify";
import { errorToast } from "../../../../../helpers/toastHelper";
import Button from "../../../../Button/Button";
import styles from "./AddLight.module.scss";
import { useUnityLight } from "./useUnityLight";

export const AddLight = ({ onCancel }) => {
  const { addLight } = useUnityLight();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name.trim()) {
      errorToast('Please add light name')
      return;
    }

    addLight(name);
    onCancel();
  };

  const [name, setName] = useState("");

  return (
    <form className={cx(styles.formContainer, "p-3 mb-3")} onSubmit={handleSubmit}>
      <div className="accordion-range mb-3">
        <FormLabel>Light Name</FormLabel>
        <input
          placeholder="Light Name"
          type="text"
          className="form-control"
          value={name}
          name="lightName"
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className="mb-3"></div>
      <div className={`d-flex ${styles.buttons}`}>
        <Button
          color="light"
          type="button"
          className={`w-50 me-3 ${styles.btn}`}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          color="warning"
          type="submit"
          className={`w-50 ${styles.btn}`}
          onClick={handleSubmit}
        >
          Add
        </Button>
      </div>
    </form>
  );
};
