import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ModalButtonArea, BigButton } from '.';
import { icons } from '../assets';
import { DELETE_ICON, ARCHIVE_ICON } from '../constants/assetConstants';
import { apiErrorHandler } from '../helpers/axiosHelper';
import pluralize from 'pluralize';

const { BinIcon, ArchiveIcon } = icons;

// This one will be used by delete, archive and remove forever functionality
export default function DeactivatePopup(props) {
  const { selectedList, id, isPlural, actionWord = 'delete' } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const articleWord = isPlural ? 'these' : 'this';
  let item = props.item;
  if (item.includes('-')) {
    item = _.chain(item).split('-').join(' & ').value();
  }
  const phrase = isPlural
    ? `${articleWord} ${pluralize.plural(item)}`
    : `${articleWord} ${pluralize.singular(item)}`;
  const deleteTitle = `Are you sure you want to ${actionWord} ${phrase}?`;
  const deleteButtonPrompt = _.capitalize(actionWord); //`Yes, please ${actionWord} ${phrase}`;
  let errorMessage = `There was an error trying to ${actionWord} ${phrase}`;
  let successMessage = `${_.capitalize(phrase)} have been successfully ${actionWord}d`;

  let buttonIcon = BinIcon;
  let iconSrc = props.iconSrc || DELETE_ICON;
  if (actionWord === 'archive') {
    buttonIcon = ArchiveIcon;
    iconSrc = ARCHIVE_ICON;
  }

  useEffect(() => {
    return () => {
      setProcessing(false);
    };
  }, []);

  function processAction() {
    let data = isPlural ? selectedList : [{id: id}];
    if (_.isFunction(props.onConfirm)) {
      // this one is used for removing items from collection, since the "delete" from the collection
      // is a more complicated payload structure
      props.onConfirm(setProcessing, data)
    }
    if (_.isFunction(props.deleteAction)) {
      setProcessing(true);
      let payload;
      payload = new FormData();
      _.map(data, (card) => {
        payload.append('id', card.id);
      });
      // TODO
      // still need to complete the permissions area
      // since some delete apis will only be available to
      // live furnish staff only

      // setProcessing(false);
      props
        .deleteAction(payload, successMessage, errorMessage)
        .then((res) => {
          if (res && res.status) {
            setModalOpen(false);
            if (_.isFunction(props.onSuccess)) {
              props.onSuccess();
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setProcessing(false));
        setProcessing(false)
    }
  }

  return (
    <ModalButtonArea
      button={props.button}
      bigButton={props.bigButton}
      miniButton={props.miniButton}
      footerButton={props.footerButton}
      icon={props.icon}
      variant={props.disabled ? 'outline-secondary' : 'outline-dark'}
      buttonIcon={buttonIcon}
      buttonTitle={_.startCase(actionWord)}
      disabled={props.disabled || false}
      title={props.title || deleteTitle}
      subtitle={props.subtitle || 'Your items can be restored from the Trash area.'}
      size="lg"
      setShowModal={setModalOpen}
      showModal={modalOpen}
      includeCancelButton={true}
      promptButton={
        <BigButton
          onClick={processAction}
          disabled={processing}
          variant={processing ? 'outline-secondary' : 'outline-dark'}
          buttonTitle={deleteButtonPrompt}
        />
      }
      iconSrc={iconSrc}
      buttonLabel={props.buttonLabel}
    />
  );
}
