import { createContext, useContext } from "react";
const UserContext = createContext({
  currentUser: {},  
  setCurrentUser: () => {},
  token: null,
  setToken: () => {},
  refreshToken: null,
  setRefreshToken: () => {},
  verificationToken: null,
  setVerificationToken: () => {},
  onLogin: () => {},
  logout: () => {},
  latestTNCAccepted: false,
  setLatestTNCAccepted: () => {},
  emailVerified: false,
  isInternalUser: false,
  setEmailVerified: () => {},
  changeProfilePic: () => {},


  onInviteRoute: false,
  setOnInviteRoute: () => {},


});

export const useUserContext = () => useContext(UserContext);

export default UserContext;
