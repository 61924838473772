import { useEffect } from "react";
import { useSelector } from "react-redux"
import { selectIsLoading } from "../../../redux/slicers/admin/curatorSlicer";

export const CuratorCursorLoading = () => {
  const curatorLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (curatorLoading) {
      document.body.classList.add('curator-cursor-loading')

      return () => {
        document.body.classList.remove('curator-cursor-loading')
      }
    }
  }, [curatorLoading])

  return null;
}