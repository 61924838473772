import { API_ROOT_URL, GET_PROJECT_API, GET_PROPS_API, GET_ROOMS_API, PROJECT_UPDATE } from '../../config/constant/apiConstant';
import { createListApiRequest } from '../../helpers/apiHelper';
import api from "../../helpers/axiosHelper";
import { objectToSearchParams, uniqueId } from '../../helpers/jsHelper';
import { setPropsData } from '../../redux/slicers/admin/curatorSlicer';
import { setProps } from '../../redux/slicers/admin/propsSlicer';
import { roomActions, setRooms } from '../../redux/slicers/admin/roomsSlicer';
import { setLables } from '../../redux/slicers/admin/sidebarSlicer';
import { myProjectsActions, setMyProjectsData } from '../../redux/slicers/my-projects/myProjectsSlicer';
import { fetchMyProjectDetailsFailed, fetchMyProjectDetailsStart, fetchMyProjectDetailsSuccess, updateProject } from '../../redux/slicers/myProjectDetails/myProjectDetailsSlicer';
import { setSearchResult } from '../../redux/slicers/search/searchSlicer';

export const ProjectsSearchApi = (searchVal) => (dispatch) => {
    api().root(API_ROOT_URL)
        .get(`${GET_PROJECT_API}?search=${searchVal}`)
        .success((data) => {

            dispatch(setSearchResult(data))

        }).error((error) => {
            // console.log("texture search data err:", error)
            // dispatch(setSearchResult(error))
        }).send()

}

export const getProjectsApi = (arr, type) => (dispatch) => {
    let sort = type ? arr : 'sort=-created_at'

    api().root(API_ROOT_URL).get(`${GET_PROJECT_API}?&${arr}&${sort}`).success((data) => {

        dispatch(setMyProjectsData(data))
        dispatch(setLables(data?.sidefilter))
    }).error((error) => {

    }).send()
}

export const getProjectsApiV2 = ({ page, perPage, sort, project_type, ...rest}) => (dispatch) => {
    const searchParams = objectToSearchParams({ page, perPage, sort, project_type, ...rest});
    const API_URL = `${GET_PROJECT_API}?${searchParams}`;
    const requestId = uniqueId();

    dispatch(myProjectsActions.fetchMyProjectsListStart({ requestId }))

    api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => {
        dispatch(myProjectsActions.fetchMyProjectsListSuccess({
            page: page,
            data,
            requestId,
        }))
        dispatch(setLables(data.sidefilter))
      })
      .error((error) => {
        // TODO: add logger
        console.error(error);
        dispatch(myProjectsActions.fetchMyProjectsListFailed({ error, requestId }))  
      })
      .send();
}


export const getProjectApi = (id) => (dispatch) => {
    dispatch(fetchMyProjectDetailsStart())

    api()
        .root(API_ROOT_URL)
        .get(`${GET_PROJECT_API}${id}/`).success((data) => {
            dispatch(fetchMyProjectDetailsSuccess(data))
        }).error((error) => {
            dispatch(fetchMyProjectDetailsFailed(error))
        }).send()
}


export const deleteProjectsApi = (id) => (dispatch) => {
    api().root(API_ROOT_URL).delete(`${GET_PROJECT_API}${id}`).success((data) => {
        dispatch(getProjectsApi())
    }).error((error) => {

    }).send()
}
export const editProjectsApi = (formData) => (dispatch) => {
    api()
        .root(API_ROOT_URL)
        .patch(GET_PROJECT_API)
        .data(formData)
        .success((res) => {
            dispatch(getProjectsApi());
        }).error((error) => {

        }).send()
}

export const getRoomsApi = (arr) => (dispatch) => {


    api().root(API_ROOT_URL).get(`${GET_ROOMS_API}?&${arr}&sort=-created_at`).success((res) => {

        // console.log("get rooms api")

        dispatch(setRooms(res));
        dispatch(setLables(res?.sidefilter))

    }).error(() => {

    }).send()
}


export const getRoomListApi = createListApiRequest({
    url: GET_ROOMS_API,
    onStart: (dispatch) => 
        dispatch(roomActions.fetchRoomListStart()),
    onSuccess: ({ data, dispatch, args }) => {
        dispatch(roomActions.fetchRoomListSuccess({
            page: args.page,
            data,
        }))
        dispatch(setLables(data.sidefilter))
    },
    onError: (error, dispatch) => {
        console.error(error);
        dispatch(roomActions.fetchRoomListFailed(error))   
    }
  })


export const getPropsApi = () => (dispatch) => {

    api().root(API_ROOT_URL).get(`${GET_PROPS_API}?sort=-created_at&per_page=1000`).success((res) => {
        dispatch(setProps(res))
    }).error(() => {

    }).send()
}

export const updateProjectApi = ({ id, formData, onSuccess, onError }) => (dispatch) => {
    const API_URL = PROJECT_UPDATE.replace(':projectId', id);

    api().root(API_ROOT_URL)
        .patch(API_URL)
        .data(formData)
        .success((data) => {

            dispatch(updateProject(data));
            onSuccess(data)
        }).error((error) => {
            onError(error)
        }).send()
}
