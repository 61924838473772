/* eslint-disable no-debugger */
import { dispatcher } from '../../../../helpers/projectHelper';

export class UndoRedoObjectProperties {
  constructor({ undoRedoInstance, unityObjectProperties }) {
    this.undoRedo = undoRedoInstance;
    this.unityObjectProperties = unityObjectProperties;

    this.init();
  }

  init = () => {
    this.toggleExpertMode = this.undoRedo.createAction(({ prevValue, nextValue }) => {
      return {
        undo: () => {
          this.unityObjectProperties.setExpertMode(prevValue)
        },
        redo: () => {
          this.unityObjectProperties.setExpertMode(nextValue)
        },
      }
    })

    this.updateObject = this.undoRedo.createAction(({ name, prevValue, nextValue, data }) => {
      const { tilingOptionType }  = data;
      // TODO: parse light values here -> float/int etc
      // const lightId = selectSelectedLightId(getAppState());
      // const light = this._getLight(lightId);

      return {
        undo: () => {
          this.unityObjectProperties.modifyTiling(tilingOptionType, name, prevValue);
        },
        redo: () => {
          this.unityObjectProperties.modifyTiling(tilingOptionType, name, nextValue);
        }
      }
    })

    this.changeSize = this.undoRedo.createAction(({ prevValue, nextValue, data }) => {
      const { tilingOptionType }  = data;

      return {
        undo: () => {
          this.unityObjectProperties.changeSize({
            tilingOptionType,
            width: prevValue.width,
            height: prevValue.height,
          });
        },
        redo: () => {
          this.unityObjectProperties.changeSize({
            tilingOptionType,
            width: nextValue.width,
            height: nextValue.height,
          });
        }
      }
    })
    
  };
}
