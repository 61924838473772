import { useDispatch, useSelector } from 'react-redux';
import {
  ENVIRONMENT_LIGHT,
  setPreviewHDRI,
} from '../../../../../redux/slicers/admin/curatorLightSlicer';
import { curatorSelector } from '../../../../../redux/slicers/admin/curatorSlicer';
import { EyeIcon } from '../../../../icons/EyeIcon';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import { EnvironmentColor } from './EnvironmentColor';
import { HDRIButtons } from './HDRIButtons';
import { HDRIItem } from './HDRIItem';
import { HDRIPopup } from './HDRIPopup';
import { HDRIPreview } from './HDRIPreview';
import { PopupButtons } from './PopupButtons';
import { useUnityHDRI } from './useUnityHDRI';

export const EnvironmentLight = () => {
  const {
    eyeToolActive,
    selectedHDRI,
    hdriIntensity,
    environmentLightType,
    hdriRotation,
    resetLightEnabled,
    toggleHDRIEyeTool,
    toggleEnvironmentLightType,
    updateHDRIIntesity,
    updateRotation,
    resetLight,
  } = useUnityHDRI();

  const handleClick = (type) => () => {
    toggleEnvironmentLightType(type);
  };

  const handleRotationChange = (e) => {
    updateRotation(parseFloat(e.target.value));
  };

  const handleIntensityChange = (e) => {
    updateHDRIIntesity(e.target.value);
  };

  const hdriActive = environmentLightType === ENVIRONMENT_LIGHT.HDRI
  const hdriIcon = hdriActive ? (
    <EyeIcon
      color={eyeToolActive ? 'var(--secondary-color)' : undefined}
      onClick={(e) => {
        e.stopPropagation();
        toggleHDRIEyeTool();
      }}
    />
  ) : null;

  return (
    <>
      <div>
        <HDRIPopup />
        <HDRIPreview />

        <CuratorAccordion activeKey={environmentLightType} hideArrows>
          <CuratorAccordion.Item eventKey={ENVIRONMENT_LIGHT.HDRI}>
            <CuratorAccordion.Header
              selected={hdriActive}
              onCheckboxChange={handleClick(ENVIRONMENT_LIGHT.HDRI)}
              // onClick={handleClick(ENVIRONMENT_LIGHT.HDRI)}
              icon={hdriIcon}>
              HDRI
            </CuratorAccordion.Header>

            <CuratorAccordion.Body>
              <HDRIButtons />
              {selectedHDRI && (
                <HDRIItem
                  expanded
                  hdriItem={selectedHDRI}
                  intensity={hdriIntensity}
                  rotation={hdriRotation}
                  onRotationChange={handleRotationChange}
                  onIntensityChange={handleIntensityChange}
                />
              )}
            </CuratorAccordion.Body>
          </CuratorAccordion.Item>

          <CuratorAccordion.Item eventKey={ENVIRONMENT_LIGHT.COLOR}>
            <CuratorAccordion.Header
              onCheckboxChange={handleClick(ENVIRONMENT_LIGHT.COLOR)}
              // onClick={handleClick(ENVIRONMENT_LIGHT.COLOR)}
              selected={environmentLightType === ENVIRONMENT_LIGHT.COLOR}>
              Color
            </CuratorAccordion.Header>

            <CuratorAccordion.Body>
              <EnvironmentColor />
            </CuratorAccordion.Body>
          </CuratorAccordion.Item>
        </CuratorAccordion>
      </div>
      {resetLightEnabled && (
        <PopupButtons
          onCancel={() => resetLight()}
          cancelText="Reset to default"
          className="mt-auto"
        />
      )}
    </>
  );
};
