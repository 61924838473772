export const EyeIcon = ({ color , ...rest}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      style={{ color }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72411 14.948C8.9465 18.4273 12.1799 20.4311 15.6046 20.4311C19.0201 20.4311 22.2535 18.4273 24.4759 14.948C25.4414 13.436 25.4414 10.986 24.4759 9.48311C22.2535 6.00379 19.0201 4 15.6046 4C12.189 4 8.9556 6.00379 6.72411 9.48311C5.75864 10.9951 5.75864 13.4452 6.72411 14.948ZM7.88084 10.2209C9.8482 7.13321 12.6626 5.36622 15.6046 5.36622C18.5465 5.36622 21.3609 7.13321 23.3283 10.2209C24.0114 11.2865 24.0114 13.1446 23.3283 14.2102C21.3609 17.2979 18.5465 19.0649 15.6046 19.0649C12.6626 19.0649 9.8482 17.2979 7.88084 14.2102C7.19773 13.1446 7.19773 11.2865 7.88084 10.2209ZM11.6641 12.2173C11.6641 14.3941 13.431 16.1611 15.6079 16.1611C17.7847 16.1611 19.5517 14.3941 19.5517 12.2173C19.5517 10.0404 17.7847 8.27344 15.6079 8.27344C13.431 8.27344 11.6641 10.0404 11.6641 12.2173ZM13.0303 12.2173C13.0303 10.7964 14.187 9.63966 15.6079 9.63966C17.0288 9.63966 18.1855 10.7964 18.1855 12.2173C18.1855 13.6381 17.0288 14.7949 15.6079 14.7949C14.187 14.7949 13.0303 13.6381 13.0303 12.2173Z"
        fill="currentColor"
      />
    </svg>
  );
};
