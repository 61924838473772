import React from 'react';
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Image,
  Spinner,
} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import api, { apiFetch, apiPost } from '../helpers/axiosHelper';
import {
  API_ROOT_URL,
  CHECK_ORG_NAME,
  CREATE_ORGANIZATION,
  apiUpdateOrganization,
  apiGetOrganizationDetails,
  apiGetMembers,
  apiUpdateUserDetails,
} from '../helpers/api';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { objectToFormData, objectToSearchParams, uniqueId } from '../helpers/jsHelper';
import { icons } from '../assets';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Input } from '../components/common/input';
import CancelIcon from '../assets/images/settings/cancel.svg';
import AsyncSelect from 'react-select/async';
import { UserInfo, ViewEditItem, ModalButtonArea, BigButton } from './';
import { MY_ACCOUNT_ICON } from '../constants/assetConstants';
import _ from 'lodash';

const { ChangeProfileIcon, EditIcon, EyeIcon, FigureIcon, ProfileIcon } = icons;

function OrgOwnerModal(props) {
  const [showModal, setShowModal] = useState(false);
  const [confirmOwnerChange, setConfirmOwnerChange] = useState(false);
  const [currentOwner, setCurrentOwner] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [orgMembers, setOrgMembers] = useState(props.orgMembers);
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    if (orgMembers !== props.orgMembers) {
      setOrgMembers(props.orgMembers);
    }
  }, [props.orgMembers]);

  function submitMakeOwner() {
    let formData = new FormData();

    setSelectedUser(selectedUser.label);
    formData.append('owner', selectedUser.value); // need to render these feature based ui elements based on role permissions when we have them
    apiUpdateOrganization(formData).then((response) => {});
  }

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== '') {
      const filteredData = orgMembers.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(orgMembers);
    }
  };

  function handleModalClose() {
    setSelectedUser([]);
    setConfirmOwnerChange(false);
  }

  return (
    <>
      <div className="input-border">
        <label className="detail-label">Organization Owner</label>
        <div className="org-info">
          <span className="detail-info">{props?.orgOwner?.email}</span>
          <Button className="greyEditBtn" onClick={() => setShowModal(true)}>
            <img src={EditIcon} />
            Edit
          </Button>
        </div>
      </div>
      <ModalButtonArea
        ownerModal
        handleOwnerClose={handleModalClose}
        showButton={false}
        showModal={showModal}
        setShowModal={setShowModal}
        animation={false}
        className={confirmOwnerChange ? 'modal-chooseowner' : 'modal-changeowner'}
        title="Select Organization Owner"
        includeCancelButton={confirmOwnerChange ? false : true}
        onHide={() => setConfirmOwnerChange(false)}
        promptButton={
          confirmOwnerChange ? null : (
            <BigButton onClick={() => setConfirmOwnerChange(true)} buttonTitle={'Yes'} />
          )
        }
        body={
          confirmOwnerChange ? (
            <>
              <div>
                <Form.Control
                  className="search-input"
                  type={'text'}
                  placeholder="Search..."
                  maxLength={20}
                  value={selectedUser ? selectedUser.label : null}
                  onChange={(e) => searchItems(e.target.value)}
                  style={
                    {
                      // border: currentPassError ? 'red 2px solid' : '',
                      // position: 'relative'
                    }
                  }
                />
                <img
                  src={CancelIcon}
                  className="cancel-icon"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSelectedUser([])}
                />
              </div>
              <div className="filter-section">
                <div className="filter-section-props">
                  {searchInput.length > 1
                    ? _.map(filteredResults, (item, index) => {
                        return (
                          <div
                            className="props-section"
                            key={index}
                            onClick={() => setSelectedUser(item)}
                            style={{ cursor: 'pointer' }}>
                            <div>
                              <img
                                style={{ width: '25px', height: '25px' }}
                                src={item.profile_image}
                              />
                            </div>
                            <div className="props-data">
                              <p className="mb-0">{item.label}</p>
                              <span>{item.email}</span>
                            </div>
                          </div>
                        );
                      })
                    : _.map(orgMembers, (item, index) => {
                        return (
                          <div
                            className="props-section"
                            key={index}
                            onClick={() => setSelectedUser(item)}
                            style={{ cursor: 'pointer' }}>
                            <div>
                              <img
                                style={{ width: '25px', height: '25px' }}
                                src={item.profile_image}
                              />
                            </div>
                            <div className="props-data">
                              <p className="mb-0">{item.label}</p>
                              <span>{item.email}</span>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  className="submit-btn"
                  variant="primary"
                  onClick={submitMakeOwner}
                  style={{ width: '80%', margin: '0' }}>
                  Make owner
                </Button>
              </div>
            </>
          ) : (
            <div className="changeowner-div">
              <h1>
                You will lose ownership &#38; your role will be switched from owner to admin.{' '}
              </h1>
              <span>Are you sure you want to change the owner?</span>
            </div>
          )
        }
      />
    </>
  );
}

export default function OrgSection(props) {
  const [selectedOrgPhoto, setSelectedOrgPhoto] = useState('images/orgPic.svg');
  const [orgError, setOrgError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingImg, setSavingImg] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [userDetails, setUserDetails] = useState(props.userDetails);
  const [orgInfo, setOrgInfo] = useState({});
  const [orgId, setOrgId] = useState();
  const [orgOwnerId, setOrgOwnerId] = useState();
  const [orgOwner, setOrgOwner] = useState({});
  const [orgMembers, setOrgMembers] = useState([]);
  const [editingOrgName, setEditingOrgName] = useState(false);


  useEffect(() => {
    if (userDetails !== props.userDetails) {
      setUserDetails(props.userDetails);
    }
    getOrgInfo();
  }, [userDetails, props.userDetails]);

  useEffect(() => {
    getOrgOwner();
  }, [orgId]);

  function getOrgInfo() {
    apiGetOrganizationDetails().then((response) => {
      if (response) {
        setOrgInfo(response);
        setOrgOwnerId(response.owner);
        setOrgId(response.id);
      }
    });
  }

  function getOrgOwner() {
    apiGetMembers().then((response) => {
      setOrgMembers(formatOrgMembers(response.results));
      let owner = _.find(response.results, (x) => x.id === orgOwnerId);
      setOrgOwner(owner?.user);
    });
  }

  function formatOrgMembers(arr) {
    let newArray = _.map(arr, (item) => {
      let newItem = {};
      newItem.label = item.user.name ? item.user.name : '';
      newItem.value = item.id;
      newItem.email = item.user.email ? item.user.email : '';
      newItem.profile_image = item.user.profile_image;
      return newItem;
    });
    return newArray;
  }

  const handleOrganizationChange = () => {
    hiddenOrgInput.current.click();
  };

  const hiddenOrgInput = React.useRef(null);

  const handleOrganizationImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setSelectedOrgPhoto(URL.createObjectURL(img));

      let formData = new FormData();

      formData.append('thumbnail', img);
      setSavingImg(true);
      apiUpdateOrganization(formData).then((response) => {
        getOrgInfo();
        setSavingImg(false);
      });
    }
  };

  const handleOrgSave = () => {
    setSaving(true);
    let formData = new FormData();

    formData.append('name', orgName);

    apiUpdateOrganization(formData).then((response) => {
      props.getUserDetails();
      setSaving(false);
    });
  };

  const handleOrgNameChange = async (e) => {
    let name = e.target.value;
    setOrgName(name);
    if (name.length > 0) {
      await apiFetch(`${CHECK_ORG_NAME}?name=${name}`).send((data) => {
        if (data && data.status) {
          setOrgError(false);
        } else {
          // setOrgError(true);
          // setOrgError(false);
        }
        return data;
      });
    } else {
      // will put this back when API works
      //setOrgError(true);
    }
  };

  return (
    <Col xs={12} md={6}>
      <div className="right-section">
        <div className="org-details">
          <div className="input-border">
            <label className="detail-label">Organization Picture</label>
            <figure>
              <img
                src={orgInfo.thumbnail}
                style={{ width: '6em', height: '6em', borderRadius: '0.5em' }}></img>
              <Button className="greyEditBtn" onClick={handleOrganizationChange}>
                <input
                  type="file"
                  ref={hiddenOrgInput}
                  onChange={handleOrganizationImage}
                  style={{ display: 'none' }}
                />{' '}
                <img src={ChangeProfileIcon}></img>
                <span className="ms-2">
                  {savingImg ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Change Picture'
                  )}
                </span>
              </Button>
            </figure>
          </div>
          <ViewEditItem
            label="Organization Name"
            disabled={orgError}
            onUpdate={handleOrgSave}
            value={userDetails?.organization_details?.name}
            editing={editingOrgName}
            setEditing={setEditingOrgName}
          >
            <div className="input-div">
              <Form.Control
                className="input-name"
                value={orgName}
                onChange={handleOrgNameChange}
                maxLength={20}
                style={{ border: orgError ? 'red 3px solid' : '' }}
                placeholder="ABC Interiors"
                onKeyDown={(e) => {
                  if(e.key === "Enter") {
                    handleOrgSave();
                    setEditingOrgName(false);
                  }
                }}
              />
            </div>
          </ViewEditItem>
          <OrgOwnerModal orgOwner={orgOwner} orgMembers={orgMembers} />
        </div>
      </div>
    </Col>
  );
}
