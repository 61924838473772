import cx from "classnames";
import { TILING_TYPE } from "../../../../../config/constant/unityConstants";
import FormRange from "../../FormElements/FormRange";
import { undoRedo } from "../../UndoRedo/UndoRedo";
import { useProperties, useUnityRedux } from "../useUnityRedux";
import { MapTextureForm } from "./MapTextureForm";
import styles from './PropertiesSection.module.scss';

export const PropertiesSection = ({
  title,
  data,
  expanded,
  min,
  max,

  // sliderValue,
  // onSliderChange,
  // checkboxes,
  // onIvertMapCilck,
  // onFileChange,
  filePlaceholder,
}) => {
  const { onChange, toggleMapSelection } = useUnityRedux();
  const {
    propertiesData: { isTexture },
  } = useProperties();

  if (!data) return null;

  const {
    tilingType,

    intensity,
    invertMap,
    customMap,
    width,
    height,
    x,
    y,
    lock,
    rotation,
    mapImage,
    useSeparateTiling,
    applicable,
    customMapApplicable,
    textureAlpha,
    effectLightColorBounce,
    texture,
  } = data;


  const useSeparateTilingChecked = customMap && useSeparateTiling;

  const checkboxes = [
    {
      label: "Use a custom map",
      // value: customMapApplicable ? customMap : undefined, // undefined as it'll be filtered later
      value: customMap,
      // disabled: intensity <= 0,
      name: "customMap",
    },
    // TODO: uncomment it when it's ready on unity side
    {
      label: "Use texture alpha",
      value: textureAlpha,
      disabled: !isTexture,
      name: "textureAlpha",
    },
    // TODO: uncomment it when it's ready on unity side
    {
      label: "Effect light color bounce",
      value: effectLightColorBounce,
      name: "effectLightColorBounce",
    },
    {
      label: "Use separate Tiling",
      value: useSeparateTilingChecked,
      disabled: !customMap || textureAlpha,
      name: "useSeparateTiling",
      hidden: !customMap,
    },
  ].filter((c) => typeof c.value === "boolean");

  const handleChange = onChange(tilingType, data);

  const hasSlider = typeof intensity === "number";
  const hasInvert = typeof invertMap === "boolean";

  const showWhileCollapsed = tilingType === TILING_TYPE.GLOSSY || (hasSlider && intensity > 0 & customMap);

  if (!expanded && !showWhileCollapsed) return null;
  // if (!applicable) return null;

  const undoRedoProps = {
    undoRedoAction: undoRedo.objectProperties.updateObject,
    undoRedoData: {
      tilingOptionType: tilingType,
    }
  }

  return (
    <div className="properties-global">
      <div className="material-input-sliders">
          {hasSlider && (
            <FormRange
              label={title}
              onChange={handleChange}
              min={min}
              max={max}
              value={intensity}
              step={0.01}
              name="intensity"
              {...undoRedoProps}
            />
          )}

          {/* Checkboxes */}
          {expanded && (
            <div className="w-100 d-flex justify-content-between mt-2">
              <div className="w-40">
                {checkboxes.map((checkbox, idx) => {
                  if (checkbox.hidden) return null;

                  return (
                    <div
                      className="form-check custom-checkbox d-flex align-items-center mb-3"
                      key={idx}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={checkbox.name}
                        checked={checkbox.value}
                        disabled={checkbox.disabled}
                        onChange={handleChange}
                      />
                      <span>{checkbox.label}</span>
                    </div>
                  );
                })}
              </div>

              {/* Invert button */}
              {hasInvert && customMap && (
                <div className="w-30 invert">
                  <div className="invert-map d-flex">
                    <div className="invert-btn ">
                      <input
                        type="button"
                        name="invertMap"
                        className={cx(
                          "btn btn-light texture-btn",
                          styles.invertBtn,
                          invertMap && "active"
                        )}
                        onClick={handleChange}
                        value="Invert map"
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* upload button */}
              {customMapApplicable && mapImage&& (
                <div className="w-30">
                  <img
                    src={mapImage}
                    alt=""
                    className={styles.textureMapThumbnail}
                    onClick={() => toggleMapSelection(tilingType)}
                  />
                </div>
              )}
            </div>
          )}

          {expanded && useSeparateTilingChecked  && (
            <MapTextureForm
              data={{
                width,
                height,
                x,
                y,
                rotation,
                tilingType,
                lock,
              }}
            />
          )}
      </div>
    </div>
  );
};
