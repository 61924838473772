import { Card, Carousel, Col, Row, Container, Image, Navbar, Form } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import { BsExclamationTriangle, BsEye, BsEyeSlash } from 'react-icons/bs';
import { GoogleLogin } from 'react-google-login';
import { useNavigate, Link } from 'react-router-dom';
import { setTitle, emailIsValid, passwordIsValid } from '../helpers/jsHelper';
import { BULB, GOOGLE_ICON, LOGO } from '../constants/assetConstants';
import { PublicCarousel } from '../common';
import { apiGetCarouselImages, apiPostGoogleLogin, apiPostRegister } from '../helpers/api';
import { successToast } from '../helpers/toastHelper';
import { UserContext } from '../contexts';
import {
  AUTH_TOKEN,
  EMAIL_VERIFIED,
  LATEST_TNC_ACCEPTED,
  REFRESH_TOKEN,
} from '../constants/projectConstants';
import {
  CUSTOMIZE_EXPERIENCE,
  SIGN_IN_ROUTE,
  ADMIN_DASHBOARD,
} from '../constants/routePathConstants';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { UnionExclamation } from '../constants/assetConstants';



export default function Register() {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const [carouselList, setCarouselList] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [confirmationPasswordMessage, setConfirmationPasswordMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
  const [firstNameMessage, setFirstNameMessage] = useState('');
  const [lastNameMessage, setLastNameMessage] = useState('');


  useEffect(() => {
    setTitle('Login');
    apiGetCarouselImages({ name: 'furnish' }, null, 'Unable to get carousel images.').then((res) =>
      setCarouselList(res?.results)
    );
  }, []);

  // google login/register
  const onSuccess = (res) => {
    apiPostGoogleLogin(
      { access_token: res.accessToken },
      false,
      null,
      'Unable to complete google login'
    ).then((res) => {
      if (res) {
        let { access, is_active_org, is_email_verified, latest_tnc_accepted, refresh, info } = res;
        localStorage.setItem('is_active_org', is_active_org);
        localStorage.setItem(EMAIL_VERIFIED, is_email_verified);
        localStorage.setItem(LATEST_TNC_ACCEPTED, latest_tnc_accepted);
        localStorage.setItem(AUTH_TOKEN, access);
        localStorage.setItem(REFRESH_TOKEN, refresh);

        userCtx.setToken(access);
        userCtx.setRefreshToken(refresh);
        userCtx.setEmailVerified(is_email_verified);
        userCtx.setLatestTNCAccepted(latest_tnc_accepted);

        if (info === undefined) {
          navigate(CUSTOMIZE_EXPERIENCE);
        } else if (is_email_verified) {
          if (userCtx && userCtx.onLogin) {
            userCtx.onLogin(info, access, refresh, is_email_verified, latest_tnc_accepted);
            navigate(ADMIN_DASHBOARD);
          }
        }
      }
    });
  };

  // google login
  const onFailure = (res) => {
    console.error(res);
  };

  const handleFirstName = (e) => {
    e.preventDefault();
    const alphabets = /^[a-zA-Z]*$/;
    setFirstName(e.target.value);
    if (!e.target.value || !e.target.value.trim()) {
      setFirstNameMessage('First Name is Required');
    } else {
      if (!alphabets.test(e.target.value)) {
        setFirstNameMessage('Only Letters are Allowed');
      } else {
        setFirstNameMessage('');
      }
    }
  };

  const handleLastName = (e) => {
    e.preventDefault();
    const alphabets = /^[a-zA-Z]*$/;
    setLastName(e.target.value);
    if (!e.target.value || !e.target.value.trim()) {
      setLastNameMessage('Last Name is Required');
    } else {
      if (!alphabets.test(e.target.value)) {
        setLastNameMessage('Only Letters are Allowed');
      } else {
        setLastNameMessage('');
      }
    }
  };
  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    if (!e.target.value || !e.target.value.trim()) {
      setEmailMessage('Email is Required');
    } else if (!emailIsValid(e.target.value)) {
      setEmailMessage('Invalid Email');
    } else {
      setEmailMessage('');
    }
  };

  const handlePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
    if (!e.target.value) {
      setPasswordMessage('Password Field is Required');
    } else if (!passwordIsValid(e.target.value)) {
      setPasswordMessage(
        'Must contain at least 8 characters and one number, one lowercase, one uppercase character.'
      );
    } else if (e.target.value.length < 8) {
      setPasswordMessage('Password must contain at least 8 characters');
    } else {
      setPasswordMessage('');
    }
  };
  const handlePasswordConfirmation = (e) => {
    e.preventDefault();
    setPasswordConfirmation(e.target.value);
    if (password !== e.target.value) {
      setConfirmationPasswordMessage('Password and confirmation password do not match');
    } else if (!e.target.value || !e.target.value.trim()) {
      setConfirmationPasswordMessage('Confirm Password Field is Required');
    } else {
      setConfirmationPasswordMessage('');
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleShowConfirmationPassword = (e) => {
    e.preventDefault();
    setShowConfirmationPassword(!showConfirmationPassword);
  };

  const validate = () => {
    let firstnameError = '';
    let lastnameError = '';
    let emailError = '';
    let passwordError = '';
    let confpasswordError = '';

    if (!firstName || !firstName.trim()) {
      firstnameError = 'First Name is Required';
    }

    if (!lastName || !lastName.trim()) {
      lastnameError = 'Last Name is Required';
    }

    if (!email || !email.trim() || !emailIsValid(email)) {
      emailError = 'Email Field is Invalid ';
    }

    if (!password || !password.trim()) {
      passwordError = 'Password Field is Required';
    } else if (!passwordIsValid(password)) {
      passwordError =
        'Must contain at least 8 characters and one number, one lowercase, one uppercase character.';
    } else if (password.length < 8) {
      passwordError = 'Must contain at least 8 characters.';
    }

    if (password !== passwordConfirmation) {
      confpasswordError = 'Password and Confirmation Password do not match';
    } else if (!passwordConfirmation || !passwordConfirmation.trim()) {
      confpasswordError = 'Confirm Password field is required';
    }

    if (emailError || passwordError || firstnameError || lastnameError || confpasswordError) {
      setEmailMessage(emailError);
      setPasswordMessage(passwordError);
      setFirstNameMessage(firstnameError);
      setLastNameMessage(lastnameError);
      setConfirmationPasswordMessage(confpasswordError);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const payload = {
        email: email.trim(),
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        password: password.trim(),
      };

      apiPostRegister(payload, true).then((res) => {
        if (res) {
          let { data, message, status } = res;

          successToast(message);
          if (status === true) {
            userCtx.setVerificationToken(data.verification_token);
            navigate('/verify', {
              state: { email: email, verificationToken: data.verification_token },
            });
          }
        }
      });
    }
  };

  return (
    <div className="vh-100 vw-100">
      <Navbar expand="lg" className="bg-body publicMenu">
        <Container className="px-0">
          <div className="w-100 d-flex align-items-center justify-content-between mx-auto py-2">
            <Navbar.Brand>
              <Image src={LOGO} responsive="true" />
            </Navbar.Brand>
            <div className="justify-content-end">
              <Navbar.Text>
                <div className="d-flex align-items-center">
                  <img src={BULB} />
                  <span className="me-2 fw-normal">Already a member?</span>
                  <Link to={SIGN_IN_ROUTE} className="text-primary fw-bold link-primary">
                    Sign in
                  </Link>
                </div>
              </Navbar.Text>
            </div>
          </div>
        </Container>
      </Navbar>

      <div className="publicContent">
        <Container>
          <Row className="d-flex justify-content-center align-items-stretch pt-5">
            <Col xs={7}>
              <PublicCarousel data={carouselList}/>
            </Col>
            <Col xs={5}>
              <Card className="py-5 px-4 h-100 border-0">
                <div className="text-center">
                  <h1 className="m-bottom-1 appH1">Become a member</h1>
                </div>
                <Form action="" className="mt-3" onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group className="col ps-5">
                      {firstNameMessage ? (
                        <Form.Label className="text-danger">
                          <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {firstNameMessage}
                            </small>
                        </Form.Label>
                      ) : (
                        <Form.Label>First Name</Form.Label>
                      )}
                      <Form.Control
                        type="text"
                        id="form2Example19"
                        name="first_name"
                        className={firstNameMessage ? 'input-error' : ''}
                        placeholder="Type your first name"
                        onChange={(e) => setFirstName(e.target.value)}
                        onBlur={handleFirstName}
                        onFocus={() => setFirstNameMessage('')}
                      />
                    </Form.Group>
                    <Form.Group className="col pe-5">
                      {lastNameMessage ? (
                        <Form.Label className="text-danger">
                          <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {lastNameMessage}
                            </small>
                        </Form.Label>
                      ) : (
                        <Form.Label>Last Name</Form.Label>
                      )}
                      <Form.Control
                        type="text"
                        id="form2Example20"
                        name="last_name"
                        className={lastNameMessage ? 'input-error' : ''}
                        placeholder="Type your last name"
                        onChange={(e) => setLastName(e.target.value)}
                        onBlur={handleLastName}
                        onFocus={() => setLastNameMessage('')}
                      />
                    </Form.Group>
                    <Form.Group className="px-5 py-3">
                      {emailMessage ? (
                        <Form.Label className="text-danger">
                          <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {emailMessage}
                            </small>
                        </Form.Label>
                      ) : (
                        <Form.Label>Email</Form.Label>
                      )}
                      <Form.Control
                        type="email"
                        id="form2Example21"
                        name="email"
                        className={emailMessage ? 'input-error' : ''}
                        placeholder="Type your email"
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={handleEmail}
                        onFocus={() => setEmailMessage('')}
                      />
                    </Form.Group>
                    <Form.Group className="px-5 py-3">
                      <div className="password-field">
                        {passwordMessage ? (
                          <Form.Label className="text-danger">
                            <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {passwordMessage}
                            </small>
                          </Form.Label>
                        ) : (
                          <Form.Label>Password</Form.Label>
                        )}
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          id="form2Example28"
                          name="password"
                          className={passwordMessage ? 'input-error' : ''}
                          placeholder="Type your password"
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={handlePassword}
                          onFocus={() => setPasswordMessage('')}
                        />
                        {showPassword ? (
                          <RiEyeLine className="eye-icon" onClick={handleShowPassword} />
                        ) : (
                          <RiEyeOffLine className="eye-icon" onClick={handleShowPassword} />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="px-5 py-3">
                      <div className="password-field">
                        {confirmationPasswordMessage ? (
                          <Form.Label className="text-danger">
                            <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {confirmationPasswordMessage}
                            </small>
                          </Form.Label>
                        ) : (
                          <Form.Label>Confirm Password</Form.Label>
                        )}
                        <Form.Control
                          type={showConfirmationPassword ? 'text' : 'password'}
                          id="form2Example29"
                          className={confirmationPasswordMessage ? 'input-error' : ''}
                          placeholder="Repeat your password"
                          onChange={(e) => setPasswordConfirmation(e.target.value)}
                          onBlur={handlePasswordConfirmation}
                          onFocus={() => setConfirmationPasswordMessage('')}
                        />
                        {showConfirmationPassword ? (
                          <RiEyeLine className="eye-icon" onClick={handleShowConfirmationPassword} />
                        ) : (
                          <RiEyeOffLine
                            className="eye-icon"
                            onClick={handleShowConfirmationPassword}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <div className="d-grid px-5 py-3">
                      <button className="btn btn-warning" type="submit">
                        <span className="btn-font">Register</span>
                      </button>
                    </div>
                    <div>
                      <div className="separator px-5 py-3">
                        <small className="fw-normal">Or register with</small>
                      </div>
                      <div className="google-login d-flex justify-content-center">
                        <GoogleLogin
                          render={(renderProps) => (
                            <button
                              className="btn bg-body py-3 px-4 shadow"
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}>
                              <img src={GOOGLE_ICON} className="mx-auto" />
                            </button>
                          )}
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          // buttonText=""
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                        />
                      </div>
                    </div>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

     
    </div>
  );
}