/* eslint-disable no-unreachable */
import PubSub from 'pubsub-js'
import hotkeys from 'hotkeys-js';
import { detectOS } from 'detect-browser';
import { useEffect } from 'react';
import { useUnityShortcuts } from '../CuratorHeader/useUnityShortcuts';
import { curatorShortcutsMap, SHORTCUT } from './curatorShortcuts.config';
import { AXIS, CURSOR_MODE, RP_TYPE, UNITY_2D_VIEW_MODE } from '../../../../config/constant/unityConstants';
import { getAppState } from '../../../../redux/store';
import { useOpenProjectFromFile } from '../CuratorHeader/useOpenProjectFromFile';
import { useUnityContext } from '../../../container/unityContainer';
import { KEYBOARD_EVENT } from '../../../../helpers/unityPubSub';

hotkeys.filter = (event) => {
  const target = event.target || event.srcElement;
  const { tagName, type } = target;
  let flag = true;
  // ignore: isContentEditable === 'true', <input> and <textarea> when readOnly state is false, <select>
  if (
    target.isContentEditable
    || ((tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') && !target.readOnly && type !== 'checkbox')
  ) {
    flag = false;
  }
  return flag;
}

export const useCuratorShortcuts = () => {
  const unityContex = useUnityContext()
  const unityShortcuts = useUnityShortcuts();
  const { triggerFileSelection } = useOpenProjectFromFile();

  const getData = () => {
    const state = getAppState();
  }

  useEffect(() => {
    const os = detectOS(navigator.userAgent);
    const isMac = os === 'Mac OS';

    const getShortcut = (name) => {
      return curatorShortcutsMap[name].keys
        .map((key) => {
          key = key.toLowerCase();

          if (key === 'hover' || key === 'click') {
            return;
          }

          if (!isMac) return key;

          if (key === 'alt') {
            return 'option';
          }
          
          if (key === 'ctrl') {
            return 'command';
          }

          if (key === 'delete') {
            return 'backspace';
          }

          return key;
        })
        .filter(Boolean)
        .join('+')
        .toLocaleLowerCase();
    };

    const basicShortcuts = {
      [SHORTCUT.OPEN_PROJECT]: () => {
        triggerFileSelection();
      },

      [SHORTCUT.SAVE_PROJECT]: (e) => {
        unityShortcuts.saveProject();
      },

      [SHORTCUT.SAVE_AS_NEW]: (e) => {
        unityShortcuts.saveProjectAsNew();
      },

      [SHORTCUT.QUIT_PROJECT]: () => {
        unityShortcuts.closeProject();
      },

      [SHORTCUT.UNDO]: (e) => {
        unityShortcuts.undo();
      },

      [SHORTCUT.REDO]: (e) => {
        unityShortcuts.redo();
      },

      [SHORTCUT.DELETE]: (e) => {
        unityShortcuts.deleteObject();
      },

      [SHORTCUT.STYLE_ONLY]: (e) => {
        unityShortcuts.changeCursorMode(CURSOR_MODE.STYLE_ONLY)
      },
      
    };

    const viewportShortcuts = {
      // [SHORTCUT.ROTATE_VIEWPORT]: () => {
      //   alert(SHORTCUT.ROTATE_VIEWPORT);
      // },

      // [SHORTCUT.PAN_VIEWPORT]: () => {
      //   alert(SHORTCUT.PAN_VIEWPORT);
      // },

      // [SHORTCUT.ZOOM_IN_OUT]: () => {
      //   alert(SHORTCUT.ZOOM_IN_OUT);
      // },

      [SHORTCUT.FOCUS_SELECTED]: () => {
        unityShortcuts.focusObject();
      },

      [SHORTCUT.SWITCH_VIEW_2D_3D]: () => {
        unityShortcuts.changeDimension()
      },

      [SHORTCUT.TOP_VIEW]: () => {
        unityShortcuts.update2DViewMode(UNITY_2D_VIEW_MODE.TOP)
      },

      [SHORTCUT.FRONT_VIEW]: () => {
        unityShortcuts.update2DViewMode(UNITY_2D_VIEW_MODE.FRONT)
      },

      [SHORTCUT.LEFT_VIEW]: () => {
        unityShortcuts.update2DViewMode(UNITY_2D_VIEW_MODE.LEFT)
      },

      [SHORTCUT.RIGHT_VIEW]: () => {
        unityShortcuts.update2DViewMode(UNITY_2D_VIEW_MODE.RIGHT)
      },

      [SHORTCUT.BACK_VIEW]: () => {
        unityShortcuts.update2DViewMode(UNITY_2D_VIEW_MODE.BACK)
      },
    }

    const editShortcuts = {
      [SHORTCUT.EDIT_PROPERTIES]: () => {
        unityShortcuts.toggleProperties()
      },
      [SHORTCUT.CHOOSE_TEXTURE]: () => {
        unityShortcuts.toggleTexture()
      },
      [SHORTCUT.CHOOSE_MATERIAL]: () => {
        unityShortcuts.toggleMaterial()
      },
      [SHORTCUT.CHOOSE_COLOR]: () => {
        unityShortcuts.toggleColor()
      },

      [SHORTCUT.COPY_MATERIAL]: () => {
        unityShortcuts.copyMaterial();
      },
      [SHORTCUT.PASTE_MATERIAL]: () => {
        unityShortcuts.pasteMaterial();
      },
      [SHORTCUT.DUPLICATE_SELECTED]: () => {
        unityShortcuts.duplicateObject();
      },
      [SHORTCUT.REPLACE_SELECTED_PROP]: () => {
        unityShortcuts.replaceProp();
      },

      [SHORTCUT.PROJECT_IMAGES]: () => {
        unityShortcuts.toggleImages();
      },
      [SHORTCUT.RENDER_PROJECT]: () => {
        unityShortcuts.renderPreviewPopup.togglePopup(RP_TYPE.RENDER);
      },
      [SHORTCUT.PREVIEW_PROJECT]: () => {
        unityShortcuts.renderPreviewPopup.togglePopup(RP_TYPE.PREVIEW);
      },
    }

    const toolsShortcuts = {
      [SHORTCUT.STYLE_ONLY]: () => {
        unityShortcuts.switchToStyleOnly();
      },
      [SHORTCUT.MOVE_SELECTED]: () => {
        unityShortcuts.switchToMove();
      },
      [SHORTCUT.ROTATE_SELECTED]: () => {
        unityShortcuts.switchToRotate();
      },
      [SHORTCUT.SCALE_SELECTED]: () => {
        unityShortcuts.switchToScale();
      },

      [SHORTCUT.SNAP_SELECTED]: {
        keyDown: () => unityShortcuts.snapObject(true),
        keyUp: () => unityShortcuts.snapObject(false),
      },
      [SHORTCUT.SWITCH_AXIS_GL]: () => {
        unityShortcuts.toggleTransformSpaceMode();
      },

      [SHORTCUT.ACTIVATE_X_AXIS]: () => {
        unityShortcuts.toggleSelectedAxis(AXIS.X);
      },
      [SHORTCUT.ACTIVATE_Y_AXIS]: () => {
        unityShortcuts.toggleSelectedAxis(AXIS.Y);
      },
      [SHORTCUT.ACTIVATE_Z_AXIS]: () => {
        unityShortcuts.toggleSelectedAxis(AXIS.Z);
      },
    }

    const selectionShortcuts = {
      // [SHORTCUT.SELECT]: () => {
      //   alert(SHORTCUT.SELECT)
      // },
      // [SHORTCUT.DESELECT]: {
      //   keyDown: () => unityShortcuts.removeSelection(true),
      //   keyUp: () => unityShortcuts.removeSelection(false),
      // }
      // [SHORTCUT.SELECT_MULTIPLE]: () => {
      //   alert(SHORTCUT.SELECT_MULTIPLE)
      // },
      // [SHORTCUT.OPEN_GROUP]: () => {
      //   alert(SHORTCUT.OPEN_GROUP)
      // },

      // [SHORTCUT.SELECT_CHILD]: () => {
      //   alert(SHORTCUT.SELECT_CHILD)
      // },
      // [SHORTCUT.DESELECT_CHILD]: () => {
      //   alert(SHORTCUT.DESELECT_CHILD)
      // },
      // [SHORTCUT.SELECT_MULTIPLE_CHILD]: () => {
      //   alert(SHORTCUT.SELECT_MULTIPLE_CHILD)
      // },
    }

    const viewShortcuts = {
      [SHORTCUT.HIGHLIGHT_SELECTED]: () => {
        unityShortcuts.toggleHighlightSelected()
      },
      [SHORTCUT.DIMENSIONS]: () => {
        unityShortcuts.toggleDimensions()
      },
      [SHORTCUT.LIGHTS]: () => {
        unityShortcuts.toggleLightsVisibility()
      },
      [SHORTCUT.CAMERA_SAFE_FRAME]: () => {
        unityShortcuts.toggleCameraSafeMode()
      },
      
      [SHORTCUT.FOCUS_EVERYTHING]: () => {
        unityShortcuts.focusEverything();
      },
      
    }

    const otherShortcuts = {
      [KEYBOARD_EVENT.ESC_PRESSED]: () => {
        PubSub.publish(KEYBOARD_EVENT.ESC_PRESSED);
        unityContex.metadataModule.CloseFocusObjectReceiver();
      }
    }

    const shortcuts = {
      ...basicShortcuts,
      ...viewportShortcuts,
      ...editShortcuts,
      ...toolsShortcuts,
      ...selectionShortcuts,
      ...viewShortcuts
    }

    Object.entries(shortcuts).forEach(([name, callback]) => {
      if (typeof callback === 'function') {
        hotkeys(getShortcut(name), (e, handler)  => {
          e.preventDefault();
          callback(e, handler)
        });
      } else {
        const { keyUp, keyDown } = callback;
        hotkeys(getShortcut(name), { keyup: true }, (e, handler)  => {
          if (e.type === 'keydown', name) {
            keyDown(e, handler)
          }

          if (e.type === 'keyup') {
            keyUp(e, handler)
          }
        });
      }
    });

    Object.entries(otherShortcuts).forEach(([eventName, callback]) => {
      if (eventName === KEYBOARD_EVENT.ESC_PRESSED) {
        hotkeys('esc', (e, handler) => {
          callback(e, handler)
        })
      }
    })


    hotkeys('*', { keyup: true }, function(e) {
      // handle shortkeys with mouse combinations
      unityContex.shortcutsModule.removeSelection(false);
      unityContex.shortcutsModule.addSelection(false);
      unityContex.shortcutsModule.SingleObjectSelectionReceiver(false);
      unityShortcuts.snapObject(false)
      
      window.hotkeys = hotkeys

      const pressedKeys = hotkeys.getPressedKeyCodes();
      const onlyOnePressed = pressedKeys.length === 1;
      const onlyTwoPressed = pressedKeys.length === 2;
      const { altKey, ctrlKey, metaKey, shiftKey } = hotkeys.modifierMap;
      const ctrlOrCommand = (pressedKeys.includes(ctrlKey) || pressedKeys.includes(metaKey));
      const altPressed = pressedKeys.includes(altKey)
      const shiftPressed = pressedKeys.includes(shiftKey)
      const controlKeyList = ['Meta', 'Ctrl', 'Control'];

      if (e.key.toLowerCase() === 's' && onlyOnePressed) {
        unityShortcuts.snapObject(true)
      }

      if (altPressed && onlyOnePressed && e.key === 'Alt') {
        unityContex.shortcutsModule.removeSelection(true);
      }

      if (shiftPressed && onlyOnePressed && e.key === 'Shift') {
        unityContex.shortcutsModule.addSelection(true);
      }

      if (ctrlOrCommand && onlyOnePressed && controlKeyList.includes(e.key)) {
        unityContex.shortcutsModule.SingleObjectSelectionReceiver(true);
      }

      if (
        ctrlOrCommand &&
        altPressed &&
        onlyTwoPressed && 
        [...controlKeyList, 'Alt'].includes(e.key)
      ) {
        unityContex.shortcutsModule.SingleObjectSelectionReceiver(true);
        unityContex.shortcutsModule.removeSelection(true);
      }

      if (
        ctrlOrCommand &&
        shiftPressed &&
        onlyTwoPressed && 
        [...controlKeyList, 'Shift'].includes(e.key)
      ) {
        unityContex.shortcutsModule.SingleObjectSelectionReceiver(true);
        unityContex.shortcutsModule.addSelection(true);
      }

    });

    return () => {
      Object.keys(shortcuts).forEach((name) => {
        hotkeys.unbind(getShortcut(name));
      })
      hotkeys.unbind('*')
    }
  }, []);
};
