import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { cameraSettingsSelector } from "../../redux/slicers/camera/cameraSettings"

export const SafeCameraFrame =  (props) => {
    
    const { 
        globalWidth, 
        globalHeight, 
        canvasFrameWidth, 
        canvasFrameHeight,
        globalSafeCameraFlag,
    } = useSelector(cameraSettingsSelector)

    const [width, setWidth] = useState(100)
    const [height, setHeight] = useState(100)

    const container = {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: "2px",
        pointerEvents: 'none',
        // backgroundColor: "rgba(0,0,0,0.5)"
    }
    
    const innerContainer = {
        position: "absolute",
        display: "block",
        // width: `${width}px`,
        // height: `${height}px`,
        width: `${canvasFrameWidth}px`,
        height: `${canvasFrameHeight}px`,
        backgroundColor: "transparent",
        borderTop: `${(canvasFrameHeight - height) / 2}px solid rgba(0,0,0,0.5)`,
        borderLeft: `${(canvasFrameWidth - width) / 2}px solid rgba(0,0,0,0.5)`,
        borderBottom: `${(canvasFrameHeight - height) / 2}px solid rgba(0,0,0,0.5)`,
        borderRight: `${(canvasFrameWidth - width) / 2}px solid rgba(0,0,0,0.5)`
        // backgroundColor: "#ffff00",
        // border: "2px solid #ff0000"
    }

    // const canvasRatio = canvasFrameWidth === 0 || canvasFrameHeight === 0 ? 1 : Number.parseFloat((canvasFrameWidth/canvasFrameHeight).toFixed(2));
    // const previewRatio = globalWidth === 0 || globalHeight === 0 ? 1 : Number.parseFloat((globalWidth/globalHeight).toFixed(2));

    useEffect(() => {

        const canvasRatio = canvasFrameWidth/canvasFrameHeight
        const globalRatio = globalWidth/globalHeight
        const previewRatio = width/height

        if(globalRatio === canvasRatio){
            
            setWidth(canvasFrameWidth)
            setHeight(canvasFrameWidth)

        }else if(globalRatio > canvasRatio){

            setWidth(canvasFrameWidth)
            setHeight(globalHeight * (canvasFrameWidth / globalWidth))

        }else if(globalRatio < canvasRatio){

            setWidth(globalWidth * (canvasFrameHeight / globalHeight))
            setHeight(canvasFrameHeight)

        }

        console.log("****************")
        console.log(`safe : canvasRatio: ${canvasRatio} && previewRatio: ${previewRatio} && globalRatio: ${globalRatio}`)
        console.log(`safe : width: ${width} && height: ${height}`)
        console.log(`safe : globalWidth: ${globalWidth} && globalHeight: ${globalHeight}`)
        console.log(`safe : canvasFrameWidth: ${canvasFrameWidth} && canvasFrameHeight: ${canvasFrameHeight}`)
    }, [width, height, globalWidth, globalHeight, canvasFrameWidth, canvasFrameHeight])

    if (!globalSafeCameraFlag) return null;

    return (
        <div style={container}>            
            <div style={innerContainer}></div>
        </div>
    );
}

