/* eslint-disable no-debugger */
import cx from 'classnames';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MetadataForm } from './MetadataForm';
import { MetadataItem } from './MetadataItem';
import { useUnityMetadata } from './useUnityMetadata';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { changeMetadataOrder, selectAddMetadataObjectName } from '../../../../redux/slicers/admin/curatorMetadataSlicer';
import { useUnityContext } from '../../../container/unityContainer';
import styles from './MetadataSection.module.scss';

export const MetadataSection = memo(({ active }) => {
  // const [addFormVisible, setAddFormVisible] = useState(false);
  const objectName = useSelector(selectAddMetadataObjectName);

  const { metadata, addMetadata, onAddIntent, updateMetadata, deleteMetadata, selectMetadata, cancelAddMetadata } =
    useUnityMetadata();

  const handleCancel = () => {
    cancelAddMetadata();
  }

  useEffect(() => {
    if (!active) {
      handleCancel();
    }
  }, [active])

  


  

  const sortedMetadata = useMemo(() => {
    return [...metadata].sort((a, b) => a.sequenceNo - b.sequenceNo);
  }, [metadata]);

  return (
    <div className={`metadata-submenu `}>
      <button className={cx('btn btn-default', styles.button)} onClick={() => onAddIntent()}>
        + Add object in metadata list
      </button>

      {objectName && (
        <MetadataForm onCancel={handleCancel} onSubmit={addMetadata} />
      )}

      <DraggableMetadataList
        metadataList={sortedMetadata}
        onDelete={deleteMetadata}
        onClick={selectMetadata}
        onUpdate={updateMetadata}
      />
    </div>
  );
});

const DraggableMetadataList = ({ metadataList, onDelete, onClick, onUpdate }) => {
  const dispatch = useDispatch();
  const { metadataModule } = useUnityContext();
  const editInProgress = Boolean(metadataList.find((md) => md.edit));

  const handleDragEnd = (result) => {
    const { destination, source, draggableId: metadataTitle } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const metadataItem = metadataList.find(md => md.title === metadataTitle);
    dispatch(changeMetadataOrder({
      title: metadataItem.title,
      destinationIndex: destination.index
    }));

    // unity uses 0 based index isntead of sorting everything by sequenceNo
    metadataModule.UpdateSequenceReceiver(metadataItem.title, destination.index - 1, metadataItem.sequenceNo - 1)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="metadataSectionList" direction="vertical">
        {(dropProvided) => {
          return (
            <div
              id="metadataSectionList"
              {...dropProvided.droppableProps}
              ref={dropProvided.innerRef}>
              {metadataList.map((md, idx) => {
                return (
                  <Draggable draggableId={md.title} index={md.sequenceNo} key={md.title}>
                    {(provided) => {
                      return (
                        <div
                          // key={md.id}
                          ref={provided.innerRef}
                          className="py-2 my-2"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          // isDragging={snapshot.isDragging}
                        >
                          <MetadataItem
                            key={md.title}
                            metadataItem={md}
                            onDelete={() => onDelete(md)}
                            onClick={() => onClick(md)}
                            onUpdate={({ name }) => onUpdate(md, name)}
                            disabled={editInProgress && !md.edit}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {dropProvided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};


