import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Container, Row, Col } from 'react-bootstrap';
import { MyDetails, Interests, Notifications, TransactionHistory} from '../common';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// import Notifications from "./notifications";
// import Transactionhistory from "./transactionHistory";
// import Preferences from "./interest";

export default function Settings() {


  return (
      <div className="mySettings">
        <div className="setting-tabs">
          <Tabs id="uncontrolled-tab-example" defaultActiveKey="details">
            <Tab eventKey="details" title="My Details">
              <MyDetails />
            </Tab>
            <Tab eventKey="notification" title="Notifications">
              <Notifications />
            </Tab>
            <Tab eventKey="preference" title="Interests">
              <Interests />
            </Tab>
            <Tab eventKey="transactionHis" title="Transaction History">
              <TransactionHistory/>
            </Tab>
          </Tabs>
        </div>
      </div>
  );
}
