import {createSlice} from "@reduxjs/toolkit";

const login = createSlice({
    name: 'login',
    initialState: {
        carouselList : [],
        userData : {},
        customizeUserData:{},
        emailVerified: false,
        latestTnc: false,
        professionOptions:[],
        industriesOptions:[],
        platformOptions:[],
        industriesPreference:[],
        termsAndCondition:'',
        privacyPolicy:'',
        userPreferenceData:{},
        userVerificationToken:'',
        userVerificationEmail:'',
    },
    reducers: {
        setCarouselList: (state, {payload}) => {
            state.carouselList = payload
        },
        setUserData: (state, {payload}) => {
            state.userData = {...state.userData,...payload}
        },
        setLoggedInResponse: (state, {payload}) => {
            state.loggedInResponse = payload
        },
        setEmailVerified:(state, {payload}) => {
            state.emailVerified = payload
        },
        setLatestTnc:(state, {payload}) => {
            state.latestTnc = payload
        },
        setCustomizeUserData: (state, {payload}) => {
            state.customizeUserData = {...state.customizeUserData,...payload}
        },
        setProfessionOptions: (state, {payload}) => {
            // state.professionOptions = {...state.professionOptions,...payload}
            state.professionOptions = payload
        },
        setIndustriesOptions: (state, {payload}) => {
            state.industriesOptions = payload
        },
        setPlatformOptions: (state, {payload}) => {
            state.platformOptions = payload
        },
        setIndustriesPreference: (state, {payload}) =>{
            state.industriesPreference = payload
        },
        setTermsAndConditions: (state, {payload}) =>{
            state.termsAndCondition = payload
        },
        setPrivacyPolicy: (state, {payload}) =>{
            state.privacyPolicy = payload
        },
        setUserPreferenceData: (state, {payload}) =>{
            state.userPreferenceData = payload
        },
        setUserVerificationToken: (state, {payload}) =>{
            state.userVerificationToken = payload
        },
        setUserVerificationEmail: (state, {payload}) =>{
            state.userVerificationEmail = payload
        },
    }
})

export const {setCarouselList, setUserData, setLoggedInResponse, setEmailVerified, setLatestTnc, setCustomizeUserData, setProfessionOptions, setIndustriesOptions, setPlatformOptions, setIndustriesPreference, setTermsAndConditions, setUserPreferenceData, setPrivacyPolicy,setUserVerificationToken,setUserVerificationEmail} = login.actions;
export const loginSelector = state => state.login;
export const loginReducer = login.reducer;
export default loginReducer