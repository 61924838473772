import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { errorToast } from '../../../../../helpers/toastHelper';
import { makePresetValid, projectCameraAngleSelector, resetImportPressets, selectImportPresets } from '../../../../../redux/slicers/camera/projectCameraAngleSlicer';
import { CameraNameForm } from './CameraNameForm';
import { useImportCameraPresets } from './useImportCameraPresets';

export const ImportCameraPresetsConflicts = () => {
  const dispatch = useDispatch()
  const { importPresets  } = useImportCameraPresets();
  const { getProjectCameraAnglesList } = useSelector(projectCameraAngleSelector);
  const { invalid, valid: validPresets, active } = useSelector(selectImportPresets);
  const prevInvalid = usePrevious(invalid);

  useEffect(() => {
    if (!active) return;

    if (!invalid.length && validPresets.length && prevInvalid.length > 0) {
      // we've just resolved last name conflict
      importPresets(validPresets);
      return;
    }

    if (prevInvalid && prevInvalid.length > invalid.length) {
      errorToast('Another camera name conflict - please rename')
    }

  }, [invalid, validPresets])

  if (!active || invalid.length === 0) {
    return null;
  }

  const activePreset = invalid[0];

  const handleCancel = () => {
    dispatch(resetImportPressets())
  }

  const handleSubmit = ({ name }) => {
    // TODO: check if name is unique
    const findFunc = p => p.cameraName.toLowerCase() === name.trim().toLowerCase();
    const existInValidPresets = validPresets.find(findFunc)
    const existInCameras = getProjectCameraAnglesList.find(findFunc);

    if (existInValidPresets) {
      errorToast('You already trying to add preset with same name - please rename')
      return;
    }

    if (existInCameras) {
      errorToast('Camera name already exist - please rename');
      return;
    }

    dispatch(makePresetValid({
      id: activePreset.id,
      cameraName: name,
    }))
  }


  return (
    <div className="mb-3">
      <CameraNameForm
        key={activePreset.id}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        defaultName={activePreset.cameraName}
      />
    </div>
  );
};
