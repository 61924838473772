import { useDispatch, useSelector } from "react-redux";
import { filterToParams } from "../../../../../hooks/useDataList";
import { curatorActions, curatorSelector } from "../../../../../redux/slicers/admin/curatorSlicer";
import { paginatedProductActions, paginatedProductSelector } from "../../../../../redux/slicers/admin/paginatedProductSlicer";
import { FILTER } from "../../CuratorFilter/CuratorFilter";

export const useProductsFilter = () => {
  const dispatch = useDispatch();
  const filterOpen = useSelector(state => curatorSelector(state).filterOpen) === 'product';

  const { filter, filterValue } = useSelector(paginatedProductSelector);

  const toggleFilterOpen = () => dispatch(curatorActions.toggleFilterOpen('product'));


  const handleFilterChange = (value) => {
    dispatch(paginatedProductActions.toggleFilter(value))
  }

  const filterConfig = [
    {
      items: [
        {
          label: "My favorites",
          value: FILTER.MY_FAVOURITES,
        },
        {
          label: "My 3d models",
          value: FILTER.MY_3D_MODELS,
        },
        // {
        //   label: "Props",
        //   value: FILTER.PROPS,
        // },
      ],
    },
  ]

  const handleFilterClear = () => {
    dispatch(paginatedProductActions.resetFilters())
  }


  return {
    filterOpen,
    filter,
    filterValue,
    toggleFilterOpen,
    handleFilterChange,
    handleFilterClear,
    filterConfig,
    
  }
}