/* eslint-disable no-unreachable */
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deletePresetCameraAngleAPI, importPresetCameraAngleAPI } from '../../../../../api/camera/presetCamera';
import { useUserContext } from '../../../../../contexts/UserContext';
import { useCuratorData } from '../../../../../hooks/useCuratorData';
import { useInfoDialogaActions } from '../../../../../hooks/useInfoDialogaActions';
import { useToggleListItem } from '../../../../../hooks/useToggleListItem';
import { selectCameraPresetAnglesList, setCameraPresetAnglesList } from '../../../../../redux/slicers/camera/cameraAngleSlicer';
import { projectCameraAngleSelector } from '../../../../../redux/slicers/camera/projectCameraAngleSlicer';
import { useUnityContext } from '../../../../container/unityContainer';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import { CuratorSidebarLayout } from '../../CuratorSidebarLayout/CuratorSidebarLayout';
import { PopupButtons } from '../lights/PopupButtons';
import { CameraItem } from './CameraItem';
import { useImportCameraPresets } from './useImportCameraPresets';

export const CameraPresetsPopup = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { currentUser } = useUserContext();
  const { ids: selectedIds, toggleItem } = useToggleListItem({ reset: open });
  const cameraPresetList = useSelector(selectCameraPresetAnglesList);
  const { handleSelectedCamera } = useUnityContext()
  const { openInfoDialog } = useInfoDialogaActions();
  const { importPresets } = useImportCameraPresets();

  if (!open) {
    return null;
  }

  const handleImportClick = () => {
    const selectedPresets = cameraPresetList.filter(c => selectedIds.includes(c.id));
    importPresets(selectedPresets)
    onClose();
  }


  const deletePreset = (preset) => () => {
    openInfoDialog({
      title: "Delete Preset",
      description: `Do you want to delte "${preset.cameraName}?"`,
      cancelButtonText: "No",
      buttonText: "Yes",
      onButtonClick: () => {
        dispatch(deletePresetCameraAngleAPI(preset.id));
        const updatedList = cameraPresetList.filter(p => p.id !== preset.id);
        dispatch(setCameraPresetAnglesList(updatedList))
      },
    });
  }

  return (
    <CuratorSidebarLayout fixed>
      <CuratorSidebarLayout.Inner>
        <CuratorSidebarLayout.FixedHeader>
          <h2 className="mb-0">Camera Preset</h2>
        </CuratorSidebarLayout.FixedHeader>

        <CuratorAccordion hideArrows activeKey="NONE">
          {cameraPresetList?.map((camera) => {
            const belongsToUser = camera.member === currentUser.id;
            const selected = selectedIds.includes(camera.id)

            return (
              <CameraItem
                camera={camera}
                key={camera.id}
                expandable={false}
                selected={selected}
                onDelete={belongsToUser ? deletePreset(camera) : undefined}
                onSelect={() => toggleItem(camera)}
                onClick={() => handleSelectedCamera(camera)}
              />
            );
          })}
        </CuratorAccordion>

        <PopupButtons
          sticky
          className="mt-auto"
          onCancel={onClose}
          submitText="Import"
          submitDisable={selectedIds.length === 0}
          onSubmit={handleImportClick}
        />
      </CuratorSidebarLayout.Inner>
    </CuratorSidebarLayout>
  );
};
