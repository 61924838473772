import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CommonGrid, TopContainer } from '../layouts';
import {
  BigButton,
  TopButtonArea,
  ThumbnailContainer,
  InfiniteScroll,
  ActionFilters,
  GlobalFilter,
  MiniButton,
  AddToCollections,
  SharePopup,
} from '../common';
import _ from 'lodash';
import { FilterContext, UserContext } from '../contexts';
import {
  getCommonMetadata,
  addValueLabelToList,
  constructQueryParams,
  changeFilter,
  setTitle
} from '../helpers/jsHelper';
import { apiErrorHandler } from '../helpers/axiosHelper';
import { apiGetRooms, parseBasePathname } from '../helpers/api';
import { getScreenSettings } from '../helpers/configureHelper';
import { ReactLoader } from '../layouts/Loading';
import constants from '../constants/constants';
import {
  MY_PROJECTS_ROUTE,
  REQUESTS_ROUTE,
  NEW_PROJECTS_ROUTE,
} from '../constants/routePathConstants';
const { PROJECT_TYPE_OPTIONS } = constants;
import { EDIT_ICON, SHARE_ICON, COLLECTION_ICON, DOWNLOAD_ICON } from '../constants/assetConstants';

export default function MyRooms() {
  const navigate = useNavigate();
  const location = useLocation();
  // const { filter } = location.state;
  const routeParams = useParams();
  let roomType; // templates do not have room type while create new project/rooms does
  if (routeParams) {
    roomType = _.startCase(routeParams.id);
  }
  let project_type = _.find(PROJECT_TYPE_OPTIONS, (t) => {
    return t.label === roomType;
  });
  const pathname = parseBasePathname(location);
  let cardType = _.replace(pathname, '/', '');
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && `${currentUser.first_name} ${currentUser.last_name} `;
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sortField, setSortField] = useState();
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState(project_type ? `room_type=${project_type.value}` : '');
  const [total, setTotal] = useState(0);
  const pageSettings = getScreenSettings(cardType);

  const { sortOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter, filterOptions } =
    pageSettings;
  
  const isRooms = cardType === 'rooms';
  const title = isRooms
     ? 'Select a room to start your new project' : _.startCase(cardType);
  
  const browserTabTitle = isRooms ? 'My Rooms' : 'Templates';

  useEffect(() => {
    if (!sortField && defaultSortOption) {
      setSortField(defaultSortOption);
    }
  }, [sortOptions, sortField]);

  
  useEffect(() => {
    setTitle(browserTabTitle);
  }, [browserTabTitle]);

  useEffect(() => {
    return () => {
      filterCtx.setBreadcrumbs(null);
      if (filterCtx) {
        filterCtx.handleFilterOptions(null);
      }
    };
  }, []);

  // useEffect(() => {
  //   refreshList();
  // }, []);

  useEffect(() => {
    let useRouteFiltering = false;
    let routeFilters = [];
    if (roomType) {
      useRouteFiltering = true;
      let project_type = _.find(PROJECT_TYPE_OPTIONS, (t) => {
        return t.label === roomType;
      });
      if (project_type) {
        routeFilters.push({
          label: `room_type`,
          value: project_type.value,
        });
      }
    }
    let params = constructQueryParams(
      sortField,
      useRouteFiltering ? routeFilters : filters,
      pageNumber,
      filterCtx?.globalFilters,
      filterCtx?.teamMembers
    );
    if (params !== query) {
      setQuery(params);
    }
  }, [sortField, filterCtx?.globalFilters, filterCtx?.teamMembers, roomType, pageNumber, filters]);

  useEffect(() => {
    let breadcrumbs = roomType
      ? [
          {
            link: MY_PROJECTS_ROUTE,
            label: 'My projects',
          },
          {
            link: _.replace(NEW_PROJECTS_ROUTE, ':projectId', -1),
            label: 'Create new project',
          },
          {
            link: null,
            label: roomType,
          },
        ]
      : null;
    filterCtx.setBreadcrumbs(breadcrumbs);
  }, [roomType]);

  useEffect(refreshList, [query, filterCtx?.refListsReady]);
  useEffect(() => {
    if (list && list.length > 0) {
      addDataToList();
    }
  }, [pageNumber]);

  useEffect(() => {
    if (location.state?.filter) {
      handleFilter('room_type', location.state?.filter);
    }
  }, []);

  useEffect(() => {
    setPageNumber(1);
    refreshList();
  }, [userCtx.currentUser.organization]);

  // to do error catch handler for all bottom popups
  // testing api without redux dispatch
  async function refreshList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      try {
        const res = await apiGetRooms(query);
        if (res) {
          if (res.results) {
            let newList = _.map(res.results, (r) => {
              r.filteroptions = addValueLabelToList(r.filteroptions);
              r.tags =
                r.tags && r.tags.length > 0
                  ? _.map(r.tags, (t) => {
                      return {
                        label: t,
                        value: t,
                      };
                    })
                  : [];

              return r;
            });
            setTotal(res.count);
            setTotalPages(res.total_pages);
            setList(newList);
          }
          if (res.sidefilter) {
            filterCtx.handleFilterOptions(res.sidefilter);
          }
        }
      } catch {
        apiErrorHandler();
      } finally {
        setLoading(false);
      }
    }
  }

  function handleFilter(field, selection) {
    setPageNumber(1);
    setFilters(changeFilter(filters, field, selection));
  }

  async function addDataToList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      try {
        const res = await apiGetRooms(query + `&page=${pageNumber}`);
        if (res) {
          if (res.results) {
            let newList = _.map(res.results, (r) => {
              r.filteroptions = addValueLabelToList(r.filteroptions);
              r.tags =
                r.tags && r.tags.length > 0
                  ? _.map(r.tags, (t) => {
                      return {
                        label: t,
                        value: t,
                      };
                    })
                  : [];

              return r;
            });
            setTotal(res.count);
            setTotalPages(res.total_pages);
            setList([...list, ...newList]);
          }
          if (res.sidefilter) {
            filterCtx.handleFilterOptions(res.sidefilter);
          }
        }
      } catch {
        apiErrorHandler();
      } finally {
        setLoading(false);
      }
    }
  }

  function handleCardSelect(id, allOrNone = null) {
    let newList = _.map(list, (item) => {
      if (item.id === id) {
        item.selected = item.selected ? false : true;
      }
      if (_.isBoolean(allOrNone)) {
        item.selected = allOrNone;
      }
      return item;
    });
    setList(newList);
  }

  function handleSort({ value }) {
    setPageNumber(1);
    setSortField(value);
  }

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  const { hasData, anySelected, allSelected, selectedList } = getCommonMetadata(
    list,
    pageNumber,
    totalPages
  );

  const hasMore = pageNumber < totalPages;

  if (!filterCtx) return <ReactLoader />;

  return (
    <CommonGrid
      topContainer={
        <TopContainer
          title={
            <>
              {`${title} `}
              {total && <small>({total})</small>}
            </>
          }
          item={cardType}
          loading={loading || !filterCtx?.refListsReady}
          hasData={hasData}
          buttonArea={
            <TopButtonArea
              hasData={hasData}
              item={cardType}
              addNewButtonArea={
                roomType ? null : (
                  <BigButton buttonTitle="Request Room" onClick={() => navigate(REQUESTS_ROUTE)} />
                )
              }
              topButtonList={
                roomType
                  ? null
                  : [
                      {
                        element: AddToCollections,
                        bigButton: true,
                        title: 'Edit',
                        iconSrc: DOWNLOAD_ICON,
                        isPlural: true,
                        item: cardType,
                      },
                      {
                        element: SharePopup,
                        bigButton: true,
                      },
                    ]
              }
              anySelected={anySelected}
              selectedList={selectedList}
              loading={!filterCtx?.refListsReady || loading}
            />
          }
        />
      }
      hasData={hasData}
      list={list}
      filterArea={
        <>
          <ActionFilters
            hasData={hasData}
            sortProps={{
              data: sortOptions,
              onChange: (e) => handleSort(e),
            }}
            filterProps={{
              data: filterOptions,
              onChange: (v) => handleFilter('room_type', v),
            }}
            selectedProps={
              roomType
                ? null
                : {
                    allSelected: allSelected,
                    onSelectAll: (checked) => handleCardSelect(null, checked),
                  }
            }
            showTeamMemberFilter={showTeamMemberFilter}
            loading={loading}
          />
          <GlobalFilter />
        </>
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={!filterCtx?.refListsReady || loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainer
          data={list}
          cardType={cardType}
          selected={selectedList}
          onSelect={handleCardSelect}
          pageSettings={pageSettings}
          clickUrl={`/templates/:id`}
          showFavoriteIcon={showFavoriteIcon}
          cardButtonList={[
            {
              element: MiniButton,
              title: 'Edit',
              iconSrc: EDIT_ICON,
              clickFunction: () => {},
              clickUrl: null,
            },
            { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
            {
              element: AddToCollections,
              miniButton: true,
              iconSrc: COLLECTION_ICON,
              item: cardType,
            },
          ]}
        />
        {hasMore && <div id="whiteShadow" />}
      </InfiniteScroll>
    </CommonGrid>
  );
}
