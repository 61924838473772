import { v4 as uuidv4 } from 'uuid';
import { isEqual, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createPresetCameraAngleAPI } from '../../../../../api/camera/presetCamera';
import {
  createProjectCameraAngleAPI,
  deleteProjectCameraAngleAPI,
  updateProjectCameraAngleAPI,
} from '../../../../../api/camera/projectCamera';
import { useUserContext } from '../../../../../contexts/UserContext';
import { decimals, getLocalStorage, objectToFormData } from '../../../../../helpers/jsHelper';
import { useCuratorData } from '../../../../../hooks/useCuratorData';
import { useInfoDialogaActions } from '../../../../../hooks/useInfoDialogaActions';
import { selectCameraPresetAnglesList } from '../../../../../redux/slicers/camera/cameraAngleSlicer';
import {
  cameraSettingsSelector,
  selectGlobalHeight,
  selectGlobalWidth,
} from '../../../../../redux/slicers/camera/cameraSettings';
import {
  addCameraSuccess,
  projectCameraAngleSelector,
  setProjectCameraAnglesList,
  updateCameraSuccess,
} from '../../../../../redux/slicers/camera/projectCameraAngleSlicer';
import { useUnityContext } from '../../../../container/unityContainer';
import { saveCurrentProjectCameras } from '../../../../../api/curator';
import { errorToast, successToast } from '../../../../../helpers/toastHelper';
import { MAX_RESOLUTION, MIN_RESOLUTION } from '../../../../../config/constant/unityConstants';
import { BLUR_INTENSITY_MAX_VALUE, BLUR_INTENSITY_MIN_VALUE, CAMERA_HEIGHT_MAX_VALUE, CAMERA_HEIGHT_MIN_VALUE, FOV_MAX_VALUE, FOV_MIN_VALUE } from '../../../../../config/constant/projectConstant';

export const useCameraActions = () => {
  const dispatch = useDispatch();
  const { getProjectCameraAnglesList } = useSelector(projectCameraAngleSelector);
  const { type, roomId, isNewProjectFlow, isOpenProjectFlow } = useCuratorData();
  const { handleCreateCamera, unityTigg } = useUnityContext();
  const { openInfoDialog } = useInfoDialogaActions();
  const { currentUser } = useUserContext();
  const cameraPresetList = useSelector(selectCameraPresetAnglesList);
  const globalWidth = useSelector(selectGlobalWidth) || 1920;
  const globalHeight = useSelector(selectGlobalHeight) || 1080;

  const createCamera = async ({ name, onSuccess }) => {
    if (!name || !name.trim()) {
      errorToast('Please enter a camera name.');
      return;
    }

    if (
      getProjectCameraAnglesList?.find(
        (a) => a.cameraName.toLowerCase() === name.trim().toLowerCase()
      )
    ) {
      errorToast('Camera name already exist - please rename');
      return;
    }

    unityTigg();
    handleCreateCamera();
    // TODO: change it and use pubsub here
    const data = JSON.parse(getLocalStorage('CameraDetailsReceiver'));
    const camDetails = { ...data };

    if (isNewProjectFlow) {
      camDetails.room = Number.parseInt(roomId);
    } else {
      camDetails.project = Number.parseInt(roomId);
      camDetails.id = uuidv4();
    }

    camDetails.resoX = globalWidth;
    camDetails.resoY = globalHeight;
    camDetails.fov = parseInt(camDetails.fov);
    camDetails.isCameraCorrection = camDetails.isCameraCorrection;
    camDetails.cameraHeight = decimals(camDetails.cameraHeight, 2);
    camDetails.isdepthOfField = camDetails.isdepthOfField;
    camDetails.blurIntensity = 5;
    camDetails.selectedObjectName = '';
    camDetails.distanceToSet = decimals(camDetails.distanceToSet, 4);
    camDetails.is_lock = false;

    camDetails.focalLength = decimals(camDetails.focalLength, 4);
    camDetails.xTransRot = decimals(camDetails.xTransRot, 4);
    camDetails.yTransRot = decimals(camDetails.yTransRot, 4);
    camDetails.zTransRot = decimals(camDetails.zTransRot, 4);
    camDetails.xPos = decimals(camDetails.xPos, 4);
    camDetails.yPos = decimals(camDetails.yPos, 4);
    camDetails.zPos = decimals(camDetails.zPos, 4);
    camDetails.centerObjectxPos = decimals(camDetails.centerObjectxPos, 4);
    camDetails.centerObjectyPos = decimals(camDetails.centerObjectyPos, 4);
    camDetails.centerObjectzPos = decimals(camDetails.centerObjectzPos, 4);
    camDetails.cameraRotX = camDetails.cameraRotX;
    camDetails.cameraRotZ = camDetails.cameraRotZ;
    camDetails.lensShiftY = camDetails.lensShiftY;

    camDetails.cameraName = name;

    if (isNewProjectFlow) {
      const formData = objectToFormData(camDetails);
      dispatch(createProjectCameraAngleAPI(formData, { onSuccess }));
    } else {
      dispatch(addCameraSuccess(camDetails));
      successToast('Camera created successfully!');
      onSuccess();

      if (!isOpenProjectFlow) {
        saveCurrentProjectCameras();
      }
    }
  };

  const saveToPresets = ({ camera, onSuccess, onConflict }) => {
    const presetWithSameName = cameraPresetList.find(
      (p) => p.cameraName.toLowerCase() === camera.cameraName.toLowerCase()
    );

    if (presetWithSameName) {
      errorToast('Camera name already exist - please rename');
      onConflict?.();
      return;
    }

    const cameraCopy = { 
      ...camera,
      member: currentUser.id,
      isdepthOfField: false,
      selectedObjectName: '',
    };
    delete cameraCopy.id;
    delete cameraCopy.project;
    delete cameraCopy.room;
    cameraCopy[type] = roomId,
    
    openInfoDialog({
      title: 'Add to Presets',
      description: `Do you want to add "${cameraCopy.cameraName} to presets?"`,
      cancelButtonText: 'No',
      buttonText: 'Yes',
      onButtonClick: () => {
        const formData = objectToFormData(cameraCopy);
        dispatch(
          createPresetCameraAngleAPI(formData, {
            onSuccess,
          })
        );
      },
    });
  };

  const validateCamera = (camera) => {
    if (typeof camera.resoX !== 'number') {
     errorToast('Camera width is missing') 
     return false;
    }

    if (typeof camera.resoY !== 'number') {
      errorToast('Camera height is missing') 
      return false;
    }

    if (camera.resoX < MIN_RESOLUTION || camera.resoX > MAX_RESOLUTION) {
      errorToast(`Camera width must be in range [${MIN_RESOLUTION}, ${MAX_RESOLUTION}]`) ;
      return;
    }

    if (camera.resoY < MIN_RESOLUTION || camera.resoY > MAX_RESOLUTION) {
      errorToast(`Camera height must be in range [${MIN_RESOLUTION}, ${MAX_RESOLUTION}]`) ;
      return;
    }

    if (typeof camera.fov !== 'number') {
      errorToast('Camera fov is missing') 
      return false;
    }

    if (typeof camera.cameraHeight !== 'number') {
      errorToast('Camera slide is missing') 
      return false;
    }

    if (camera.cameraHeight > CAMERA_HEIGHT_MAX_VALUE || camera.cameraHeight < CAMERA_HEIGHT_MIN_VALUE) {
      errorToast(`Camera slide must be in range [${CAMERA_HEIGHT_MIN_VALUE}, ${CAMERA_HEIGHT_MAX_VALUE}]`) ;
      return;
    }

    if (camera.fov > FOV_MAX_VALUE || camera.fov < FOV_MIN_VALUE) {
      errorToast(`Camera fov must be in range [${FOV_MIN_VALUE}, ${FOV_MAX_VALUE}]`) ;
      return;
    }

    if (camera.isdepthOfField && !camera.selectedObjectName) {
      errorToast('Please select an object for depth of field') 
      return false;
    }

    if (camera.isdepthOfField && typeof camera.blurIntensity === 'string' && camera.blurIntensity.trim() === '') {
      errorToast('Camera blur intensity is missing') 
      return false;
    }

    if (camera.blurIntensity > BLUR_INTENSITY_MAX_VALUE || camera.blurIntensity < BLUR_INTENSITY_MIN_VALUE) {
      errorToast(`Blur intensity must be in range [${BLUR_INTENSITY_MIN_VALUE}, ${BLUR_INTENSITY_MAX_VALUE}]`) ;
      return;
    }

    return true;
  }

  const updateCamera = ({ camera, newData, onSuccess, onValidationError }) => {
    if (isEqual(camera, newData)) {
      onSuccess?.();
      return;
    }

    if (newData.project === null) {
      newData = { ...newData };
      delete newData.project; // backend sends us project as null but not accept it back as null :(
    }

    if (!validateCamera(newData)) {
      onValidationError?.()
      return false;
    }


    if (isNewProjectFlow) {
      const formData = objectToFormData(newData);
      dispatch(updateProjectCameraAngleAPI(camera.id, formData, { onSuccess }));
    } else {
      successToast('Camera updated successfully!', { toastId: `CAMERA_UPDATE_SUCCESS_${newData.id}` });
      dispatch(updateCameraSuccess(newData))
      onSuccess?.();

      if (!isOpenProjectFlow) {
        saveCurrentProjectCameras();
      }
    }
  };

  const deleteCamera = (camera, onSuccess) => {
    openInfoDialog({
      title: 'Delete',
      description: `Do you want to delete "${camera.cameraName}"`,
      cancelButtonText: 'No',
      buttonText: 'Yes',
      onButtonClick: () => {
        dispatch(
          setProjectCameraAnglesList([
            ...getProjectCameraAnglesList?.filter((a) => a.id != camera.id),
          ])
        );

        if (isNewProjectFlow) {
          // we need to delete it from the room. It's deleted from the project with line above and for the project we need to delete it only from the store
          deleteProjectCameraAngleAPI(camera.id);
        }

        if (!isOpenProjectFlow) {
          // update project json
          // updateProjectJson({ action: 'SAVE_CAMERAS' }) // something like this
          saveCurrentProjectCameras();
        }

        successToast('Camera deleted successfully!', {
          toastId: `CAMERA_DELETE_SUCCESS_${camera.id}`,
        });
        onSuccess?.();
      },
    });
  };

  return {
    createCamera,
    deleteCamera,
    saveToPresets,
    updateCamera,
  };
};
