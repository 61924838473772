import { SHORTCUT_TYPE } from "../../../../redux/slicers/admin/shortcutsModuleSlicer";

export const SHORTCUT = {
  // basic
  OPEN_PROJECT: 'OPEN_PROJECT',
  SAVE_PROJECT: 'SAVE_PROJECT',
  SAVE_AS_NEW: 'SAVE_AS_NEW',
  QUIT_PROJECT: 'QUIT_PROJECT',
  UNDO: 'UNDO',
  REDO: 'REDO',
  DELETE: 'DELETE',

  // viewport
  ROTATE_VIEWPORT: 'ROTATE_VIEWPORT',
  PAN_VIEWPORT: 'PAN_VIEWPORT',
  ZOOM_IN_OUT: 'ZOOM_IN_OUT',
  SWITCH_VIEW_2D_3D: 'SWITCH_VIEW_2D_3D',
  TOP_VIEW: 'TOP_VIEW',
  FRONT_VIEW: 'FRONT_VIEW',
  LEFT_VIEW: 'LEFT_VIEW',
  RIGHT_VIEW: 'RIGHT_VIEW',
  BACK_VIEW: 'BACK_VIEW',

  // edit
  EDIT_PROPERTIES: 'EDIT_PROPERTIES',
  CHOOSE_TEXTURE: 'CHOOSE_TEXTURE',
  CHOOSE_MATERIAL: 'CHOOSE_MATERIAL',
  CHOOSE_COLOR: 'CHOOSE_COLOR',
  COPY_MATERIAL: 'COPY_MATERIAL',
  PASTE_MATERIAL: 'PASTE_MATERIAL',
  DUPLICATE_SELECTED: 'DUPLICATE_SELECTED',
  REPLACE_SELECTED_PROP: 'REPLACE_SELECTED_PROP',
  PROJECT_IMAGES: 'PROJECT_IMAGES',
  RENDER_PROJECT: 'RENDER_PROJECT',
  PREVIEW_PROJECT: 'PREVIEW_PROJECT',

  // tools
  STYLE_ONLY: 'STYLE_ONLY',
  MOVE_SELECTED: 'MOVE_SELECTED',
  ROTATE_SELECTED: 'ROTATE_SELECTED',
  SCALE_SELECTED: 'SCALE_SELECTED',
  SNAP_SELECTED: 'SNAP_SELECTED',
  SWITCH_AXIS_GL: 'SWITCH_AXIS_GL',
  ACTIVATE_X_AXIS: 'ACTIVATE_X_AXIS',
  ACTIVATE_Y_AXIS: 'ACTIVATE_Y_AXIS',
  ACTIVATE_Z_AXIS: 'ACTIVATE_Z_AXIS',

  // selection
  SELECT: 'SELECT',
  DESELECT: 'DESELECT',
  SELECT_MULTIPLE: 'SELECT_MULTIPLE',
  OPEN_GROUP: 'OPEN_GROUP',
  SELECT_CHILD: 'SELECT_CHILD',
  DESELECT_CHILD: 'DESELECT_CHILD',
  SELECT_MULTIPLE_CHILD: 'SELECT_MULTIPLE_CHILD',

  // view
  HIGHLIGHT_SELECTED: 'HIGHLIGHT_SELECTED',
  DIMENSIONS: 'DIMENSIONS',
  LIGHTS: 'LIGHTS',
  CAMERA_SAFE_FRAME: 'CAMERA_SAFE_FRAME',
  FOCUS_SELECTED: 'FOCUS_SELECTED',
  FOCUS_EVERYTHING: 'FOCUS_EVERYTHING',
}

export const basicShortcutsMap = {
  [SHORTCUT.OPEN_PROJECT]: {
    type: SHORTCUT_TYPE.BASIC,
    name: 'Open Project',
    keys: ['Ctrl', 'O'],
  },
  [SHORTCUT.SAVE_PROJECT]: {
    type: SHORTCUT_TYPE.BASIC,
    name: 'Save Project',
    keys: ['Ctrl', 'S'],
  },
  [SHORTCUT.SAVE_AS_NEW]: {
    type: SHORTCUT_TYPE.BASIC,
    name: 'Save as new',
    keys: ['Shift', 'Ctrl', 'S'],
  },
  [SHORTCUT.QUIT_PROJECT]: {
    type: SHORTCUT_TYPE.BASIC,
    name: 'Quit Project',
    keys: ['Ctrl', 'Q'],
  },

  [SHORTCUT.UNDO]: {
    type: SHORTCUT_TYPE.BASIC,
    name: 'Undo',
    keys: ['Ctrl', 'Z'],
  },
  [SHORTCUT.REDO]: {
    type: SHORTCUT_TYPE.BASIC,
    name: 'Redo',
    keys: ['Shift', 'Z'],
  },
  [SHORTCUT.DELETE]: {
    type: SHORTCUT_TYPE.BASIC,
    name: 'Delete',
    keys: ['Delete'],
  },

  [SHORTCUT.STYLE_ONLY]: {
    type: SHORTCUT_TYPE.BASIC,
    name: 'Style Only',
    keys: ['Q'],
  }
}

export const viewportShortcutsMap = {
  [SHORTCUT.ROTATE_VIEWPORT]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Rotate viewport',
    keys: ['Click', 'Drag'],
    isHotkey: false,
  },
  [SHORTCUT.PAN_VIEWPORT]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Pan viewport',
    keys: ['Middle Click', 'Drag'],
    isHotkey: false,
  },
  [SHORTCUT.ZOOM_IN_OUT]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Zoom in/out',
    keys: ['Scroll'],
    isHotkey: false,
  },
  [SHORTCUT.FOCUS_SELECTED]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Focus selected',
    keys: ['F'],
  },

  [SHORTCUT.SWITCH_VIEW_2D_3D]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Switch view (2D/3D)',
    keys: ['0'],
  },
  [SHORTCUT.TOP_VIEW]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Top view',
    keys: ['1'],
  },
  [SHORTCUT.FRONT_VIEW]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Front view',
    keys: ['2'],
  },
  [SHORTCUT.LEFT_VIEW]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Left view',
    keys: ['3'],
  },

  [SHORTCUT.RIGHT_VIEW]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Right view',
    keys: ['4'],
  },
  [SHORTCUT.BACK_VIEW]: {
    type: SHORTCUT_TYPE.VIEWPORT,
    name: 'Back view',
    keys: ['5'],
  },
}

export const editShortcutsMap = {
  [SHORTCUT.EDIT_PROPERTIES]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Edit properties',
    keys: ['P'],
  },
  [SHORTCUT.CHOOSE_TEXTURE]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Choose Texture',
    keys: ['T'],
  },
  [SHORTCUT.CHOOSE_MATERIAL]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Choose Material',
    keys: ['M'],
  },
  [SHORTCUT.CHOOSE_COLOR]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Choose Color',
    keys: ['C'],
  },

  [SHORTCUT.COPY_MATERIAL]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Copy material',
    keys: ['Ctrl', 'C'],
  },
  [SHORTCUT.PASTE_MATERIAL]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Paste material',
    keys: ['Ctrl', 'V'],
  },
  [SHORTCUT.DUPLICATE_SELECTED]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Duplicate selected',
    keys: ['Ctrl', 'D'],
  },
  [SHORTCUT.REPLACE_SELECTED_PROP]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Replace selected prop',
    keys: ['Ctrl', 'H'],
  },

  [SHORTCUT.PROJECT_IMAGES]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Project Images',
    keys: ['Ctrl', 'I'],
  },
  [SHORTCUT.RENDER_PROJECT]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Render project',
    keys: ['Ctrl', 'R'],
  },
  [SHORTCUT.PREVIEW_PROJECT]: {
    type: SHORTCUT_TYPE.EDIT,
    name: 'Preview project',
    keys: ['Ctrl', 'P'],
  },
}

export const toolsShortcutsMap = {
  [SHORTCUT.MOVE_SELECTED]: {
    type: SHORTCUT_TYPE.TOOLS,
    name: 'Move selected',
    keys: ['W'],
  },
  [SHORTCUT.ROTATE_SELECTED]: {
    type: SHORTCUT_TYPE.TOOLS,
    name: 'Rotate selected',
    keys: ['E'],
  },
  [SHORTCUT.SCALE_SELECTED]: {
    type: SHORTCUT_TYPE.TOOLS,
    name: 'Scale selected',
    keys: ['R'],
  },

  [SHORTCUT.SNAP_SELECTED]: {
    type: SHORTCUT_TYPE.TOOLS,
    name: 'Snap Selected',
    keys: ['S', 'Hover'], // + mouse move
    isHotkey: false,
  },
  [SHORTCUT.SWITCH_AXIS_GL]: {
    type: SHORTCUT_TYPE.TOOLS,
    name: 'Switch Axis (Global/Local)',
    keys: ['G'],
  },

  [SHORTCUT.ACTIVATE_X_AXIS]: {
    type: SHORTCUT_TYPE.TOOLS,
    name: 'Activate x axis only',
    keys: ['X'],
  },
  [SHORTCUT.ACTIVATE_Y_AXIS]: {
    type: SHORTCUT_TYPE.TOOLS,
    name: 'Activate y axis only',
    keys: ['Y'],
  },
  [SHORTCUT.ACTIVATE_Z_AXIS]: {
    type: SHORTCUT_TYPE.TOOLS,
    name: 'Activate z axis only',
    keys: ['Z'],
  },
}


export const selectionShortcutsMap = {
  [SHORTCUT.SELECT]: {
    type: SHORTCUT_TYPE.SELECTION,
    name: 'Select',
    keys: ['Click'],
    isHotkey: false,
  },
  [SHORTCUT.DESELECT]: {
    type: SHORTCUT_TYPE.SELECTION,
    name: 'Deselect',
    keys: ['Alt', 'Click'], // + click
    isHotkey: false,
  },
  [SHORTCUT.SELECT_MULTIPLE]: {
    type: SHORTCUT_TYPE.SELECTION,
    name: 'Select multiple',
    keys: ['Shift', 'Click'],
  },
  [SHORTCUT.OPEN_GROUP]: {
    type: SHORTCUT_TYPE.SELECTION,
    name: 'Open group',
    keys: ['Double Click'],
  },

  [SHORTCUT.SELECT_CHILD]: {
    type: SHORTCUT_TYPE.SELECTION,
    name: 'Select child',
    keys: ['Ctrl', 'Click'],
  },
  [SHORTCUT.DESELECT_CHILD]: {
    type: SHORTCUT_TYPE.SELECTION,
    name: 'Deselect child',
    keys: ['Alt', 'Ctrl', 'Click'],
  },
  [SHORTCUT.SELECT_MULTIPLE_CHILD]: {
    type: SHORTCUT_TYPE.SELECTION,
    name: 'Select multiple child',
    keys: ['Shift', 'Ctrl', 'Click'],
  },
}

export const viewShortcutsMap = {
  [SHORTCUT.HIGHLIGHT_SELECTED]: {
    type: SHORTCUT_TYPE.VIEW,
    name: <>Highlight selected <small>(On/Off)</small></>,
    keys: ['Alt', 'H'],
  },
  [SHORTCUT.DIMENSIONS]: {
    type: SHORTCUT_TYPE.VIEW,
    name: <>Dimensions <small>(On/Off)</small></>,
    keys: ['Alt', 'D'],
  },
  [SHORTCUT.LIGHTS]: {
    type: SHORTCUT_TYPE.VIEW,
    name: <>Lights <small>(Hide/Unhide)</small></>,
    keys: ['Alt', 'L'],
  },
  [SHORTCUT.CAMERA_SAFE_FRAME]: {
    type: SHORTCUT_TYPE.VIEW,
    name: 'Camera safe frame',
    keys: ['Alt', 'F'],
  },

  [SHORTCUT.FOCUS_EVERYTHING]: {
    type: SHORTCUT_TYPE.VIEW,
    name: 'Focus everything',
    keys: ['Shift', 'F'],
  },
}

export const curatorShortcutsMap = {
  ...basicShortcutsMap,
  ...viewportShortcutsMap,
  ...editShortcutsMap,
  ...toolsShortcutsMap,
  ...selectionShortcutsMap,
  ...viewShortcutsMap,
}

export const curatorShortcutsSections = { 
  [SHORTCUT_TYPE.BASIC]: {
    left: [
      SHORTCUT.OPEN_PROJECT,
      SHORTCUT.SAVE_PROJECT,
      SHORTCUT.SAVE_AS_NEW,
      SHORTCUT.QUIT_PROJECT,
    ],
    middle: [
      SHORTCUT.UNDO,
      SHORTCUT.REDO,
      SHORTCUT.DELETE,
    ],
    right: [
      SHORTCUT.STYLE_ONLY,
    ],
  },
  [SHORTCUT_TYPE.VIEWPORT]: {
    left: [
      SHORTCUT.ROTATE_VIEWPORT,
      SHORTCUT.PAN_VIEWPORT,
      SHORTCUT.ZOOM_IN_OUT,
      SHORTCUT.FOCUS_SELECTED,
    ],
    middle: [
      SHORTCUT.SWITCH_VIEW_2D_3D,
      SHORTCUT.TOP_VIEW,
      SHORTCUT.FRONT_VIEW,
      SHORTCUT.LEFT_VIEW,
    ],
    right: [
      SHORTCUT.RIGHT_VIEW,
      SHORTCUT.BACK_VIEW,
    ],
  },
  [SHORTCUT_TYPE.EDIT]: {
    left: [
      SHORTCUT.EDIT_PROPERTIES,
      SHORTCUT.CHOOSE_TEXTURE,
      SHORTCUT.CHOOSE_MATERIAL,
      SHORTCUT.CHOOSE_COLOR
    ],
    middle: [
      SHORTCUT.COPY_MATERIAL,
      SHORTCUT.PASTE_MATERIAL,
      SHORTCUT.DUPLICATE_SELECTED,
      SHORTCUT.REPLACE_SELECTED_PROP,
    ],
    right: [
      SHORTCUT.PROJECT_IMAGES,
      SHORTCUT.RENDER_PROJECT,
      SHORTCUT.PREVIEW_PROJECT,
    ],
  },
  [SHORTCUT_TYPE.TOOLS]: {
    left: [
      SHORTCUT.STYLE_ONLY,
      SHORTCUT.MOVE_SELECTED,
      SHORTCUT.ROTATE_SELECTED,
      SHORTCUT.SCALE_SELECTED,
    ],
    middle: [
      SHORTCUT.SNAP_SELECTED,
      SHORTCUT.SWITCH_AXIS_GL,
    ],
    right: [
      SHORTCUT.ACTIVATE_X_AXIS,
      SHORTCUT.ACTIVATE_Y_AXIS,
      SHORTCUT.ACTIVATE_Z_AXIS,
    ],
  },
  [SHORTCUT_TYPE.SELECTION]: {
    left: [
      SHORTCUT.SELECT,
      SHORTCUT.DESELECT,
      SHORTCUT.SELECT_MULTIPLE,
      SHORTCUT.OPEN_GROUP,
    ],
    middle: [
      SHORTCUT.SELECT_CHILD,
      SHORTCUT.DESELECT_CHILD,
      SHORTCUT.SELECT_MULTIPLE_CHILD,
    ],
    right: [],
  },
  [SHORTCUT_TYPE.VIEW]: {
    left: [
      SHORTCUT.HIGHLIGHT_SELECTED,
      SHORTCUT.DIMENSIONS,
      SHORTCUT.LIGHTS,
      SHORTCUT.CAMERA_SAFE_FRAME,
    ],
    middle: [
      SHORTCUT.FOCUS_SELECTED,
      SHORTCUT.FOCUS_EVERYTHING,
    ],
    right: [],
  },
}