/* eslint-disable no-debugger */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  curatorLightSelector,
  ENVIRONMENT_LIGHT,
} from '../../../../../redux/slicers/admin/curatorLightSlicer';
import { selectUnityLoaded } from '../../../../../redux/slicers/admin/curatorSlicer';
import { getAppState } from '../../../../../redux/store';
import { useUnityContext } from '../../../../container/unityContainer';

export const useUnityLightInit = () => {
  const unityLoaded = useSelector(selectUnityLoaded);
  const unityContext = useUnityContext();

  useEffect(() => {
    if (unityLoaded) {
      const {
        environmentLightType,
        selectedHDRI,
        hdriRotation,
        hdriIntensity,
        environmentColor,
        environmentColorIntensity,
      } = curatorLightSelector(getAppState());

      if (environmentLightType === ENVIRONMENT_LIGHT.HDRI && selectedHDRI.file) {
        unityContext.lightModule.UpdateEnvironmentSkyboxReceiver({
          url: selectedHDRI.file,
          rotation: hdriRotation,
          intensity: hdriIntensity,
        });
      } else {
        unityContext.lightModule.UpdateEnvironmentColorReceiver(environmentColor);
        unityContext.lightModule.UpdateColorIntensityReceiver(environmentColorIntensity);
      }
    }
  }, [unityLoaded]);
};
