import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, Container, Navbar, Form, Card, Carousel } from 'react-bootstrap';
import { BACK } from '../constants/assetConstants';
import { SIGN_IN_ROUTE, SETTINGS_ROUTE, FORGET_ROUTE_SETTINGS } from '../constants/routePathConstants';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { PublicCarousel } from '../common';
import { apiGetCarouselImages, apiPostForgotPassword } from '../helpers/api';
import { BsExclamationTriangle } from 'react-icons/bs';
import { emailIsValid } from '../helpers/jsHelper';
import { successToast } from '../helpers/toastHelper';
import { UnionExclamation } from '../constants/assetConstants';
import { UserContext } from '../contexts';


export default function ForgotPassword() {
  const userCtx = useContext(UserContext);
  const location = useLocation();
  const fromSettings = location?.pathname === FORGET_ROUTE_SETTINGS;
  const navigate = useNavigate();
  const [carouselList, setCarouselList] = useState([]);
  const [email, setEmail] = useState('');
  const [emailMessage, setEmailMessage] = useState('');


  const backRoute = fromSettings ? SETTINGS_ROUTE : SIGN_IN_ROUTE;

  useEffect(() => {
    apiGetCarouselImages({ name: 'furnish' }, null, 'Unable to get carousel images.').then((res) =>
      setCarouselList(res?.results)
    );
  }, []);

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    if (!e.target.value || !e.target.value.trim()) {
      setEmailMessage('Email is Required');
    } else if (!emailIsValid(e.target.value)) {
      setEmailMessage('Invalid Email');
    } else {
      setEmailMessage('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        email: email.trim(),
      };

      apiPostForgotPassword(payload, true).then((res) => {
        if (res) {
          let { message, status } = res;
          if (status === true) {
            successToast(message, 'Email Sent');
            navigate(backRoute);
          }
        }
      });
    }
  };

  const validate = () => {
    if (!email || !email.trim()) {
      setEmailMessage('Email Field is Required');
      return false;
    } else {
      if (!emailIsValid(email)) {
        setEmailMessage('Invalid Email');
        return false;
      } else {
        setEmailMessage('');
        return true;
      }
    }
  };
  return (
    <div className="vw-100">
      <Row>
        <Col>
          <Navbar expand="lg" className="page-nav publicMenu">
            <Container fluid>
              <Navbar>
                <Navbar.Text>
                  <Link to={-1} className="back-button lf-link d-flex">
                    <img className="lf-link-img" src={BACK} alt="" />
                    Back
                  </Link>
                </Navbar.Text>
              </Navbar>
            </Container>
          </Navbar>
        </Col>
      </Row>

      <div className="publicContent">
        <Container>
          <Row className="d-flex justify-content-center align-items-stretch pt-5">
            <Col xs={6}>
              <Card className="py-5 px-4 h-100 border-0">
                <div className="text-center">
                  <h1 className="m-bottom-1 appH1">Forgot your password?</h1>
                  <h5 className="m-bottom-2 py-0 fw-light appH3">
                    Please enter your information below to create a new one.
                  </h5>
                </div>
                <Form action="" onSubmit={handleSubmit}>
                  <Form.Group className="px-5 py-3">
                    {emailMessage ? (
                      <Form.Label className="text-danger">
                        <small>
                          <img
                            src={UnionExclamation}
                            style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                          />
                          {emailMessage}
                        </small>
                      </Form.Label>
                    ) : (
                      <Form.Label className="title-color float-left">Email</Form.Label>
                    )}
                    <Form.Control
                      type="email"
                      id="form2Example21"
                      name="email"
                      className={emailMessage ? 'input-error' : null}
                      placeholder="Type your email"
                      value={email || ''}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleEmail}
                      onFocus={() => setEmailMessage('')}
                    />
                  </Form.Group>
                  <Form.Group className="d-grid px-5 py-3">
                    <button className="btn btn-warning" type="submit">
                      <span className="btn-font">Resend Password</span>
                    </button>
                  </Form.Group>
                  <p className="text-center text-size-2 title-color">
                    You got it again?{" "}
                    <Link to={backRoute} className="lf-link text-decoration-none">
                      {fromSettings ? 'Back to My Account' : 'Sign in'}
                    </Link>
                  </p>
                </Form>
              </Card>
            </Col>
            <Col>
              <PublicCarousel data={carouselList}/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
