import React, { useEffect, useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import { Modal, Row, Col, DropdownButton } from 'react-bootstrap';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { ADD_CREDITS_ROUTE } from '../config/constant/routePathConstant';
import { INVOICE_ICON, PAYMENT_NOTE_ICON } from '../config/constant/assetConstant';

import { apiGetOrganizationBalance, apiGetTransactionHistory } from '../helpers/api';

import { Download, TopButtonArea, BigButton } from '../common';
import DownloadIcon from '../components/icons/DownloadIcon';
import PlusIcon from '../components/icons/PlusIcon';
import { UserContext } from '../contexts';

const transactionTypeOption = [
  { label: 'Rendered images', value: 'Rendered images' },
  { label: 'Requests', value: 'Requests' },
  { label: 'Credit', value: 'ADD' },
  { label: 'Refund', value: 'Refund' },
];

export default function TransactionHistory() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [startDate, setStartDate] = useState(new Date());
  const [transValue, setTransValue] = useState('');
  const [transOption, setTransOption] = useState('');
  const [transDisplay, setTransDisplay] = useState(false);
  const [emailValue, setEmailValue] = useState('');

  const userCtx = useContext(UserContext);

  // new
  const [balance, setBalance] = useState(0);
  const [borrowedCredits, setBorrowedCredits] = useState(0);
  const [effectiveBalance, setEffectiveBalance] = useState(0);
  const [selectedTransactionType, setSelectedTransactionType] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState('halie@sightsource.net');
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);
  const [transactionHistory, setTransactionHistory] = useState([]);

  useEffect(() => {
    apiGetOrganizationBalance().then((res) => {
      if (res) {
        // let { results } = res;
        let { amount, borrowed_credit, effective_balance } = res;

        if (amount) {
          setBalance(amount);
        }
        if (borrowed_credit) {
          setBorrowedCredits(borrowed_credit);
        }
        if (effective_balance) {
          setEffectiveBalance(effective_balance);
        }
      }
    });

    apiGetTransactionHistory().then((res) => {
      if (res) {
        let { results } = res;
        setTransactionHistory(results);
        // setTransactionHistory(dummyTransactions);
      }
    });
  }, []);

  const handleEmailChange = (e) => {
    // setEmailValue(e.target.value);
  };

  const handleTransaction = (obj) => {
    // setTransOption(obj)
    // setTransValue(obj.value);
    // setTransDisplay(true);
  };

  const handleAddCreditPage = () => {
    // navigate(ADD_CREDITS_ROUTE);
  };

  const handleDownload = () => {};

  const handleAddCreditsClick = () => {
    navigate(ADD_CREDITS_ROUTE);
  };

  // TODO:
  // - infinite scroll?
  // - add filters to headers
  // - finish add credits screen
  // - how to know which details links go to which screens
  // - download?

  return (
    <>
      <div className="transaction-container">
        <div className="header">
          <h1>Your historical </h1>
          <div className="top-buttons">
            <BigButton
              onClick={handleAddCreditsClick}
              buttonTitle={'Add Credits'}
              buttonIcon={PlusIcon}
            />
            <BigButton buttonTitle={'Download'} buttonIcon={DownloadIcon} />
          </div>
        </div>

        <Row>
          <Col className="transaction-top">
            <span>Balance</span>
            <h1>$ {balance}</h1>
          </Col>
          <Col className="transaction-top">
            <span>Borrowed Credits</span>
            <h1>$ {borrowedCredits}</h1>
          </Col>
          <Col className="transaction-top">
            <span>Effective Balance</span>
            <h1>$ {effectiveBalance}</h1>
          </Col>
        </Row>

        {/* <Form.Select onChange={handleTransaction}>
                        <option value="Transaction type 1">
                          Rendered images
                        </option>
                        <option value="Transaction type 2">
                          Requests
                        </option>
                        <option value="Transaction type 3">
                          Credit
                        </option>
                        <option value="Transaction type 4">
                          Refund
                        </option>
                      </Form.Select> */}

        {/* <Select
                        options={transactionTypeOption}
                        value={transOption}
                        isClearable={true}
                        onChange={(transactionTypeOption) => handleTransaction(transactionTypeOption)}
                      /> */}

        <Row>
          <Col>
            <Table striped bordered className="basic-table">
              <thead className="basic-table-head">
                <tr>
                  <th>
                    <div>
                      Transaction Type
                      {/* <Dropdown className="basic-table-dropdown">
                      <Form.Select onChange={handleEmailChange}>
                      <option value="abc@email.com">abc@email.com</option>
                      <option value="xyz@email.com">xyz@email.com</option>
                      <option value="test@email.com">test@email.com</option>
                      </Form.Select>
                    </Dropdown> */}
                      <DropdownButton id="dropdown-basic-button" title="">
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className="basic-table-specification">{selectedTransactionType}</div>
                  </th>
                  <th>
                    <div>Name</div>
                    <div className="basic-table-specification"></div>
                  </th>
                  <th>
                    <div>
                      Email
                      <DropdownButton id="dropdown-basic-button" title="">
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </DropdownButton>
                      {/* <Dropdown className="basic-table-dropdown">
                    <Form.Select onChange={handleEmailChange}>
                    <option value="abc@email.com">abc@email.com</option>
                    <option value="xyz@email.com">xyz@email.com</option>
                    <option value="test@email.com">test@email.com</option>
                    </Form.Select>
                  </Dropdown> */}
                    </div>
                    <div className="basic-table-specification">{selectedEmail}</div>
                  </th>

                  <th>
                    <div>
                      Date&nbsp;Time
                      <DropdownButton id="dropdown-basic-button" title="">
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </DropdownButton>
                      {/* <Dropdown className="basic-table-dropdown">
                    <Form.Select onChange={handleEmailChange}>
                    <option value="abc@email.com">abc@email.com</option>
                    <option value="xyz@email.com">xyz@email.com</option>
                    <option value="test@email.com">test@email.com</option>
                    </Form.Select>
                  </Dropdown> */}
                      {/* <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  /> */}
                    </div>
                    <div className="basic-table-specification">
                      {startDateRange}
                      {endDateRange}
                    </div>
                  </th>
                  <th>
                    <div>Amount</div>
                    <div className="basic-table-specification"></div>
                  </th>
                  <th>
                    <div>Balance</div>
                    <div className="basic-table-specification"></div>
                  </th>
                  <th>
                    <div>Details</div>
                    <div className="basic-table-specification"></div>
                  </th>
                </tr>
              </thead>

              {/* <div className="basic-table-border"> */}
              <tbody>
                {transactionHistory.map((record, index) => {
                  // let date = new Date(d.created_at);
                  // let createdAt = date.toLocaleString('en-GB', {
                  //       day: 'numeric', // numeric, 2-digit
                  //       year: 'numeric', // numeric, 2-digit
                  //       month: 'long', // numeric, 2-digit, long, short, narrow
                  //       hour: 'numeric', // numeric, 2-digit
                  //       minute: 'numeric', // numeric, 2-digit
                  //   })
                  return (
                    <tr key={`transaction-${index}`}>
                      <td className="table-desc">{record.trans_type}</td>
                      <td className="table-desc">{record.name}</td>
                      <td className="table-desc">{record.created_by_email}</td>
                      <td className="table-desc">{record.date}</td>
                      {/* <td className="table-desc">{createdAt.replaceAll(',', '-')}</td> */}
                      <td
                        className="table-desc"
                        style={{ color: record.amount > 0 ? 'black' : 'red' }}>
                        {record.amount}
                      </td>
                      <td
                        className="table-desc"
                        style={{ color: record.remaining_amount < 0 ? 'red' : 'black' }}>
                        {record.remaining_amount}
                      </td>
                      {record.receipt_url ? (
                        <td className="table-desc">
                          {/* <a href={record.receipt_url} target="_blank"><img src={INVOICE_ICON}/></a> */}
                        </td>
                      ) : (
                        <td className="table-desc">
                          <img src={PAYMENT_NOTE_ICON} onClick={handleShow}></img>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
              {/* </div> */}
            </Table>
          </Col>
        </Row>

        {/* </Row> */}
        <Modal show={show} onHide={handleClose} animation={false} className="modal-editpassword">
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src=""></img>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
