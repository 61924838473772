export const TicketIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6573 17.7209H15.3457C18.2994 17.7209 19.1969 16.8234 19.1969 13.8698C19.1969 13.5952 18.9692 13.3674 18.6945 13.3674C18.0516 13.3674 17.5225 12.8383 17.5225 12.1953C17.5225 11.5524 18.0516 11.0233 18.6945 11.0233C18.9692 11.0233 19.1969 10.7955 19.1969 10.5209V9.85116C19.1969 6.89749 18.2994 6 15.3457 6H10.6573H8.64804C5.69436 6 4.79688 6.89749 4.79688 9.85116V10.186C4.79688 10.4607 5.0246 10.6884 5.2992 10.6884C5.94218 10.6884 6.47129 11.2175 6.47129 11.8605C6.47129 12.5034 5.94218 13.0326 5.2992 13.0326C5.0246 13.0326 4.79688 13.2603 4.79688 13.5349V13.8698C4.79688 16.8234 5.69436 17.7209 8.64804 17.7209H10.6573ZM10.155 16.7163H8.64804C6.29046 16.7163 5.81492 16.2809 5.80153 13.9769C6.75929 13.7492 7.47594 12.8852 7.47594 11.8605C7.47594 10.8357 6.75929 9.97172 5.80153 9.744C5.81492 7.44 6.29046 7.00465 8.64804 7.00465H10.155V8.17674C10.155 8.45135 10.3827 8.67907 10.6573 8.67907C10.9319 8.67907 11.1597 8.45135 11.1597 8.17674V7.00465H15.3457C17.7435 7.00465 18.1922 7.46009 18.1922 9.85116V10.0789C17.2345 10.3066 16.5178 11.1706 16.5178 12.1953C16.5178 13.2201 17.2278 14.0841 18.1855 14.3118C18.1252 16.3211 17.5827 16.7163 15.3457 16.7163H11.1597V15.5442C11.1597 15.2696 10.9319 15.0419 10.6573 15.0419C10.3827 15.0419 10.155 15.2696 10.155 15.5442V16.7163ZM10.6573 13.5886C10.3827 13.5886 10.155 13.3608 10.155 13.0862V10.6282C10.155 10.3536 10.3827 10.1259 10.6573 10.1259C10.9319 10.1259 11.1597 10.3536 11.1597 10.6282V13.0862C11.1597 13.3675 10.9319 13.5886 10.6573 13.5886Z"
        fill="currentColor"
      />
    </svg>
  );
};
