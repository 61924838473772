import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import {
  SETTINGS_SECTION,
  updateResolutionPreset,
} from '../../../../../redux/slicers/admin/curatorSlicer';
import Button from '../../../../Button/Button';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import { Lock } from '../../Lock/Lock';
import { useResolutionPresets } from './useResolutionPresets';
import styles from './ResolutionPreset.module.scss';

const ResolutionPreset = memo(({ onSectionToggle }) => {
  const dispatch = useDispatch();
  const { resolutionPresets, handleBlur, handleChange, handleSelect, selectedItem, toggleLock } =
    useResolutionPresets();

  if (!resolutionPresets) return null;

  const handleNameChange = (e) => {
    dispatch(
      updateResolutionPreset({
        id: selectedItem.id,
        name: e.target.value,
      })
    );
  };

  return (
    <CuratorAccordion.Item eventKey={SETTINGS_SECTION.RESOLUTION_PRESETS}>
      <CuratorAccordion.Header onClick={() => onSectionToggle(SETTINGS_SECTION.RESOLUTION_PRESETS)}>
        Resolution Presets
      </CuratorAccordion.Header>
      <CuratorAccordion.Body>
        <div className={`d-flex justify-content-between accordion-btn mb-3 ${styles.presetRow}`}>
          {resolutionPresets.map((item, index) => (
            <Button
              color="default"
              className={styles.presetButton}
              onClick={handleSelect(item)}
              active={item?.isSelected}
              key={index}
            >
              {item?.name}
            </Button>
          ))}
        </div>
        <div className="mb-3">
          <label className="form-label">Preset name</label>
          <input
            type="text"
            className={`form-control ${styles.input}`}
            value={selectedItem.name}
            name="name"
            onChange={handleNameChange}
          />
        </div>
        <div className={`d-flex ${styles.lockInput}`}>
          <div className="resolution-input">
            <label className="form-label">Width (px)</label>
            <input
              type="number"
              className="form-control"
              value={selectedItem.width}
              name="width"
              step="1"
              onChange={handleChange}
            />
          </div>
          <div className="lock-icon d-flex align-items-center justify-content-center">
            <p className="pt-4 m-0">
              <Lock active={selectedItem.lock} onClick={toggleLock} />
            </p>
          </div>
          <div className="resolution-input">
            <label className="form-label">Height (px)</label>
            <input
              type="number"
              className="form-control"
              value={selectedItem.height}
              name="height"
              step="1"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </CuratorAccordion.Body>
    </CuratorAccordion.Item>
  );
});

export default React.memo(ResolutionPreset);
