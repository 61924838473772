import { useState, useEffect, useContext } from 'react';
import { Carousel, Col, Container, Image, Navbar, Row, Card, Form } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { setTitle, emailIsValid } from '../helpers/jsHelper';
import { apiGetCarouselImages, apiPostGoogleLogin, apiPostLogin } from '../helpers/api';
import { PublicCarousel } from '../common';
import { GOOGLE_ICON, LOGO, BULB } from '../constants/assetConstants';
import {
  SIGNUP_ROUTE,
  CUSTOMIZE_EXPERIENCE,
  ADMIN_DASHBOARD,
  FORGET_ROUTE,
} from '../constants/routePathConstants';
import GoogleLogin from 'react-google-login';
import {
  AUTH_TOKEN,
  EMAIL_VERIFIED,
  LATEST_TNC_ACCEPTED,
  REFRESH_TOKEN,
} from '../constants/projectConstants';
import { UnionExclamation } from '../constants/assetConstants';
import { successToast } from '../helpers/toastHelper';
import { UserContext } from '../contexts';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import {
  getLocalStorage,
} from '../helpers/jsHelper';

export default function Login() {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [username, setUsername] = useState(localStorage?.getItem('username'));
  const [password, setPassword] = useState('');
  const [usernameMessage, setUsernameMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [carouselList, setCarouselList] = useState([]);
  const [emailVerified, setEmailVerified] = useState(true);
  const [latestTermsAndConditionsAccepted, setLatestTermsAndConditionsAccepted] = useState(false);

  useEffect(() => {
    setTitle('Login');
    apiGetCarouselImages({ name: 'furnish' }, null, 'Unable to get carousel images.').then((res) =>
      // setCarouselList(res?.data?.images);
      setCarouselList(res?.results)
    );
  }, []);


  const handleShowPassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const usernameChangeOnBlur = (e) => {
    e.preventDefault();
    setUsername(e.target.value);
    if (!e.target.value || !e.target.value.trim()) {
      setUsernameMessage('Email Field Is Required');
    } else {
      emailIsValid(e.target.value) ? setUsernameMessage('') : setUsernameMessage('Invalid Email');
    }
  };

  const passwordChangeOnBlur = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
    let passwordError = '';
    if (!e.target.value || !e.target.value.trim()) {
      setPasswordMessage('Password Field Is Required');
    } else {
      setPasswordMessage('');
    }
  };

  // google login
  const onSuccess = (res) => {
    apiPostGoogleLogin(
      { access_token: res.accessToken },
      false,
      null,
      'Unable to complete google login'
    ).then((res) => {
      if (res) {
        let { access, info, is_active_org, is_email_verified, latest_tnc_accepted, refresh } = res;

        //successToast(message);
        localStorage.setItem('is_active_org', is_active_org);
        localStorage.setItem(EMAIL_VERIFIED, is_email_verified);
        localStorage.setItem(LATEST_TNC_ACCEPTED, latest_tnc_accepted);
        localStorage.setItem(AUTH_TOKEN, access);
        localStorage.setItem(REFRESH_TOKEN, refresh);

        userCtx.setToken(access);
        userCtx.setRefreshToken(refresh);
        userCtx.setEmailVerified(is_email_verified);
        userCtx.setLatestTNCAccepted(latest_tnc_accepted);

        // if no info need to redirect to the user experience page
        // since you could close out of the screen and not enter your user info
        if (info === undefined) {
          navigate(CUSTOMIZE_EXPERIENCE);
        }
        // email is inherently verified through google login
        else if (is_email_verified) {
          if (userCtx && userCtx.onLogin) {
            userCtx.onLogin(info, access, refresh, is_email_verified, latest_tnc_accepted);
            navigate(ADMIN_DASHBOARD);
          }
        }
      }
    });
  };

  // google login
  const onFailure = (res) => {
    console.error(res);
  };

  // normal login
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const payload = {
        email: username,
        password: password,
      };

      apiPostLogin(payload, false)
        .then((res) => {
          if (res) {
            let { data, message, status } = res;
            let { access, is_active_org, is_email_verified, latest_tnc_accepted, refresh, info } =
              data;
            if (status === true) {
              successToast(message);

              localStorage.setItem('is_active_org', is_active_org);
              localStorage.setItem(EMAIL_VERIFIED, is_email_verified);
              localStorage.setItem(LATEST_TNC_ACCEPTED, latest_tnc_accepted);
              localStorage.setItem(AUTH_TOKEN, access);
              localStorage.setItem(REFRESH_TOKEN, refresh);

              // if no info need to redirect to the user experience page
              // since you could close out of the screen and not enter your user info
              if (info === undefined) {
                localStorage.setItem(AUTH_TOKEN, access);
                localStorage.setItem(REFRESH_TOKEN, refresh);
                userCtx.setToken(access);
                userCtx.setRefreshToken(refresh);
                navigate(CUSTOMIZE_EXPERIENCE);
              }
              // if the email is verified and inherently we have a user, redirect to main page. There, must show t&c modal if latest has not been accepted
              else if (is_email_verified) {
                if (userCtx && userCtx.onLogin) {
                  //userCtx.setLatestTNCAccepted(latest_tnc_accepted);
                  userCtx.onLogin(info, access, refresh, is_email_verified, latest_tnc_accepted);
                  navigate(ADMIN_DASHBOARD);
                }
              }
            }
          }
        })
        .catch((error) => {
          // api does not return correct result for if email is not verified
          // if email is not verified, redirect to verify
          if (
            error &&
            error.message &&
            error.message.non_field_errors &&
            error.message.non_field_errors[0]
          ) {
            if (error.message.non_field_errors[0].toUpperCase().includes('VERIFY')) {
              successToast('You must verify your email first');
              // do not have any user info, access token, refresh token, or data about t&c if hit here (because api throws error rather than handling no email verified)
              navigate('/verify', { state: { email: username, verificationToken: null } });
            }
          }
        });
    }
  };

  const validate = () => {
    let usernameValidated = false;
    let passwordValidated = false;
    if (!username || !username.trim()) {
      setUsernameMessage('Email is Required');
    } else {
      setUsernameMessage('');
      usernameValidated = true;
    }

    if (!password || !password.trim()) {
      setPasswordMessage('Password is Required');
    } else {
      setPasswordMessage('');
      passwordValidated = true;
    }

    if (!usernameValidated || !passwordValidated) {
      return false;
    }
    return true;
  };

  // since the "/" route can get here and also via Sign in Page will redirect
  if (getLocalStorage(AUTH_TOKEN) && location?.pathname !== ADMIN_DASHBOARD) {
    window.location.pathname = ADMIN_DASHBOARD
  }
  return (
    <div className="vh-100 vw-100">
      <Navbar expand="lg" className="bg-body" id="publicMenu">
        <Container>
          <div className="w-100 d-flex align-items-center justify-content-between mx-auto py-2">
            <Navbar.Brand>
              <Image src={LOGO} responsive="true" />
            </Navbar.Brand>
            <div className="justify-content-end">
              <Navbar.Text>
                <div className="d-flex align-items-center">
                  <img src={BULB}/>
                  <span className="me-2 fw-normal">Not a member yet?</span>
                  <Link to={SIGNUP_ROUTE} className="text-primary fw-bold link-primary">
                    Register now
                  </Link>
                </div>
              </Navbar.Text>
            </div>
          </div>
        </Container>
      </Navbar>

      <div className="publicContent">
        <Container>
          <Row className="d-flex justify-content-center align-items-stretch pt-5">
            <Col xs={5}>
              <Card className="py-5 px-4 h-100 border-0">
                <div className="text-center">
                  <h1 className="m-bottom-1 appH1">Hey there</h1>
                  <h5 className="m-bottom-2 py-0 fw-light appH3">Welcome back we have missed you</h5>
                </div>
                <Form action="" onSubmit={handleSubmit}>
                  <Form.Group className="px-5 py-3">
                    {usernameMessage ? (
                      <Form.Label className="text-danger">
                        <small>
                          <img
                            src={UnionExclamation}
                            style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                          />
                          {usernameMessage}
                        </small>
                      </Form.Label>
                    ) : (
                      <Form.Label className="title-color float-left">Email</Form.Label>
                    )}
                    <Form.Control
                      type="text"
                      id="form2Example18"
                      name="email"
                      className={usernameMessage ? 'input-error' : null}
                      maxLength={50}
                      onChange={(e) => setUsername(e.target.value)}
                      onBlur={usernameChangeOnBlur}
                      onFocus={() => setUsernameMessage('')}
                      value={username || ''}
                      placeholder="Type your email"
                    />
                  </Form.Group>
                  <Form.Group className="px-5 py-3">
                    <div className="password-field">
                      {passwordMessage ? (
                        <Form.Label className="text-danger">
                          <small>
                            <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                            />
                            {passwordMessage}
                          </small>
                        </Form.Label>
                      ) : (
                        <Form.Label className="title-color float-left">Password</Form.Label>
                      )}
                      <Form.Control
                        type={passwordShown ? 'text' : 'password'}
                        id="form2Example28"
                        name="password"
                        className={passwordMessage ? 'input-error' : null}
                        maxLength={50}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={passwordChangeOnBlur}
                        onFocus={() => setPasswordMessage('')}
                        placeholder="Type your password"
                        value={password || ''}
                      />
                      {passwordShown ? (
                        <RiEyeLine
                          className="eye-icon"
                          onClick={handleShowPassword}
                        />
                      ) : (
                        <RiEyeOffLine
                        className="eye-icon"
                        onClick={handleShowPassword}
                      />
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className="px-5 py-3 d-flex justify-content-end">
                    <Link to={FORGET_ROUTE} className="lf-link float-right text-decoration-none">
                      Forgot password?
                    </Link>
                  </Form.Group>
                  <Form.Group className="d-grid px-5 py-3">
                    <button className="btn btn-warning" type="submit">
                      <span className="btn-font">Sign In</span>
                    </button>
                  </Form.Group>
                  <div>
                    <div className="separator px-5 py-3">
                      <small className="fw-normal">Or continue with</small>
                    </div>
                    <div className="google-login d-flex justify-content-center">
                      <GoogleLogin
                        render={(renderProps) => (
                          <button
                            className="btn bg-body py-3 px-4 shadow"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}>
                              <img src={GOOGLE_ICON} className="mx-auto" />
                          </button>
                        )}
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        // buttonText=""
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                      />
                    </div>
                  </div>
                </Form>
              </Card>
            </Col>
            <Col xs={7}>
              <PublicCarousel data={carouselList}/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
