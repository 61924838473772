/* eslint-disable no-unsafe-optional-chaining */
import { useState, useContext, useEffect } from 'react';
import { CURATOR_MOVE } from '../../../../config/constant/assetConstant';
import { useDispatch, useSelector } from 'react-redux';
import { creatRoomApi, getGlobalTagsApi, getOrganisationsApi } from '../../../../api/curator';
import { useSearchParams, useParams } from 'react-router-dom';
import {
  selectCuratorInitialized,
  setShowProjectModal,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { curatorSelector } from '../../../../redux/slicers/admin/curatorSlicer';
import { warningToast } from '../../../../helpers/toastHelper';
import { saveProjectApi } from '../../../../api/curator';
import { unitycontext } from '../../../../hooks/useUnityContext';
import { Image } from 'react-bootstrap';
import { CreateRoom } from '../components/CreateRoom';
import { getFiltersApi } from '../../../../api/commonApi';
import {
  cameraSettingsSelector,
  setGlobalFov,
  setGlobalHeight,
  setGlobalResolution,
  setGlobalWidth,
} from '../../../../redux/slicers/camera/cameraSettings';
import { RenderPreviewDialog } from '../RenderPreviewsDialog/RenderPreviewDialog';
import { projectCameraAngleSelector } from '../../../../redux/slicers/camera/projectCameraAngleSlicer';
import { FOV_MAX_VALUE, FOV_MIN_VALUE } from '../../../../config/constant/projectConstant';
import { getLocalStorage } from '../../../../helpers/jsHelper';
import { Navigation } from './Navigation';
import { CuratorHeaderActions } from './CuratorHeaderActions';
import { RenderIcon } from '../../../icons/RenderIcon';
import { PreviewIcon } from '../../../icons/PreviewIcon';
import Button from '../../../Button/Button';
import { useRenderPreviewPopup } from '../../../../hooks/useRenderPreviewPopup';
import styles from './CuratorHeader.module.scss';
import { RP_TYPE } from '../../../../config/constant/unityConstants';
import { selectRenderPreviewDetailsDialogOpen } from '../../../../redux/slicers/renders-previews/rendersPreviewsSlicer';

const initialState = {
  name: '',
  project_type: '',
  owner_organization: '',
  organization: [],
  category: [],
  style: [],
  search_tags: [],
  selectedCameras: [],
  is_filmic: 'false',
};

export const CuratorHeader = () => {
  const [createRoom, setCreateRoom] = useState(initialState);
  const [errors, setErrors] = useState([]);
  const { openPopup } = useRenderPreviewPopup();
  const curatorInitialized = useSelector(selectCuratorInitialized);
  const onlyFileMenu = useSelector(selectRenderPreviewDetailsDialogOpen);

  const dispatch = useDispatch();

  // State and methods for Live on app module
  const [modalShow, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(!modalShow);
  const onModalClose = () => {
    setCreateRoom(initialState);
    setErrors([]);
    handleModalShow();
  };

  useEffect(() => {
    dispatch(getOrganisationsApi());
    dispatch(getFiltersApi());
    dispatch(getGlobalTagsApi());
  }, [dispatch]);

  const { handleCameraScreenshot, unityContext } = useContext(unitycontext);

  const { getProjectCameraAnglesList: cameraList = [] } = useSelector(projectCameraAngleSelector);

  const handleChangeValues = (name, value) => {
    setCreateRoom({ ...createRoom, [name]: value });
  };
  const handleChangeCamera = (camera) => {
    const cameras = createRoom.selectedCameras;
    const isPresent = cameras.find((c) => c.id == camera.id);
    if (!isPresent) {
      cameras.push(camera);
      setCreateRoom({ ...createRoom, selectedCameras: cameras });
      return;
    }
    const updatedCameraList = cameras.filter((c) => c.id != camera.id);
    setCreateRoom({ ...createRoom, selectedCameras: updatedCameraList });
  };
  const updateCameraTags = (camera) => {
    const cameras = createRoom.selectedCameras;
    const isPresent = cameras.find((c) => c.id == camera.id);

    if (!isPresent) return;

    const updatedCameraList = cameras.map((c) => (camera.id == c.id ? camera : c));
    setCreateRoom({ ...createRoom, selectedCameras: updatedCameraList });
  };
  const handleCreateRoom = () => {
    // Form validation code start
    const errorList = [];
    if (createRoom.name === '')
      errorList.push({ field: 'name', message: 'Please Enter Scene name' });
    if (createRoom.owner_organization === '')
      errorList.push({
        field: 'owner_organization',
        message: 'Please Enter Scene type',
      });
    if (createRoom.project_type === '')
      errorList.push({
        field: 'project_type',
        message: 'Please Enter Brand Owner',
      });
    if (createRoom.organization.length === 0)
      errorList.push({
        field: 'organization',
        message: 'Please add atleast one Organisation',
      });
    if (createRoom.category.length === 0 && createRoom.style.length === 0)
      errorList.push({
        field: 'filters',
        message: 'Please add either one category or styles',
      });
    if (createRoom.search_tags.length === 0)
      errorList.push({
        field: 'search_tags',
        message: 'Please add atleast one search tag',
      });
    const selectedCameras = createRoom.selectedCameras;
    selectedCameras.forEach((cam) => {
      if (cam.tags === undefined || cam.tags.length === 0) {
        errorList.push({ field: 'camera', message: 'Camera Tags Missing' });
      }
    });
    if (errorList.length > 0) {
      setErrors(errorList);
      return;
    }

    let object = {
      sequence_number: Math.floor(Math.random() * 100000),
      name: createRoom.name,
      project_type: createRoom.project_type.value,
      owner_organization: createRoom.owner_organization.value,
      filters: [
        ...createRoom.category?.map((c) => c.value),
        ...createRoom.style?.map((s) => s.value),
      ],
      tags: createRoom.search_tags?.map((t) => t.label),
      version: 1,
      is_filmic: createRoom.is_filmic,
    };

    let selectedOrganizations = createRoom.organization?.map((o) => o.value);
    if (selectedOrganizations.includes('all')) {
      object.assign_to_all_org = true;
    } else {
      object.organization = selectedOrganizations.filter((organization) => organization !== 'all');
    }

    const file_data = JSON.parse(localStorage.getItem('roomInfo'));
    if (!file_data) {
      alert('file data not available. Please check you unity settings');
      onModalClose();
    }

    if (selectedCameras.length === 0) {
      file_data.cameraInfo = [];
      let json_data = JSON.stringify(file_data);
      const blob = new Blob([json_data], { type: 'application/json' });
      object.room_data = blob;
      object.json_file = blob;

      createRoomFromData(object, () => onModalClose());
    } else {
      file_data.cameraInfo = createRoom.selectedCameras;
      let json_data = JSON.stringify(file_data);
      const blob = new Blob([json_data], { type: 'application/json' });
      object.room_data = blob;
      object.json_file = blob;

      handleCameraScreenshot(cameraList);
      unityContext.on('GetScreenShotForCamera', (camName, imageInfo) => {
        const byteCharacters = window.atob(imageInfo);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });

        object.camera_angle_name = camName;
        object.thumbnail = blob;
      });

      unityContext.on('OnAllScreenShotProcessed', () => {
        createRoomFromData(object, () => onModalClose());
      });
    }
  };

  const createRoomFromData = (data, callback) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const item = data[key];
      if (Array.isArray(item)) {
        item.forEach((value) => formData.append(key, value));
      } else if (item instanceof Blob) {
        formData.append(key, item, `${data['camera_angle_name']}.jpg`);
      } else {
        formData.append(key, item);
      }
    });

    dispatch(creatRoomApi(formData));
    callback();
  };

  return (
    <>
      <div className={`curator-header ${styles.root}`}>
        <nav className="navbar navbar-expand-md navbar-light py-0">
          <div className="container-fluid ps-0">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <div className="menu d-flex justify-content-between w-100 align-items-center">
                <Navigation onLiveOnAppClick={() => handleModalShow()} onlyFileMenu={onlyFileMenu} />
                {!onlyFileMenu && (
                  <div className="right-menu d-flex align-items-center">
                    <CuratorHeaderActions />
                    <div>
                      <Button
                        type="button"
                        color="warning"
                        variant="outline"
                        className="me-2"
                        disabled={!curatorInitialized}
                        onClick={() => openPopup(RP_TYPE.PREVIEW)}
                        iconBefore={<PreviewIcon />}>
                        Preview
                      </Button>
                      <Button
                        type="button"
                        color="warning"
                        disabled={!curatorInitialized}
                        onClick={() => openPopup(RP_TYPE.RENDER)}
                        iconBefore={<RenderIcon />}>
                        Render
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>

        <CreateRoom
          modalProps={{ show: modalShow, onHide: onModalClose }}
          values={createRoom}
          cameraList={cameraList}
          errors={errors}
          onChange={handleChangeValues}
          handleChangeCamera={handleChangeCamera}
          updateCameraTags={updateCameraTags}
          onSubmit={handleCreateRoom}
        />

        <RenderPreviewDialog />
      </div>
    </>
  );
};
