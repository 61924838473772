import {createSlice} from "@reduxjs/toolkit";

const sharedFiles = createSlice({
    name: 'sharedFiles',
    initialState: {
        sharedFilesData : {}
    },
    reducers: {
        setSharedFilesData: (state, {payload}) => {
            state.sharedFilesData = payload
        }
    }
})

export const {setSharedFilesData} = sharedFiles.actions;
export const sharedFilesSelector = state => state.sharedFiles;
export const sharedFilesReducer = sharedFiles.reducer;
export default sharedFilesReducer;