import React, { useState } from 'react';
import { ModalButtonArea } from './';
import { icons } from '../assets';

const { ShareIcon } = icons;

export default function SharePopup(props) {
  const [showNewCollection, setShowNewCollection] = useState(false);

  let title = 'Share files with';

  return (
    <ModalButtonArea
      button={props.button}
      bigButton={props.bigButton}
      miniButton={props.miniButton}
      footerButton={props.footerButton}
      variant={props.disabled ? 'outline-secondary' : 'secondary'}
      buttonIcon={ShareIcon}
      buttonTitle="Share"
      disabled={props.disabled || false}
      title={props.title || title}
      // subtitle={subtitle}
      size="lg"
      body={<>TO DO</>}
      iconSrc={props.iconSrc}
      buttonLabel={props.buttonLabel}
    />
  );
}
