export const QuestionMarkCircleIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9969 12.0008C17.9969 15.3145 15.3106 18.0008 11.9969 18.0008C8.68317 18.0008 5.99688 15.3145 5.99688 12.0008C5.99688 8.68707 8.68317 6.00078 11.9969 6.00078C15.3106 6.00078 17.9969 8.68707 17.9969 12.0008ZM11.9969 19.2008C15.9733 19.2008 19.1969 15.9772 19.1969 12.0008C19.1969 8.02433 15.9733 4.80078 11.9969 4.80078C8.02043 4.80078 4.79688 8.02433 4.79688 12.0008C4.79688 15.9772 8.02043 19.2008 11.9969 19.2008ZM11.1947 13.2377H12.5395C12.543 12.967 12.5799 12.7332 12.6502 12.5363C12.724 12.3395 12.8611 12.1514 13.0615 11.9721C13.2936 11.7541 13.5063 11.5432 13.6996 11.3393C13.893 11.1354 14.0494 10.9156 14.1689 10.6801C14.2885 10.441 14.3482 10.1686 14.3482 9.8627C14.3482 9.42324 14.2551 9.05059 14.0688 8.74473C13.8824 8.43535 13.6135 8.20156 13.2619 8.04336C12.9104 7.88164 12.4885 7.80078 11.9963 7.80078C11.5498 7.80078 11.1473 7.87813 10.7887 8.03281C10.4301 8.18398 10.1436 8.41777 9.9291 8.73418C9.71816 9.04707 9.60742 9.44434 9.59688 9.92598H11.1262C11.1297 9.70801 11.1719 9.53223 11.2527 9.39863C11.3371 9.26504 11.4443 9.1666 11.5744 9.10332C11.7045 9.03652 11.8451 9.00313 11.9963 9.00313C12.1791 9.00313 12.3303 9.03828 12.4498 9.10859C12.5729 9.17891 12.6643 9.28262 12.724 9.41973C12.7873 9.55332 12.8189 9.72207 12.8189 9.92598C12.8189 10.1018 12.7838 10.2617 12.7135 10.4059C12.6432 10.5465 12.5465 10.6836 12.4234 10.8172C12.3004 10.9508 12.1563 11.0914 11.991 11.2391C11.7941 11.4219 11.6359 11.6064 11.5164 11.7928C11.4004 11.9791 11.3178 12.1883 11.2686 12.4203C11.2229 12.6488 11.1982 12.9213 11.1947 13.2377ZM11.2686 14.3082C11.1139 14.4559 11.0365 14.6422 11.0365 14.8672C11.0365 15.0852 11.1139 15.2697 11.2686 15.4209C11.4232 15.5721 11.6307 15.6477 11.8908 15.6477C12.1475 15.6477 12.3531 15.5721 12.5078 15.4209C12.666 15.2697 12.7451 15.0852 12.7451 14.8672C12.7451 14.6422 12.666 14.4559 12.5078 14.3082C12.3531 14.157 12.1475 14.0814 11.8908 14.0814C11.6307 14.0814 11.4232 14.157 11.2686 14.3082Z"
        fill="currentColor"
      />
    </svg>
  );
};
