import { useDataList } from "./useDataList";
import { paginatedMaterialsActions, paginatedMaterialsSelector } from "../redux/slicers/materials/paginatedMaterialSlicer";
import { getMaterialListApi } from "../api/materials";

export const useMaterials = () => {
  const data = useDataList({
    selector: paginatedMaterialsSelector,
    resetAction: paginatedMaterialsActions.resetList,
    getAction: getMaterialListApi,
  });

  return data;
};
