import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { OPEN_PROJECT_ID } from "../config/constant/curatorConstants";
import { selectRoomId } from "../redux/slicers/admin/curatorSlicer";

export const useCuratorData = () => {
  const { type, room_id } = useParams();
  const curatorRoomId = useSelector(selectRoomId)
  const isOpenProjectFlow = room_id === OPEN_PROJECT_ID;
  const isRoom = type === 'room';
  const isNewProjectFlow = type === 'room' && !isOpenProjectFlow;

  return {
    type,
    roomId: type === 'project' ? room_id : curatorRoomId,
    isOpenProjectFlow,
    isRoom,
    isNewProjectFlow,
    isNewProject: isNewProjectFlow,
    projectId: type === 'project' ? room_id : undefined,
  }
}