import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Container, Row, Col, Card } from "react-bootstrap";
import { SLIDER_CLOSE, USER_ICON } from "../../../../../config/constant/assetConstant";
import { useDispatch, useSelector } from "react-redux";
import { setRenderPreviewCurrentCamera, setRenderPreviewExpand, rendersPreviewsExpSelector, selectRenderPreviewDetailsDialogOpen } from '../../../../../redux/slicers/renders-previews/rendersPreviewsSlicer';
import { ImagesViewer } from './ImagesViewer';
import { MY_PROJECTS_ROUTE } from "../../../../../config/constant/routePathConstant";
import { API_ROOT_URL, GET_CAMERA_INFO } from "../../../../../config/constant/apiConstant";
import { AUTH_TOKEN } from "../../../../../config/constant/projectConstant";
import { getLocalStorage } from "../../../../../helpers/jsHelper";
import axios from "axios";
import { setCuratorPanel } from "../../../../../redux/slicers/utilities/utilitiesSlicer";
import { AddToCollections, PreviewUI } from "../../../../../common";
import { useNavigate } from "react-router-dom";
import { apiGetProjects } from "../../../../../helpers/api";
import { useRenderPreviewsActions } from "../../../../../hooks/useRenderPreviewsActions";
import { getCuratorPath, getProjectPath } from "../../../../../helpers/urlHelper";
import styles from './ExpandImages.module.scss';
import { useRenderPreviewPopup } from "../../../../../hooks/useRenderPreviewPopup";
import { RP_TYPE } from "../../../../../config/constant/unityConstants";


export function ExpandImages() {
  const dispatch = useDispatch();
  const { renderPreviewExpand: data } = useSelector(rendersPreviewsExpSelector);
  const { openPopup } = useRenderPreviewPopup();
  const open = useSelector(selectRenderPreviewDetailsDialogOpen)

  const [detailsVisible, setDetailsVisible] = useState(false);
  // const { getProjectCameraAnglesList  } = useSelector(projectCameraAngleSelector);
  const { onDelete, onFavouriteToggle } = useRenderPreviewsActions({ item: data })


  const handleEditClick = async () => {
    const projects = await apiGetProjects(`render_id=${data.id}`)
    location.href = getCuratorPath(projects.results[0].id, 'project')
  }

  const handleRenderClick = () => {
    dispatch(setRenderPreviewExpand({}));
    openPopup(RP_TYPE.RENDER);
  }

  const actions = [
    data.is_main_render && {
      type: "render",
      onClick: handleRenderClick,
    },
    data.is_main_render && {
      type: "edit",
      // to: getCuratorPath({ id: projectId, type: "project" }),
      // target: "_blank",
      onClick: handleEditClick,
    },
    // {
    //   type: "share",
    //   onClick: share,
    // },
    // {
    //   type: "collection",
    //   onClick: addToCollection,
    // },
    {
      type: "favourite",
      active: data.is_favourite,
      onClick: onFavouriteToggle(data)
    },
    {
      type: "fullscreen",
    },
    {
      type: "delete",
      onClick: onDelete
    },
    {
      type: "help",
      onClick: () => alert("info"),
    },
  ].filter(Boolean);


  const updatedAt = useMemo(() => {
    if (!data?.updated_at) return null;
    
    return new Date(data?.updated_at).getTime();
  }, [data?.updated_at])

  if (!open) return null;

  const sidebarCards = data.images?.map((image) => ( {
    id: image.id,
    name: image.angle_name,
    thumbnail: image.thumbnail,
  }))

  const slides = data.images?.map((image) => image.file)


  return (
    <>
      <PreviewUI 
        theme="dark"
        title={data?.name}
        actions={actions}
        onClose={() => dispatch(setRenderPreviewExpand({}))}
        loading={false}
        updatedAt={updatedAt}
        userName="Sasha"
        sidebarCards={sidebarCards}
        slides={slides}
        sidebarProps={{
          className: styles.sidebar,
          classNameFullscreen: styles.sidebarFullscreen
        }}
        previewProps={{
          className: styles.preview,
          classNameFullscreen: styles.previewFullscreen
        }}
      />
      <AddToCollections 
        id={data.id}
        cardType='renders-previews'
      />
    </>
  )
}

