import {createSlice} from "@reduxjs/toolkit";

const trash = createSlice({
    name: 'trash',
    initialState: {
        trashData : {}
    },
    reducers: {
        setTrashData: (state, {payload}) => {
            state.trashData = payload
        }
    }
})

export const {setTrashData} = trash.actions;
export const trashSelector = state => state.trash;
export const trashReducer = trash.reducer;
export default trashReducer;