import { createSlice } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { REVERSE_PROPERTY_MAP, REVERSE_TILING_MAP, SELECTED_OBJECT_COUNT_STR, UNITY_OBJECT_TYPE } from "../../../config/constant/unityConstants";

const initialState = {
  selectedObjectsCountStr: SELECTED_OBJECT_COUNT_STR.NONE,
  unitySelectedObjectInfo: {}, 
  mapImage: {}, // map of [objectId][texture|material|normal ...]
  initialTextureData: null,
  selectedObjectList: [],
  objectPositionAlongAxis: {},
  // objectPositionAlongAxis: {
  //   value: 22,
  //   transformType: 'Move',
  // },
}

const curatorUnityObjectSlicer = createSlice({
  name: "curatorUnityObject",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setBase64Data: (state, { payload }) => {
      const { mapType, image, objectName } = payload;
      const tillingType = REVERSE_TILING_MAP[mapType];

      if (!state.mapImage[objectName]) {
        state.mapImage[objectName] = {}
      };

      state.mapImage[objectName][tillingType] = image;
    },
    setUnitySelectedObjectInfo: (state, { payload }) => {
      const currentObjectName = state.unitySelectedObjectInfo?.raw?.objectName;
      state.unitySelectedObjectInfo = payload;

      if (!payload.raw)  {
        state.initialTextureData = null;
        return;
      };

      if (currentObjectName !== payload.raw.objectName) {
        // it's a new selected object, save texture inital data to use it later
        state.initialTextureData = payload.parsed?.texture;
      }
      

      // remove not needed images on selecting new object
      state.mapImage = Object.entries(state.mapImage).reduce((acc, [key, value]) => {
        if (key === payload.raw.objectName) {
          acc[key] = value;
        }

        return acc;
      }, {})
    },
    setTextureMap: (state, { payload }) => {
        state.unitySelectedObjectInfo.parsed[payload.tilingTypeName].texture = payload.texture;
    },
    updateRawObject: (state, { payload }) => {
      state.unitySelectedObjectInfo.raw = merge(
        {},
        state.unitySelectedObjectInfo.raw,
        payload,
      )
    },
    updateUnityObject: (state, { payload }) => {
        const { glossiness, normal, transparency } = state.unitySelectedObjectInfo.parsed || {};

        console.log('sasha updateUnityObject ', payload)

        state.unitySelectedObjectInfo.parsed = merge(
            {}, 
            state.unitySelectedObjectInfo.parsed,
            payload,
            {
                glossiness: {
                    texture: glossiness?.texture,
                },
                normal: {
                    texture: normal?.texture,
                },
                transparency: {
                    texture: transparency?.texture,
                },

            }
        )
    },
    setSelectedObjectsCountStr: (state, { payload }) => {
      state.selectedObjectsCountStr = payload
    },
    setSelectedObjectList: ( state, { payload }) => {
      state.selectedObjectList = payload;
    },
    setObjectPositionAlongAxis: (state, { payload }) => {
      state.objectPositionAlongAxis = {
        value: payload.value,
        transformType: payload.transformType,
      }
    },
    setObjectPositionAlongAxisValue: (state, { payload }) => {
      state.objectPositionAlongAxis.value = payload;
    },
    resetObjectPositionAlongAxis: (state, { payload }) => {
      state.objectPositionAlongAxis = initialState.objectPositionAlongAxis
    }
  },
});

export const {
  setBase64Data,
  setUnitySelectedObjectInfo,
  updateUnityObject,
  setTextureMap,
  updateRawObject,
  setSelectedObjectsCountStr,
  setSelectedObjectList,
  setObjectPositionAlongAxis,
  resetObjectPositionAlongAxis,
  setObjectPositionAlongAxisValue,
} = curatorUnityObjectSlicer.actions;
export const curatorUnityObjectActions = curatorUnityObjectSlicer.actions;

export const curatorUnityObjectSelector = (state) => state.curatorUnityObject;
export const curatorUnityObjectReducer = curatorUnityObjectSlicer.reducer;
export default curatorUnityObjectReducer;

export const selectUnitySelectedObjectInfo = (state) => state.curatorUnityObject.unitySelectedObjectInfo;
export const selectUnitySelectedObjectInfoRaw = (state) => state.curatorUnityObject.unitySelectedObjectInfo?.raw;
export const selectIsAnyObjectSelected = (state) => state.curatorUnityObject.selectedObjectsCountStr !== SELECTED_OBJECT_COUNT_STR.NONE;
export const selectSelectedObjectName = (state) => state.curatorUnityObject.unitySelectedObjectInfo?.raw?.objectName
export const selectHasSelectedObjectInfo = (state) => Boolean(state.curatorUnityObject.unitySelectedObjectInfo?.raw?.objectName)
export const selectIsStyleableObject = (state) => Boolean(state.curatorUnityObject.unitySelectedObjectInfo?.raw?.objectName)
export const selectSelectedObjectCountStr = (state) => Boolean(state.curatorUnityObject.selectedObjectsCountStr)
export const selectIsOneObjectSelected = (state) => Boolean(state.curatorUnityObject.selectedObjectsCountStr === SELECTED_OBJECT_COUNT_STR.SINGLE)
export const selectCanReplaceProp = (state) => Boolean(state.curatorUnityObject.selectedObjectList.find(o => o.type === UNITY_OBJECT_TYPE.PROP))
export const selectSeclectedObjectList = (state) => state.curatorUnityObject.selectedObjectList;
export const selectReplaceProp = (state) => selectSeclectedObjectList(state)?.[0] || null;
export const selectIsSingleLightObjectSelected = (state) => Boolean(state.curatorUnityObject.selectedObjectList.find(o => o.type === UNITY_OBJECT_TYPE.LIGHT)) 
  && state.curatorUnityObject.selectedObjectList.length === 1;
export const selectParsedObject = (state) => state.curatorUnityObject.unitySelectedObjectInfo.parsed;
export const selectObjectPositionAlongAxis = (state) => state.curatorUnityObject.objectPositionAlongAxis;

export const selectSelectedLightObject = (state) => state.curatorUnityObject.selectedObjectList.find(o => o.type === UNITY_OBJECT_TYPE.LIGHT);

export const selectMainTilingType = (state) => {
  if (!state.curatorUnityObject.unitySelectedObjectInfo?.parsed) return null;

  const { texture, material } = state.curatorUnityObject.unitySelectedObjectInfo?.parsed
  return texture.hideUI ? material : texture;
} 