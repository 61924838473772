import React, { forwardRef, useEffect, useState } from "react";
const Input = forwardRef(
  (
    {
      maskPlaceholder = null,
      mask = null,
      required = false,
      className = null,
      limit = 1000,
      label = null,
      type = "text",
      errorData = [],
      multiline = false,
      name = null,
      defaultData = {},
      onChange = null,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState("");
    const [errorText, setErrorText] = useState("");

    className = className === false ? "" : `form-control ${className}`;
    useEffect(() => {
      if (maskPlaceholder) {
        setValue(maskPlaceholder);
      }
    }, [maskPlaceholder]);
    useEffect(() => {
      if (defaultData?.[name]) {
        setValue(defaultData[name]);
      }
    }, [defaultData]);

    useEffect(() => {
      if (errorData?.[name]) {
        setErrorText(errorData[name]);
      } else {
        setErrorText("");
      }
    }, [errorData]);
    function currencyFormat(num) {
      return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    const handleChange = ({ target }) => {
      const { value: val = "" } = target;
      if (val.length <= limit) {
        setValue(type === "number" ? parseFloat(val) : val);
        onChange?.({ name, value: type === "number" ? val : val });
      }
    };

    return (
      <>
        {label !== false && <label>{label}</label>}
        {multiline ? (
          <textarea
            required={required}
            className={` ${className} ${errorText && "is-invalid"}`}
            name={name}
            value={value}
            onChange={handleChange}
            {...props}
          />
        ) : (
          <>
            <input
              required={required}
              type={type}
              ref={ref}
              className={`${className} ${errorText && "is-invalid"}`}
              name={name}
              value={value}
              onChange={handleChange}
              {...props}
            />
            {errorText && <div className="invalid-feedback">{errorText}</div>}
          </>
        )}
      </>
    );
  }
);

export default Input;
