import cx from 'classnames';
import { forwardRef } from 'react';
import styles from './DropZonePlaceholder.module.scss';

export const DropZonePlaceholder = forwardRef(({ className, title, fileFormats,  inputProps, children, ...rest }, ref) => {

  return (
    <div className={cx(styles.root, className)} {...rest} ref={ref}>
      <div className={styles.title}>
        {title}
        <div className={styles.fileFormats}>({fileFormats})</div>
      </div>
      
      <div className={styles.text}>or drop here </div>

      {inputProps && (
        <input
          {...inputProps}
          className={styles.input}
        />
      )}

      {children}
    </div>
  )
})