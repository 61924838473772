export const VisibilityOffIcon = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5175 4.89336L1.5075 2.88336L2.565 1.81836L15.8625 15.1234L14.805 16.1809L12.24 13.6159C11.235 14.0134 10.14 14.2309 9 14.2309C5.25 14.2309 2.0475 11.8984 0.75 8.60586C1.3275 7.12836 2.295 5.85336 3.5175 4.89336ZM9 4.48086C11.8425 4.48086 14.3775 6.07836 15.615 8.60586C15.1725 9.52086 14.55 10.3084 13.8075 10.9459L14.865 12.0034C15.9075 11.0809 16.7325 9.92586 17.25 8.60586C15.9525 5.31336 12.75 2.98086 9 2.98086C8.0475 2.98086 7.1325 3.13086 6.27 3.40836L7.5075 4.64586C7.995 4.54836 8.49 4.48086 9 4.48086ZM8.1975 5.33586L9.75 6.88836C10.1775 7.07586 10.5225 7.42086 10.71 7.84836L12.2625 9.40086C12.3225 9.14586 12.3675 8.87586 12.3675 8.59836C12.375 6.73836 10.86 5.23086 9 5.23086C8.7225 5.23086 8.46 5.26836 8.1975 5.33586ZM7.1325 8.50836L9.09 10.4659C9.06 10.4734 9.03 10.4809 9 10.4809C7.965 10.4809 7.125 9.64086 7.125 8.60586C7.125 8.58711 7.12687 8.57211 7.12875 8.55711L7.12875 8.5571C7.13063 8.5421 7.1325 8.52711 7.1325 8.50836ZM5.895 7.27086L4.5825 5.95836C3.675 6.64836 2.91 7.53336 2.385 8.60586C3.6225 11.1334 6.1575 12.7309 9 12.7309C9.7125 12.7309 10.4025 12.6259 11.0625 12.4459L10.3275 11.7109C9.9225 11.8834 9.4725 11.9809 9 11.9809C7.14 11.9809 5.625 10.4659 5.625 8.60586C5.625 8.13336 5.7225 7.68336 5.895 7.27086Z"
        fill="currentColor"
      />
    </svg>
  );
};
