import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonGrid, TopContainer } from '../layouts';
import _ from 'lodash';
import {
  TopButtonArea,
  ModalButtonArea,
  Image,
  InfiniteScroll,
  RightSidebar,
  ThumbnailContainer,
  ActionFilters,
  GlobalFilter,
  AddToCollections,
  MiniButton,
  SharePopup,
} from '../common';
import {
  getCommonMetadata,
  constructQueryParams,
  changeFilter,
  setTitle,
} from '../helpers/jsHelper';
import { ReactLoader } from '../layouts/Loading';
import { FilterContext, UserContext } from '../contexts';
import { getScreenSettings } from '../helpers/configureHelper';
import { apiGetProps } from '../helpers/api';
import { Col, Row } from 'react-bootstrap';
import { apiErrorHandler } from '../helpers/axiosHelper';
import { EDIT_ICON, SHARE_ICON, COLLECTION_ICON, DOWNLOAD_ICON } from '../constants/assetConstants';
import vignette1 from '../assets/images/vignette1.png';
import vignette2 from '../assets/images/vignette2.png';
import vignette3 from '../assets/images/vignette3.png';
import lifestyle1 from '../assets/images/lifestyle1.png';
import lifestyle2 from '../assets/images/lifestyle2.png';
import lifestyle3 from '../assets/images/lifestyle3.png';

let title = 'Props';

export default function MyProps() {
  let cardType = 'props';
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editItem, setEditItem] = useState(null);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState([]);
  const [sortField, setSortField] = useState();
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const pageSettings = getScreenSettings(cardType);
  const { sortOptions, filterOptions, showTeamMemberFilter } = pageSettings;
  useEffect(() => {
    setTitle(title);
  }, []);

  useEffect(() => {
    return () => {
      if (filterCtx) {
        filterCtx.handleFilterOptions(null);
      }
    };
  }, []);

  useEffect(() => {
    const last = _.last(filters);
    const newFilters = [
      // {
      //   label: 'is_preview',
      //   value: {
      //     value: last?.value.value == 'preview' ? 'true' : 'false',
      //   },
      // },
    ];
    let params = constructQueryParams(
      sortField,
      newFilters,
      null,
      filterCtx?.globalFilters,
      filterCtx?.teamMembers
    );
    if (params !== query) {
      setQuery(params);
    }
  }, [sortField, filters, filterCtx?.globalFilters, filterCtx?.teamMembers]);

  useEffect(refreshList, [query, filterCtx?.refListsReady]);
  useEffect(addToList, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    refreshList();
  }, [userCtx.currentUser.organization]);

  async function refreshList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      apiGetProps(query)
        .then((res) => {
          if (res) {
            if (res.results) {
              let newList = _.map(res.results, (r) => {
                return r;
              });
              setTotal(res.count);
              setTotalPages(res.total_pages);
              setList(newList);
            }
            if (res.sidefilter) {
              filterCtx.handleFilterOptions(res.sidefilter);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  async function addToList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      apiGetProps(query + `&page=${pageNumber}`)
        .then((res) => {
          if (res) {
            if (res.results) {
              let newList = _.map(res.results, (r) => {
                return r;
              });
              setTotal(res.count);
              setTotalPages(res.total_pages);
              setList([...list, ...newList]);
            }
            if (res.sidefilter) {
              filterCtx.handleFilterOptions(res.sidefilter);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  const onEdit = async (id) => {
    let itemToEdit = _.find(list, (p) => {
      return p.id === id;
    });
    setEditItem(itemToEdit);
  };

  function handleCardSelect(id, allOrNone = null) {
    let newList = _.map(list, (item) => {
      if (item.id === id) {
        item.selected = item.selected ? false : true;
      }
      if (_.isBoolean(allOrNone)) {
        item.selected = allOrNone;
      }
      return item;
    });
    setList(newList);
  }

  function handleSort({ value }) {
    setPageNumber(1);
    setSortField(value);
  }

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber((prev) => prev + 1);
    }
  }

  function handleFilter(field, selection) {
    setPageNumber(1);
    setFilters(changeFilter(filters, field, selection));
  }

  const { hasData, anySelected, allSelected, selectedList } = getCommonMetadata(list);

  const hasMore = pageNumber < totalPages;

  if (!filterCtx) return <ReactLoader />;
  return (
    <CommonGrid
      topContainer={
        <TopContainer
          title={<>My Props {total && <small>({total})</small>}</>}
          item={cardType}
          loading={loading || !filterCtx?.refListsReady}
          hasData={hasData}
          buttonArea={
            <TopButtonArea
              hasData={hasData}
              item={cardType}
              addNewButtonArea={
                <ModalButtonArea
                  bigButton="true"
                  variant="warning"
                  buttonTitle="Request Product"
                  className="new-project"
                  title={<>TITLE</>}
                  size="lg"
                  body={<>BODY</>}
                />
              }
              topButtonList={[
                {
                  element: AddToCollections,
                  bigButton: true,
                  title: 'Edit',
                  iconSrc: DOWNLOAD_ICON,
                  isPlural: true,
                  item: cardType,
                },
                {
                  element: SharePopup,
                  bigButton: true,
                },
              ]}
              anySelected={anySelected}
              selectedList={selectedList}
              loading={loading}
            />
          }
        />
      }
      hasData={hasData}
      list={list}
      filterArea={
        <>
          <ActionFilters
            hasData={hasData}
            filterProps={{
              value: filters?.selected,
              data: filterOptions,
              onChange: (v) => handleFilter(v),
            }}
            sortProps={{
              data: sortOptions,
              onChange: (v) => handleSort(v),
            }}
            selectedProps={{
              allSelected: allSelected,
              onSelectAll: (checked) => handleCardSelect(null, checked),
            }}
            showTeamMemberFilter={showTeamMemberFilter}
            loading={loading}
          />
          <GlobalFilter />
        </>
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainer
          data={list}
          cardType={cardType}
          selected={selectedList}
          onEdit={onEdit}
          onSelect={handleCardSelect}
          pageSettings={pageSettings}
          cardButtonList={[
            {
              element: MiniButton,
              title: 'Edit',
              iconSrc: EDIT_ICON,
              clickFunction: onEdit,
              clickUrl: null,
              onSuccess: refreshList,
            },
            { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
            {
              element: AddToCollections,
              miniButton: true,
              iconSrc: COLLECTION_ICON,
              item: cardType,
            },
          ]}
        />
        {hasMore && <div id="whiteShadow" />}
      </InfiniteScroll>
      <RightSidebar
        initialShowOpen={!_.isNil(editItem)}
        onClose={() => setEditItem(null)}
        title={
          <>
            <div>{editItem?.name}</div>
            <small>
              <span className="text-muted">SKU ID: </span>57898
            </small>
          </>
        }
        body={
          <>
            <Image src={editItem?.thumbnail} />
            <div className="d-flex justify-content-between mt-3">
              <h6>Create Vignette</h6>
              <div role="button" onClick={() => navigate('/templates')}>
                See All
              </div>
            </div>
            <Row>
              {[vignette1, vignette2, vignette3].map((img, idx) => (
                <Col key={idx}>
                  <Image src={img} style={{ borderRadius: '8px' }} />
                </Col>
              ))}
            </Row>
            <div className="d-flex justify-content-between mt-3">
              <h6>Create Lifestyle</h6>
              <div
                role="button"
                onClick={() =>
                  navigate('/templates', { state: { filter: { label: 'Lifestyle', value: '2' } } })
                }>
                See All
              </div>
            </div>

            <Row>
              {[lifestyle1, lifestyle2, lifestyle3].map((img, idx) => (
                <Col key={idx}>
                  <Image src={img} style={{ borderRadius: '8px' }} />
                </Col>
              ))}
            </Row>
          </>
        }
      />
    </CommonGrid>
  );
}
