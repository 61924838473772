import { createSlice } from "@reduxjs/toolkit";

const myprojects = createSlice({
  name: "myprojects",
  initialState: {
    myProjectsData: {},
    data: {},
    list: [],
    page: 1,
    perPage: 20,
    hasData: false,
    loading: false,
    currentRequestId: null,
  },
  reducers: {
    setMyProjectsData: (state, { payload }) => {
      state.myProjectsData = payload;
    },
    fetchMyProjectsListStart: (state, { payload }) => {
      state.loading = true;
      state.currentRequestId = payload.requestId
    },
    fetchMyProjectsListSuccess: (state, { payload }) => {
      if (state.currentRequestId !== payload.requestId) return;

      state.page = payload.page;
      state.data = payload.data;
      state.list = [...state.list, ...payload.data.results]; // TODO: leave only unique items in the array
      state.loading = false;

      if (!state.hasData) {
        // we update has data only once to know that he has some data so if later on filters are applied we still know that data is there
        state.hasData = payload.data.count > 0;
      }
    },
    fetchMyProjectsListFailed: (state) => {
      state.loading = false;
    },
    resetMyProjectsList: (state) => {
      state.data = {};
      state.list = [];
      state.page = 1;
      // state.hasData = false;
    },
    addMyProjectsSuccess: (state, { payload }) => {
      state.list = [payload, ...state.list];
    },
    updateMyProjects: (state, { payload }) => {
      state.list = state.list.map((myProjects) => {
        if (myProjects.id === payload.id) {
          return {
            ...myProjects,
            ...payload,
          };
        }

        return myProjects;
      });
    },
  },
});

export const myProjectsActions = myprojects.actions;
export const { setMyProjectsData } = myprojects.actions;
export const myprojectsSelector = (state) => state.myprojects;
export const myprojectsReducer = myprojects.reducer;
export default myprojectsReducer;
