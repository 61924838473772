export const MoveIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6976 6.79112C14.8143 6.90789 14.8688 7.0558 14.8688 7.20371C14.8688 7.35162 14.8143 7.50732 14.6976 7.61631C14.4718 7.84207 14.0981 7.84207 13.8724 7.61631L12.5805 6.58239V11.3635L17.3616 11.3635L16.3276 10.0716C16.1019 9.84582 16.1019 9.47215 16.3276 9.24639C16.4366 9.12961 16.5923 9.07512 16.7402 9.07512C16.8881 9.07512 17.0361 9.12961 17.1528 9.24639L19.0666 11.5347C19.2924 11.7605 19.2924 12.1342 19.0666 12.3599L17.1528 14.5715C16.9271 14.7973 16.5534 14.7973 16.3276 14.5715C16.1019 14.3458 16.1019 13.9721 16.3276 13.7464L17.3416 12.5312L12.5805 12.5312V17.2336L13.8724 16.1997C14.0981 15.9739 14.4718 15.9739 14.6976 16.1997C14.8143 16.3087 14.8688 16.4644 14.8688 16.6123C14.8688 16.7602 14.8143 16.9081 14.6976 17.0249L12.4092 18.9387C12.1835 19.1644 11.8098 19.1644 11.584 18.9387L9.3724 17.0249C9.14664 16.7991 9.14664 16.4255 9.3724 16.1997C9.59816 15.9739 9.97183 15.9739 10.1976 16.1997L11.4128 17.2137L11.4128 12.5312L6.73025 12.5312L7.74424 13.7464C7.97 13.9721 7.97 14.3458 7.74424 14.5715C7.51848 14.7973 7.14481 14.7973 6.91904 14.5715L5.00526 12.3599C4.7795 12.1342 4.7795 11.7605 5.00526 11.5347L6.91904 9.24638C7.03582 9.12961 7.18373 9.07512 7.33164 9.07512C7.47955 9.07512 7.63525 9.12961 7.74424 9.24638C7.97 9.47215 7.97 9.84582 7.74424 10.0716L6.71032 11.3635L11.4128 11.3635L11.4128 6.60232L10.1976 7.61631C9.97183 7.84207 9.59816 7.84207 9.3724 7.61631C9.14664 7.39055 9.14664 7.01688 9.3724 6.79112L11.584 4.87733C11.8098 4.65157 12.1835 4.65157 12.4092 4.87733L14.6976 6.79112Z"
        fill="currentColor"
      />
    </svg>
  );
};
