// this component is handling the user card for login user
import { useState, useContext } from 'react';
import { Card, Nav, Image } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import { useNavigate } from 'react-router-dom';
import { icons } from '../assets';
import { SIGN_IN_ROUTE } from '../config/constant/routePathConstant';
import { USER_MENU } from '../constants/menu';
import { UserContext } from '../contexts';

const { UserIcon } = icons;

export default function UserCard() {
  const userCtx = useContext(UserContext);
  const currentUser = userCtx?.currentUser?.user;
  const navigate = useNavigate();
  const [cardFlag, setUserCardVisiblity] = useState(false);
  const [themeMode, setThemeMode] = useState(false);

  const handleUserCard = () => setUserCardVisiblity(!cardFlag);

  const onToggle = () => setThemeMode(!themeMode);

  function handleLink(path) {
    navigate(path)
    setUserCardVisiblity(false)
  }

  function handleLogout() {
    userCtx.logout()
    setUserCardVisiblity(false);

  }

  return (
    <div className="user-card">
      <button className="user-card-btn" onClick={handleUserCard}>
        {currentUser && currentUser.profile_image ? <Image src={currentUser.profile_image} /> : <UserIcon />}
      </button>
      <Card className={cardFlag ? '' : 'hide'}>
        <Card.Body>
          <Nav as="ul" className="menu card-arrow">
            {USER_MENU.map((item, i) => {
              return (
                <Nav.Item role="button" as="li" key={item.path} className="pb-1">
                  <a
                    className="nav-link pt-2 pb-2 pe-auto"
                    onClick={() =>
                      item.name === 'Logout' ? handleLogout() : handleLink(item.path)
                    }>
                      <Image src={item.icon} /> 
                    {item.name}
                  </a>
                </Nav.Item>
              );
            })}
          </Nav>
        </Card.Body>
        <Card.Body className="theme-mode">
          <Nav as="ul" className="menu">
            <Nav.Item as="li" className="theme-mode-switcher">
              <span className="theme-label">{themeMode ? 'Dark' : 'Light'} Mode </span>
              <Toggle
                onClick={onToggle}
                on={<span className="text">ON</span>}
                off={<span className="text">OFF</span>}
                width="40px"
                height="20px"
                size="xs"
                offstyle="danger"
                active={themeMode}
              />
            </Nav.Item>
          </Nav>
        </Card.Body>
      </Card>
    </div>
  );
}
