import { PROJECT_TYPE } from "../config/constant/projectConstant"

export const getProjectTypeLabel = (projectType) => {
  const map = {
    [PROJECT_TYPE.ROOM]: 'Room Template',
    [PROJECT_TYPE.LIFESTYLE]: 'Lifestyle',
    [PROJECT_TYPE.SILO]: 'Silo',
    [PROJECT_TYPE.VIGNETTE]: 'Vignette',
    [PROJECT_TYPE.THREESIXTY]: '360 Web Viewer',
    [PROJECT_TYPE.VIDEO]: 'Video',
    [PROJECT_TYPE.CONFIGURATOR]: 'Configurator', 
    [PROJECT_TYPE.AUGMENTED_REALITY]: 'Augumented Reality',
  }
  return map[projectType]
}