import { uniq } from "lodash";
import { apiGetMaterials, apiGetTextures } from "../../../../helpers/api";
import { getMaterial, getTexture, updateDbMaterial, updateDbTexture } from "../../../../helpers/idb";
import { getUnityItemLoader } from "./loadUnityProps";


export const loadUnityMaterialDetails = async ({ materialDetails, unityContext }) => {
  await storeProjectMaterialDetailsInDb(materialDetails);

  const initMaterial = material => unityContext.projectModule.ApplyMaterialReceiver(material)
  const loadMaterialToUnity = getUnityItemLoader(initMaterial)
  const unityDebugArray = await Promise.all(materialDetails.map(async (material) => {
    return parseMaterial(material, unityContext, loadMaterialToUnity)
  }));

  console.log('UNITY_DEBUG ->> MATERIAL LIST', unityDebugArray);
}

export const storeProjectMaterialDetailsInDb = async (materialData) => {
  let textureIds = [];
  let materialIds = [];
  materialData.forEach((md) => {
    const { AOId, diffuseId, metallicId, normalId, roughnessId, transparencyId, loadedMaterialId } =
      md;
    textureIds.push(AOId, diffuseId, metallicId, normalId, roughnessId, transparencyId);
    materialIds.push(loadedMaterialId);
  });

  // fetch textures
  textureIds = uniq(textureIds.filter(Boolean));
  if (textureIds.length > 0) {
    const query = `id=${textureIds.join(',')}`;
    const textures = await apiGetTextures(query);
    // TODO: check if results count is correct
    // store textures data in index db
    await Promise.all(
      textures.results.map((texture) => {
        return updateDbTexture(texture);
      })
    );
  }

  // fetch materials
  materialIds = uniq(materialIds.filter(Boolean));
  if (materialIds.length > 0) {
    const materialsQuery = `id=${materialIds.join(',')}`;
    const materials = await apiGetMaterials(materialsQuery);
    // TODO: check if results count is correct
    // store materials data in index db
    await Promise.all(materials.results.map((texture) => updateDbMaterial(texture)));
  }
};

export const parseMaterial = async (material, unityContext, loadMaterialToUnity) => {
  // this should be always called after material/textures was added to idnexdb
  const { AOId, diffuseId, loadedMaterialId, metallicId, normalId, roughnessId, transparencyId } =
    material;
  const materialCopy = { ...material };

  const keys = [
    'AO',
    'diffuse',
    'metallic',
    'normal',
    'roughness',
    'transparency',
    'loadedMaterial',
  ];
  await Promise.all(
    keys.map(async (key) => {
      const id = material[`${key}Id`];
      if (!id) return;

      if (key === 'loadedMaterial') {
        const dbMaterial = await getMaterial(id);
        materialCopy[`${key}Path`] = dbMaterial.file;
      } else {
        const dbTexture = await getTexture(id);
        materialCopy[`${key}Path`] = dbTexture.file;
      }
    })
  );


  loadMaterialToUnity(materialCopy);

  return materialCopy;
};
