import cx from "classnames";
import { AiFillUnlock } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectSelectedObjectName } from "../../../../../redux/slicers/admin/curatorUnityObjectSlicer";
import { LockIcon } from "../../../../icons/LockIcon";
import { RefreshIcon } from "../../../../icons/RefreshIcon";
import { LockedInput, UndoRedoLockedInput } from "../../LockedInput/LockedInput";
import { useUnityRedux } from "../useUnityRedux";
import { NumberInput } from '../../NumberInput';
import styles from './MapTextureForm.module.scss';
import { UndoRedoInput } from "../../UndoRedoInput";
import { undoRedo } from "../../UndoRedo/UndoRedo";

export const MapTextureForm = ({ data, hideLock, onReset }) => {
  const objectName = useSelector(selectSelectedObjectName);
  const { modifyTiling, onChange, handleWidthHeightChange } = useUnityRedux();

  if (!data) return null;

  const { width, height, x, y, rotation, lock, tilingType } = data;

  const toggleLock = () => {
    const newLock = !lock;
    modifyTiling(tilingType, 'lock', newLock);
    undoRedo.objectProperties.updateObject({
      name: 'lock',
      prevValue: lock,
      nextValue: newLock,
      data: {
        tilingOptionType: tilingType,
      }
    })
  };

  const handleChange = onChange(tilingType);

  const undoRedoProps = {
    undoRedoAction: undoRedo.objectProperties.updateObject,
    undoRedoData: {
      tilingOptionType: tilingType,
    }
  }

  const handleRotationClick = (e) => {
    handleChange(e)
    undoRedo.objectProperties.updateObject({
      name: 'rotation',
      prevValue: rotation,
      nextValue: e.target.value,
      data: {
        tilingOptionType: tilingType,
      }
    })
  }

  return (
    <div className="row input-par">
      <div className={cx('col-12')}>
        <div className={styles.firstRow}>
        {[<UndoRedoLockedInput 
          key={objectName}
          id={tilingType}
          className="mb-3"
          lock={lock}
          onLockToggle={toggleLock}
          value={[
            { name: 'width', value: width, label: 'Width (inches)', defaultValue: 12 },
            { name: 'height', value: height, label: 'Height (inches)', defaultValue: 12 },
          ]}
          onChange={data => handleWidthHeightChange(tilingType, data)}
          normalizeOnBlur
          min={0.01}
          step={0.01}
          withArrows
          undoRedoAction={undoRedo.objectProperties.changeSize}
          undoRedoData={{ 
            tilingOptionType: tilingType,
          }}
        />]}
        {onReset && <RefreshIcon onClick={onReset} className={styles.resetButton} />}
        </div>

        

        <div className="row mb-3   d-flex justify-content-between">
          <div className="texture-input col-5 mb-2">
            <label className="form-label">Position X</label>
            <NumberInput
              className="form-control"
              value={x}
              step={0.01}
              name="x"
              onChange={handleChange}
              withArrows
              InputComponent={UndoRedoInput}
              inputProps={undoRedoProps}
            />
          </div>

          <div className="texture-input col-5 mb-2">
            <label className="form-label">Position Y</label>
            <NumberInput
              className="form-control"
              value={y}
              step={0.01}
              name="y"
              onChange={handleChange}
              withArrows
              InputComponent={UndoRedoInput}
              inputProps={undoRedoProps}
            />
          </div>
        </div>

        <div className="rotation ">
          <span className="head">Rotation</span>
          <div className="measure-input d-flex justify-content-between">
            {[0, 90, 180, 270].map((value, idx) => {
              return (
                <input
                  key={idx}
                  type="button"
                  className={cx("rotaion_btn", value === rotation && "active")}
                  name="rotation"
                  value={value}
                  onClick={handleRotationClick}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
