import { useSelector } from 'react-redux';
import {
  selectCuratorInitialized,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { saveProjectApi } from '../../../../api/curator';
import { NavDropdown, NavItem } from 'react-bootstrap';
import { useUnityContext } from '../../../container/unityContainer';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectIcon from '../../../icons/ProjectIcon';
import { PowerIcon } from '../../../icons/PowerIcon';
import { FileIcon } from '../../../icons/FileIcon';
import { FolderAddIcon } from '../../../icons/FolderAddIcon';
import { FileSaveIcon } from '../../../icons/FileSaveIcon';
import { FileSaveLocal } from '../../../icons/FileSaveLocal';
import styles from './Navigation.module.scss';
import { SaveProjectDialog } from '../SaveProjectDialog/SaveProjectDialog';
import { useSaveProject } from './useSaveProject';
import { INTERNAL_USER_ROLES } from '../../../../config/constant/userConstants';
import { UserContext } from '../../../../contexts';
import { useUserContext } from '../../../../contexts/UserContext';
import FileInput from '../../../FileInput/FileInput';
import { useOpenProjectFromFile } from './useOpenProjectFromFile';
import { useUnityShortcuts } from './useUnityShortcuts';

export const NavigationFile = ({ onLiveOnAppClick }) => {
  const { isInternalUser } = useUserContext();
  const { type } = useParams();
  const curatorInitialized = useSelector(selectCuratorInitialized);

  const {
    openSaveProjectDialog,
    exportProject,
    openSaveProjectDialogAsNew,
  } = useSaveProject();
  const { closeProject } = useUnityShortcuts();

  const { openProjectFromFile } = useOpenProjectFromFile();

  return (
    <>
      <SaveProjectDialog />
      <NavDropdown title="File" className={styles.topMenuItem}>
        <NavDropdown.Item as={FileInput} onFileSelect={openProjectFromFile} disabled={!curatorInitialized} accept=".zip">
          <FileIcon /> Open Project
        </NavDropdown.Item>

        {type !== 'room' && (
          <NavDropdown.Item onClick={openSaveProjectDialog} disabled={!curatorInitialized}>
            <FileSaveIcon /> Save Project
          </NavDropdown.Item>
        )}

        <NavDropdown.Item onClick={openSaveProjectDialogAsNew} disabled={!curatorInitialized}>
          <FileSaveIcon /> Save as New
        </NavDropdown.Item>

        <NavDropdown.Item onClick={exportProject} disabled={!curatorInitialized}>
          <FileSaveLocal /> Save on Local
        </NavDropdown.Item>

        {isInternalUser && (
          <NavDropdown.Item onClick={onLiveOnAppClick} disabled={!curatorInitialized}>
            <FolderAddIcon /> Live on app
          </NavDropdown.Item>
        )}

        <NavDropdown.Item onClick={() => closeProject()}>
          <PowerIcon /> Quit Project
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};
