import React from 'react';
import {
  Col,
  Row,
  Button,
  Card,
} from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import CancelIcon from '../assets/images/settings/cancel.svg';
import _ from 'lodash';
import { Image } from './';

export default function YourInterests(props) {
  const [industriesPreference, setIndustriesPreference] = useState(props.industriesPreference);
  const [objArr, setObjArr] = useState(props.objArr);
  const [selectedProduct, setSelectedProduct] = useState(props.selectedProduct);

  useEffect(() => {
    if (industriesPreference !== props.industriesPreference) {
      setIndustriesPreference(props.industriesPreference);
    }
  }, [props.industriesPreference]);

  useEffect(() => {
    if (selectedProduct !== props.selectedProduct) {
      setSelectedProduct(props.selectedProduct);
    }
  }, [props.selectedProduct]);

  useEffect(() => {
    if (objArr !== props.objArr) {
      setObjArr(props.objArr);
    }
  }, [props.objArr]);

  const renderList = () => {
    return industriesPreference.map((data, k) => {
      if (objArr.length && objArr.indexOf(data.id) > -1) {
        return (
          <Col className="card-col" key={k} xs={12} md={4} lg={3}>
            <Card className="interestImage" id={k}>
              <Card.Img src={data.image_file} className="interestImage" />
              <Card.Body>
                <Card.Text>{data?.name}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      } else if (!objArr.length) {
        return (
          <Col className="card-col" key={k} xs={12} md={4} lg={3}>
            <Card className="interestImage " onClick={() => props.handleProduct(data.id, data.type)}>
              <div className="thumbnail-img">
                <Image
                  src={data.image_file}
                  placeholderWhileLoading
                  ratio={1.259}
                  style={{
                    height: "100%"
                  }}
                />
                </div>
              {/* <Card.Img src={data.image_file} className=""/> */}
              <Card.Body
                className={selectedProduct.indexOf(data.id) > -1 ? 'selected-product' : ''}>
                <Card.Text>{data.name}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      }
    });
  };

  const renderButton = () => {
    {
      return (
      <div style={{marginBottom: "1%"}}>{industriesPreference.map((data, k) => {
        return (
          <Button
            className={selectedProduct.indexOf(data.id) > -1 ? '  interest-btn' : 'hide-btn'}
            onClick={() => props.handleButtonClick(data.id)}
            key={`industryBtn${k}`}>
            {data.name}
            <img src={CancelIcon} style={{ margin: '5px' }}></img>
          </Button>
        );
      })}
      </div>);
    }
  };

  return (
    <>
      <div className="interests-cont">
        <h1>{props.title}</h1>
        {props.error ? <p className='title-error-color'><BsExclamationTriangle style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }} />{props.errorMessage}</p>
        : <p> {props.description}</p>}
        {renderButton()}
        <Row>{renderList()}</Row>
      </div>
    </>
  );
}