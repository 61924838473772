import { getProjectTypeLabel } from "../../helpers/dataHelper";

export const UNITY_REF = {
    loaderUrl: '/Build/LiveFurnishBuild.loader.js',
    dataUrl: '/Build/LiveFurnishBuild.data',
    frameworkUrl: '/Build/LiveFurnishBuild.framework.js',
    codeUrl: '/Build/LiveFurnishBuild.wasm',
    streamingAssetsUrl: "StreamingAssets",
    companyName: "DefaultCompany",
    productName: "Live FurnishV2",
    productVersion: "0.1"
}
export const THEME_LIGHT = 'theme-light'
export const THEME_DARK = 'theme-dark'


export const TITLE_PREFIX = 'Live Furnish'
export const NETWORK_ERROR = 'Network Error';

export const FOV_MIN_VALUE = 4
export const FOV_MAX_VALUE = 60

export const CAMERA_HEIGHT_MIN_VALUE = -5.51
export const CAMERA_HEIGHT_MAX_VALUE = 10.49

export const BLUR_INTENSITY_MIN_VALUE = 0;
export const BLUR_INTENSITY_MAX_VALUE = 10;


export const PROJECT_TYPE_ID = '1';

//ls
export const AUTH_TOKEN = 'token'
export const REFRESH_TOKEN = 'refresh_token'
export const MEMBER_ID = 'id'
export const THEME = 'theme'
export const USER_ID = "user_id";

export const LIGHT_TOAST_OPTIONS = {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
};

export const DARK_TOAST_OPTIONS = {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
};

export const CURATOR_DB = 'curator'
export const STORAGE_DB = '/idbfs'

export const CONVERT_IN_METER = 39.37;
export const CONVERT_IN_INCHES = 39.37;
export const TABLE_HEADER_SCROLL_THRESHOLD = 50;

export const FILTER_OPTIONS = [
    { label: 'Filter by', value: '' },
    { label: "ROOM", value: "1" },
    { label: "LIFESTYLEROOM", value: "2" },
    { label: "SILOROOM", value: "3" },
    { label: "VIGNETTEROOM", value: "4" },
    { label: "THREESIXTYROOM", value: "5" },
    { label: "VIDEOROOM", value: "6" },
    { label: "CONFIGURATORROOM", value: "7" },
    { label: "AUGMENTED_REALITYROOM", value: "8" },
];

export const SORT_BY = [
    { label: "Sort by" },
    { label: "Newest first", value: "-created_at" },
    { label: "Oldest first", value: "created_at" },
    { label: "Name:A-Z", value: "name" },
    { label: "Name :Z-A", value: "-name" },


]

export const PROJECT_TYPE = {
    ROOM: '1',
    LIFESTYLE: '2',
    SILO: '3',
    VIGNETTE: '4',
    THREESIXTY: '5',
    VIDEO: '6',
    CONFIGURATOR: '7',
    AUGMENTED_REALITY: '8',
}

