import activityReducer from "./slicers/activitySlicer";
import sessionReducer from "./slicers/sessionSlicer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slicers/login/loginSlicer";
import sampleReducer from "./slicers/login/sampleSlicer";
import curatorSlicer from "./slicers/admin/curatorSlicer";
import searchReducer from "./slicers/search/searchSlicer";
import settingsReducer from "./slicers/admin/settingsSlicer";
import trashReducer from "./slicers/trash/trashSlicer";
import sharedFilesReducer from "./slicers/shared-files/sharedFilesSlicer";
import myprojectsReducer from "./slicers/my-projects/myProjectsSlicer";
import teamReducer from "./slicers/team/teamSlicer";
import sidebarReducer from "./slicers/admin/sidebarSlicer";
import propsReducer from "./slicers/admin/propsSlicer";
import roomsReducer from "./slicers/admin/roomsSlicer";
import materialReducer from "./slicers/materials/materialsSlicer";
import textureReducer from "./slicers/textures/textureSlicer";
import cameraAngleReducer from "./slicers/camera/cameraAngleSlicer";
import rendersPreviewsExpReducer from "./slicers/renders-previews/rendersPreviewsSlicer";
import projectCameraAngleReducer from "./slicers/camera/projectCameraAngleSlicer";
import myRenderPreviewReducer from "./slicers/render_preview/myRenderPreviewSlice";
import myRenderDownloadReducer from "./slicers/render_preview/myRenderDownloadSlice";
import utilitiesReducer from "./slicers/utilities/utilitiesSlicer";
import cameraSettingsReducer from "./slicers/camera/cameraSettings";
import filterReducer from "./slicers/filters/filterSlicer";
import organisationReducer from "./slicers/organisation/organisationSlicer";
import tagReducer from "./slicers/tags/globalTagSlicer";
import curatorStylesReducer from "./slicers/admin/curatorStylesSlicer";
import infoDialogReducer from "./slicers/infoDialogSlicer";
import myProjectDetailsReducer from "./slicers/myProjectDetails/myProjectDetailsSlicer";
import paginatedTeamReducer from "./slicers/team/paginatedTeamSlicer";
import uiReducer from "./slicers/uiSlicer";
import uploadsReducer from "./slicers/uploads/uploadSlicer";
import paginatedMaterialsReducer from "./slicers/materials/paginatedMaterialSlicer";
import paginatedTexturesReducer from "./slicers/textures/paginatedTexturesSlicer";
import curatorLightReducer from "./slicers/admin/curatorLightSlicer";
import curatorMetadataReducer from "./slicers/admin/curatorMetadataSlicer";
import paginatedLightReducer from "./slicers/admin/paginatedLightSlicer";
import paginatedColorReducer from "./slicers/admin/paginatedColorSlicer";
import curatorUnityObjectReducer from "./slicers/admin/curatorUnityObjectSlicer";
import paginatedProductReducer from "./slicers/admin/paginatedProductSlicer";
import curatorProductReducer from "./slicers/admin/curatorProductSlicer";
import paginatedRenderPreviewsReducer from "./slicers/admin/paginatedRenderPreviewsSlicer";
import curatorMenuPreferencesReducer from "./slicers/admin/curatorMenuPreferencesSlicer";
import renderPreviewPoppupReducer from "./slicers/camera/renderPreviewPoppupSlicer";
import shortcutsModuleReducer from "./slicers/admin/shortcutsModuleSlicer";
import curatorProjectArchiveReducer from "./slicers/admin/curatorProjectArchiveSlicer";
import curatorLoaderReducer from "./slicers/admin/curatorLoaderSlicer";

const rootReducer = combineReducers({
  activity: activityReducer,
  session: sessionReducer,
  login: loginReducer,
  sample: sampleReducer,
  curator: curatorSlicer,
  curatorLoader: curatorLoaderReducer,
  curatorStyles: curatorStylesReducer,
  curatorLight: curatorLightReducer,
  curatorUnityObject: curatorUnityObjectReducer,
  paginatedLight: paginatedLightReducer,
  paginatedProduct: paginatedProductReducer,
  paginatedRenderPreviews: paginatedRenderPreviewsReducer,
  curatorMetadata: curatorMetadataReducer,
  curatorProduct: curatorProductReducer,
  curatorProjectArchive: curatorProjectArchiveReducer,
  search: searchReducer,
  renderPreviewPoppup: renderPreviewPoppupReducer,
  settings: settingsReducer,
  trash: trashReducer,
  sharedFiles: sharedFilesReducer,
  myprojects: myprojectsReducer,
  myProjectDetails: myProjectDetailsReducer,
  team: teamReducer,
  paginatedTeam: paginatedTeamReducer,
  sidebar: sidebarReducer,
  props: propsReducer,
  shortcutsModule: shortcutsModuleReducer,
  rooms: roomsReducer,
  material: materialReducer,
  paginatedMaterials: paginatedMaterialsReducer,
  texture: textureReducer,
  paginatedTextures: paginatedTexturesReducer,
  paginatedColor: paginatedColorReducer,
  cameraAngle: cameraAngleReducer,
  myRenderData: myRenderPreviewReducer,
  myRenderDownload: myRenderDownloadReducer,
  rendersPreviewsExp: rendersPreviewsExpReducer,
  projectCameraAngle: projectCameraAngleReducer,
  filter: filterReducer,
  organisation: organisationReducer,
  tag: tagReducer,
  utilities: utilitiesReducer,
  curatorMenuPreferences: curatorMenuPreferencesReducer,
  cameraSettings: cameraSettingsReducer,
  infoDialog: infoDialogReducer,
  ui: uiReducer,
  uploads: uploadsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["infoDialog/openInfoDialog", 'curatorProjectArchive/setFile'],
        // Ignore these paths in the state
        ignoredPaths: ["infoDialog", 'curatorProjectArchive'],
      },
    }),
});
export default store;


export const getStore = () => store;
export const getAppState = () => store.getState();