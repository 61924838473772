import cx from 'classnames';
import { Accordion } from 'react-bootstrap';
import styles from './CuratorAccordion.module.scss';

export const CuratorAccordion = ({ hideArrows, className, ...rest }) => {
  return (
    <Accordion
      {...rest}
      className={cx(styles.accordion, className, hideArrows && styles.hideArrows)}
    />
  );
};

CuratorAccordion.Header = ({ children, onCheckboxChange, icon, actions, selected, checkboxStyle, ...rest }) => {
  return (
    <Accordion.Header {...rest} className={cx(rest.className, actions && actions.length > 0 && styles.hasActions)}>
      {onCheckboxChange && (
        <>
          {checkboxStyle === 'checkbox' ? (
            <div className={`form-check ${styles.checkbox}`}>
            <input
              className="form-check-input"
              type="checkbox"
              checked={selected}
              onChange={onCheckboxChange}
            />
          </div>
          ) : (
            <div className="form-check form-switch fs-3 ps-0 pe-2">
              <input
                className="form-check-input m-0"
                type="checkbox"
                checked={selected}
                onChange={onCheckboxChange}
              />
            </div>
            
          )}
        </>
      )}
      {children}
      <div className={styles.iconContainer}>{icon}</div>

      {actions && (
        <div className={styles.actions}>
          {actions.map((action, idx) => {
            return (
              <div className={styles.action} onClick={action.onClick} key={idx}>
                {action.icon}
              </div>
            );
          })}
        </div>
      )}
    </Accordion.Header>
  );
};

CuratorAccordion.Item = ({ disabled, ...rest}) => {
  return <Accordion.Item {...rest} className={cx(rest.className, disabled && styles.disabled)} />
};

CuratorAccordion.Divider = ({ ...rest}) => {
  return <div {...rest} className={cx(rest.className, styles.divider)} />
};

CuratorAccordion.Body = Accordion.Body;
