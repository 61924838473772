import cx from 'classnames';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialAddToFavouriteApi } from '../../../../api/materials';
import { MATERIAL } from '../../../../config/constant/assetConstant';
import { useMaterials } from '../../../../hooks/useMaterials';
import {
  curatorStylesSelector,
  toggleMaterialFilterOpen,
} from '../../../../redux/slicers/admin/curatorStylesSlicer';
import { materialSelector } from '../../../../redux/slicers/materials/materialsSlicer';
import ThumbnailCardUI from '../../../../common/ThumbnailCardUI';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll';
import SearchBar from '../SearchBar/SearchBar';

import styles from './stylesSection.module.scss';
import { useUnityMaterial } from './useUnityRedux';
import {
  paginatedMaterialsActions,
  paginatedMaterialsSelector,
  paginatedMaterialsSelectors,
} from '../../../../redux/slicers/materials/paginatedMaterialSlicer';
import { SelectedFilterList } from '../SelectedFilterList/SelectedFilterList';
import { CuratorFilter, FILTER } from '../CuratorFilter/CuratorFilter';
import { useCuratorFilter } from './TexturesTab/useCuratorFilter';
import { CuratorSidebarLayout } from '../CuratorSidebarLayout/CuratorSidebarLayout';

const MaterialTab = memo(({ active }) => {
  const unityMaterial = useUnityMaterial();
  const dispatch = useDispatch();
  const selectedMaterial = useSelector((state) => curatorStylesSelector(state).selectedMaterial);

  const {
    filterOpen,
    filter,
    filterValue,
    toggleFilterOpen,
    handleFilterChange,
    handleFilterClear,
  } = useCuratorFilter({
    name: 'material',
    actions: paginatedMaterialsActions,
    filterSelector: paginatedMaterialsSelector,
  });

  const { list: materialList, fetchMoreData, hasMore, loading, hasData, dataLoadedOnce, onSearchChagne } = useMaterials();

  const applyMaterial = (material) => {
    unityMaterial.applyMaterial({ material, switchToProperties: false });
  };

  const filterConfig = [
    {
      items: [
        {
          label: 'My favorites',
          value: FILTER.MY_FAVOURITES,
        },
      ],
    },
  ];

  const handleFavouriteChange = (id) => (e) => {
    const formData = new FormData();
    formData.append('is_favourite', e.target.checked);
    dispatch(MaterialAddToFavouriteApi(formData, id));
  };

  return (
    <div className={cx(!active && 'hide')}>
      <CuratorSidebarLayout.FixedHeader className={styles.searchBarContainer}>
        <SearchBar
          filterActive={filterOpen}
          onChange={onSearchChagne}
          onFilterClick={toggleFilterOpen}
          placeholder="Search material"
        />
      </CuratorSidebarLayout.FixedHeader>

      <SelectedFilterList
        name="curatorMaterials"
        className="mb-3"
        filter={filter}
        filterValue={filterValue}
        onDelete={handleFilterChange}
        onClear={handleFilterClear}
      />

      {dataLoadedOnce && !hasData && <h4>No materials</h4>}


      <div className={cx(styles.cardContainer, styles.sectionContentContainer)}>
        <InfiniteScroll
          dataLength={materialList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loading={loading}
          className={styles.scrollableContent}
          hasChildren={Boolean(materialList.length)}
          scrollableTarget="CuratorSidebarLayoutScrollableContainer_STYLE">
          {materialList.map((material) => {
            const { id, name, is_favourite, thumbnail } = material;
            const selected = id === selectedMaterial?.id;
            return (
              <MaterialCard
                id={id}
                key={id}
                name={name}
                isFavourite={is_favourite}
                thumbnail={thumbnail}
                onClick={() => applyMaterial(material)}
                selectable={selected}
                selected={selected}
                onFavouriteChange={handleFavouriteChange(id)}
              />
            );
          })}
        </InfiniteScroll>
      </div>

      <CuratorFilter
        open={filterOpen}
        onChange={handleFilterChange}
        filter={filter}
        value={filterValue}
        config={filterConfig}
      />
    </div>
  );
});

export default MaterialTab;


const MaterialCard = (props) => {
  const favouriteLoading = useSelector((state) =>
    paginatedMaterialsSelectors.selectIsFavouriteLoading(state, props.id)
  );
  return <ThumbnailCardUI {...props} favouriteLoading={favouriteLoading} />;
};