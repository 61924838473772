import { Link } from 'react-router-dom';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import _ from 'lodash';

export default function Breadcrumbs(props) {
  if (!props || !props.list || props.list.length < 1) {
    return null;
  }

  let breadcrumbColumn = 12;
  if (props.middleArea) {
    breadcrumbColumn = breadcrumbColumn - 6
  }
  if (props.rightArea) {
    breadcrumbColumn = breadcrumbColumn - 2
  }
  return (
    <div className="breadcrumbHeader pt-2">
      <Row className="my-0 ms-0 me-2 p-0 align-items-center">
        <Col xs={breadcrumbColumn}>
          <Breadcrumb className="pt-3">
            {_.map(props.list, (item, index) => {
              if (item.link) {
                return (
                  <Breadcrumb.Item
                    as={Link}
                    to={item.link}
                    linkAs="span"
                    key={`breadcrumbLink${index}`}>
                    {item.label}
                  </Breadcrumb.Item>
                );
              }
              return (
                <Breadcrumb.Item key={`breadcrumbLink${index}`} active>
                  {item.label}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </Col>
        {props.middleArea && <Col xs="6">{props.middleArea}</Col>}
        {props.rightArea && <Col xs="2">{props.rightArea}</Col>}
      </Row>
    </div>
  );
}
