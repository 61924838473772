import { useDispatch, useSelector } from 'react-redux';
import { toUnityFixed } from '../../../../helpers/unityHelper';
import {
  selectObjectPositionAlongAxis,
  setObjectPositionAlongAxisValue,
} from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { useUnityContext } from '../../../container/unityContainer';
import { NumberInput } from '../NumberInput';
import styles from './ObjectPositionAlongAxis.module.scss';

export const ObjectPositionAlongAxis = () => {
  const dispatch = useDispatch();
  const { shortcutsModule } = useUnityContext();
  const objectPositionAlongAxis = useSelector(selectObjectPositionAlongAxis);

  if (!objectPositionAlongAxis.transformType) return null;
  let { value, transformType } = objectPositionAlongAxis;

  const handleChange = (e) => {
    dispatch(setObjectPositionAlongAxisValue(e.target.value));


    if (!Number.isNaN(parseFloat(e.target.value))) {
      shortcutsModule.SetPositionAlongAxis({
        value: parseFloat(e.target.value) || 0, 
        transformType
      })
    }
  };

  if (typeof value === 'string' && value.trim() === '') {
    value = '';
  } else {
    value = Number.isNaN(parseFloat(value)) ? value : toUnityFixed(value);
  }


  return (
    <NumberInput
      step={0.01}
      value={value}
      enableNegative
      className={styles.input}
      onChange={handleChange}
    />
  );
};
