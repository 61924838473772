import React, { useState, useContext, useEffect } from 'react';
import { FilterContext, UserContext } from '../contexts';
import { Row, Col } from 'react-bootstrap';
import { warningToast } from '../helpers/toastHelper.js';
import {
  ModalButtonArea,
  SearchInput,
  ClickablePill,
  SelectableTableList,
  BigButton,
  Input,
} from './';
import { icons } from '../assets';
import _ from 'lodash';
import { apiCreateCollection, apiAddToCollection } from '../helpers/api';
import { COLLECTION_PROPS } from '../constants/constants';
import { apiErrorHandler } from '../helpers/axiosHelper';
import pluralize from 'pluralize';

const { CollectionIcon } = icons;

const newCollection = {
  name: '',
  description: '',
};

export default function AddToCollections(props) {
  const { selectedList, id, cardType } = props;
  let isPlural = props.isPlural || (selectedList && selectedList.length > 0);
  const filterCtx = useContext(FilterContext);
  const userCtx = useContext(UserContext);
  const currentUser = userCtx?.currentUser;
  const [searchValue, setSearchValue] = useState(null);
  const [showNew, setShowNew] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [editItem, setEditItem] = useState(Object.assign({}, newCollection));
  const [collectionOptions, setCollectionOptions] = useState(filterCtx?.collections || []);
  const [modalOpen, setModalOpen] = useState(false);

  let filteredOptions =
    searchValue && searchValue.length > 0
      ? _.filter(collectionOptions, (opt) => {
          return _.includes(_.toLower(opt.label), _.toLower(searchValue));
        })
      : collectionOptions;

  let selectedCollections = _.filter(filteredOptions, (opt) => opt.selected);

  useEffect(() => {
    if (_.isEqual(filterCtx?.collections, collectionOptions) || filterCtx?.collections?.length > 0) {
      setCollectionOptions(filterCtx.collections);
    }
  }, [filterCtx?.collections]);

  let title = showNew ? 'Create collection' : 'Add items to collection';
  let subtitle = showNew ? (
    <>
      Please add a name and description
      <br />
      for the collection
    </>
  ) : (
    <>
      Select the collection where you want <br /> to add the items or create a new one
    </>
  );

  function handleClick(item) {
    // TODO remove item from selected list
  }

  function onSelectCollection(item) {
    let newList = _.map(collectionOptions, (opt) => {
      if (opt.value === item.value) {
        opt.selected = item.selected ? false : true;
      }
      return opt;
    });
    setCollectionOptions(newList);
  }

  function saveAddCollections() {
    if (!selectedCollections || selectedCollections.length < 1) {
      warningToast('Please selection at least one collection in which to add these items');
      return;
    }
    if (!processing) {
      setProcessing(true);
      let payload = new FormData();
      let field = pluralize.singular(cardType);
      field = COLLECTION_PROPS(field);
      if (!field) {
        warningToast('This area does not support adding to collections. Please contact support.');
        setProcessing(false);
        return;
      }
      if (isPlural) {
        _.map(selectedList, (card) => {
          payload.append(field, card.id);
        });
      } else {
        payload.append(field, id);
      }
      _.forEach(selectedCollections, (col) => {
        payload.append('collection', col.id);
      });
      for (var pair of payload.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      apiAddToCollection(
        payload,
        `Successfully added ${cardType} to collections`,
        `There was an error adding ${cardType} to collections`
      )
        .then((res) => {
          if (res && res.status) {
            onClose();
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setProcessing(false));
    }
  }

  function saveNewCollection() {
    if (!processing) {
      setProcessing(true);
      let payload = Object.assign({}, editItem);
      payload.owner = currentUser?.id;
      apiCreateCollection(payload)
        .then((res) => {
          if (res && res.status) {
            filterCtx?.refreshOptions();
            setShowNew(false);
            if (res.data) {
              let newList = Object.assign([], collectionOptions);
              res.data.label = res.data.name;
              res.data.value = res.data.id;
              newList.push(res.data);
              setCollectionOptions(newList);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setProcessing(false));
    }
  }

  function onClose() {
    filterCtx?.refreshOptions();
    setShowNew(false);
    setCollectionOptions(
      _.map(collectionOptions, (opt) => {
        opt.selected = false;
        return opt;
      })
    );
    if (_.isFunction(props.onSuccess)) {
      props.onSuccess();
    }
    setProcessing(false)
    setModalOpen(false);
  }

  function handleOnChange(key, value) {
    let newItem = Object.assign({}, editItem);
    newItem[key] = value;
    setEditItem(newItem);
  }

  if (!filterCtx) return null;
  return (
    <ModalButtonArea
      button={props.button}
      bigButton={props.bigButton}
      miniButton={props.miniButton}
      footerButton={props.footerButton}
      variant={props.disabled ? 'outline-secondary' : 'secondary'}
      buttonIcon={CollectionIcon}
      buttonTitle="Add To Collection"
      disabled={props.disabled || false}
      title={props.title || title}
      subtitle={subtitle}
      onClose={onClose}
      setShowModal={setModalOpen}
      showModal={modalOpen}
      size="lg"
      body={
        <>
          {showNew ? (
            <>
              <Row className="mb-2">
                <Col>
                  <Input
                    onChange={(e) => handleOnChange(e.name, e.value)}
                    defaultData={editItem}
                    required
                    label="Name"
                    name="name"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    onChange={(e) => handleOnChange(e.name, e.value)}
                    defaultData={editItem}
                    required
                    multiline
                    label="Description"
                    name="description"
                  />
                </Col>
              </Row>
            </>
          ) : (
            <div className="mt-3">
              {selectedList && selectedList.length > 0 ? (
                <Row className="mb-2">
                  {_.map(selectedList, (item, index) => {
                    return (
                      <ClickablePill
                        key={`addToCollectionSelection${item.id}${index}`}
                        title={item.name}
                        label={item.name}
                        onClick={() => handleClick(item)}
                      />
                    );
                  })}
                </Row>
              ) : null}
              <div className="justify-content-center mb-3">
                <SearchInput
                  value={searchValue}
                  placeholder="Search"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <SelectableTableList
                options={filteredOptions}
                onSelect={onSelectCollection}
                showHeaderLabel={'+ Create collection'}
                onHeaderClick={() => setShowNew(true)}
                id="AddToCollections"
                showIcon
                checkboxRight
                striped
                hover
              />
            </div>
          )}
        </>
      }
      promptButton={
        showNew ? (
          <>
            <BigButton
              onClick={() => setShowNew(false)}
              disabled={processing}
              // variant={processing ? 'outline-secondary' : 'outline-dark'}
              buttonTitle={'Cancel'}
            />
            <BigButton
              onClick={saveNewCollection}
              disabled={processing}
              variant={processing ? 'outline-secondary' : 'outline-dark'}
              buttonTitle={'Save'}
            />
          </>
        ) : (
          <BigButton
            onClick={saveAddCollections}
            disabled={processing || selectedCollections.length < 1}
            // variant={processing ? 'outline-secondary' : 'outline-dark'}
            buttonTitle={'Save'}
            block
            className="fullWidth"
          />
        )
      }
      iconSrc={props.iconSrc}
    />
  );
}
