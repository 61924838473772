/* eslint-disable no-debugger */
import { throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TILING_NAME_MAP, TILING_TYPE } from '../../../../../config/constant/unityConstants';
import { sizeDecimals } from '../../../../../helpers/unityHelper';
import {
  updateUnityObject,
  selectMainTilingType,
} from '../../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { useUnityContext } from '../../../../container/unityContainer';
import styles from './DraggableTexture.module.scss';

// Pixels	Millimeters
// 37.8px	10mm
const ONE_PX_MM = 10 / 37.8;

export const DraggableTexture = ({ src }) => {
  const ref = useRef(null);
  const hoveredRef = useRef(false);
  const dispatch = useDispatch();
  const lastMouseDownValues = useRef(null);
  const unityContext = useUnityContext();

  const mainTilingType = useSelector(selectMainTilingType) || {};
  const { 
    x: baseX,
    y: baseY,
    width: baseWidth,
    height: baseHeight,
    tilingType,
    rotation,
  } = mainTilingType;
  const tilingTypeRef = useRef(null)
  const mainTilingTypeRef = useRef(null)
  tilingTypeRef.current = tilingType;
  mainTilingTypeRef.current = mainTilingType;


  const x = baseX * 100;
  const y = baseY * 100; // *100 to convert meters to cm
  const width = baseWidth / 26; // / 26 just to make it look nicer
  const height = baseHeight / 26;

  console.log({
    baseX,
    baseY,
    baseWidth,
    baseHeight,
  });

  const handleMouseUp = () => {
    lastMouseDownValues.current = null;
  };

  const handleMouseDown = (e) => {
    lastMouseDownValues.current = {
      x: baseX,
      y: baseY,
      width: baseWidth,
      height: baseHeight,
      startX: e.clientX,
      startY: e.clientY,
    };

    const mouseUpListener = () => {
      handleMouseUp();
      document.body.removeEventListener('mouseup', mouseUpListener);
    };

    document.body.addEventListener('mouseup', mouseUpListener);
  };

  const handleMouseMove = useCallback(
    throttle((e) => {
      if (!lastMouseDownValues.current) return;

      const { x, y, startX, startY } = lastMouseDownValues.current;

      if (startX === e.clientX && startY === e.clientY) return;
      
      const distanceX = e.clientX - startX;
      const distanceY = e.clientY - startY;


      const updatedX = sizeDecimals(x + (distanceX * ONE_PX_MM) / 1000); // we calculate x/y in m
      const updatedY = sizeDecimals(y - (distanceY * ONE_PX_MM) / 1000);

      unityContext.materialModule.OnUpdateTilingOffsetReceiver({
        x: updatedX,
        y: updatedY,
        tilingOptionType: tilingTypeRef.current, // TODO: handle it for material
      });

      dispatch(
        updateUnityObject({
          [TILING_NAME_MAP[tilingTypeRef.current]]: {
            x: updatedX,
            y: updatedY,
          },
        })
      );
    }, 50),
    []
  );

  const handleWheel = useCallback(
    throttle((e) => {
      const { width, height } = mainTilingTypeRef.current;
      let updatedWidth = sizeDecimals(width - (e.deltaY * ONE_PX_MM) / 50);

      if (updatedWidth <= 0) {
        updatedWidth = 0.01;
      };

      const ratio = width / updatedWidth;
      const updatedHeight = sizeDecimals(height / ratio) || 0.01;

      const updatedData = { width: updatedWidth, height: updatedHeight };

      // update unity
      unityContext.materialModule.OnUpdateTilingSizeReceiver({
        ...updatedData,
        tilingOptionType: tilingTypeRef.current,
      });

      dispatch(
        updateUnityObject({
          [TILING_NAME_MAP[tilingTypeRef.current]]: updatedData,
        })
      );
    }, 50),
    []
  );

  useEffect(() => {
    const preventIfNeeded = (e) => {
      if (hoveredRef.current) {
        e.preventDefault();
      }
    };

    const scrollableContainer = document.querySelector('#styleSubmenu');
    scrollableContainer.addEventListener('wheel', preventIfNeeded, { passive: false });
    return () => scrollableContainer.removeEventListener('wheel', preventIfNeeded);
  }, []);

  return (
    <div
      data-id="DraggableTexture"
      ref={ref}
      className={styles.root}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
      onWheel={handleWheel}
      onMouseEnter={() => {
        hoveredRef.current = true;
      }}
      onMouseLeave={() => {
        hoveredRef.current = false;
      }}>
      <div className={styles.innerImageContainer}>
        <div
          className={styles.innerImage}
          style={{
            backgroundImage: `url('${src}')`,
            backgroundPosition: `left ${x}cm bottom ${y}cm`,
            backgroundSize: `${Math.abs(width)}in ${Math.abs(height)}in`,
            transform: `rotate(${rotation || 0}deg)`,
          }}
        />
      </div>
    </div>
  );
};
