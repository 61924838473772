import cx from "classnames";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GREYDOT,
  BLACKDOT,
  PURPLEDOT,
  TEXTURE_IMAGE,
} from "../../../../../config/constant/assetConstant";
import { curatorStylesSelector, setTextureInfoOpen } from "../../../../../redux/slicers/admin/curatorStylesSlicer";
import { selectIsStyleableObject, selectSelectedObjectName } from "../../../../../redux/slicers/admin/curatorUnityObjectSlicer";
import { CuratorSidebarLayout } from "../../CuratorSidebarLayout/CuratorSidebarLayout";
import { initialTextures, useProperties } from "../useUnityRedux";
import { MaterialProperties } from "./MaterialProperties";
import { PropertiesSection } from "./PropertiesSection";
import TextureProperties from "./TextureProperties";
import { TopImage } from './TopImage';
import styles from '../stylesSection.module.scss';
import { useUnityContext } from "../../../../container/unityContainer";

const PropertiesTab = memo(({ active }) => {
  const dispatch  = useDispatch();
  const { materialModule } = useUnityContext();
  const objectName = useSelector(selectSelectedObjectName);
  const expertMode = useSelector(
    (state) => curatorStylesSelector(state).propertiesExpertMode
  );
  const isStyleableObject = useSelector(selectIsStyleableObject);
  const { propertiesData } = useProperties();
  useEffect(() => {
    if (!active) {
      dispatch(setTextureInfoOpen(false))
    }
  }, [active])

  useEffect(() => {
    if (!isStyleableObject) return;
    // TODO: think about improving this logic if unity will not come up with better approach
    const keys = Object.keys(initialTextures).filter(key => key.startsWith(`${objectName}__`));
    if (!keys.length) {
      // load data from unity
      materialModule.OnCallBase64ImageDataReceiver()
      return;
    }
    
    // load data from cache
    keys.forEach((key) => {
      window.ReceiveBase64String(...initialTextures[key])
    })
  }, [objectName])

  if (!isStyleableObject) return null;

  return (
    <div
      className={cx(
        `properties-parent d-flex justify-content-center flex-column`,
        !active && "hide"
      )}
    >
      <CuratorSidebarLayout.FixedHeader className={styles.searchBarContainer}>
        <TopImage />
      </CuratorSidebarLayout.FixedHeader>
      <TextureProperties />
      <MaterialProperties />

      <PropertiesSection
        expanded={expertMode}
        {...{
          title: "Glossy",
          data: propertiesData.glossiness,
          filePlaceholder: GREYDOT,
          min: 0,
          max: 1,
        }}
      />

      <PropertiesSection
        expanded={expertMode}
        {...{
          title: "Normal",
          data: propertiesData.normal,
          filePlaceholder: PURPLEDOT,
          min: 0,
          max: 1.5,
        }}
      />

      <PropertiesSection
        expanded={expertMode}
        {...{
          title: "Transparency",
          data: propertiesData.transparency,
          filePlaceholder: BLACKDOT,
          min: 0,
          max: 1,
        }}
      />

      <PropertiesSection
        expanded={expertMode}
        {...{
          title: "Metallic",
          data: propertiesData.metallic,
          min: 0,
          max: 1,
        }}
      />

      <PropertiesSection
        expanded={expertMode}
        {...{
          title: "Ao",
          data: propertiesData.ao,
          min: 0,
          max: 1,
        }}
      />

      <PropertiesSection
        expanded={expertMode}
        {...{
          title: "Emission",
          data: propertiesData.emission,
          min: 0,
          max: 1,
        }}
      />

      <PropertiesSection
        expanded={expertMode}
        {...{
          data: propertiesData.other,
        }}
      />
    </div>
  );
});

export default PropertiesTab;
