export const ScaleIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0062 19.1998H13.3364C13.0618 19.1998 12.8341 18.9721 12.8341 18.6975C12.8341 18.4229 13.0618 18.1952 13.3364 18.1952H14.0062C17.0938 18.1952 18.1922 17.0967 18.1922 14.0091V9.9905C18.1922 6.90287 17.0938 5.80446 14.0062 5.80446H9.98757C6.89994 5.80446 5.80153 6.90287 5.80153 9.9905V10.6469C5.80153 10.9215 5.57381 11.1492 5.2992 11.1492C5.0246 11.1492 4.79688 10.9215 4.79688 10.6469V9.9905C4.79688 6.35367 6.35074 4.7998 9.98757 4.7998H14.0062C17.643 4.7998 19.1969 6.35367 19.1969 9.9905V14.0091C19.1969 17.6459 17.643 19.1998 14.0062 19.1998ZM12.3117 11.6853C12.4121 11.7857 12.5394 11.8326 12.6666 11.8326C12.7939 11.8326 12.9212 11.7857 13.0216 11.6853L15.5197 9.17721V10.6539C15.5197 10.9285 15.7474 11.1562 16.022 11.1562C16.2966 11.1562 16.5243 10.9285 16.5243 10.6472V7.96139C16.5243 7.89062 16.5092 7.82297 16.4821 7.76153C16.4069 7.57719 16.2211 7.45903 16.0222 7.45903H13.3364C13.0551 7.45903 12.8341 7.68675 12.8341 7.96136C12.8341 8.23596 13.0618 8.46368 13.3364 8.46368H14.8166L12.3117 10.9753C12.1174 11.1695 12.1174 11.491 12.3117 11.6853ZM7.40897 19.1998H9.21734C10.9989 19.1998 11.8294 18.3693 11.8294 16.5877V14.7793C11.8294 12.9978 10.9989 12.1672 9.21734 12.1672H7.40897C5.62739 12.1672 4.79688 12.9978 4.79688 14.7793V16.5877C4.79688 18.3693 5.62739 19.1998 7.40897 19.1998ZM5.80153 14.7793C5.80153 13.547 6.1766 13.1719 7.40897 13.1719H9.21734C10.4497 13.1719 10.8248 13.547 10.8248 14.7793V16.5877C10.8248 17.8201 10.4497 18.1952 9.21734 18.1952H7.40897C6.1766 18.1952 5.80153 17.8201 5.80153 16.5877V14.7793Z"
        fill="currentColor"
      />
    </svg>
  );
};
