/* eslint-disable no-debugger */
import { useDispatch, useSelector } from 'react-redux';
import {
  curatorLightSelector,
  setSelectedHDRI,
  curatorLightActions,
  ENVIRONMENT_LIGHT,
  setEnvironmentColor,
  setHDRIRotation,
  setEyeToolActive,
  selectDefaultEnvironmentLightData,
  setPopupSelectedHDRI,
  setPreviewHDRI,
  selectEnvironmentLightType,
} from '../../../../../redux/slicers/admin/curatorLightSlicer';
import { getAppState } from '../../../../../redux/store';
import { useUnityContext } from '../../../../container/unityContainer';

export const useUnityHDRI = () => {
  const dispatch = useDispatch();
  const unityContext = useUnityContext();
  const defaultEnvironmentLightData = useSelector(selectDefaultEnvironmentLightData);
  const {
    selectedHDRI,
    eyeToolActive,
    environmentLightType,
    environmentColor,
    hdriRotation,
    hdriIntensity,
  } = useSelector((state) => {
    const {
      selectedHDRI,
      environmentLightType,
      environmentColor,
      hdriRotation,
      hdriIntensity,
      eyeToolActive,
    } = curatorLightSelector(state);
    return {
      selectedHDRI,
      environmentLightType,
      environmentColor,
      hdriRotation,
      hdriIntensity,
      eyeToolActive,
    };
  });

  const unitySelectHDRI = (hdri) => {
    unityContext.lightModule.UpdateEnvironmentSkyboxReceiver({
      url: hdri.file,
      rotation: hdriRotation,
      intensity: hdriIntensity,
    });
  };

  const selectHDRI = (hdri) => {
    dispatch(setSelectedHDRI(hdri));
    dispatch(setPreviewHDRI(null))
    dispatch(setEyeToolActive(true));
    unitySelectHDRI(hdri);
  };

  const updateColorIntesity = (intensity) => {
    dispatch(curatorLightActions.setEnvironmentColorIntensity(intensity));
    unityContext.lightModule.UpdateColorIntensityReceiver(intensity);
  };

  const updateHDRIIntesity = (intensity) => {
    dispatch(curatorLightActions.setHDRIIntensity(intensity));
    unityContext.lightModule.UpdateSkyboxIntensityReceiver(intensity);
  };

  const updateColor = (color) => {
    unityContext.lightModule.UpdateEnvironmentColorReceiver(color);
    dispatch(setEnvironmentColor(color));
  };

  const updateRotation = (rotation) => {
    unityContext.lightModule.UpdateSkyboxRotationReceiver(rotation);
    dispatch(setHDRIRotation(rotation));
  };

  const toggleEnvironmentLightType = (type) => {
    if (environmentLightType === type) {
      // one environment type should always be enabled
      type = type === ENVIRONMENT_LIGHT.HDRI 
        ? ENVIRONMENT_LIGHT.COLOR
        : ENVIRONMENT_LIGHT.HDRI
      // dispatch(curatorLightActions.setEnvironmentLightType(null));
      // return;
    }

    dispatch(curatorLightActions.setEnvironmentLightType(type));

    if (type === ENVIRONMENT_LIGHT.HDRI) {
      dispatch(setEyeToolActive(true));
      if (!selectedHDRI) return;
      unitySelectHDRI(selectedHDRI);
    } else if (type === ENVIRONMENT_LIGHT.COLOR) {
      unityContext.lightModule.UpdateEnvironmentColorReceiver(environmentColor);
    } else {
      throw new Error('Invalid environment light type ' + type);
    }
  };

  const toggleHDRIEyeTool = () => {
    const nextState = !eyeToolActive;
    unityContext.lightModule.EnableDisableHDRSkyboxReceiver(nextState);
    dispatch(setEyeToolActive(nextState));
  };

  const resetLight = () => {
    const { hdri, color } = defaultEnvironmentLightData || {};
    const selectedLightType = selectEnvironmentLightType(getAppState())

    if (hdri) {
      if (hdri.enabled && selectedLightType !== ENVIRONMENT_LIGHT.HDRI) {
        toggleEnvironmentLightType(ENVIRONMENT_LIGHT.HDRI)
      }
      
      dispatch(setSelectedHDRI(hdri.data));
      updateHDRIIntesity(hdri.intensity)
      updateRotation(hdri.rotation)
    }

    if (color) {
      if (color.enabled && selectedLightType !== ENVIRONMENT_LIGHT.COLOR) {
        toggleEnvironmentLightType(ENVIRONMENT_LIGHT.COLOR)
      }

      updateColor(color.color)
      updateColorIntesity(color.intensity)
    }
  };

  const resetLightEnabled =
    Boolean(defaultEnvironmentLightData?.hdri) || Boolean(defaultEnvironmentLightData?.color);

  return {
    eyeToolActive,
    selectedHDRI,
    selectHDRI,
    hdriIntensity,
    hdriRotation,
    environmentLightType,
    resetLightEnabled,
    toggleEnvironmentLightType,
    updateColorIntesity,
    updateHDRIIntesity,
    updateColor,
    updateRotation,
    toggleHDRIEyeTool,
    resetLight,
  };
};
