import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Preview, FooterButton, SharePopup, AddToCollections } from '../common';
import { getCuratorPath } from '../helpers/urlHelper';
import { useParams } from 'react-router-dom';
import { apiGetPresetCameraAngle } from '../helpers/api';
import _ from 'lodash';
import { UserContext } from '../contexts';

import { icons } from '../assets';

const {
  ArchiveIcon,
  CollectionIcon,
  EditIcon,
  FullScreenIcon,
  HeartLineIcon,
  HeadsetIcon,
  ShareIcon,
} = icons;

export default function ProductRender() {
  const { id } = useParams();
  const userCtx = useContext(UserContext);
  const [data, setData] = useState([]);
  const [sidebarCards, setSidebarCards] = useState([]);
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const res = await apiGetPresetCameraAngle(id);
    if (res) {
      if (res.results) {
        setData(res.results);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      setSidebarCards(_.map(data, (d) => ({ ...d, name: d.cameraName })));
      setSlides(_.map(data, (d) => d.file));
    }
  }, [data]);

  const actions = [
    {
      element: FooterButton,
      title: 'Edit',
      icon: EditIcon,
      clickFunction: () => {
        window.open(getCuratorPath(id, 'room'), '_blank');
      }
    },
    { element: SharePopup, footerButton: true, icon: ShareIcon, title: 'Share' },
    {
      element: AddToCollections,
      footerButton: true,
      icon: CollectionIcon,
      title: 'Add to Collection',
      onSuccess: () => console.log('success'),
    },
    {
      element: FooterButton,
      footerButton: true,
      title: 'Favourite',
      icon: HeartLineIcon,
      // onClick: () => alert("favourite"),
    },
    {
      element: FooterButton,
      footerButton: true,
      icon: FullScreenIcon,
      title: 'Full Screen',
    },
    {
      element: FooterButton,
      footerButton: true,
      title: 'Help',
      icon: HeadsetIcon,
      // onClick: () => alert("info"),
    },
  ];

  const updatedAt = useMemo(() => {
    if (!data?.updated_at) return null;
    return new Date(data?.updated_at).getTime();
  }, [data?.updated_at]);

  return (
    <>
      <Preview
        title={data?.name}
        actions={actions}
        // onClose={() => navigate(MY_PROJECTS_ROUTE)}
        loading={loading}
        updatedAt={updatedAt}
        userName="Sasha"
        sidebarCards={sidebarCards}
        slides={slides}
      />
    </>
  );
}
