/* eslint-disable react/jsx-no-target-blank, no-debugger */
import api, { getJson } from '../helpers/axiosHelper';

import {
  API_ROOT_URL,
  CREATE_ROOM,
  GET_GLOBAL_TAGS,
  GET_PROJECT_API,
  ORGANISATION_DETAILS_API,
  PROPS_API,
  ROOM_DETAILS_API,
  ROOM_LAYOUT_API,
  GET_SETTING_API,
  EDIT_SETTING_API,
  CREATE_SETTINGS_API,
  GET_DEFAULT_METADATA,
} from '../config/constant/apiConstant';
import {
  editCuratorSettingData,
  getCuratorSettingData,
  selectIndividualProjectData,
  selectProjectJsonUrl,
  setCuratorSettingData,
  setDefaultMetaData,
  setIndividualProjectData,
  setLayoutData,
  setRoomData,
  setSaveProjectLoading,
} from '../redux/slicers/admin/curatorSlicer';
import { dispatcher, navigate } from '../helpers/projectHelper';
import { ADMIN_MY_ROOMS, MY_PROJECTS_ROUTE } from '../config/constant/routePathConstant';
import { errorToast, successToast } from '../helpers/toastHelper';
import { setOrganisationData } from '../redux/slicers/organisation/organisationSlicer';
import { setGlobalTagData, setTagData } from '../redux/slicers/tags/globalTagSlicer';
import { CURATOR_SETTING } from '../config/indexDB/schema';
import { getLocalStorage, objectToSearchParams } from '../helpers/jsHelper';
import { USER_ID } from '../config/constant/projectConstant';
import { getCuratorPath } from '../helpers/urlHelper';
import { setGlobalHeight, setGlobalWidth } from '../redux/slicers/camera/cameraSettings';
import { selectProjectCameraList } from '../redux/slicers/camera/projectCameraAngleSlicer';
import { getAppState } from '../redux/store';
import { toast } from 'react-toastify';
import { EXPORT_PROJECT, GET_SIDE_PANEL_OPTIONS, SIDE_PANEL_OPTIONS } from '../helpers/api';
import {
  setConfig,
  setUserEnabledSections,
} from '../redux/slicers/admin/curatorMenuPreferencesSlicer';
import download from 'downloadjs';

export const layoutApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(ROOM_LAYOUT_API)
    .success((data) => {
      dispatch(setLayoutData(data));
    })
    .error(() => {})
    .send();
};

export const getRoomDetailsApi =
  ({ ids, ...rest } = {}) =>
  (dispatch) => {
    let query = '';

    if (ids) {
      query = ids.map((a) => `&id=${a}`).join('&');
    } else {
      query = objectToSearchParams(rest).toString();
    }

    return api()
      .root(API_ROOT_URL)
      .get(`${ROOM_DETAILS_API}?${query}`)
      .success((data) => {
        dispatch(setRoomData(data.results?.[0]));
      })
      .error(() => {})
      .send();
  };
// Create Room
export const creatRoomApi = (formData) => (dispatch) => {
  console.log('create room');
  api()
    .root(API_ROOT_URL)
    .post(CREATE_ROOM)
    .data(formData)
    .success((data) => {
      navigate(ADMIN_MY_ROOMS);
      successToast('Room created successfully');
      localStorage.removeItem('roomInfo');
      console.log(data);
    })
    .error((err) => {
      console.log(err);
    })
    .send();
};

export const propsReadApi = async (arr = []) => {
  const query = arr.map((a) => `id=${a}`).join('&');
  return await api().root(API_ROOT_URL).get(`${PROPS_API}?${query}&per_page=1000`).send();
};
export const propsV1ReadApi = async (arr = []) => {
  const query = arr.map((a) => `old_product_id=${a}`).join('&');
  return await api().root(API_ROOT_URL).get(`${PROPS_API}?${query}&per_page=1000`).send();
};
export const uniquePropsApi = async (id = '') => {
  return await api().root(API_ROOT_URL).get(`${PROPS_API}?id=${id}`).send();
};

export const layoutReadApi = async (id = '') => {
  return await api()
    .root(API_ROOT_URL)
    .get(`${ROOM_LAYOUT_API}${id}`)
    .success((data) => {})
    .send();
};
export const createProjectApi =
  (formData, { onSuccess, onError } = {}) =>
  (dispatch) => {
    dispatch(setSaveProjectLoading(true));
    api()
      .root(API_ROOT_URL)
      .post(GET_PROJECT_API)
      .data(formData)
      .success((data) => {
        successToast(`Project created successfully.`);
        onSuccess?.(data);
      })
      .error(() => {
        errorToast('Unknown error happened while creating project');
        onError?.();
      })
      .upload(() => {})
      .finally(() => {
        dispatch(setSaveProjectLoading(false));
      });
  };
export const getProjectApi = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${GET_PROJECT_API}${formData}/`)
    .data(formData)
    .success((data) => {
      dispatch(setIndividualProjectData(data));
    })
    .error(() => {})
    .upload(() => {});
};

export const saveProjectApi =
  (formData, id, { onSuccess, onError } = {}) =>
  (dispatch) => {
    dispatch(setSaveProjectLoading(true));
    api()
      .root(API_ROOT_URL)
      .patch(`${GET_PROJECT_API}${id}/`)
      .data(formData)
      .success((data) => {
        successToast('Project updated successfully');
        dispatch(setIndividualProjectData(data));
        onSuccess?.(data);
      })
      .error(() => onError?.())
      .upload(() => {})
      .finally(() => {
        dispatch(setSaveProjectLoading(false));
      });
  };

export const saveCurrentProjectCameras = async ({ onError, onSuccess } = {}) => {
  const projectId = selectIndividualProjectData(getAppState()).id;
  const jsonFileUrl = selectProjectJsonUrl(getAppState());
  const projectJson = await getJson(jsonFileUrl);

  projectJson.cameraList = selectProjectCameraList(getAppState()) || [];
  const jsonData = JSON.stringify(projectJson);

  console.log('sasha jsonData', jsonData);

  const blob = new Blob([jsonData], { type: 'application/json' });
  var formData = new FormData();
  formData.append('file', blob, 'newproject.json');

  return api()
    .root(API_ROOT_URL)
    .patch(`${GET_PROJECT_API}${projectId}/`)
    .data(formData)
    .success((data) => {
      dispatcher(setIndividualProjectData(data));
      onSuccess?.();
    })
    .error(() => {
      errorToast(
        'Something went wrong during updating camera list. Please save project using "Save" in navigation menu '
      );
      onError?.();
    })
    .upload(() => {});
};

// Live on App APIs start
export const getOrganisationsApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${ORGANISATION_DETAILS_API}?page=2`)
    .success((data) => dispatch(setOrganisationData(data)))
    // .success((data) => console.table(data.results))
    .error((error) => console.error('Error occured: ', error.message))
    .send();
};

export const getCuratorSettings = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${GET_SETTING_API}`)
    .send()
    .then((data) => {
      if (!data?.json_data) {
        dispatch(getDefaultMetaData());
      } else {
        if (data?.json_data?.metaData) {
          let tempMetaData = [...data?.json_data?.metaData];
          data?.default_meta_data?.forEach((newMetaData) => {
            let flag = true;
            data?.json_data?.metaData?.forEach((oldMetaData) => {
              if (oldMetaData?.id === newMetaData?.id) {
                flag = false;
              }
            });
            if (flag) {
              tempMetaData.push({ ...newMetaData, seq_no: tempMetaData?.length });
            }
          });

          let presets = data?.json_data?.resolutionPresent;
          if (presets && presets.length) {
            let selectedPreset = presets.find((p) => p.isSelected);
            if (!selectedPreset) {
              presets = presets.map((p, idx) => (idx === 0 ? { ...p, isSelected: true } : p));
            }

            selectedPreset = presets.find((p) => p.isSelected);
            dispatch(setGlobalWidth(selectedPreset.width));
            dispatch(setGlobalHeight(selectedPreset.height));
          }

          dispatch(
            getCuratorSettingData({
              ...data,
              json_data: {
                ...data?.json_data,
                metaData: tempMetaData,
                resolutionPresent: presets,
              },
            })
          );
        }
      }
    })
    .catch((error) => {
      console.log('getCuratorSettings error', error);
    });
};

export const createCuratorSettings = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .post(`${CREATE_SETTINGS_API}`)
    .data(formData)
    .send()
    .then((data) => {
      dispatch(getCuratorSettingData(data));
    })
    .catch(() => {
      errorToast('Could not cerate settings');
    });
};

export const editCuratorSetting = (formData, id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .patch(`${EDIT_SETTING_API}${id}/`)
    .data(formData)
    .send()
    .then((data) => {
      dispatch(editCuratorSettingData(data));
    })
    .catch((error) => {
      console.log('error', error);
      errorToast('something went wrong');
    });
};
export const getGlobalTagsApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(GET_GLOBAL_TAGS)
    .success((data) => dispatch(setTagData(data)))
    .error((error) => console.error('Error occured: ', error.message))
    .send();
};

export const getDefaultMetaData = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(GET_DEFAULT_METADATA)
    .send()
    .then((data) => {
      dispatch(setDefaultMetaData(data));
      const formData = new FormData();
      formData.append('json_data', JSON.stringify({ ...CURATOR_SETTING, metaData: data?.results }));
      console.log('default_data', data);
      formData.append('user', getLocalStorage(USER_ID));
      dispatch(createCuratorSettings(formData));
    });
};
// Live on App APIs end

export const getSidePanelOptionsApi = () => (dispatch) => {
  return api()
    .root(API_ROOT_URL)
    .get(GET_SIDE_PANEL_OPTIONS)
    .success((data) => {
      dispatch(setUserEnabledSections(data));
      console.log('getSidePanelOptionsApi ', data);
    })
    .send();
};

export const getSidePanelOptionsConfigApi = () => (dispatch) => {
  return api()
    .root(API_ROOT_URL)
    .get(SIDE_PANEL_OPTIONS)
    .success((data) => {
      dispatch(setConfig(data.results));
    })
    .send();
};

export const updateSidePanelOptionsApi =
  ({ ids }) =>
  (dispatch) => {
    const formData = new FormData();
    formData.append('id', ids.join(','));

    return api()
      .root(API_ROOT_URL)
      .data(formData)
      .post(GET_SIDE_PANEL_OPTIONS)
      .error((err) => {
        errorToast('Unable to save sidebar panel configuration at this time');
      })
      .send();
  };

export const exportProjectApi = (formData, { fileName }) => async (dispatch) => {
  const result = await api()
    .root(API_ROOT_URL)
    .post(EXPORT_PROJECT)
    .data(formData)
    .error((err) => {
      errorToast('Error happened while exporting project');
    })
    .send();

  // TODO: handle error here
  const fileUrl = result.data.zip_file;
  await downloadFile(fileUrl, fileName);
};

const downloadFile = (fileUrl, fileName) => {
  // this one is temporary until we prepare download progress component

  return new Promise((resolve, reject) => {
    var x = new XMLHttpRequest();
    x.open('GET', fileUrl, true);
    x.responseType = 'blob';
    x.onload = function (e) {
      download(e.target.response, fileName );
      resolve(undefined);
    };
    x.send();
  });
};
