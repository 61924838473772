/* eslint-disable no-debugger */
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cameraSettingsSelector,
  selectGlobalHeight,
  selectGlobalWidth,
  setGlobalFov,
  setGlobalHeight,
  setGlobalSafeCameraFlag,
  setGlobalWidth,
} from '../../../../redux/slicers/camera/cameraSettings';
import { FOV_MAX_VALUE, FOV_MIN_VALUE } from '../../../../config/constant/projectConstant';
import {
  curatorSelector,
  selectCuratorInitialized,
  selectDimension,
  selectResolutionPresets,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { DIMENSION } from '../../../../config/constant/unityConstants';
import FormRange from '../FormElements/FormRange';
import { useUnityContext } from '../../../container/unityContainer';
import { NavDropdown } from 'react-bootstrap';
import styles from './Resolution.module.scss';
import { Lock } from '../Lock/Lock';
import { useResolutionHandlers } from '../components/Setting/useResolutionPresets';
import { normalizeResSize } from '../../../../helpers/unityHelper';
import { useUndoRedo } from '../UndoRedoContext';
import { UndoRedoCheckbox } from '../UndoRedoCheckbox';
import { undoRedo } from '../UndoRedo/UndoRedo';
import { NumberInput } from '../NumberInput';
import { UndoRedoInput } from '../UndoRedoInput';

export const Resolution = () => {
  const dispatch = useDispatch();
  const dimension = useSelector(selectDimension);
  const { unityData = {} } = useSelector(curatorSelector);
  const { handleCameraFov } = useUnityContext();
  const curatorInitialized = useSelector(selectCuratorInitialized);
  const resolutionPresets = useSelector(selectResolutionPresets);
  const { globalFov, globalSafeCameraFlag } = useSelector(cameraSettingsSelector);
  const globalWidth = useSelector(selectGlobalWidth);
  const globalHeight = useSelector(selectGlobalHeight);
  const [lock, setLock] = useState(false);
  const fovBeforeFocusValue = useRef(null);
  const resolutionValuesRef = useRef(null);

  const updateUndoRedoResolution = (resolution) => {
    const newResolution = {
      width: resolution.width,
      height: resolution.height,
    }
    undoRedo.safeCameraResolution.updateResolution({
      prevValue: resolutionValuesRef.current,
      nextValue: newResolution,
    })
    resolutionValuesRef.current = newResolution; // store latest data to ref
  }

  const updateResolution = (resolution, { type }) => {
    const width = normalizeResSize(resolution.width);
    const height = normalizeResSize(resolution.height);
    dispatch(setGlobalWidth(width));
    dispatch(setGlobalHeight(height));

    if (type === 'blur') {
      updateUndoRedoResolution(resolution)
    }
  };

  const { handleResolutionBlur, handleResolutionChange } = useResolutionHandlers({
    data: {
      width: globalWidth,
      height: globalHeight,
      lock,
    },
    defaultX: 1,
    defaultY: 1,
    valueFormatter: parseInt,
    updateCallback: updateResolution,
  });

  const handleChangeFov = (val) => {
    let fov = Number.parseInt(val);
    fov = fov < FOV_MIN_VALUE ? FOV_MIN_VALUE : fov;
    fov = fov > FOV_MAX_VALUE ? FOV_MAX_VALUE : fov;
    dispatch(setGlobalFov(fov));
    handleCameraFov(fov);
  };

  useEffect(() => {
    console.log('unityData?.roomCameraInfo?.fov: ', unityData?.roomCameraInfo?.fov);
    if (unityData?.roomCameraInfo?.fov === undefined || isNaN(unityData?.roomCameraInfo?.fov)) {
      dispatch(setGlobalFov(FOV_MIN_VALUE));
    } else {
      dispatch(setGlobalFov(Number.parseInt(unityData?.roomCameraInfo?.fov)));
    }
  }, [unityData?.roomCameraInfo?.fov]);

  if (dimension === DIMENSION._2D || !Array.isArray(resolutionPresets)) {
    return null;
  }

  const handleSelect = (resolutionPreset) => () => {
    dispatch(setGlobalWidth(resolutionPreset.width));
    dispatch(setGlobalHeight(resolutionPreset.height));
    updateUndoRedoResolution(resolutionPreset)
  };

  const toggleLock = () => {
    setLock(!lock);
  };

  const resolutionLabel = `${globalWidth}x${globalHeight}`;
  const stopPropagation = (e) => e.stopPropagation();

  const handleFovFocus = () => {
    fovBeforeFocusValue.current = globalFov;
  }

  const handleFovBlur = (e) => {
    undoRedo.changeFOV({ 
      fov: globalFov,
      prevFov: fovBeforeFocusValue.current,
    })
  }

  const handleResolutionInputFocus = () => {
    resolutionValuesRef.current = {
      width: globalWidth,
      height: globalHeight,
    };
  }

  return (
    <>
      <div className="divider"></div>
      <div className="range d-flex align-items-center">
        <label className={styles.fovLabel}>Fov({globalFov ? globalFov : FOV_MIN_VALUE})</label>
        <FormRange
          className={styles.fovInput}
          value={globalFov}
          min={FOV_MIN_VALUE}
          step={0.01}
          hideValue
          max={FOV_MAX_VALUE}
          size="large"
          disabled={!curatorInitialized}
          onChange={(e) => handleChangeFov(e.target.value)}
          onFocus={handleFovFocus}
          onBlur={handleFovBlur}
        />
      </div>
      <div className="divider me-0"></div>
      <div className="range-size curator-header-resolution">
        <NavDropdown title={resolutionLabel} className={styles.topMenuItem}>
          <NavDropdown.Item disabled={!curatorInitialized}>
            <div onClick={stopPropagation}>
              <div className="resolution-btn">
                {resolutionPresets.map((item) => {
                  const active = item.width === globalWidth && item.height === globalHeight;
                  return (
                    <button
                      key={item.id}
                      className={`btn btn-default ${active && 'active'}`}
                      onClick={handleSelect(item)}>
                      {item.name}
                    </button>
                  );
                })}
              </div>
              <ul className="resolution-input">
                <li>
                  <p className="mb-2">Width (px)</p>
                  <NumberInput 
                    className="form-control p-1"
                    value={globalWidth}
                    onChange={handleResolutionChange}
                    onBlur={handleResolutionBlur}
                    name="width"
                    min={FOV_MIN_VALUE}
                    max={FOV_MAX_VALUE}
                    onlyIntegers
                    onFocus={handleResolutionInputFocus}
                  />
                </li>
                <li>
                  <p className="pt-4 m-0">
                    <Lock
                      active={lock}
                      className="mx-2  resolution-input-lock-icon"
                      onClick={toggleLock}
                    />
                  </p>
                </li>
                <li>
                  <p className="mb-2">Height (px)</p>
                  <NumberInput 
                    className="form-control p-1"
                    value={globalHeight}
                    onChange={handleResolutionChange}
                    onBlur={handleResolutionBlur}
                    name="height"
                    min={FOV_MIN_VALUE}
                    max={FOV_MAX_VALUE}
                    onlyIntegers
                    onFocus={handleResolutionInputFocus}
                  />
                </li>
              </ul>
              <div className="custom-checkbox">
                <UndoRedoCheckbox
                  className="form-check-input"
                  checked={globalSafeCameraFlag}
                  onChange={(e) => dispatch(setGlobalSafeCameraFlag(!globalSafeCameraFlag))}
                  undoRedoAction={undoRedo.safeCameraResolution.changeSafeCameraFlag}
                />
                <label className="form-check-label" htmlFor="cameraSafeView">
                  Show camera safe frame
                </label>
              </div>
            </div>
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    </>
  );
};
