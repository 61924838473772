import { PROJECT_TYPE } from './projectConstants';
import { getProjectTypeLabel } from '../helpers/dataHelper';

const PROJECT_TYPE_OPTIONS = [
  {
    label: getProjectTypeLabel(PROJECT_TYPE.ROOM),
    value: PROJECT_TYPE.ROOM,
  },
  {
    label: getProjectTypeLabel(PROJECT_TYPE.LIFESTYLE),
    value: PROJECT_TYPE.LIFESTYLE,
  },
  {
    label: getProjectTypeLabel(PROJECT_TYPE.SILO),
    value: PROJECT_TYPE.SILO,
  },
  {
    label: getProjectTypeLabel(PROJECT_TYPE.VIGNETTE),
    value: PROJECT_TYPE.VIGNETTE,
  },
  {
    label: getProjectTypeLabel(PROJECT_TYPE.THREESIXTY),
    value: PROJECT_TYPE.THREESIXTY,
  },
  {
    label: getProjectTypeLabel(PROJECT_TYPE.VIDEO),
    value: PROJECT_TYPE.VIDEO,
  },
  {
    label: getProjectTypeLabel(PROJECT_TYPE.CONFIGURATOR),
    value: PROJECT_TYPE.CONFIGURATOR,
  },
  {
    label: getProjectTypeLabel(PROJECT_TYPE.AUGMENTED_REALITY),
    value: PROJECT_TYPE.AUGMENTED_REALITY,
  },
];

const RENDER_STATUS = {
  STATUS_NOT_DONE: 0,
  STATUS_DONE: 1,
  STATUS_PROCESSING: 2,
  STATUS_FAILED: 3,
  STATUS_DISABLED: 4,
};
const STATUS_NOT_DONE = 'Not Done';
const STATUS_DONE = 'Done';
const STATUS_PROCESSING = 'Processing';
const STATUS_FAILED = 'Failed';
const STATUS_DISABLED = 'Disabled';

const RENDER_STATUS_OPTIONS = [
  {
    value: RENDER_STATUS.STATUS_NOT_DONE,
    label: STATUS_NOT_DONE,
  },
  {
    value: RENDER_STATUS.STATUS_DONE,
    label: STATUS_DONE,
  },
  {
    value: RENDER_STATUS.STATUS_PROCESSING,
    label: STATUS_PROCESSING,
  },
  {
    value: RENDER_STATUS.STATUS_FAILED,
    label: STATUS_FAILED,
  },
  {
    value: RENDER_STATUS.STATUS_DISABLED,
    label: STATUS_DISABLED,
  },
];
export const USER_ROLE_IDS = {
  // This is still somewhat tenative, per Aakash 04/28/2022
  // He said that role #5 is not in use anywhere on the API side yet
  ADMIN: 5,
  MEMBER: 6,
};

export const INTERNAL_USER_ROLES = [USER_ROLE_IDS.MEMBER];
export const USER_ROLES = [
  { label: 'Member', value: USER_ROLE_IDS.MEMBER },
  { label: 'Admin', value: USER_ROLE_IDS.ADMIN },
];

export const MODULE_TYPE_OPTIONS = {
  PROPS: 1,
  THREEDMODELS: 2,
  TEXTURES: 3,
  UPLOADS: 4,
  ROOMS: 5,
  TEMPLATES: 6,
  RENDERS: 7,
  PREVIEWS: 8,
  PROJECT: 9,
  MATERIAL: 10,
};

export const GET_MODULE_KEY_BY_ID = (id) => {
  id = parseInt(id, 10);
  switch (id) {
    case MODULE_TYPE_OPTIONS.PROPS:
      return MODULE_TYPES.PROP;
    case MODULE_TYPE_OPTIONS.ROOMS:
    case MODULE_TYPE_OPTIONS.TEMPLATES:
      return MODULE_TYPES.ROOM;
    case MODULE_TYPE_OPTIONS.PROJECT:
      return MODULE_TYPES.PROJECT;
    case MODULE_TYPE_OPTIONS.RENDERS:
    case MODULE_TYPE_OPTIONS.PREVIEWS:
      return MODULE_TYPES.PROJECT_RENDER;

    // not sure about these, so need to test
    case MODULE_TYPE_OPTIONS.TEXTURES:
    case MODULE_TYPE_OPTIONS.UPLOADS:
    case MODULE_TYPE_OPTIONS.MATERIAL:
    case MODULE_TYPE_OPTIONS.THREEDMODELS:
      return MODULE_TYPES.PRODUCT;
    default:
      // if you have gotten here, then we may have a missing or new module type
      // that we need to know the value from the API team
      return null;
  }
};

/// these are the keys that come back on the sidefilter "order" field
// and other common filter keys
export const FILTER_TYPES = {
  BRANDS: 'brands',
  PRODUCTS: 'products',
  COLLECTIONS: 'collections',
  FILTER_OPTIONS: 'filteroptions',
  FILTER_LABELS: 'filterlabels',
  FAVOURITE: 'is_favourite',
  PAGE: 'page',
  CREATED_BY: 'created_by',
  ORDERED_FILTERS: 'orderedFilters',
};

export const collectionFriendlyTitle = (model_type) => {
  switch (parseInt(model_type, 10)) {
    case MODULE_TYPE_OPTIONS.PROPS:
      return 'Common Prop';
    case MODULE_TYPE_OPTIONS.THREEDMODELS:
      return 'My product';
    case MODULE_TYPE_OPTIONS.TEXTURES:
      return 'Texture';
    case MODULE_TYPE_OPTIONS.UPLOADS:
      return 'Upload';
    case MODULE_TYPE_OPTIONS.ROOMS:
      return 'My Room';
    case MODULE_TYPE_OPTIONS.TEMPLATES:
      return 'Common Room';
    case MODULE_TYPE_OPTIONS.RENDERS:
      return 'Render';
    case MODULE_TYPE_OPTIONS.PREVIEWS:
      return 'Preview';
    case MODULE_TYPE_OPTIONS.PROJECT:
      return 'My Project';
    case MODULE_TYPE_OPTIONS.MATERIAL:
      return 'Material';
  }
};

export const MODULE_TYPES = {
  PRODUCT: 'product',
  PRODUCTS: 'products',
  PROP: 'prop',
  PROPS: 'props',
  TEXTURE: 'texture',
  TEXTURES: 'textures',
  ROOM: 'room',
  ROOMS: 'rooms',
  TEMPLATE: 'template',
  TEMPLATES: 'templates',
  PROJECT: 'project',
  PROJECTS: 'projects',
  PROJECT_RENDER: 'project_render',
  RENDER: 'render',
  RENDERS: 'renders',
  RENDER_DETAIL: 'render-detail',
  RENDER_PREVIEW: 'renders-previews',
  PREVIEW: 'preview',
  PREVIEWS: 'previews',
  COLLECTION: 'collection',
  COLLECTIONS: 'collections',
  MODEL: 'model',
  MODELS: 'models',
  THREEDMODELS: '3dmodels',
  THREEDMODEL: '3dmodel',
};

// per Aakash 04-May-2022
export const COLLECTION_PROPS = (itemType) => {
  switch (itemType) {
    case MODULE_TYPES.PRODUCT:
    case MODULE_TYPES.PROP:
      return MODULE_TYPES.PROP;
    case MODULE_TYPES.ROOM:
    case MODULE_TYPES.TEMPLATE:
      return MODULE_TYPES.ROOM;
    case MODULE_TYPES.PROJECT:
      return MODULE_TYPES.PROJECT;
    case MODULE_TYPES.RENDER:
    case MODULE_TYPES.RENDER_DETAIL:
    case MODULE_TYPES.RENDER_PREVIEW:
    case 'renders-preview':
      return MODULE_TYPES.PROJECT_RENDER;
    case MODULE_TYPES.PREVIEW:
      return MODULE_TYPES.PROJECT_RENDER;
    default:
      return null;
  }
};

const constants = {
  PROJECT_TYPE_OPTIONS,
  RENDER_STATUS,
  RENDER_STATUS_OPTIONS,
  INTERNAL_USER_ROLES,
  USER_ROLES,
  MODULE_TYPES,
  COLLECTION_PROPS,
  FILTER_TYPES,
  collectionFriendlyTitle,
};

export default constants;
