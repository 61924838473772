export const InfoIcon2 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5ZM12.6 15.96C12.6 16.1824 12.3822 16.45 12 16.45C11.6178 16.45 11.4 16.1824 11.4 15.96V11.01C11.4 10.7876 11.6178 10.52 12 10.52C12.3822 10.52 12.6 10.7876 12.6 11.01V15.96ZM11.4 8.04C11.4 7.81763 11.6178 7.55 12 7.55C12.3822 7.55 12.6 7.81763 12.6 8.04C12.6 8.26237 12.3822 8.53 12 8.53C11.6178 8.53 11.4 8.26237 11.4 8.04Z"
        fill="#3D4145"
        stroke="white"
      />
    </svg>
  );
};
