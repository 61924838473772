const LightIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7357 28.6483H16.5801C16.0886 28.6483 15.463 28.5925 15.0496 28.1679C14.6474 27.7546 14.6586 27.2407 14.6698 26.8944V25.9001C11.8545 24.0233 10 20.7611 10 17.6554C10 14.7172 11.3183 11.969 13.6085 10.1257C15.8987 8.28232 18.9038 7.58968 21.842 8.23764C24.6349 8.85208 27.048 10.7178 28.2992 13.2314C30.7235 18.1134 28.3886 23.3976 24.6573 25.9113V26.7491C24.6684 27.0731 24.6796 27.6652 24.2216 28.1344C23.8753 28.4696 23.3837 28.6483 22.7357 28.6483ZM16.3344 26.9614C16.3582 26.9614 16.3861 26.9634 16.4181 26.9658C16.4611 26.9689 16.5114 26.9726 16.569 26.9726H22.7357C22.8475 26.9726 22.9257 26.9614 22.9703 26.9502V26.8385V25.4309C22.9703 25.1404 23.1267 24.8611 23.3725 24.7159C26.6793 22.7162 28.869 18.1469 26.7799 13.9575C25.7521 11.8908 23.7635 10.3603 21.4622 9.85753C19.0267 9.32129 16.5354 9.89105 14.6363 11.4216C12.7371 12.9521 11.6534 15.2199 11.6534 17.6554C11.6534 20.7611 13.7984 23.4423 15.921 24.7271C16.178 24.8835 16.3232 25.1516 16.3232 25.4421V26.9502C16.3344 26.9502 16.3344 26.9502 16.3344 26.9614ZM19.6414 22.0571C19.4962 22.0571 19.351 22.0236 19.2281 21.9454C18.8259 21.7108 18.6918 21.1969 18.9153 20.8059L19.8649 19.1525H18.9264C18.3679 19.1525 17.9098 18.9067 17.664 18.4934C17.4183 18.0688 17.4406 17.5549 17.7199 17.0634L18.9153 14.9854C19.1499 14.5833 19.6638 14.4492 20.0548 14.6726C20.457 14.9072 20.591 15.4211 20.3676 15.8122L19.418 17.4767H20.3564C20.915 17.4767 21.3731 17.7225 21.6188 18.1359C21.8646 18.5604 21.8423 19.0743 21.563 19.5658L20.3676 21.6438C20.2112 21.9119 19.9319 22.0571 19.6414 22.0571ZM23.3168 31.9665C23.395 31.9888 23.4732 32 23.5514 32C23.9201 32 24.2552 31.7654 24.3558 31.3855C24.4787 30.9387 24.2217 30.4695 23.7749 30.3466C21.0713 29.5757 18.2002 29.5757 15.4966 30.3466C15.0498 30.4806 14.7816 30.9387 14.9157 31.3855C15.0498 31.8324 15.5078 32.0894 15.9547 31.9665C18.3678 31.2738 20.9037 31.2738 23.3168 31.9665Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LightIcon;
