import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Breadcrumbs } from '../layouts';
import {  Button, ButtonGroup, Container, Row, Col } from 'react-bootstrap';
import { RendersPreviews } from './';
import {
  ADMIN_MY_PRODUCTS_3DMODAL,
  MY_PRODUCTS_ROUTE
} from '../constants/routePathConstants';
import { ReactLoader } from '../layouts/Loading';
import constants from '../constants/constants';
import _ from 'lodash';
import { icons } from '../assets';
import { FilterContext, UserContext } from '../contexts';
import { apiGetCachedList, apiErrorHandler } from '../helpers/axiosHelper';
import {
  apiGetProp,
  apiEditProject,
  parseBasePathname,
  parseProductTypePathname,
  apiGetProductWebExperience
} from '../helpers/api';

const tabs = {
  WEB: 'Website Experience',
  RENDERS: 'Renders & Previews',
  FILES: 'Project files',
};

function WebsiteExperience(props) {
  return (<Container>
    <Row>
      <Col xs="7">
        Website area
      </Col>
      <Col xs="5">
        Score Area
      </Col>
    </Row>
  </Container>);
}

export default function MyProductsWebView(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = parseBasePathname(location);
  const productType = parseProductTypePathname(location);
  let cardType = props && props.cardType ? props.cardType : _.replace(pathname, '/', '');
  let routeParams = useParams();
  let productId;
  if (routeParams) {
    productId = parseInt(routeParams.id, 10);
  }
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && currentUser.name;
  const [data, setData] = useState(null);
  const [webExperience, setWebExperience] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [query, setQuery] = useState('');
  const [activeTab, setActiveTab] = useState(tabs.WEB);

  useEffect(() => {
    let links = productId
      ? [
          {
            link: MY_PRODUCTS_ROUTE,
            label: 'My products',
          },
          {
            link: ADMIN_MY_PRODUCTS_3DMODAL,
            label: '3D Models',
          },
          {
            link: null,
            label: productId,
          },
        ]
      : null;
    setBreadcrumbs(links);
  }, [productId]);

  useEffect(async () => {
    refreshData();
  }, []);

  useEffect(() => {
    return () => {
      if (filterCtx) {
        filterCtx.cleanGlobalFiltersOnNavigate();
      }
    };
  }, []);

  useEffect(() => {
    refreshData();
  }, [query, filterCtx?.refListsReady]);

  function refreshData() {
    if (!loading && filterCtx?.refListsReady && productId > 0) {
      setLoading(true);
      const getProductInfo = apiGetProp(productId).then(res => {
        return {productInfo: res}
      })
      const getWebExperience = apiGetProductWebExperience(productId).then(res => {
        return {webExp: res}
      })
      Promise.all(getProductInfo, getWebExperience)
        .then((aggResults) => {
          const newStatus = {};
          aggResults.forEach((r) => Object.assign(newStatus, r));
          const { productInfo, webExp } = newStatus;
          if (productInfo) {
            setData(productInfo);
          }
          if (webExp) {
            setWebExperience(webExp);
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  const saveProject = async () => {
    if (!editItem) return;
    setSaving(true);
    const { name, project_type, tags, filteroptions } = editItem;
    let payload = {
      name: name,
      project_type: project_type?.value,
      // file: filejson,
      // is_favourite: editItem.is_favourite,
      // thumb: thumbnailfile
    };
    if (filteroptions && filteroptions.length > 0) {
      payload.filteroptions = _.chain(filteroptions)
        .map((o) => o.value)
        .join(',')
        .value();
    }
    if (tags && tags.length > 0) {
      payload.tags = _.chain(tags)
        .map((o) => o.label)
        .toArray()
        .value();
    }

    await new Promise((resolve, reject) => {
      apiEditProject(editItem.id, payload)
        .then((res) => {})
        .catch(apiErrorHandler)
        .finally(() => {
          setSaving(false);
          setEditItem(null);
          refreshData();
        });
    });
  };

  function changeTab(tabName) {
    setActiveTab(tabName);
  }

  if (!filterCtx) return <ReactLoader />;
  let mainArea = null;
  switch (activeTab) {
    case tabs.FILES:
      mainArea = <RendersPreviews productId={productId} />;
      break;
    case tabs.RENDERS:
      mainArea = <RendersPreviews productId={productId} />;
      break;
    case tabs.WEB:
    default:
      mainArea = <WebsiteExperience data={data} productId={productId} />;
      break;
  }
  return (
    <>
      {breadcrumbs && (
        <Breadcrumbs
          list={breadcrumbs}
          middleArea={
            <ButtonGroup className="float-start">
              <Button className="topProductButtons" onClick={() => changeTab(tabs.WEB)}>
                {tabs.WEB}
              </Button>
              <Button className="topProductButtons" onClick={() => changeTab(tabs.RENDERS)}>
                {tabs.RENDERS} (9)
              </Button>
              <Button className="topProductButtons" onClick={() => changeTab(tabs.FILES)}>
                {tabs.FILES} (12)
              </Button>
            </ButtonGroup>
          }
          rightArea={<Button className="topProductButtons float-end">Project Info</Button>}
        />
      )}
      {mainArea}
    </>
  );
}
