export const KeyboardIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1697 18.2893H8.82406C8.29469 18.2893 7.91274 18.2691 7.58441 18.2222C5.25923 17.9676 4.79688 16.5739 4.79688 14.2621V10.0272C4.79688 7.71541 5.25253 6.32162 7.59781 6.06699C7.91945 6.02009 8.30139 6 8.82406 6H15.1697C15.6924 6 16.0743 6.02009 16.4093 6.06699C18.7412 6.32162 19.1969 7.71541 19.1969 10.0272V14.2621C19.1969 16.5739 18.7412 17.9676 16.396 18.2222C16.081 18.2691 15.6991 18.2893 15.1697 18.2893ZM8.81735 7.00512C8.34159 7.00512 7.99985 7.02524 7.71842 7.06544C6.27105 7.22626 5.7953 7.67521 5.7953 10.0339V14.2688C5.7953 16.6208 6.27106 17.0764 7.70503 17.2305C7.99986 17.2707 8.3416 17.2908 8.82406 17.2908H15.1697C15.6522 17.2908 15.9872 17.2707 16.2686 17.2305C17.716 17.0697 18.1918 16.6208 18.1918 14.2621V10.0272C18.1918 7.6752 17.716 7.21956 16.282 7.06544C15.9805 7.02524 15.6455 7.00512 15.163 7.00512H8.81735ZM15.5183 11.2401H13.0525C12.7777 11.2401 12.5499 11.0122 12.5499 10.7375C12.5499 10.4628 12.7777 10.2349 13.0525 10.2349H15.5183C15.7931 10.2349 16.0209 10.4628 16.0209 10.7375C16.0209 11.0122 15.7998 11.2401 15.5183 11.2401ZM9.98344 10.7375C9.98344 11.106 10.285 11.4076 10.6535 11.4076C11.0288 11.4076 11.3236 11.106 11.3236 10.7375C11.3236 10.369 11.0221 10.0674 10.6535 10.0674C10.2783 10.0674 9.98344 10.369 9.98344 10.7375ZM8.54251 11.4076C8.17397 11.4076 7.87243 11.106 7.87243 10.7375C7.87243 10.369 8.16727 10.0674 8.54251 10.0674H8.54921C8.91775 10.0674 9.21929 10.369 9.21929 10.7375C9.21929 11.106 8.91105 11.4076 8.54251 11.4076ZM8.47592 15.1197H15.5184C15.7999 15.1197 16.021 14.8919 16.021 14.6171C16.021 14.3424 15.7932 14.1146 15.5184 14.1146H8.46251C8.18778 14.1146 7.96666 14.3424 7.96666 14.6171C7.96666 14.8919 8.20119 15.1197 8.47592 15.1197Z"
        fill="currentColor"
      />
    </svg>
  );
};
