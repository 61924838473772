const ImageIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="56"
      viewBox="0 0 72 56"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3418 39.989H32.6472C26.5886 39.989 24 37.4004 24 31.3418V24.6472C24 18.5886 26.5886 16 32.6472 16H39.3418C45.4004 16 47.989 18.5886 47.989 24.6472V29.9793C48.0036 30.0688 48.0037 30.1606 47.989 30.2509V31.3418C47.989 37.4004 45.4004 39.989 39.3418 39.989ZM46.3154 30.4918V31.3418C46.3154 36.4855 44.4855 38.3154 39.3418 38.3154H32.6472C29.5764 38.3154 27.6867 37.6632 26.6623 36.043L31.5538 32.7589C32.1228 32.3684 32.9708 32.413 33.484 32.8482L33.8523 33.1717C35.0461 34.1871 36.9095 34.1871 38.0922 33.1717L42.7338 29.1884C43.2917 28.7087 44.2401 28.7087 44.7979 29.1884L46.3154 30.4918ZM46.3154 28.2806L45.8914 27.9165C44.6975 26.9011 42.8342 26.9011 41.6515 27.9165L37.0099 31.8998C36.4632 32.3795 35.5147 32.3795 34.9569 31.8998L34.5887 31.5762C33.4952 30.6501 31.8327 30.5497 30.6277 31.3642L26.0039 34.4686C25.7771 33.6004 25.6737 32.5647 25.6737 31.3418V24.6472C25.6737 19.5035 27.5035 17.6737 32.6472 17.6737H39.3418C44.4855 17.6737 46.3154 19.5035 46.3154 24.6472V28.2806ZM32.6472 26.5998C30.9512 26.5998 29.5788 25.2274 29.5788 23.5314C29.5788 21.8355 30.9512 20.4631 32.6472 20.4631C34.3432 20.4631 35.7156 21.8355 35.7156 23.5314C35.7156 25.2274 34.3432 26.5998 32.6472 26.5998ZM32.6472 22.1367C31.8773 22.1367 31.2525 22.7616 31.2525 23.5314C31.2525 24.3013 31.8773 24.9261 32.6472 24.9261C33.4171 24.9261 34.0419 24.3013 34.0419 23.5314C34.0419 22.7616 33.4171 22.1367 32.6472 22.1367Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ImageIcon;
