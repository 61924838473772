import { useUndoRedoInput } from '../../../hooks/useUndoRedoInput';

export const UndoRedoInput = ({
  undoRedoAction,
  undoRedoData,
  value,
  onBlur,
  onFocus,
  ...rest
}) => {
  const { handleBlur, handleFocus } = useUndoRedoInput({
    value,
    onFocus,
    onBlur,
    undoRedoAction,
    data: undoRedoData,
  });

  return <input {...rest} value={value} onBlur={handleBlur} onFocus={handleFocus} />;
};
