export const FileIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98757 19.1998H14.0062C17.643 19.1998 19.1969 17.6459 19.1969 14.0091V10.6603C19.1969 10.5797 19.1773 10.5031 19.1426 10.4351C19.1189 10.3875 19.0877 10.3434 19.0495 10.3053L13.6914 4.94715C13.6772 4.93356 13.6623 4.92085 13.6469 4.90904C13.5611 4.84082 13.453 4.7998 13.3364 4.7998H9.98757C6.35074 4.7998 4.79688 6.35367 4.79688 9.9905V14.0091C4.79688 17.6459 6.35074 19.1998 9.98757 19.1998ZM12.8341 5.80446H9.98757C6.89994 5.80446 5.80153 6.90287 5.80153 9.9905V14.0091C5.80153 17.0967 6.89994 18.1952 9.98757 18.1952H14.0062C17.0938 18.1952 18.1922 17.0967 18.1922 14.0091V11.1626H16.0155C13.7249 11.1626 12.8341 10.2718 12.8341 7.98119V5.80446ZM13.8387 7.98119V6.5144L17.4823 10.1579H16.0155C14.2875 10.1579 13.8387 9.70919 13.8387 7.98119Z"
        fill="white"
      />
    </svg>
  );
};
