const ProductIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6391 23.9665H15.3274C21.1022 23.9665 23.7129 21.6212 23.9488 16.1701C23.9819 16.0808 24 15.9847 24 15.8848C24 15.8016 23.9874 15.7209 23.9641 15.6447C23.9657 15.54 23.9665 15.4343 23.9665 15.3274V8.6391C23.9665 2.58616 21.3804 0 15.3274 0H8.6391C2.58616 0 0 2.58616 0 8.6391V15.3274C0 21.3804 2.58616 23.9665 8.6391 23.9665ZM22.2944 15.0487V8.6391C22.2944 3.50023 20.4663 1.67208 15.3274 1.67208H8.6391C3.50023 1.67208 1.67208 3.50023 1.67208 8.6391V15.0487H22.2944ZM22.2423 16.7208H1.72423C2.05475 20.8013 3.99494 22.2944 8.6391 22.2944H15.3274C19.9716 22.2944 21.9118 20.8013 22.2423 16.7208Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ProductIcon;
