import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CURSOR_MODE } from "../config/constant/unityConstants";
import { resetCursorModeConfig, setCursorMode } from "../redux/slicers/admin/curatorSlicer";
import { setSelectedObjectsCountStr } from "../redux/slicers/admin/curatorUnityObjectSlicer";

export const useUnityObjectSelection = () => {
  // TODO: use pubsub instead 
  const dispatch = useDispatch();

  useEffect(() => {
    window.NoObjectSelectedForGizmoReceiver = () => {
      dispatch(resetCursorModeConfig());
      dispatch(setCursorMode(CURSOR_MODE.STYLE_ONLY));
    }

    // window.ObjectSelectionTypeForGizmoReceiver = (message) => {
    //   console.log('ObjectSelectionTypeForGizmoReceiver', message)
    //   dispatch(setSelectedObjectsCountStr(message))
    // } 

    window.DisableMeasureMentToolReceiver = () => {
      // TODO: disable measurement tool on  react end when it's ready
    }
  }, [])
}

