export const ColorPickerIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1969 4.7998C19.1969 4.7998 19.1969 7.7998 17.9969 8.9998L14.9969 5.9998C16.1969 4.7998 19.1969 4.7998 19.1969 4.7998ZM7.42408 18.2998L14.9241 10.7998L13.1969 9.0726L5.69688 16.5726V18.2998H7.42408ZM4.79688 16.1998L13.1969 7.7998L16.1969 10.7998L7.79688 19.1998H4.79688V16.1998ZM18.3655 10.32L13.5437 5.4981L12.9073 6.13449L17.7291 10.9564L18.3655 10.32Z"
        fill="currentColor"
      />
    </svg>
  );
};
