import cx from "classnames";
import { useState } from "react";
import {
  SketchPicker,
  SliderPicker,
  CustomPicker,
} from "react-color";
import { ColorInput } from "../FormElements/ColorInput";
import styles from "./ColorPicker.module.scss";
import { ColorTypeSwitcher } from "./ColorTypeSwitcher";
import { EyeDropperButton } from "./EyeDropperButton";
import { RgbPicker } from "./RgbPicker";

export const ColorPicker = CustomPicker(({ color, className, hsv, rgb, hex, onChange, ...rest }) => {
  const [colorType, setColorType] = useState('RGB')

  return (
    <div className={styles.root}>
      <SketchPicker
        // {...rest} this causing issues when onChangeComplete is triggered 2 times
        color={color}
        onChange={onChange}
        className={cx(styles.colorPicker, className)}
        disableAlpha
        styles={{
          default: {
            Alpha: {
              display: "none",
            },
            Saturation: {
              display: "none",
            },
            alpha: {
              display: "none",
            },
          },
        }}
      />
      <SliderPicker
        color={color}
        onChange={onChange}
        className={styles.slider}
        styles={{
          default: {
            Hue: {
              display: 'none',
              height: '40px',
              borderRadius: '0px 0px 8px 8px',
              marginTop: '2px',
            }
          }
        }}
      />
      <div className={`my-3 ${styles.topRow}`}>
        <ColorTypeSwitcher value={colorType} onChange={setColorType} />
        <EyeDropperButton onChange={onChange} />
        <ColorInput
          value={hex}
          onChange={onChange}
        />
      </div>
      

      <RgbPicker color={color} hsv={hsv} rgb={rgb} onChange={onChange} colorType={colorType} />

      <div className="my-3" />

    </div>
  );
});
