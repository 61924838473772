import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import ProductIcon from '../../icons/productIcon';
import StyleIcon from '../../icons/styleIcon';
import CameraIcon from '../../icons/cameraIcon';
import ImageIcon from '../../icons/imageIcon';
import SettingsIcon from '../../icons/settingsIcon';
import MetaDataIcon from '../../icons/metaDataIcon';
import MoreIcon from '../../icons/moreIcon';
import LightIcon from '../../icons/lightIcon';
import SidebarSections from './sidebarSections';
import {
  curatorSelector,
  curatorActions,
  selectCuratorInitialized,
  selectIs2DMode,
  toggleSidebarExpanded,
} from '../../../redux/slicers/admin/curatorSlicer';

import styles from './sidebar.v2.module.scss';
import UnityUI from '../../../pages/admin/curator/unityUI';
import _ from 'lodash';
import { editCuratorSetting, getSidePanelOptionsApi, getSidePanelOptionsConfigApi } from '../../../api/curator';
import { ExpandImages } from './components/images/ExpandImages';
import {
  rendersPreviewsExpSelector,
  setRenderPreviewExpand,
} from '../../../redux/slicers/renders-previews/rendersPreviewsSlicer';
import { memo, useEffect, useMemo, useState } from 'react';
import { selectUnitySelectedObjectInfo } from '../../../redux/slicers/admin/curatorUnityObjectSlicer';
import {
  CURATOR_MENU_SECTION,
  selectEnabledSections,
  selectSidebarDefaultConfig,
} from '../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import { toast } from 'react-toastify';
import { toggleSidebarSection } from '../../../helpers/curatorActions';
import { HIDDEN_SECTIONS } from '../../../config/constant/unityConstants';
import { errorToast } from '../../../helpers/toastHelper';

export const SidebarV2 = memo(() => {
  const dispatch = useDispatch();
  const enabledSections = useSelector(selectEnabledSections);
  const curatorInitialized = useSelector(selectCuratorInitialized);
  
  const is2DMode = useSelector(selectIs2DMode);
  const sidebarConfig = useSelector(selectSidebarDefaultConfig)

  const sidebarActiveSection = useSelector((state) => curatorSelector(state).sidebarSection);

  const unitySelectedObjectInfo = useSelector(selectUnitySelectedObjectInfo);

  useEffect(() => {
    dispatch(getSidePanelOptionsApi())
    dispatch(getSidePanelOptionsConfigApi());
  }, [])

  useEffect(() => {
    // close styles section if object is not styleable
    if (sidebarActiveSection === CURATOR_MENU_SECTION.STYLE && !unitySelectedObjectInfo.raw) {
      toggleSidebarSection(null);
    }
  }, [unitySelectedObjectInfo.raw === undefined]);

  useEffect(() => {
    dispatch(curatorActions.toggleFilterOpen(null));
    dispatch(setRenderPreviewExpand({}));
    dispatch(toggleSidebarExpanded(false));
  }, [sidebarActiveSection]);


  const menuItems = useMemo(() => {
    const sectionsConfig = {
      [CURATOR_MENU_SECTION.PRODUCTS]: {
        
        Icon: ProductIcon,
      },
      [CURATOR_MENU_SECTION.STYLE]: {
        
        Icon: StyleIcon,
        preventOpen: !unitySelectedObjectInfo.raw ? () => {
          errorToast('Please select an object first')
        } : undefined,
        disabled: !unitySelectedObjectInfo.raw,
      },
      [CURATOR_MENU_SECTION.CAMERAS]: {
        
        Icon: CameraIcon,
        disabled: is2DMode,
        preventOpen: is2DMode ? () => {
          errorToast('Please switch to 3d mode to use cameras')
        } : undefined,
      },
      [CURATOR_MENU_SECTION.RENDERS_AND_PREVIEWS]: {
        
        Icon: ImageIcon,
      },
      [CURATOR_MENU_SECTION.SETTINGS]: {
        
        Icon: SettingsIcon,
      },
      [CURATOR_MENU_SECTION.METADATA]: {
        
        Icon: MetaDataIcon,
      },
      [CURATOR_MENU_SECTION.LIGHT]: {
        
        Icon: LightIcon,
      },
    }
    
    let menuConfig = sidebarConfig.map(item => {
      return {
        name: item.unique_name,
        label: item.display_name,
        seqNo: item.seq_no,
        isDefault: item.is_default,
        ...(sectionsConfig[item.unique_name] ? sectionsConfig[item.unique_name] : {})
      }
    })
    
    menuConfig = menuConfig.filter((section) => {
      if (HIDDEN_SECTIONS.includes(section.name)) return false;

      return section.isDefault || enabledSections.includes(section.name);
    });

    menuConfig.sort((a, b) => a.seqNo - b.seqNo)

    return menuConfig
  }, [sidebarConfig, enabledSections, unitySelectedObjectInfo, is2DMode]);

  console.log('menuItems', menuItems)

  const handleClick = (sectionName) => () => {
    const config = menuItems.find(m  => m.name === sectionName)

    if (config?.preventOpen) {
      config.preventOpen()
      return;
    }

    if (config?.disabled) {
      return;
    }

    toggleSidebarSection(sectionName)
  };

  return (
    <section className="curator-sidebar">
      <div
        className={cx(
          'body-of-content d-flex',)}>
        {/* TODO: refactor layout and remove unity ui from sidebar */}
        <UnityUI className={cx(styles.unity, curatorInitialized && styles.unitySmall)} />

        <div className={cx(styles.sidebar, curatorInitialized && styles.sidebarVisible)}>
          <ul className="sidebar-content main-menu">
            {menuItems.map((menuItem) => (
              <li
                key={menuItem.name}
                onClick={handleClick(menuItem.name)}
                className={cx('product-menu', styles.menuItem, {
                  active: sidebarActiveSection === menuItem.name,
                  [styles.disabled]: menuItem.disabled,
                })}>
                <span className={cx(styles.iconContainer, 'mb-2')}>
                  {menuItem.Icon && <menuItem.Icon />}
                </span>
                {menuItem.label}
              </li>
            ))}
          </ul>

          <ul className="sidebar-content mb-0" id="more-menu">
            <li
              className={cx('more', {
                active: sidebarActiveSection === CURATOR_MENU_SECTION.MORE,
              })}
              onClick={handleClick(CURATOR_MENU_SECTION.MORE)}>
              <MoreIcon className="mb-2" />
              More
            </li>
          </ul>
        </div>
        <ExpandImages />
      </div>

      <SidebarSections />
    </section>
  );
});
