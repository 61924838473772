import cx from "classnames";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import { ROOM1 } from "../../../../config/constant/assetConstant";
import styles from "./PreviewUI.module.scss";
import { ActionsButtons } from "./ActionsButtons";
import isEmpty from "lodash/isEmpty";

export const PreviewUIDisplay = ({
  className,
  isFullscreen,
  onClose,
  title,
  actions,
  toggleFullscreen,
  slides = [
    'http://images.astronet.ru/pubd/2003/08/18/0001192435/orionfull_jcc_big.jpg',
    'https://1.cms.s81c.com/sites/default/files/2020-11-18/big-data-analytics-leadspace_0.jpg',
    ROOM1,
  ]
}) => {
  return (
    <div className={cx(styles.root, isFullscreen && styles.fullscreen, className)}>
      <button className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </button>

      <div
        id="carouselExampleControls"
        className={cx(
          styles.carouselContainer,
          "d-flex justify-content-center carousel slide"
        )}
        data-bs-ride="carousel"
      >
        <div className="carousel-inner w-100 rounded-0">
          {slides && slides.map((slide, idx) => {
            return (
              <div className={cx('carousel-item', idx === 0 ? 'active' : '')} key={idx}>
                <img
                  src={slide}
                  alt="..."
                />
              </div>
            )
          })}

        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <footer className={styles.footer}>
        <h1 className={styles.title}>{title}</h1>
        {!isEmpty(actions) && <ActionsButtons items={actions} onFullScreenClick={toggleFullscreen} />}
      </footer>
    </div>
  );
};
