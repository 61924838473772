import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../assets';
import styles from '../assets/scss/modules/_previewUI.module.scss';
import { RenderIconFilled } from '../components/icons/RenderIconFilled';
import { FAVOURITE_ICON } from '../constants/assetConstants';

const {
  InfoIcon,
  CommentIcon,
  EditIcon,
  ShareIcon,
  CollectionIcon,
  HeartLineIcon,
  FullScreenIcon,
  BinIcon,
  HeadsetIcon,
  DownloadIcon,
} = icons;

export function ActionsButtons({ items, onFullScreenClick }) {
  const ACTION_CONFIG = {
    render: {
      label: 'Render',
      icon: <RenderIconFilled style={{ color: 'var(--primary-color)' }} />,
    },
    download: {
      label: 'Download',
      icon: <DownloadIcon />,
    },
    info: {
      label: 'Info',
      icon: <InfoIcon />,
    },
    comment: {
      label: 'Comments',
      icon: <CommentIcon />,
    },
    edit: {
      label: 'Edit file',
      icon: <EditIcon />,
    },
    share: {
      label: 'Share',
      icon: <ShareIcon />,
    },
    collection: {
      label: 'Add to collection',
      icon: <CollectionIcon />,
    },
    favourite: {
      label: 'Favourite',
      icon: <HeartLineIcon />,
      iconActive: <img src={FAVOURITE_ICON} className={styles.activeImage} />,
    },
    fullscreen: {
      label: 'Full screen',
      icon: <FullScreenIcon />,
      onClick: onFullScreenClick,
    },
    delete: {
      label: 'Delete',
      icon: <BinIcon />,
    },
    help: {
      label: 'Help',
      icon: <HeadsetIcon />,
    },
  };

  return (
    <div className={styles.actions}>
      {items?.map((action, idx) => {
        const { type, label, onClick, to, target, icon, active } = action;

        const config = ACTION_CONFIG[type];
        const actionLabel = label || config?.label;
        let actionIcon = icon || config?.icon;
        const Component = to ? Link : 'button';

        if (type === 'favourite' && active) {
          actionIcon = config.iconActive
        }
        

        const handleClick = () => {
          config?.onClick?.();
          onClick?.();
        };

        return (
          <Component
            to={to}
            onClick={handleClick}
            target={target}
            key={idx}
            className={styles.actionButton}>
            {actionIcon}
            <span className={styles.actionText}>{actionLabel}</span>
          </Component>
        );
      })}
    </div>
  );
}
