import { useEffect, useState } from "react";

export const useToggleListItem = ({ reset, items } = {}) => {
  const [ids, setIds] = useState([]);

  const toggleItem = (item) => {
    ids.includes(item.id) 
      ? setIds(ids.filter(id => id !== item.id))
      : setIds([...ids, item.id]);
  }

  useEffect(() => {
    if (reset) setIds([])
  }, [reset])

  const resetSelectedItems = (id) => {
    if (id) {
      setIds(ids.filter(_id => _id !== id))
    } else {
      setIds([])
    }
  }

  const toggleAll = () => {
    if (ids.length === items.length) {
      setIds([])
    } else {
      setIds(items.map(item => item.id))
    }
  }

  return {
    ids,
    toggleItem,
    resetSelectedItems,
    setIds,
    toggleAll,
    allSelected: items?.length ===  ids.length,
  }
}