import {createSlice} from "@reduxjs/toolkit";

const session = createSlice({
    name: 'session',
    initialState: {
        session: false,
        sessionData: {},
        sessionLoading: true
    },
    reducers: {
        setSession: (state, {payload}) => {
            state.session = payload;
        },
        sessionLoading: (state, {payload}) => {
            state.sessionLoading = payload;
        },
        setSessionFail: (state, {payload}) => {
            state.session = false;
            state.sessionData = {};
        }
    }
})

export const {setSession, setSessionFail} = session.actions;
export const sessionSelector = state => state.session;
export const sessionReducer = session.reducer;
export default sessionReducer