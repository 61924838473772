import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../../Button/Button';
import styles from './CameraNameForm.module.scss';

export const CameraNameForm = ({ onCancel, onSubmit, loading, defaultName }) => {
  const [name, setName] = useState(defaultName || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;

    onSubmit({ name })
  }

  return (
    <form className={cx(styles.form)} onSubmit={handleSubmit}>
      <Form.Group className="w-100">
        <Form.Control
          className={styles.input}
          placeholder="Enter a camera name"
          // className={`${error ? 'error' : ''}`}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>
      <Button color="light" type="button" size="xlg" className="px-3" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="submit" color="warning" size="xlg" className="px-3" loading={loading}>
        Save
      </Button>
    </form>
  );
};
