import { useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { CommonGrid, TopContainer } from '../layouts';
import {
  TopButtonArea,
  ThumbnailContainer,
  InfiniteScroll,
  ActionFilters,
  GlobalFilter,
  SharePopup,
  DeactivatePopup,
} from '../common';
import _ from 'lodash';
import { FilterContext, UserContext } from '../contexts';
import {
  getCommonMetadata,
  addValueLabelToList,
  constructQueryParams,
  changeFilter,
  setTitle,
} from '../helpers/jsHelper';
import { apiErrorHandler } from '../helpers/axiosHelper';
import { COLLECTION_ID } from '../constants/routePathConstants';
import { apiGetCollections, parseBasePathname, apiDeleteCollections } from '../helpers/api';
import { getScreenSettings } from '../helpers/configureHelper';
import { ReactLoader } from '../layouts/Loading';
import { SHARE_ICON, DELETE_ICON } from '../constants/assetConstants';

let title = 'Collections';

export default function Collections() {
  const location = useLocation();
  const pathname = parseBasePathname(location);
  let cardType = _.replace(pathname, '/', '');
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && currentUser.name;
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sortField, setSortField] = useState();
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [total, setTotal] = useState(0);

  const pageSettings = getScreenSettings(cardType);
  const { sortOptions, filterOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter } =
    pageSettings;

  useEffect(() => {
    if (!sortField && defaultSortOption) {
      setSortField(defaultSortOption);
    }
  }, [sortOptions, sortField]);

  useEffect(async () => {
    refreshList();
    setTitle(title);
    filterCtx?.cleanGlobalFiltersOnNavigate();
  }, []);

  useEffect(() => {
    return () => {
      filterCtx?.cleanGlobalFiltersOnNavigate();
    };
  }, []);

  useEffect(() => {
    let params = constructQueryParams(
      sortField,
      filters,
      null,
      filterCtx?.globalFilters,
      filterCtx?.teamMembers
    );
    if (params !== query) {
      setQuery(params);
    }
  }, [sortField, filters, filterCtx?.globalFilters, filterCtx?.teamMembers]);

  useEffect(() => {
    refreshList();
  }, [query, filterCtx?.refListsReady]);
  useEffect(addDataToList, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    refreshList();
  }, [userCtx.currentUser.organization]);

  function refreshList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      apiGetCollections(query)
        .then((res) => {
          if (res) {
            if (res.total_pages) {
              setTotalPages(res.total_pages);
            }
            // if (res.current_page_number) {
            //   setPageNumber(res.current_page_number);
            // }
            if (res.results) {
              let newList = addValueLabelToList(res.results);
              setTotal(res.count);
              setList(newList);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  function addDataToList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      apiGetCollections(query + `&page=${pageNumber}`)
        .then((res) => {
          if (res) {
            if (res.total_pages) {
              setTotalPages(res.total_pages);
            }
            if (res.results) {
              let newList = addValueLabelToList(res.results);
              setTotal(res.count);
              setList([...list, ...newList]);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  function handleCardSelect(id, allOrNone = null) {
    let newList = _.map(list, (item) => {
      if (item.id === id) {
        item.selected = item.selected ? false : true;
      }
      if (_.isBoolean(allOrNone)) {
        item.selected = allOrNone;
      }
      return item;
    });
    setList(newList);
  }

  function handleSort({ value }) {
    setPageNumber(1);
    setSortField(value);
  }

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber((prev) => prev + 1);
    }
  }

  function handleFilter(field, selection) {
    setPageNumber(1);
    setFilters(changeFilter(filters, field, selection));
  }

  function onChange(field, value) {
    let item = { ...editItem };
    item[field] = value;
    setEditItem(item);
  }

  const onEdit = async (id) => {
    let itemToEdit = _.find(list, (p) => {
      return p.id === id;
    });
    setEditItem(itemToEdit);
  };

  const { hasData, anySelected, allSelected, selectedList } = getCommonMetadata(
    list,
    pageNumber,
    totalPages
  );

  const hasMore = pageNumber < totalPages;

  if (!filterCtx) return <ReactLoader />;
  return (
    <CommonGrid
      topContainer={
        <TopContainer
          title={
            <>
              {title} {total && <small>({total})</small>}
            </>
          }
          item={cardType}
          loading={loading || !filterCtx?.refListsReady}
          hasData={hasData}
          buttonArea={
            <TopButtonArea
              hasData={hasData}
              item={cardType}
              topButtonList={[
                {
                  element: DeactivatePopup,
                  bigButton: true,
                  isPlural: true,
                  iconSrc: DELETE_ICON,
                  deleteAction: apiDeleteCollections,
                  item: cardType,
                  onSuccess: refreshList,
                  actionWord: 'delete',
                },
                {
                  element: SharePopup,
                  bigButton: true,
                  onSuccess: refreshList,
                },
              ]}
              addNewButtonArea={null}
              anySelected={anySelected}
              selectedList={selectedList}
              loading={!filterCtx?.refListsReady || loading}
            />
          }
        />
      }
      hasData={hasData}
      list={list}
      filterArea={
        <>
          <ActionFilters
            hasData={hasData}
            filterProps={{
              value: filters?.project_type?.selection,
              data: filterOptions,
              onChange: (e) => handleFilter('project_type', e),
            }}
            sortProps={{
              data: sortOptions,
              onChange: (e) => handleSort(e),
            }}
            selectedProps={{
              allSelected: allSelected,
              onSelectAll: (checked) => handleCardSelect(null, checked),
            }}
            showTeamMemberFilter={showTeamMemberFilter}
            loading={loading}
          />
          <GlobalFilter />
        </>
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={!filterCtx?.refListsReady || loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainer
          data={list}
          cardType={cardType}
          selectedList={selectedList}
          onEdit={onEdit}
          onSelect={handleCardSelect}
          clickUrl={COLLECTION_ID} // :id gets dynamically replaced when the list is iterated over
          showFavoriteIcon={showFavoriteIcon}
          stackedCardImages={true}
          buttonAreaClass="collectionButtons"
          cardButtonList={[
            {
              element: SharePopup,
              miniButton: true,
              iconSrc: SHARE_ICON,
              buttonLabel: <span className="mx-2">Share</span>,
            },
            {
              element: DeactivatePopup,
              miniButton: true,
              iconSrc: DELETE_ICON,
              isPlural: false,
              item: cardType,
              onSuccess: refreshList,
              deleteAction: apiDeleteCollections,
              actionWord: 'delete',
              buttonLabel: <span className="mx-2">Delete</span>,
            },
          ]}
        />
      </InfiniteScroll>
    </CommonGrid>
  );
}
