import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { curatorMetadataSelector, selectAddMetadataObjectName, setAddMetadataObjectName, toggleEditMetadata, updateMetadataTitle } from "../../../../redux/slicers/admin/curatorMetadataSlicer";
import { useUnityContext } from "../../../container/unityContainer";
import { actions  } from '../../../../redux/slicers/admin/curatorMetadataSlicer'
import { useInfoDialogaActions } from "../../../../hooks/useInfoDialogaActions";
import { errorToast, successToast } from "../../../../helpers/toastHelper";
import { getAppState } from "../../../../redux/store";

export const useUnityMetadata = () => {
  const dispatch = useDispatch();
  const unityContext = useUnityContext();
  const { metadata } = useSelector(curatorMetadataSelector)
  const deleteMetadataRef = useRef([]);
  const editMetaDataRef = useRef(null);
  const { openInfoDialog } = useInfoDialogaActions();

  useEffect(() => {
    window.OnReceiveRoomMetadata = (metadata) => {
      console.log('OnReceiveRoomMetadata', metadata)
      if (!metadata) return;
      
      try {
        dispatch(actions.setMetadata(JSON.parse(metadata)));
      } catch(e) {
        // TODO: add logger here
        console.error(e)
      }
    };

    window.OnMetadataAdded = (dataJson) => {
      const data = JSON.parse(dataJson)
      dispatch(actions.addMetadata(data))
      dispatch(setAddMetadataObjectName(null))
    };

    window.OnDeleteRoomMetadata = (status) => {
      if (status !== 1) {
        errorToast("Something went wrong during metadata deletion");
        return;
      }
      deleteMetadataRef.current.forEach(metadata => {
        dispatch(actions.deleteMetadata(metadata))
        deleteMetadataRef.current = deleteMetadataRef.current.filter(md => md.title !== metadata.title)
      })
    }

    window.OnEditMetadataComplete = (status) => {
      console.log('sasha OnEditMetadataComplete', status)
      if (status !== 1) {
        errorToast("Something went wrong during metadata deletion");
        return;
      }

      console.log('sasha OnEditMetadataComplete', 'before check ref', editMetaDataRef.current)
      if (!editMetaDataRef.current) return;

      const { newTitle, metadata } = editMetaDataRef.current;
      dispatch(updateMetadataTitle({
        title: metadata.title,
        newTitle,
      }))
      dispatch(toggleEditMetadata({ title: newTitle }))
      console.log('sasha OnEditMetadataComplete', 'after dispatch')
      editMetaDataRef.current = null;
    }

    window.ShowAddMetadataPopup = (enabled, objectName) => {
      dispatch(setAddMetadataObjectName(objectName));
    };

    window.OnMessageReceivedFromUnity = (messageJson) => {
      const message = JSON.parse(messageJson)

      if (message.isError) {
        errorToast(message.message)
      } else {
        successToast(message.message);
      }
    }

    // TODO: remove it after testing
    window.metadataModule = unityContext.metadataModule;
  }, []);

  const onAddIntent = () => {
    unityContext.metadataModule.OnAddMetadataButtonClickReceiver();
    // TOOD: use info toast here
    successToast('Select an object')
  }

  const addMetadata = ({ name }) => {
    const objectName = selectAddMetadataObjectName(getAppState())
    unityContext.metadataModule.AddMetadataReceiver({
      metadataName: name,
      objectName,
    })
    successToast('Metadata added successfully')
  }

  const deleteMetadata = (metadata) => {
    openInfoDialog({
      title: `Delete "${metadata.title}"?`,
      buttonText: 'Yes',
      cancelButtonText: 'No',
      onButtonClick: () => {
        deleteMetadataRef.current.push(metadata);
        // TODO: use pub sub and wait for event instead of ref
        unityContext.metadataModule.DeleteMetadataReceiver(metadata.title);
        successToast('Metadata has been deleted')
      }
    })
  }

  const selectMetadata = (metadata) => {
    unityContext.metadataModule.SelectMetadataReceiver(metadata.title);
  }

  const updateMetadata = (metadata, newTitle) => {
    // TODO: use promise await here instead of ref
    editMetaDataRef.current = {
      metadata, 
      newTitle,
    }
    unityContext.metadataModule.EditMetadataReceiver(metadata.title, newTitle);
    successToast('Metadata saved')
  }

  const cancelAddMetadata = () => {
    dispatch(setAddMetadataObjectName(null))
    unityContext.metadataModule.CloseFocusObjectReceiver();
  }

  return {
    metadata,

    addMetadata,
    onAddIntent,
    updateMetadata,
    deleteMetadata,
    selectMetadata,
    cancelAddMetadata,
  };
};
