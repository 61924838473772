import api from "../helpers/axiosHelper";
import {
  API_ROOT_URL,
  CREATE_ROOM,
  GET_PROJECT_API,
  PROPS_API,
  ROOM_DETAILS_API,
  ROOM_LAYOUT_API,
  COLLECTION_DETAILS_API
} from "../helpers/api";
import { setMyProjectsData } from "../redux/slicers/my-projects/myProjectsSlicer";
import { getLocalStorage } from "../helpers/jsHelper";
import { AUTH_TOKEN } from "../constants/projectConstants";
import axios from "axios";
import { setRooms } from "../redux/slicers/admin/roomsSlicer";
import { setProps } from "../redux/slicers/admin/propsSlicer";

export const searchFilterApi = (query) => (dispatch) => {
  let data = query.map((ele) => `${ele.key}=${ele.value}`).join("&");

  api()
    .root(API_ROOT_URL)
    .get(`${GET_PROJECT_API}?&${data}`)
    .success((res) => {
      dispatch(setMyProjectsData(res));
    })
    .error(() => {})
    .send(() => {});
};

export const paginationApi = (query, path, arr) => (dispatch) => {
  const token = getLocalStorage(AUTH_TOKEN);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  axios
    .get(query, config)
    .then((res) => {
      let new_arr = { ...arr };

      let arr_concat = [...new_arr.results, ...res.data.results.map((a) => a)];
      new_arr["next_link"] = res?.data?.next_link;
      new_arr["results"] = arr_concat;

      switch (path) {
        case "project":
          dispatch(setMyProjectsData(new_arr));
          break;
        case "rooms":
          dispatch(setRooms(new_arr));
          break;
        case "props":
        default:
          dispatch(setProps(new_arr));
          break;
      }
    })
    .catch((err) => console.log(err));
};

export const apiFunction = (isFavourite, isColan, isFurnish) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${GET_PROJECT_API}`)
    .success((res) => {})
    .error(() => {})
    .send(() => {});
};

export const apiBrands = (Brands) => (dispatch) => {
  let brand = "";
  if (Brands) {
    brand = "?brand=" + Brands;
  }
  return api()
    .root(API_ROOT_URL)
    .get(`${GET_PROJECT_API}${brand}`)
    .success((res) => {
      if (dispatch) {
        dispatch(setMyProjectsData(res));
      } else {
        return res;
      }
    })
    .error(() => {})
    .send(() => {});
};
export const apiproducts = (filterlabels) => (dispatch) => {
  let Products = "";
  if (filterlabels) {
    Products = "?Products=" + filterlabels;
  }
  return api()
    .root(API_ROOT_URL)
    .get(`${GET_PROJECT_API}${Products}`)
    .success((res) => {
      dispatch(setMyProjectsData(res));
    })
    .error(() => {})
    .send(() => {});
};
export const apiCategory = (Categories) => (dispatch) => {
  let category = "";
  if (Categories) {
    category = "?category" + Categories;
  }
  return api()
    .root(API_ROOT_URL)
    .get(`${GET_PROJECT_API}${category}`)
    .success((res) => {
      if (dispatch) {
        dispatch(setMyProjectsData(res));
      } else {
        return res;
      }
    })
    .error(() => {})
    .send(() => {});
};

export const apiCollections = () => (dispatch) => {
  return api()
    .root(API_ROOT_URL)
    .get(`${COLLECTION_DETAILS_API}`)
    .success((res) => {
      if (dispatch) {
        dispatch(setMyProjectsData(res));
      } else {
        return res;
      }
    })
    .error(() => {})
    .send(() => {});
};
