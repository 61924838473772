/* eslint-disable no-debugger */
import PubSub from 'pubsub-js'
import { FOV_MIN_VALUE } from '../config/constant/projectConstant';
import { setObjectPositionAlongAxis } from '../redux/slicers/admin/curatorUnityObjectSlicer';
import { setGlobalFov } from '../redux/slicers/camera/cameraSettings';
import { dispatcher } from './projectHelper';
import { toUnityFixed } from './unityHelper';

export const UnityEvent = {
  // unity events
  FocusObjectNameReceiver: 'FocusObjectNameReceiver',
  SwitchTransformTypeReceiver: 'SwitchTransformTypeReceiver',
  AllPropsLoaded: 'AllPropsLoaded',
  GetRoomInfo: 'GetRoomInfo',
  GetSelectedObjectInfoOnSelection: 'GetSelectedObjectInfoOnSelection',
  PredefinedCameraDetailsReceiver: 'PredefinedCameraDetailsReceiver',
  GetLightData: 'GetLightData',
  CameraFocalLengthReceiver: 'CameraFocalLengthReceiver',
  UpdatedFovReceiver: 'UpdatedFovReceiver',
  OnLibraryPropInstantiated: 'OnLibraryPropInstantiated',
  OnDeleteOperationCommpleted: 'OnDeleteOperationCommpleted',
  OnReceiveObjectPositionAlongAxis: 'OnReceiveObjectPositionAlongAxis',
  OnReceieveCurrentCameraScreenshot: 'OnReceieveCurrentCameraScreenshot',
}

export const AppEvent = {
  ResolveTextureConflicts: 'ResolveTextureConflicts',
}

export const KEYBOARD_EVENT = {
  ESC_PRESSED: 'ESC_PRESSED',
}

window.FocusObjectNameReceiver = (objectName) => {
  PubSub.publish(UnityEvent.FocusObjectNameReceiver, objectName);
}

window.SwitchTransformTypeReceiver = (message) => {
  PubSub.publish(UnityEvent.SwitchTransformTypeReceiver, message);
}


window.AllPropsLoaded = () => {
  const data = {
    method:'props-loaded',
    data:{id:'loaded'}
  }
  // TODO: this is some old implementation -> refactor it later
  window.postMessage(data);

  PubSub.publish(UnityEvent.AllPropsLoaded, {});
}

window.GetRoomInfo = (roomInfo) => {
  localStorage.setItem('roomInfo',roomInfo)
  console.log('roomInfo', JSON.parse(roomInfo))
  PubSub.publish(UnityEvent.GetRoomInfo, JSON.parse(roomInfo));
}


window.GetSelectedObjectInfoOnSelection = (objectList) => {
  console.log('GetSelectedObjectInfoOnSelection', objectList)
  PubSub.publish(UnityEvent.GetSelectedObjectInfoOnSelection, JSON.parse(objectList) || []);
}

window.PredefinedCameraDetailsReceiver = (cameraPresetsJson) => {
  const cameraPresetsList = JSON.parse(cameraPresetsJson);
  PubSub.publish(UnityEvent.PredefinedCameraDetailsReceiver, cameraPresetsList);
}

window.GetLightData = (roomLightDataJson) => {
  const roomLightData = JSON.parse(roomLightDataJson)
  PubSub.publish(UnityEvent.GetLightData, roomLightData);
}

window.CameraFocalLengthReceiver = (focalLengthString) => {
  const focalLength = parseFloat(focalLengthString);
  PubSub.publish(UnityEvent.CameraFocalLengthReceiver, focalLength)
}

window.UpdatedFovReceiver = (fovValue) => {
  dispatcher(setGlobalFov(parseInt(fovValue) || FOV_MIN_VALUE));
  PubSub.publish(UnityEvent.UpdatedFovReceiver, fovValue)
}

window.OnLibraryPropInstantiated = () => {
  PubSub.publish(UnityEvent.OnLibraryPropInstantiated)
}

window.OnDeleteOperationCommpleted = () => {
  
}

window.OnReceiveObjectPositionAlongAxis = (value, transformType) => {
  console.log('OnReceiveObjectPositionAlongAxis', value,transformType)

  dispatcher(setObjectPositionAlongAxis({
    value: value,
    transformType,
  }))
}

window.OnReceieveCurrentCameraScreenshot = (imageData) =>{
  PubSub.publish(UnityEvent.OnReceieveCurrentCameraScreenshot, imageData)
}

window.AllImagesOfObjectProcessed = () => {
  console.log('AllImagesOfObjectProcessed')
}