/* eslint-disable no-debugger */
import { toast } from 'react-toastify';
import { LightAddApi } from '../../../../../api/lights';
import { objectToFormData } from '../../../../../helpers/jsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
  curatorLightSelector,
  setUploadDialogOpen,
} from '../../../../../redux/slicers/admin/curatorLightSlicer';
import { curatorSelector } from '../../../../../redux/slicers/admin/curatorSlicer';
import { LIGHT_HDRI_FILE_MAX_SIZE } from '../../../../../config/constant/validationConstant';
import { useUserContext } from '../../../../../contexts/UserContext';
import { errorToast } from '../../../../../helpers/toastHelper';

// TODO: work on default toast configuration


export const useHDRIUpload = () => {
  const { currentUser } = useUserContext();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => curatorSelector(state).individualProjectData.id);
  const loading = useSelector((state) => curatorLightSelector(state).createLoading);

  const openDialog = () => {
    dispatch(setUploadDialogOpen(true));
  };

  const closeDialog = () => {
    dispatch(setUploadDialogOpen(false));
  };

  const onDrop = (type) => () => {
    if (loading) return;
  };

  const onDropRejected = () => {
    // TODO: work on this error might be because of file is not supported or too big or too many files
    errorToast('File type is not supported');
  };

  const hdri = useDropzone({
    onDrop: onDrop('hdri'),
    accept: '.hdr',
    maxSize: LIGHT_HDRI_FILE_MAX_SIZE,
    onDropRejected,
    maxFiles: 1,
    multiple: false,
  });

  const image = useDropzone({
    onDrop: onDrop('image'),
    accept: 'image/png,image/jpeg,image/jpg',
    maxSize: LIGHT_HDRI_FILE_MAX_SIZE,
    onDropRejected,
    maxFiles: 1,
    multiple: false,
  });

  const onSubmit = () => {
    if (loading) return;

    const imageFile = image.acceptedFiles[0];
    const hdriFile = hdri.acceptedFiles[0];

    if (!hdriFile && !imageFile) {
      errorToast('Please add HDRI and output reference');
      return;
    }

    if (!hdriFile) {
      errorToast('Please add HDRI image');
      return;
    }

    if (!imageFile) {
      errorToast('Please add HDRI output reference');
      return;
    }

    const nameArr = hdriFile.name.split('.');
    nameArr.pop();
    const name = nameArr.join('.');

    const formData = objectToFormData({
      // file: hdriFile,
      // thumbnail: imageFile,
      file: imageFile,
      thumbnail: imageFile,
      member: currentUser.id,

      name,
      is_custom: true, // TODO: test if you can pass is_custom false when validation is ready
    });

    if (projectId) {
      formData.append('project', projectId);
    }

    dispatch(
      LightAddApi(formData, {
        successCallback: () => {
          closeDialog();
        },
      })
    );
  };

  return {
    image,
    hdri,
    loading,
    openDialog,
    closeDialog,
    onDropRejected,
    onDrop,
    onSubmit,
  };
};
