export const CuratorSubMenu = () =>{
    return (
        <section>
            <div className="filter-submenu hide">
                <div className="filter-checkbox">
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            My favorites
                        </label>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            My 3d models
                        </label>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            LF props
                        </label>
                    </div>
                </div>
                <div className="filter-checkbox">
                    <div>
                        <h5>Brand</h5>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            A&B Home
                        </label>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            Beyond Borders
                        </label>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            Jordan Manufaturi...
                        </label>
                    </div>
                    <div className="more">
                        <a>8 more</a>
                    </div>
                </div>
                <div className="filter-checkbox">
                    <div>
                        <h5>Category</h5>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Decor
                        </label>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            Furniture
                        </label>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            Lighting
                        </label>
                    </div>
                    <div className="more">
                        <a>15 more</a>
                    </div>
                </div>
                <div className="filter-checkbox">
                    <div>
                        <h5>Tag</h5>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Decor
                        </label>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            Furniture
                        </label>
                    </div>
                    <div className="form-check custom-checkbox">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            Lighting
                        </label>
                    </div>
                    <div className="more">
                        <a>15 more</a>
                    </div>
                </div>
            </div>
        </section>
    )
}