import { useSelector } from "react-redux";
import { UPLOAD_MAP_FILTER_NAME, UPLOAD_MAP_FILTER_TYPE } from "../../../../../config/constant/curatorConstants";
import { curatorStylesSelector } from "../../../../../redux/slicers/admin/curatorStylesSlicer";
import { filterSelector } from "../../../../../redux/slicers/filters/filterSlicer";

export const useFilterOption = () => {
  const selectMapMode = useSelector((state) => curatorStylesSelector(state).selectMapMode);
  
  const { filterData } = useSelector(filterSelector)
  const filters = filterData?.results || [];

  const uploadMapFilters = filters.find(f => f.type === UPLOAD_MAP_FILTER_TYPE)
  const filterName = UPLOAD_MAP_FILTER_NAME[selectMapMode];
  const filter = uploadMapFilters?.options?.find(f => f.name.toLowerCase() === filterName)
  const filterId = filter?.id;

  return {
    filter,
    tilingType: selectMapMode,
  }
}