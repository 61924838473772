import cx from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { errorToast } from "../../../../helpers/toastHelper";
import { curatorMetadataSelector } from "../../../../redux/slicers/admin/curatorMetadataSlicer";
import styles from "./MetadataForm.module.scss";

export const MetadataForm = ({ onCancel, onSubmit, metadataItem }) => {
  const metadata = useSelector(
    (state) => curatorMetadataSelector(state).metadata
  );
  
  const [name, setName] = useState(metadataItem?.title || '');

  const handleSubmit = (e) => {
    e.preventDefault();

    const exist = metadata.find(
      (md) => md.title === name && metadataItem?.title !== name
    );

    if (exist) {
      errorToast(`Metadata "${name}" already exists`);
      return;
    }

    if (!name.trim()) {
      errorToast(`Please provide a name`);
      return;
    }

    onSubmit({ name });
    setName("");
  };

  const handleChagne = (e) => {
    setName(e.target.value);
  };

  return (
    <form className="custom-input d-flex my-2" onSubmit={handleSubmit}>
      <input
        type="name"
        className={cx("form-control", styles.input)}
        name="name"
        placeholder="Name"
        value={name}
        onChange={handleChagne}
      />
      <div className="button d-flex ps-2">
        <button className="btn btn-light me-2" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-warning">Save</button>
      </div>
    </form>
  );
};
