import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CommonGrid, TopContainer } from '../layouts';
import {
  TopButtonArea,
  ThumbnailContainer,
  InfiniteScroll,
  ActionFilters,
  GlobalFilter,
  SharePopup,
  DeactivatePopup,
  ModalButtonArea,
  Input,
  BigButton,
} from '../common';
import _ from 'lodash';
import { FilterContext, UserContext } from '../contexts';
import {
  getCommonMetadata,
  addValueLabelToList,
  constructQueryParams,
  changeFilter,
  setTitle,
} from '../helpers/jsHelper';
import { apiErrorHandler } from '../helpers/axiosHelper';
import {
  apiGetCollectionItems,
  parseBasePathname,
  apiDeleteProjects,
  apiGetCollectionDetail,
  apiEditCollection,
  apiRemoveFromCollections,
} from '../helpers/api';
import { getScreenSettings } from '../helpers/configureHelper';
import { ReactLoader } from '../layouts/Loading';
import { EDIT_ICON, SHARE_ICON, DELETE_ICON, DOWNLOAD_ICON } from '../constants/assetConstants';
import { getCuratorPath } from '../helpers/urlHelper';
import { icons } from '../assets';
import { COLLECTIONS_ROUTE } from '../constants/routePathConstants';
const { EditIcon } = icons;
import constants, {
  MODULE_TYPES,
  GET_MODULE_KEY_BY_ID,
  MODULE_TYPE_OPTIONS,
  collectionFriendlyTitle,
} from '../constants/constants';

let title = 'Collections';
export default function Collection() {
  const location = useLocation();
  const pathname = parseBasePathname(location);
  let cardType = _.replace(pathname, '/', '');
  const routeParams = useParams();
  let collectionId;
  if (routeParams) {
    collectionId = parseInt(routeParams.id, 10);
  }
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && currentUser.name;
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sortField, setSortField] = useState();
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [data, setData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const pageSettings = getScreenSettings(cardType);
  const { sortOptions, filterOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter } =
    pageSettings;

  useEffect(() => {
    if (!sortField && defaultSortOption) {
      setSortField(defaultSortOption);
    }
  }, [sortOptions, sortField]);

  useEffect(async () => {
    refreshList();
    setTitle(title);
  }, []);

  useEffect(() => {
    return () => {
      filterCtx?.cleanGlobalFiltersOnNavigate();
    };
  }, []);

  useEffect(() => {
    let breadcrumbs =
      data && data.name
        ? [
            {
              link: COLLECTIONS_ROUTE,
              label: title,
            },
            {
              link: null,
              label: data.name,
            },
          ]
        : null;
    filterCtx.setBreadcrumbs(breadcrumbs);
  }, [data]);

  useEffect(() => {
    let params = constructQueryParams(
      sortField,
      filters,
      pageNumber,
      filterCtx?.globalFilters,
      filterCtx?.teamMembers
    );
    if (params !== query) {
      setQuery(params);
    }
  }, [
    collectionId,
    sortField,
    filters,
    pageNumber,
    filterCtx?.globalFilters,
    filterCtx?.teamMembers,
  ]);

  useEffect(() => {
    refreshList();
  }, [query, collectionId, filterCtx?.refListsReady]);

  function refreshList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      const getItems = apiGetCollectionItems(collectionId, query).then((res) => {
        return { items: res };
      });
      const getDetails = apiGetCollectionDetail(collectionId).then((res) => {
        return { details: res };
      });
      Promise.all([getItems, getDetails])
        .then((aggResults) => {
          const newStatus = {};
          aggResults.forEach((r) => Object.assign(newStatus, r));
          const { items, details } = newStatus;

          if (items) {
            if (items.total_pages) {
              setTotalPages(items.total_pages);
            }
            if (items.current_page_number) {
              setPageNumber(items.current_page_number);
            }
            if (items.results) {
              let newList = addValueLabelToList(items.results);
              setList(newList);
            }
            if (items.sidefilter) {
              filterCtx.handleFilterOptions(items.sidefilter);
            }
          }
          if (details) {
            setData(details);
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  function handleCardSelect(id, allOrNone = null) {
    let newList = _.map(list, (item) => {
      if (item.id === id) {
        item.selected = item.selected ? false : true;
      }
      if (_.isBoolean(allOrNone)) {
        item.selected = allOrNone;
      }
      return item;
    });
    setList(newList);
  }

  function handleSort({ value }) {
    setPageNumber(1);
    setSortField(value);
  }

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function handleFilter(field, selection) {
    setPageNumber(1);
    setFilters(changeFilter(filters, field, selection));
  }

  function handleOnChange(key, value) {
    let newItem = Object.assign({}, data);
    newItem[key] = value;
    setData(newItem);
  }

  function saveCollection() {
    if (!saving) {
      setSaving(true);
      let payload = {
        name: data?.name,
        description: data?.description,
      };

      apiEditCollection(data.id, payload)
        .then((res) => {})
        .catch(apiErrorHandler)
        .finally(() => {
          setSaving(false);
          onClose();
        });
    }
  }

  function removeFromCollection(setProcessingCallback, data) {
    let payload;
    payload = new FormData();
    _.map(data, (card) => {
      let removeCardType = _.findKey(MODULE_TYPE_OPTIONS, (value, key) => { return value === parseInt(card?.model_type, 10)})
      if (removeCardType) {
        payload.append(_.lowerCase(removeCardType), card?.data?.id);
      }
    });
    // ClickUp tasks since getting 400 error here
    Promise.all([apiRemoveFromCollections(collectionId, payload)]).then(res => {
      if (res) {
        console.log('res', res)
      }
    })
    .catch(apiErrorHandler)
    .finally(() => {
      if (_.isFunction(setProcessingCallback)) {
        setProcessingCallback(false)
      }
      onClose()
    })
  }

  function onClose() {
    refreshList();
    setModalOpen(false);
  }

  const { total, hasData, anySelected, allSelected, selectedList, hasMore } = getCommonMetadata(
    list,
    pageNumber,
    totalPages
  );

  if (!filterCtx) return <ReactLoader />;
  return (
    <CommonGrid
      topContainer={
        <TopContainer
          title={
            <>
              {data?.name} {total && <small>({total})</small>}
            </>
          }
          subtitle={data?.description}
          item={cardType}
          loading={loading || !filterCtx?.refListsReady}
          hasData={hasData}
          buttonArea={
            <TopButtonArea
              hasData={hasData}
              item={cardType}
              topButtonList={
                anySelected
                  ? [
                      {
                        element: DeactivatePopup,
                        bigButton: true,
                        isPlural: true,
                        iconSrc: DELETE_ICON,
                        onConfirm: removeFromCollection,
                        item: 'item', // these can be generic so not cardType which is 'collection' here
                        onSuccess: refreshList,
                        actionWord: 'remove',
                      },
                      {
                        element: SharePopup,
                        bigButton: true,
                        isPlural: true,
                        item: cardType,
                        onSuccess: refreshList,
                      },
                    ]
                  : []
              }
              addNewButtonArea={
                anySelected ? null : (
                  <ModalButtonArea
                    bigButton={true}
                    variant={saving ? 'outline-secondary' : 'outline-dark'}
                    buttonTitle="Edit"
                    buttonIcon={EditIcon}
                    title="Edit collection information"
                    body={
                      <div className="p-3">
                        <Row className="mb-2">
                          <Col>
                            <Input
                              onChange={(e) => handleOnChange(e.name, e.value)}
                              defaultData={data}
                              required
                              label="Name"
                              name="name"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Input
                              onChange={(e) => handleOnChange(e.name, e.value)}
                              defaultData={data}
                              required
                              multiline
                              label="Description"
                              name="description"
                            />
                          </Col>
                        </Row>
                      </div>
                    }
                    onClose={onClose}
                    setShowModal={setModalOpen}
                    showModal={modalOpen}
                    promptButton={
                      <BigButton
                        onClick={saveCollection}
                        disabled={saving || data?.name < 1}
                        buttonTitle={'Save'}
                        block
                        className="fullWidth"
                      />
                    }
                  />
                )
              }
              anySelected={anySelected}
              selectedList={selectedList}
              loading={!filterCtx?.refListsReady || loading}
            />
          }
        />
      }
      hasData={hasData}
      list={list}
      filterArea={
        <>
          <ActionFilters
            hasData={hasData}
            filterProps={{
              value: filters?.model_type?.value,
              data: filterOptions,
              onChange: (e) => handleFilter('model_type', e),
            }}
            sortProps={{
              data: sortOptions,
              onChange: (e) => handleSort(e),
            }}
            selectedProps={{
              allSelected: allSelected,
              onSelectAll: (checked) => handleCardSelect(null, checked),
            }}
            showTeamMemberFilter={showTeamMemberFilter}
            loading={loading}
          />
          <GlobalFilter />
        </>
      }>
      <InfiniteScroll
        dataLength={total}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={!filterCtx?.refListsReady || loading}
        hasChildren={hasData}
        scrollableTarget="layoutContentContainer">
        <ThumbnailContainer
          data={list}
          cardType={cardType}
          selectedList={selectedList}
          onSelect={handleCardSelect}
          clickUrl={getCuratorPath(':id', cardType)} // :id gets dynamically replaced when the list is iterated over
          showFavoriteIcon={showFavoriteIcon}
          cardButtonList={[
            { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
            {
              element: DeactivatePopup,
              miniButton: true,
              iconSrc: DELETE_ICON,
              isPlural: false,
              item: cardType,
              onSuccess: refreshList,
              deleteAction: apiDeleteProjects,
              actionWord: 'delete',
            },
          ]}
        />
      </InfiniteScroll>
    </CommonGrid>
  );
}
