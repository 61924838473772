import { createSlice } from '@reduxjs/toolkit';
import { dispatcher } from '../../../helpers/projectHelper';

const curatorLoaderSlicer = createSlice({
  name: 'curatorLoader',
  initialState: {
    exportProjectLoading: false,
  },
  reducers: {
    setExportProjectLoading: (state, { payload }) => {
      state.exportProjectLoading = payload;
    },
  },
});

export const actions = curatorLoaderSlicer.actions;

export const curatorLoaderSelector = (state) => state.curatorLoader;
export const curatorLoaderReducer = curatorLoaderSlicer.reducer;
export default curatorLoaderReducer;

export const setExportProjectLoading = (loading) => {
  dispatcher(actions.setExportProjectLoading(loading))
}

export const selectExportProjectLoading = (state) => state.curatorLoader.exportProjectLoading