import { useRef } from "react";
import useToggle from "react-use/lib/useToggle";
import useFullscreenReactUse from "react-use/lib/useFullscreen";

export const useFullscreen = () => {
  const ref = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreenReactUse(ref, show, {
    onClose: () => toggle(false),
  });

  return {
    ref,
    show,
    toggle,
    isFullscreen,
  };
};
