/* eslint-disable no-debugger */
import { createSlice } from "@reduxjs/toolkit";

export const LOADING_KEY = {
  FAVOURITE: 'FAVOURITE',
}

export const createPaginatedSlice = (
  name,
  { reducers, defaultState, newItemPosition = "start" } = {}
) => {
  const slice =  createSlice({
    name: name,
    initialState: {
      data: {},
      list: [],
      searchResultList: {}, // map of filter option label id, and results
      page: 1,
      perPage: 20,
      hasData: false,
      dataLoadedOnce: false,
      loading: false,
      currentRequestId: null,
      addLoading: false,
      deleteLoading: false,
      deletedItems: [],
      filter: null,
      filterValue: [],
      refetchCounter: 0,
      itemsLoading: {}, // { [id]: { favourite: true, name: false, item: true }} // keeps state of loading of different data for an item
      ...defaultState,
    },
    reducers: {
      ...reducers,
      [`fetchListStart`]: (state, { payload }) => {
        state.loading = true;
        state.currentRequestId = payload.requestId;
      },
      [`fetchListSuccess`]: (state, { payload }) => {
        if (state.currentRequestId !== payload.requestId) return;
        state.dataLoadedOnce = true;

        state.page = payload.page;
        state.data = payload.data;
        state.list = [...state.list, ...payload.data.results]; // TODO: leave only unique items in the array
        state.loading = false;

        if (!state.hasData) {
          // we update has data only once to know that he has some data so if later on filters are applied we still know that data is there
          state.hasData = payload.data.count > 0;
        }
      },
      [`fetchListFailed`]: (state) => {
        state.dataLoadedOnce = true;
        state.loading = false;
      },
      [`softResetList`]: (state) => {
        state.data = {};
        state.list = [];
        state.page = 1;
        state.triggerRefetch = 0;
      },
      [`resetList`]: (state) => {
        state.data = {};
        state.list = [];
        state.page = 1;
        state.dataLoadedOnce = false;
        state.triggerRefetch = 0;
      },
      [`fullResetList`]: (state) => {
        state.data = {};
        state.list = [];
        state.page = 1;
        state.hasData = false;
        state.loading = false;
        state.currentRequestId = null;
        state.dataLoadedOnce = false;
        state.triggerRefetch = 0;
      },
      triggerRefetch: (state) => {
        state.refetchCounter = state.refetchCounter + 1;
      },

      // add
      [`addStart`]: (state, { payload }) => {
        if (payload.data?.isTemporaryItem) {
          state.list =
            newItemPosition === "start"
              ? [payload.data, ...state.list]
              : [...state.list, payload.data];
        } else {
          state.addLoading = true;
        }
      },
      [`addSuccess`]: (state, { payload }) => {
        state.addLoading = false;

        if (payload.temporaryId) {
          // optimistic ui logic, item was already added we just need to update it with the data from the server
          state.list = state.list.map((item) => {
            return item.isTemporaryItem && item.id === payload.temporaryId
              ? payload.data
              : item;
          });
        } else {
          state.list =
            newItemPosition === "start"
              ? [payload, ...state.list]
              : [...state.list, payload];
        }
      },
      [`addFailed`]: (state, { payload }) => {
        state.addLoading = false;

        if (payload.temporaryId) {
          // optimistic ui logic, item was already added we need to remove it from the list
          state.list = state.list.filter(
            (item) => item.id !== payload.temporaryId
          );
        }
      },
      setAddLoading: (state, { payload }) => {
        state.addLoading = payload;
      },

      // delete
      [`deleteStart`]: (state, { payload }) => {
        if (payload.itemId) {
          const item = state.list.find((item) => item.id === payload.itemId);
          state.list = state.list.filter((item) => item.id !== payload.itemId);
          state.deletedItems.push(item);
        }
        state.deleteLoading = true;
      },
      [`deleteSuccess`]: (state, { payload }) => {
        state.deleteLoading = false;
        state.deletedItems = state.deletedItems.filter(
          (item) => item.id !== payload.itemId
        );
        state.list = state.list.filter((item) => item.id !== payload.itemId);
      },
      [`deleteFailed`]: (state, { payload }) => {
        state.deleteLoading = false;

        if (payload.itemId) {
          // optimistic ui logic
          const item = state.deletedItems.find(
            (item) => item.id === payload.itemId
          );
          state.items = [...state.items, item];
        }
      },

      // update
      [`update`]: (state, { payload }) => {
        state.list = state.list.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...payload,
            };
          }

          return item;
        });
      },
      setItemLoading: (state, { payload }) => {
        const { id, key, value } = payload;
        if (!state.itemsLoading[id]) {
          state.itemsLoading[id] = {};
        }

        state.itemsLoading[id][key] = value;
      },

      // filter
      setFilter: (state, { payload }) => {
        if (!state.filter) {
          state.filter = payload;
        }
      },
      toggleFilter: (state, { payload }) => {
        state.filterValue = state.filterValue.includes(payload)
          ? state.filterValue.filter((x) => x !== payload)
          : [...state.filterValue, payload];
      },
      groupFilterUpdate: (state, { payload }) => {
        let newValue = [...state.filterValue];
        payload.forEach(({ name, enabled }) => {
          if (enabled && !newValue.includes(name)) {
            newValue.push(name);
            return;
          }

          if (!enabled && newValue.includes(name)) {
            newValue = newValue.filter(v => v !== name);
          }
        })

        state.filterValue = newValue
      },
      resetFilters: (state) => {
        state.filterValue = [];
      },

      // search
      searchStart: (state, { payload }) => {
        // state.loading = true;
        state.searchResultList[payload.optionId] = {
          currentRequestId: payload.requestId,
          list: [],
          loading: true,
        } 
      },
      searchSuccess: (state, { payload }) => {
        if (state.searchResultList[payload.optionId].currentRequestId !== payload.requestId) return;

        state.searchResultList[payload.optionId] = {
          list: payload.data.results,
          loading: false,
        } 
      },
      searchFailed: (state, { payload }) => {
        if (state.currentRequestId !== payload.requestId) return;
        state.searchResultList[payload.optionId] = {
          list: [],
          loading: false,
        } 
      }
    },
  });

  slice.LF = {
    selectors: {
      selectIsFavouriteLoading: (state, id) => Boolean(state[name].itemsLoading?.[id]?.[LOADING_KEY.FAVOURITE]),
    }
  }

  return slice;
};
