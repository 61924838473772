import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useDropzone } from 'react-dropzone';
import ReactTimeAgo from 'react-time-ago';
import { icons } from '../assets';
import {
  ActionFilters,
  AddToCollections,
  BigButton,
  DeactivatePopup,
  Download,
  EditButton,
  GlobalFilter,
  Image,
  InfiniteScroll,
  ModalButtonArea,
  RightSidebar,
  SharePopup,
  ThumbnailContainer,
  TopButtonArea,
} from '../common';
import {
  ARCHIVE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
  EDIT_ICON,
  SHARE_ICON,
  TRASH_ICON,
} from '../constants/assetConstants';
import { RENDER_DETAILS } from '../constants/routePathConstants';
import { FilterContext, UserContext } from '../contexts';
import {
  apiArchiveRenders,
  apiGetDownloadRendersImages,
  apiGetPreviewAndMainRenders,
  apiGetProps,
  apiUploadRenderImages,
} from '../helpers/api';
import { getScreenSettings } from '../helpers/configureHelper';
import { changeFilter, constructQueryParams, getCommonMetadata, setTitle } from '../helpers/jsHelper';
import { CommonGrid, TopContainer } from '../layouts';

const { ArchiveIcon, DownloadIcon, FileUploadIcon } = icons;

let title = "Renders & Previews";

export default function RendersPreviews() {
  let cardType = 'renders-previews';
  const filterCtx = useContext(FilterContext);
  const userCtx = useContext(UserContext);
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && `${currentUser.first_name} ${currentUser.last_name} `;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [query, setQuery] = useState('');
  const [editItem, setEditItem] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [sortField, setSortField] = useState();
  const [total, setTotal] = useState(0);
  const [files, setFiles] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [imageType, setImageType] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

  const pageSettings = getScreenSettings(cardType);
  const { sortOptions, filterOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter } =
    pageSettings;

  useEffect(() => {
    setTitle(title);
  }, []);

  useEffect(() => {
    return () => {
      if (filterCtx) {
        filterCtx.cleanGlobalFiltersOnNavigate();
      }
    };
  }, []);

  useEffect(() => {
    if (!sortField && defaultSortOption) {
      setSortField(defaultSortOption);
    }
  }, [sortOptions, sortField]);

  useEffect(() => {
    let newFilter = {};
    if (filterBy?.label === 'Renders') {
      newFilter = {
        label: 'is_preview',
        value: 'false',
      };
    }
    if (filterBy?.label === 'Previews') {
      newFilter = {
        label: 'is_preview',
        value: 'true',
      };
    }
    if (filterBy?.label === 'Uploads') {
      newFilter = {
        label: 'is_upload',
        value: 'true',
      };
    }
    let params = constructQueryParams(
      sortField,
      [newFilter],
      null,
      filterCtx?.globalFilters,
      filterCtx?.teamMembers
    );
    if (params !== query) {
      setQuery(params);
    }
  }, [sortField, filterBy, filterCtx?.globalFilters, filterCtx?.teamMembers]);

  useEffect(refreshList, [query, filterCtx?.refListsReady]);
  useEffect(addDataToList, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    refreshList();
  }, [userCtx.currentUser.organization]);

  async function refreshList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      const res = await apiGetPreviewAndMainRenders(query);
      if (res) {
        if (res.results) {
          let newList = _.map(res.results, (r) => {
            return r;
          });
          setTotal(res.count);
          setTotalPages(res.total_pages);
          setList(newList);
          setLoading(false);
        }
        if (res.sidefilter) {
          filterCtx.handleFilterOptions(res.sidefilter);
        }
      }
    }
  }

  async function addDataToList() {
    if (!loading && filterCtx?.refListsReady) {
      const res = await apiGetPreviewAndMainRenders(query + `&page=${pageNumber}`);
      if (res) {
        if (res.results) {
          let newList = _.map(res.results, (r) => {
            return r;
          });
          setTotal(res.count);
          setTotalPages(res.total_pages);
          setList([...list, ...newList]);
        }
        if (res.sidefilter) {
          filterCtx.handleFilterOptions(res.sidefilter);
        }
      }
    }
  }

  async function handleUploadClick() {
    const fd = new FormData();
    fd.append('name', product[0].name);
    fd.append('type', imageType);
    _.forEach(files, (f) => fd.append('file', f));
    const res = await apiUploadRenderImages(fd);
  }

  function onEdit(id) {
    let itemToEdit = _.find(list, (p) => p.id === id);
    setEditItem(itemToEdit);
  }

  function handleCardSelect(id, allOrNone = null) {
    let newList = _.map(list, (item) => {
      if (item.id === id) {
        item.selected = item.selected ? false : true;
      }
      if (_.isBoolean(allOrNone)) {
        item.selected = allOrNone;
      }
      return item;
    });
    setList(newList);
  }

  function handleSort({ value }) {
    setPageNumber(1);
    setSortField(value);
  }

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber((prev) => prev + 1);
    }
  }

  // function handleFilter(field, selection) {
  //   setPageNumber(1);
  //   setFilters(changeFilter(filters, field, selection));
  // }

  function handleFileDelete(file) {
    const filtered = files.filter((f) => f.path !== file.path);
    setFiles(filtered);
  }

  async function handleDownloadClick() {
    if (selectedList.length < 1) return;
    let query = selectedList.reduce((prev, curr, index) => {
      let q = prev + curr.id;
      if (selectedList.length > 1 && index < selectedList.length - 1) {
        q += ',';
      }
      return q;
    }, 'ids=');
    const res = await apiGetDownloadRendersImages(query);
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'render.zip');
    document.body.appendChild(link);
    link.click();
  }

  async function handleProductSearch(search) {
    setProductsLoading(true);
    const res = await apiGetProps(`search=${search}`);
    setProducts(res.results);
    setProductsLoading(false);
  }

  const { hasData, anySelected, allSelected, selectedList } = getCommonMetadata(list);

  const hasMore = pageNumber < totalPages;

  return (
    <CommonGrid
      topContainer={
        <TopContainer
          // title={<>Renders & Previews {total && <small>({total})</small>}</>}
          title={title}
          item={cardType}
          loading={!filterCtx?.refListsReady || loading}
          hasData={hasData}
          buttonArea={
            <TopButtonArea
              hasData={hasData}
              item={cardType}
              loading={!filterCtx?.refListsReady || loading}
              addNewButtonArea={
                <>
                  <ModalButtonArea
                    bigButton={true}
                    variant="warning"
                    buttonTitle="Upload"
                    className="upload-btn"
                    iconBefore={<FileUploadIcon />}
                    title={
                      <>
                        Upload Images
                        <h2 className="lead py-2">Add Files [jpeg/png - max. 50mb]</h2>
                      </>
                    }
                    size="lg"
                    body={
                      <div className="px-5">
                        <div>
                          <div
                            {...getRootProps({
                              className: 'dropzone render-dropzone p-5 text-center',
                            })}>
                            <input {...getInputProps()} />
                            <p className="text-primary">Select [file type] file</p>
                            <p className="text-muted">
                              <small>or drop [file type] here</small>
                            </p>
                          </div>
                        </div>
                        {files && files.length > 0 && (
                          <>
                            <div>
                              <ul className="render-upload-list">
                                {files.map((file) => (
                                  <li
                                    key={file.path}
                                    className="d-flex justify-content-between align-items-center">
                                    {file.name}
                                    <div role="button" onClick={() => handleFileDelete(file)}>
                                      <img src={TRASH_ICON} />
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="render-upload-group pt-2">
                              <div className="mb-2">Select product name</div>
                              <AsyncTypeahead
                                filterBy={() => true}
                                id="product-search"
                                isLoading={productsLoading}
                                labelKey="name"
                                minLength={3}
                                onSearch={handleProductSearch}
                                options={products}
                                placeholder="Enter product name..."
                                selected={product}
                                onChange={(t, e) => setProduct(t)}
                                renderMenuItemChildren={(option, props) => (
                                  <>
                                    <img
                                      src={option.thumbnail}
                                      className="shadow me-3"
                                      style={{ maxWidth: '32px' }}
                                    />{' '}
                                    {option.name}
                                  </>
                                )}
                              />
                            </div>
                            <div className="render-upload-group pt-3 pb-2">
                              <div className="mb-2">Type of image</div>
                              <Form.Select
                                value={imageType}
                                onChange={(x) => setImageType(x.target.value)}>
                                {[
                                  { value: 4, label: 'Vignette' },
                                  { value: 3, label: 'Silo' },
                                  { value: 2, label: 'Lifestyle' },
                                ].map((o) => (
                                  <option key={o.value} value={o.value}>
                                    {o.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="d-grid py-4">
                              <Button variant="warning" onClick={handleUploadClick}>
                                Import
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    }
                  />
                </>
              }
              topButtonList={[
                {
                  element: DeactivatePopup,
                  title: 'Are you sure you want to archive this render?',
                  subtitle: 'You can view archived renders through the filter panel.',
                  bigButton: true,
                  isPlural: true,
                  item: cardType,
                  onSuccess: refreshList,
                  actionWord: 'archive',
                  deleteAction: apiArchiveRenders,
                },
                {
                  element: AddToCollections,
                  bigButton: true,
                  iconSrc: DOWNLOAD_ICON,
                  isPlural: true,
                  item: cardType,
                },
                {
                  element: SharePopup,
                  bigButton: true,
                },
                {
                  element: BigButton,
                  buttonTitle: 'Download',
                  iconBefore: <img src={DOWNLOAD_ICON} />,
                  onClick: handleDownloadClick,
                },
              ]}
              anySelected={anySelected}
              selectedList={selectedList}
            />
          }
        />
      }
      hasData={hasData}
      list={list}
      filterArea={
        <>
          <ActionFilters
            hasData={hasData}
            filterProps={{
              value: filterBy,
              data: filterOptions,
              onChange: (v) => setFilterBy(v),
            }}
            sortProps={{
              data: sortOptions,
              onChange: (v) => handleSort(v),
            }}
            selectedProps={{
              allSelected: allSelected,
              onSelectAll: (checked) => handleCardSelect(null, checked),
            }}
            showTeamMemberFilter={showTeamMemberFilter}
            loading={loading}
          />
          <GlobalFilter />
        </>
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={!filterCtx?.refListsReady || loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainer
          data={list}
          cardType={cardType}
          selected={selectedList}
          onEdit={onEdit}
          onSelect={handleCardSelect}
          pageSettings={pageSettings}
          clickUrl={RENDER_DETAILS}
          showFavoriteIcon={showFavoriteIcon}
          cardButtonList={[
            {
              element: Download,
              // TODO
              // need to change this out dynamically to a render button,
              // hasRenders in projectHelper function will check if an item has something downloaded
              miniButton: true,
              title: 'Download',
              iconSrc: DOWNLOAD_ICON,
            },
            {
              element: EditButton,
              miniButton: true,
              title: 'Edit',
              iconSrc: EDIT_ICON,
            },
            { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
            {
              element: AddToCollections,
              miniButton: true,
              iconSrc: COLLECTION_ICON,
              item: cardType,
              onSuccess: refreshList,
            },
            {
              element: DeactivatePopup,
              miniButton: true,
              iconSrc: ARCHIVE_ICON,
              isPlural: false,
              item: cardType,
              onSuccess: refreshList,
              deleteAction: apiArchiveRenders,
              actionWord: 'archive',
            },
          ]}
        />
        {hasMore && <div id="whiteShadow" />}
      </InfiniteScroll>
      <RightSidebar
        initialShowOpen={!_.isNil(editItem)}
        onClose={() => setEditItem(null)}
        title={
          <header className="header">
            {editItem?.src && <Image src={editItem.src} alt="Avatar" className="avatar" rounded />}
            <div>
              <div className="title">Edit {editItem?.name}</div>
              <div className="headerInfo">
                {userName ? `${userName} · ` : null}
                {editItem?.updated_at && <ReactTimeAgo date={Date.parse(editItem?.updated_at)} />}
              </div>
            </div>
          </header>
        }
        body={<>BODY</>}
      />
    </CommonGrid>
  );
}
