import cx from "classnames";
import { useState } from "react";
import { RiAddLine, RiCheckLine, RiSubtractLine } from "react-icons/ri";

import { useUnityColors } from "../useUnityRedux";
import styles from "./ColorsTabSwatches.module.scss";

export const ColorsTabSwatches = ({ selectedColor, colorList, onAdd, onDelete }) => {
  const { changeColor } = useUnityColors();
  const [deleteMode, setDeleteMode] = useState(false);

  const handleColorClick = (color) => () => {
    if (deleteMode) {
      onDelete(color.id)
      return;
    }

    changeColor(color.color_value);
  };

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between mb-4">
        Color swatches
        <div>
          {!deleteMode && (<><button className={styles.btn} onClick={() => onAdd(selectedColor)}>
            <RiAddLine />
          </button>
          <button className={styles.btn} onClick={() => setDeleteMode(true)}>
            <RiSubtractLine />
          </button></> )}
          {deleteMode && <button className={styles.btn} onClick={() => setDeleteMode(false)}>
            <RiCheckLine />
          </button>}
        </div>
      </div>

      <div className={styles.swatchesContainer}>
        {colorList.map((color) => {
          const selected = selectedColor === color.color_value;

          return (
            <div
              key={color.id}
              className={cx(styles.swatchItem, selected && styles.selected)}
              style={{ backgroundColor: color.color_value }}
              onClick={handleColorClick(color)}
            >
              {deleteMode && (
                <div className={styles.deleteIcon}>
                  <RiSubtractLine />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
