import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSelectedLightId } from "../../../../../redux/slicers/admin/curatorLightSlicer";
import { curatorActions, syncLightFromUnity } from "../../../../../redux/slicers/admin/curatorSlicer";

export const useUnityLightModuleListeners = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    window.SelectedLightReceiver = (data) => {
      const light = JSON.parse(data);

      console.log('sasha SelectedLightReceiver', light)
      dispatch(syncLightFromUnity(light));
      dispatch(setSelectedLightId(light.id));
    };

    window.DeletedLightReceiver = (lightId) => {
      dispatch(curatorActions.deleteLight(lightId));
    };
  }, []);
}