import cx from 'classnames';
import CircularProgress from "../../../../CircularProgress/CircularProgress";
import FileInput from "../../../../FileInput/FileInput";
import FileUploadIcon from "../../../../icons/FileUploadIcon";
import Loader from "../../../../Loader/Loader";
import { useUploadTexture } from "./useUploadTexture";
import styles from './UploadTextureButton.module.scss';

export const UploadTextureButton = () => {
  const { uploadTexture, loading } = useUploadTexture()

  const handleFileSelect = (file) => {
    uploadTexture(file);
  }

  // TODO: add loader here
  return (
    <FileInput
      className={cx(styles.button, loading && styles.loading)}
      onFileSelect={handleFileSelect}
      accept="image/png, image/jpeg"
      icon={<FileUploadIcon />}
    >
      Upload
      <Loader loading={loading} position="absolute" backdrop backdropColor="dark" size={20} />
    </FileInput>
  );
};
