import cx from 'classnames';
import Button from "../../../../Button/Button";
import styles from './PopupButtons.module.scss';

export const PopupButtons = ({
  onCancel,
  cancelText = "Cancel",
  onSubmit,
  submitText = "Save",
  submitDisable,
  cancelDisabled,
  className,
  sticky,
  fixed,
  cancelProps,
  submitProps,
  cancelIcon,
  submitIcon,
}) => {
  return (
    <div className={cx('w-100 mt-3 d-flex gap-3', className, sticky && styles.sticky, fixed && styles.fixed)}>
      {onCancel && (
        <Button
          color="light"
          variant="outline"
          className="w-100"
          size="xlg"
          onClick={onCancel}
          iconBefore={cancelIcon}
          disabled={cancelDisabled}
          {...cancelProps}
        >
          
          {cancelText}
        </Button>
      )}
      {onSubmit && (
        <Button
          color="warning"
          className="w-100"
          size="xlg"
          disabled={submitDisable}
          onClick={onSubmit}
          iconBefore={submitIcon}
          {...submitProps}
        >
          {submitText}
        </Button>
      )}
    </div>
  );
};
