/* eslint-disable no-debugger */
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { unzip } from 'unzipit';
import {
  getRoomDetailsApi,
  layoutReadApi,
  propsReadApi,
  uniquePropsApi,
} from '../../../../api/curator';
import { getJson } from '../../../../helpers/axiosHelper';
import {
  getMaterial,
  getTexture,
  updateDbLayout,
  updateDbMaterial,
  updateDbProp,
  updateDbTexture,
} from '../../../../helpers/idb';
import { duplicateArrayRemove } from '../../../../helpers/jsHelper';
import { apiGetMaterials, apiGetTextures } from '../../../../helpers/api';
import {
  selectIndividualProjectData,
  setUnityData,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { uniq } from 'lodash';
import {
  selectProjectCameraList,
  setProjectCameraAnglesList,
} from '../../../../redux/slicers/camera/projectCameraAngleSlicer';
import { getAppState } from '../../../../redux/store';
import { parseArchiveV2 } from './parseArchiveV2';
import { parseLight } from './parseLight';
import { parseArchiveV1 } from './parseArchiveV1';
import { simplifyPropData } from './simplifyPropData';
import { parseV1ZipCameras } from './parseV1ZipCameras';
import { parseHDRIData } from './parseHDRIData';
import { errorToast } from '../../../../helpers/toastHelper';
import { useUnityContext } from '../../../../components/container/unityContainer';
import { loadUnityProps } from './loadUnityProps';
import { loadUnityMaterialDetails } from './loadUnityMaterialDetails';

export const getArchiveData = async ({ file }) => {
  if (!file) return null;

  const { entries } = await unzip(file);
  const filePathList = Object.keys(entries);
  const jsonFilePath = filePathList.find((p) => p.endsWith('.json'));

  if (!jsonFilePath) {
    // TODO: do this validation
    errorToast('Incorrect archive data, [project].json file is missing');
    throw new Error('Incorrect archive data');
  }

  const projectJson = await entries[jsonFilePath].json();
  console.log('getArchiveData() file json', projectJson);
  console.log('sasha getArchiveData()', entries);
  const isV1Archive =
    projectJson.materialVersion && projectJson.materialVersion.toLowerCase() !== 'v3';

  return {
    json: projectJson,
    entries,
    isV1Archive,
    jsonFilePath,
  };
};

export const getProjectV1ArchiveData = async ({ archiveData, dispatch }) => {
  const { json, jsonFilePath, entries } = archiveData;
  const roomId = jsonFilePath.split('.json')[0];

  // we need to find object with 'ObjectsData' key that where all the data is (and key is random)
  const dataKey = Object.keys(json).find((key) => {
    return typeof json[key] === 'object' && json[key].ObjectsData;
  });

  const data = json[dataKey];

  return await parseArchiveV1({
    data,
    dispatch,
    projectJson: json,
    entries,
    roomId,
  });
};

export const usePrepareRoom = () => {
  const unityContext = useUnityContext();
  const dispatch = useDispatch();

  const prepareRoom = async ({ projectArchive, roomId, projectId } = {}) => {
    if (!roomId && !projectArchive && !projectId) {
      errorToast('Unable to render room');
      // throw new Error('Unable to render room, not enough data');
      return;
    }

    let archiveData = null;
    try {
      archiveData = await getArchiveData({ file: projectArchive });
    } catch (error) {
      console.error(error);
      errorToast('Unable to load project file');
      return;
    }

    if (!roomId) {
      if (projectId || (!projectId && archiveData && !archiveData?.isV1Archive)) {
        await openProject({ projectId, archiveData, dispatch, unityContext });
        return;
      }
    }

    // openRoom flow is below
    // move it to separate function

    let isOldZipFileOpened = Boolean(projectArchive);
    let roomRequestParams = null;
    let oldProjectData = null;

    if (projectArchive) {
      try {
        oldProjectData = await getProjectV1ArchiveData({ archiveData, dispatch });
        console.log('oldProjectData', oldProjectData);
        roomRequestParams = { old_room_id: oldProjectData.roomId };
      } catch (error) {
        console.error(error);
        errorToast('Unable to load project file');
        return;
      }
    } else {
      roomRequestParams = { ids: [roomId] };
    }

    if (!roomRequestParams) {
      errorToast('Unable open room. Reason: REQUEST_PARAMS missing');
      return;
    }

    const rooms = await dispatch(getRoomDetailsApi(roomRequestParams));
    const roomData = rooms?.results?.[0];
    console.log('sasah roomData rooms', rooms);

    if (!roomData?.room_file) {
      errorToast('Unable to load the room, please try again. Reason: ROOM_FILE is missing');
      return;
    }

    const roomJson = await getJson(roomData.room_file);

    // get layout data
    const layoutId = roomJson?.layoutId;
    if (!layoutId) {
      errorToast('Unable to load the room, please try again. Reason: LAYOUT_ID is missing');
      return;
    }

    const layoutData = await layoutReadApi(layoutId);
    if (!layoutData?.json_file) {
      errorToast('Unable to load the room, layout data is missing');
      return;
    }

    const layoutJson = await getJson(layoutData.json_file);
    const layoutProps = layoutJson?.Layout || [];

    // store layout in index DB
    const layoutDbData = await updateDbLayout(layoutData);

    // fetch props
    const propList = [...roomJson?.props, ...layoutProps]
      .filter(Boolean)
      .filter((a) => a.is_layout !== true) // remove layout props
      .map((p) => ({
        ...p,
        type: isOldZipFileOpened ? 'ZipProp' : 'RoomProp',
      }));

    const unityPropList = [...propList, ...(oldProjectData?.unityPropList || [])].map((p) => ({
      ...p,
      propLoaderId: uuidv4(),
    }));

    const unityData = {};
    unityData.layoutId = layoutId;
    unityData.layoutLocalBundlePath = layoutDbData.file || layoutData.webgl;
    unityData.roomCameraInfo = layoutJson.camera[0];
    unityData.roomLightInfos = layoutJson.lights;
    unityData.EnvironmentLight = layoutJson.EnvironmentLight;
    unityData.roomLightData = roomJson.roomLightData?.map(parseLight) || [];
    unityData.isOldZipFileOpened = false;
    unityData.props = unityPropList;
    unityData.materialDetails = [];

    if (isOldZipFileOpened) {
      const oldRoomObjectDatas = await getJson(roomData.object_rename_file);

      unityData.isOldZipFileOpened = true;
      unityData.transformData = oldProjectData.data.transformData;
      // unityData.roomCameraInfo = oldProjectData.data.CameraData;
      // unityData.roomLightInfos = undefined; // TODO: wait for confirmation
      // unityData.roomLightData = oldProjectData.lightData;
      unityData.roomMetadata = oldProjectData.data.roomMetadata;
      unityData.culpSkuUsedData = oldProjectData.data.CulpSkuData;
      unityData.materialDetails = getCompactMaterialDetails(oldProjectData.materialDetails); // TODO: Set this property from ObjectsData present in the old zip files
      unityData.oldRoomObjectDatas = oldRoomObjectDatas?.other_info;

      parseV1ZipCameras(oldProjectData.data['CameraData2019.2.2'], dispatch, roomData.id);
    }

    parseHDRIData({
      defaultHDRIData: layoutJson.EnvironmentLight,
      selectedHDRIData: null,
      hdriFile: layoutData.hdriFile,
      hdriFileThumbnail: layoutData.hdriFileThumbnail,
      hdriFileName: layoutData.hdriFileName,
      dispatch,
    });

    console.log(layoutProps);
    console.log('roomJson', roomJson);

    console.log('sasha unity json', unityData);

    dispatch(setUnityData(unityData));

    // load unity props
    loadUnityProps({ unityContext, unityPropList });

    if (isOldZipFileOpened) {
      loadUnityMaterialDetails({ unityContext, materialDetails: oldProjectData.materialDetails });
    }
  };

  return {
    prepareRoom,
  };
};

const openProject = async ({ projectId, archiveData, dispatch, unityContext }) => {
  if (!projectId && !archiveData) {
    errorToast('Unable to open project')
    return;
  }

  let unityData = {};
  const individualProjectData = selectIndividualProjectData(getAppState());

  // json: projectJson,
  // entries,
  // isV1Archive,
  // jsonFilePath,

  let projectJson;

  if (projectId) {
    const jsonFile = individualProjectData?.data?.json_file;

    if (!jsonFile) {
      errorToast('Project json file is missing')
      return;
    }
    
    projectJson = await getJson(jsonFile);
  } else {
    const { materialDetails } = await parseArchiveV2({ archiveData, dispatch });
    projectJson = {
      ...archiveData.json,
      materialDetails,
    };
  }

  if (!projectJson) {
    errorToast('Project json file is missing')
    return;
  }

  // parse cameras
  dispatch(setProjectCameraAnglesList(projectJson.cameraList || []));

  console.log('sasha project json', projectJson);

  // parse layout
  const layoutId = projectJson.blenderLayoutBundleDatas[0]?.id;

  if (!layoutId) {
    errorToast('Project layout is missing')
    return;
  }
  const layout = await layoutReadApi(layoutId);
  const layoutJson = await getJson(layout.json_file);
  const layoutDbData = await updateDbLayout(layout);

  const unityPropList = projectJson?.blenderAssetBundleDatas?.map((a) => {
    let transform_prop = projectJson && projectJson?.transformChangedObjectData[a?.objectName];

    // TODO: handle case when product is missing
    // let propDetailsArr = await uniquePropsApi(a?.id);
    // let propDetails = propDetailsArr?.results[0];
    // const propDbData = await updateDbProp(propDetails);
    // const fileUrl = propDbData?.file || propDetails?.product_file;

    return {
      type: 'ProjectProp',
      id: a?.id,
      objectName: a?.objectName,
      indexName: a?.objectIndex,
      xPosition: transform_prop.xPosition,
      yPosition: transform_prop.yPosition,
      zPosition: transform_prop.zPosition,
      xRotation: transform_prop.xRotation,
      yRotation: transform_prop.yRotation,
      zRotation: transform_prop.zRotation,
      wRotation: transform_prop.wRotation,
      xScale: transform_prop.xScale,
      yScale: transform_prop.yScale,
      zScale: transform_prop.zScale,
      // locationUrl: fileUrl,
      propLoaderId: uuidv4(),
      ...(a.is_metadata && a.product_meta_info ? { preDefinedMetadata: a.product_meta_info } : {}),
    };
  });

  unityData.roomCameraInfo = projectJson.roomCameraInfo;
  unityData.roomLightData = projectJson.roomLightData?.map(parseLight) || [];
  unityData.transformData = projectJson.transformChangedObjectData;
  unityData.roomMetadata = projectJson.roomMetadata || {};
  unityData.culpSkuUsedData = projectJson.culpSkuUsedData || {};
  unityData.props = unityPropList;
  unityData.layoutLocalBundlePath = layoutDbData.file;

  unityData.userCameraInfo = selectProjectCameraList(getAppState());
  unityData.isCustomRoom = false;
  unityData.isProjectRoom = true;
  unityData.propsHierarchyData = projectJson.propsHierarchyData || {};
  unityData.duplicatePropDatas = projectJson.duplicatePropDatas || [];
  unityData.deletedObjectsData = projectJson.deletedObjectData || [];

  // parse mateial data
  unityData.materialDetails = getCompactMaterialDetails(projectJson.materialDetails || []);

  parseHDRIData({
    defaultHDRIData: layoutJson.EnvironmentLight,
    selectedHDRIData: projectJson.SelectedEnvironmentLight,
    hdriFile: layout.hdriFile,
    hdriFileThumbnail: layout.hdriFileThumbnail,
    hdriFileName: layout.hdriFileName,
    dispatch,
  });

  unityData.layoutId = layoutId;

  console.log('sasha unity json', unityData);
  dispatch(setUnityData(unityData));

  loadUnityProps({ unityContext, unityPropList });
  loadUnityMaterialDetails({ unityContext, materialDetails: projectJson.materialDetails });
};

const getCompactMaterialDetails = (materialDetails) => {
  return materialDetails.map((md) => ({
    objectName: md.objectName,
    matName: md.matName,
    OtherObjectName: md.OtherObjectName,
    propId: md.propId,
    layoutId: md.layoutId,
    currentIndex: md.currentIndex,
    ...(md.objectUniqueIdentifier ? { objectUniqueIdentifier: md.objectUniqueIdentifier } : {}),
  }));
};
