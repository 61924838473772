/* eslint-disable no-debugger */
import { useDispatch, useSelector } from "react-redux";
import { curatorActions, curatorSelector } from "../../../../../redux/slicers/admin/curatorSlicer";
import { FILTER } from "../../CuratorFilter/CuratorFilter";

export const useCuratorFilter = ({ actions, filterSelector, name, additionalFilterList }) => {
  const dispatch = useDispatch();
  const filterOpen = useSelector(state => curatorSelector(state).filterOpen) === name;

  const { filter, filterValue } = useSelector(filterSelector);

  const toggleFilterOpen = () => dispatch(curatorActions.toggleFilterOpen(name));


  const handleFilterChange = (value, event) => {

    if (name === 'texture') {
      const fieldName = event?.target?.name

      if (fieldName === 'filterTexture' || fieldName === 'filterUploads') {
        dispatch(actions.groupFilterUpdate([
          { name: FILTER.ALL_TEXTURES, enabled: false },
          { name: FILTER.MY_TEXTURES, enabled: false },
          { name: FILTER.ALL_UPLOADS, enabled: false },
          { name: FILTER.MY_UPLOADS, enabled: false },
          { name: value, enabled: !filterValue.includes(value)},
        ]))
        return;
      }

    }

    dispatch(actions.toggleFilter(value))
  }

  const handleFilterClear = () => {
    dispatch(actions.resetFilters())
  }


  return {
    filterOpen,
    filter,
    filterValue,
    toggleFilterOpen,
    handleFilterChange,
    handleFilterClear,
  }
}