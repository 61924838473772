import { useRef } from "react";
import styles from "./FileInput.module.scss";

const FileInput = ({ children, onFileSelect, onClick, accept, icon, ...rest }) => {
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    // TODO: file validation
    let file = e.target.files[0];

    if (!file) {
      return;
    }

    onFileSelect(file);
  };

  const handleClick = (e) => {
    // reset previous file
    fileInputRef.current.value = null;
    onClick?.(e);
  };

  return (
    <label type="button" onClick={handleClick} {...rest}>
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleChange}
        className={styles.input}
        accept={accept}
      />
      {icon}
      {children}
    </label>
  );
};

export default FileInput;
