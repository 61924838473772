import { useEffect } from 'react';
import {
  setTitle
} from '../helpers/jsHelper';
let title = 'Search';

export default function Search() {
  useEffect(() => {
    setTitle(title);
  }, []);
  
  return 'Search';
}
