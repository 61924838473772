/* eslint-disable no-unsafe-optional-chaining */
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  layoutReadApi,
  uniquePropsApi,
  getProjectApi,
} from '../../../api/curator';
import './curator.scss';
import { CuratorHeader } from '../../../components/admin/curator/CuratorHeader/CuratorHeader';
import { SidebarV2 } from '../../../components/admin/curator/sideBar.v2';
import { curatorSelector, selectPreventProjectInitialization, setUnityData } from '../../../redux/slicers/admin/curatorSlicer';
import { useParams } from 'react-router-dom';
import { getJson } from '../../../helpers/axiosHelper';
import { CuratorSubMenu } from '../../../components/admin/curator/subMenu';
import { useSearchParams } from 'react-router-dom';
import { cameraAngleSelector } from '../../../redux/slicers/camera/cameraAngleSlicer';
import {
  getProejctZip,
  updateDbLayout,
  updateDbProp,
} from '../../../helpers/idb';
import { CuratorInitAndListeners } from './CuratorInitAndListeners';
import './../../../helpers/unityPubSub';
import { OPEN_PROJECT_ID } from '../../../config/constant/curatorConstants';
import { usePrepareRoom } from './projectParser';
import {
  selectProjectArchive,
  setFile,
} from '../../../redux/slicers/admin/curatorProjectArchiveSlicer';
import { CuratorCursorLoading } from '../../../components/admin/curator/CuratorCursorLoading';
import { errorToast } from '../../../helpers/toastHelper';

const AdminCurator = () => {
  const { getCameraAnglesList } = useSelector(cameraAngleSelector);
  const projectArchive = useSelector(selectProjectArchive);
  const { prepareRoom } = usePrepareRoom();
  const preventProjectInitialization = useSelector(selectPreventProjectInitialization)

  const {
    roomData,
    individualProjectData = {},
  } = useSelector(curatorSelector);

  const { room_id } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const dispatch = useDispatch();
  const URL_PATH = '/idbfs/fbb7b4051301b91273d81e288768386f/';

  useEffect(() => {
    // add project archive data from index db after refreshing the page
    if (room_id !== OPEN_PROJECT_ID) return;
    const loadProjectFromDb = async () => {
      try {
        const projectZipData = await getProejctZip();
        if (!projectZipData.file) {
          throw new Error('File is missing');
        }
        dispatch(setFile(projectZipData.file));
      } catch (error) {
        console.error(error);
        errorToast('Unable to find project zip file, please open project again');
      }
    };
    loadProjectFromDb();
  }, []);

  useEffect(() => {
    // use class insetead
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  useEffect(() => {
    if (!projectArchive) return;
    prepareRoom({ projectArchive });
  }, [projectArchive]);

  useEffect(() => {
    if (preventProjectInitialization) return;

    if (room_id === OPEN_PROJECT_ID) {
      // we handle it in different place
      return;
    }

    if (room_id && type === 'project') {
      dispatch(getProjectApi(room_id));
    } else {
      prepareRoom({ roomId: room_id });
    }
  }, [room_id]);

  useEffect(async () => {
    if (preventProjectInitialization) return;

    if (type === 'project') {
      if (!individualProjectData?.id) return;
      prepareRoom({ projectId: room_id });
    } else {
      if (room_id === OPEN_PROJECT_ID) {
        return;
      }

      let check = roomData[0]?.is_custom;
      if (check) {
        console.log(check);
        let json = {};
        //constructing json
        const find_json = roomData[0]?.room_data;
        if (find_json) {
          const file_data = await getJson(find_json);

          console.log('sasha 222 file_data', file_data);

          const layout_id = file_data?.blenderLayoutBundleDatas[0]?.id;
          console.log(file_data);
          if (!layout_id) {
            return;
          }
          const layoutData = await layoutReadApi(layout_id);
          const layoutSingle = layoutData;

          json.layoutId = layout_id;
          const layoutDbData = await updateDbLayout(layoutData);
          json.layoutLocalBundlePath = layoutDbData.file || layoutSingle.webgl;
          let props = [];
          let createProps = await Promise.all(
            file_data?.blenderAssetBundleDatas?.map(async (a) => {
              let transform_prop =
                file_data && file_data?.transformChangedObjectData[a?.objectName];

              let propDetailsArr = await uniquePropsApi(a?.id);
              let propDetails = propDetailsArr?.results[0];
              const propDbData = await updateDbProp(propDetails);
              const fileUrl = propDbData?.file || propDetails?.product_file;
              return {
                type: 'ProjectProp',
                id: a?.id,
                objectName: a?.objectName,
                indexName: a?.objectIndex,
                xPosition: transform_prop.xPosition,
                yPosition: transform_prop.yPosition,
                zPosition: transform_prop.zPosition,
                xRotation: transform_prop.xRotation,
                yRotation: transform_prop.yRotation,
                zRotation: transform_prop.zRotation,
                wRotation: transform_prop.wRotation,
                xScale: transform_prop.xScale,
                yScale: transform_prop.yScale,
                zScale: transform_prop.zScale,
                locationUrl: fileUrl,
                ...(a.is_metadata && a.product_meta_info
                  ? { preDefinedMetadata: a.product_meta_info }
                  : {}),
              };
            })
          );
          json.roomCameraInfo = file_data?.roomCameraInfo;
          json.roomLightData = [];
          json.transformData = file_data?.transformChangedObjectData;
          json.props = createProps;
          json.materialDetails = file_data?.materialDetails;
          const propsList = json?.props;

          json.userCameraInfo = getCameraAnglesList;
          json.isCustomRoom = true;
          json.isProjectRoom = false;
          json.propsHierarchyData = file_data?.propsHierarchyData || {};
          json.duplicatePropDatas = file_data?.duplicatePropDatas || [];
          json.deletedObjectsData = file_data?.deletedObjectData || [];

          dispatch(setUnityData(json));
        }
      }
    }
  }, [individualProjectData?.id]);

  return (
    <div style={{ fontSize: '0.875rem', userSelect: 'none' }}>
      <CuratorInitAndListeners />
      <CuratorHeader />

      <SidebarV2 />
      <CuratorSubMenu />
      <CuratorCursorLoading />
    </div>
  );
};

export default AdminCurator;
