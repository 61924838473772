import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { RiImageLine } from 'react-icons/ri';
import { propsReadApi } from '../api/curator';

export default function Image({
  containerProps,
  ratio,
  placeholderWhileLoading,
  placeholderColor = 'var(--image-placeholder)',
  clickLink,
  ...imgProps
}) {
  const [loaded, setLoaded] = useState(!placeholderWhileLoading);
  const containerClassName = cx('imageRoot', containerProps?.className);

  if (imgProps.avatar) {
    imgProps.style = {
      ...imgProps.style,
      height: "32px",
      borderRadius: "8px"
    }
  }
  if (!ratio) {
    return <img {...imgProps} className={cx('simpleImage', imgProps.className)} />;
  }

  const paddingBottom = `${(1 / ratio) * 100}%`;

  const handleImageLoad = (event) => {
    setLoaded(true);
    imgProps?.onLoad?.(event);
  };
  let Element = `div`;
  if (clickLink && clickLink.length > 0) {
    let otherProps = Object.assign({}, containerProps);
    Element = Link;
    otherProps.to = clickLink;
    containerProps = otherProps;
  }
  return (
    <Element {...containerProps} className={containerClassName}>
      <div
        style={{
          paddingBottom,
          display: 'flex',
          width: '100%',
          backgroundColor: placeholderWhileLoading ? placeholderColor : 'transparent',
          borderRadius: 8,
        }}>
        {!loaded && <RiImageLine fontSize={24} className={'placeholderIcon'} />}
        <img
          loading="lazy"
          {...imgProps}
          onLoad={placeholderWhileLoading ? handleImageLoad : imgProps.onLoad}
          style={{
            ...imgProps.style,
            opacity: loaded ? 1 : 0,
          }}
        />
      </div>
    </Element>
  );
}
