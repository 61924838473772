import cx from "classnames";
import CloseCircleIcon from "../../../icons/closeCircleIcon";
import { FILTER } from "../CuratorFilter/CuratorFilter";
import { useIntersection, useMedia } from "react-use";
import { animateScroll, Element, scroller } from "react-scroll";

import styles from "./SelectedFilterList.module.scss";
import ArrowDownIcon from "../../../icons/ArrowDownIcon";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

export const SelectedFilterList = ({
  filter,
  filterValue,
  onDelete,
  onClear,
  className,
  name,
}) => {
  if (!name) {
    throw new Error('Unique Name is required');
  }

  const [arrowsVisible, setArrowsVisible] = useState(false);
  const containerRef = useRef(null);

  const intersectionLeftRef = useRef(null);
  const intersectionLeft = useIntersection(intersectionLeftRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionRightRef = useRef(null);
  const intersectionRight = useIntersection(intersectionRightRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  useEffect(() => {
    if (!intersectionRight || !intersectionLeft) return;

    const visible =
      intersectionRight.intersectionRatio !== 1 ||
      intersectionLeft.intersectionRatio !== 1;
    setArrowsVisible(visible);
  }, [
    intersectionRight?.intersectionRatio,
    intersectionLeft?.intersectionRatio,
  ]);

  if (filterValue.length === 0) return null;

  const defaultLabels = {
    [FILTER.MY_FAVOURITES]: "My Favourites",
    [FILTER.MY_TEXTURES]: "My Textures",
    [FILTER.ALL_TEXTURES]: "All Textures",
    [FILTER.MY_UPLOADS]: "My Uploads",
    [FILTER.ALL_UPLOADS]: "All Uploads",
    [FILTER.MY_3D_MODELS]: "My 3d models",
    [FILTER.PROPS]: "Props",
  };

  const scrollContainerId = `curatorFilter-${name}`;

  const handleNextClick = () => {
    const visibleItems = containerRef.current.querySelectorAll(
      '[data-visible="true"]'
    );
    const lastVisibleItem = visibleItems[visibleItems.length - 1];
    const nextItem = lastVisibleItem.nextSibling;

    if (nextItem && nextItem.dataset.visible === "false") {
      const container = document.getElementById(scrollContainerId);
      const width = nextItem.clientWidth;
      const scrollToPx = container.scrollLeft + width;

      animateScroll.scrollTo(scrollToPx, {
        horizontal: true,
        containerId: scrollContainerId,
        duration: 200,
      });
    } else {
      animateScroll.scrollTo(10000, {
        horizontal: true,
        containerId: scrollContainerId,
        duration: 200,
      });
    }
  };

  const handlePrevClick = () => {
    const visibleItems = containerRef.current.querySelectorAll(
      '[data-visible="true"]'
    );
    const lastVisibleItem = visibleItems[0];
    const prevItem = lastVisibleItem.previousSibling;

    if (prevItem && prevItem.dataset.visible === "false") {
      const container = document.getElementById(scrollContainerId);
      const width = prevItem.clientWidth;
      const scrollToPx = container.scrollLeft - width;

      animateScroll.scrollTo(scrollToPx, {
        horizontal: true,
        containerId: scrollContainerId,
        duration: 200,
      });
    } else {
      animateScroll.scrollTo(0, {
        horizontal: true,
        containerId: scrollContainerId,
        duration: 200,
      });
    }
  };

  return (
    <div className={className}>
      <div className={styles.sliderContainer}>
        {arrowsVisible && (
          <button className={styles.prevButton} onClick={handlePrevClick}>
            <ArrowDownIcon />
          </button>
        )}

        <div
          id={scrollContainerId}
          className={cx(
            styles.scrollableContainer,
            arrowsVisible && styles.withArrows
          )}
          ref={containerRef}
        >
          <div className={styles.observableDiv} ref={intersectionLeftRef} />

          {filterValue.map((value) => {
            let label = defaultLabels[value];

            if (!label && value.startsWith("brand")) {
              const [, id] = value.split("_");
              label = filter.brands.find((b) => b.id === parseInt(id))?.name;
            }

            if (!label) {
              label = filter.filteroptions.find(
                (option) => `option_${option.filter_label}_${option.id}` === value
              )?.name;
            }

            return (
              <Item
                value={value}
                label={label}
                key={value}
                onDelete={onDelete}
              />
            );
          })}

          <div className={styles.observableDiv} ref={intersectionRightRef} />
        </div>
        {arrowsVisible && (
          <button
            className={styles.nextButton}
            onClick={handleNextClick}
          >
            <ArrowDownIcon />
          </button>
        )}

        <button type="button" className={styles.clearButton} onClick={onClear}>
          Clear all
        </button>
      </div>
    </div>
  );
};

export const Item = ({ value, label, onDelete }) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  return (
    <div
      id={`curator-filter-item-${value}`}
      data-name={`curator-filter-${value}`}
      ref={intersectionRef}
      className={cx("m-1", styles.selectedFilter)}
      data-visible={intersection?.intersectionRatio === 1 ? "true" : "false"}
      key={value}
      // style={{ minWidth: 120 }}
    >
      {label}
      <span className={styles.deleteButton} onClick={() => onDelete(value)}>
        <CloseCircleIcon />
      </span>
    </div>
  );
};
