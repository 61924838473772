import { ColorPickerIcon } from '../../../icons/ColorPickerIcon';
import styles from './EyeDropperButton.module.scss';

export const EyeDropperButton = ({ onChange }) => {
  const supported = 'EyeDropper' in window
  if (!supported) {
    return null;
  }


  const handleClick = async () => {
    const eyeDropper = new window.EyeDropper();

    try {
      const selectedColor = await eyeDropper.open();
      if (selectedColor.sRGBHex) {
        onChange(selectedColor.sRGBHex);
      } else {
        // TODO: log error here
      }
    } catch (err) {
        // ignore this, color selection was cancelled
    }
  }

  return (
    <button className={styles.button} onClick={handleClick}>
      <ColorPickerIcon />
    </button>
  )
}