import logo from '../../assets/images/lf-logo.png';
import MyProductsIcon from '../../assets/images/products.svg';
import MyProjectsIcon from '../../assets/images/my-projects.svg';
import RendersPreviewsIcon from '../../assets/images/renders-previews.svg';
import RoomsIcon from '../../assets/images/rooms.svg';
import PropsIcon from '../../assets/images/props.svg';
import TexturesIcon from '../../assets/images/textures.svg';
import TextureClose from '../../assets/images/textureclose-icon.png';
import SofaIcon from '../../assets/images/sample-pic-of-sofa.png';
import Room1 from '../../assets/images/room-1.png';
import HeartIcon from '../../assets/images/icon for card/Icons-heart.svg';
import MenuIcon from '../../assets/images/icon for card/Menu.svg';
import Person12 from '../../assets/images/Render/person12.png';
import ArrowSvg from '../../assets/images/arrow.svg';
import RightIcon from '../../assets/images/right-icon.svg';
import CrossIcon from '../../assets/images/Cross-icon.svg';
import Unsplash from '../../assets/images/unsplash_5aGUyCW_PJw.png';
import Unsplash1 from '../../assets/images/unsplash_IF9TK5Uy-KI.png';
import SendIcon from '../../assets/images/send-icon.svg';
import CommentSvg from '../../assets/images/comment.svg';
import CollectionsIcon from '../../assets/images/collections.svg';
import SearchIcon from '../../assets/images/search.svg';
import NotificationsIcon from '../../assets/images/notification.svg';
import Skyblue from '../../assets/images/skyblue.png';
import image1 from '../../assets/images/image 1.png';
import Photo from '../../assets/images/Photo.png';
import Grapelite from '../../assets/images/grapelite.png';
import Yellow from '../../assets/images/yellow.png';
import Graylite from '../../assets/images/graylite.png';
import Material from '../../assets/images/material.png';
import BlackDot from '../../assets/images/black-dot.png';
import PurpleDot from '../../assets/images/purple-dot.png';
import Pink from '../../assets/images/pink.png';
import Image3 from '../../assets/images/image 3.png';
import Image2 from '../../assets/images/image 2.png';
import GreyDot from '../../assets/images/grey-dot.png';
import TextureImage from '../../assets/images/texture-img.jpg';
import Visibility from '../../assets/images/visibility.svg';

import Tick from '../../assets/images/tick-wight.svg';
import ClearAll from '../../assets/images/clear-all.svg';
import NotTick from '../../assets/images/not-tick.svg';
import NotificationBars from '../../assets/images/notificationbars.png';


//MY TEAMS 
import Union from '../../assets/images/Union.svg';
import Picsp from '../../assets/images/pics.png';

import OneMemberIcon from '../../assets/images/one-member.svg';
import OneMemberWhiteIcon from '../../assets/images/one-member-white.svg';
import TeamMemberIcon from '../../assets/images/team-member.svg';
import TeamMemberWhiteIcon from '../../assets/images/team-member-white.svg';
import VectorArrow from '../../assets/images/VectorArrow.svg';
import UnionBin from '../../assets/images/UnionBin.svg';
import back from '../../assets/images/UnionBack.svg';
import close from '../../assets/images/Unionclose.svg';
import Exclamation from '../../assets/images/UnionExclamation.svg';
import clipBoard from '../../assets/images/Unionclipboard.svg';
import call from '../../assets/images/VectorCall.svg';
import location from '../../assets/images/Unionlocation.svg';

// top main menu icons

// user menu icons
import MyAccountIcon from '../../assets/images/my-account.svg';
import AddCreditsIcon from '../../assets/images/add-credits.svg';
import LogoutIcon from '../../assets/images/logout.svg';
import QuitIcon from '../../assets/images/quit.svg';
import UserIcon from "../../assets/images/myprojects/rect.svg";

// sidebar icons
import MyTeamIcon from '../../assets/images/my-team.svg';
import SharedFilesIcon from '../../assets/images/shared-files.svg';
import CalendarIcon from '../../assets/images/calendar.svg';
import CommunityIcon from '../../assets/images/community.svg';
import SettingsIcon from '../../assets/images/settings.svg';
import TrashIcon from '../../assets/images/trash.svg';
import PlayToLearnIcon from '../../assets/images/play-to-learn.svg';
import RequestsIcon from '../../assets/images/requests.svg';
import SupportIcon from '../../assets/images/support.svg';


import BlackBorder from '../../assets/images/Background & Border.png';
import BlackBorder1 from '../../assets/images/Background & Border (1).png';
import DownArrw from '../../assets/images/downarrow.svg';
import closeBtn from '../../assets/images/curator/close-btn.png';
import move from '../../assets/images/curator/move.png';
import photo from '../../assets/images/curator/photo.png';
import Rectangle651 from '../../assets/images/curator/rectangle-651.png';

import closeIcon from '../../assets/images/closeWithBG.svg';
import closeWhite from '../../assets/images/close-white.svg';

// thumbnail card icons
// import NotFavouriteIcon from '../../assets/images/favourite.png';
// import FavouriteIcon from '../../assets/images/tick-favourite.png';
import NotFavouriteBlackIcon from '../../assets/images/not-favourite-black.svg';
import NotFavouriteIcon from '../../assets/images/not-favourite.svg';
import FavouriteIcon from '../../assets/images/tick-favourite.svg';
import EditIcon from '../../assets/images/edit.svg';
import ShareIcon from '../../assets/images/share.svg';
import DeleteIcon from '../../assets/images/delete.svg';
import DownloadIcon from "../../assets/images/download.svg";

import TableLayoutIcon from '../../assets/images/btn-table.svg';
import TableLayoutWhiteIcon from '../../assets/images/btn-table-white.svg';
import GridLayoutIcon from '../../assets/images/btn-grid.svg';
import GridLayoutWhiteIcon from '../../assets/images/btn-grid-white.svg';
import SelectArrow from '../../assets/images/select-arrow.svg';

import ActionDotsIcon from '../../assets/images/action-dots.svg';

// home page: design card images & icons
import SiloTemplateBG from '../../assets/images/silo-design-bg.svg';
import RoomTemplateBG from '../../assets/images/room-design-bg.svg';
import AugmentedRealityBG from '../../assets/images/augmented-design-bg.svg';
import VideoBG from '../../assets/images/video-design-bg.svg';
import VignetteBG from '../../assets/images/vignette-design-bg.svg';
import WebViewerBG from '../../assets/images/web-viewer-design-bg.svg';
import ConfiguratorBG from '../../assets/images/configurator-design-bg.svg';

import SiloTemplateDIcon from '../../assets/images/silo-template.svg';
import RoomTemplateDIcon from '../../assets/images/room-template.svg';
import AugmentedRealityDIcon from '../../assets/images/augmented-reality.svg';
import VideoDIcon from '../../assets/images/video.svg';
import VignetteDIcon from '../../assets/images/vignette.svg';
import WebViewerDIcon from '../../assets/images/web-viewer.svg';
import ConfiguratorDIcon from '../../assets/images/configurator.svg';
import NextArrowIcon from '../../assets/images/nextarrow.png';
import PrevArrowIcon from '../../assets/images/previousarrow.png';
import ApplePay from '../../assets/images/applepay.png';
import GooglePay from '../../assets/images/googlepay.svg';

import Download from '../../assets/images/curator/download-icon.png';
import Comments_Curator from '../../assets/images/curator/comments.png';
import Edit from '../../assets/images/curator/edit-file.png';
import Share from '../../assets/images/curator/share.png';
import AddCollection from '../../assets/images/curator/add-collection.png';
import Favourite from '../../assets/images/curator/favourite.png';
import FullScreen from '../../assets/images/curator/full-screen.png';
import Archive from '../../assets/images/curator/archive.png';
import Help from '../../assets/images/curator/help.png';
// web experience
import BackSvg from '../../assets/images/back.svg';
import SmallSofa from '../../assets/images/smallsofa.png';
import PhotoSvg from '../../assets/images/Photo.svg';
import ShareIconSvg from '../../assets/images/shareicon.svg';
import DownloadSvg from '../../assets/images/download.svg';
import RatingSvg from '../../assets/images/rating.svg';
import SmallSvg from '../../assets/images/smallimg.svg';
import RectangleOne from "../../assets/images/Rectangle 627-1.png"
import Rectangle_First from "../../assets/images/foto01 1.png"

import ImgProfile from '../../assets/images/textures/imgprofile.png'
import ImgDelete from '../../assets/images/imgdeleteicon.svg'
import SofaPng from '../../assets/images/sofa.png'
import ProductName from '../../assets/images/productname.svg'
import UploadSvg from '../../assets/images/upload.svg'
import BtnUpload from '../../assets/images/btnupload.png'
import InfoBtn from '../../assets/images/infobtn.svg'
import Invoice from '../../assets/images/invoice.svg'
import paymentNote from '../../assets/images/paymentnote.svg'

import ClosePanel from '../../assets/images/closePanel.png'

import SliderArrowLeft from '../../assets/images/slider-arrow-left.svg'
import SliderArrowRight from '../../assets/images/slider-arrow-right.svg'
import SliderClose from '../../assets/images/slider-close.svg'

import bulb from '../../assets/images/bulb.png'

export const REACTANGLE_ONE = RectangleOne
export const REACTANGLE_FIRST = Rectangle_First

export const SMALL_SVG = SmallSvg
export const BACK_SVG = BackSvg
export const RATING_SVG = RatingSvg
export const SMALL_SOFA = SmallSofa
export const PHOTO_SVG = PhotoSvg
export const SHARE_ICON_SVG = ShareIconSvg
export const DOWNLOAD_SVG = DownloadSvg



// my product
export const UPLOAD_SVG = UploadSvg
export const PRODUCT_NAME = ProductName
export const SOFA_PNG = SofaPng
export const IMG_DELETE = ImgDelete
export const IMG_PROFILE = ImgProfile
export const BTN_UPLOAD = BtnUpload
export const INFO_BTN = InfoBtn

// notification

export const VISIBILITY = Visibility
export const TICK = Tick
export const CLEARALL_ICON = ClearAll
export const NOT_TICK = NotTick
export const NOTIFICATONS_BARS = NotificationBars




export const NEXT_ICON = NextArrowIcon
export const PREV_ICON = PrevArrowIcon

export const ONE_MEMBER_ICON = OneMemberIcon
export const ONE_MEMBER_WHITE_ICON = OneMemberWhiteIcon
export const TEAM_MEMBER_ICON = TeamMemberIcon
export const TEAM_MEMBER_WHITE_ICON = TeamMemberWhiteIcon

export const CLOSE_ICON = closeIcon
export const CLOSE_WHITE = closeWhite

// exporting main menu icons
export const MY_PRODUCTS_ICON = MyProductsIcon
export const MY_PROJECTS_ICON = MyProjectsIcon
export const RENDERS_PREVIEWS_ICON = RendersPreviewsIcon
export const ROOMS_ICON = RoomsIcon
export const PROPS_ICON = PropsIcon
export const TEXTURES_ICON = TexturesIcon
export const COLLECTION_ICON = CollectionsIcon
export const SEARCH_ICON = SearchIcon
export const NOTIFICATONS_ICON = NotificationsIcon
export const USER_ICON = UserIcon;

// exporting user menu icons
export const MY_ACCOUNT_ICON = MyAccountIcon
export const ADD_CREDITS_ICON = AddCreditsIcon
export const LOGOUT_ICON = LogoutIcon
export const QUIT_ICON = QuitIcon
export const UNION = Union
export const PICSP = Picsp



// exporting sidebar menu icons
export const MY_TEAM_ICON = MyTeamIcon
export const SHARED_FILES_ICON = SharedFilesIcon
export const CALENDAR_ICON = CalendarIcon
export const COMMUNITY_ICON = CommunityIcon
export const SETTINGS_ICON = SettingsIcon
export const TRASH_ICON = TrashIcon
export const PLAY_TO_LEARN_ICON = PlayToLearnIcon
export const REQUESTS_ICON = RequestsIcon
export const SUPPORT_ICON = SupportIcon

export const LOGO = logo
export const TEAMS_VECTOR_ICON = VectorArrow
export const BIN = UnionBin
export const BACK = back
export const CLOSE = close
export const UnionExclamation = Exclamation
export const ClipBoard = clipBoard
export const Call = call
export const Location = location

//curator
export const CURATOR_BLACK_BORDER = BlackBorder
export const CURATOR_BLACK_BORDER1 = BlackBorder1
export const DOWNARRW = DownArrw
export const CURATOR_CLOSE_BTN = closeBtn
export const CURATOR_MOVE = move
export const CURATOR_PHOTO = photo
export const CURATOR_RECTANGLE = Rectangle651
export const IMAGE1 = image1
export const PHOTO = Photo
export const GRAPELITE = Grapelite
export const YELLOW = Yellow
export const SKYBLUE = Skyblue
export const GRAYLITE = Graylite
export const TEXTURE_IMAGE = TextureImage

export const MATERIAL = Material
export const BLACKDOT = BlackDot
export const PURPLEDOT = PurpleDot
export const IMAGE3 = Image3
export const IMAGE2 = Image2
export const GREYDOT = GreyDot
export const PINK = Pink

export const DOWNLOAD = Download;
export const COMMENTS_CURATOR = Comments_Curator;
export const EDITFILE = EditIcon;
export const SHARE = Share;
export const ADDCOLLECTION = AddCollection;
export const FAVOURITE = Favourite;
export const FULLSCREEN = FullScreen;
export const ARCHIVE = Archive;
export const HELP = Help;

export const CLOSE_PANEL = ClosePanel


// exporting thumbnail card icons
export const NOT_FAVOURITE_BLACK_ICON = NotFavouriteBlackIcon
export const NOT_FAVOURITE_ICON = NotFavouriteIcon
export const FAVOURITE_ICON = FavouriteIcon
export const EDIT_ICON = EditIcon
export const SHARE_ICON = ShareIcon
export const DELETE_ICON = DeleteIcon
export const DOWNLOAD_ICON = DownloadIcon;

export const TABLE_LAYOUT_ICON = TableLayoutIcon
export const TABLE_LAYOUT_WHITE_ICON = TableLayoutWhiteIcon
export const GRID_LAYOUT_ICON = GridLayoutIcon
export const GRID_LAYOUT_WHITE_ICON = GridLayoutWhiteIcon
export const SELECT_ARROW = SelectArrow

export const ACTION_DOTS_ICON = ActionDotsIcon

export const SILO_TEMPLATE_BG = SiloTemplateBG
export const ROOM_TEMPLATE_BG = RoomTemplateBG
export const AUGMENTED_REALITY_BG = AugmentedRealityBG
export const VIDEO_BG = VideoBG
export const VIGNETTE_BG = VignetteBG
export const WEB_VIEWER_BG = WebViewerBG
export const CONFIGURATOR_BG = ConfiguratorBG

export const SILO_TEMPLATE_DICON = SiloTemplateDIcon
export const ROOM_TEMPLATE_DICON = RoomTemplateDIcon
export const AUGMENTED_REALITY_DICON = AugmentedRealityDIcon
export const VIDEO_DICON = VideoDIcon
export const VIGNETTE_DICON = VignetteDIcon
export const WEB_VIEWER_DICON = WebViewerDIcon
export const CONFIGURATOR_DICON = ConfiguratorDIcon

export const THEME_DARK_BLUE = '#004c70'

export const SLIDER_ARROW_LEFT = SliderArrowLeft
export const SLIDER_ARROW_RIGHT = SliderArrowRight
export const SLIDER_CLOSE = SliderClose

// render
export const ROOM1 = Room1
export const TEXTURES_CLOSE = TextureClose
export const SOFA_ICON = SofaIcon
export const HEART_ICON = HeartIcon
export const MENU_ICON = MenuIcon
export const PERSON12 = Person12
export const ARROW_SVG = ArrowSvg
export const RIGHT_ICON = RightIcon
export const UNSPLASH = Unsplash
export const CROSS_ICON = CrossIcon
export const UNSPLASH1 = Unsplash1
export const SEND_ICON = SendIcon
export const COMMENT_SVG = CommentSvg

export const GOOGLE_PAY = GooglePay
export const APPLE_PAY = ApplePay

export const BULB = bulb
//settings
export const INVOICE_ICON = Invoice
export const PAYMENT_NOTE_ICON = paymentNote


