import { useEffect, useCallback, useState } from 'react';

export const useContextMenu = ({ selector }) => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);

  const handleContextMenu = useCallback(
    (event) => {
      if (event.ctrlKey) return;

      event.preventDefault();
      setAnchorPoint({ x: event.pageX, y: event.pageY });
      setShow(true);
    },
    [setShow, setAnchorPoint]
  );

  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

  useEffect(() => {
    const element = selector ? document.querySelector(selector) : document;

    document.addEventListener('click', handleClick);
    element.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('click', handleClick);
      element.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [show]);

  return { anchorPoint, show };
};
