import { useEffect, useState } from "react"
import { getMaterial, getTexture } from "../../../../../helpers/idb";

export const useTextureImages = (changedMapsPath, properties) => {
  const [data, setData] = useState([]);

  console.log('useTextureImages rerender')

  useEffect(() => {
    const getData = async () => {
      const textures = [
        {
          label: 'Texture',
          id: changedMapsPath?.diffuseId,
          image: changedMapsPath?.diffusePath,
        },
        {
          type: 'material',
          label: 'Material',
          id: properties?.loadedMaterialId,
        },
        {
          label: 'AO map',
          id: changedMapsPath?.AOId,
          image: changedMapsPath?.AOPath,
        },
        {
          label: 'Metallic map',
          id: changedMapsPath?.metallicId,
          image: changedMapsPath?.metallicPath,
        },
        {
          label: 'Normal map',
          id: changedMapsPath?.normalId,
          image: changedMapsPath?.normalPath,
        },
        {
          label: 'Roughness map',
          id: changedMapsPath?.roughnessId,
          image: changedMapsPath?.roughnessPath,
        },
        {
          label: 'Transparent map',
          id: changedMapsPath?.transparencyId,
          image: changedMapsPath?.transparencyPath,
        }
      ].filter(t => Boolean(t.id))

      
      const result = await Promise.all(textures.map(async (t) => {
        if (t.type === 'material') {
          return await getMaterial(t.id)
        }
        const texture = await getTexture(t.id)
        return texture;
      }))

      
  
      setData(textures.map((t, idx) => ({
        thumbnail: t.type === 'material' ? result[idx].thumbnail : result[idx].file,
        label: t.label,
        organizationId: result[idx].owner_organization,
        name: result[idx].name,
      })))
    }

    getData();
  }, [properties])
  return {
    data,
  }
}