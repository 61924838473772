import { useDispatch, useSelector } from "react-redux";
import { TextureAddApi } from "../../../../../api/textures";
import { useCuratorData } from "../../../../../hooks/useCuratorData";
import { paginatedTexturesSelector } from "../../../../../redux/slicers/textures/paginatedTexturesSlicer";
import { useFilterOption } from "./useFilterOption";

export const useUploadTexture = () => {
  const dispatch = useDispatch();
  const { projectId } = useCuratorData();
  const { filter, tilingType } = useFilterOption();
  const loading = useSelector(state => paginatedTexturesSelector(state).addLoading)

  const uploadTexture = (file) => {
    const formData = new FormData();
    formData.append("texture_file", file);
    
    if (projectId) {
      formData.append('project', projectId);
    }

    if (filter) {
      formData.append('filteroptions', filter.id);
      formData.append('is_map', true);
    }

    dispatch(TextureAddApi(formData));
  };

  return {
    uploadTexture,
    loading,
  }
}
