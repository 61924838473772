/* eslint-disable no-debugger */
import cx from 'classnames';
import { useEffect, useState } from 'react';
import {
  stripNonInteger,
  stripNonNumbers,
  stripNonNumbersFullRange,
} from '../../../helpers/jsHelper';
import styles from './NumberInput.module.scss';

// TODO: handle blur event

export const NumberInput = ({
  onChange,
  value: defaultValue,
  onlyIntegers,
  enableNegative,
  withArrows,
  containerClassName,
  step = 1,
  min,
  max,
  inputProps,
  InputComponent = 'input',
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    const { value } = e.target;
    let stripFunction = onlyIntegers ? stripNonInteger : stripNonNumbers;

    if (enableNegative) {
      stripFunction = onlyIntegers ? stripNonInteger : stripNonNumbersFullRange;
    }

    if (value.length !== stripFunction(value).length) {
      e.preventDefault();
      return;
    }

    if (value.startsWith('.')) return;

    if (value.includes('.') && value.split('.').length > 2) {
      // we do not allow 2 . symbols
      e.preventDefault();
      return;
    }

    if (enableNegative && value.includes('-') && value.split('-').length > 2) {
      e.preventDefault();
      return;
    }

    if (enableNegative && value.includes('-') && value.indexOf('-') !== 0) {
      return;
    }

    if (value.endsWith('.')) {
      setValue(value);
      return;
    }

    onChange?.(e);
  };

  const input = <InputComponent
      {...rest}
      {...inputProps}
      onChange={handleChange}
      value={value}
      className={cx(styles.input, rest.className)}
    />

  if (withArrows) {
    const handleArrowUpClick = () => {
      let newValue = Number.isNaN(parseFloat(value)) ? 0 : value;
      newValue += step;

      if (typeof max === 'number' && newValue > max) return;
      
      onChange({
        target: {
          name: rest.name,
          value: `${newValue}`,
        }
      })
    }

    const handleArrowDownClick = () => {
      let newValue = Number.isNaN(parseFloat(value)) ? 0 : value;
      newValue -= step;

      if (typeof min === 'number' && newValue < min) return;
      
      onChange({
        target: {
          name: rest.name,
          value: `${newValue}`,
        }
      })
    }

    return (
      <div className={cx(containerClassName, styles.container)}>
        {input}
        <div className={styles.arrowContainer}>
          <div className={styles.arrowUp} onClick={handleArrowUpClick}>
            <Arrow />
          </div>
          <div className={styles.arrowDown} onClick={handleArrowDownClick}>
            <Arrow />
          </div>
        </div>
      </div>
    );
  }

  return input;
};

const Arrow = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.32313 10.3608C8.9191 10.3608 8.51506 10.2113 8.20915 9.91781L4.44585 6.30708C4.27847 6.14648 4.27847 5.88066 4.44585 5.72006C4.61324 5.55946 4.89029 5.55946 5.05768 5.72006L8.82097 9.33079C9.09802 9.59661 9.54823 9.59661 9.82529 9.33079L13.5886 5.72006C13.756 5.55946 14.033 5.55946 14.2004 5.72006C14.3678 5.88066 14.3678 6.14648 14.2004 6.30708L10.4371 9.91781C10.1312 10.2113 9.72716 10.3608 9.32313 10.3608Z"
        fill="#5A5C61"
      />
    </svg>
  );
};
