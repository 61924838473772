import { useDispatch } from "react-redux";
import { ColorCodeAddApi, ColorCodeDeleteApi, getMaterialColorCodeListApi } from "../../../../../api/materials";
import { useUserContext } from "../../../../../contexts/UserContext";
import { infoToast } from "../../../../../helpers/toastHelper";
import { useSimpleDataList } from "../../../../../hooks/useDataList";
import {
  paginatedColorActions,
  paginatedColorSelector,
} from "../../../../../redux/slicers/admin/paginatedColorSlicer";

export const useColors = () => {
  const dispatch = useDispatch();
  const { currentUser } = useUserContext();

  let {
    list: colorList,
  } = useSimpleDataList({
    getAction: getMaterialColorCodeListApi,
    resetAction: paginatedColorActions.resetList,
    selector: paginatedColorSelector,
  });

  const addColor = (color) => {
    const exist = colorList.find(c => c.color_value === color);
    if (exist) {
      infoToast('This color is already added to the list')
      return;
    }

    dispatch(ColorCodeAddApi({
      color, 
      memberId: currentUser.id,
    }))
  }

  const deleteColor = (colorId) => {
    dispatch(ColorCodeDeleteApi(colorId))
  }

  return {
    colorList,
    addColor,
    deleteColor,
  }
}