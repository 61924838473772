import cx from "classnames";
import { useRef, useState } from "react";
import useClickAway from "react-use/lib/useClickAway";
import ArrowDownIcon from "../../../icons/ArrowDownIcon";
import styles from "./ColorTypeSwitcher.module.scss";

export const ColorTypeSwitcher = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setOpen(false);
  });

  const handleClick = (e) => {
    onChange(e.target.innerText);
  };

  return (
    <div
      ref={ref}
      className={cx(styles.dropdown, open && styles.open)}
      onClick={() => setOpen(!open)}
    >
      <div className={styles.value}>
        {value}
        <ArrowDownIcon />
      </div>

      <div className={styles.dropdownList}>
        <div className={styles.dropdownListItem} onClick={handleClick}>
          RGB
        </div>
        <div className={styles.dropdownListItem} onClick={handleClick}>
          HSV
        </div>
      </div>
    </div>
  );
};
