export const LockIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5185 9.3214V10.2214C18.5345 10.4734 19.1969 11.4958 19.1969 14.0086V15.3478C19.1969 18.2998 18.2969 19.1998 15.3449 19.1998H8.64887C5.69688 19.1998 4.79688 18.2998 4.79688 15.3478V14.0086C4.79688 11.4958 5.45927 10.4734 7.47528 10.2214V9.3214C7.47528 7.3774 7.94327 4.7998 11.9969 4.7998C16.0505 4.7998 16.5185 7.3774 16.5185 9.3214ZM8.48328 10.1566H8.64887V10.1638H15.3449H15.5105V9.3214C15.5105 7.2118 14.9129 5.8078 11.9969 5.8078C9.08087 5.8078 8.48328 7.2118 8.48328 9.3214V10.1566Z"
        fill="currentColor"
      />
    </svg>
  );
};
