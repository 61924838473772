/* eslint-disable no-unreachable, no-debugger */
import { parseArchiveProjectTextures } from "./parseArchiveProjectTextures";

export const parseArchiveV2 = async ({ archiveData, dispatch }) => {
  const { json, entries } = archiveData;

  const textureVersionsMap = {};
  
  const keys = ['diffuse', 'transparency', 'normal', 'AO', 'metallic', 'roughness']
  const updateTextureMap = (md, key) => {
    const textureId = md[`${key}Id`];
    if (!textureId) return;
    // aoVersion | diffuseVersion ...
    const version = md[`${key}Version`] || md[`${key.toLowerCase()}Version`];
    if (!version) return;
    textureVersionsMap[textureId] = version;
  }

  json.materialDetails.forEach(md => {
    keys.forEach(key => updateTextureMap(md, key))
  })


  console.log('parseArchiveV2 textureVersionsMap', textureVersionsMap)

  // parse image assets
  const imagesMap = await parseArchiveProjectTextures({
    entries,
    projectJsonVersion: 2,
    textureVersionsMap,
  })

  console.log('imagesMap', imagesMap);


  // parse texture details
  const updateTextureData = (md, key) => {
    const idKey = `${key}Id`;
    const prevTextureId = md[idKey];
    if (!prevTextureId) return;
    const versionKey = key === 'AO' ? 'aoVersion' : `${key}Version`;
    const texture = imagesMap[prevTextureId];

    md[idKey] = texture.id;
    md[versionKey] = texture.version;
  }

  const materialDetails = await Promise.all(
    Object.values(json.materialDetails).map((md) => {
      keys.forEach(key => updateTextureData(md, key))
      return md;
    })
  );

  return {
    materialDetails,
  }
}