import React from 'react';
import { Container } from 'react-bootstrap';
import { ThumbnailCard } from './';
import _ from 'lodash';

export default function ThumbnailContainer(props) {
  const { data, cardType, sideCard = false } = props;

  const lastEle = _.tail(data);

  if (!data || data.length < 1) return null;

  return (
    <Container id="infiniteScroll" className={`card-grid px-0 ${sideCard && 'justify-content-center'}`} fluid>
      {_.map(data, (item, i) => {
        let clickUrl = props.clickUrl ? props.clickUrl.replace(`:id`, item.id) : null;
        let cardProps = { ...props };
        delete cardProps.clickUrl;
        return (
          <ThumbnailCard
            key={`thumbnailCard${cardType}${item.id}${i}`}
            id={item.id}
            selectedList={props.selectedList}
            stackedCardImages={props.stackedCardImages}
            thumbnailData={item}
            cardType={cardType}
            last={lastEle}
            arr={data}
            clickUrl={clickUrl}
            newTab={props.newTab || false}
            onClick={props.setActiveIndex ? () => props.setActiveIndex(i) : props.onClick}
            {...cardProps}
          />
        );
      })}
    </Container>
  );
}
