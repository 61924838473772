import cx from 'classnames';
import { forwardRef } from 'react';
import CircularProgress from "../CircularProgress/CircularProgress";
import styles from './Loader.module.scss';

const Loader = forwardRef((props, ref) => {
  const { loading, color, thickness, position, as, size, children, className, style, backdrop, backdropColor, ...rest } = props;

  if (!loading && !children) return null;

  const Component = as || 'div';

  return (
      <Component 
          {...rest} 
          ref={ref}
          className={cx(
              styles.loaderV2, 
            //   !children && backdrop && styles.backdrop,
              className,
              backdropColor === 'dark' && styles.backdropDark,
          )} 
          style={{ 
              position, 
              ...style 
          }}
      >
          {backdrop && loading && <div className={styles.backdrop}></div>}
          {loading && (
              <div className={cx(styles.progressContainer)}>
                  <CircularProgress size={size} color={color} thickness={thickness} />
              </div>
          )}
          {children}
      </Component>
  );
});

export default Loader;
