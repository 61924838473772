import { useDispatch } from "react-redux";
import { infoDialogActions } from "../redux/slicers/infoDialogSlicer";

let resetTimerId = null;

export const useInfoDialogaActions = () => {
  const dispatch = useDispatch();
  return {
    /**
     * @param {{
     *  title: any;
     *  content?: any;
     *  buttonText?: any;
     *  cancelButtonText?: any;
     *  onButtonClick?: () => Promise<void> | void;
     *  onCancelClick?: () => void;
     *  onClose?: () => void;
     * }} config 
     * @returns void
     */
    openInfoDialog: (config) => {
      dispatch(infoDialogActions.openInfoDialog({ ...config, dispatch }));
      clearTimeout(resetTimerId);
    },
    closeInfoDialog: () => {
      dispatch(infoDialogActions.closeInfoDialog())

      resetTimerId = setTimeout(() => {
        dispatch(infoDialogActions.reset())
      }, 200)
    },
  };
};
