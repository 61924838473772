import { createSlice } from "@reduxjs/toolkit";

const filter = createSlice({
  name: "filters",
  initialState: {
    filterData: {},
    members: [],
  },
  reducers: {
    setFilterData: (state, { payload }) => {
      state.filterData = payload;
    },
    setMembers: (state, { payload }) => {
      state.members = payload;
    },
    toggleMember: (state, { payload }) => {
      state.members = state.members.includes(payload.id) 
        ? state.members.filter(m => m.id !== payload.id)
        : [...state.members, payload.id];
    }
  },
});

export const { setFilterData, setMembers, toggleMember } = filter.actions;
export const filterSelector = (state) => state.filter;
export const filterReducer = filter.reducer;
export default filterReducer;
