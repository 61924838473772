import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Stack,
  ToggleButton,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CLOSE_ICON } from "../../../../config/constant/assetConstant";
import { FILTER_OPTIONS } from "../../../../config/constant/projectConstant";
import { filterSelector } from "../../../../redux/slicers/filters/filterSlicer";
import { organisationSelector } from "../../../../redux/slicers/organisation/organisationSlicer";
import { tagSelector } from "../../../../redux/slicers/tags/globalTagSlicer";
import LFButton from "../../../LFButton";
import { IoWarningOutline } from "react-icons/io5";
import { addFilterOptionApi } from "../../../../api/commonApi";

const TYPE_ROOM = 1;
const LABEL_CATEGORY = "category";
const LABEL_STYLE = "style";

let isTags = {};

export const CreateRoom = ({
  modalProps,
  values,
  cameraList,
  errors,
  onChange,
  handleChangeCamera,
  updateCameraTags,
  onSubmit,
}) => {
  const dispatch = useDispatch()

  const { organisationData: organisations = [] } =
    useSelector(organisationSelector);
  const { filterData: filters = [] } = useSelector(filterSelector);
  const { tagData: globalTags = [] } = useSelector(tagSelector);

  const [cameras, setCameras] = useState([]);
  const [sceneOptions, setSceneOptions] = useState(FILTER_OPTIONS);
  const [brandOptions, setBrandOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [styleOptions, setStyleOptions] = useState([]);
  const [searchTagsOptions, setSearchTagsOptions] = useState([]);

  function getOptions(data) {
    if (data === undefined) return [];

    const array = data.map((item) => ({
      id: item.id,
      value: item.id,
      label: item.name,
    }));

    return array;
  }

  // useEffect(() => {
  //   console.log(filters.results.filter((f) => f.type === TYPE_ROOM));
  // }, [filters]);

  useEffect(() => {
    // Currently fetching static filters (styles, categories). To implement dynamic filters according to TYPE_ROOM
    const styles = filters.results?.find(
      (f) => f.type === TYPE_ROOM && f.label_name === LABEL_STYLE
    );
    const categories = filters.results?.find(
      (f) => f.type === TYPE_ROOM && f.label_name === LABEL_CATEGORY
    );

    const uoptions = [
      { id: 0, value: "all", label: "Select All Organisations" },
    ];
    uoptions.push(...getOptions(organisations.results));

    setBrandOptions(getOptions(organisations.results));
    setUserOptions(uoptions);
    setCategoryOptions(getOptions(categories?.options));
    setStyleOptions(getOptions(styles?.options));
    setSearchTagsOptions(getOptions(globalTags.results));
  }, [organisations, filters, globalTags]);

  useEffect(() => {
    setCameras(cameraList);
  }, [cameraList]);

  const [tagData, setTagData] = useState({});
  const [addTag, setAddTag] = useState(isTags);
  const [tags, setTags] = useState([]);

  const handleTagClick = (e, id) => {
    e.preventDefault();
    setAddTag({ ...addTag, [`is_visible${id}`]: false });
  };

  const handleCloseTagClick = (e, id) => {
    e.preventDefault();
    setTags([]);
    setAddTag({ ...addTag, [`is_visible${id}`]: true });
  };

  const hideTagList = (e) => {
    setTags([]);
  };

  const manageTagData = (value, id) => {
    const camera = cameras.find((item) => {
      return item.id === id;
    });

    setTags(camera.tags);
    setTagData({ ...tagData, [`box${id}`]: value });
  };

  const [isHovered, setIsHovered] = useState({});
  const handleMouseEnter = (index) => {
    setIsHovered((prevState) => ({ ...prevState.isHovered, [index]: true }));
  };
  const handleMouseLeave = (index) => {
    setIsHovered((prevState) => ({
      isHovered: { ...prevState.isHovered, [index]: false },
    }));
  };

  const onAddRemoveCamera = (id) => {
    const camera = cameraList.find((cam) => cam.id == id);
    handleChangeCamera(camera);
  };

  const onChangeCameraTag = (id, array) => {
    const camera = cameraList.find((cam) => cam.id == id);
    const tags = array.map((item) => item.label);
    const object = { ...camera, tags: tags };
    updateCameraTags(object);
  };

  return (
    <Modal
      {...modalProps}
      className="modal-create-room"
      size="lg"
      aria-labelledby="live-on-app-modal"
      centered
    >
      <Modal.Body>
        <div className="close-icon">
          <Image
            width={24}
            height={24}
            src={CLOSE_ICON}
            alt="close"
            onClick={modalProps.onHide}
            style={{ cursor: "pointer" }}
          />
        </div>
        <h3 className="modal-heading">
          This scene will be Live on server & users can access
        </h3>
        <Form className="modal-body-form">
          <Row>
            <Col md={7} className="modal-body-left">
              <div className="modal-header d-block">
                <div className="modal-body-child d-flex justify-content-between select-all-block">
                  <div className="d-flex justify-content-between w-100 multi-cb">
                    <div className="w-100 form-check custom-checkbox d-flex align-items-center">
                      <div className="w-100 d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="cbSelectAll"
                        />
                        <p>
                          Select All{" "}
                          <span style={{ color: "#A3A4A6" }}>
                            ({cameras && cameras.length} cameras)
                          </span>
                        </p>
                      </div>
                      <ButtonGroup>
                        <ToggleButton
                          id={`radio-standard`}
                          type="radio"
                          variant="outline-primary"
                          name="is_filmic"
                          value={"false"}
                          checked={"false" === values.is_filmic}
                          onChange={(e) =>
                            onChange(
                              e.currentTarget.name,
                              e.currentTarget.value
                            )
                          }
                        >
                          Standard
                        </ToggleButton>
                        <ToggleButton
                          id={`radio-filmic`}
                          type="radio"
                          variant="outline-primary"
                          name="is_filmic"
                          value={"true"}
                          checked={"true" === values.is_filmic}
                          onChange={(e) =>
                            onChange(
                              e.currentTarget.name,
                              e.currentTarget.value
                            )
                          }
                        >
                          Filmic
                        </ToggleButton>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
                <hr className="m-0" />
                <div className="modal-body p-0 w-100">
                  {cameras &&
                    cameras.map((item, index) => (
                      <div
                        key={`cameraBlock${item.id}`}
                        className="modal-body-child d-flex justify-content-between align-items-center" 
                        id={`cameraBlock${item.id}`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        <div className="d-flex justify-content-between w-100 multi-cb align-items-center">
                          <div className="form-check custom-checkbox d-flex align-items-center">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item.id}
                              onChange={(e) =>
                                onAddRemoveCamera(
                                  e.target.value,
                                  e.target.checked
                                )
                              }
                            />
                            <p>{item.cameraName}</p>
                          </div>
                          <div
                            className="verticle-align-middle"
                            style={{
                              display: isHovered[index] ? "block" : "none",
                            }}
                          >
                            <p
                              style={{
                                color: "#0059F1",
                                cursor: "pointer",
                                fontSize: "14px",
                                verticalAlign: "-webkit-baseline-middle",
                                marginBottom:"0"
                              }}
                              onClick={(e) => handleTagClick(e, item.id)}
                            >
                              + Add Tags
                            </p>
                          </div>
                        </div>
                        {addTag[`is_visible${item.id}`] === false && (
                          <div
                            className="d-block w-100 tag-selection-box"
                            style={{ position: "relative" }}
                          >
                            <div
                              className="d-flex justify-content-between my-2"
                              style={{ fontSize: "14px" }}
                            >
                              <span>Tags</span>
                              <span
                                onClick={(e) => handleCloseTagClick(e, item.id)}
                                style={{
                                  color: "#0059F1",
                                  cursor: "pointer",
                                }}
                              >
                                Cancel
                              </span>
                            </div>
                            <Select
                              name="search_tags"
                              options={searchTagsOptions}
                              isMulti
                              isClearable={false}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(selectedItems) => {
                                onChangeCameraTag(item.id, selectedItems);
                              }}
                              // onChange={(selectedItems) =>
                              //   onChange("search_tags", selectedItems)
                              // }
                            />
                            {/* <textarea
                              className="form-control border-black"
                              rows={4}
                              value={tagData[`box${item.id}`]}
                              onBlur={(e) => {
                                hideTagList(e);
                              }}
                              onChange={(e) =>
                                manageTagData(e.target.value, item.id)
                              }
                            ></textarea>
                            {tags && tags.length > 0 && (
                              <div className="d-block mt-2">
                                <ul className="list-group w-100 border-black">
                                  {tags.map((tag) => (
                                    <li className="list-group-item">{tag.name}</li>
                                  ))}
                                </ul>
                              </div>
                            )} */}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </Col>
            <Col md={5} className="modal-body-right">
              <Row className="mb-3">
                <Form.Group>
                  <Form.Label htmlFor="name">Scene name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter scene name"
                    value={values.name}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Scene type</Form.Label>
                    <Select
                      name="project_type"
                      options={sceneOptions}
                      value={values.project_type}
                      onChange={(selectedItems) =>
                        onChange("project_type", selectedItems)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Brand/Owner</Form.Label>
                    <Select
                      name="owner_organization"
                      options={brandOptions}
                      value={values.owner_organization}
                      onChange={(selectedItems) =>
                        onChange("owner_organization", selectedItems)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Group>
                  <Form.Label>Users</Form.Label>
                  <Select
                    name="organization"
                    options={userOptions}
                    isMulti
                    isClearable={false}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={values.organization}
                    onChange={(selectedItems) =>
                      onChange("organization", selectedItems)
                    }
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group>
                  <Form.Label>Category</Form.Label>
                  <Select
                    name="category"
                    options={categoryOptions}
                    isMulti
                    isClearable={false}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={values.category}
                    onChange={(selectedItems) =>
                      onChange("category", selectedItems)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.keyCode === 13) {
                        const substring = e.target.value;
                        const index = categoryOptions.findIndex((element) => {
                          if (element.label.includes(substring)) {
                            return true;
                          }
                        });
                        if (index === -1) {
                          // CALL API HERE
                          const formData = new FormData();
                          formData.append("label_name", LABEL_CATEGORY)
                          formData.append("type", TYPE_ROOM)
                          formData.append("options", substring)

                          dispatch(addFilterOptionApi(formData))
                        }
                      }
                    }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group>
                  <Form.Label>Style</Form.Label>
                  <Select
                    name="style"
                    options={styleOptions}
                    isMulti
                    isClearable={false}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={values.style}
                    onChange={(selectedItems) =>
                      onChange("style", selectedItems)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.keyCode === 13) {
                        const substring = e.target.value;
                        const index = styleOptions.findIndex((element) => {
                          if (element.label.includes(substring)) {
                            return true;
                          }
                        });
                        if (index === -1) {
                          // CALL API HERE
                          const formData = new FormData();
                          formData.append("label_name", LABEL_STYLE)
                          formData.append("type", TYPE_ROOM)
                          formData.append("options", substring)

                          dispatch(addFilterOptionApi(formData))
                        }
                      }
                    }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group>
                  <Form.Label>Search Tags</Form.Label>
                  <Select
                    name="search_tags"
                    options={searchTagsOptions}
                    isMulti
                    isClearable={false}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={values.search_tags}
                    onChange={(selectedItems) =>
                      onChange("search_tags", selectedItems)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.keyCode === 13) {
                        const substring = e.target.value;
                        const index = searchTagsOptions.findIndex((element) => {
                          if (element.label.includes(substring)) {
                            return true;
                          }
                        });
                        if (index === -1) {
                          console.log(
                            `adding --${substring}-- to database.....`
                          );
                          // CALL API HERE
                        }
                      }
                    }}
                  />
                </Form.Group>
              </Row>
              <Stack gap={2}>
                <LFButton
                  btnProps={{ variant: "orange", onClick: onSubmit }}
                  text="Upload"
                />
                {errors &&
                  errors.map((err, index) => (
                    <span className="error" key={index}>
                      <IoWarningOutline /> {err.message}
                    </span>
                  ))}
              </Stack>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
