export const RefreshIcon2 = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8767 7.2302C14.8676 7.21752 14.8579 7.20517 14.8474 7.19321L12.9121 4.96997C12.7313 4.76238 12.4099 4.74229 12.2023 4.9231C11.9947 5.1039 11.9746 5.42533 12.1554 5.63292L13.0923 6.70917C12.6403 6.62037 12.1778 6.57692 11.7065 6.57692C8.22436 6.57692 5.39844 9.41624 5.39844 12.8917C5.39844 16.3672 8.22436 19.1998 11.7065 19.1998C15.1887 19.1998 18.0146 16.3672 18.0213 12.8917C18.0213 11.6328 17.653 10.4207 16.9566 9.38275C16.8026 9.14838 16.4878 9.08811 16.2601 9.24213C16.0258 9.39615 15.9655 9.71088 16.1195 9.93856C16.7021 10.8091 17.0101 11.827 17.0101 12.885C17.0101 15.8114 14.6329 18.1886 11.7065 18.1886C8.78016 18.1886 6.40291 15.8114 6.40291 12.885C6.40291 9.95865 8.78016 7.5814 11.7065 7.5814C12.2393 7.5814 12.7621 7.65152 13.2619 7.7831L11.9142 8.76688C11.6932 8.93429 11.6464 9.24902 11.8071 9.47001C11.9075 9.60394 12.0615 9.6776 12.2156 9.6776C12.3227 9.6776 12.4231 9.65081 12.5102 9.58385L14.7119 7.97014C14.744 7.95206 14.7738 7.92976 14.8006 7.90303C14.8799 7.83393 14.9318 7.74429 14.9557 7.64831C14.9924 7.50819 14.9693 7.35466 14.8767 7.2302Z"
        fill="currentColor"
      />
    </svg>
  );
};
