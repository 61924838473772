import { useState, useEffect } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { Table } from 'react-bootstrap';
import { Image } from './';
import { icons } from '../assets';
import { Checkbox } from './';
const { UserIcon } = icons;

export default function SelectableTableList(props) {
  const [allSelected, setAllSelected] = useState(false)
  const { options } = props;

  useEffect(() => {
    const anyFalse = _.some(options, (opt) => !opt.selected)
    setAllSelected(!anyFalse)
  }, [options])

  function handleAllSelect() {
    let newValue = !allSelected;
    setAllSelected(newValue)
    if (_.isFunction(props.onSelectAll)) {
      props.onSelectAll(newValue)
    }
  }
  
  function handleSelect(item) {
    let change = item.selected ? false : true;
    props.onSelect(item, change)
  }
  return (
    <div className="selectableTable">
      <Table striped={props.striped} hover={props.hover} className={props.tableClassName || ''}>
        {props.showHeaderLabel && (
          <thead className="selectableThead">
            <tr
              onClick={props.onHeaderClick}
              className={cx(props.className || 'text-primary', 'selectableRow')}>
              <th role="button" className="px-3 py-4 fw-normal">{props.showHeaderLabel}</th>
            </tr>
          </thead>
        )}
        {props.onSelectAll && (
          <thead className="selectableTheadAll">
            <tr
              onClick={handleAllSelect}
              className={cx(props.className || 'text-light', 'selectableRow')}>
              <th className="p-3">
                <Checkbox
                  name={'Select All'}
                  onChange={() => {}}
                  label={`Select All (${options.length} ${props.item}s)`}
                  selected={allSelected}
                />
              </th>
            </tr>
          </thead>
        )}
        {options && options.length > 0 ? (
          <>
            <tbody className="selectableTableBody">
              {_.map(options, (item, index) => {
                return (
                  <tr
                    key={`selectableList${props.id}${index}}`}
                    className={`selectableRow ${props.textColor || ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelect(item)
                    }}
                  >
                    <td className="p-3">
                      <Checkbox
                        name={`sideFilter-${item.value}`}
                        onChange={() => {}}
                        selected={item.selected || false}
                        label={item.label}
                        className={`form-check-input selectableCheckbox float-${
                          props.checkboxLeft ? 'start' : 'end'
                        } `}
                        icon={
                          props.showIcon ? (
                            <div className="selectIconDiv d-inline p-0">
                              {item.thumbnail ? <Image src={item.thumbnail} /> : <UserIcon />}
                            </div>
                          ) : null
                        }
                      />
                      {props.showDimensions && (<div className="text-secondary float-end">{item.resX} x {item.resY}</div>)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : null}
      </Table>
    </div>
  );
}
