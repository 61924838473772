import React, { useEffect, useState } from 'react';
import { Checkbox as PrettyCheckbox } from 'pretty-checkbox-react';
import { BsCheck } from 'react-icons/bs';
import _ from 'lodash';

export default function Checkbox({
  successValue = true,
  label = '',
  name = null,
  className = '',
  defaultData = {},
  onChange = null,
  selected,
  preview,
  disabled,
}) {
  const [checked, setChecked] = useState(selected || false);

  useEffect(() => {
    if (defaultData?.[name] !== undefined) {
      setChecked(defaultData[name]);
    }
  }, [defaultData]);

  useEffect(() => {
    if (_.isBoolean(selected)) {
      setChecked(selected);
    }
  }, [selected]);

  const handleChange = () => {
    let check = !checked;
    setChecked(check);
    if (_.isFunction(onChange)) {
      onChange({ name, value: check && successValue });
    }
  };

  return (
    <div onClick={handleChange} className="align-items-center" disabled={disabled}>
      <PrettyCheckbox
        shape="curve"
        onChange={handleChange}
        checked={(e) => e.preventPropagation()}
        name={name}
        state={checked}
        setState={setChecked}
        className={`styled-checkbox me-2 ${className} ${checked && 'checked'}`}
        variant="fill"
        icon={checked ? <BsCheck size="1.5em" /> : null}
        disabled={disabled}
      />
      {preview && <img src={preview} alt={label} />}
      <label onClick={handleChange} className={`pt-2 d-inline-block`} disabled={disabled}>
        {label}
      </label>
    </div>
  );
}

export const StatelessCheckbox = ({ label, name, className, onChange, checked, preview }) => {
  return (
    <div className="align-items-center">
      <PrettyCheckbox
        shape="curve"
        onChange={onChange}
        checked={checked}
        name={name}
        className={`styled-checkbox me-2 ${className} ${checked && 'checked'}`}
        variant="fill"
        icon={checked ? <BsCheck size="1.5em" /> : null}
      />
      {preview && <img src={preview} alt={label} />}
      {label && <label className="pt-2 d-inline-block">{label}</label>}
    </div>
  );
};
