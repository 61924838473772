import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDataListActions } from "./useDataListActions";
import { sidebarSelector } from "../redux/slicers/admin/sidebarSlicer";
import { filterSelector } from "../redux/slicers/filters/filterSlicer";
import { FILTER } from "../components/admin/curator/CuratorFilter/CuratorFilter";
import { useUserContext } from "../contexts/UserContext";

export const useDataList = ({
  selector,
  resetAction,
  getAction,
  itemDeleteText,
  itemsDeleteText,
  requestParams,
  disableFilters,
}) => {
  const dispatch = useDispatch();

  const { data, perPage, page, list, loading, hasData, filterValue, dataLoadedOnce, filter } = useSelector(selector);
  const selectedMembers = useSelector((state) => filterSelector(state).members);
  const { filterValues } = useSelector(sidebarSelector);
  const {
    selected,
    sortBy,
    filterBy,
    search,
    hasSelected,
    deleteDisabled,
    allSelected,

    onDelete,
    toggleAll,
    toggleItem,
    onSortChange,
    onFilterByChange,
    onSearchChagne,
  } = useDataListActions({
    items: list,
    itemDeleteText,
    itemsDeleteText,
  });
  const hasMore = data.current_page_number < data.total_pages;
  const { currentUser } = useUserContext();
  const organisationId = currentUser.organization; // TODO: implement it when user context is ready

  const _filterValue = filterValue || filterValues;

  const hasFilters = Boolean(filter?.filteroptions?.length);
  const searchActive = search && !_filterValue.length && hasFilters;

  const fetchData = (page) => {
    const filterParams = filterToParams(_filterValue, { organisationId, userId: currentUser.id });

    dispatch(
      getAction({
        page,
        per_page: perPage,
        search,
        member: selectedMembers,
        sort: sortBy,
        project_type: filterBy,
        ...(disableFilters ? {} :  filterParams),
        ...requestParams
      })
    );

    // if (!searchActive) {
    //   dispatch(
    //     getAction({
    //       page,
    //       perPage,
    //       search,
    //       member: selectedMembers,
    //       sort: sortBy,
    //       project_type: filterBy,
    //       ...filterParams,
    //     })
    //   );
    //   return;
    // }


    // // search requests

    // filter.filteroptions.forEach(option => {
    //   const searchConfig = {
    //    optionId: option.id,
    //    labelId: option.filter_label
    //   }

    //   dispatch(
    //     getAction({
    //       page,
    //       perPage,
    //       search,
    //       member: selectedMembers,
    //       sort: sortBy,
    //       project_type: filterBy,
    //       searchConfig,
    //       ...filterParams,
    //     })
    //   );

    // })

    
  };

  useEffect(() => {
    dispatch(resetAction());
    fetchData(1)
  }, [_filterValue, sortBy, filterBy, search, selectedMembers, requestParams, getAction]);

  const fetchMoreData = () => {
    fetchData(page + 1);
  };

  const fetchFirstPage = () => {
    dispatch(resetAction());
    fetchData(1)
  }

  return {
    data,
    list,
    loading,
    fetchMoreData,
    hasMore,
    total: data.count,
    hasData,
    dataLoadedOnce,

    selected,
    onDelete,
    toggleAll,
    toggleItem,
    hasSelected,
    deleteDisabled,
    allSelected,
    onSortChange,
    onFilterByChange,
    onSearchChagne,
    fetchFirstPage,
  };
};

export const useSimpleDataList = ({
  selector,
  resetAction,
  getAction,
  itemDeleteText,
  itemsDeleteText,
  requestParams,
  filterSelector,
  skip,
}) => {
  const dispatch = useDispatch();

  const { data, perPage, page, list, loading, hasData, dataLoadedOnce, refetchCounter } = useSelector(selector);
  const {
    selected,
    sortBy,
    filterBy,
    search,
    hasSelected,
    deleteDisabled,
    allSelected,

    onDelete,
    toggleAll,
    toggleItem,
    onSortChange,
    onSearchChagne,
    onFilterByChange,
  } = useDataListActions({
    items: list,
    itemDeleteText,
    itemsDeleteText,
  });
  const hasMore = data.current_page_number < data.total_pages;

  const fetchData = (page) => {
    dispatch(
      getAction({ page, perPage, search, sort: sortBy, project_type: filterBy, ...requestParams })
    );
  };

  useEffect(() => {
    if (skip) return;
    
    dispatch(resetAction());
    fetchData(1);
  }, [sortBy, filterBy, search, requestParams, skip]);

  const fetchMoreData = () => {
    fetchData(page + 1);
  };

  useEffect(() => {
    if (refetchCounter > 0) {
      // at each counter change we refresh the whole list
      dispatch(resetAction());
      fetchData(1);
    }
  }, [refetchCounter])

  return {
    data,
    list,
    loading,
    fetchMoreData,
    hasMore,
    total: data.count,
    hasData,
    dataLoadedOnce,

    selected,
    onDelete,
    toggleAll,
    toggleItem,
    hasSelected,
    deleteDisabled,
    allSelected,
    onSortChange,
    onFilterByChange,
    onSearchChagne,
  };
};

export const filterToParams = (filterValues, { organisationId, userId }) => {
  const filterParams = {};

  filterValues.forEach((value) => {
    if (value === "favourite") {
      filterParams.is_favourite = true;
      return;
    }
    

    if (value.startsWith("option_")) {
      const [, optionId, labelId] = value.split("_");
      const optionKey = `fl_${optionId}`;

      if (!filterParams[optionKey]) {
        filterParams[optionKey] = labelId;
        return;
      }

      filterParams[optionKey] += `,${labelId}`;
      return;
    }

    if (value.startsWith("brand_")) {
      const [, brandId] = value.split("_");

      if (!filterParams.brands) {
        filterParams.brands = brandId;
        return;
      }

      filterParams.brands += `,${brandId}`;
      return;
    }

    if (value === FILTER.MY_3D_MODELS) {
      filterParams.brands = filterParams.brands ? `${filterParams.brands},${organisationId}` : organisationId;
    }

    if(value === FILTER.MY_TEXTURES) {
      filterParams.is_map = false;
      filterParams.created_by = userId
      return;
    }

    if(value === FILTER.ALL_TEXTURES) {
      filterParams.is_map = false;
      return;
    }

    if(value === FILTER.MY_UPLOADS) {
      filterParams.created_by = userId
      filterParams.is_map = true;
      return;
    }

    if(value === FILTER.ALL_UPLOADS) {
      filterParams.is_map = true;
      return;
    }
  });

  return filterParams;
};
