import { curatorActions } from "../redux/slicers/admin/curatorSlicer"
import { selectPopupOpen, shortcutsModuleActions } from "../redux/slicers/admin/shortcutsModuleSlicer";
import { getAppState } from "../redux/store";
import { dispatcher } from "./projectHelper"

export const isShortcutsOpen = () => selectPopupOpen(getAppState());

export const toggleSidebarSection = (sectionName) => {
  dispatcher(curatorActions.toggleSidebarSection(sectionName));

  if (sectionName && isShortcutsOpen()) {
    dispatcher(shortcutsModuleActions.setPopupOpen(false))
  }
}