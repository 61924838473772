/* eslint-disable no-debugger */
import { dispatcher } from '../../../../helpers/projectHelper';
import { setGlobalFov } from '../../../../redux/slicers/camera/cameraSettings';
import { UndoRedoLight } from './UndoRedoLight';
import { UndoRedoObjectProperties } from './UndoRedoObjectProperties';
import { UndoRedoSafeCameraResolution } from './UndoRedoSafeCameraResolution';

const ACTION = {
  SELECT_OBJECT: 'SELECT_OBJECT',
  DELETE_OBJECT: 'DELETE_OBJECT',

  CHANGE_FOV: 'CHANGE_FOV',
};

export class UndoRedo {
  constructor() {
    this.history = new LinkedList();
    this.currentItem = 'tail';
    this.lastAction = null;
  }

  setUnityContext = (unityContext) => {
    this.unityContext = unityContext;
  };

  setUnityLight = (unityLight) => {
    this.unityLight = unityLight;
  };

  setUnityRedux = (unityRedux) => {
    this.unityRedux = unityRedux
  }

  undo = () => {
    this.history;
    if (!this.currentItem) return;
    if (this.currentItem === 'head') return;

    const item = this.currentItem === 'tail' ? this.history.tail : this.currentItem;

    if (!item) {
      // this is case when history is empty
      return;
    }

    item.value.undo();
    this.currentItem = item.prev || 'head';
    this.lastAction = 'undo';
  };

  redo = () => {
    if (!this.currentItem) return;
    if (this.currentItem === 'tail') return;

    const item = this.currentItem === 'head' ? this.history.head : this.currentItem;
    item.value.redo();
    this.currentItem = item.next || 'tail';
    this.lastAction = 'redo';
  };

  getCurrentNode = () => {
    if (this.currentItem === 'head') return this.history.head;
    if (this.currentItem === 'tail') return this.history.tail;

    if (this.lastAction === 'undo') {
      return this.currentItem.next;
    }

    if (this.lastAction === 'redo') {
      return this.currentItem.prev;
    }

    throw new Error('Unable to get current history node');
  };

  createAction =
    (func) =>
    (...args) => {
      const newHistoryValue = func(...args);
      const currentNode = this.getCurrentNode();
      this.history.pushValue(newHistoryValue, currentNode);
      this.currentItem = 'tail';
      this.lastAction = null;
    };

  changeFOV = this.createAction(({ fov, prevFov }) => {
    const historyValue = {
      undo: () => {
        dispatcher(setGlobalFov(prevFov));
        this.unityContext.handleCameraFov(prevFov);
      },
      redo: () => {
        dispatcher(setGlobalFov(fov));
        this.unityContext.handleCameraFov(fov);
      },
    };
    return historyValue;
  });

  init = () => {
    this.light = new UndoRedoLight({ undoRedoInstance: this, unityLight: this.unityLight })
    this.safeCameraResolution = new UndoRedoSafeCameraResolution({ undoRedoInstance: this })
    this.objectProperties = new UndoRedoObjectProperties({ undoRedoInstance: this, unityObjectProperties: this.unityRedux })
  }
}

class Node {
  constructor(value, prev, next = null) {
    this.value = value;
    this.prev = prev;
    this.next = next;
  }
}

class LinkedList {
  // this linked list will work only for undo/redo, do not use it in different places

  constructor() {
    this.head = null;
    this.tail = null;
  }

  pushValue = (value, afterNode) => {
    const prev = afterNode || this.tail;
    const newNode = new Node(value, prev);

    if (prev) {
      prev.next = newNode;
    }

    if (this.head === null) {
      this.head = newNode;
    }

    this.tail = newNode;
  };
}

export const undoRedo = new UndoRedo();

window.undoRedo = undoRedo;
