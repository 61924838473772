import cx from 'classnames';
import { useDispatch } from 'react-redux';
import {
  setMetadataStatus,
  toggleEditMetadata,
} from '../../../../redux/slicers/admin/curatorMetadataSlicer';
import { useUnityContext } from '../../../container/unityContainer';
import BinIcon from '../../../icons/BinIcon';
import { MetadataForm } from './MetadataForm';
import styles from './MetadataItem.module.scss';

export const MetadataItem = ({ metadataItem, onDelete, onClick, onUpdate, disabled }) => {
  const dispatch = useDispatch();
  const { metadataModule } = useUnityContext();
  const { title, edit, sequenceNo, status } = metadataItem;

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete();
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    dispatch(toggleEditMetadata(metadataItem));
    // onEdit()
  };

  if (edit) {
    return (
      <MetadataForm
        onCancel={() => dispatch(toggleEditMetadata(metadataItem))}
        onSubmit={onUpdate}
        metadataItem={metadataItem}
      />
    );
  }

  const handleStatusChange = () => {
    const newStatus = !status;
    dispatch(setMetadataStatus({ title, status: newStatus }));
    metadataModule.UpdateMetadataStatusReceiver(title, newStatus);
  };

  return (
    <div
      className={cx('accordion-cnt d-flex', disabled && 'accordion-cnt--disabled', styles.root)}
      onClick={onClick}>
      <div className={cx('form-check form-switch', styles.switch)}>
        <input
          className="form-check-input m-0"
          type="checkbox"
          checked={status}
          onChange={handleStatusChange}
        />
      </div>
      <span>
        {sequenceNo}. {title}
      </span>
      <div className="accordion-icon d-flex ">
        <div className="edit-icon p-0">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleEdit}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.7179 24.4031C8.13957 24.7911 8.7258 25 9.35316 25C9.45864 25 9.55988 24.9918 9.66503 24.9833C9.72393 24.9785 9.78405 24.9737 9.84683 24.9701L13.1585 24.423C13.7859 24.3235 14.5881 23.9057 15.02 23.458L23.4637 14.8131C25.5721 12.6543 25.5104 10.5254 23.2786 8.48603C21.0468 6.44666 18.8459 6.50635 16.7376 8.6651L8.29384 17.31C7.8516 17.7478 7.47107 18.5536 7.39908 19.1604L7.01855 22.3836C6.92598 23.1795 7.1831 23.9156 7.7179 24.4031ZM17.8586 9.66986C18.5579 8.9536 19.247 8.47609 20.0389 8.47609C20.6766 8.47609 21.3862 8.79443 22.209 9.56043C24.0705 11.2516 23.6077 12.4653 22.3324 13.7785L21.1935 14.9445C18.9793 14.5808 17.2144 12.9507 16.7287 10.828L17.8586 9.66986ZM15.5001 12.0873L9.41487 18.3248C9.20918 18.5337 8.97263 19.0311 8.93149 19.3196L8.55096 22.5428C8.50982 22.8711 8.5921 23.1397 8.77722 23.3088C8.96235 23.4779 9.24003 23.5376 9.57943 23.4878L12.8911 22.9407C13.1893 22.891 13.683 22.6323 13.8887 22.4234L19.9759 16.1911C17.9138 15.5605 16.2605 14.0463 15.5001 12.0873Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="delete-icon p-0 ms-3">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleDelete}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.3234 8.44492L12.1325 9.58145C11.2954 9.6269 10.46 9.69089 9.62641 9.7755L7.80464 9.9541C7.43851 9.98982 7.1706 10.3113 7.20632 10.6864C7.24204 11.0614 7.57246 11.3294 7.94753 11.2936L8.60441 11.2292L9.70679 22.4834C9.80503 23.8765 9.93005 25.6 13.0467 25.6H18.7799C21.8965 25.6 22.0215 23.8765 22.1198 22.4834L23.0473 11.2134C23.3245 11.2391 23.6017 11.2659 23.879 11.2936H23.9505C24.2898 11.2936 24.5845 11.0347 24.6113 10.6864C24.647 10.3202 24.3791 9.98982 24.013 9.9541C22.5741 9.81288 21.136 9.6971 19.7001 9.61696L19.6998 9.61478L19.5033 8.45385L19.5024 8.44861C19.3685 7.62704 19.1684 6.3999 17.0832 6.3999H14.7435C12.6642 6.3999 12.4666 7.58592 12.3237 8.44308L12.3234 8.44492ZM21.7098 11.0982C17.7696 10.7855 13.8393 10.7162 9.93983 11.098L11.0463 22.3941L11.0465 22.3965C11.1446 23.7521 11.1814 24.2605 13.0467 24.2605H18.7799C20.6552 24.2605 20.6909 23.7515 20.7802 22.3941L21.7098 11.0982ZM18.1906 8.67711L18.3386 9.55195C16.7226 9.48821 15.1099 9.4738 13.5027 9.52333L13.654 8.66817L13.6554 8.66014C13.7881 7.87254 13.809 7.74836 14.7524 7.74836H17.0921C18.0387 7.74836 18.0655 7.90018 18.1906 8.67711ZM17.3958 20.6882H14.4221C14.0559 20.6882 13.7523 20.3846 13.7523 20.0185C13.7523 19.6523 14.0559 19.3487 14.4221 19.3487H17.3958C17.762 19.3487 18.0656 19.6523 18.0656 20.0185C18.0656 20.3846 17.762 20.6882 17.3958 20.6882ZM13.6808 17.1162H18.1459C18.512 17.1162 18.8157 16.8125 18.8157 16.4464C18.8157 16.0802 18.512 15.7766 18.1459 15.7766H13.6808C13.3147 15.7766 13.011 16.0802 13.011 16.4464C13.011 16.8125 13.3147 17.1162 13.6808 17.1162Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
