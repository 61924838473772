import { createSlice } from "@reduxjs/toolkit";
import { AXIS, CURSOR_MODE, DIMENSION, MOVE_MODE, ROTATE_MODE, TRANSFORM_SPACE_MODE, UNITY_2D_VIEW_MODE } from "../../../config/constant/unityConstants";
import { PROJECT_TYPE } from "../../../constants/projectConstants";
import { CURATOR_MENU_SECTION } from "./curatorMenuPreferencesSlicer";

export const SAVE_PROJECT_MODE = {
  SAVE: 'SAVE',
  NEW: 'NEW',
  CLOSE_CURATOR: 'CLOSE_CURATOR',
  SAVE_ON_LOCAL: 'SAVE_ON_LOCAL',
}

export const SETTINGS_SECTION = {
  RESOLUTION_PRESETS: 'RESOLUTION_PRESETS',
  CAMERA_SETTINGS: 'CAMERA_SETTINGS',
  GRAPHIC_QUALITY: 'GRAPHIC_QUALITY',
  DEFAULT_MEASURE_UNITS: 'DEFAULT_MEASURE_UNITS',
  METADATA_SETTINGS: 'METADATA_SETTINGS',
}

const initialState = {
  curatorInitialized: process.env.REACT_APP_CURATOR_INITIALIZED === 'true',
  sidebarSection: null, //CURATOR_MENU_SECTION.CAMERAS,
  sidebarExpanded: false,
  preventProjectInitialization: false,
  filterOpen: null, // 'product', 'texture', 'material',
  layoutData: {},
  roomData: {},
  propsData: [],
  unityData: {},
  saveProjectDialogOpen: false,
  projectData: [],
  unityTrigger: false,
  projectJson: {},
  individualProjectData: {},
  unityLoaded: false,
  curatorLoader: false,
  saveProjectMode: SAVE_PROJECT_MODE.SAVE,
  unity2DViewMode: UNITY_2D_VIEW_MODE.TOP,
  dimension: DIMENSION._3D,
  cursorMode: CURSOR_MODE.MOVE,
  selectedAxis: AXIS.ALL,
  saveProjectLoading: false,
  transformSpaceMode: TRANSFORM_SPACE_MODE.LOCAL,
  // rotateMode: null, // ROTATE_MODE.GLOBAL
  settingData: {},
  oldSettingData: {},
  defaultDetaData: [],
  textureVersionSelectorDialogOpen: false,
  importProjectTexturesConflictList: [],
  settingsSection: null,
}

const curator = createSlice({
  name: "curator",
  initialState,
  reducers: {
    resetState: (state) => {
      return {
        ...initialState,
        settingData: state.settingData,
      };
    },
    toggleSidebarSection: (state, { payload }) => {
      state.sidebarSection = state.sidebarSection === payload ? null : payload;
    },
    setSidebarSection: (state, { payload }) => {
      state.sidebarSection = payload;
    },
    setLayoutData: (state, { payload }) => {
      state.layoutData = payload;
    },
    setRoomData: (state, { payload }) => {
      state.roomData = payload;
    },
    setPropsData: (state, { payload }) => {
      state.propsData = payload;
    },
    setUnityData: (state, { payload }) => {
      state.unityData = payload;
    },
    setSaveProjectDialogOpen: (state, { payload }) => {
      state.saveProjectDialogOpen = payload;
    },
    setProjectData: (state, { payload }) => {
      state.projectData = payload;
    },
    setUnityTrigger: (state, { payload }) => {
      state.setUnityTrigger = payload;
    },
    setProjectJson: (state, { payload }) => {
      state.projectJson = payload;
    },
    setIndividualProjectData: (state, { payload }) => {
      state.individualProjectData = payload;
    },
    setUnityLoaded: (state, { payload }) => {
      state.unityLoaded = payload;
    },
    setCuratorLoader: (state, { payload }) => {
      state.curatorLoader = payload;
    },
    getCuratorSettingData: (state, { payload }) => {
      state.oldSettingData = payload;
      state.settingData = payload;
    },
    setCuratorSettingData: (state, { payload }) => {
      state.settingData = payload;
    },
    setSelectedResolutionPreset: (state, { payload }) => {
      state.settingData.json_data.resolutionPresent = [...state.settingData.json_data.resolutionPresent].map(resolutionPreset => {
        return { ...resolutionPreset, isSelected: resolutionPreset.id === payload };
      })
    },
    updateResolutionPreset: (state, { payload }) => {
      state.settingData.json_data.resolutionPresent = state.settingData.json_data.resolutionPresent.map(preset => {
        return preset.id === payload.id ? { ...preset, ...payload } : preset;
      })
    },
    editCuratorSettingData: (state, { payload }) => {
      state.oldSettingData = { ...state.oldSettingData, ...payload };
      state.settingData = { ...state.settingData, ...payload };
    },
    setDefaultMetaData: (state, { payload }) => {
      state.defaultDetaData = payload;
    },
    toggleDimension: (state, { payload }) => {
      state.dimension = state.dimension === DIMENSION._2D ? DIMENSION._3D : DIMENSION._2D;
    },
    setUnity2DViewMode: (state, { payload }) => {
      state.unity2DViewMode = payload;
    },
    syncLightFromUnity: (state, { payload }) => {
      const exist = Boolean(
        state.unityData.roomLightData.find((l) => l.id === payload.id)
      );
      if (exist) {
        state.unityData.roomLightData = state.unityData.roomLightData.map(
          (l) => {
            return l.id === payload.id ? payload : l;
          }
        );
      } else {
        state.unityData.roomLightData.unshift(payload);
      }
    },
    updateLight: (state, { payload }) => {
      state.unityData.roomLightData = state.unityData.roomLightData.map(
        (light) => {
          return light.id === payload.id ? payload : light;
        }
      );
    },
    deleteLight: (state, { payload }) => {
      state.unityData.roomLightData = state.unityData.roomLightData.filter(
        (light) => light.id !== payload
      );
    },
    toggleSidebarExpanded: (state, { payload }) => {
      state.sidebarExpanded =
        typeof payload === "boolean" ? payload : !state.sidebarExpanded;
    },
    setSidebarExpanded: (state, { payload }) => {
      state.sidebarExpanded = payload;
    },
    toggleFilterOpen: (state, { payload }) => {
      if (!payload) {
        state.filterOpen = null
      }
      state.filterOpen = state.filterOpen === payload ? null : payload;
    },
    setCursorMode: (state, { payload }) => {
      state.cursorMode = payload
    },
    setTransformSpaceMode: (state, { payload }) => {
      state.transformSpaceMode = payload;
    },
    toggleSelectedAxis: (state, { payload }) => {
      state.selectedAxis = state.selectedAxis === payload ? AXIS.ALL : payload;
    },
    resetCursorModeConfig: (state, { payload }) => {
      state.selectedAxis = AXIS.ALL
      state.transformSpaceMode = TRANSFORM_SPACE_MODE.LOCAL;
    },
    setSaveProjectMode: (state, { payload }) => {
      state.saveProjectMode = payload;
    },
    setSaveProjectLoading: (state, { payload }) => {
      state.saveProjectLoading = payload
    },
    setCuratorInitialized: (state, { payload }) => {
      state.curatorInitialized = payload
    },
    setRoomLightData: (state, { payload }) => {
      state.unityData.roomLightData = payload
    },
    setTextureVersionSelectorDialogOpen: (state, { payload }) => {
      state.textureVersionSelectorDialogOpen = payload
    },
    setImportProjectTexturesConflictList: (state, { payload }) => {
      state.importProjectTexturesConflictList = payload
    },
    setSettingsSection: (state, { payload }) => {
      state.settingsSection = payload;
    },
    toggleSettingsSection: (state, { payload }) => {
      state.settingsSection = payload && payload === state.settingsSection ? null : payload;
    },
    saveAsNew: (state, { payload }) => {
      const { project } = payload;
      state.preventProjectInitialization = true;
      state.individualProjectData = project;
      state.roomData = initialState.roomData;
    },
    unblockProjectInitialization: (state) => {
      state.preventProjectInitialization = false;
    },
  },
});

export const {
  toggleSidebarSection,
  setCuratorLoader,
  setIndividualProjectData,
  setProjectJson,
  setUnityTrigger,
  setProjectData,
  setLayoutData,
  setRoomData,
  setPropsData,
  setUnityData,
  setCuratorSettingData,
  editCuratorSettingData,
  getCuratorSettingData,
  setDefaultMetaData,
  syncLightFromUnity,
  updateLight,
  toggleSidebarExpanded,
  setSidebarExpanded,
  toggleFilterOpen,
  toggleDimension,
  setUnity2DViewMode,
  setCursorMode,
  setTransformSpaceMode,
  setSelectedAxis,
  toggleSelectedAxis,
  setSaveProjectDialogOpen,
  setSaveProjectMode,
  setSaveProjectLoading,
  resetCursorModeConfig,
  setUnityLoaded,
  setCuratorInitialized,
  setSidebarSection,
  setSelectedResolutionPreset,
  updateResolutionPreset,
  setRoomLightData,
  setTextureVersionSelectorDialogOpen,
  setImportProjectTexturesConflictList,
  setSettingsSection,
  toggleSettingsSection,
  saveAsNew,
  unblockProjectInitialization,
} = curator.actions;
export const curatorActions = curator.actions;

export const curatorSelector = (state) => state.curator;
export const curatorReducer = curator.reducer;
export default curatorReducer;


export const selectIsLoading = (state) => !curatorSelector(state).curatorLoader
export const selectUnityLoaded = (state) => curatorSelector(state).unityLoaded
export const selectUnityData = (state) => curatorSelector(state).unityData
export const selectDimension = (state) => curatorSelector(state).dimension
export const selectIs2DMode = (state) => curatorSelector(state).dimension === DIMENSION._2D
export const selectIs3DMode = (state) => curatorSelector(state).dimension === DIMENSION._3D
export const selectCursorMode = (state) => curatorSelector(state).cursorMode
export const selectSelectedAxis = (state) => curatorSelector(state).selectedAxis
export const selectTransformSpaceMode = (state) => curatorSelector(state).transformSpaceMode
export const selectUnity2DViewMode = (state) => curatorSelector(state).unity2DViewMode
export const selectSaveProjectDialogOpen = (state) => curatorSelector(state).saveProjectDialogOpen
export const selectSaveProjectMode = (state) => curatorSelector(state).saveProjectMode
export const selectSaveProjectLoading = (state) => curatorSelector(state).saveProjectLoading
export const selectSettingData = (state) => curatorSelector(state).settingData
export const selectOldSettingData = (state) => curatorSelector(state).oldSettingData
export const selectResolutionPresets = (state) => curatorSelector(state).settingData?.json_data?.resolutionPresent
export const selectSidebarExpanded = (state) => state.curator.sidebarExpanded;

export const selectCuratorInitialized = (state) => curatorSelector(state).curatorInitialized;
export const curatorActionsDisabled = (state) => !curatorSelector(state).curatorInitialized
export const selecteIsSidebarOpen = (state) => Boolean(state.curator.sidebarSection);
export const selectSidebarSection = (state) => state.curator.sidebarSection
export const selecteIsFilterOpen= (state) => Boolean(state.curator.filterOpen);
export const selectRoomId = (state) => state.curator.roomData?.id;
export const selectMeasureUnit = (state) => state.curator.settingData?.json_data?.measureUnit?.find(m => m.isSelected);
export const selectIsSiloRoom = (state) => (
  state.curator.roomData?.room_type === PROJECT_TYPE.SILO || state.curator.individualProjectData?.project_type === PROJECT_TYPE.SILO
)
export const selectPreventProjectInitialization = (state) => state.curator.preventProjectInitialization;

export const selectIndividualProjectData = (state) => state.curator.individualProjectData;
export const selectTextureVersionSelectorDialogOpen = (state) => state.curator.textureVersionSelectorDialogOpen;
export const selectImportProjectTexturesConflictList = (state) => state.curator.importProjectTexturesConflictList
export const selectSettingsSection = (state) => state.curator.settingsSection
export const selectProjectJsonUrl = (state) => selectIndividualProjectData(state)?.data?.json_file;
export const selectLightById = (state, id) => {
  return state.curator.unityData.roomLightData.find(light => light.id === id)
}

export const selectAxisAvailability = (state) => {
  const is3D = selectIs3DMode(state);
  const unity2DViewMode = selectUnity2DViewMode(state)
  const isTopView = unity2DViewMode === UNITY_2D_VIEW_MODE.TOP;
  const isBackView = unity2DViewMode === UNITY_2D_VIEW_MODE.BACK;
  const isFrontView = unity2DViewMode === UNITY_2D_VIEW_MODE.FRONT;
  const isLeftView = unity2DViewMode === UNITY_2D_VIEW_MODE.LEFT;
  const isRightView = unity2DViewMode === UNITY_2D_VIEW_MODE.RIGHT;

  const isAxisVisible = (axis) => {
    if (is3D) return true;

    switch (axis) {
      case AXIS.X:
        return isTopView || isBackView || isFrontView;
      case AXIS.Y:
        return !isTopView;
      case AXIS.Z:
        return isTopView || isLeftView || isRightView;
      default:
        throw new Error('Not supported axis: ' + axis);
    }
  };

  return {
    [AXIS.X]: isAxisVisible(AXIS.X),
    [AXIS.Y]: isAxisVisible(AXIS.Y),
    [AXIS.Z]: isAxisVisible(AXIS.Z),
  }
}