/* eslint-disable no-debugger */
import cx from 'classnames';
import { useEffect, useRef } from 'react';
import { errorToast } from '../../../../helpers/toastHelper';
import { useResolutionHandlers } from '../components/Setting/useResolutionPresets';
import { Lock } from '../Lock/Lock';
import { NumberInput } from '../NumberInput';

export const LockedInput = ({
  id,
  value: [
    valueLeft,
    valueRight,
  ],
  lock,
  onLockToggle,
  className,
  inputClassName,
  onChange,
  onFocus,
  min,
  max,
  minMaxWarning,
  valueFormatter,
  normalizeOnBlur,
  onlyIntegers,
  withArrows,
  step,
}) => {



  const { handleResolutionBlur, handleResolutionChange } = useResolutionHandlers({
    keyX: valueLeft.name,
    keyY: valueRight.name,
    defaultX: valueLeft.defaultValue,
    defaultY: valueRight.defaultValue,
    min,
    max,
    data: {
      [valueLeft.name]: valueLeft.value,
      [valueRight.name]: valueRight.value,
      lock,
    },
    valueFormatter,
    updateCallback: (data, details) => {
      if (!data) return;

      const val1 = data[valueLeft.name]
      const val2 = data[valueRight.name]

      if (minMaxWarning) {
        const val1Incorrect = val1 < min || val1 > max;
        const val2Incorrect = val2 < min || val2 > max;
        if (val1Incorrect || val2Incorrect) {
          errorToast(minMaxWarning, { toastId: `LOCKED_INPUT_${id}`});
        }
      }

      return onChange(data, details);
    },
  });

  // TODO: use own state
  // send only values in onchange event
  // send only values in blur even
  // use hook for handling locked data
  // maybe accept value like => value={{ left: { value: 2, name: 'width' }, right: { value: 5, name: 'height' }}}
  // add possibility for custom validation ->> for example negative, min/max values
  // // add possibility to change values to min/max on blur (this one should be optional) 
  // use this input everywhere where we have locked property

  const handleLockToggle = () => {
    if (lock) {
      // disabling lock should always work 
      onLockToggle();
      return;
    }

    // for enabling lock we need to check if there are some values provided
    const value1Valid = typeof valueLeft.value === 'number' && !Number.isNaN(valueLeft.value); 
    const value2Valid = typeof valueRight.value === 'number' && !Number.isNaN(valueRight.value); 

    if (!value1Valid || !value2Valid) {
      errorToast('Please provide values before locking fields');
      return;
    }

    onLockToggle();
  }

  const handleBlur = (e) => {
    if (!normalizeOnBlur) return;
    handleResolutionBlur(e)
  }

  const maxLength = `${max}`.length;
  
  return (
    <div className={cx('row', className)}>
      <div className="resolution-input col-5 mb-2">
        <label className="form-label">{valueLeft.label}</label>
        <NumberInput
          type="text"
          className={cx('form-control', inputClassName)}
          name={valueLeft.name}
          value={valueLeft.value}
          onBlur={handleBlur}
          onChange={handleResolutionChange}
          onlyIntegers={onlyIntegers}
          withArrows={withArrows}
          min={min}
          max={max}
          step={step}
          onFocus={onFocus}
        />
      </div>
      <div className="lock-icon col-2 pt-3 d-flex align-items-center justify-content-center">
        <Lock active={lock} onClick={handleLockToggle} />
      </div>
      <div className="resolution-input col-5 mb-2">
        <label className="form-label">{valueRight.label}</label>
        <NumberInput
          type="text"
          className={cx('form-control', inputClassName)}
          name={valueRight.name}
          value={valueRight.value}
          onBlur={handleBlur}
          onChange={handleResolutionChange}
          onlyIntegers={onlyIntegers}
          withArrows={withArrows}
          min={min}
          max={max}
          step={step}
          onFocus={onFocus}
        />
      </div>
    </div>
  );
};

export const UndoRedoLockedInput = ({ undoRedoAction, undoRedoData, onChange, onFocus, ...rest }) => {
  const valueRef = useRef(null);

  const handleFocus = (e) => {
    valueRef.current = rest.value;
    onFocus?.(e);
  };

  const handleChange = (data, details) => {

    if (details.type === 'blur') {
      const [valueLeft, valueRight] = valueRef.current

      undoRedoAction?.({
        prevValue: {
          [valueLeft.name]: valueLeft.value,
          [valueRight.name]: valueRight.value,
        },
        nextValue: data,
        data: undoRedoData,
      });
    }

    onChange?.(data, details);
  };

  return <LockedInput 
    {...rest}
    onChange={handleChange}
    onFocus={handleFocus}
  />
}