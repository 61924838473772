import { useDispatch, useSelector } from "react-redux"
import { addToFavouriteRenderPreview, deleteRenderPreviewAPI } from "../api/commonApi"
import { errorToast } from "../helpers/toastHelper"
import { paginatedRenderPreviewsSelectors } from "../redux/slicers/admin/paginatedRenderPreviewsSlicer"
import { setRenderPreviewExpand } from "../redux/slicers/renders-previews/rendersPreviewsSlicer"
import { useInfoDialogaActions } from "./useInfoDialogaActions"

export const useRenderPreviewsActions = ({ item }) => {
  const dispatch = useDispatch()
  const { openInfoDialog } = useInfoDialogaActions()
  const favouriteLoading = useSelector((state) =>
    paginatedRenderPreviewsSelectors.selectIsFavouriteLoading(state, item.id)
  );

  const onImageClick = () => {
    if (item.images.length !== 0) {
      dispatch(setRenderPreviewExpand(item));
    } else {
      errorToast("This Preview does have a camera image.");
    }
  }

  const onDelete = (id) => {
    openInfoDialog({
      title: "Are you sure you want to archive this render?",
      description: `You can view the archived renders by clicking on the left pane of this screen`,
      cancelButtonText: "No",
      buttonText: "Yes",
      onButtonClick: () => {
        dispatch(deleteRenderPreviewAPI(id));
      },
    });

    
  }

  const onFavouriteToggle = (clickedItem) => (event) => {
    const tempItem = clickedItem || item;

    let formValue = new FormData();
    formValue.append("is_favourite", !tempItem.is_favourite);
    dispatch(addToFavouriteRenderPreview(formValue, tempItem.id));
  }
  
  return {
    onImageClick,
    onDelete,
    onFavouriteToggle,
    favouriteLoading,
  }
}