
import React, { useState, useEffect, useContext } from 'react';
import {useParams, Link, useNavigate} from "react-router-dom";
import {Col, Container, Image, Navbar, Row, Card, Form, Carousel } from "react-bootstrap";
import jwt_decode from 'jwt-decode';
import {CUSTOMIZE_EXPERIENCE, SIGN_IN_ROUTE, ADMIN_DASHBOARD} from '../config/constant/routePathConstant';
import { successToast } from '../helpers/toastHelper';
import { LOGO, BULB, INVITE_ICON } from '../constants/assetConstants';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { UnionExclamation } from '../constants/assetConstants';
import { setTitle, emailIsValid, passwordIsValid, setLocalStorage } from '../helpers/jsHelper';
import { apiGetCarouselImages, apiGetRegisterInvite, apiGetVerifyEmailLink, apiPostRegisterInvite } from '../helpers/api';
import { UserContext } from '../contexts';
import { toast } from "react-toastify";
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  MEMBER_ID,
  CURRENT_USER,
  EMAIL_VERIFIED,
  LATEST_TNC_ACCEPTED,
} from '../constants/projectConstants';
import { PublicCarousel } from '../common';




export default function Invitation() {
    const { token } = useParams();    // token comes from email link 
    const jwtData = jwt_decode(token);

    const userCtx = useContext(UserContext);
    userCtx.setOnInviteRoute(true);


    const {new_user, invite_sent_to, organization_name, invite_sent_by_name} = jwtData;
    console.error("new user: ", new_user);
    const navigate = useNavigate();


 

    const [carouselList, setCarouselList] = useState([]);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(invite_sent_to);
    const [passwordMessage, setPasswordMessage] = useState('');
    const [confirmationPasswordMessage, setConfirmationPasswordMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const [firstNameMessage, setFirstNameMessage] = useState('');
    const [lastNameMessage, setLastNameMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    useEffect(() => {
      
      if(new_user === false) {
    
        const payload = {}

        // add user to org and redirect to login page
        // figma says jump to home, but api response does not send back info such as access token, refresh token, is_email_verified etc from this api call, so must have user sign in first
        apiPostRegisterInvite(payload, token, true).then((res) => {
          if (res) {
            let { data, message, status } = res;
            const { is_email_verified, access, refresh, info } = data;
            successToast(message);
            if (status === true) {
              navigate(SIGN_IN_ROUTE);
            }
          }
        });

      }
    }, [])
    useEffect(() => {
      apiGetCarouselImages({ name: 'furnish' }, null, 'Unable to get carousel images.').then((res) =>
        setCarouselList(res?.results)
      );
    }, []);


    const handleFirstName = (e) => {
      e.preventDefault();
      const alphabets = /^[a-zA-Z]*$/;
      setFirstName(e.target.value);
      if (!e.target.value || !e.target.value.trim()) {
        setFirstNameMessage('First Name is Required');
      } else {
        if (!alphabets.test(e.target.value)) {
          setFirstNameMessage('Only Letters are Allowed');
        } else {
          setFirstNameMessage('');
        }
      }
    };
  
    const handleLastName = (e) => {
      e.preventDefault();
      const alphabets = /^[a-zA-Z]*$/;
      setLastName(e.target.value);
      if (!e.target.value || !e.target.value.trim()) {
        setLastNameMessage('Last Name is Required');
      } else {
        if (!alphabets.test(e.target.value)) {
          setLastNameMessage('Only Letters are Allowed');
        } else {
          setLastNameMessage('');
        }
      }
    };
  
    const handlePassword = (e) => {
      e.preventDefault();
      setPassword(e.target.value);
      if (!e.target.value) {
        setPasswordMessage('Password Field is Required');
      } else if (!passwordIsValid(e.target.value)) {
        setPasswordMessage(
          'Must contain at least 8 characters and one number, one lowercase, one uppercase character.'
        );
      } else if (e.target.value.length < 8) {
        setPasswordMessage('Password must contain at least 8 characters');
      } else {
        setPasswordMessage('');
      }
    };
    const handlePasswordConfirmation = (e) => {
      e.preventDefault();
      setPasswordConfirmation(e.target.value);
      if (password !== e.target.value) {
        setConfirmationPasswordMessage('Password and confirmation password do not match');
      } else if (!e.target.value || !e.target.value.trim()) {
        setConfirmationPasswordMessage('Confirm Password Field is Required');
      } else {
        setConfirmationPasswordMessage('');
      }
    };
  
    const handleShowPassword = (e) => {
      e.preventDefault();
      setShowPassword(!showPassword);
    };
  
    const handleShowConfirmationPassword = (e) => {
      e.preventDefault();
      setShowConfirmationPassword(!showConfirmationPassword);
    };

    const validate = () => {
      let firstnameError = '';
      let lastnameError = '';
      let emailError = '';
      let passwordError = '';
      let confpasswordError = '';
  
      if (!firstName || !firstName.trim()) {
        firstnameError = 'First Name is Required';
      }
  
      if (!lastName || !lastName.trim()) {
        lastnameError = 'Last Name is Required';
      }
  
      if (!email || !email.trim()) {
        emailError = 'Email Field is Invalid ';
      }
  
      if (!password || !password.trim()) {
        passwordError = 'Password Field is Required';
      } else if (!passwordIsValid(password)) {
        passwordError =
          'Must contain at least 8 characters and one number, one lowercase, one uppercase character.';
      } else if (password.length < 8) {
        passwordError = 'Must contain at least 8 characters.';
      }
  
      if (password !== passwordConfirmation) {
        confpasswordError = 'Password and Confirmation Password do not match';
      } else if (!passwordConfirmation || !passwordConfirmation.trim()) {
        confpasswordError = 'Confirm Password field is required';
      }
  
      if (emailError || passwordError || firstnameError || lastnameError || confpasswordError) {
        setEmailMessage(emailError);
        setPasswordMessage(passwordError);
        setFirstNameMessage(firstnameError);
        setLastNameMessage(lastnameError);
        setConfirmationPasswordMessage(confpasswordError);
        return false;
      }
      return true;
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (validate()) {
        const payload = {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          password: password.trim(),
        };

        console.log("PAYLOAD: ", payload);


        apiPostRegisterInvite(payload, token, true).then((res) => {
          if (res) {
            let { data, message, status } = res;
            const { is_email_verified, access, refresh, info } = data;
            successToast(message);
            if (status === true) {

              //userCtx.setOnInvitePath(true);
  
              // userCtx.setCurrentUser(info);
              // setLocalStorage(CURRENT_USER, info);
              // setLocalStorage(MEMBER_ID, info?.id);
              // userCtx.setToken(access);
              // setLocalStorage(AUTH_TOKEN, token);
              // userCtx.setRefreshToken(refresh);
              // setLocalStorage(REFRESH_TOKEN, refresh);
              // userCtx.setEmailVerified(is_email_verified);
              // setLocalStorage(EMAIL_VERIFIED, is_email_verified);
              
        
            
              let on_invite_route = true;
              userCtx.onLogin(info, access, refresh, is_email_verified, false, on_invite_route)

              // giving time for context to change
              setTimeout(() => {
                navigate(CUSTOMIZE_EXPERIENCE)
              }, 500)
            
            }
          }
        });
      }




    };


    return (
    <div className="vh-100 vw-100">
    <Navbar expand="lg" className="bg-body">
      <Container>
        <div className="w-100 d-flex align-items-center justify-content-between mx-auto py-2">
          <Navbar.Brand>
            <Image src={LOGO} responsive="true" />
          </Navbar.Brand>
          <div className="justify-content-end">
            <Navbar.Text>
              <div className="d-flex align-items-center">
                <img src={BULB}/>
                <span className="me-2 fw-normal">Already a member?</span>
                <Link to={SIGN_IN_ROUTE} className="text-primary fw-bold link-primary">
                  Sign in
                </Link>
              </div>
            </Navbar.Text>
          </div>
        </div>
      </Container>
    </Navbar>

    <div>
        <Container>
          <Row className="d-flex justify-content-center align-items-stretch pt-5">
            <Col xs={7}>
              <PublicCarousel data={carouselList}/>
            </Col>
            <Col xs={5}>
              <Card className="py-5 px-4 h-100 border-0">
                <div className="text-center">
                  <h1 className="m-bottom-1 appH1">Invitation  <img src={INVITE_ICON}/></h1>
                  <h5 className="m-bottom-2 py-0 fw-light appH3"><b>{invite_sent_by_name}</b> has invited you to join <b>{organization_name}</b></h5>
                </div>
                <Form action="" className="mt-3" onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group className="col ps-5">
                      {firstNameMessage ? (
                        <Form.Label className="text-danger">
                          <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {firstNameMessage}
                            </small>
                        </Form.Label>
                      ) : (
                        <Form.Label>First Name</Form.Label>
                      )}
                      <Form.Control
                        type="text"
                        id="form2Example19"
                        name="first_name"
                        className={firstNameMessage ? 'input-error' : ''}
                        placeholder="Type your first name"
                        onChange={(e) => setFirstName(e.target.value)}
                        onBlur={handleFirstName}
                        onFocus={() => setFirstNameMessage('')}
                      />
                    </Form.Group>
                    <Form.Group className="col pe-5">
                      {lastNameMessage ? (
                        <Form.Label className="text-danger">
                          <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {lastNameMessage}
                            </small>
                        </Form.Label>
                      ) : (
                        <Form.Label>Last Name</Form.Label>
                      )}
                      <Form.Control
                        type="text"
                        id="form2Example20"
                        name="last_name"
                        className={lastNameMessage ? 'input-error' : ''}
                        placeholder="Type your last name"
                        onChange={(e) => setLastName(e.target.value)}
                        onBlur={handleLastName}
                        onFocus={() => setLastNameMessage('')}
                      />
                    </Form.Group>
                    <Form.Group className="px-5 py-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        id="form2Example21"
                        name="email"
                        className={emailMessage ? 'input-error input-box-disabled' : 'input-box-disabled'}
                        placeholder="Type your email"
                        value={email}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="px-5 py-3">
                      <div className="password-field">
                        {passwordMessage ? (
                          <Form.Label className="text-danger">
                            <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {passwordMessage}
                            </small>
                          </Form.Label>
                        ) : (
                          <Form.Label>Password</Form.Label>
                        )}
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          id="form2Example28"
                          name="password"
                          className={passwordMessage ? 'input-error' : ''}
                          placeholder="Type your password"
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={handlePassword}
                          onFocus={() => setPasswordMessage('')}
                        />
                        {showPassword ? (
                          <RiEyeLine className="eye-icon" onClick={handleShowPassword} />
                        ) : (
                          <RiEyeOffLine className="eye-icon" onClick={handleShowPassword} />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="px-5 py-3">
                      <div className="password-field">
                        {confirmationPasswordMessage ? (
                          <Form.Label className="text-danger">
                            <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {confirmationPasswordMessage}
                            </small>
                          </Form.Label>
                        ) : (
                          <Form.Label>Confirm Password</Form.Label>
                        )}
                        <Form.Control
                          type={showConfirmationPassword ? 'text' : 'password'}
                          id="form2Example29"
                          className={confirmationPasswordMessage ? 'input-error' : ''}
                          placeholder="Repeat your password"
                          onChange={(e) => setPasswordConfirmation(e.target.value)}
                          onBlur={handlePasswordConfirmation}
                          onFocus={() => setConfirmationPasswordMessage('')}
                        />
                        {showConfirmationPassword ? (
                          <RiEyeLine className="eye-icon" onClick={handleShowConfirmationPassword} />
                        ) : (
                          <RiEyeOffLine
                            className="eye-icon"
                            onClick={handleShowConfirmationPassword}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <div className="d-grid px-5 py-3">
                      <button className="btn btn-warning" type="submit">
                        <span className="btn-font">Register</span>
                      </button>
                    </div>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    );
}