import { createSlice } from "@reduxjs/toolkit";

const rooms = createSlice({
  name: "rooms",
  initialState: {
    roomsData: {},
    data: {},
    list: [],
    hasData: false,
    page: 1,
    perPage: 20,
    loading: false,
  },
  reducers: {
    setRooms: (state, { payload }) => {
      state.roomsData = payload;
    },
    fetchRoomListStart: (state) => {
      state.loading = true;
    },
    fetchRoomListSuccess: (state, { payload }) => {
      state.page = payload.page;
      state.data = payload.data;
      state.list = [...state.list, ...payload.data.results]; // TODO: leave only unique items in the array
      state.loading = false;
      state.hasData = payload.data.count > 0;
    },
    fetchRoomListFailed: (state) => {
      state.loading = false;
    },
    resetRoomList: (state) => {
      state.list = [];
      state.page = 1;
    },
    addRoomSuccess: (state, { payload }) => {
      state.list = [payload, ...state.list];
    },
    updateRoom: (state, { payload }) => {
      state.list = state.list.map((room) => {
        if (room.id === payload.id) {
          return {
            ...room,
            ...payload,
          };
        }

        return room;
      });
    },
  },
});

export const roomActions = rooms.actions;
export const { setRooms } = rooms.actions;
export const roomsSelector = (state) => state.rooms;
export const roomsReducer = rooms.reducer;
export default roomsReducer;
