import {
  AUTH_TOKEN,
} from '../constants/projectConstants';
import {
  getLocalStorage,
} from '../helpers/jsHelper';
import {
  ADMIN_DASHBOARD,
} from '../constants/routePathConstants';

export default function NotFound() {
  
  // since the "/sign-in" route can get here if authorized and also via Sign in Page will redirect
  if (getLocalStorage(AUTH_TOKEN)) {
    window.location.pathname = ADMIN_DASHBOARD
  }
  return (
    <div className="p-3">
      <h1>Not Found</h1>
    </div>
  );
}
