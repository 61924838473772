import {createSlice} from "@reduxjs/toolkit";

const sample = createSlice({
    name: 'sample',
    initialState: {
        count : 0,
        userName :'',
        userPassword:'',
    },
    reducers: {
        setCount: (state, {payload}) => {
            state.count += payload
        },
       setUserName: (state, {payload}) => {
           state.userName = payload
       }, 
       setUserPassword: (state, {payload}) => {
           state.userPassword = payload
       }, 
    }
})

export const {setCount, setUserName, setUserPassword} = sample.actions;
export const sampleSelector = state => state.sample;
export const sampleReducer = sample.reducer;
export default sampleReducer