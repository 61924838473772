import cx from 'classnames';
import React  from 'react';
import ReactTimeAgo from 'react-time-ago';
import styles from '../assets/scss/modules/_previewUI.module.scss';
import ThumbnailCardUI from './ThumbnailCardUI';

export default function PreviewUISidebar({
  theme,
  title,
  userName,
  updatedAt,
  cards,
  isFullscreen,
  className,
  classNameFullscreen,
}) {
  return (
    <div
      className={cx(
        styles.sidebar,
        className,
        isFullscreen && styles.fullscreen,
        isFullscreen && classNameFullscreen,
        styles[theme]
      )}>
      {/* Top block */}
      <div className={styles.sidebarTop}>
        <img
          src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/avatar-2-1583234102.jpg"
          alt="Avatar"
          className={styles.avatar}
        />

        <div className={styles.textContainer}>
          <div className={styles.textContainerTitle}>{title}</div>
          <div className={styles.editedInfo}>
            {userName} ·
            <span className={styles.timeAgo}>{updatedAt && <ReactTimeAgo date={updatedAt} />}</span>
          </div>
        </div>
      </div>
      {/* End Top Block */}

      <div className={styles.cardListContainer}>
        {cards?.map((card) => {
          return <ThumbnailCardUI key={card.id} {...card} fullWidth />;
        })}
      </div>
    </div>
  );
}
