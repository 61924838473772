import React, { useEffect, useState } from 'react';
import { Image, Modal, ModalTitle } from 'react-bootstrap';
import { CLOSE_ICON } from '../../constants/assetConstants';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import styles from './InfoDialogUI.module.scss';

// export type TProps = {
//   icon: 'info' | 'success' | 'delete' | 'confirmation' | 'error';
//   title: React.ReactNode;
//   buttonText: React.ReactNode;
//   cancelButtonText?: React.ReactNode;
//   contentContainerProps?: any;
//   closeable?: boolean;
//   onClose?: () => void;
//   onButtonClick: () => Promise<any> | void;
//   onCancelClick?: () => void;
// } & Omit<Omit<DialogProps, 'title'>, 'onClose'>;

const InfoDialogUI = ({
  title,
  content,
  buttonText,
  cancelButtonText,
  onButtonClick,
  onCancelClick,
  onClose,
  icon,
  description,
  maxWidth = 'xs',
  contentContainerProps,
  closeable,
  children,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    setLoading(true);
    // TODO: work on this logic
    await onButtonClick?.();
    try {
      setLoading(false);
    } catch (e) {
      // it will fail only when component was unmounted, ignore this error
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [rest.open]);

  const showCancelButton = Boolean(onCancelClick);
  const showOkButton = Boolean(buttonText && onButtonClick);

  return (
    <Modal
      onHide={onClose}
      centered
      {...rest}
      dialogClassName={styles.modal}
      contentClassName={styles.content}>
      <Modal.Body className={styles.body}>
        <Loader position="absolute" loading={loading} backdrop />
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{title}</h2>
          {description && <div className={styles.description}>{description}</div>}
          {onClose && (
            <button className={styles.close} onClick={onClose}>
              <Image src={CLOSE_ICON} />
            </button>
          )}
        </div>

        {content}
        {children}

        {(showCancelButton || showOkButton) && (
          <div className="d-flex justify-content-between gap-3 mt-4">
            {showCancelButton && (
              <Button color="secondary" onClick={onCancelClick} className="w-100" size="xlg">
                {cancelButtonText}
              </Button>
            )}
            {showOkButton && (
              <Button
                variant="primary"
                color="warning"
                size="xlg"
                onClick={handleButtonClick}
                className="w-100"
                // loading={loading}
              >
                {buttonText}
              </Button>
            )}
          </div>
        )}
              
      </Modal.Body>
    </Modal>
  );
};

export default InfoDialogUI;
