import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { BACK } from '../constants/assetConstants';
import { Container, Navbar, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { apiGetTNCLatest, apiGetAcceptTermsAndConditions } from '../helpers/api';
import { successToast } from '../helpers/toastHelper';
import { UserContext } from '../contexts';

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      <div className="row mt-3">
        <h1 className="my-3" style={{ textAlign: 'left', paddingLeft: 0 }}>
          Privacy policy
        </h1>
        <p className="px-0">{data}</p>
      </div>
    </>
  );
};

const TermsAndConditions = ({ data }) => {
  return (
    <div className="row">
      <h1 className="my-3" style={{ textAlign: 'left', paddingLeft: 0 }}>
        Terms and conditions
      </h1>
      <p className="px-0">{data}</p>
    </div>
  );
};

export default function TermsAndPrivacyPolicy({
  isOnTermsAndConditions,
  setIsOnTermsAndConditions,
  setTermsAndPolicyPageVisible,
  isModalVersion,
}) {
  const userCtx = useContext(UserContext);

  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [modalAccepted, setModalAccepted] = useState(false);

  useEffect(() => {
    apiGetTNCLatest(null).then((res) => {
      if (res) {
        let { version_number, data } = res;
        setPrivacyPolicy(data.privacy);
        setTermsAndConditions(data['t&c']);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (!modalAccepted) return;
    apiGetAcceptTermsAndConditions().then((res) => {
      if (res) {
        let { status, data, message } = res;
        if (status === true) {
          successToast(message);
          let { latest_tnc_accepted } = data;
          userCtx.setLatestTNCAccepted(latest_tnc_accepted);
        }
      }
    });
  };

  return (
    <>
      {isModalVersion ? (
        <div className="user-popup">
          <Modal show={true} backdrop="static" centered size="xl" scrollable={true}>
            <ModalBody>
              <div
                className="container-fluid panels-toggle mt-3"
                style={{ minHeight: '70vh', maxHeight: '70vh' }}>
                <div className="row mt-5">
                  <div
                    className={`col panel left-panel background-gray ${
                      isOnTermsAndConditions && 'selected-panel'
                    }`}
                    onClick={() => setIsOnTermsAndConditions(true)}>
                    <span>Terms and conditions</span>
                  </div>
                  <div
                    className={`col panel right-panel background-gray ${
                      !isOnTermsAndConditions && 'selected-panel'
                    }`}
                    onClick={() => setIsOnTermsAndConditions(false)}>
                    <span>Privacy policy</span>
                  </div>
                </div>
                <div className="text-align-left mt-3">
                  {isOnTermsAndConditions ? (
                    <TermsAndConditions data={termsAndConditions} />
                  ) : (
                    <PrivacyPolicy data={privacyPolicy} />
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <nav
                className="tac-modal-bottom navbar navbar-expand-lg navbar-bg fixed-top app-header"
                style={{ width: '100%' }}>
                <div className="container-fluid">
                  <div className="form-check mr-1" style={{ marginRight: '1rem' }}>
                    <input
                      className={`form-check-input`}
                      type="checkbox"
                      name="tandc"
                      id="tandc1"
                      onChange={(e) => setModalAccepted(e.target.checked)}
                    />
                    <label className="form-check-label toc-text">
                      I accept
                      <Link
                        to="#"
                        onClick={() => {
                          setIsOnTermsAndConditions(false);
                        }}>
                        {' '}
                        Privacy Policy{' '}
                      </Link>
                      and{' '}
                      <Link
                        to="#"
                        onClick={() => {
                          setIsOnTermsAndConditions(true);
                        }}>
                        Term of use
                      </Link>
                    </label>
                  </div>
                  <button
                    className={`${modalAccepted ? 'invite-btn' : 'light-btn'}`}
                    onClick={handleSubmit}>
                    <span className="btn-font">Submit</span>
                  </button>
                </div>
              </nav>
            </ModalFooter>
          </Modal>
        </div>
      ) : (
        <>
          <Navbar expand="lg" className="page-nav">
            <Container fluid>
              <Navbar>
                <Navbar.Text>
                  <a
                    onClick={() => setTermsAndPolicyPageVisible(false)}
                    className="back-button lf-link cursorPointer">
                    <img src={BACK} alt="" className="me-2" />
                    Back
                  </a>
                </Navbar.Text>
              </Navbar>
            </Container>
          </Navbar>
          <div className="panels-toggle mt-3">
            <div className="row mt-5">
              <div
                className={`col panel left-panel cursorPointer ${
                  isOnTermsAndConditions && 'selected-panel'
                }`}
                onClick={() => setIsOnTermsAndConditions(true)}>
                <span>Terms and conditions</span>
              </div>
              <div
                className={`col panel right-panel cursorPointer ${
                  !isOnTermsAndConditions && 'selected-panel'
                }`}
                onClick={() => setIsOnTermsAndConditions(false)}>
                <span>Privacy policy</span>
              </div>
            </div>
            <div className="text-align-left mt-3">
              {isOnTermsAndConditions ? (
                <TermsAndConditions data={termsAndConditions} />
              ) : (
                <PrivacyPolicy data={privacyPolicy} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
