import React, { useEffect } from "react";
import cx from "classnames";
import moment from "moment";
import useToggle from "react-use/lib/useToggle";
import { Card } from "react-bootstrap";
import styles from './ThumbnailCardUI.module.scss';

import {
  ACTION_DOTS_ICON,
  COLLECTION_ICON,
  DELETE_ICON,
  DOWNLOAD_ICON,
  EDIT_ICON,
  FAVOURITE_ICON,
  NOT_FAVOURITE_ICON,
  SHARE_ICON,
} from "../config/constant/assetConstant";
import Image from "../components/Image/Image";
import CircularProgress from "../components/CircularProgress/CircularProgress";

const ThumbnailCardUI = ({
  id,
  name,
  updatedAt,
  isFavourite,
  favouriteLoading,
  thumbnail,
  images,
  selected,
  selectable,
  fullWidth,
  secondLine,

  as = "div",
  className,

  onDownload,
  onEdit,
  onShare,
  onCollection,
  onDelete,
  onSelect,
  onFavouriteChange,
  onCardImageClick,

  onLinkClick,
  to,
  target,
  size,

  ...rest
}) => {
  const [actionsOpen, toggleActionsOpen] = useToggle(false);

  const preventPropagation = (e) => {
    e.stopPropagation();
  }

  const hasActions = Boolean(
    onDownload || onEdit || onShare || onCollection || onDelete
  );

  // TODO: handle case with no image
  const thumbnailSrc = thumbnail || images?.[0]?.thumbnail || "";
  const Component = as;

  return (
    <Card
      className={cx("thumbnail-card mb-0", fullWidth && styles.fullWidth, className, size === 'small' && styles.smallSize)}
      id={`thumbnail-card-${id}`}
      {...rest}
    >
      <Component to={to} onClick={onLinkClick} target={target}>
        <div className="thumbnail-img thumbnail-img4444444444">
          <div className={cx('thumbnail-action', selected && 'thumbnail-action--selected', isFavourite && 'thumbnail-action--favourite')}>
            <ul className="action-list">
              <li className="thumbnail-action-checkbox">
                {selectable && (
                  <>
                    <input
                      type="checkbox"
                      name="selectCard"
                      id={`selectCard-${id}`}
                      className="selectCard"
                      value={id}
                      onChange={onSelect}
                      checked={selected}
                      onClick={preventPropagation}
                    />
                    <label htmlFor={`selectCard-${id}`} onClick={preventPropagation}></label>
                  </>
                )}
              </li>
              {onFavouriteChange && (
                <li className={cx('thumbnail-action-favourite', favouriteLoading && styles.favouriteLoading)}>
                  <input
                    type="checkbox"
                    name="favouriteCard"
                    id={`favouriteCard-${id}`}
                    className="favouriteCard"
                    onChange={onFavouriteChange}
                    checked={isFavourite}
                    onClick={preventPropagation}
                  />
                  <label htmlFor={`favouriteCard-${id}`} onClick={preventPropagation}>
                    {!favouriteLoading && (
                      <img
                        src={isFavourite ? FAVOURITE_ICON : NOT_FAVOURITE_ICON}
                        width="13px"
                        alt="favourite"
                      />
                    )}
                    {favouriteLoading && <CircularProgress size={14} thickness={1} />}
                  </label>
                </li>
              )}
            </ul>
          </div>
          <Image
            onClick={onCardImageClick}
            src={thumbnailSrc}
            placeholderWhileLoading
            ratio={1.259}
          />
        </div>
      </Component>

      <Card.Body className="thumbnail-info">
        <Card.Title className="inner-info">
          <h2 className="thumbnail-title">{name}</h2>
          {updatedAt && (
            <h5 className="thumbnail-etime">
              {`Edited ${moment.utc(updatedAt).local().fromNow()}`}
            </h5>
          )}
          {secondLine}
        </Card.Title>
        {hasActions && (
          <div className="inner-action">
            <div className="card-action" onClick={() => toggleActionsOpen()}>
              <img src={ACTION_DOTS_ICON} alt="more" />
            </div>

            {actionsOpen && (
              <ul className="action-list ced-action">
                {onDownload && (
                  <li>
                    <div onClick={() => onDownload(id)}>
                      <img
                        className="img-fluid"
                        src={DOWNLOAD_ICON}
                        alt="delete"
                      />
                    </div>
                  </li>
                )}

                {onEdit && (
                  <li>
                    <div onClick={() => onEdit(id)}>
                      <img className="img-fluid" src={EDIT_ICON} alt="delete" />
                    </div>
                  </li>
                )}
                {onShare && (
                  <li>
                    <div onClick={() => onShare(id)}>
                      <img className="img-fluid" src={SHARE_ICON} alt="share" />
                    </div>
                  </li>
                )}
                {onCollection && (
                  <li>
                    <div onClick={() => onCollection(id)}>
                      <img
                        className="img-fluid"
                        src={COLLECTION_ICON}
                        alt="collection"
                      />
                    </div>
                  </li>
                )}
                {onDelete && (
                  <li>
                    <div onClick={() => onDelete(id)}>
                      <img
                        className="img-fluid"
                        src={DELETE_ICON}
                        alt="delete"
                      />
                    </div>
                  </li>
                )}
              </ul>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ThumbnailCardUI;



