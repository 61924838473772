import cx from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CURATOR_MENU_SECTION } from '../../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import {
  curatorSelector,
  selecteIsFilterOpen,
  selectSidebarExpanded,
  setSidebarExpanded,
  toggleSidebarExpanded,
  toggleSidebarSection,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { getAppState } from '../../../../redux/store';
import ArrowDownIcon from '../../../icons/ArrowDownIcon';
import styles from './CuratorSidebarLayout.module.scss';

export const CuratorSidebarLayout = ({ inside, className, fixed, ...rest }) => {
  const expanded = useSelector((state) => curatorSelector(state).sidebarExpanded);

  return (
    <div
      {...rest}
      className={cx(
        styles.root,
        className,
        expanded && styles.expanded,
        inside && styles.inside,
        fixed && styles.fixed,
      )}></div>
  );
};

CuratorSidebarLayout.Inner = (props) => {
  return (
    <div
      {...props}
      className={cx(styles.inner, props.className)}
      id={`CuratorSidebarLayoutScrollableContainer_${props.name}`}
    />
  );
};

CuratorSidebarLayout.FixedHeader = (props) => {
  return <div {...props} className={cx(styles.fixedHeader, props.className)}></div>;
};

CuratorSidebarLayout.Filter = function Filter(props) {
  const expanded = useSelector((state) => curatorSelector(state).sidebarExpanded);

  return (
    <div
      {...props}
      className={cx(styles.filter, props.className, expanded && styles.filterExpanded)}></div>
  );
};

CuratorSidebarLayout.Button = function Button({ location, ...rest }) {
  const dispatch = useDispatch();
  const filterOpen = useSelector(selecteIsFilterOpen);
  const sidebarActiveSection = useSelector((state) => curatorSelector(state).sidebarSection);

  if (filterOpen && location === 'sidebar') return null;

  const isProductsActive = sidebarActiveSection === CURATOR_MENU_SECTION.PRODUCTS;

  const handleButtonClick = () => {
    if (isProductsActive) return;
    dispatch(toggleSidebarSection(null));
  };

  const handleExpandClick = () => {
    dispatch(setSidebarExpanded(true));
  }

  const handleCollapseClick = () => {
    const sidebarExpanded = selectSidebarExpanded(getAppState());
    if (sidebarExpanded) {
      dispatch(setSidebarExpanded(false));
    } else {
      dispatch(toggleSidebarSection(null));
    }
  }

  return (
    <button
      type="button"
      {...rest}
      className={cx(styles.button, location === 'filter' && styles.butonFilterLocation)}
      onClick={handleButtonClick}>
      {isProductsActive && <ArrowDownIcon className={styles.imgExpand} onClick={handleExpandClick} />}
      <ArrowDownIcon className={cx(styles.imgCollapse, isProductsActive && styles.translated)} onClick={handleCollapseClick} />
    </button>
  );
};
