import { useRenderPreviewsActions } from "../hooks/useRenderPreviewsActions"
import ThumbnailCardUI from "./ThumbnailCardUI"

export const ThumbnailCardRenderPreviews = ({ data, size }) => {
  const { onImageClick, onDelete, onFavouriteToggle, favouriteLoading } = useRenderPreviewsActions({ item: data })
  
  return (
    <ThumbnailCardUI 
      key={data.id}
      id={data.id}
      // onClick={() => handleAddProps(data)}
      onCardImageClick={onImageClick}
      thumbnail={data?.thumbnail}
      images={data?.images}
      name={data.name}
      isFavourite={data.is_favourite}
      favouriteLoading={favouriteLoading}
      updatedAt={data.updated_at}
      onDelete={onDelete}
      onFavouriteChange={onFavouriteToggle(data)}
      size={size}
    />
  )
}





