export const CheckCircleIcon = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.6353 15.2843C10.4432 15.2843 10.2607 15.2074 10.1263 15.073L7.40812 12.3548C7.12958 12.0763 7.12958 11.6152 7.40812 11.3367C7.68666 11.0582 8.14769 11.0582 8.42623 11.3367L10.6353 13.5458L15.5722 8.60893C15.8507 8.33039 16.3118 8.33039 16.5903 8.60893C16.8689 8.88747 16.8689 9.3485 16.5903 9.62704L11.1444 15.073C11.0099 15.2074 10.8274 15.2843 10.6353 15.2843Z"
        fill="currentColor"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
};
