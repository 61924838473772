import cx from 'classnames';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useMedia } from 'react-use';
import { DimensionSwitcher } from './DimensionSwitcher';
import { Resolution } from './Resolution';
import styles from './CuratorHeaderActions.module.scss';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

export const CuratorHeaderActions = () => {
  const [open, setOpen] = useState(false);
  const isWide = useMedia('(min-width: 1150px)');

  const rootClassName = cx(styles.root, !isWide && styles.smallScreen, open && styles.open);

  return (
    <div className={rootClassName}>
      {!isWide && (
        <button onClick={() => setOpen(!open)} className={styles.arrowButton}>
          {open ? <RiArrowRightSLine /> : <RiArrowLeftSLine /> }
        </button>
      )}
      <Collapse in={isWide || open} dimension="width">
        <div className={styles.container}>
          <Resolution />
          <DimensionSwitcher />
        </div>
      </Collapse>
      <div className="divider"></div>
    </div>
  );
};
