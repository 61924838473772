import {
  MY_PRODUCTS_ICON,
  MY_PROJECTS_ICON,
  RENDERS_PREVIEWS_ICON,
  ROOMS_ICON,
  PROPS_ICON,
  TEXTURES_ICON,
  COLLECTION_ICON,
  MY_TEAM_ICON,
  SHARED_FILES_ICON,
  CALENDAR_ICON,
  COMMUNITY_ICON,
  SETTINGS_ICON,
  TRASH_ICON,
  PLAY_TO_LEARN_ICON,
  REQUESTS_ICON,
  MY_ACCOUNT_ICON,
  ADD_CREDITS_ICON,
  LOGOUT_ICON,
  QUIT_ICON,
  SUPPORT_ICON,
} from './assetConstants';
import {
  MY_PRODUCTS_ROUTE,
  MY_PROJECTS_ROUTE,
  RENDERS_PREVIEWS_ROUTE,
  TEMPLATES_ROUTE,
  PROPS_ROUTE,
  TEXTURES_ROUTE,
  COLLECTIONS_ROUTE,
  MY_TEAM_ROUTE,
  SHARED_FILES_ROUTE,
  CALENDAR_ROUTE,
  COMMUNITY_ROUTE,
  SETTINGS_ROUTE,
  TRASH_ROUTE,
  PLAY_TO_LEARN_ROUTE,
  REQUESTS_ROUTE,
  MY_ACCOUNT_ROUTE,
  ADD_CREDITS_ROUTE,
  LOGOUT_ROUTE,
  QUIT_ROUTE,
  SUPPORT_ROUTE,
} from '../constants/routePathConstants';

export const SIDEBAR_MENU = [
  {
    name: 'My team',
    icon: MY_TEAM_ICON,
    path: MY_TEAM_ROUTE,
  },
  {
    name: 'Shared files',
    icon: SHARED_FILES_ICON,
    path: SHARED_FILES_ROUTE,
  },
  {
    name: 'Calendar',
    icon: CALENDAR_ICON,
    path: CALENDAR_ROUTE,
  },
  {
    name: 'Community',
    icon: COMMUNITY_ICON,
    path: COMMUNITY_ROUTE,
  },
  {
    name: 'Settings',
    icon: SETTINGS_ICON,
    path: SETTINGS_ROUTE,
  },
  {
    name: 'Trash',
    icon: TRASH_ICON,
    path: TRASH_ROUTE,
  },
  {
    name: 'Play to learn',
    icon: PLAY_TO_LEARN_ICON,
    path: PLAY_TO_LEARN_ROUTE,
  },
];

export const SIDEBAR_MENU_2 = [
  {
    name: 'Support',
    icon: SUPPORT_ICON,
    path: SUPPORT_ROUTE,
  },
  {
    name: 'Requests',
    icon: REQUESTS_ICON,
    path: REQUESTS_ROUTE,
  },
];

export const MAIN_MENU = [
  {
    name: 'My Products',
    icon: MY_PRODUCTS_ICON,
    path: MY_PRODUCTS_ROUTE,
  },
  {
    name: 'My Projects',
    icon: MY_PROJECTS_ICON,
    path: MY_PROJECTS_ROUTE,
  },
  {
    name: 'Renders & Previews',
    icon: RENDERS_PREVIEWS_ICON,
    path: RENDERS_PREVIEWS_ROUTE,
  },
  {
    name: 'Templates',
    icon: ROOMS_ICON,
    path: TEMPLATES_ROUTE,
  },
  {
    name: 'Props',
    icon: PROPS_ICON,
    path: PROPS_ROUTE,
  },
  {
    name: 'Collections',
    icon: COLLECTION_ICON,
    path: COLLECTIONS_ROUTE,
  },
];

export const USER_MENU = [
  {
    name: 'My account',
    icon: MY_ACCOUNT_ICON,
    path: SETTINGS_ROUTE,
  },
  {
    name: 'Add credits',
    icon: ADD_CREDITS_ICON,
    path: ADD_CREDITS_ROUTE,
  },
  {
    name: 'Logout',
    icon: LOGOUT_ICON,
    path: LOGOUT_ROUTE,
  },
  {
    name: 'Quit',
    icon: QUIT_ICON,
    path: QUIT_ROUTE,
  },
];
