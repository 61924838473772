import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import { CommonGrid, TopContainer } from '../layouts';
import {
  BigButton,
  TopButtonArea,
  ThumbnailContainer,
  ModalButtonArea,
  DesignTemplates,
  InfiniteScroll,
  ActionFilters,
  Image,
  SelectFormGroup,
  GlobalFilter,
  Input,
  RightSidebar,
  MiniButton,
  Download,
  AddToCollections,
  SharePopup,
  DeactivatePopup,
} from '../common';
import constants from '../constants/constants';
import _ from 'lodash';
import { icons } from '../assets';
import { FilterContext, UserContext } from '../contexts';
import {
  getCommonMetadata,
  addValueLabelToList,
  constructQueryParams,
  changeFilter,
  setTitle
} from '../helpers/jsHelper';
import { apiGetCachedList, apiErrorHandler } from '../helpers/axiosHelper';
import {
  apiGetProjects,
  apiEditProject,
  parseBasePathname,
  apiDeleteProjects,
  apiGetDownloadProjectData,
} from '../helpers/api';
import { getScreenSettings } from '../helpers/configureHelper';
import { ReactLoader } from '../layouts/Loading';
import {
  EDIT_ICON,
  SHARE_ICON,
  DELETE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
} from '../constants/assetConstants';
import { getCuratorPath } from '../helpers/urlHelper';

const { PROJECT_TYPE_OPTIONS } = constants;
const { ProjectIcon } = icons;
let title = 'My projects';
export default function MyProjects() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = parseBasePathname(location);
  let cardType = _.replace(pathname, '/', '');
  let routeParams = useParams();
  let createNew;
  if (routeParams) {
    createNew = parseInt(routeParams.projectId, 10) < 0;
  }
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && currentUser.name;
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sortField, setSortField] = useState();
  const [globalTags, setGlobalTags] = useState([]);
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [total, setTotal] = useState(0);

  const pageSettings = getScreenSettings(cardType);
  const { sortOptions, filterOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter } =
    pageSettings;

  useEffect(() => {
    setTitle(title);
    refreshList();
  }, []);
  
  useEffect(() => {
    return () => {
      if (filterCtx) {
        filterCtx.cleanGlobalFiltersOnNavigate();
      }
    };
  }, []);

  useEffect(() => {
    if (!sortField && defaultSortOption) {
      setSortField(defaultSortOption);
    }
  }, [sortOptions, sortField]);

  useEffect(async () => {
    refreshList();
    let tagList = await apiGetCachedList('global_tags');
    if (tagList && tagList.length > 0) {
      setGlobalTags(tagList);
    }
  }, []);

  useEffect(() => {
    let params = constructQueryParams(
      sortField,
      filters,
      null,
      filterCtx?.globalFilters,
      filterCtx?.teamMembers
    );
    if (params !== query) {
      setQuery(params);
    }
  }, [sortField, filters, filterCtx?.globalFilters, filterCtx?.teamMembers]);

  useEffect(() => {
    refreshList();
  }, [query, filterCtx?.refListsReady]);

  useEffect(addDataToList, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    refreshList();
  }, [userCtx.currentUser.organization]);

  function refreshList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      apiGetProjects(query)
        .then((res) => {
          if (res) {
            // if (res.total_pages) {
            //   setTotalPages(res.total_pages);
            // }
            // if (res.current_page_number) {
            //   setPageNumber(res.current_page_number);
            // }
            if (res.results) {
              let newList = _.map(res.results, (r) => {
                r.project_type = _.find(PROJECT_TYPE_OPTIONS, (t) => t.value === r.project_type);
                r.filteroptions = addValueLabelToList(r.filteroptions);
                r.tags =
                  r.tags && r.tags.length > 0
                    ? _.map(r.tags, (t) => {
                        return {
                          label: t,
                          value: t,
                        };
                      })
                    : [];

                return r;
              });
              setTotal(res.count);
              setTotalPages(res.total_pages);
              setList(newList);
            }
            if (res.sidefilter) {
              filterCtx.handleFilterOptions(res.sidefilter);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  async function addDataToList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      apiGetProjects(query + `&page=${pageNumber}`)
        .then((res) => {
          if (res) {
            if (res.total_pages) {
              setTotalPages(res.total_pages);
            }
            if (res.current_page_number) {
              setPageNumber(res.current_page_number);
            }
            if (res.results) {
              let newList = _.map(res.results, (r) => {
                r.project_type = _.find(PROJECT_TYPE_OPTIONS, (t) => t.value === r.project_type);
                r.filteroptions = addValueLabelToList(r.filteroptions);
                r.tags =
                  r.tags && r.tags.length > 0
                    ? _.map(r.tags, (t) => {
                        return {
                          label: t,
                          value: t,
                        };
                      })
                    : [];

                return r;
              });
              setTotal(res.count);
              setTotalPages(res.total_pages);
              setList([...list, ...newList]);
            }
            if (res.sidefilter) {
              filterCtx.handleFilterOptions(res.sidefilter);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  function handleCardSelect(id, allOrNone = null) {
    let newList = _.map(list, (item) => {
      if (item.id === id) {
        item.selected = item.selected ? false : true;
      }
      if (_.isBoolean(allOrNone)) {
        item.selected = allOrNone;
      }
      return item;
    });
    setList(newList);
  }

  function handleSort({ value }) {
    setPageNumber(1);
    setSortField(value);
  }

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber((prev) => Number(prev) + 1);
    }
  }

  function handleFilter(field, selection) {
    setPageNumber(1);
    setFilters(changeFilter(filters, field, selection));
  }

  function onChange(field, value) {
    let item = { ...editItem };
    item[field] = value;
    setEditItem(item);
  }

  const onEdit = async (id) => {
    let itemToEdit = _.find(list, (p) => {
      return p.id === id;
    });
    setEditItem(itemToEdit);
  };

  const saveProject = async () => {
    if (!editItem) return;
    setSaving(true);
    const { name, project_type, tags, filteroptions } = editItem;
    let payload = {
      name: name,
      project_type: project_type?.value,
      // file: filejson,
      // is_favourite: editItem.is_favourite,
      // thumb: thumbnailfile
    };
    if (filteroptions && filteroptions.length > 0) {
      payload.filteroptions = _.chain(filteroptions)
        .map((o) => o.value)
        .join(',')
        .value();
    }
    if (tags && tags.length > 0) {
      payload.tags = _.chain(tags)
        .map((o) => o.label)
        .toArray()
        .value();
    }

    await new Promise((resolve, reject) => {
      apiEditProject(editItem.id, payload)
        .then((res) => {})
        .catch(apiErrorHandler)
        .finally(() => {
          setSaving(false);
          setEditItem(null);
          refreshList();
        });
    });
  };

  async function handleDownloadClick() {
    if (selectedList.length < 1) return;
    let query = selectedList.reduce((prev, curr, index) => {
      let q = prev + curr.id;
      if (selectedList.length > 1 && index < selectedList.length - 1) {
        q += ',';
      }
      return q;
    }, 'ids=');
    const res = await apiGetDownloadProjectData(query)
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'project.zip');
    document.body.appendChild(link);
    link.click();
  }

  const { hasData, anySelected, allSelected, selectedList } = getCommonMetadata(
    list,
    pageNumber,
    totalPages
  );

  const hasMore = pageNumber < totalPages;

  if (!filterCtx) return <ReactLoader />;
  return (
    <CommonGrid
      topContainer={
        <TopContainer
          title={<>{title} {total && <small>({total})</small>}</>}
          item={cardType}
          loading={loading || !filterCtx?.refListsReady}
          hasData={hasData}
          buttonArea={
            <TopButtonArea
              hasData={hasData}
              item={cardType}
              topButtonList={[
                {
                  element: DeactivatePopup,
                  bigButton: true,
                  isPlural: true,
                  iconSrc: DELETE_ICON,
                  deleteAction: apiDeleteProjects,
                  item: cardType,
                  onSuccess: refreshList,
                  actionWord: 'delete',
                },
                {
                  element: AddToCollections,
                  bigButton: true,
                  iconSrc: DOWNLOAD_ICON,
                  item: cardType,
                  onSuccess: refreshList,
                  selectedList: selectedList,
                },
                {
                  element: SharePopup,
                  bigButton: true,
                  onSuccess: refreshList,
                },
                {
                  element: BigButton,
                  buttonTitle: 'Download',
                  iconBefore: <img src={DOWNLOAD_ICON} />,
                  onClick: handleDownloadClick,
                },
              ]}
              addNewButtonArea={
                <ModalButtonArea
                  bigButton={true}
                  variant="warning"
                  buttonTitle="New Project"
                  className="new-project"
                  buttonIcon={ProjectIcon}
                  startOpen={createNew}
                  title={
                    <>
                      <h2>What type of project do you want to design?</h2>
                      <h5>Select one of the ones you see down below.</h5>
                    </>
                  }
                  size="lg"
                  body={
                    <>
                      <div className="d-flex spaceEvenly fullWidth pb-2">
                        <BigButton onClick={() => {}}>Open Project</BigButton>
                      </div>
                      <DesignTemplates center />
                    </>
                  }
                />
              }
              anySelected={anySelected}
              selectedList={selectedList}
              loading={!filterCtx?.refListsReady || loading}
            />
          }
        />
      }
      hasData={hasData}
      list={list}
      filterArea={
        <>
          <ActionFilters
            hasData={hasData}
            filterProps={{
              value: filters?.project_type?.selection,
              data: filterOptions,
              onChange: (e) => handleFilter('project_type', e),
            }}
            sortProps={{
              data: sortOptions,
              onChange: (e) => handleSort(e),
            }}
            selectedProps={{
              allSelected: allSelected,
              onSelectAll: (checked) => handleCardSelect(null, checked),
            }}
            showTeamMemberFilter={showTeamMemberFilter}
            loading={loading}
          />
          <GlobalFilter />
        </>
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={!filterCtx?.refListsReady || loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainer
          data={list}
          cardType={cardType}
          selectedList={selectedList}
          onEdit={onEdit}
          onSelect={handleCardSelect}
          clickUrl={getCuratorPath(':id', cardType)} // :id gets dynamically replaced when the list is iterated over
          newTab={true}
          showFavoriteIcon={showFavoriteIcon}
          cardButtonList={[
            {
              element: Download,
              miniButton: true,
              title: 'Download',
              iconSrc: DOWNLOAD_ICON,
              onSuccess: refreshList,
            },
            {
              element: MiniButton,
              title: 'Edit',
              iconSrc: EDIT_ICON,
              clickFunction: onEdit,
              clickUrl: null,
              onSuccess: refreshList,
            },
            { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
            {
              element: AddToCollections,
              miniButton: true,
              iconSrc: COLLECTION_ICON,
              item: cardType,
              onSuccess: refreshList,
            },
            {
              element: DeactivatePopup,
              miniButton: true,
              iconSrc: DELETE_ICON,
              isPlural: false,
              item: cardType,
              onSuccess: refreshList,
              deleteAction: apiDeleteProjects,
              actionWord: 'delete',
            },
          ]}
        />
        {hasMore && <div id="whiteShadow" />}
      </InfiniteScroll>
      <RightSidebar
        initialShowOpen={!_.isNil(editItem)}
        onClose={() => setEditItem(null)}
        title={
          <header className="header">
            {editItem?.src && <Image src={editItem.src} alt="Avatar" className="avatar" rounded />}

            <div>
              <div className="title">Edit {editItem?.name}</div>
              <div className="headerInfo">
                {`${userName ? `${userName} · ` : ''}`}
                {editItem?.updated_at && <ReactTimeAgo date={Date.parse(editItem?.updated_at)} />}
              </div>
            </div>
          </header>
        }
        body={
          <>
            <Form className="d-block">
              <Form.Group className="search-form-group">
                <Input
                  label="Name"
                  name="name"
                  onChange={(e) => onChange(e.name, e.value)}
                  value={editItem?.name || ''}
                  placeholder="Project Name"
                />
              </Form.Group>
              <hr className="solid" />
              <SelectFormGroup
                // error // TO DO
                label="Project Type"
                id="project_type"
                name="project_type"
                onChangeCallback={(e) => onChange('project_type', e)}
                value={editItem?.project_type}
                options={PROJECT_TYPE_OPTIONS}
                selectLabel="project type"
              />
              <hr className="solid" />
              <SelectFormGroup
                label="Categories"
                id="filteroptions"
                name="filteroptions"
                onChangeCallback={(e) => onChange('filteroptions', e)}
                value={editItem?.filteroptions}
                options={filterCtx?.categoryOptions}
                selectLabel="category"
                allowCreatable
                isMulti
              />
              <hr className="solid" />
              <SelectFormGroup
                label="Styles"
                id="tags"
                name="tags"
                onChangeCallback={(e) => onChange('tags', e)}
                value={editItem?.tags}
                options={globalTags}
                selectLabel="style"
                isMulti
                allowCreatable
              />
              <Row>
                <Col>
                  <div className="float-end">
                    <Button
                      variant="secondary"
                      className="m-2"
                      onClick={() => {
                        setEditItem(null);
                        setSaving(false);
                      }}>
                      Cancel
                    </Button>
                    <Button
                      onClick={saveProject}
                      variant="primary"
                      className="my-2"
                      disabled={saving}>
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        }
      />
    </CommonGrid>
  );
}
