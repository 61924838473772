import styles from './PreviewAndRenderSidebarPopup.module.scss';
import { CloseIcon } from '../../../../icons/CloseIcon';

export const PreviewAndRenderSidebarPopup = ({ onClose, children }) => {
  return (
    <div className={styles.root}>
      <button className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </button>

      {children}
    </div>
  );
};
