export const PowerIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.5361 9C17.4883 11.0592 17.4879 14.3972 15.5353 16.4559C13.5828 18.5147 10.4172 18.5147 8.46466 16.4559C6.51208 14.3972 6.51174 11.0592 8.46389 9"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6V12"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
