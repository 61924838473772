import { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { CommonMenu, Sidemenu, Breadcrumbs } from '.';
import cx from 'classnames';
import { useContext } from 'react';
import { FilterContext } from '../contexts';
import _ from 'lodash';
import { IoIosInformationCircle } from 'react-icons/io';
import { IoChatboxEllipsesOutline, IoMailOutline } from 'react-icons/io5';
import { BsQuestionCircle } from 'react-icons/bs';
import { icons } from '../assets';
import { UserContext } from '../contexts';
import { onAuthPathOnly } from '../routes';
import { SIGN_IN_ROUTE } from '../constants/routePathConstants';

const { SupportIcon, CrownIcon } = icons;

// common layout for screens with logged into a session
export default function LayoutWrapper({
  nav = true,
  token,
  refreshToken,
  isEmailVerified,
  currentUser,
  curator,
  displayInfoIcon,
  fullScreen,
}) {
  const userCtx = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPathname = location?.pathname;
  const filterCtx = useContext(FilterContext);
  const pageWrapper = useRef();
  const visualViewportHeight = window.visualViewport
    ? window.visualViewport.height
    : window.innerHeight;
  const heightMinusHeader = visualViewportHeight - 64;
  const [contentHeight, setContentHeight] = useState(heightMinusHeader);
  const [showInfoArea, setShowInfoArea] = useState(false);
  useEffect(() => {
    if (pageWrapper && pageWrapper.current && token) {
      const newHeight = _.ceil(pageWrapper.current.offsetHeight, contentHeight, heightMinusHeader);
      if (newHeight > contentHeight) {
        setContentHeight(newHeight);
      }
    }
  }, [pageWrapper, contentHeight, heightMinusHeader, token]);

  if (!token || !refreshToken || !isEmailVerified || !currentUser || userCtx.onInviteRoute)
    return <Outlet />; // token here
  
  if (onAuthPathOnly(currentPathname) &&
    !(
      userCtx.token &&
      userCtx.refreshToken &&
      userCtx.emailVerified &&
      userCtx.currentUser &&
      !userCtx.onInviteRoute
    )
  ) {
    navigate(SIGN_IN_ROUTE);
  }
  return (
    <div>
      {nav && <CommonMenu />}
      <section className="body-of-main">
        <Sidemenu height={`${contentHeight}px`} />
        <Container
          fluid
          className={cx(
            {
              'page-wrapper': !fullScreen,
              'full-screeen-page-wrapper': fullScreen,
            },
            'p-0'
          )}
          ref={pageWrapper}>
          {filterCtx && filterCtx.breadcrumbs && <Breadcrumbs list={filterCtx.breadcrumbs} />}
          <Row>
            <Col className="wrapper pe-0">
              <Outlet />
            </Col>
          </Row>
          {displayInfoIcon && (
            <OverlayTrigger
              trigger="click"
              key="infoArea"
              placement="top"
              overlay={
                <Popover id={`popover-positioned-top`} className="infoAreaPopover">
                  <Popover.Body className="align-items-stretch">
                    {/* these are temporary navigation links until we hear back about functionality for these links */}
                    <div
                      className="infoPopoverLink"
                      onClick={() => navigate('https://www.livefurnish.com/')}>
                      <CrownIcon />
                      Book an expert
                    </div>
                    <div
                      className="infoPopoverLink"
                      onClick={() => navigate('https://www.livefurnish.com/')}>
                      <IoChatboxEllipsesOutline size="1.5em" reverse="true" />
                      Chat support
                    </div>
                    <div
                      className="infoPopoverLink"
                      onClick={() => navigate('https://www.livefurnish.com/')}>
                      <BsQuestionCircle size="1.5em" />
                      FAQs
                    </div>
                    <div
                      className="infoPopoverLink"
                      onClick={() => navigate('https://www.livefurnish.com/')}>
                      <IoMailOutline size="1.5em" />
                      Contact Us
                    </div>
                    <div
                      className="infoPopoverLink"
                      onClick={() => navigate('https://www.livefurnish.com/')}>
                      <SupportIcon />
                      Raise ticket
                    </div>
                  </Popover.Body>
                </Popover>
              }>
              <div className="infoIconHelper" onClick={() => setShowInfoArea(!showInfoArea)}>
                <IoIosInformationCircle size="40px" />
              </div>
            </OverlayTrigger>
          )}
        </Container>
      </section>
    </div>
  );
}
