import {createSlice} from "@reduxjs/toolkit";

const utilities = createSlice({
    name: 'utilities',
    initialState: {
        curatorPanel : ''
    },
    reducers: {
        setCuratorPanel: (state, {payload}) => {
            state.curatorPanel = payload
        }
    }
})

export const {curatorPanel, setCuratorPanel} = utilities.actions;
export const utilitiesSelector = state => state.utilities;
export const utilitiesReducer = utilities.reducer;
export default utilitiesReducer;