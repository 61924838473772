import { createSlice } from "@reduxjs/toolkit";

const texture = createSlice({
  name: "textures",
  initialState: {
    textureData: {},
    textureDataV2: {},
    textureList: [],
    page: 1,
    perPage: 12,
    loading: false,
  },
  reducers: {
    setTextureData: (state, { payload }) => {
      state.textureData = payload;
    },
    fetchTextureListStart: (state) => {
      state.loading = true;
    },
    fetchTextureListSuccess: (state, { payload }) => {
      state.page = payload.page;
      state.textureDataV2 = payload.data;
      // state.textureList = [...state.textureList, ...payload.data.results] // TODO: leave only unique items in the array
      state.textureList = payload.data.results;
      state.loading = false;
    },
    fetchTextureListFailed: (state) => {
      state.loading = false;
    },
    resetTextureList: (state) => {
      state.textureList = [];
    },
    addTextureSuccess: (state, { payload }) => {
      state.textureList = [payload, ...state.textureList]
    },
    updateTexture: (state, { payload }) => {
      state.textureList = state.textureList.map(texture => {
        if (texture.id === payload.id) {
          return {
            ...texture,
            ...payload,
          }
        }

        return texture;
      })
    }
  },
});

export const { 
  setTextureData,
  fetchTextureListStart,
  fetchTextureListSuccess,
  fetchTextureListFailed,
  addTextureSuccess,
  updateTexture,
} = texture.actions;
export const textureSelector = (state) => state.texture;
export const textureReducer = texture.reducer;
export default textureReducer;
