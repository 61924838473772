import { useState } from 'react';
import _ from 'lodash';

export default function FooterButton(props) {
  const { id, cardType, title, clickFunction, showModalFunction, alt, icon, inPreview} = props;

  function handleClickEvent(e) {
    if (_.isFunction(clickFunction)) {
      clickFunction(e);
    } else if (_.isFunction(showModalFunction)) {
      showModalFunction(true);
    }
  }

  const Icon = icon;

  return (
    <li title={title}>
      <div
        role="button"
        onClick={handleClickEvent}
        className="text-light d-flex flex-column justify-content-center align-items-center">
        <Icon />
        <div className="text-center text-nowrap" style={{ fontSize: '0.75rem' }}>
          {props.title}
        </div>
      </div>
    </li>
  );
}
