import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import { useUndoRedoInput } from '../../../../hooks/useUndoRedoInput';
import { FormLabel } from './FormLabel';
import styles from './FormRange.module.scss';

const FormRange = ({
  label,
  value,
  min,
  max,
  hideValue,
  size,
  containerProps,
  labelProps,
  onBlur,
  onFocus,
  undoRedoAction,
  undoRedoData,
  ...rest
}) => {
  const { handleBlur, handleFocus } = useUndoRedoInput({
    value,
    onBlur,
    onFocus,
    undoRedoAction,
    data: undoRedoData,
  });
  value = Number.isNaN(parseFloat(value)) ? min : value;
  const bgSize = ((value - min) * 100) / (max - min);

  return (
    <div {...containerProps}>
      {(!hideValue || label) && (
        <div className={cx('d-flex justify-content-between')}>
          <FormLabel {...labelProps}>{label}</FormLabel>
          {!hideValue && <span>{value}</span>}
        </div>
      )}
      <Form.Range
        {...rest}
        value={value}
        className={cx(styles.range, rest.className, size === 'large' && styles.large)}
        min={min}
        max={max}
        onBlur={handleBlur}
        onFocus={handleFocus}
        style={{
          backgroundSize: `${bgSize}% 100%`,
        }}
      />
    </div>
  );
};

export default FormRange;
