const ShareIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99148 19.1998H14.0101C17.6469 19.1998 19.2008 17.6459 19.2008 14.0091V12.6696C19.2008 12.395 18.9731 12.1672 18.6985 12.1672C18.4239 12.1672 18.1961 12.395 18.1961 12.6696V14.0091C18.1961 17.0967 17.0977 18.1952 14.0101 18.1952H9.99148C6.90385 18.1952 5.80543 17.0967 5.80543 14.0091V9.9905C5.80543 6.90287 6.90385 5.80446 9.99148 5.80446H11.331C11.6056 5.80446 11.8333 5.57673 11.8333 5.30213C11.8333 5.02753 11.6056 4.7998 11.331 4.7998H9.99148C6.35464 4.7998 4.80078 6.35367 4.80078 9.9905V14.0091C4.80078 17.6459 6.35464 19.1998 9.99148 19.1998ZM18.6985 9.01934C18.4239 9.01934 18.1961 8.79162 18.1961 8.51701V6.5144L13.0255 11.685C12.9251 11.7855 12.7978 11.8324 12.6705 11.8324C12.5433 11.8324 12.416 11.7855 12.3156 11.685C12.1213 11.4908 12.1213 11.1693 12.3156 10.9751L17.4862 5.80446H15.4836C15.209 5.80446 14.9813 5.57673 14.9813 5.30213C14.9813 5.02753 15.209 4.7998 15.4836 4.7998H18.6985C18.9731 4.7998 19.2008 5.02753 19.2008 5.30213V8.51701C19.2008 8.79162 18.9731 9.01934 18.6985 9.01934Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ShareIcon;
