
export function generate(count, type) {
  let list = [];
  
  for (let i = 1; i <= count; i++) {
    list.push({
          "id": i,
          "thumbnail": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png",
          "name": `${type} Name ${i}`,
          "edited": `Edited ${i}h ago`
        })
  }
    
  const dataModel = {
    "results": list
  }
  return dataModel;
}