import React from "react";
import "./switch.scss";
import { useState } from "react";

function Switch(props) {
  // const [isToggled, setIsToggled] = useState(false);
  // const onToggle = () => setIsToggled(!isToggled);
  return (
    <label className="toggle-switch">
      <input type="checkbox" ref={props.selectRef} checked={props.checked} onChange={props.onToggle}  id={props.toggleId} />
      <span className="switch" />
    </label>
  );
}

export default Switch;
