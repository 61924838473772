import { openDB } from 'idb';

const FILE_KEY = {
  PROJECT_ZIP: 'projectZip',
}

const STORE = {
  PROPS: 'props',
  MATERIALS: 'materials',
  TEXTURES: 'textures',
  LAYOUTS: 'layouts',
  FILES: 'files',
}

export const idb = {
  curator: openDB("LiveFurnishCurator", 2, {
    blocked: () => {
      // TODO: handle this case when first upgrade will be needed
      console.log(`Please close this app opened in other browser tabs.`);
    },
    blocking: () => {
      // TODO: handle this case when first upgrade will be needed
      console.log(`App is outdated, please close this tab`);
    },
    upgrade: (db, oldVersion, newVersion, transaction) => {
      // TOOD: handle db upgrades when it's needed
      switch (oldVersion) {
        case 0:
          upgradeCuratorFromV0toV1();
          break;
        case 1:
          upgradeCuratorFromV1toV2();
          break;
        default:
          console.error('unknown db version');
      }


      function upgradeCuratorFromV0toV1() {
        db.createObjectStore(STORE.LAYOUTS, { keyPath: 'id' });
        db.createObjectStore(STORE.PROPS, { keyPath: 'id' });
        db.createObjectStore(STORE.MATERIALS, { keyPath: 'id' });
        db.createObjectStore(STORE.TEXTURES, { keyPath: 'id' });
      }

      function upgradeCuratorFromV1toV2() {
        db.createObjectStore(STORE.FILES, { keyPath: 'id' });
      }
    }
  }),
};

export const getCuratorDB = () => idb.curator;

window.getCuratorDB = getCuratorDB;

// export const add
export const updateAsset = async (storeName, data) => {
  const db = await getCuratorDB();
  await db.put(storeName, data);
}

const assetStore = {}

export const getAsset = async (storeName, id) => {
  // if (!assetStore[storeName]) {
  //   assetStore[storeName] = {};
  // }

  // if (assetStore[storeName][id]) {
  //   return assetStore[storeName][id];
  // }


  const db = await getCuratorDB();
  const asset = db.get(storeName, id)

  // assetStore[storeName][id] = asset;
  return asset;
}


const objectUrlStore = {} // key = `{store}_{id}

const normalizeAsset = (asset, storeName) => {
  // TODO: add this url to some store and revoke it later so we don't keep it in memory
  console.log('normalizeAsset', asset, storeName)
  const urlId = `${storeName}_${asset.id}`;
  if (!objectUrlStore[urlId]) {
    // objectUrlStore[urlId] = asset.originalFileUrl // URL.createObjectURL(asset.file);
    objectUrlStore[urlId] = URL.createObjectURL(asset.file);
  }

  asset.file = objectUrlStore[urlId];
  return asset;
}

export const getOrStoreAsset = async (storeName, { id, version, file, ...rest }) => {

  try {
    let asset = await getAsset(storeName, id)

    if (!asset || !asset.file || asset.version !== version) {
      const blob = await fetch(file).then(res => res.blob())
      asset = { ...rest, id, version, file: blob, originalFileUrl: file };
      await updateAsset(storeName, asset)
    } 
    return normalizeAsset(asset, storeName)
  } catch (err) {
    console.log('sasha getOrStoreAsset error', err)
  }
}

export const updateDbProp = ({ id, name, version, product_file, ...rest }) => {
  return getOrStoreAsset(STORE.PROPS, { ...rest, id, name, version, file: product_file });
}

export const updateDbTexture  = ({ id, name, version, texture_file, ...rest }) => {
  return getOrStoreAsset(STORE.TEXTURES, { ...rest, id, name, version, file: texture_file });
}

export const updateDbMaterial  = ({ id, name, version, material_image, ...rest }) => {
  return getOrStoreAsset(STORE.MATERIALS, { ...rest, id, name, version, file: material_image });
}

export const updateDbLayout  = ({ id, display_name, version, webgl, ...rest }) => {
  return getOrStoreAsset(STORE.LAYOUTS, { ...rest, id, name: display_name, version, file: webgl });
}


export const getTexture = async (id) => {
  const asset = await getAsset(STORE.TEXTURES, parseInt(id))
  return normalizeAsset(asset, STORE.TEXTURES)
}

export const getMaterial = async (id) => {
  const asset = await getAsset(STORE.MATERIALS, parseInt(id))
  return normalizeAsset(asset, STORE.MATERIALS)
}

export const getProejctZip = () => {
  return getAsset(STORE.FILES, FILE_KEY.PROJECT_ZIP);
}

export const setProjectZip = (file) => {
  return updateAsset(STORE.FILES, {
    id: FILE_KEY.PROJECT_ZIP,
    file,
  })
}