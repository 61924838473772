const BinIcon2 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3234 8.44541L12.1325 9.58194C11.2954 9.62739 10.46 9.69138 9.62641 9.77599L7.80464 9.95459C7.43851 9.99031 7.1706 10.3118 7.20632 10.6869C7.24204 11.0619 7.57246 11.3298 7.94753 11.2941L8.60441 11.2297L9.70679 22.4839C9.80503 23.877 9.93005 25.6005 13.0467 25.6005H18.7799C21.8965 25.6005 22.0215 23.877 22.1198 22.4839L23.0473 11.2139C23.3245 11.2396 23.6017 11.2663 23.879 11.2941H23.9505C24.2898 11.2941 24.5845 11.0351 24.6113 10.6869C24.647 10.3207 24.3791 9.99031 24.013 9.95459C22.5741 9.81337 21.136 9.69759 19.7001 9.61745L19.6998 9.61527L19.5033 8.45434L19.5024 8.44909C19.3685 7.62753 19.1684 6.40039 17.0832 6.40039H14.7435C12.6642 6.40039 12.4666 7.58641 12.3237 8.44357L12.3234 8.44541ZM21.7098 11.0987C17.7696 10.786 13.8393 10.7167 9.93983 11.0985L11.0463 22.3946L11.0465 22.397C11.1446 23.7526 11.1814 24.261 13.0467 24.261H18.7799C20.6552 24.261 20.6909 23.7519 20.7802 22.3946L21.7098 11.0987ZM18.1906 8.67759L18.3386 9.55244C16.7226 9.48869 15.1099 9.47429 13.5027 9.52382L13.654 8.66866L13.6554 8.66063C13.7881 7.87303 13.809 7.74885 14.7524 7.74885H17.0921C18.0387 7.74885 18.0655 7.90067 18.1906 8.67759ZM17.3958 20.6887H14.4221C14.0559 20.6887 13.7523 20.3851 13.7523 20.019C13.7523 19.6528 14.0559 19.3492 14.4221 19.3492H17.3958C17.762 19.3492 18.0656 19.6528 18.0656 20.019C18.0656 20.3851 17.762 20.6887 17.3958 20.6887ZM13.6808 17.1166H18.1459C18.512 17.1166 18.8157 16.813 18.8157 16.4469C18.8157 16.0807 18.512 15.7771 18.1459 15.7771H13.6808C13.3147 15.7771 13.011 16.0807 13.011 16.4469C13.011 16.813 13.3147 17.1166 13.6808 17.1166Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BinIcon2;
