export const SaveIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.34 6.3999C11.0518 6.3999 9.2579 6.85302 8.05473 8.05619C6.90382 9.2071 6.43922 10.8985 6.40103 13.0461H6.39844V13.3414V16.9304V17.4768V18.6584C6.39844 20.9466 6.85156 22.7404 8.05473 23.9436C9.2579 25.1468 11.0518 25.5999 13.34 25.5999H18.6569C20.9451 25.5999 22.739 25.1468 23.9421 23.9436C25.1453 22.7404 25.5984 20.9466 25.5984 18.6584V13.3414C25.5984 11.0532 25.1453 9.25936 23.9421 8.05619C22.739 6.85302 20.9451 6.3999 18.6569 6.3999H13.34ZM7.87536 16.9304V13.3414C7.87536 11.1989 8.30839 9.89121 9.09907 9.10053C9.17819 9.02141 9.26249 8.94587 9.35228 8.87399V12.3076C9.35228 13.939 10.6748 15.2614 12.3061 15.2614H19.6907C21.3221 15.2614 22.6446 13.939 22.6446 12.3076V8.87399C22.7344 8.94587 22.8187 9.02141 22.8978 9.10053C23.6885 9.89121 24.1215 11.1989 24.1215 13.3414V18.6584C24.1215 20.8009 23.6885 22.1086 22.8978 22.8993C22.1071 23.6899 20.7994 24.123 18.6569 24.123H13.34C11.1974 24.123 9.88975 23.6899 9.09907 22.8993C8.30839 22.1086 7.87536 20.8009 7.87536 18.6584V17.4768V16.9304ZM21.1677 8.15872C20.7399 8.04096 20.2505 7.95902 19.6907 7.91493V10.0922C19.6907 11.3157 18.6989 12.3076 17.4754 12.3076C16.2518 12.3076 15.26 11.3157 15.26 10.0922V7.87683H13.34C12.328 7.87683 11.5022 7.97344 10.8292 8.15872V12.3076C10.8292 13.1233 11.4904 13.7845 12.3061 13.7845H19.6907C20.5064 13.7845 21.1677 13.1233 21.1677 12.3076V8.15872ZM16.7369 7.87683V10.0922C16.7369 10.5001 17.0675 10.8307 17.4754 10.8307C17.8832 10.8307 18.2138 10.5001 18.2138 10.0922V7.87683H16.7369Z"
        fill="currentColor"
      />
    </svg>
  );
};
