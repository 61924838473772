import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import '../assets/scss/_style.scss';
import { useEffect, useState, useContext } from 'react';
import { setTitle } from '../helpers/jsHelper';
import HomeCardCarousel from '../components/basic/HomeCardCarousel';
import {
  INSPIRATION_ROUTE,
  RECENT_DESIGNS_ROUTE,
  MY_PROJECTS_ROUTE,
  SIGN_IN_ROUTE,
} from '../constants/routePathConstants';
import { apiGetProjects } from '../helpers/api';
import { DesignTemplates } from '../common';
import { UserContext } from '../contexts';
import { getLocalStorage } from '../helpers/jsHelper';
import { AUTH_TOKEN } from '../constants/projectConstants';

import { warningToast } from '../helpers/toastHelper.js';

const data = {
  results: [
    {
      id: 1,
      thumbnail:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png',
      name: 'Project Name 1',
      edited: 'Edited 19h ago',
    },
    {
      id: 2,
      thumbnail:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png',
      name: 'Project Name 2',
      edited: 'Edited 19h ago',
    },
    {
      id: 3,
      thumbnail:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png',
      name: 'Project Name 3',
      edited: 'Edited 19h ago',
    },
    {
      id: 4,
      thumbnail:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png',
      name: 'Project Name 4',
      edited: 'Edited 19h ago',
    },
    {
      id: 5,
      thumbnail:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png',
      name: 'Project Name 5',
      edited: 'Edited 19h ago',
    },
    {
      id: 6,
      thumbnail:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png',
      name: 'Project Name 6',
      edited: 'Edited 19h ago',
    },
  ],
};

export default function AdminDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [myProjectsData, setMyProjectsData] = useState({});

  useEffect(() => {
    setTitle('Home');
    getProjects();
    window.addEventListener('click', verifyUser);
  }, []);

  function verifyUser() {
    if (!getLocalStorage(AUTH_TOKEN)) {
      warningToast("You are logged out. Redirecting...")
      window.location.pathname = SIGN_IN_ROUTE;
      return null;
    }
  }
  // this screen could still be open in another tab after logout and since it is "/" path,
  // which is both public and auth
  // forcing redirects on clicks anywhere in the screen is this is still open.

  function getProjects() {
    if (userCtx && userCtx.currentUser) {
      apiGetProjects().then((response) => {
        setMyProjectsData(response);
      });
    }
  }

  // since the "/" route can get here and also via Sign in Page will redirect
  if (!getLocalStorage(AUTH_TOKEN)) {
    warningToast("You are logged out. Redirecting...")
    window.location.pathname = SIGN_IN_ROUTE;
    return null;
  }
  return (
    <div className="content">
      <Container className="px-0 pb-4 grid-section" fluid>
        <Row className="mb-2">
          <Col>
            <h2 className="pg-title mb-3">What do you want to design?</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <DesignTemplates />
          </Col>
        </Row>
      </Container>
      <Container className="px-0 pb-4" fluid>
        <Row className="mb-2">
          <Col className="carousel-card-header">
            <h5 className="mb-0 head-h5">Inspiration</h5>
            <Link className="see_all_link" to={INSPIRATION_ROUTE}>
              See all
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <HomeCardCarousel data={data} />
          </Col>
        </Row>
      </Container>
      <Container className="px-0 pb-4" fluid>
        <Row className="mb-2">
          <Col className="carousel-card-header">
            <h5 className="mb-0 head-h5">Recent designs</h5>
            <Link className="see_all_link" to={RECENT_DESIGNS_ROUTE}>
              See all
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <HomeCardCarousel data={data} />
          </Col>
        </Row>
      </Container>
      <Container className="px-0 pb-4" fluid>
        <Row className="mb-2">
          <Col className="carousel-card-header">
            <h5 className="mb-0 head-h5">My projects</h5>
            <Link className="see_all_link" to={MY_PROJECTS_ROUTE}>
              See all
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <HomeCardCarousel data={myProjectsData} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
