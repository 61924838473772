import { useEffect } from 'react';
import { ComingSoon } from '../common';
import {
  setTitle
} from '../helpers/jsHelper';
let title = 'Community';

export default function Community() {
  useEffect(() => {
    setTitle(title);
  }, []);
  
  return <ComingSoon name={title} />;
}
