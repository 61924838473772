import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { CommonGrid, TopContainer } from '../layouts';
import { GridSection } from '../common';
import { MyProducts3dModel, MyProductsRooms, MyProductsTextures, MyProductsUploads } from '../screens';
import { parseBasePathname } from '../helpers/api';
import {
  ADMIN_MY_PRODUCTS_3DMODAL,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_ROOMS,
  ADMIN_MY_PRODUCTS_TEXTURE,
  ADMIN_MY_PRODUCTS_UPLOADS,
} from '../constants/routePathConstants';
import { generate } from '../helpers/randomResults';
import { setTitle } from '../helpers/jsHelper';
import { useEffect } from 'react';
//generating some random data for now

let rooms = generate(5, 'Room');

let title = 'My Products';

export default function MyProducts() {
  const location = useLocation();
  const pathname = parseBasePathname(location);
  let cardType = _.replace(pathname, '/', '');
  useEffect(() => {
    setTitle(title);
  }, []);
  return (
    <CommonGrid
      topContainer={
        <TopContainer
          title={<>My Products</>}
          item={cardType}
        />
      }>
      <MyProducts3dModel title="3D Models" cardType='model' carouselView />
      <GridSection title="Rooms" seeAllLink={ADMIN_MY_PRODUCTS_ROOMS} carouselData={rooms} />
      <MyProductsTextures title="Textures" cardType='texture' carouselView />
      <MyProductsUploads title="Uploads" cardType='uploads' carouselView />
    </CommonGrid>
  );
}
