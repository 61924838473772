import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UndoRedoContextProvider } from '../components/admin/curator/UndoRedoContext';
import UnityContainer from '../components/container/unityContainer';
import { useResetCuratorState } from '../hooks/useResetCuratorState';
import AdminCurator from '../pages/admin/curator';
import { setCuratorLoader } from '../redux/slicers/admin/curatorSlicer';

export default function Curator() {
  const dispatch = useDispatch();
  const { resetCuratorState } = useResetCuratorState();

  useEffect(() => {
    dispatch(setCuratorLoader(false));

    return () => {
      resetCuratorState();
    };
  }, []);

  return (
    <UnityContainer>
      <UndoRedoContextProvider>
        <AdminCurator />
      </UndoRedoContextProvider>
    </UnityContainer>
  );
}
