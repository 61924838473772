import { Button } from 'react-bootstrap';
import _ from 'lodash';
import cx from 'classnames';

export default function BigButton({
  id,
  cardType,
  title,
  onClick,
  showModalFunction,
  iconSrc,
  alt = null,
  ...props
}) {
  function handleClickEvent(e) {
    if (_.isFunction(onClick)) {
      onClick(id, cardType);
    } else if (_.isFunction(showModalFunction)) {
      showModalFunction(true);
    }
  }

  return (
    <Button
      variant={props.variant || 'warning'}
      size={props.size || 'lg'}
      onClick={handleClickEvent}
      type="button"
      disabled={props.disabled}
      className={cx(
        props.className,
        'bigButton font16')}
      title={props.buttonTitle || props.children}
      block={props.block}
      style={props.style}
    >
      {props.iconBefore}
      {props.buttonIcon && <props.buttonIcon className='me-2' />}
      {props.buttonTitle || props.children}
    </Button>
  );
}
