import {createSlice} from "@reduxjs/toolkit";

const props = createSlice({
    name: 'props',
    initialState: {
       propsData:{}
    },
    reducers: {
        setProps: (state, {payload}) => {
            state.propsData = payload
        },
       
    }
})

export const {setProps} = props.actions;
export const propsSelector = state => state.props;
export const propsReducer = props.reducer;
export default propsReducer


