import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styles from '../assets/scss/modules/SideFilter.module.scss';
import ReactTimeAgo from 'react-time-ago';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FilterItem } from '../common';
import {
  ADMIN_MY_PRODUCTS_TEXTURE,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
} from '../constants/routePathConstants';
import { CommonGrid, TopContainer } from '../layouts';
import { ReactLoader } from '../layouts/Loading';
import {
  BigButton,
  TopButtonArea,
  ThumbnailContainer,
  ModalButtonArea,
  DesignTemplates,
  InfiniteScroll,
  ActionFilters,
  Image,
  SelectFormGroup,
  GlobalFilter,
  Input,
  RightSidebar,
  MiniButton,
  Download,
  AddToCollections,
  SharePopup,
  DeactivatePopup,
  EditButton,
  DropzoneUpload,
} from '../common';
import constants from '../constants/constants';
import _ from 'lodash';
import { icons } from '../assets';
import { FilterContext, UserContext } from '../contexts';
import {
  getCommonMetadata,
  addValueLabelToList,
  constructQueryParams,
  changeFilter,
  keyFromLocationState,
  setTitle
} from '../helpers/jsHelper';
import { apiGetCachedList, apiErrorHandler } from '../helpers/axiosHelper';
import {
  apiGetTextures,
  apiGetTextureList,
  apiEditProject,
  parseBasePathname,
  apiDeleteProjects,
  parseProductTypePathname,
} from '../helpers/api';
import { getScreenSettings } from '../helpers/configureHelper';
import {
  EDIT_ICON,
  SHARE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
  DELETE_ICON,
} from '../constants/assetConstants';

const { PROJECT_TYPE_OPTIONS, FILTER_TYPES } = constants;
const { UploadIcon } = icons;

let title = 'My Products';

export default function MyProductsTexturesEdit(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = parseBasePathname(location);
  const productType = parseProductTypePathname(location);
  let incomingFiles = keyFromLocationState(location, 'files');
  let cardType = props && props.cardType ? props.cardType : _.replace(pathname, '/', '');
  let routeParams = useParams();
  let createNew;
  if (routeParams) {
    createNew = parseInt(routeParams.projectId, 10) < 0;
  }
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && currentUser.name;
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sortField, setSortField] = useState();
  const [globalTags, setGlobalTags] = useState([]);
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [total, setTotal] = useState(0);
  const [importFiles, setImportFiles] = useState(incomingFiles || []);

  const pageSettings = getScreenSettings(cardType);
  const { sortOptions, filterOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter } =
    pageSettings;

  useEffect(() => {
    setTitle(title);
  }, []);

  useEffect(() => {
    if (!props.carouselView) {
      let breadcrumbs = productType
        ? [
            {
              link: ADMIN_MY_PRODUCTS_TEXTURE,
              label: 'Textures',
            },
            {
              link: null,
              label: 'Edit Texture',
            },
          ]
        : null;
      filterCtx.setBreadcrumbs(breadcrumbs);
    }
  }, [productType, props.carouselView]);

  useEffect(() => {
    return () => {
      if (filterCtx) {
        filterCtx.cleanGlobalFiltersOnNavigate();
      }
    };
  }, []);
  
  function handleTextureSelect() {

  }
  if (!filterCtx) return <ReactLoader />;
  return (
    <>
      <aside className="sidebar">
        <div className="sidebar-wrapper filter-sidebar-wrapper">
          <div className="sidebar-filter fs-container filter_container">
              <div className={styles.root}>
                <FilterItem
                  title="Textures"
                  list={importFiles}
                  onChange={handleTextureSelect}
                  key={`dropdownSidemenuTextureSelect`}
                  showPreview
                />
              </div>
          </div>
        </div>
      </aside>
    </>
  );
}
