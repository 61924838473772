import cx from 'classnames';
import Button from "../../../../Button/Button";
import FileUploadIcon from "../../../../icons/FileUploadIcon";
import { useHDRIUpload } from './useHDRIUpload';

export const UploadHDRIButton = ({ className }) => {
  const { openDialog } = useHDRIUpload();

  const handleClick = (e) => {
    e.stopPropagation();
    openDialog();
  }

  return (
    <Button
      variant="outline"
      color="light"
      iconBefore={<FileUploadIcon />}
      className={cx('w-50', className)}
      size="xlg"
      hoverStyle="secondary"
      onClick={handleClick}
    >
      Upload
    </Button>
  );
};
