import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPreviewHDRI } from "../../../../../redux/slicers/admin/curatorLightSlicer";
import { useUnityLight } from "./useUnityLight";


// does clean up after light section was hidden
export const useLightSectionCleanUp = ({ active }) => {
  const dispatch = useDispatch();
  const { closeColorPopup } = useUnityLight();

  useEffect(() => {
    if (!active) {
      // it was just hidden, perform cleanup
      dispatch(setPreviewHDRI(null))
      closeColorPopup();
    }
  }, [active, dispatch])
}