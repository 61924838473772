import cx from 'classnames';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPropsApi } from '../../../api/myprojects';
import { useUnityEvents } from '../../../components/admin/curator/stylesSection/useUnityRedux';
import { UnityCanvasContainer } from '../../../components/admin/unity';
import Loader from '../../../components/Loader/Loader';
import { SafeCameraFrame } from '../../../components/container/SafeCameraFrame';

import { curatorSelector } from '../../../redux/slicers/admin/curatorSlicer';
import { cameraSettingsSelector } from '../../../redux/slicers/camera/cameraSettings';

import styles from './unityUI.module.scss';
import { SelectedPropName } from '../../../components/admin/curator/SelectedPropName/SelectedPropName';
import { CuratorContextMenu } from '../../../components/admin/curator/CuratorContextMenu/CuratorContextMenu';
import { CuratorShortcuts } from '../../../components/admin/curator/CuratorShortcuts/CuratorShortcuts';
import { CuratorInfoIcon } from '../../../components/admin/curator/CuratorInfoIcon/CuratorInfoIcon';
import { TextureVersionSelectorDialog } from '../../../components/admin/curator/TextureVersionSelectorDialog/TextureVersionSelectorDialog';
import { CuratorLoader } from '../../../components/admin/curator/CuratorLoader';

const UnityUI = memo(({ className }) => {
  const dispatch = useDispatch();
  const { unityData = {} } = useSelector(curatorSelector);

  useUnityEvents();

  useEffect(() => {
    dispatch(getPropsApi());
  }, []);

  return (
    <>
      <div className={cx(styles.unityContainer, className)} id="unityUIContainer">
        {unityData?.props && (
          <div
            style={{ width: '100%', height: '100%' }}
            // onClick={hideDisplayHandler}
          >
            <UnityCanvasContainer data={unityData} />
          </div>
        )}
        <CuratorLoader />
        <SafeCameraFrame />
      </div>
      <SelectedPropName />
      <CuratorContextMenu />
      <CuratorShortcuts />
      <CuratorInfoIcon />
      <TextureVersionSelectorDialog />
    </>
  );
});

export default UnityUI;
