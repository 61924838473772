import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { icons } from '../assets';
import FooterButton from './FooterButton';
import { apiGetDownloadRendersImages } from '../helpers/api';
import api from '../helpers/axiosHelper';

const { DownloadIcon } = icons;

export default function Download(props) {
  const [downloading, setDownloading] = useState(false);

  async function handleDownload() {
    let res;

    if (props.cardType === 'renders-previews') {
      res = await apiGetDownloadRendersImages(`ids=${props.id}`);
    }
    // TODO: Handlers for other cardTypes can go here.

    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'render.zip');
    document.body.appendChild(link);
    link.click();
  }

  if (props.footerButton) return <FooterButton icon={DownloadIcon} {...props} />;

  if (props.miniButton)
    return (
      <li title={props.buttonTitle}>
        <div onClick={handleDownload} style={{ width: '16px' }}>
          <img className="img-fluid" src={props.iconSrc} alt={props.buttonTitle} />
        </div>
      </li>
    );

  return (
    <Button
      className="btn-warning"
      // variant={props.disabled ? 'outline-secondary' : 'outline-dark'}
      size={props.size || 'lg'}
      onClick={handleDownload}
      type="button"
      disabled={props.disabled}>
      <DownloadIcon />
      Download
    </Button>
  );
}
