import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { projectTypeDropdownOptions, PROJECT_TYPE } from '../../../../constants/projectConstants';
import {
  MAX_PROJECT_NAME_LENGTH,
  MIN_PROJECT_NAME_LENGTH,
} from '../../../../config/constant/validationConstant';
import { SelectController } from '../../../hookForm/SelectController';
import closeIcon from '../../../../assets/images/curator/dropdown-delete-icon.svg';
import dropdownArrow from '../../../../assets/images/curator/dropdown-icon.svg';

import Button from '../../../Button/Button';
import {
  selectSaveProjectLoading,
  selectSaveProjectMode,
} from '../../../../redux/slicers/admin/curatorSlicer';
import styles from './SaveProjectForm.module.scss';

export const SaveProjectForm = ({ onSubmit, data, categoryOptions, styleOptions }) => {
  const loading = useSelector(selectSaveProjectLoading);

  const schema = useMemo(() => {
    return yup.object({
      name: yup
        .string()
        .min(MIN_PROJECT_NAME_LENGTH, 'Project name is missing')
        .max(
          MAX_PROJECT_NAME_LENGTH,
          `Project length should not be longer than ${MAX_PROJECT_NAME_LENGTH}`
        )
        .required('Project name is missing'),
      projectType: yup.string().required('Project type is missing'),
      category: yup.array().test('categoryMin', 'Please select category', (value, context) => {
        if (context.parent.projectType === PROJECT_TYPE.SILO) return true;

        return value.length > 0;
      }),
      style: yup.array().test('styleMin', 'Please select style', (value, context) => {
        if (context.parent.projectType === PROJECT_TYPE.SILO) return true;

        return value.length > 0;
      }),
    });
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      projectType: PROJECT_TYPE.ROOM,
      category: [],
      style: [],
    },
  });

  useEffect(() => {
    reset(data);
  }, [data]);

  const projectType = watch('projectType');
  const isSilo = projectType === PROJECT_TYPE.SILO;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Project name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          {...register('name')}
          placeholder="Enter project name"
          className={`${errors.name ? 'is-invalid' : ''} ${styles.projectNameInput}`}
        />
        <div
          className="invalid-feedback"
          style={{ display: errors.name?.message ? 'block' : 'none' }}>
          {errors.name?.message}
        </div>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGroupEmail">
        <SelectController
          name="projectType"
          control={control}
          options={projectTypeDropdownOptions}
          label="Type of project"
          isSearchable={false}
          variant="curatorLight"
          selectStyles={colourStyles}
        />
      </Form.Group>

      {!isSilo && (
        <>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <SelectController
              name="category"
              control={control}
              options={categoryOptions}
              label="Category"
              isSearchable
              isMulti
              selectStyles={colourStyles}
              isClearable={false}
              placeholder="Select Category"
            />
          </Form.Group>

          <Form.Group controlId="formGroupEmail">
            <SelectController
              name="style"
              control={control}
              options={styleOptions}
              label="Style"
              isSearchable
              isMulti
              selectStyles={colourStyles}
              isClearable={false}
              placeholder="Select Style"
              // menuIsOpen
            />
          </Form.Group>
        </>
      )}

      <Button
        variant="primary"
        color="warning"
        size="xlg"
        className="w-100 mt-4"
        type="submit"
        loading={isSubmitting || loading}>
        Save
      </Button>
    </Form>
  );
};

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    minHeight: '48px',
    borderRadius: '8px',
    border: '1px solid var(--neutral20-color)',
    // padding: 12,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      padding: 16,
      fontSize: '1rem',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? 'var(--neutral10-color)'
        : isFocused
        ? '#E5EEFE'
        : undefined,
      color: isDisabled ? '#ccc' : isSelected ? 'var(--neutral60-color)' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      height: 56,
      display: 'flex',
      alignItems: 'center',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? data.color : '#E5EEFE') : undefined,
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    borderRadius: 8,
    border: '1px solid var(--black-color)',
    boxShadow: 'none',
    overflow: 'hidden',
  }),
  menuList: (styles) => ({
    padding: 0,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  // input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles }),
  indicatorSeparator: () => ({ display: 'none' }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),

  indicatorsContainer: (styles) => ({
    ...styles,

    '& div:last-child': {
      background: `url(${dropdownArrow}) no-repeat center center`,
      width: 24,
      marginRight: 12,

      '& svg': {
        display: 'none',
      },
    },
  }),

  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--white-color)',
    borderRadius: 8,
    border: '1px solid var(--neutral80-color)',
    height: 32,
    padding: '4px 26px 4px 8px',
    fontSize: '0.875rem',
    lineHeight: '22px',
    position: 'relative',

    '& div:first-child': {
      padding: 0,
    },

    '& [role="button"]': {
      width: 24,
      height: 24,
      right: 0,
      top: 3,
      background: `url(${closeIcon}) no-repeat center center`,
      position: 'absolute',

      '& svg': {
        display: 'none',
      },

      ':hover': {
        backgroundColor: 'transparent',
        transform: 'scale(1.1)',
        // color: 'inherit',
      },
    },
  }),
};
