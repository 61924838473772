import { useDispatch } from "react-redux"
import { ProductEditApi } from "../api/products";

export const useProductActions = () => {
  const dispatch = useDispatch();

  const toggleFavourite = ({ id, favourite }) => {
    dispatch(ProductEditApi({
      id,
      is_favourite: favourite,
    }))
  }
  
  return {
    toggleFavourite,
  }
}