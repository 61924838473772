import { useContext } from "react";
import { FilterContext } from "../contexts";
import { ClickablePill } from "./";
import { icons } from "../assets";
import _ from "lodash";

const { NEXT_ICON, PREV_ICON } = icons;

export default function GlobalFilter() {
  const filterCtx = useContext(FilterContext);


  if (!filterCtx) return null;
  const { globalFilters, clearGlobalFilters } = filterCtx;
  let filters = [];
  _.forEach(globalFilters?.orderedFilters, (filterItem, index) => {
    let { list, key} = filterItem;
    if (list && list.length > 0) {
      let selectedList = _.filter(list, (item) => item.selected);
      if (selectedList?.length > 0) {
        _.forEach(selectedList, (item) => {
          const alreadyInList = _.find(filters, (f) => f.key === item.key);
          if (!alreadyInList) {
            filters.push({
              type: key,
              key: `pillFilter${_.startCase(key)}${item.id}${_.startCase(
                item.name
              )}`,
              value: item.id,
              label: item.name,
            });
          }
        });
      }
    }
  });
  if (filters.length < 1) return null;
  return (
    <div className="container-fluid px-3">
      <div className="pb-3  header-name-of-my-projectbtns">
        <div className="container-fluid px-0">
          <div className="d-flex">
            <div className="col-sm previouscol mt-1">
              <div className="previousarrow">
                <img src={PREV_ICON} alt="" />
              </div>
            </div>
            <div className="row scl">
              {filters && filters.length > 0 && filters.map((filter, i) => {
                return (
                  <ClickablePill
                    key={filter.key}
                    title={filter.type}
                    label={filter.label}
                    onClick={() => filterCtx.selectGlobalFilter(filter.type, filter.value)}
                  />
                );
              })}
            </div>
            <div className="col-sm">
              <div role="button" className="clrbtn" onClick={() => clearGlobalFilters()}>
                <p className="text-nowrap">Clear All</p>
              </div>
            </div>
            <div className="col-sm previouscol mt-1">
              <div className="nextarrow">
                <img src={NEXT_ICON} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
