const DownloadIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0101 19.1998H9.99148C6.35464 19.1998 4.80078 17.6459 4.80078 14.0091V9.9905C4.80078 6.35367 6.35464 4.7998 9.99148 4.7998H13.3403C13.4569 4.7998 13.565 4.84082 13.6508 4.90904C13.6662 4.92085 13.6811 4.93356 13.6953 4.94715L19.0534 10.3053C19.0916 10.3434 19.1228 10.3874 19.1465 10.4351C19.1812 10.5031 19.2008 10.5797 19.2008 10.6603V14.0091C19.2008 17.6459 17.6469 19.1998 14.0101 19.1998ZM18.1961 11.1626V14.0091C18.1961 17.0967 17.0977 18.1952 14.0101 18.1952H9.99148C6.90385 18.1952 5.80543 17.0967 5.80543 14.0091V9.9905C5.80543 6.90287 6.90385 5.80446 9.99148 5.80446H12.838V7.98119C12.838 10.2718 13.7288 11.1626 16.0194 11.1626H18.1961ZM9.96491 15.8502C9.97367 15.8507 9.98251 15.851 9.99148 15.851L10.0079 15.8507C10.1233 15.847 10.2377 15.8045 10.3312 15.7182C10.3364 15.7135 10.3415 15.7086 10.3465 15.7036L11.686 14.3641C11.8802 14.1699 11.8802 13.8484 11.686 13.6541C11.4918 13.4599 11.1703 13.4599 10.976 13.6541L10.4938 14.1364V11.33C10.4938 11.0554 10.2661 10.8277 9.99148 10.8277C9.71687 10.8277 9.48915 11.0554 9.48915 11.33V14.1364L9.00688 13.6541C8.81265 13.4599 8.49116 13.4599 8.29693 13.6541C8.1027 13.8483 8.1027 14.1698 8.29693 14.364L9.61222 15.6793C9.64862 15.7213 9.69219 15.7572 9.74167 15.7846C9.81141 15.8249 9.88782 15.8463 9.96491 15.8502ZM13.8426 7.98119V6.5144L17.4862 10.1579H16.0194C14.2914 10.1579 13.8426 9.70919 13.8426 7.98119Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DownloadIcon;
