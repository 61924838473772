import {createSlice} from "@reduxjs/toolkit";


const sidebar = createSlice({
    name: 'sidebar',
    initialState: {
       labels: [],
       query:[],
       labelCheck : [],
       filtersData: null,
       filterValues: [],
       filtersByKey: {},
    },
    reducers: {
        setLables: (state, {payload}) => {
            state.labels = payload; // seems like we need to remove it
            state.filtersData = payload;
        },
        setFilters: (state, { payload }) => {
            state.filtersByKey[payload.key] = payload.filters;
        },
        setQueryFilter:(state,{payload}) => {
            state.query = payload
        },
        setLabelCheck:(state,{payload}) => {
            state.labelCheck = payload
        },
        changeSideFilter: (state, { payload }) => {
            state.filterValues = state.filterValues.includes(payload)
                ? state.filterValues.filter((x) => x !== payload)
                : [...state.filterValues, payload];
        },
        resetFilters: (state) => {
            state.filtersData = null;
            state.filterValues = [];
        }
       
    }
})

export const {setLables,setQueryFilter,setLabelCheck, changeSideFilter, resetFilters, setFilters} = sidebar.actions;
export const sidebarSelector = state => state.sidebar;
export const sidebarReducer = sidebar.reducer;
export default sidebarReducer


