import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLightColorPopup,
  curatorLightSelector,
  openLightColorPopup,
  setSelectedLightId,
} from "../../../../../redux/slicers/admin/curatorLightSlicer";
import {
  syncLightFromUnity,
  updateLight,
  curatorActions,
} from "../../../../../redux/slicers/admin/curatorSlicer";
import { useInfoDialogaActions } from "../../../../../hooks/useInfoDialogaActions";
import { useUnityContext } from "../../../../container/unityContainer";
import { toUnityFixed } from "../../../../../helpers/unityHelper";
import { undoRedo } from "../../UndoRedo/UndoRedo";
import { updateByPath } from "../../../../../helpers/jsHelper";

export const useUnityLight = () => {
  const dispatch = useDispatch();
  const unityContext = useUnityContext();
  const { openInfoDialog } = useInfoDialogaActions();
  const selectedLightId = useSelector(
    (state) => curatorLightSelector(state).selectedLightId
  );

  const selectLight = (light) => {
    unityContext.lightModule.SelectLightObjectReceiver(light.id);
  };

  const toggleLightEnabled = (light) => {
    const newActive =!light.enableInRendering
    setLightEnabled(light, newActive);

    undoRedo.light.toggleLight({ id: light.id, enabled: newActive })
  };

  const setLightEnabled = (light, active) => {
    if (!active) {
      dispatch(setSelectedLightId(null))
    }

    unityContext.lightModule.UpdateEnableInRenderingReceiver(
      light.id,
      active
    );
    dispatch(
      updateLight({
        ...light,
        enableInRendering: active,
      })
    );
  }


  const openColorPopup = (light, type, color) => {
    dispatch(openLightColorPopup({ light, type, color }));
  };

  const closeColorPopup = (light, type, color) => {
    dispatch(closeLightColorPopup());

    if (light) {
      // reset light while closing popup
      updateLightColor(light, type, light.lightDetails[type].lightColor);
    }
  };

  const updateLightColor = (light, type, color) => {
    const updatedLight = {
      ...light,
      lightDetails: {
        ...light.lightDetails,
        [type]: {
          ...light.lightDetails[type],
          lightColor: color,
        },
      },
    };
    unityContext.lightModule.UpdateColorReceiver(color);
    dispatch(updateLight(updatedLight));
  };

  const modifyLight = (light, name, value) => {
    // TODO: handle number values here
    const updatedLight = updateByPath(light, name, value);
    
    switch (name) {
      case "lightDetails.Area.lightColor":
      case "lightDetails.Direction.lightColor":
      case "lightDetails.Spot.lightColor":
      case "lightDetails.Point.lightColor":
        unityContext.lightModule.UpdateColorReceiver(value);
        break;
      case "lightDetails.Area.intensity":
      case "lightDetails.Direction.intensity":
      case "lightDetails.Spot.intensity":
      case "lightDetails.Point.intensity":
        unityContext.lightModule.UpdateIntensityReceiver(parseFloat(value));
        break;
      case "lightType":
        unityContext.lightModule.UpdateLightTypeReceiver(updatedLight);
        break;
      case "lightDetails.Area.size":
      case "lightDetails.Point.size":
      case "lightDetails.Direction.size":
      case "lightDetails.Spot.size":
        unityContext.lightModule.UpdateSizeReceiver(parseFloat(value));
        break;
      case "visibleInReflection":
        unityContext.lightModule.UpdateVisibleInReflectionReceiver(value);
        break;
      case "spotAngle":
        unityContext.lightModule.UpdateSpotAngleReceiver(parseFloat(value));
        break;
      case "blendSpot":
        unityContext.lightModule.UpdateBlendSpotReceiver(parseFloat(value));
        break;
      case "lightDetails.Spot.blurShadow":
        unityContext.lightModule.UpdateSizeReceiver(parseFloat(value));
        break;
      case "sizeWidth":
        if (typeof value === 'string' && value.trim() === '') break;
        updatedLight.sizeWidth = toUnityFixed(value);
        unityContext.lightModule.UpdateAreaWidthReceiver(updatedLight.sizeWidth);
        break;
      case "sizeHeight":
        if (typeof value === 'string' && value.trim() === '') break;
        updatedLight.sizeHeight = toUnityFixed(value);
        unityContext.lightModule.UpdateAreaHeightReceiver(updatedLight.sizeHeight);
        break;
      default:
        throw new Error("Missing handler for " + name);
    }

    dispatch(updateLight(updatedLight));
  };


  const createChangeHandler = (light) => (event) => {
    let { name, value, type, checked } = event.target;
    value = type === 'checkbox' ? checked : value;
    modifyLight(light, name, value)
  }

  const addLight = (lightName) => {
    unityContext.lightModule.GenerateLightReceiver(lightName);
  };

  const toggleEdit = (light) => {
    if (selectedLightId === light.id) {
      dispatch(setSelectedLightId(null));
    } else {
      dispatch(setSelectedLightId(light.id));
      selectLight(light);
    }
  };

  const deleteLight = (light) => {

    openInfoDialog({
      title: "Delete",
      description: `Do you want to delete ${light.lightName}`,
      cancelButtonText: "No",
      buttonText: "Yes",
      onButtonClick: () => {
        unityContext.lightModule.OnDeleteLightReceiver(light.id);
        dispatch(curatorActions.deleteLight(light.id));
      },
    });
  };

  return {
    addLight,
    selectLight,
    toggleEdit,
    createChangeHandler,
    deleteLight,
    toggleLightEnabled,
    openColorPopup,
    closeColorPopup,
    updateLightColor,
    setLightEnabled,
    modifyLight,
  };
};
