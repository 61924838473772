import { useDispatch } from "react-redux";
import { curatorLightActions } from "../redux/slicers/admin/curatorLightSlicer";
import { curatorMetadataActions } from "../redux/slicers/admin/curatorMetadataSlicer";
import { curatorActions, setCuratorLoader, setSidebarSection } from "../redux/slicers/admin/curatorSlicer";
import { curatorStyleActions } from "../redux/slicers/admin/curatorStylesSlicer";
import { curatorUnityObjectActions } from "../redux/slicers/admin/curatorUnityObjectSlicer";

export const useResetCuratorState = () => {
  const dispatch = useDispatch();

  const resetCuratorState = () => {
    // reset curator state
    dispatch(setSidebarSection(null))
    dispatch(curatorLightActions.resetState());
    dispatch(curatorMetadataActions.resetState());
    dispatch(curatorActions.resetState());
    dispatch(curatorStyleActions.resetState());
    dispatch(curatorUnityObjectActions.resetState());
    dispatch(setCuratorLoader(true));
  }

  return {
    resetCuratorState,
  }
}