/* eslint-disable no-unreachable */
import PubSub from 'pubsub-js';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { importPresetCameraAngleAPI } from '../../../../../api/camera/presetCamera';
import { useUserContext } from '../../../../../contexts/UserContext';
import { useCuratorData } from '../../../../../hooks/useCuratorData';
import { importCameraPresetsSuccess, projectCameraAngleSelector, resetImportPressets, setImportPresetsLoading, setImportPressets } from '../../../../../redux/slicers/camera/projectCameraAngleSlicer';
import { getAppState } from '../../../../../redux/store';
import { useUnityContext } from '../../../../container/unityContainer';
import { AppEvent, UnityEvent } from '../../../../../helpers/unityPubSub';
import { errorToast, successToast } from '../../../../../helpers/toastHelper';
import { saveCurrentProjectCameras } from '../../../../../api/curator';
import { decimals } from '../../../../../helpers/jsHelper';



export const useImportCameraPresets = () => {
  const dispatch = useDispatch();
  const unityCotnext = useUnityContext();
  const { type, roomId, isNewProjectFlow, isOpenProjectFlow } = useCuratorData();
  const { currentUser } = useUserContext();

  const importValidPresets = async (presets) => {
    dispatch(setImportPresetsLoading(true))
    const predefinedPresetList = presets.filter((preset) => preset.isPredefinedCamera);
    let presetList = presets.filter((preset) => !preset.isPredefinedCamera);

    console.log('importValidPresets presets beginning', presets)

    if (predefinedPresetList.length > 0) {
      const cameraList = predefinedPresetList.map(transformPresetToCamera);
      console.log('importValidPresets cameraList', cameraList)
      unityCotnext.cameraModule.importPredefinedCameraFromPresets(cameraList);

      console.log('importValidPresets predefinedPresetList', predefinedPresetList);

      // TODO: wait for callbacks from unity
      const updatedCameraList = await new Promise((resolve) => {
        const waitForCameraList = (_, cameraPresetList) => {
          resolve(cameraPresetList);
        };
        PubSub.subscribeOnce(UnityEvent.PredefinedCameraDetailsReceiver, waitForCameraList);
      });

      console.log('importValidPresets updatedCameraList', updatedCameraList);

      presetList.push(...updatedCameraList);
    }

    presetList = presetList.map(transformPresetToCamera)
      .map(c => ({
        ...c,
        room: roomId,
      }))
      
    if (isNewProjectFlow) {
      const formData = new FormData();
      const projectTypeKey = type === 'project' ? 'project' : 'room';
      formData.append(projectTypeKey, roomId);
      formData.append('member', currentUser.id);
      formData.append('data', JSON.stringify(presetList));


      await dispatch(importPresetCameraAngleAPI(formData));
    } else {
      // TOOD: add cameras to list
      presetList = presetList.map(p => ({
        ...p,
        id: uuidv4()
      })),
      dispatch(importCameraPresetsSuccess(presetList))
      successToast('Your camera has been succesfully added.', 'Camera Added')
    }

    dispatch(setImportPresetsLoading(false))
    dispatch(resetImportPressets());
    
    if (!isOpenProjectFlow) {
      saveCurrentProjectCameras();
    }
  };

  const importPresets = async (presetsList) => {
    // we need to rename presets that have same names
    const cameraList = projectCameraAngleSelector(getAppState()).getProjectCameraAnglesList;
    const cameraNames = cameraList.map((c) => c.cameraName.toLowerCase());

    const validPresets = presetsList.filter(
      (sp) => !cameraNames.includes(sp.cameraName.toLowerCase())
    );
    const invalidPresets = presetsList.filter((sp) =>
      cameraNames.includes(sp.cameraName.toLowerCase())
    );

    // import valid presets
    if (invalidPresets.length === 0) {
      await importValidPresets(validPresets);
    } else {
      dispatch(setImportPressets({
        invalid: invalidPresets,
        valid: validPresets,
      }))

      const message = invalidPresets.length > 1
        ? `Camera name already exist - please rename ${invalidPresets.length} preset(s)`
        : 'Camera name already exist - please rename'

      errorToast(message)
    }
  };

  return {
    importPresets,
  };
};


const transformPresetToCamera = (preset) => {
  const p = preset;
  return {
    blurIntensity: p.blurIntensity,
    cameraHeight: p.cameraHeight,
    cameraName: p.cameraName,
    cameraRotX: p.cameraRotX,
    cameraRotZ: p.cameraRotZ,
    centerObjectxPos: p.centerObjectxPos,
    centerObjectyPos: p.centerObjectyPos,
    centerObjectzPos: p.centerObjectzPos,
    distanceToSet: p.distanceToSet,
    focalLength: p.focalLength,
    fov: p.fov,
    isCameraCorrection: p.isCameraCorrection,
    isPredefinedCamera: p.isPredefinedCamera,
    isdepthOfField: p.isdepthOfField,
    lensShiftY: p.lensShiftY,
    resoX: p.resoX,
    resoY: p.resoY,
    selectedObjectName: p.selectedObjectName,
    xPos: p.xPos,
    xTransRot: p.xTransRot,
    yPos: p.yPos,
    yTransRot: p.yTransRot,
    zPos: p.zPos,
    zTransRot: p.zTransRot,
  }
}