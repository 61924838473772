import { getProjectTypeLabel } from "../helpers/dataHelper";

export const UNITY_REF = {
  loaderUrl: '/Build/LiveFurnishBuild.loader.js',
  dataUrl: '/Build/LiveFurnishBuild.data',
  frameworkUrl: '/Build/LiveFurnishBuild.framework.js',
  codeUrl: '/Build/LiveFurnishBuild.wasm',
  streamingAssetsUrl: 'StreamingAssets',
  companyName: 'DefaultCompany',
  productName: 'Live FurnishV2',
  productVersion: '0.1',
};
export const THEME_LIGHT = 'theme-light';
export const THEME_DARK = 'theme-dark';

export const TITLE_PREFIX = 'Live Furnish';
export const NETWORK_ERROR = 'Network Error';

//ls
export const AUTH_TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const MEMBER_ID = 'id';
export const THEME = 'theme';
export const CURRENT_USER = 'current_user';
export const EMAIL_VERIFIED = 'is_email_verified';
export const LATEST_TNC_ACCEPTED = 'latest_tnc_accepted';
export const IN_FULL_SCREEN = 'in_full_screen'

export const LIGHT_TOAST_OPTIONS = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  icon: false
};

export const DARK_TOAST_OPTIONS = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  theme: 'dark',
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  icon: false
};

export const CURATOR_DB = 'curator';
export const STORAGE_DB = '/idbfs';

export const CONVERT_IN_METER = 39.37;
export const CONVERT_IN_INCHES = 39.37;
export const TABLE_HEADER_SCROLL_THRESHOLD = 50;
// export const  FILTER_OPTIONS = [
//     { text: "ROOM", value: "1" },
//     { text: "LIFESTYLEROOM", value: "2" },
//     { text: "SILOROOM", value: "3" },
//     { text: "VIGNETTEROOM", value: "4" },
//     { text: "THREESIXTYROOM", value: "5" },
//     { text: "VIDEOROOM", value: "6" },
//     { text: "CONFIGURATORROOM", value: "7" },
//     { text: "AUGMENTED_REALITYROOM", value: "8" },
//   ];

// export const SORT_BY = [
//     {text:'Name',value:'name'},
//     {text:'Old',value:'created_at'},

// ]

export const CURATOR_TYPE = {
  ROOM: 'room',
  PROJECT: 'project',
}

export const FILTER_OPTIONS = [
  { label: 'Filter by' },
  { label: 'ROOM', value: '1' },
  { label: 'LIFESTYLEROOM', value: '2' },
  { label: 'SILOROOM', value: '3' },
  { label: 'VIGNETTEROOM', value: '4' },
  { label: 'THREESIXTYROOM', value: '5' },
  { label: 'VIDEOROOM', value: '6' },
  { label: 'CONFIGURATORROOM', value: '7' },
  { label: 'AUGMENTED_REALITYROOM', value: '8' },
];

export const SORT_BY = [
  { label: 'Sort by' },
  { label: 'Oldest first', value: 'created_at' },
  { label: 'Name:A-Z', value: '2' },
  { label: 'Name:Z-A', value: '3' },
];

export const PROJECT_TYPE = {
    ROOM: '1',
    LIFESTYLE: '2',
    SILO: '3',
    VIGNETTE: '4',
    THREESIXTY: '5',
    VIDEO: '6',
    CONFIGURATOR: '7',
    AUGMENTED_REALITY: '8',
}

export const projectTypeDropdownOptions = [
    {
      label: getProjectTypeLabel(PROJECT_TYPE.ROOM),
      value: PROJECT_TYPE.ROOM,
    },
    {
      label: getProjectTypeLabel(PROJECT_TYPE.LIFESTYLE),
      value: PROJECT_TYPE.LIFESTYLE,
    },
    {
      label: getProjectTypeLabel(PROJECT_TYPE.SILO),
      value: PROJECT_TYPE.SILO,
    },
    {
      label: getProjectTypeLabel(PROJECT_TYPE.VIGNETTE),
      value: PROJECT_TYPE.VIGNETTE,
    },
    {
      label: getProjectTypeLabel(PROJECT_TYPE.THREESIXTY),
      value: PROJECT_TYPE.THREESIXTY,
    },
    {
      label: getProjectTypeLabel(PROJECT_TYPE.VIDEO),
      value: PROJECT_TYPE.VIDEO,
    },
    {
      label: getProjectTypeLabel(PROJECT_TYPE.CONFIGURATOR),
      value: PROJECT_TYPE.CONFIGURATOR,
    },
    {
      label: getProjectTypeLabel(PROJECT_TYPE.AUGMENTED_REALITY),
      value: PROJECT_TYPE.AUGMENTED_REALITY,
    },
  ]

  export const CATEGORY_FILTER_NAME = 'Category';
  export const CATEGORY_FILTER_TYPE = 1;
  export const CATEGORY_FILTER_ID = 9;

  export const STYLE_FILTER_NAME = 'Style';
  export const STYLE_FILTER_TYPE = 1;
  export const STYLE_FILTER_ID = 12;
