import _ from 'lodash';
import { TeamMembersFilter, Checkbox, StyledSelect } from '.';

export default function ActionFilters({
  sortProps,
  filterProps,
  selectedProps,
  showTeamMemberFilter,
  searchBar,
  hasData,
  loading,
}) {
  return (
    <div className="filter_container action-filters">
      <div className="fc-left d-flex">
        {hasData && filterProps && filterProps.data && filterProps.data.length > 0 && (
          <div className="minWidth165 mx-2">
            <StyledSelect
              value={filterProps.value}
              onChange={filterProps.onChange}
              options={filterProps.data}
              placeholder={'Filter by'}
              disabled={loading}
            />
          </div>
        )}
        {hasData && sortProps && sortProps.data && sortProps.data.length > 0 && (
          <div className="minWidth165 mx-2">
            <StyledSelect
              value={sortProps.value}
              onChange={sortProps.onChange}
              options={sortProps.data}
              placeholder={'Sort by'}
              disabled={loading}
            />
          </div>
        )}
      </div>
      <div className="fc-right d-flex align-items-center">
        {hasData && selectedProps && (
          <div className="me-4">
            <Checkbox
              selected={selectedProps.allSelected}
              onChange={() => selectedProps.onSelectAll(!selectedProps.allSelected)}
              className="selectAll"
              label="Select All"
              disabled={loading}
            />
          </div>
        )}
        {showTeamMemberFilter && <TeamMembersFilter loading={loading} />}
      </div>
    </div>
  );
}
