const CameraIcon = (props) => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56341 20.4955H12.5842C16.4126 20.4955 18.0076 19.0666 18.1387 15.439L19.8593 16.6441C20.588 17.153 21.2241 17.3149 21.7215 17.3149C22.1495 17.3149 22.4964 17.1993 22.7162 17.072C23.1904 16.8291 24.0001 16.1583 24.0001 14.4812V6.00304C24.0001 4.32593 23.1904 3.66665 22.7162 3.41219C22.242 3.16929 21.2357 2.8917 19.8593 3.85171L18.1387 5.05678C18.0077 1.42905 16.4127 0 12.5842 0H5.56341C1.56145 0 0 1.56145 0 5.56341V14.9321C0 17.6155 1.45736 20.4955 5.56341 20.4955ZM18.1476 13.3015V7.19432L20.8656 5.2975C21.3861 4.93894 21.7562 4.90424 21.9066 4.98521C22.0569 5.06617 22.242 5.37846 22.242 6.01461V14.4812C22.242 15.1057 22.0685 15.4296 21.9066 15.5106C21.7562 15.5915 21.3861 15.5568 20.8656 15.1983L18.1476 13.3015ZM1.75808 5.56341C1.75808 2.54459 2.54459 1.75808 5.56341 1.75808H12.5842C15.603 1.75808 16.3895 2.54459 16.3895 5.56341V14.9321C16.3895 17.9509 15.603 18.7375 12.5842 18.7375H5.56341C2.25543 18.7375 1.75808 16.3548 1.75808 14.9321V5.56341Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CameraIcon;
