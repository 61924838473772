import { uniqueId } from "lodash";
import {
  API_ROOT_URL,
  MATERIAL_API,
  MATERIAL_COLOR_CODE_API,
} from "../../config/constant/apiConstant";
import api from "../../helpers/axiosHelper";
import { objectToFormData, objectToSearchParams } from "../../helpers/jsHelper";
import { LOADING_KEY } from "../../helpers/reduxHelper";
import { errorToast, successToast } from "../../helpers/toastHelper";
import { paginatedColorActions } from "../../redux/slicers/admin/paginatedColorSlicer";
import { setFilters } from "../../redux/slicers/admin/sidebarSlicer";
import { setMaterialData } from "../../redux/slicers/materials/materialsSlicer";
import { paginatedMaterialsActions } from "../../redux/slicers/materials/paginatedMaterialSlicer";
import { createGetListAction } from "../products";

/**
 * @method GET
 * @desc Fetch list of materials for the user
 */
export const MaterialFetchApi = (filters) => (dispatch) => {
  let API_URL = MATERIAL_API;

  API_URL = filters?.search ? `${API_URL}?search=${filters.search}` : API_URL;
  API_URL = filters?.sort ? `${API_URL}?sort=${filters.sort}` : API_URL;

  api()
    .root(API_ROOT_URL)
    .get(API_URL)
    .success((data) => dispatch(setMaterialData(data)))
    .error((error) => console.error("Error occured: ", error.message))
    .send();
};

export const MaterialAddToFavouriteApi = (formData, id) => (dispatch) => {
  dispatch(paginatedMaterialsActions.setItemLoading({
    id,
    key: LOADING_KEY.FAVOURITE,
    value: true,
  }))
  
  api()
    .root(API_ROOT_URL)
    .patch(`${MATERIAL_API}${id}/`)
    .data(formData)
    .success((data) => {
      dispatch(paginatedMaterialsActions.update(data));
      
      if (data.is_favourite) {
        successToast("Added To Favourite");
      } else {
        successToast("Removed From Favourite");
      }
    })
    .error((e) => {
      errorToast(e.message);
    })
    .finally(() => {
      dispatch(paginatedMaterialsActions.setItemLoading({
        id,
        key: LOADING_KEY.FAVOURITE,
        value: false,
      }))
    })
    .send();
};

export const getMaterialListApi = createGetListAction({
  endpoint: MATERIAL_API,
  actions: paginatedMaterialsActions,
})

// export const getMaterialListApi =
//   ({ page, perPage, sort, ...rest }) =>
//   (dispatch) => {
//     const searchParams = objectToSearchParams({ page, perPage, sort, ...rest });
//     const API_URL = `${MATERIAL_API}?${searchParams}`;
//     const requestId = uniqueId();

//     dispatch(paginatedMaterialsActions.fetchListStart({ requestId }));

//     api()
//       .root(API_ROOT_URL)
//       .get(API_URL)
//       .success((data) => {
//         dispatch(
//           paginatedMaterialsActions.fetchListSuccess({
//             page: page,
//             data,
//             requestId,
//           })
//         );
//         dispatch(setFilters({ filters: data.sidefilter, key: "material" }));
//       })
//       .error((error) => {
//         // TODO: add logger
//         console.error(error);
//         dispatch(
//           paginatedMaterialsActions.fetchListFailed({ error, requestId })
//         );
//       })
//       .send();
//   };

export const getMaterialColorCodeListApi =
  ({ page, perPage, sort, ...rest }) =>
  (dispatch) => {

    const searchParams = objectToSearchParams({
      page,
      per_page: perPage,
      sort,
      ...rest,
    });

    const API_URL = `${MATERIAL_COLOR_CODE_API}?${searchParams}`;
    const requestId = uniqueId();

    dispatch(paginatedColorActions.fetchListStart({ requestId }));

    api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => {
        dispatch(
          paginatedColorActions.fetchListSuccess({
            page: page,
            data,
            requestId,
          })
        );
      })
      .error((error) => {
        // TODO: add logger
        console.error(error);
        dispatch(paginatedColorActions.fetchListFailed({ error, requestId }));
      })
      .send();
  };

/**
 * @method POST
 * @desc Add color
 */
export const ColorCodeAddApi =
  ({ color, memberId }, cb) =>
  (dispatch) => {
    const formData = objectToFormData({
      color_value: color,
      member: memberId,
    });
    const temporaryId = uniqueId("temp_id_");

    dispatch(
      paginatedColorActions.addStart({
        data: {
          id: temporaryId,
          color_value: color,
          member: memberId,
          isTemporaryItem: true,
        },
      })
    );

    api()
      .root(API_ROOT_URL)
      .post(MATERIAL_COLOR_CODE_API)
      .data(formData)
      .success((res) => {
        dispatch(
          paginatedColorActions.addSuccess({
            temporaryId,
            data: res.data,
          })
        );
      })
      .error((e) => {
        errorToast("Some error occured");
        // TODO: work on this -> API never calls error if it's a network error or some other specific error
        dispatch(
          paginatedColorActions.addFailed({
            temporaryId,
            error: e,
          })
        );
      })
      .send(cb);
  };

/**
 * @method DELETE
 * @desc Delete color
 */
export const ColorCodeDeleteApi = (colorId, cb) => (dispatch) => {
  dispatch(
    paginatedColorActions.deleteStart({
      itemId: colorId,
    })
  );

  api()
    .root(API_ROOT_URL)
    .delete(`${MATERIAL_COLOR_CODE_API}${colorId}/`)
    .success((res) => {
      dispatch(
        paginatedColorActions.deleteSuccess({
          itemId: colorId,
        })
      );
    })
    .error((e) => {
      errorToast("Some error occured");
      // TODO: work on this -> API never calls error if it's a network error or some other specific error
      dispatch(
        paginatedColorActions.deleteFailed({
          itemId: colorId,
          error: e,
        })
      );
    })
    .send(cb);
};
