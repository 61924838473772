import Slider from "react-slick";
// import ThumbnailContainer from '../container/ThumbnailContainer';
import ThumbnailCard from '../card/ThumbnailCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const HomeCardCarousel = ({ data, time = 3000, path = null }) => {
    var config = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: true,
        // variableWidth: true
        // responsive: [
        //     {
        //         breakpoint: 767,
        //         settings: {
        //             slidesToShow: 1
        //         }
        //     },
        //     {
        //         breakpoint: 991,
        //         settings: {
        //             slidesToShow: 2
        //         }
        //     },
        //     {
        //         breakpoint: 1024,
        //         settings: {
        //             slidesToShow: 3
        //         }
        //     },
        //     {
        //         breakpoint: 1430,
        //         settings: {
        //             slidesToShow: 4
        //         }
        //     }
        // ]
    };

    return (
        <Slider {...config}>
            {
                data?.results !== undefined && data?.results.map((item, i) => <ThumbnailCard key={item.id} thumbnailData={item} path={path} />)

            }
        </Slider>
    )
}

export default HomeCardCarousel