import api from "./axiosHelper";
import { API_ROOT_URL } from "../config/constant/apiConstant";
import { objectToSearchParams } from "./jsHelper";

export const createListApiRequest =
  ({
    url,
    onStart,
    onSuccess,
    onError,
    startAction,
    successAction,
    errorAction,
  }) =>
  ({ search, sort = "-created_at", perPage, page, ...rest } = {}) =>
  (dispatch) => {
    const searchParams = objectToSearchParams({
      search,
      sort,
      per_page: perPage,
      page,
      ...rest
    });
    const API_URL = `${url}?${searchParams}`;

    onStart?.(dispatch);
    startAction && dispatch(startAction());

    api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => {
        onSuccess?.({
          data,
          dispatch,
          args: {
            search,
            sort,
            perPage,
            page,
          },
        });

        successAction &&
          dispatch(
            successAction({
              page,
              data,
            })
          );
      })
      .error((error) => {
        // TODO: add logger
        console.error(error);
        onError?.(error, dispatch);
        errorAction && dispatch(errorAction(error));
      })
      .send();
  };
