import React from 'react';
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Image,
  Table,
} from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import api, { apiFetch, apiPost } from '../helpers/axiosHelper';
import {
  API_ROOT_URL,
  CHECK_ORG_NAME,
  CREATE_ORGANIZATION,
  apiNotificationSettings,
  apiUpdateNotificationSettings,
} from '../helpers/api';
import Switch from '../components/admin/switch/index';
import { ToggleRow } from "./";
import _ from 'lodash';

const settings = [
  { label: 'Render status', app: "render_status_app", email: "render_status_email", value: false },
  { label: 'Preview Status', app: "preview_app", email: "preview_email", value: false },
  { label: 'Requests status & comments', app: "request_status_and_comments_app", email: "request_status_and_comments_email", value: false },
  { label: 'Shared files', app: "shared_files_app", email: "shared_files_email", value: false },
  { label: 'Updates/Announcement from Live Furnish', app: "update_or_announcement_from_live_furnish_app", email: "update_or_announcement_from_live_furnish_email", value: false}
]

export default function Notifications() {
  const [emailToggle, setEmailToggle] = useState(false);
  const [appToggle, setAppToggle] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({});

  useEffect(() => {
    getNotificationSettings();
  }, []);

  function getNotificationSettings() {
    apiNotificationSettings().then((response) => {
      setNotificationSettings(response.data);
    });
  }

  const onToggle = (tag, checked) => {           
    let formData = new FormData();
    formData.append(tag, checked.target.checked);
    apiUpdateNotificationSettings(formData).then((response) => {
      setNotificationSettings(response.data); 
    });
  };

  const allAppToggle = (tag, checked) => {        
    let formData = new FormData();
    formData.append('render_status_app', checked.target.checked);
    formData.append('preview_app', checked.target.checked);
    formData.append('request_status_and_comments_app', checked.target.checked);
    formData.append('shared_files_app', checked.target.checked);
    formData.append('update_or_announcement_from_live_furnish_app', checked.target.checked);
    apiUpdateNotificationSettings(formData).then((response) => {
      setNotificationSettings(response.data);
    });
  };

  const allEmailToggle = (tag, checked) => {
    let formData = new FormData();
    formData.append('render_status_email', checked.target.checked);
    formData.append('preview_email', checked.target.checked);
    formData.append('request_status_and_comments_email', checked.target.checked);
    formData.append('shared_files_email', checked.target.checked);
    formData.append('update_or_announcement_from_live_furnish_email', checked.target.checked);
    apiUpdateNotificationSettings(formData).then((response) => {
      setNotificationSettings(response.data);
    });
  };

  const allEmails = _.chain(notificationSettings)
    .reduce((result, value, key) => {
      if (_.endsWith(key, '_email')) {
        return [...result, value];
      }
      return result;
    }, [])
    .every((o) => o)
    .value();

  const allApp = _.chain(notificationSettings)
    .reduce((result, value, key) => {
      if (_.endsWith(key, '_app')) {
        return [...result, value];
      }
      return result;
    }, [])
    .every((o) => o)
    .value();

  return (
    <>
      <div className="bg-container">
        <div className="notif-container">
          <div className="notif-heading">
            {' '}
            <h1>What would you like to receive?</h1>
          </div>

          <p>We can keep you informed about certain activities.</p>
          <div className="notification-manage d-flex flex-column">
            <div className="notification-type">
              <span className="notification-email-label" style={{ marginRight: '2.5em' }}>
                Email
              </span>
              <span style={{ marginRight: '1em' }}>Application</span>
            </div>
            <div className="table-layout">
              <Table striped>
                <tbody>
                  <ToggleRow name="Activate all" toggle1={{
                    checked: appToggle || allApp,
                    toggleId: 'allAppToggle',
                    onChange: allAppToggle
                  }}toggle2={{
                    checked: emailToggle || allEmails,
                    toggleId: 'allEmailToggle',
                    onChange: allEmailToggle
                  }} />                
                  {_.map(settings, (item, index) => {
                    return (                
                      <>      
                      <ToggleRow 
                      name={item.label}
                      index={index}
                      toggle1={{
                        onChange: onToggle,
                        toggleId: item.app,
                        checked: notificationSettings[item.app],
                        tag: item.app
                      }}
                      toggle2={{
                        onChange: onToggle,
                        toggleId: item.email,
                        checked: notificationSettings[item.email],
                        tag: item.email
                      }}                      
                      />                      
                      </>
                    );
                  })}                
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
