import cx from 'classnames';
import { useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { updateByPath } from '../../../../../helpers/jsHelper';
import Button from '../../../../Button/Button';
import { InfoIcon } from '../../../../icons/InfoIcon';
import { Pagination } from '../../../../Pagination/Pagination';
import { ColorInput } from '../../FormElements/ColorInput';
import FormRange from '../../FormElements/FormRange';
import { NumberInput } from '../../NumberInput';
import { undoRedo } from '../../UndoRedo/UndoRedo';
import { UndoRedoCheckbox } from '../../UndoRedoCheckbox';
import { UndoRedoInput } from '../../UndoRedoInput';
import styles from './LightForm.module.scss';
import { useUnityLight } from './useUnityLight';

export const LightForm = ({ light, showButtons, onCancel, onSubmit }) => {
  const { createChangeHandler, openColorPopup, modifyLight } = useUnityLight();

  const LIGHT_TYPE = {
    POINT: 'Point',
    AREA: 'Area',
    SUN: 'Direction',
    SPOT: 'Spot',
  };

  const {
    lightName,
    lightType,
    lightDetails: { Point, Area, Direction, Spot },
    visibleInReflection,
    sizeHeight,
    sizeWidth,
    spotAngle,
    blendSpot,
  } = light;

  const handleTabClick = (selectedLightType) => () => {
    modifyLight(light, 'lightType', selectedLightType);
    undoRedo.light.updateLight({
      name: 'lightType',
      prevValue: lightType,
      nextValue: selectedLightType,
    })
  };

  const handleChange = createChangeHandler(light);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form className={styles.form}>
      <Pagination fullWidth className="mb-3">
        <Pagination.Item
          onClick={handleTabClick(LIGHT_TYPE.POINT)}
          active={lightType === LIGHT_TYPE.POINT}>
          Point
        </Pagination.Item>
        <Pagination.Item
          active={lightType === LIGHT_TYPE.AREA}
          onClick={handleTabClick(LIGHT_TYPE.AREA)}>
          Area
        </Pagination.Item>
        <Pagination.Item
          active={lightType === LIGHT_TYPE.SUN}
          onClick={handleTabClick(LIGHT_TYPE.SUN)}>
          Sun
        </Pagination.Item>
        <Pagination.Item
          active={lightType === LIGHT_TYPE.SPOT}
          onClick={handleTabClick(LIGHT_TYPE.SPOT)}>
          Spot
        </Pagination.Item>
      </Pagination>
      {lightType === LIGHT_TYPE.POINT && (
        <div className="point">
          <div className="accordion-range mb-3">
            <ColorInput
              value={Point.lightColor}
              label="Light color"
              name="lightDetails.Point.lightColor"
              readonly
              onClick={() => openColorPopup(light, LIGHT_TYPE.POINT, Point.lightColor)}
            />
          </div>

          <div className="accordion-range mb-3">
            <FormRange
              label="Intensity"
              value={Point.intensity}
              min="0"
              step="1"
              max="100"
              name="lightDetails.Point.intensity"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <div className="accordion-range mb-3">
            <FormRange
              label="Size"
              value={Point.size}
              min="0"
              step={0.1}
              max="100"
              name="lightDetails.Point.size"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <p className={styles.warning}>
            <InfoIcon />
            <span className="ms-1">Bigger light size will blur shadows.</span>
          </p>
        </div>
      )}

      {lightType === LIGHT_TYPE.AREA && (
        <div className="area">
          <div className="accordion-range mb-3">
            <ColorInput
              value={Area.lightColor}
              label="Light color"
              name="lightDetails.Area.lightColor"
              readonly
              onClick={() => openColorPopup(light, LIGHT_TYPE.AREA, Area.lightColor)}
            />
          </div>

          <div className="accordion-range mb-3">
            <FormRange
              label="Intensity"
              value={Area.intensity}
              min="0"
              step="1"
              max="100"
              name="lightDetails.Area.intensity"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <div className="row mb-3">
            <label className="form-label">Size</label>
            <div className="d-flex">
              <div className="resolution-input mb-2">
                <NumberInput
                  className="form-control"
                  step={0.01}
                  value={sizeWidth}
                  enableNegative
                  name="sizeWidth"
                  onChange={handleChange}
                  InputComponent={UndoRedoInput}
                  inputProps={{ 
                    undoRedoAction: undoRedo.light.updateLight,
                  }}
                />
              </div>
              <div className="lock-icon d-flex align-items-center px-3">
                <span>X</span>
              </div>
              <div className="resolution-input mb-2">
                <NumberInput
                  className="form-control"
                  step={0.01}
                  value={sizeHeight}
                  enableNegative
                  name="sizeHeight"
                  onChange={handleChange}
                  InputComponent={UndoRedoInput}
                  inputProps={{ 
                    undoRedoAction: undoRedo.light.updateLight,
                  }}
                />
              </div>
            </div>
          </div>
          <p className={styles.warning}>
            <InfoIcon />
            <span className="ms-1">Bigger light size will create blurred shadows.</span>
          </p>
        </div>
      )}

      {lightType === LIGHT_TYPE.SUN && (
        <div className="sun">
          <div className="accordion-range mb-3">
            <ColorInput
              value={Direction.lightColor}
              label="Light color"
              name="lightDetails.Direction.lightColor"
              readonly
              onClick={() => openColorPopup(light, LIGHT_TYPE.SUN, Direction.lightColor)}
            />
          </div>

          <div className="accordion-range mb-3">
            <FormRange
              label="Intensity"
              value={Direction.intensity}
              min="0"
              step="1"
              max="20"
              name="lightDetails.Direction.intensity"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <div className="accordion-range mb-3">
            <FormRange
              label="Blur Shadow"
              value={Direction.size}
              min="0"
              max="100"
              step={0.1}
              name="lightDetails.Direction.size"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <p className={styles.warning}>
            <InfoIcon />
            <span className="ms-1">
              Smaller value for sharp & higher value for blurred shadows.
            </span>
          </p>
        </div>
      )}

      {lightType === LIGHT_TYPE.SPOT && (
        <div className="spot">
          <div className="accordion-range mb-3">
            <ColorInput
              value={Spot.lightColor}
              label="Light color"
              name="lightDetails.Spot.lightColor"
              readonly
              onClick={() => openColorPopup(light, LIGHT_TYPE.SPOT, Spot.lightColor)}
            />
          </div>

          <div className="accordion-range mb-3">
            <FormRange
              label="Intensity"
              value={Spot.intensity}
              min="0"
              step={1}
              max="100"
              name="lightDetails.Spot.intensity"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <div className="accordion-range mb-3">
            <FormRange
              label="Spot Angle (Degree)"
              value={spotAngle}
              min="0"
              max="180"
              step={1}
              name="spotAngle"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <div className="accordion-range mb-3">
            <FormRange
              label="Blend Spot"
              value={blendSpot}
              min="0"
              step={0.1}
              max="1"
              name="blendSpot"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <div className="accordion-range mb-3">
            <FormRange
              label="Blur Shadow"
              value={Spot.size}
              min="0"
              step={0.1}
              max="100"
              name="lightDetails.Spot.size"
              onChange={handleChange}
              undoRedoAction={undoRedo.light.updateLight}
            />
          </div>
          <p className={styles.warning}>
            <InfoIcon />
            <span className="ms-1">
              Smaller value for sharp & higher value for blurred shadows.
            </span>
          </p>
        </div>
      )}
      <div className="form-check custom-checkbox d-flex align-items-center">
        <UndoRedoCheckbox
          className="form-check-input"
          checked={visibleInReflection}
          name="visibleInReflection"
          onChange={handleChange}
          undoRedoAction={undoRedo.light.updateLight}
        />
        <label className="mt-1">Visible in Reflection </label>
      </div>

      {showButtons && (
        <>
          <div className="mb-3"></div>
          <div className={`d-flex ${styles.buttons}`}>
            <Button
              color="light"
              type="button"
              className={`w-50 me-3 ${styles.btn}`}
              onClick={onCancel}>
              Cancel
            </Button>
            <Button
              color="warning"
              type="submit"
              className={`w-50 ${styles.btn}`}
              onClick={handleSubmit}>
              Add
            </Button>
          </div>
        </>
      )}
    </form>
  );
};
