export const InfoIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0008 19.1998C8.02906 19.1998 4.80078 15.9715 4.80078 11.9998C4.80078 8.02808 8.02906 4.7998 12.0008 4.7998C15.9725 4.7998 19.2008 8.02808 19.2008 11.9998C19.2008 15.9715 15.9725 19.1998 12.0008 19.1998ZM12.0008 5.80446C8.58497 5.80446 5.80543 8.58399 5.80543 11.9998C5.80543 15.4156 8.58497 18.1952 12.0008 18.1952C15.4166 18.1952 18.1961 15.4156 18.1961 11.9998C18.1961 8.58399 15.4166 5.80446 12.0008 5.80446ZM12.0008 15.1082C11.7262 15.1082 11.4985 14.8805 11.4985 14.6059V11.257C11.4985 10.9824 11.7262 10.7547 12.0008 10.7547C12.2754 10.7547 12.5031 10.9824 12.5031 11.257V14.6059C12.5031 14.8805 12.2754 15.1082 12.0008 15.1082ZM11.7463 10.1011C11.8266 10.1346 11.9137 10.1546 12.0008 10.1546C12.0879 10.1546 12.1749 10.1346 12.2553 10.1011C12.3357 10.0676 12.4093 10.0207 12.4763 9.96041C12.5366 9.89343 12.5835 9.82646 12.617 9.73939C12.6505 9.65901 12.6706 9.57195 12.6706 9.48488C12.6706 9.39781 12.6505 9.31074 12.617 9.23036C12.5835 9.14999 12.5366 9.07632 12.4763 9.00934C12.4093 8.94906 12.3357 8.90218 12.2553 8.86869C12.0946 8.80171 11.907 8.80171 11.7463 8.86869C11.6659 8.90218 11.5922 8.94906 11.5253 9.00934C11.465 9.07632 11.4181 9.14999 11.3846 9.23036C11.3511 9.31074 11.331 9.39781 11.331 9.48488C11.331 9.57195 11.3511 9.65901 11.3846 9.73939C11.4181 9.82646 11.465 9.89343 11.5253 9.96041C11.5922 10.0207 11.6659 10.0676 11.7463 10.1011Z"
        fill="currentColor"
      />
    </svg>
  );
};
