import { Nav } from 'react-bootstrap';
import { NavigationMove } from './NavigationMove';
import { NavigationFile } from './NavigationFile';

export const Navigation = ({ onLiveOnAppClick, onlyFileMenu }) => {
  return (
    <>
      <Nav className="me-auto">
        <NavigationFile onLiveOnAppClick={onLiveOnAppClick} />
        <div className="divider mx-0"></div>
        {!onlyFileMenu && <NavigationMove />}
      </Nav>
    </>
  );
};
