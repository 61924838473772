import cx from 'classnames';
import React, { useState, useEffect } from 'react';
import { ROOM1 } from '../config/constant/assetConstant';
import isEmpty from 'lodash/isEmpty';
import { ButtonGroup, Carousel, ToggleButton } from 'react-bootstrap';
import styles from '../assets/scss/modules/_previewUI.module.scss';
import { CloseIcon } from '../components/icons/CloseIcon';
import _ from 'lodash';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

export default function PreviewUIDisplay({
  theme,
  className,
  isFullscreen,
  onClose,
  title,
  actions,
  toggleFullscreen,
  slides,
  activeIndex,
  setActiveIndex,
  classNameFullscreen,
  passOptions,
  pass,
  setPass,
}) {
  const handleSelect = (selectedIndex) => setActiveIndex(selectedIndex);

  // const [clicked, setClicked] = useState(false);

  // const [offsetX, setOffsetX] = useState(0);
  // const [offsetY, setOffsetY] = useState(0);

  // useEffect(() => {
  //   const wrapper = document.querySelector('.react-transform-wrapper');
  //   const item = document.querySelector('.carousel-item.active');

  //   if (wrapper && item) {
  //     setOffsetX(wrapper.clientWidth - item.clientWidth);
  //     setOffsetY(wrapper.clientHeight - item.clientHeight);
  //   }
  // }, [clicked]);

  // useEffect(() => {
  //   console.log('OFFSET X IS ', offsetX);
  //   console.log('OFFSET Y IS ', offsetY);
  // }, [offsetX, offsetY]);

  return (
    <div
      style={isFullscreen ? { width: '100vw', height: '100vh', left: 0 } : null}
      className={cx(
        styles.previewUI,
        isFullscreen && styles.fullscreen,
        isFullscreen && classNameFullscreen,
        className,
        styles[theme]
      )}>
      {!isFullscreen && (
        <button className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </button>
      )}
      <TransformWrapper centerZoomedOut>
        <TransformComponent>
          <div
            className={cx(
              styles.carouselContainer,
              'd-flex justify-content-center carousel slide'
            )}>
            <Carousel activeIndex={activeIndex} onSelect={handleSelect} interval={null}>
              {slides &&
                slides.map((s, i) => (
                  <Carousel.Item>
                    <img src={s ? s : ROOM1} alt="..." className="img-fluid" />
                  </Carousel.Item>
                ))}
            </Carousel>
          </div>
        </TransformComponent>
      </TransformWrapper>
      {!isFullscreen && (
        <>
          <div
            className="d-flex justify-content-end p-3"
            style={{ position: 'absolute', bottom: 64, right: 0, zIndex: 999 }}>
            <ButtonGroup>
              {passOptions &&
                passOptions.map((p, i) => (
                  <ToggleButton
                    key={i}
                    id={`radio-${i}`}
                    type="radio"
                    variant="outline-primary"
                    name="pass"
                    value={p.value}
                    checked={pass === p.value}
                    onChange={(e) => setPass(e.currentTarget.value)}
                    className={pass === p.value ? 'pass-btn active' : 'pass-btn'}>
                    {p.name}
                  </ToggleButton>
                ))}
            </ButtonGroup>
          </div>

          <footer className="preview-display-footer">
            <h1 className="title">{title}</h1>
            {!isEmpty(actions) && (
              <ul className="footer-action-list ced-action">
                {_.map(actions, (a, index) => {
                  const { element, ...rest } = a;
                  const Component = element;
                  return <Component key={`action${index}}`} {...rest} />;
                })}
              </ul>
            )}
          </footer>
        </>
      )}
    </div>
  );
}
