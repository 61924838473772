import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Preview, FooterButton, SharePopup, AddToCollections } from '../common';
import { getCuratorPath } from '../helpers/urlHelper';
import { useParams } from 'react-router-dom';
import { apiGetPresetCameraAngle, apiGetMemberDetails } from '../helpers/api';
import _ from 'lodash';
import { UserContext } from '../contexts';

import { icons } from '../assets';
import { TEMPLATES_ROUTE } from '../constants/routePathConstants';

const {
  ArchiveIcon,
  CollectionIcon,
  EditIcon,
  FullScreenIcon,
  HeartLineIcon,
  HeadsetIcon,
  ShareIcon,
} = icons;

function TemplatesDetails() {
  const { id } = useParams();
  const userCtx = useContext(UserContext);
  const [data, setData] = useState([]);
  const [sidebarCards, setSidebarCards] = useState([]);
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(async () => {
    setLoading(true);
    const res = await apiGetPresetCameraAngle(id);
    if (res) {
      if (res.results) {
        setData(_.filter(res.results, (r) => r.thumbnail !== null));
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      setSidebarCards(_.map(data, (d) => ({ ...d, name: d.cameraName })));
      setSlides(_.map(data, (d) => d.file));
    }
  }, [data]);

  const actions = [
    {
      element: FooterButton,
      title: 'Edit',
      icon: EditIcon,
      clickFunction: () => window.open(getCuratorPath(id, 'room'), '_blank'),
      seq: 1,
    },
    {
      element: SharePopup,
      footerButton: true,
      icon: ShareIcon,
      title: 'Share',
      seq: 2,
    },
    {
      element: AddToCollections,
      footerButton: true,
      icon: CollectionIcon,
      title: 'Add to Collection',
      onSuccess: () => console.log('success'),
      seq: 3,
    },
    {
      element: FooterButton,
      footerButton: true,
      title: 'Favourite',
      icon: HeartLineIcon,
      // onClick: () => alert("favourite"),
      seq: 4,
    },
    {
      element: FooterButton,
      footerButton: true,
      icon: FullScreenIcon,
      title: 'Full Screen',
      seq: 5,
    },
    {
      element: FooterButton,
      footerButton: true,
      title: 'Help',
      icon: HeadsetIcon,
      // onClick: () => alert("info"),
      seq: 6,
    },
  ];

  const updatedAt = useMemo(() => {
    if (!data?.updated_at) return null;
    return new Date(data?.updated_at).getTime();
  }, [data?.updated_at]);

  return (
    <>
      <Preview
        title={data?.name}
        actions={actions}
        onClose={() => navigate(TEMPLATES_ROUTE)}
        loading={loading}
        updatedAt={updatedAt}
        userName="Sasha"
        sidebarCards={sidebarCards}
        slides={slides}
      />
      {data.length === 0 ? (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            left: 0,
            top: 0,
          }}>
          <h3>No camera angles found.</h3>
        </div>
      ) : null}
    </>
  );
}

export default TemplatesDetails;
