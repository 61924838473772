import cx from 'classnames';
import { RiCheckLine } from "react-icons/ri";
import Image from "../../../../Image/Image";
import FormRange from "../../FormElements/FormRange";
import styles from "./HDRIItem.module.scss";

export const HDRIItem = ({ expanded, hdriItem, selected, onClick, rotation, onRotationChange, intensity, onIntensityChange }) => {
  return (
    <div onClick={onClick}>
      <div className={styles.card}>
        <Image 
          src={hdriItem.file}
          placeholderWhileLoading
          ratio={2}
        />
        <p className={cx(styles.cardTitle, 'd-flex justify-content-between')}>
          {hdriItem.name}
          {selected && <RiCheckLine fontSize={20}/>}
        </p>
      </div>
      {expanded && (
        <>
          <div className="accordion-range mb-3">
            <FormRange
              label="Intensity"
              value={intensity}
              min="0"
              step="1"
              max="5"
              onChange={onIntensityChange}
            />
          </div>

          <div className="accordion-range mb-3">
            <FormRange
              label="Rotation"
              value={rotation}
              min="0"
              max="359"
              onChange={onRotationChange}
            />
          </div>
        </>
      )}
    </div>
  );
};
