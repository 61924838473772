import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  API_ROOT_URL,
  PRESET_CAMERA_ANGLE,
  PRESET_CAMERA_IMPORT,
} from '../../config/constant/apiConstant';
import api from '../../helpers/axiosHelper';
import { decimals } from '../../helpers/jsHelper';
import { successToast, errorToast } from '../../helpers/toastHelper';
import {
  addToPresetsSuccess,
  cameraAngleSelector,
  getCameraPresetAnglesList,
  setAddToPresetsLoading,
  setCameraPresetAnglesList,
  setCreateCameraPresetStatus,
  setDeleteCameraPresetStatus,
} from '../../redux/slicers/camera/cameraAngleSlicer';
import {
  importCameraPresetsSuccess,
  setImportPresetsLoading,
} from '../../redux/slicers/camera/projectCameraAngleSlicer';
import { parseCamera } from './projectCamera';

export const getPresetCameraAngleAPI =
  ({ type, roomId }) =>
  (dispatch) => {
    const query = `per_page=10000&${type}_id=${roomId}`;

    api()
      .root(API_ROOT_URL)
      .get(`${PRESET_CAMERA_ANGLE}?${query}`)
      .success((data) => {
        console.log('get preset camera angle api success: ', data.results);

        var res = [];

        data.results.forEach(function (a) {
          delete a.project;
          delete a.room;

          const tempItem = {
            ...a,
            id: a.id,
            cameraName: a.cameraName,
            fov: parseInt(a.fov),
            isCameraCorrection: a.isCameraCorrection,
            cameraHeight: decimals(a.cameraHeight, 2),
            isdepthOfField: a.isdepthOfField,
            selectedObjectName: a.selectedObjectName,
            blurIntensity: decimals(a.blurIntensity, 4),
            distanceToSet: decimals(a.distanceToSet, 4),

            resoX: Number.parseInt(a.resoX),
            resoY: Number.parseInt(a.resoY),

            focalLength: decimals(a.focalLength, 4),
            xTransRot: decimals(a.xTransRot, 4),
            yTransRot: decimals(a.yTransRot, 4),
            zTransRot: decimals(a.zTransRot, 4),
            xPos: decimals(a.xPos, 4),
            yPos: decimals(a.yPos, 4),
            zPos: decimals(a.zPos, 4),
            centerObjectxPos: decimals(a.centerObjectxPos, 4),
            centerObjectyPos: decimals(a.centerObjectyPos, 4),
            centerObjectzPos: decimals(a.centerObjectzPos, 4),
            cameraRotX: decimals(a.cameraRotX, 4),
            cameraRotZ: decimals(a.cameraRotZ, 4),
            lensShiftY: decimals(a.lensShiftY, 4),
            member: a.member,
          };

          res.push(tempItem);
        });

        console.log('res preset: ', res);

        if (data.results.length !== 0) dispatch(setCameraPresetAnglesList(res));
      })
      .error((error) => {
        console.log('get camera angle api error: ', error);
      })
      .send();
  };

export const createPresetCameraAngleAPI =
  (formData, { onSuccess } = {}) =>
  (dispatch) => {
    const headers = { 'Content-Type': 'multipart/form-data' };
    // const { getCameraPresetAnglesList } = useSelector(cameraAngleSelector)

    dispatch(setAddToPresetsLoading(true));
    api()
      .root(API_ROOT_URL)
      .post(PRESET_CAMERA_ANGLE)
      .data(formData)
      .setHeaders(headers)
      .success((data) => {
        dispatch(addToPresetsSuccess(data.data));
        // dispatch(setCreateCameraPresetStatus(data.status))
        console.log('create preset camera angle api success: ', data.data);
        successToast('Your camera has been succesfully saved.', 'Preset Saved ');
        onSuccess?.();
      })
      .error((error) => {
        // dispatch(setCreateCameraPresetStatus(error.status))

        console.log('create preset camera angle api error: ', error);
        errorToast('Unable to save camera to Preset!');
      })
      .finally(() => {
        dispatch(setAddToPresetsLoading(false));
      })
      .send();
  };

// export const updatePresetCameraAngleAPI = (formdata) => {
//     console.log(formdata);

//     // cons

//     api().root(API_ROOT_URL).post(PRESET_CAMERA_ANGLE).data(formdata).success((data)=>{

//         // dispatch(setSearchResult(data))
//         console.log("update camera angle api success: ", data)

//         // setLocalStorage("renderStatus", data.status)

//     }).error((error)=>{
//         // dispatch(setSearchResult(error))
//         console.log("update camera angle api error: ", error)

//         // setLocalStorage("renderStatus", error.status)
//     }).send()

// }

export const deletePresetCameraAngleAPI = (id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .delete(`${PRESET_CAMERA_ANGLE}${id}/`)
    .success((data) => {
      console.log('delete camera angle api success: ', true);
      // dispatch(setDeleteCameraPresetStatus(true))
      successToast('Camera preset deleted successfully!');
    })
    .error((error) => {
      console.log('delete camera angle api error: ', false);
      // dispatch(setDeleteCameraPresetStatus(false))
      errorToast('Unable to delete camera preset!');
    })
    .send();
};

export const importPresetCameraAngleAPI = (formData) => (dispatch) => {
  const headers = { 'Content-Type': 'multipart/form-data' };

  dispatch(setImportPresetsLoading(true));

  return api()
    .root(API_ROOT_URL)
    .post(PRESET_CAMERA_IMPORT)
    .data(formData)
    .setHeaders(headers)
    .success((data) => {
      const cameraList = data.data.map(parseCamera)
      dispatch(importCameraPresetsSuccess(cameraList));
      console.log('import preset camera angle api success: ', data);
      successToast('Your camera has been succesfully added.', 'Camera Added');
    })
    .error((error) => {
      // dispatch(setCreateCameraPresetStatus(error.status))
      console.log('import preset camera angle api error: ', error);
      errorToast('Unable to import camera preset!');
    })
    .finally(() => {
      dispatch(setImportPresetsLoading(false));
    })
    .send();
};
