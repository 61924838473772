import { Collapse } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { errorToast } from '../../../../../helpers/toastHelper';
import { updateLight } from '../../../../../redux/slicers/admin/curatorSlicer';
import Button from '../../../../Button/Button';
import { useUnityContext } from '../../../../container/unityContainer';
import BinIcon2 from '../../../../icons/BinIcon2';
import { SettingsIconV2 } from '../../../../icons/SettingsIconV2';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import { useUndoRedo } from '../../UndoRedoContext';
import { UndoRedoInput } from '../../UndoRedoInput';
import { LightForm } from './LightForm';
import styles from './RoomLightItem.module.scss';
import { useUnityLight } from './useUnityLight';

export const RoomLightItem = ({ light, onActiveToggle, onEditToggle, onDelete, selected }) => {
  const dispatch = useDispatch();
  const undoRedo = useUndoRedo();
  const unityContext = useUnityContext();
  const { lightName, lightType, enableInRendering } = light;

  const actions = [
    enableInRendering && {
      icon: <SettingsIconV2 />,
      onClick: onEditToggle,
    },
    {
      icon: <BinIcon2 />,
      onClick: onDelete,
    },
  ].filter(Boolean);

  const handleSave = (e) => {
    e.stopPropagation();

    if (!lightName) {
      errorToast('Please provide light name')
      return
    }
    
    unityContext.lightModule.UpdateLightNameReceiver(lightName);
    onEditToggle()
  }

  const onChange = (e) => {
    const updatedLight =  { ...light, lightName: e.target.value };
    dispatch(updateLight(updatedLight));
  }

  const saveIcon = (
    <Button className={styles.saveButton} onClick={handleSave}>
      <SettingsIconV2 />
    </Button>
  );

  return (
    <CuratorAccordion.Item eventKey={light.id}>
      <CuratorAccordion.Header
        onCheckboxChange={onActiveToggle}
        selected={enableInRendering}
        icon={selected ? saveIcon : lightType}
        actions={selected ? undefined : actions}>
        {!selected && <>{lightName}</>}

        {selected && (
          <UndoRedoInput
            type="text"
            className={`form-control ${styles.nameInpupt}`}
            id="light-input"
            name="lightName"
            value={lightName}
            onChange={onChange}
            undoRedoAction={undoRedo.light.changeLightName}
          />
        )}
      </CuratorAccordion.Header>

      <CuratorAccordion.Body>
        <LightForm light={light} />
      </CuratorAccordion.Body>
    </CuratorAccordion.Item>
  );
};
