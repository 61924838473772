import { icons } from "../assets";

const { CloseCircleIcon } = icons;

// TODO clean up the classes on this component
export default function ClickablePill(props) {
  return (
    <div
      className="col-2"
      title={props.title}
      style={{
        width: 'max-content',
      }}>
      <div className="filterCloseBtn m-1 px-2">
        <div className="bedroom-content">
          {props.label}
          <span
            className="deleteButton ms-1"
            onClick={props.onClick}>
            <CloseCircleIcon />
          </span>
        </div>
      </div>
    </div>
  );
}
