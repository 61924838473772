import Unity from 'react-unity-webgl';
import { useContext, useState, useEffect, useRef } from 'react';
import { unitycontext } from '../../../hooks/useUnityContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { setCuratorLoader, setUnityLoaded } from '../../../redux/slicers/admin/curatorSlicer';
import { useDispatch } from 'react-redux';
import {
  setCanvasFrameHeight,
  setCanvasFrameWidth,
} from '../../../redux/slicers/camera/cameraSettings';
import { OPEN_PROJECT_ID } from '../../../config/constant/curatorConstants';

export const UnityCanvasContainer = ({ data }) => {
  const dispatch = useDispatch();
  const { unityContext, handleLoad, handleLoadProject, unityTigg } = useContext(unitycontext);
  const { type, room_id } = useParams();
  const blurListenerRef = useRef();

  const handleMouseOver = (e) => {
    if (document.activeElement.tagName === 'INPUT' && ['text', 'number'].includes(document.activeElement.type)) {
      // do not focus unity if input element has focus
      blurListenerRef.current = () => {
        unityContext.send('MainController', 'WebglKeyBoard', 'True');
      }
      window.addEventListener('blur', blurListenerRef.current)
      
      return;
    }

    unityContext.send('MainController', 'WebglKeyBoard', 'True');
  };
  const handleUnityL = (e) => {
    unityContext.send('MainController', 'WebglKeyBoard', 'False');
    window.removeEventListener('blur', blurListenerRef.current)
  };


  useEffect(() => {
    return () => {
      window.removeEventListener('blur', blurListenerRef.current)
    }
  }, [])

  useEffect(() => {
    // todo: refactor this resize function
    const handleResize = (e) => {
      dispatch(setCanvasFrameWidth(window.innerWidth - 94));
      dispatch(setCanvasFrameHeight(window.innerHeight - 56));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    unityContext.on('loaded', function () {
      console.log('loaded unity');
      setTimeout(() => {
        // TODO: check why do we need this timeout
        if (type === 'project' || room_id === OPEN_PROJECT_ID) {
          handleLoadProject(data);
        } else {
          handleLoad(data);
        }

        dispatch(setCuratorLoader(true));
        dispatch(setUnityLoaded(true));
        unityContext.send('MainController', 'WebglKeyBoard', 'True');
        unityTigg();
      }, 0);
    });
  }, []);

  useEffect(() => {
    unityTrigger();
    unityContext.send('MainController', 'WebglKeyBoard', 'False');
  }, []);

  const unityTrigger = () => {
    unityContext.send('RoomModule', 'GetRoomInfo');
  };

  return (
    <div className="unity-container" onMouseLeave={handleUnityL} onMouseOver={handleMouseOver}>
      <Unity
        unityContext={unityContext}
        style={{ width: '100%', height: '100%' }}
        width="100%"
        height="100%"
      />
    </div>
  );
};
