const ArrowDownIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.0054 8.0084C18.7449 7.73027 18.3225 7.73027 18.062 8.0084L12.0008 14.4792L5.9396 8.0084C5.67908 7.73027 5.25669 7.73027 4.99617 8.0084C4.73565 8.28653 4.73565 8.73746 4.99617 9.01559L11.5302 15.9912C11.7907 16.2693 12.2131 16.2693 12.4736 15.9912C12.4848 15.9793 12.4955 15.967 12.5057 15.9545L19.0054 9.01559C19.2659 8.73746 19.2659 8.28653 19.0054 8.0084Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowDownIcon;