import cx from 'classnames';
import BootstrapButton from 'react-bootstrap/Button'
import Loader from '../Loader/Loader';
import styles from './Button.module.scss';

const Button = ({ 
  children, 
  className, 
  variant = 'filled', // filled | outline 
  color = 'primary', // 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'
  iconBefore,
  iconAfter,
  loading,
  size,
  active,
  fullWidth,
  hoverStyle, // 'secondary'
  ...rest
}) => {

  const rootClassName = cx(
    styles.root,
    className,
    loading && styles.loading,
    size === 'xlg' && styles.xlg,
    active && styles.active,
    hoverStyle && styles[`${hoverStyle}HoverStyle`],
    fullWidth && styles.fullWidth,
  )

  const v = variant === 'outline' ? `outline-${color}` : color



  return (
    <BootstrapButton {...rest} variant={v} className={rootClassName} size={size}>
      <Loader loading={loading} position="absolute" color="inherit" size={22} thickness={2} />
      {iconBefore && <div className={styles.iconBefore}>{iconBefore}</div>}
      <span className={styles.text}>{children}</span>
      {iconAfter && <div className={styles.iconAfter}>{iconAfter}</div>}
    </BootstrapButton>
  )
}

export default Button;