import { Carousel } from 'react-bootstrap';

export default function PublicCarousel({ data, time = 3000 }) {
  return (
    <Carousel
      className="rounded px-0"
      indicators={false}
      nextIcon={false}
      prevIcon={false}
      fade>
      {data &&
        data.map((item, index) => (
          <Carousel.Item interval={time} key={item.id}>
            {item && item.images && item.images.length && <img src={item.images[0].image_file} alt="slide img" />}
          </Carousel.Item>
        ))}
    </Carousel>
  );
}
