import {createSlice} from "@reduxjs/toolkit";

const team = createSlice({
    name: 'team',
    initialState: {
        showInvitePage:false,
        members:[],
        pendingInviteList:[],
        memberUpdateStatus: false,
    },
    reducers: {
        setShowInvitePage: (state, {payload}) => {
            state.showInvitePage = payload
        },
        setMembers: (state, {payload}) => {
            state.members = [...payload]
        },
        setPendingInviteList: (state, {payload}) =>{
            state.pendingInviteList = [...payload]
        },
        setMemberUpdateStatus: (state, {payload}) =>{
            state.memberUpdateStatus = payload
        }
    }
})

export const {setShowInvitePage, setMembers, setPendingInviteList, setMemberUpdateStatus} = team.actions;
export const teamSelector = state => state.team;
export const teamReducer = team.reducer;
export default teamReducer