import { useState, useEffect } from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import { icons } from '../assets';
const { CloseIcon } = icons;

export default function RightSidebar(props) {
  const [sidebarOpen, setSidebarOpen] = useState(props.initialShowOpen ? true : false);

  useEffect(() => {
    if (!props.initialShowOpen) {
      setSidebarOpen(false);
    } else if (props.initialShowOpen && !sidebarOpen) {
      setSidebarOpen(true);
    }
  }, [props]);

  function onClose() {
    setSidebarOpen(false);
    props.onClose();
  }

  return (
    <div className="rightSidebar" >
      <Offcanvas show={sidebarOpen} onHide={onClose} placement={props.placement || 'end'} className={props.className || ""}>
        <Offcanvas.Header className="align-items-top sidebarHeader">
          <Offcanvas.Title className="fullWidth">{props.title}</Offcanvas.Title>
          <Button className="sidebarCloseButton" onClick={onClose}>
            <CloseIcon />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>{props.body}</Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
