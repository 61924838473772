import { toUnityFixed } from "../../../../helpers/unityHelper"

export const parseLight = (light) => {
  return {
    ...light,
    sizeWidth: toUnityFixed(light.sizeWidth),
    sizeHeight: toUnityFixed(light.sizeHeight),
    lightDetails: {
      ...light.lightDetails,
      Area: {
        ...light.lightDetails.Area,
        size: toUnityFixed(light.lightDetails.Area.size) || 0,
      },
      Direction: {
        ...light.lightDetails.Direction,
        size: toUnityFixed(light.lightDetails.Direction.size) || 0,
      },
      Point: {
        ...light.lightDetails.Point,
        size: toUnityFixed(light.lightDetails.Point.size) || 0,
      },
      Spot: {
        ...light.lightDetails.Spot,
        size: toUnityFixed(light.lightDetails.Spot.size) || 0,
      },
    }
  }
}