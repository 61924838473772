import { DECIMAL_NUM_COUNT, MAX_RESOLUTION, MIN_RESOLUTION } from "../config/constant/unityConstants";

export const inchesToUnitySize = (inches) => {
  if (inches === 0) return 0;

  const feet = inches / 12;
  return 10 / feet;
}

export const unitySizeToInches = (unitySize) => {
  const feet = 10 / unitySize;
  return parseFloat((feet * 12).toFixed(DECIMAL_NUM_COUNT));
}

export const sizeDecimals = (size) => parseFloat(size.toFixed(2));
export const toUnityFixed = (size) => parseFloat(parseFloat(size).toFixed(2));



window.unitySizeToInches = unitySizeToInches
window.inchesToUnitySize = inchesToUnitySize

export const normalizeResSize = (size) => {
  return size > MAX_RESOLUTION
    ? MAX_RESOLUTION
    : size < MIN_RESOLUTION
      ? MIN_RESOLUTION
      : size;
}