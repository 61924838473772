import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveTab,
  SHORTCUT_TYPE,
  setActiveTab,
  setPopupOpen,
  selectPopupOpen,
} from '../../../../redux/slicers/admin/shortcutsModuleSlicer';
import { CloseIcon } from '../../../icons/CloseIcon';
import { Pagination } from '../../../Pagination/Pagination';
import styles from './CuratorShortcuts.module.scss';
import { useCuratorShortcuts } from './useCuratorShortcuts';
import { curatorShortcutsMap, curatorShortcutsSections } from './curatorShortcuts.config';
import { Col, Row } from 'react-bootstrap';
import React, { memo } from 'react';
import { selectCuratorInitialized } from '../../../../redux/slicers/admin/curatorSlicer';

export const CuratorShortcuts = memo(() => {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectActiveTab);
  const popupOpen = useSelector(selectPopupOpen);
  const curatorInitialized = useSelector(selectCuratorInitialized);

  useCuratorShortcuts();

  const activeSection = curatorShortcutsSections[activeTab];
  console.log('activeSection', activeSection);
  const toolsSelected = activeTab === SHORTCUT_TYPE.TOOLS;

  return (
    <div
      className={cx(
        styles.root,
        popupOpen && styles.visible,
        !curatorInitialized && styles.fullWidth
      )}>
      <div className="d-flex justify-content-between mb-4">
        <h2>Shortcuts</h2>

        <button
          className={styles.closeButton}
          onClick={() => {
            dispatch(setPopupOpen(false));
          }}>
          <CloseIcon />
        </button>

        <Pagination fullWidth className={styles.pagination}>
          {Object.values(SHORTCUT_TYPE).map((tabLabel) => (
            <Pagination.Item
              key={tabLabel}
              active={activeTab === tabLabel}
              onClick={() => {
                dispatch(setActiveTab(tabLabel));
              }}>
              {tabLabel}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      {activeSection && (
        <div className={styles.grid}>
          <Column shortcutsInfo={activeSection.left} />
          <Column shortcutsInfo={activeSection.middle} />
          <Column shortcutsInfo={toolsSelected ? [] : activeSection.right}>
            <CustomRightColumn activeSectionName={activeTab} shortcutsInfo={activeSection.right} />
          </Column>
        </div>
      )}
    </div>
  );
});

export const Column = ({ shortcutsInfo, children }) => {
  return (
    <div className={styles.column}>
      {shortcutsInfo.map((shortcutKey) => {
        const shortcut = curatorShortcutsMap[shortcutKey];
        return (
          <div className={styles.shortcutDetails} key={shortcutKey}>
            <div key={shortcut.name}>{shortcut.name}</div>
            <ShortcutKeys keys={shortcut.keys} />
          </div>
        );
      })}
      {children}
    </div>
  );
};

export const ShortcutKeys = ({ keys }) => {
  return (
    <div className={styles.keyList}>
      {keys.map((key, idx) => {
        return (
          <React.Fragment key={key}>
            {idx !== 0 && <span className={styles.separator}>+</span>}
            <div className={styles.key}>{key}</div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const CustomRightColumn = ({ activeSectionName, shortcutsInfo }) => {
  if (activeSectionName === SHORTCUT_TYPE.TOOLS) {
    return (
      <>
        <p>Axis Constraints when in Move, Rotate or Scale mode.</p>
        <div className={styles.highlightedContent}>
          <Column shortcutsInfo={shortcutsInfo} />
        </div>
      </>
    );
  }

  if (activeSectionName === SHORTCUT_TYPE.SELECTION) {
    return (
      <>
        <p>What is child object?</p>
        <div className={styles.highlightedContent}>
          <p>A Product is a group of multiple parts called “Child” which moves together with that product.</p>
          <p>These child objects can be customized from style panel.</p>
        </div>
      </>
    )
  }

  if (activeSectionName === SHORTCUT_TYPE.VIEW) {
    return (
      <>
        <p>Highlights on Hover?</p>
        <div className={styles.highlightedContent}>
          <p><span className={cx(styles.highlightedText, styles.orangeColor)}>Orange</span>  -    select Group</p>
          <p><span className={cx(styles.highlightedText, styles.cyanColor)}>Cyan</span>      -    select Cutomizable object</p>
        </div>
      </>
    )
  }

  return null;
};
