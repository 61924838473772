import { createSlice } from '@reduxjs/toolkit';

const curatorProjectArchive = createSlice({
  name: 'curatorProjectArchive',
  initialState: {
    file: null,
  },
  reducers: {
    setFile: (state, { payload }) => {
      state.file = payload;
    },
  },
});

export const { setFile } = curatorProjectArchive.actions;
export const curatorProjectArchiveReducer = curatorProjectArchive.reducer;
export default curatorProjectArchiveReducer;

export const selectProjectArchive = (state) => state.curatorProjectArchive.file;