import { React, useState, useRef, useEffect, useContext } from 'react';
import { Form, Card, Row, Navbar, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { BsExclamationTriangle } from 'react-icons/bs';
import { ADMIN_DASHBOARD } from '../constants/routePathConstants';
import {
  apiGetIndustries,
  apiGetIndustriesPreferences,
  apiPatchAfterRegister,
} from '../helpers/api';
import { SelectFormGroup } from '../common';
import _ from 'lodash';
import { YourInterests, UserInfo } from '../common';
import { successToast } from '../helpers/toastHelper';
import { UserContext } from '../contexts';
import TermsAndPrivacyPolicy from './TermsAndPrivacyPolicy';

const OrganizationCard = ({
  organizationNameMessage,
  setOrganizationNameMessage,
  userPreferenceData,
  setUserPreferenceData,
}) => {
  const imgPreview = useRef();
  const imagePicker = useRef();

  const userCtx = useContext(UserContext);

  useEffect(() => {
    imagePicker.current = document.querySelector('#orgImage');
  }, []);

  // useEffect(() => {
  //   if(registeredFromInvite === true) {
  //     // api call to get organization image
  //     apiGetOrganizationDetails.then((res) => {

  //     })
  //   }
  // }, [])

  const handleOrganizationInput = (e) => {
    e.preventDefault();
    let userPreferenceDataTemp = Object.assign({}, userPreferenceData);
    userPreferenceDataTemp.organization_name = e.target.value;
    setUserPreferenceData(userPreferenceDataTemp);
  };

  const handleOrganization = (e) => {
    e.preventDefault();

    if (!e.target.value || !e.target.value.trim()) {
      setOrganizationNameMessage('Organization is required');
    } else {
      setOrganizationNameMessage('');
      let userPreferenceDataTemp = Object.assign({}, userPreferenceData);
      userPreferenceDataTemp.organization_name = e.target.value.trim();
      setUserPreferenceData(userPreferenceDataTemp);
    }
  };

  const onImageSelection = (e) => {
    if (e.target.files.length > 0) {
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = imgPreview.current;
      preview.src = src;
      preview.style.display = 'block';
      let userPreferenceDataTemp = Object.assign({}, userPreferenceData);
      userPreferenceDataTemp.organization_thumb = e.target.files[0];
      setUserPreferenceData(userPreferenceDataTemp);
    }
  };

  useEffect(() => {
    if (imgPreview.current && userPreferenceData.organization_thumb) {
      imgPreview.current.src = URL.createObjectURL(userPreferenceData.organization_thumb);
    }
  }, [imgPreview]);

  // make api call to get thumbnail or organization

  function openImagePicker() {
    if (imagePicker && imagePicker.current) {
      imagePicker.current.click();
    }
  }

  const hasImageSet = userPreferenceData && userPreferenceData.organization_thumb && imgPreview && imgPreview.current && imgPreview.current.src;
  
  return (
    <Card className="border-0 p-3 customizeCards mb-xs-2 mb-sm-2 mb-md-2 mb-lg-0">
      <div className="organization-div">
        <h2 className="text-center">
          {userCtx?.currentUser?.organization_detail
            ? 'You have successfully joined'
            : 'Add your organization'}
        </h2>

        <div className="thumb-img-center m-bottom-3">
          <div className="form-input">
            <input
              type="file"
              className="form-control form-control-md placeholder-active input-box d-none"
              placeholder="Enter your organization"
              id="orgImage"
              accept="image/*"
              onChange={onImageSelection}
              ref={imagePicker}
              disabled={userCtx?.currentUser?.organization_detail}
            />
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              onClick={openImagePicker}>
              {hasImageSet ? null : (
                <span className="selectThumbnail smallSubtitles">Select thumbnail</span>
              )}
              <img id="file-ip-1-preview" ref={imgPreview} className={cx(
                {
                  "d-block": hasImageSet,
                  "d-none": !hasImageSet
                },
                "orgImagePreview")} />
              {hasImageSet ? null :<div className="orgImagePreview"/>}
            </div>
          </div>
        </div>

        {userCtx?.currentUser?.organization_detail ? (
          <b>
            <h5 className="text-center">{userCtx?.currentUser?.organization_detail?.name}</h5>
          </b>
        ) : (
          <Form.Group className="form-outline my-3 org-name-div px-5">
            <Form.Label
              className={
                organizationNameMessage ? 'title-error-color float-left' : 'title-color float-left'
              }>
              {organizationNameMessage && (
                <BsExclamationTriangle
                  style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                />
              )}
              {organizationNameMessage ? organizationNameMessage : 'Organization Name'}
            </Form.Label>
            <Form.Control
              value={userPreferenceData?.organization_name}
              type="text"
              id="form2Example18"
              name="organization_name"
              className={`form-control form-control-md placeholder-active input-box ${
                organizationNameMessage ? 'input-error' : ''
              }`}
              placeholder="Enter your organization"
              onChange={handleOrganizationInput}
              onBlur={handleOrganization}
              onFocus={() => setOrganizationNameMessage('')}
            />
          </Form.Group>
        )}
      </div>
    </Card>
  );
};

export default function CustomizeExperience({ registeredFromInvite }) {
  const userCtx = useContext(UserContext);

  const navigate = useNavigate();

  // const coming_from_invitation = location && location.state && location.state.coming_from_invitation;
  // const organization_name = location && location.state && location.state.organization_name;

  const [isTocError, setIsTocError] = useState(false);

  const [userPreferenceData, setUserPreferenceData] = useState({});
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
  const [termsAndConditionsMessage, setTermsAndConditionsMessage] = useState('');
  const [organizationNameMessage, setOrganizationNameMessage] = useState('');
  const [organizationImageMessage, setOrganizationImageMessage] = useState('');
  const [industriesPreference, setIndustriesPreference] = useState([]);
  const [objArr, setObjArr] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  // const [industries, setIndustries] = useState([]);
  const [countryMessage, setCountryMessage] = useState('');
  const [cityMessage, setCityMessage] = useState('');
  const [contactNumberMessage, setContactNumberMessage] = useState('');
  const [professionMessage, setProfessionMessage] = useState('');
  const [industryMessage, setIndustryMessage] = useState('');
  const [platformMessage, setPlatformMessage] = useState('');
  const [userProductPreferencesError, setUserProductPreferencesError] = useState(false);
  const [termsAndPolicyPageVisible, setTermsAndPolicyPageVisible] = useState(false);
  const [isOnTermsAndConditions, setIsOnTermsAndConditions] = useState(true);

  const [industryOptions, setIndustryOptions] = useState([]);

  useEffect(() => {
    apiGetIndustries().then((res) => {
      if (res) {
        const { results } = res;
        let industries = [];
        results.forEach((item) => {
          industries.push({ label: item.name, value: item.id });
        });

        setIndustryOptions(industries);
      }
    });
  }, []);

  const handleIndustryChange = (e) => {
    let userPreferenceDataTemp = Object.assign({}, userPreferenceData);
    userPreferenceDataTemp['industry'] = e;
    setUserPreferenceData(userPreferenceDataTemp);
    const industryIds = Array.isArray(e) ? e.map((x) => x.value) : [];

    apiGetIndustriesPreferences(industryIds).then((res) => {
      if (res) {
        let { results } = res;
        setIndustriesPreference(results);
      }
    });
  };

  const validate = () => {
    let termsAndConditionsError = false;
    let organizationError = false;
    let industryError = false;
    let userPreferencesError = false;
    let platformError = false;
    let professionError = false;
    let countryError = false;
    let stateError = false;
    let cityError = false;

    if (!termsAndConditionsChecked) {
      setIsTocError(true);
      setTermsAndConditionsMessage('Terms and conditions not accepted');
      termsAndConditionsError = true;
    }

    // if already part of organization (came through invite) do not need to validate the name
    if (!userCtx.currentUser?.organization_detail) {
      if (!userPreferenceData.organization_name || !userPreferenceData.organization_name.trim()) {
        setOrganizationNameMessage('Organization is required');
        organizationError = true;
      }
    }
    if (!userPreferenceData.industry || !userPreferenceData.industry.length > 0) {
      // chaning
      setIndustryMessage('Industry is required');
      industryError = true;
    }
    if (!selectedProducts || !selectedProducts.length > 0) {
      // changing
      setUserProductPreferencesError(true);
      userPreferencesError = true;
    }
    if (!userPreferenceData.usecase || !userPreferenceData.usecase.length > 0) {
      setPlatformMessage('Platform is required');
      platformError = true;
    }
    if (!userPreferenceData.profession || !userPreferenceData.profession.label) {
      setProfessionMessage('Profession is required');
      professionError = true;
    }
    if (!userPreferenceData.country || !userPreferenceData.country.label) {
      setCountryMessage('Country is required');
      countryError = true;
    }

    // not going to check for city or state because some countries do not have a city or state in the react package
    // if(!userPreferenceData.state || !userPreferenceData.state.label) {
    //   setCityMessage("State is required");
    //   stateError = true;
    // }
    // if(!userPreferenceData.city || !userPreferenceData.city.label) {
    //   setCityMessage("City is required");
    //   cityError = true;
    // }

    if (
      termsAndConditionsError ||
      organizationError ||
      industryError ||
      userPreferencesError ||
      platformError ||
      professionError ||
      countryError ||
      stateError ||
      cityError
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const payload = {
        ...userPreferenceData,
        country: userPreferenceData?.country?.label,
        city: userPreferenceData?.city?.label,
        state: userPreferenceData?.state?.label,
        industry: _.map(industriesPreference, (item) => item.name), // going to change
        usecase: _.map(userPreferenceData?.usecase, (item) => item.label),
        user_preferences: selectedProducts, // going to change
        mobile_number: userPreferenceData?.mobile_number
          ? `+${userPreferenceData?.mobile_number}`
          : '',
        profession: userPreferenceData.profession.label,
      };

      apiPatchAfterRegister(payload).then((res) => {
        if (res) {
          let { data, message, status } = res;
          if (status === true) {
            successToast(message);
            // token and refresh token either already exist from verify or login
            // inherently t&c are accepted at this point, and email must have been verified
            userCtx.onLogin(data, null, null, true, true, false);
            navigate(ADMIN_DASHBOARD);
          }
        }
      });
    }
  };

  function addUserDetails(changes) {
    let userPreferenceDataTemp = Object.assign({}, userPreferenceData);
    userPreferenceDataTemp = {
      ...userPreferenceDataTemp,
      ...changes,
    };
    setUserPreferenceData(userPreferenceDataTemp);
  }

  const handleProduct = (id, type) => {
    setUserProductPreferencesError(false);
    objArr && objArr.length && objArr.indexOf(id) > -1
      ? setObjArr(objArr.filter((k) => k !== id))
      : setObjArr([]);
    if (type === 'selected') {
      setSelectedProducts(selectedProducts.filter((k) => k !== id));
    }
    if (selectedProducts.length && selectedProducts.indexOf(id) > -1) {
      setSelectedProducts(selectedProducts.filter((k) => k !== id));
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
  };

  const handleButtonClick = (id) => {
    selectedProducts.length && selectedProducts.indexOf(id) > -1
      ? setSelectedProducts(selectedProducts.filter((k) => k !== id))
      : setSelectedProducts([...selectedProducts, id]);
  };

  const onProductAndIndustryChange = (products, industries) => {
    setSelectedProducts(products);
    setIndustriesPreference(industries);
  };

  return (
    <>
      {termsAndPolicyPageVisible ? (
        <TermsAndPrivacyPolicy
          isOnTermsAndConditions={isOnTermsAndConditions}
          setIsOnTermsAndConditions={setIsOnTermsAndConditions}
          setTermsAndPolicyPageVisible={setTermsAndPolicyPageVisible}
        />
      ) : (
        <>
          <form action="" className="user-pref-form" id="user-info" onSubmit={handleSubmit}>
            <Navbar expand="lg" className="bg-body publicMenu">
              <div
                className="form-check mr-1"
                style={{
                  marginRight: '1.75rem',
                  display: 'flex',
                  alignItems: 'flex-end',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}>
                <input
                  className={`form-check-input mr-2 ${isTocError ? 'input-error' : null}`}
                  type="checkbox"
                  name="tandc"
                  id="tandc1"
                  onChange={(e) => {
                    setTermsAndConditionsChecked(e.target.checked);
                    setIsTocError(false);
                    setTermsAndConditionsMessage('');
                  }}
                  onBlur={() => setTermsAndConditionsMessage('')}
                />
                <label className="form-check-label toc-text">
                  I accept
                  <Link
                    to="#"
                    className={termsAndConditionsMessage ? 'error-text title-error-color' : ''}
                    onClick={() => {
                      setTermsAndPolicyPageVisible(true);
                      setIsOnTermsAndConditions(false);
                    }}>
                    {' '}
                    Privacy Policy{' '}
                  </Link>
                  and{' '}
                  <Link
                    to="#"
                    className={termsAndConditionsMessage ? 'error-text title-error-color' : ''}
                    onClick={() => {
                      setTermsAndPolicyPageVisible(true);
                      setIsOnTermsAndConditions(true);
                    }}>
                    Term of use
                  </Link>
                </label>

                <button type="submit" form="user-info" className="invite-btn ms-4 startButton">
                  <span className="btn-font">Start</span>
                </button>
              </div>
            </Navbar>

            <div className="user-pref-div mt-5 px-5 publicContent">
              <Row className="px-2">
                <Col xl="5">
                  <OrganizationCard
                    organizationNameMessage={organizationNameMessage}
                    setOrganizationNameMessage={setOrganizationNameMessage}
                    organizationImageMessage={organizationImageMessage}
                    setOrganizationImageMessage={setOrganizationImageMessage}
                    userPreferenceData={userPreferenceData}
                    setUserPreferenceData={setUserPreferenceData}
                    registeredFromInvite={registeredFromInvite}

                    // comingFromInvitation={coming_from_invitation}
                    // organizationName={organization_name}
                  />
                </Col>
                <Col xl="7">
                  <UserInfo
                    userPreferenceData={userPreferenceData}
                    // setIndustriesPreference={setIndustriesPreference}
                    countryMessage={countryMessage}
                    setCountryMessage={setCountryMessage}
                    cityMessage={cityMessage}
                    setCityMessage={setCityMessage}
                    contactNumberMessage={contactNumberMessage}
                    setContactNumberMessage={setContactNumberMessage}
                    professionMessage={professionMessage}
                    setProfessionMessage={setProfessionMessage}
                    industryMessage={industryMessage}
                    setIndustryMessage={setIndustryMessage}
                    platformMessage={platformMessage}
                    setPlatformMessage={setPlatformMessage}
                    onUserPreferenceDataChange={addUserDetails}
                    cardClassName="customizeCards"
                  />
                </Col>
              </Row>
            </div>

            <div className="pt-3 pb-5 product-select">
              <div className="interests-cont">
                <h2>What Products are you interested in?</h2>
                <div>
                  <Row>
                    <div className="interest-section">
                      <div className="interest-section2">
                        <div className="d-flex">
                          <SelectFormGroup
                            error={industryMessage}
                            label="What's your industry?"
                            id="industry"
                            name="industry"
                            selectLabel="industry"
                            options={industryOptions}
                            value={userPreferenceData.industry}
                            onChangeCallback={handleIndustryChange}
                            onBlurCallback={() => setIndustryMessage('')}
                            onFocusCallback={() => setIndustryMessage('')}
                            placeholder="Choose your industry name"
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              {userProductPreferencesError ? null : (
                <h6 className="title-color">Please select one or more</h6>
              )}
              <YourInterests
                industriesPreference={industriesPreference}
                handleProduct={handleProduct}
                handleButtonClick={handleButtonClick}
                objArr={objArr} // tabs at top
                selectedProduct={selectedProducts}
                errorMessage="Please select one or more"
                error={userProductPreferencesError}
              />
            </div>
          </form>
        </>
      )}
    </>
  );
}