export const FileSaveLocal = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0062 19.1998H9.98757C6.35074 19.1998 4.79688 17.6459 4.79688 14.0091V9.9905C4.79688 6.35367 6.35074 4.7998 9.98757 4.7998H13.3364C13.453 4.7998 13.5611 4.84082 13.6469 4.90904C13.6623 4.92085 13.6772 4.93356 13.6914 4.94715L19.0495 10.3053C19.0877 10.3434 19.1189 10.3874 19.1426 10.4351C19.1772 10.5031 19.1969 10.5797 19.1969 10.6603V14.0091C19.1969 17.6459 17.643 19.1998 14.0062 19.1998ZM18.1922 11.1626V14.0091C18.1922 17.0967 17.0938 18.1952 14.0062 18.1952H9.98757C6.89994 18.1952 5.80153 17.0967 5.80153 14.0091V9.9905C5.80153 6.90287 6.89994 5.80446 9.98757 5.80446H12.8341V7.98119C12.8341 10.2718 13.7249 11.1626 16.0155 11.1626H18.1922ZM9.96101 15.8502C9.96976 15.8507 9.97861 15.851 9.98757 15.851L10.004 15.8507C10.1193 15.847 10.2338 15.8045 10.3273 15.7182C10.3325 15.7135 10.3376 15.7086 10.3425 15.7036L11.6821 14.3641C11.8763 14.1699 11.8763 13.8484 11.6821 13.6541C11.4879 13.4599 11.1664 13.4599 10.9721 13.6541L10.4899 14.1364V11.33C10.4899 11.0554 10.2622 10.8277 9.98757 10.8277C9.71297 10.8277 9.48525 11.0554 9.48525 11.33V14.1364L9.00298 13.6541C8.80874 13.4599 8.48726 13.4599 8.29302 13.6541C8.09879 13.8483 8.09879 14.1698 8.29302 14.364L9.60831 15.6793C9.64472 15.7213 9.68828 15.7572 9.73776 15.7846C9.80751 15.8249 9.88391 15.8463 9.96101 15.8502ZM13.8387 7.98119V6.5144L17.4823 10.1579H16.0155C14.2875 10.1579 13.8387 9.70919 13.8387 7.98119Z"
        fill="currentColor"
      />
    </svg>
  );
};
