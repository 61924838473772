import {createSlice} from "@reduxjs/toolkit";

const myRenderDownload = createSlice({
    name: 'myRenderupload',
    initialState: {
        myRenderDownloadData : {}
    },
    reducers: {
        setMyRenderDownloadData: (state, {payload}) => {
            state.myRenderDownloadData = payload
        }
    }
})

export const {setMyRenderDownloadData} = myRenderDownload.actions;
export const myRenderPreviewSelector = state => state.myRenderDownload;
export const myRenderDownloadReducer = myRenderDownload.reducer;
export default myRenderDownloadReducer;
