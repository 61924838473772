/* eslint-disable no-debugger */
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CATEGORY_FILTER_ID, CATEGORY_FILTER_NAME, CATEGORY_FILTER_TYPE, STYLE_FILTER_ID, STYLE_FILTER_NAME, STYLE_FILTER_TYPE } from '../../../../constants/projectConstants';
import {
  curatorSelector,
  SAVE_PROJECT_MODE,
  selectSaveProjectDialogOpen,
  selectSaveProjectMode,
  setSaveProjectDialogOpen,
} from '../../../../redux/slicers/admin/curatorSlicer';
import InfoDialogUI from '../../../InfoDialog/InfoDialogUI';
import { useSaveProject } from '../CuratorHeader/useSaveProject';
import { SaveProjectForm } from './SaveProjectForm';
import { useFilterDropdownOptions } from '../../../../hooks/useFilterDropdownOptions';

export const SaveProjectDialog = ({ load }) => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const open = useSelector(selectSaveProjectDialogOpen);
  const { roomData, individualProjectData } = useSelector(curatorSelector);
  const { saveProject } = useSaveProject();
  const saveProjectMode = useSelector(selectSaveProjectMode);

  const categoryOptions = useFilterDropdownOptions({
    // filterName: CATEGORY_FILTER_NAME,
    // filterType: CATEGORY_FILTER_TYPE,
    filterId: CATEGORY_FILTER_ID,
  });

  const styleOptions = useFilterDropdownOptions({
    // filterName: STYLE_FILTER_NAME,
    // filterType: STYLE_FILTER_TYPE,
    filterId: STYLE_FILTER_ID,
  });

  const onClose = () => {
    dispatch(setSaveProjectDialogOpen(false));
  };

  const formData = useMemo(() => {
    // prepare initial form data
    if (!individualProjectData?.id && !roomData?.id) return {};

    const categoryIds = categoryOptions.map((c) => c.value);
    const styleIds = styleOptions.map((c) => c.value);

    const filterOptions =  individualProjectData.filteroptions || roomData.filteroptions;

    const category = filterOptions
      .filter((o) => categoryIds.includes(o.id))
      .map((c) => ({
        value: c.id,
        label: c.name,
      }));

    const style = filterOptions
      .filter((o) => styleIds.includes(o.id))
      .map((c) => ({
        value: c.id,
        label: c.name,
      }));

    return type === 'project'
      ? {
          name: individualProjectData.name,
          projectType: individualProjectData.project_type,
          category,
          style,
        }
      : {
        category,
        style,
      };
  }, [individualProjectData, roomData, type, categoryOptions, styleOptions]);

  const handleFormSubmit = async (values) => {
    await saveProject(values);
  };

  const title = saveProjectMode === SAVE_PROJECT_MODE.NEW ? 'New Project' : 'Save Project';

  return (
    <InfoDialogUI show={open} title={title} onClose={onClose}>
      <SaveProjectForm
        data={formData}
        onSubmit={handleFormSubmit}
        categoryOptions={categoryOptions}
        styleOptions={styleOptions}
      />
    </InfoDialogUI>
  );
};
