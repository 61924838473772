import { useContext, useState } from 'react';
import cx from 'classnames';
import { Offcanvas, Button } from 'react-bootstrap';
import _ from 'lodash';
import { icons } from '../assets';
import { FilterContext, UserContext } from '../contexts';
import { Image, SearchInput } from './';
import { getCommonMetadata } from '../helpers/jsHelper';
const { UserIcon, CloseIcon, SearchIcon, UserGroupIcon } = icons;

export default function TeamMembersFilter(props) {
  const filterCtx = useContext(FilterContext);
  const userCtx = useContext(UserContext);
  let { teamMembers, setTeamMembers } = filterCtx;
  const [sidebarOpen, setSidebarOpen] = useState(props.initialShowOpen ? true : false);
  const [searchName, setSearchName] = useState('');
  const [filterMineOnly, setFilterMineOnly] = useState(false);
  let myUserId = parseInt(userCtx?.currentUser?.id, 10);

  const { total, hasData, anySelected, allSelected, selectedList } = getCommonMetadata(teamMembers);

  function toggleSidebar() {
    setSidebarOpen(!sidebarOpen);
  }

  function handleClose() {
    setSidebarOpen(false);
  }

  function handleMeClick() {
    let mineSelected = !filterMineOnly;
    setFilterMineOnly(mineSelected)
    handleSelect(myUserId, null, mineSelected);
  }

  function handleSelect(id, allOrNone = null, myFilterOverride) {
    if (!props.loading) {
      let newList = _.map(teamMembers, (item) => {
        if (item.id === id) {
          item.selected = item.selected ? false : true;
          if (_.isBoolean(myFilterOverride)) {
            item.selected = myFilterOverride;
          }
          if (item.id === myUserId) {
            setFilterMineOnly(item.selected)
          }
        } else {
          if (myFilterOverride) {
            item.selected = false;
          }
        }
        if (_.isBoolean(allOrNone)) {
          item.selected = allOrNone;
          setFilterMineOnly(allOrNone);
        }
        return item;
      });
      setTeamMembers(newList);
    }
  }

  let [users, outsideUsers] = _.chain(teamMembers)
    .map((member) => ({
      id: member.id,
      name: member.user.name || member.user.email,
      email: member.user.email,
      avatar: member.user.profile_image,
      selected: member.selected,
    }))
    .filter((member) => {
      if (searchName && searchName.length) {
        return (
          _.includes(_.toLower(member.name), _.toLower(searchName)) ||
          _.includes(_.toLower(member.email), _.toLower(searchName))
        );
      }
      return true;
    })
    .partition((member) => {
      return true;
    }) // need to determine if outside user so for now just returning true
    .value();

  if (!myUserId) return null;
  return (
    <div>
      <div className="filter layout ms-2">
        <Button
          className={cx(
            {
              active: filterMineOnly && !allSelected //!sidebarOpen && !anySelected,
            },
            'btn-table'
          )}
          disabled={props.loading}
          variant={filterMineOnly && !allSelected  ? 'primary' : ''}
          onClick={handleMeClick}>
          <UserIcon /> Me
        </Button>
        <Button
          className={cx(
            {
              active: anySelected && !filterMineOnly,
            },
            'btn-grid'
          )}
          disabled={props.loading}
          variant={anySelected && !filterMineOnly ? 'primary' : ''}
          onClick={toggleSidebar}>
          <UserGroupIcon /> Team Members
        </Button>
      </div>
      <Offcanvas
        className={`files-shared-with-users-container sidebar`}
        show={sidebarOpen}
        placement="end"
        onHide={handleClose}
        backdropClassName="nonVisibleBackdrop">
        <button className="closeFSUsers" onClick={handleClose}>
          <CloseIcon />
        </button>

        <Offcanvas.Body className="p-3 teamMemberSidebar">
          <div className="justify-content-center dottedBorderBottom pb-4">
            <SearchInput
              value={searchName}
              placeholder="Search"
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
          <div className="fs-container inside members pb-0">
            <div className="all-user-select">
              <div className="filter select_all checkbox-selection">
                <input
                  type="checkbox"
                  name="selectCard"
                  id="membersOverlaySelectAll"
                  className="selectCard"
                  checked={allSelected}
                  onChange={() => handleSelect(null, !allSelected)}                  
                  disabled={props.loading}
                />
                <label htmlFor="membersOverlaySelectAll"></label>
              </div>
              <label htmlFor="membersOverlaySelectAll">Select all</label>
              <label className="selectionCount">{selectedList.length}</label>
            </div>
          </div>
          <div
            className={cx(
              {
                dottedBorderBottom: outsideUsers.length > 0,
              },
              'fs-container members pt-0'
            )}>
            {users.length === 0 && 'No user found'}
            {users.length > 0 && (
              <ul className="user-list">
                {users.map((item) => {
                  return (
                    <li
                      className={cx(
                        {
                          selected: item.selected,
                          disabled: props.loading
                        },
                        'teamMemberFilter'
                      )}
                      key={item.id}
                      onClick={() => handleSelect(item.id)}                      
                      disabled={props.loading}
                    >
                      {item.avatar && item.avatar.length > 0 ? (
                        <Image src={item.avatar} avatar />
                      ) : (
                        <div
                          style={{
                            height: '32px',
                            width: '32px',
                            paddingLeft: '8px',
                            paddingTop: '4px',
                          }}>
                          <UserIcon />
                        </div>
                      )}
                      <div className="user-info font16">
                        <h5 className="user-name userName">{item.name}</h5>
                        {item.description && <p className="user-org">{item.description}</p>}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* <div className="fs-container outside members">
            <h5 className="head-h5">Outside members</h5>

            {outsideUsers.length === 0 && "No user found"}
            {outsideUsers.length > 0 && (
              <ul className="user-list">
                {outsideUsers.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className={item.selected ? `selected` : ``}
                      onClick={() => handleSelect(item.id)}
                    >
                      <Avatar src={USER_ICON} size={32} />
                      <div className="user-info">
                        <h5 className="user-name userName">{item.name}</h5>
                        {item.description && (
                          <p className="user-org">{item.description}</p>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
