// this component is handling the search form on the header
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, FormControl, Image } from 'react-bootstrap';
import { SEARCH, SEARCH_TEXT } from '../constants/routePathConstants';
import axios from 'axios';
import { SearchApi } from '../api/search/search';
import { SEARCH_ICON } from '../constants/assetConstants';
import { setSearchValue, searchSelector } from '../redux/slicers/search/searchSlicer';
import { SearchInput } from './';
//redux/slicers/search/searchSlicer";

// TODO REMOVE REDUX HERE

export default function UniversalSearchForm() {
  // const dispatch = useDispatch();
  const { text = '' } = useParams();
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();

  const handleSearchValue = (evt) => {
    setSearchString(evt.target.value);
    // dispatch(setSearchValue(searchVal));
  };

  const handleSearchQuery = (evt) => {
    evt.preventDefault();
    // if (searchVal !== '') {
    //   navigate(`${SEARCH}/${searchVal}`);
    // }
    if (searchString !== '') {
      navigate(`/${searchString}`);
    }
  };

  useEffect(() => {
    //     // dispatch(setSearchValue(searchVal));
    //     if(searchVal !== ""){
    //         navigate(`${SEARCH}/${searchVal}`)
    //     }
    //     dispatch(SearchApi(searchVal))
    // if (searchVal === '') {
    //   setSearch(text);
    // }
  }, []);

  return (
    <>
    {/* <Form className="d-block search-form" onSubmit={handleSearchQuery}>
      <Form.Group className="search-form-group">
        <Form.Label className="search-icon">
          <Image src={SEARCH_ICON} />
        </Form.Label>
        <FormControl
          type="search"
          value={searchString}
          placeholder="Search"
          onChange={handleSearchValue}
        />
      </Form.Group>
    </Form> */}
    <SearchInput value={searchString} placeholder="Search" onChange={handleSearchValue} onSubmit={handleSearchQuery} />
    </>
  );
}
