import {createSlice} from "@reduxjs/toolkit";

const search = createSlice({
    name: 'search',
    initialState: {
        searchVal : '',
        searchResult : {}
    },
    reducers: {
        setSearchValue: (state, {payload}) => {
            state.searchVal = payload
        },
        setSearchResult: (state, {payload}) => {
            state.searchResult = payload
        }
    }
})

export const {setSearchValue, setSearchResult} = search.actions;
export const searchSelector = state => state.search;
export const searchReducer = search.reducer;
export default searchReducer;