import React, { memo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { curatorSelector } from '../../../../../redux/slicers/admin/curatorSlicer';
import { RoomLightItem } from './RoomLightItem';
import styles from './Lights.module.scss';
import { EnvironmentLight } from './EnvironmentLight';
import { useUnityLight } from './useUnityLight';
import { useToggle } from 'react-use';
import { Pagination } from '../../../../Pagination/Pagination';
import { AddLight } from './AddLight';
import { curatorLightSelector, selectActiveTab, setActiveTab, TAB_NAME } from '../../../../../redux/slicers/admin/curatorLightSlicer';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import Button from '../../../../Button/Button';
import { useLightSectionCleanUp } from './useLightSectionCleanUp';
import { ColorPopup } from './ColorPopup';
import { CuratorSidebarLayout } from '../../CuratorSidebarLayout/CuratorSidebarLayout';

export const Lights = memo(({ active }) => {
  const dispatch = useDispatch();
  const { selectLight, toggleEdit, deleteLight, toggleLightEnabled } = useUnityLight();
  const lightList = useSelector((state) => curatorSelector(state).unityData.roomLightData);
  const selectedLightId = useSelector((state) => curatorLightSelector(state).selectedLightId);
  const tab = useSelector(selectActiveTab);

  const [addFormVisible, toggleAddFormVisible] = useToggle(false);

  const setTab = (tabName) => {
    dispatch(setActiveTab(tabName))
  }

  useLightSectionCleanUp({ active });

  return (
    <>
      <ColorPopup />
      <CuratorSidebarLayout.FixedHeader>
        <Pagination fullWidth className="mb-0">
          <Pagination.Item active={tab === TAB_NAME.INDOOR} onClick={() => setTab(TAB_NAME.INDOOR)}>
            Indoor light
          </Pagination.Item>
          <Pagination.Item
            active={tab === TAB_NAME.ENVIRONMENT}
            onClick={() => setTab(TAB_NAME.ENVIRONMENT)}>
            Enviroment light
          </Pagination.Item>
        </Pagination>
      </CuratorSidebarLayout.FixedHeader>

      <div className={`indoor-light-page ${tab === TAB_NAME.INDOOR ? '' : 'hide'}`}>
        <Button
          color="default"
          className={`${styles.addButton} mb-3`}
          onClick={() => toggleAddFormVisible(true)}>
          + Add Light
        </Button>

        {addFormVisible && <AddLight onCancel={() => toggleAddFormVisible()} />}

        <CuratorAccordion activeKey={selectedLightId} hideArrows>
          {lightList?.map((lightItem) => {
            return (
              <RoomLightItem
                key={lightItem.id}
                light={lightItem}
                selected={selectedLightId === lightItem.id}
                onEditToggle={() => toggleEdit(lightItem)}
                onActiveToggle={() => toggleLightEnabled(lightItem)}
                onDelete={() => deleteLight(lightItem)}
              />
            );
          })}
        </CuratorAccordion>
      </div>

      {tab === TAB_NAME.ENVIRONMENT && <EnvironmentLight />}
    </>
  );
})
