import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFullscreen } from "../hooks/useFullscreen";
import ReactTimeAgo from "react-time-ago";
import { ROOM1 } from "../config/constant/assetConstant";
import isEmpty from "lodash/isEmpty";
import { icons } from "../assets";
import styles from "../assets/scss/modules/_previewUI.module.scss";
import { CloseIcon } from "../components/icons/CloseIcon";
import ThumbnailCardUI from "./ThumbnailCardUI";
import Portal from "react-overlays/esm/Portal";
import PreviewUISidebar from "./PreviewUISidebar";
import PreviewUIDisplay from "./PreviewUIDisplay";
import { apiGetPreviewAndMainRenders } from '../helpers/api'


function PreviewUI({
  title,
  actions,
  onClose,
  loading,
  updatedAt,
  userName,
  sidebarCards,
  theme,
  sidebarProps,
  previewProps,
  slides,
}) {

  const { projectId } = useParams();
  const [portalRef, setPortalRef] = useState()
  const { toggle, ref, isFullscreen } = useFullscreen();

  useEffect(() => {
    setPortalRef(document.querySelector("body"));
  }, []);

  return (
    <Portal container={portalRef}>
      <div ref={ref}>
        <PreviewUIDisplay
          {...previewProps}
          theme={theme}
          actions={actions}
          isFullscreen={isFullscreen}
          onClose={onClose}
          title={title}
          toggleFullscreen={toggle}
          slides={slides}
        />

        <PreviewUISidebar
          {...sidebarProps}
          theme={theme}
          title={title}
          updatedAt={updatedAt}
          userName={userName}
          cards={sidebarCards}
          isFullscreen={isFullscreen}
        />
      </div>
    </Portal>
  );
}

export default PreviewUI;
