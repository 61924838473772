import React, { useState, useEffect, useContext } from 'react';
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Image,
  Spinner,
} from 'react-bootstrap';
import { apiUpdateUserDetails, apiGetUserDetails, apiChangePassword } from '../helpers/api';
import makeAnimated from 'react-select/animated';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { icons } from '../assets';
import { Input } from '../components/common/input';
import { navigate } from '../helpers/projectHelper';
import { FORGET_ROUTE_SETTINGS } from '../constants/routePathConstants';
import { UserInfo, ViewEditItem, OrgSection, Loader } from './';
import _ from 'lodash';
import { UserContext } from '../contexts';
import { setLocalStorage } from '../helpers/jsHelper';

const { ChangeProfileIcon, EyeIcon, ProfileIcon } = icons;

export default function MyDetails(props) {
  const userCtx = useContext(UserContext);
  const animatedComponents = makeAnimated();
  const [userDetails, setUserDetails] = useState(null);
  const [savePassword, setSavePassword] = useState(false);
  const [data, setData] = useState();
  const [nameError, setNameError] = useState(false);
  const [profileImg, setProfileImg] = useState(ProfileIcon);
  const hiddenFileInput = React.useRef(null);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [passwordShown3, setPasswordShown3] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [confirmErrorText, setConfirmErrorText] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPassError, setCurrentPasswordError] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('images/profilepic.svg');
  const [countryMessage, setCountryMessage] = useState('');
  const [cityMessage, setCityMessage] = useState('');
  const [contactNumberMessage, setContactNumberMessage] = useState('');
  const [professionMessage, setProfessionMessage] = useState('');
  const [industryMessage, setIndustryMessage] = useState('');
  const [platformMessage, setPlatformMessage] = useState('');
  const [userData, setUserData] = useState(userDetails);
  const [saving, setSaving] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [editingDesignation, setEditingDesignation] = useState(false);

  useEffect(() => {
    getUserDetails();
  }, [userCtx.currentUser.organization]);

  useEffect(() => {
    if (userDetails?.profile_image) {
      setProfileImg(userDetails?.profile_image);
    }
  }, [userDetails]);

  let formData = { ...data };

  const handleShow = () => setShow(true);

  function getUserDetails() {
    apiGetUserDetails().then((response) => {
      setUserDetails(response);
      const newUser = { ...userCtx.currentUser };
      newUser.organization_detail.name = response.organization_details.name;
      userCtx.setCurrentUser(newUser);
      setLocalStorage('current_user', newUser);
    });
  }

  function changeUserDetails(data) {
    if (data) {
      let formData = new FormData();

      let userData = Object.entries(data);

      userData.forEach((item) => {
        let key = item[0];
        let value = item[1];

        if (key === 'country') {
          value = value?.label;
        } else if (key === 'state') {
          value = value?.label;
        } else if (key === 'profession') {
          value = value?.value;
        } else if (key === 'city') {
          value = value?.label;
        } else if (key === 'usecase') {
          value = value;
        } else if (key === 'mobile_number') {
          value = value;
        } else {
          key = null;
          value = null;
        }

        if (userDetails) {
          let currentUserData = Object.entries(userDetails);

          currentUserData.forEach((property) => {
            let userKey = property[0];
            let userValue = property[1];

            if (userKey === key && userValue !== value) {
              if (value) {
                if (key === 'usecase') {
                  value.forEach((x) => formData.append('usecase', x?.value));
                } else {
                  formData.append(key, value);
                }
              }
            }
          });
        }
      });
      apiUpdateUserDetails(formData).then((response) => {
        setUserData(response);
      });
    }
  }

  const handleChange = ({ name, value }) => {
    formData[name] = value;
    setData(formData);
  };

  const handledesignationChange = ({ name, value }) => {
    formData[name] = value;
    setData(formData);
  };

  const handleUpdate = () => {
    var form_data = new FormData();
    for (var key in formData) {
      form_data.append(key, formData[key]);
    }
    apiUpdateUserDetails(form_data).then((response) => {
      setUserDetails(response);
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePpChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSaving(true);
      let img = event.target.files[0];
      setSelectedImage(URL.createObjectURL(img));
      let formData = new FormData();
      formData.append('profile_image', img);
      apiUpdateUserDetails(formData)
        .then((response) => {
          if (response && response.profile_image) {
            setProfileImg(response.profile_image);
            if (_.isFunction(userCtx.changeProfilePic)) {
              userCtx.changeProfilePic(response.profile_image);
            }
          }
        })
        .finally(() => setSaving(false));
    }
  };

  const handleImageChange = () => {
    hiddenFileInput.current.click();
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length < 8) {
      setNewPasswordError(true);
      setErrorText('Password cannot be less than 8 characters');
    } else {
      setNewPasswordError(false);
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value.length < 8) {
      setConfirmPasswordError(true);
      setConfirmErrorText('Confirm password cannot be less than 8 characters');
    } else setConfirmPasswordError(false);
  };

  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };

  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const togglePassword3 = () => {
    setPasswordShown3(!passwordShown3);
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    navigate(FORGET_ROUTE_SETTINGS);
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  const submitPassword = () => {
    if (newPassword !== confirmPassword) {
      setNewPasswordError(true);
      setErrorText('New password and confirm password do not match');
    } else if (currentPassword === newPassword) {
      setNewPasswordError(true);
      setErrorText('New Password and current password cannot be same');
    } else if (currentPassword.length === 0) {
      setCurrentPasswordError(true);
    } else {
      let formData = new FormData();
      formData.append('old_password', currentPassword);
      formData.append('new_password', newPassword);
      setNewPasswordError(false);
      setShow(false);

      apiChangePassword(formData).then((response) => {
        setSavePassword(true);
      });

      setTimeout(function () {
        setSavePassword(false);
      }, 3000);
    }
  };

  return (
    <Row>
      <Col xs={12} md={6}>
        <div className="left-section details-page">
          <div className="input-border">
            <label className="detail-label">Profile Picture</label>
            <figure>
              <img
                src={profileImg}
                style={{ width: '6em', height: '6em', borderRadius: '0.5em' }}
              />
              <Button className="greyEditBtn" onClick={handleImageChange}>
                <img src={ChangeProfileIcon}></img>
                {saving ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Change Picture'
                )}
              </Button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handlePpChange}
                style={{ display: 'none' }}
              />
            </figure>
          </div>
          <ViewEditItem
            label="Name"
            disabled={nameError}
            onUpdate={handleUpdate}
            value={userDetails ? `${userDetails.first_name} ${userDetails.last_name}` : ''}
            editing={editingName}
            setEditing={setEditingName}>
            <div className="input-div">
              <>
                <div className="input-div">
                  <Input
                    className="input-name"
                    name="first_name"
                    onChange={handleChange}
                    required
                    defaultData={userDetails}
                    placeholder="First Name"
                  />
                </div>
                <div className="input-div">
                  <Input
                    className="input-name"
                    name="last_name"
                    onChange={handleChange}
                    required
                    defaultData={userDetails}
                    placeholder="Last Name"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleUpdate();
                        setEditingName(false);
                      }
                    }}
                  />
                </div>
              </>
            </div>
          </ViewEditItem>
          <div className="input-border">
            <Form.Label>
              <div>
                <label className="detail-label">Email</label>
              </div>
            </Form.Label>
            <div className="align-items-end">
              <span className="detail-info">{userDetails?.email}</span>
              <span className="contact-support d-inline float-end">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip-2">To change your email</Tooltip>}>
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center p-0">
                      <Image src={EyeIcon} ref={ref} style={{ width: '1vw' }}></Image>
                    </Button>
                  )}
                </OverlayTrigger>
                Contact Support &nbsp;
              </span>
            </div>
          </div>
          <div className="input-border">
            <span className="detail-links" onClick={handleShow}>
              Edit password
            </span>
            <Modal show={show} onHide={handleClose} animation={false} className="edit-pw-modal">
              <Modal.Header className="border-none" closeButton></Modal.Header>
              <Modal.Body className="px-5">
                <div className="text-center mb-4">
                  <h2>Edit Password</h2>
                </div>
                <Form className="settings-modal">
                  <Form.Group className="mb-4 password-field" controlId="formBasicEmail">
                    <Form.Label>
                      <small className="text-muted">Current Password</small>
                    </Form.Label>
                    <Form.Control
                      className="password-input"
                      type={passwordShown1 ? 'text' : 'password'}
                      placeholder="Type here your current password"
                      maxLength={20}
                      onChange={handleCurrentPassword}
                      style={{
                        border: currentPassError ? 'red 2px solid' : '',
                      }}
                    />
                    {passwordShown1 ? (
                      <RiEyeLine className="eye-icon" onClick={togglePassword1} />
                    ) : (
                      <RiEyeOffLine className="eye-icon" onClick={togglePassword1} />
                    )}
                  </Form.Group>

                  <div className="text-primary text-end mb-4 cursorPointer">
                      <span role="button" onClick={handleForgotPasswordClick}>
                        I forgot my password
                      </span>
                    </div>
                  <Form.Group
                    className="mb-3 new-password password-field"
                    controlId="formBasicPassword">
                    <Form.Label style={{ color: newPasswordError ? 'red' : '' }}>
                      <small className="text-muted">
                        {newPasswordError ? errorText : 'New Password'}
                      </small>
                    </Form.Label>
                    <Form.Control
                      className="password-input"
                      placeholder="Type here your new password"
                      type={passwordShown2 ? 'text' : 'password'}
                      onBlur={handleNewPassword}
                      style={{
                        border: newPasswordError ? 'red 2px solid' : '',
                      }}
                      maxLength={20}
                    />
                    {passwordShown2 ? (
                      <RiEyeLine className="eye-icon" onClick={togglePassword2} />
                    ) : (
                      <RiEyeOffLine className="eye-icon" onClick={togglePassword2} />
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-3 confirm-password password-field"
                    controlId="formBasicPassword">
                    <Form.Label style={{ color: confirmPasswordError ? 'red' : '' }}>
                      <small className="text-muted">
                        {confirmPasswordError ? confirmErrorText : 'Confirm New Password'}
                      </small>
                    </Form.Label>
                    <Form.Control
                      className="password-input"
                      type={passwordShown3 ? 'text' : 'password'}
                      placeholder="Repeat your new password"
                      onBlur={handleConfirmPassword}
                      style={{
                        border: confirmPasswordError ? ' red 2px solid' : '',
                      }}
                      maxLength={20}
                    />
                    {passwordShown3 ? (
                      <RiEyeLine className="eye-icon" onClick={togglePassword3} />
                    ) : (
                      <RiEyeOffLine className="eye-icon" onClick={togglePassword3} />
                    )}
                  </Form.Group>
                </Form>
                <div className="d-grid py-3 font16">
                  <Button
                    className="submit-btn"
                    variant="warning"
                    onClick={submitPassword}
                    disabled={newPasswordError || confirmPasswordError}>
                    <span className="font16">Save</span>
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <ViewEditItem
            label="Designation"
            disabled={nameError}
            onUpdate={handleUpdate}
            value={userDetails ? userDetails.designation : ''}
            editing={editingDesignation}
            setEditing={setEditingDesignation}>
            <div className="input-div">
              <Input
                className="input-name"
                name="designation"
                onChange={handledesignationChange}
                required
                defaultData={userDetails}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleUpdate();
                    setEditingDesignation(false);
                  }
                }}
              />
            </div>
          </ViewEditItem>
        </div>
      </Col>
      <OrgSection userDetails={userDetails} getUserDetails={getUserDetails} />
      <Col>
        <UserInfo
          // hideBanner,
          countryMessage={countryMessage}
          setCountryMessage={setCountryMessage}
          cityMessage={cityMessage}
          setCityMessage={setCityMessage}
          contactNumberMessage={contactNumberMessage}
          setContactNumberMessage={setContactNumberMessage}
          professionMessage={professionMessage}
          setProfessionMessage={setProfessionMessage}
          industryMessage={industryMessage}
          setIndustryMessage={setIndustryMessage}
          platformMessage={platformMessage}
          setPlatformMessage={setPlatformMessage}
          onUserPreferenceDataChange={changeUserDetails}
          inSettings={true}
        />
      </Col>
      {/* {showSavePassword ? <SavedDetails /> : ""} */}
    </Row>
  );
}
