/* eslint-disable no-unreachable, no-debugger */
import md5 from 'md5';
import PubSub from 'pubsub-js';
import download from 'downloadjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createProjectApi, exportProjectApi, saveProjectApi } from '../../../../api/curator';
import { CURATOR_TYPE, PROJECT_TYPE } from '../../../../constants/projectConstants';
import { errorToast, infoToast, successToast } from '../../../../helpers/toastHelper';
import { getCuratorPath, getProjectsPath } from '../../../../helpers/urlHelper';
import { useInfoDialogaActions } from '../../../../hooks/useInfoDialogaActions';
import { getAppState, getStore } from '../../../../redux/store';
import {
  curatorSelector,
  saveAsNew,
  SAVE_PROJECT_MODE,
  selectCuratorInitialized,
  selectSaveProjectLoading,
  selectSaveProjectMode,
  selectUnityData,
  setRoomData,
  setSaveProjectDialogOpen,
  setSaveProjectMode,
  unblockProjectInitialization,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { useUnityContext } from '../../../container/unityContainer';
import {
  ENVIRONMENT_LIGHT,
  selectEnvironmentColor,
  selectEnvironmentColorIntensity,
  selectEnvironmentLightType,
  selectHDRIIntensity,
  selectHDRIRotation,
  selectSelectedHDRI,
} from '../../../../redux/slicers/admin/curatorLightSlicer';
import { useCuratorData } from '../../../../hooks/useCuratorData';
import { selectProjectCameraList } from '../../../../redux/slicers/camera/projectCameraAngleSlicer';
import {
  selectExportProjectLoading,
  setExportProjectLoading,
} from '../../../../redux/slicers/admin/curatorLoaderSlicer';
import { duplicateArrayRemove } from '../../../../helpers/jsHelper';
import { UnityEvent } from '../../../../helpers/unityPubSub';

export const useSaveProject = () => {
  const { type, roomId, isOpenProjectFlow } = useCuratorData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const curatorInitialized = useSelector(selectCuratorInitialized);
  const { openInfoDialog } = useInfoDialogaActions();
  const saveProjectMode = useSelector(selectSaveProjectMode);
  const { individualProjectData = {}, roomData } = useSelector(curatorSelector);
  const { roomModule, cameraModule } = useUnityContext();
  const loading = useSelector(selectSaveProjectLoading);

  const addIdsToFormData = ({
    formData,
    roomInfo,
    includedIds = {
      textures: true,
      layout: true,
      materials: true,
      props: true,
    },
  }) => {
    const unityData = selectUnityData(getAppState());

    // layout
    if (includedIds.layout) {
      formData.append('layout', unityData.layoutId);
    }

    // props
    if (includedIds.props) {
      roomInfo.blenderAssetBundleDatas.forEach((item) => {
        formData.append('props', item.id);
      });
    }

    // textures and materials
    const textureIds = [];
    const materialIds = [];
    roomInfo.materialDetails.map((md) => {
      if (md.loadedMaterialId) {
        materialIds.push(md.loadedMaterialId);
      }

      ['diffuseId', 'AOId', 'metallicId', 'normalId', 'roughnessId', 'transparencyId'].forEach(
        (key) => {
          if (md[key]) {
            textureIds.push(md[key]);
          }
        }
      );
    });

    if (includedIds.textures) {
      duplicateArrayRemove(textureIds).forEach((id) => {
        formData.append('textures', id);
      });
    }

    if (includedIds.materials) {
      duplicateArrayRemove(materialIds).forEach((id) => {
        formData.append('materials', id);
      });
    }
  };

  const getRoomInfo = () => {
    // this will trigger get info which will be provided in the callback and set later on to localstorage
    // TODO: refactor it to use async/await with unityContext.on -> and wait for GetRoomInfo event triggered
    roomModule.GetRoomInfo();
    console.log('SAVE_PROJECT_FLOW START');
    const roomInfo = JSON.parse(localStorage.getItem('roomInfo'));
    console.log('SAVE_PROJECT_FLOW ROOM_INFO', roomInfo);
    return roomInfo;
  };

  // TODO: move to seprate file
  const parseCulpSkuData = (culpSkuUsedData, roomMetadataMap) => {
    const result = {};
    const culpObjectsDatas = Object.values(culpSkuUsedData);
    const roomMetadata = Object.values(roomMetadataMap);

    roomMetadata.forEach((metadataItem) => {
      if (!metadataItem.status) return;

      const skuItem = culpObjectsDatas.find((item) => item.matName === metadataItem.materialName);
      if (!skuItem) return;

      const jsonObject = {
        objectName: skuItem.objectName,
        matName: skuItem.matName,
        toShowName: metadataItem.objectName,
        skuId: skuItem.skuId.trim(),
        index: skuItem.index,
        repeatL: skuItem.repeatL,
        repeatW: skuItem.repeatW,
        offsetL: skuItem.offsetL,
        offsetW: skuItem.offsetW,
        rotation90Count: skuItem.rotation90Count,
      };

      const skuidToShow = skuItem.skuId.trim();
      // string content = null;

      let skuString = '';
      skuString += metadataItem.title + ':         ';
      skuString += 'SKU - ' + skuidToShow;

      // if (!string.IsNullOrEmpty(content))
      // {
      //     skuString += ", Content - " + content;
      // }

      if (skuItem.rotation90Count > 0) {
        if (skuItem.offsetW != '0.00' || skuItem.offsetL != '0.00') {
          skuString +=
            ', Repeat Size - (W) ' + skuItem.repeatW + ' * (L) ' + skuItem.repeatL + ', ';
          skuString += 'Offset - (W) ' + skuItem.offsetW + ' * (L) ' + skuItem.offsetL;
          skuString += ', Rotation - ' + skuItem.rotation90Count * 90 + ' degrees' + '\n';
        } else {
          skuString +=
            ', Repeat Size - (W) ' + skuItem.repeatW + ' * (L) ' + skuItem.repeatL + ', ';
          skuString += 'Rotation - ' + skuItem.rotation90Count * 90 + ' degrees' + '\n';
        }
      } else {
        if (skuItem.offsetW != '0.00' || skuItem.offsetL != '0.00') {
          skuString +=
            ', Repeat Size - (W) ' + skuItem.repeatW + ' * (L) ' + skuItem.repeatL + ', ';
          skuString += 'Offset - (W) ' + skuItem.offsetW + ' * (L) ' + skuItem.offsetL + '\n';
        } else {
          skuString += ', Repeat Size - (W) ' + skuItem.repeatW + ' * (L) ' + skuItem.repeatL + '';
        }
      }

      jsonObject.textValue = skuString;
      result[skuItem.matName] = jsonObject;
    });

    return result;
  };

  const getRoomJsonFile = () => {
    const store = getStore();
    const state = store.getState();

    const roomInfo = getRoomInfo();

    // remove generated ids required only for old zip files
    roomInfo.materialDetails = roomInfo.materialDetails.map((md) => {
      delete md.objectUniqueIdentifier;
      return md;
    });

    // add selected environment light
    const lightType = selectEnvironmentLightType(state);
    const hdrId = selectSelectedHDRI(state)?.id;
    roomInfo.SelectedEnvironmentLight = {
      color: {
        isEnvironmentColorEnabled: lightType === ENVIRONMENT_LIGHT.COLOR,
        environmentIntensity: selectEnvironmentColorIntensity(state),
        environmentColor: selectEnvironmentColor(state),
      },
      hdri: {
        ishdriEnabled: lightType === ENVIRONMENT_LIGHT.HDRI,
        hdriRotation: selectHDRIRotation(state),
        hdriIntensity: selectHDRIIntensity(state),
        hdriId: `${hdrId}`.toUpperCase() !== 'DEFAULT' ? hdrId : null,
      },
    };

    // save cameras in project json
    roomInfo.cameraList = selectProjectCameraList(getAppState()) || [];
    roomInfo.roomId = individualProjectData?.roomId || roomData?.roomId;

    // update culp json data
    roomInfo.culpSkuUsedData = parseCulpSkuData(roomInfo.culpSkuUsedData, roomInfo.roomMetadata);

    console.log('SAVE_PROJECT_FLOW JSON', roomInfo);

    const json_data = JSON.stringify(roomInfo);

    const blob = new Blob([json_data], { type: 'application/json' });
    return {
      blob,
      json: json_data,
      raw: roomInfo,
    };
  };

  const downloadProjectJson = (projectData, jsonData) => {
    const project = {
      name: projectData.name,
      projectType: projectData.projectType,
      category: projectData.category,
      style: projectData.style,

      jsonData,
    };

    download(JSON.stringify(project), `${projectData.name}.json`, 'application/json');
  };

  const getProjectThumbnail = async () => {
    return new Promise((resolve) => {
      PubSub.subscribeOnce(UnityEvent.OnReceieveCurrentCameraScreenshot, (_, imageData) => {
        // TODO: refactor this code
        const byteCharacters = window.atob(imageData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: `image/jpeg` });
        resolve(blob);
      });
      cameraModule.GetCurrentCameraScreenshotReceiver();
    });
  };

  const saveProject = async (data) => {
    if (loading) return;

    const roomJsonFile = getRoomJsonFile();

    var formObj = new FormData();
    formObj.append('file', roomJsonFile.blob, 'newproject.json');

    if (data) {
      formObj.append('name', data.name);
      formObj.append('project_type', data.projectType);

      if (data.projectType !== PROJECT_TYPE.SILO) {
        const filterOptions = [...data.category, ...data.style].map((d) => d.value).join(',');
        formObj.append('filteroptions', filterOptions);
      }
    } else {
      formObj.append('name', individualProjectData?.name);
      formObj.append('project_type', individualProjectData?.project_type);
    }

    addIdsToFormData({ formData: formObj, roomInfo: roomJsonFile.raw });

    // thumbnail
    const thumbnail = await getProjectThumbnail(formObj);
    formObj.append('thumbnail', thumbnail, 'project-thumbnail.jpg');

    console.log('SAVE_PROJECT_FLOW REQUEST_DATA', formObj);
    console.log('SAVE_PROJECT_FLOW ROOM_JSON', roomJsonFile.raw);

    // return;

    switch (saveProjectMode) {
      case SAVE_PROJECT_MODE.CLOSE_CURATOR:
        dispatch(
          saveProjectApi(formObj, roomId, {
            onSuccess: () => {
              dispatch(setSaveProjectDialogOpen(false));
              navigate(getProjectsPath());
            },
          })
        );
        break;
      case SAVE_PROJECT_MODE.SAVE:
        dispatch(
          saveProjectApi(formObj, roomId, {
            onSuccess: () => {
              dispatch(setSaveProjectDialogOpen(false));
            },
            onError: () => {
              errorToast('Unknown error happened while saving project');
            },
          })
        );
        break;
      case SAVE_PROJECT_MODE.NEW:
        dispatch(
          createProjectApi(formObj, {
            onSuccess: (data) => {
              dispatch(saveAsNew({ project: data.data }));
              dispatch(setSaveProjectDialogOpen(false));
              navigate(getCuratorPath(data.data.id, 'project'));

              setTimeout(() => {
                dispatch(unblockProjectInitialization());
              }, 200);
            },
          })
        );
        break;
      case SAVE_PROJECT_MODE.SAVE_ON_LOCAL:
        downloadProjectJson(data, roomJsonFile.raw);
        dispatch(setSaveProjectDialogOpen(false));
        break;
      default:
        throw new Error('Save project mode not supported');
    }
  };

  const openSaveProjectDialog = () => {
    dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.SAVE));
    dispatch(setSaveProjectDialogOpen(true));
  };

  const openSaveProjectDialogAsNew = () => {
    dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.NEW));
    dispatch(setSaveProjectDialogOpen(true));
  };

  const exportProject = async () => {
    const loading = selectExportProjectLoading(getAppState());
    
    if (loading) {
      infoToast('Project export is still in progress');
      return;
    }

    setExportProjectLoading(true);
    // TODO: use info toast here
    successToast('Please wait... Project is being exported');

    const roomJsonFile = getRoomJsonFile();
    const formData = new FormData();
    formData.append('json_file', roomJsonFile.blob, 'project.json');
    addIdsToFormData({
      formData,
      roomInfo: roomJsonFile.raw,
      includedIds: {
        textures: true,
      },
    });

    const jsonHash = md5(roomJsonFile.json);
    formData.append('hashkey', jsonHash);

    const fileName = individualProjectData?.name || roomData?.name || 'project.zip';
    const result = await dispatch(exportProjectApi(formData, { fileName }));

    console.log('sasha result', result);

    setExportProjectLoading(false);
  };

  return {
    getRoomJsonFile,
    exportProject,
    saveProject,
    openSaveProjectDialog,
    openSaveProjectDialogAsNew,
  };
};
