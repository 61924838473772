import * as yup from 'yup';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '../../../../Button/Button';
import {
  MAX_PREVIEW_NAME_LENGTH,
  MIN_PREVIEW_NAME_LENGTH,
} from '../../../../../config/constant/validationConstant';
import { useSaveRenderPreview } from './useSaveRenderPreview';
import styles from './PreviewRenderForm.module.scss';
import { selectIsLoading, selectIsSiloRoom } from '../../../../../redux/slicers/admin/curatorSlicer';
import { RP_TYPE } from '../../../../../config/constant/unityConstants';
import { errorToast } from '../../../../../helpers/toastHelper'

export const PreviewRenderForm = ({ type, onSubmit, className }) => {
  const loading = useSelector(selectIsLoading);
  const imageTypes = ['jpg', 'png', 'tiff'];
  const isSiloRoom = useSelector(selectIsSiloRoom);

  const schema = useMemo(() => {
    return yup.object({
      // name: yup
      //   .string()
      //   .min(MIN_PREVIEW_NAME_LENGTH, 'Please provide name')
      //   .max(
      //     MAX_PREVIEW_NAME_LENGTH,
      //     `Name length should not be longer than ${MAX_PREVIEW_NAME_LENGTH}`
      //   )
      //   .required('Name is missing'),
    });
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      metadata: false,
      watermark: false,
      transparentBg: false,
      shadowOnFloor: isSiloRoom ? true : false,
      imageType: 'jpg',
    },
    
  });

  const isTransparent = watch('transparentBg');
  const isRender = type === RP_TYPE.RENDER;

  useEffect(() => {
    if (isTransparent) {
      setValue('imageType', 'png');
    }
  }, [isTransparent]);

  const nameRegister = register('name');
  const handleNameChange = (e) => {
    const { value } = e.target;
    const specialChars = /[\\\/:\.?'<>|]/;
    if (specialChars.test(value)) {
      setValue('name', getValues('name'));
      errorToast('Special characters are not allowed', null, { toastId: 'NAME_SPECIAL_CHARS' });
      return;
    }
    
    nameRegister.onChange(e);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cx(className, styles.form)}>
      <div className="drop-input">
        <label className="form-label">File name</label>
        <div className="input-group d-flex flex-wrap preview">
          <input
            type="text"
            className={`form-control ${styles.nameInput} ${isRender ? styles.renderNameInput : ''}`}
            name="name"
            {...nameRegister}
            onChange={handleNameChange}
            placeholder={isRender ? 'Render Name' : 'Preview Name'}
          />

          {isRender && (
            <div className="input-group-append">
              <select className="rp-type" {...register('imageType')} name="imageType">
                {imageTypes?.map((a) => (
                  <option key={a} value={a} disabled={isTransparent}>
                    {a.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div
          className="invalid-feedback"
          style={{ display: errors.name?.message ? 'block' : 'none' }}>
          {errors.name?.message}
        </div>
      </div>

      <div className={`d-flex flex-wrap pt-3 ${styles.checkboxContainer}`}>
        <div>
          {isRender && (
            <div className="form-check mb-2 custom-checkbox d-flex align-items-center ">
              <input
                className="form-check-input"
                type="checkbox"
                name="metadata"
                {...register('metadata')}
              />
              <span className="mt-1">Metadata</span>
            </div>
          )}
          <div className="form-check mb-2 custom-checkbox d-flex align-items-center ">
            <input
              className="form-check-input"
              type="checkbox"
              name="transparentBg"
              {...register('transparentBg')}
            />
            <span className="mt-1">Transparent Bg</span>
          </div>
        </div>

        <div>
          {isRender && (
            <div className="form-check mb-2 custom-checkbox d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                name="watermark"
                {...register('watermark')}
              />
              <span className="mt-1">Watermark</span>
            </div>
          )}
          {isSiloRoom && (
            <div className="form-check mb-2 custom-checkbox d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                name="shadowOnFloor"
                {...register('shadowOnFloor')}
              />
              <span className="mt-1">Shadow on floor</span>
            </div>
          )}
        </div>
      </div>

      <Button type="submit" color="warning" className="mt-3 w-100" size="xlg" loading={loading}>
        {isRender ? 'Send for Rendering' : 'Send for Preview'}
      </Button>
    </form>
  );
};
