import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_ICON, RIGHT_ICON } from '../../../../../config/constant/assetConstant';
import {
  curatorSelector,
  setCuratorSettingData,
  SETTINGS_SECTION,
} from '../../../../../redux/slicers/admin/curatorSlicer';
import { errorToast } from '../../../../../helpers/toastHelper';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import { editCuratorSetting } from '../../../../../api/curator';
import styles from './MetadataSetting.module.scss';
import { CheckCircleIcon } from '../../../../icons/CheckCircleIcon';

const MetadataSetting = ({ onSectionToggle }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = React.useState({ id: null, name: null });

  const { settingData } = useSelector(curatorSelector);

  const handleChangeData = (findIndex, checked) => {
    let tempData = [...settingData?.json_data?.metaData];
    tempData[findIndex] = {
      ...tempData[findIndex],
      is_render: checked,
    };
    dispatch(
      setCuratorSettingData({
        ...settingData,
        json_data: { ...settingData?.json_data, metaData: [...tempData] },
      })
    );
  };

  const handleChangeOrder = (type, index) => {
    let tempData = [...settingData?.json_data?.metaData];
    const element = tempData?.splice(index, 1)[0];
    switch (type) {
      case 'UP':
        if (index) {
          tempData.splice(index - 1, 0, element);
        } else {
          tempData.splice(index, 0, element);
        }
        break;
      case 'DOWN':
        if (index !== tempData.length) {
          tempData.splice(index + 1, 0, element);
        } else {
          tempData.splice(index, 0, element);
        }
        break;

      default:
        break;
    }
    dispatch(
      setCuratorSettingData({
        ...settingData,
        json_data: { ...settingData?.json_data, metaData: [...tempData] },
      })
    );
  };

  const handleChangeMetaDataName = (value) => {
    setEditing({ ...editing, name: value });
  };

  const setMetaDataName = () => {
    if (!editing?.name.trim()) {
      errorToast('Please enter metadata name');
      return;
    }
    const tempData = [...settingData?.json_data?.metaData];
    tempData[editing?.id] = {
      ...tempData[editing?.id],
      display_name: editing?.name,
    };

    const updatedSettingData = {
      ...settingData,
      json_data: { ...settingData?.json_data, metaData: [...tempData] },
    };

    dispatch(setCuratorSettingData(updatedSettingData));
    setEditing({ id: null, name: null });

    const formData = new FormData();
    formData.append('json_data', JSON.stringify(updatedSettingData?.json_data));
    dispatch(editCuratorSetting(formData, settingData?.id));
  };

  const selectedItem = settingData?.json_data?.metaData?.find((_, index) => editing.id === index);


  return (
    <CuratorAccordion.Item eventKey={SETTINGS_SECTION.METADATA_SETTINGS}>
      <CuratorAccordion.Header onClick={() => onSectionToggle(SETTINGS_SECTION.METADATA_SETTINGS)}>
        Metadata settings
      </CuratorAccordion.Header>

      <CuratorAccordion.Body>
        {settingData?.json_data?.metaData?.map((item, index) => (
          <div className="metadata-cnt" key={item?.id}>
            <div className="d-flex justify-content-between align-items-center mb-0 gap-2">
              {editing.id === index && (
                <div className={styles.formContainer}>
                  <input
                    type="text"
                    className={`form-control ${styles.editInput}`}
                    name="Project name"
                    placeholder="Metadata Name"
                    value={editing?.name}
                    onChange={(e) => handleChangeMetaDataName(e.target.value, item?.id)}
                  />
                  <img
                    src={CLOSE_ICON}
                    className={styles.cancelBtn}
                    onClick={() => setEditing({ id: null, name: null })}
                  />
                  <CheckCircleIcon
                    className={cx(
                      styles.submitBtn,
                      selectedItem?.display_name !== editing?.name && editing?.name.trim() && styles.active
                    )}
                    onClick={() => setMetaDataName()}
                  />
                </div>
              )}
              <div className="form-check custom-checkbox d-flex align-items-cente mb-0">
                <input
                  className="form-check-input mt-0"
                  type="checkbox"
                  checked={item?.is_render}
                  id="flexCheckDefault"
                  onChange={(e) => handleChangeData(index, e.target.checked)}
                />
                <p className="mb-0 meta-title">
                  <span className={'me-2'}>{index + 1}.</span> {item?.display_name}
                </p>
              </div>

              <span className="meta-text">{item?.value}</span>
              <div className="accordion-icon d-flex ">
                <span
                  className="arrow-up-icon p-0 ms-2 cursor-pointer"
                  onClick={() => handleChangeOrder('UP', index)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none">
                    <rect x="8.25" y="3.75" width="1.5" height="10.5" rx="0.75" fill="white" />
                    <path
                      d="M5.03033 9.53033C4.73744 9.82322 4.26256 9.82322 3.96967 9.53033C3.67678 9.23744 3.67678 8.76256 3.96967 8.46967L8.46967 3.96967C8.7536 3.68574 9.21079 3.6758 9.50679 3.94714L14.0068 8.07213C14.3121 8.35203 14.3328 8.82646 14.0529 9.13179C13.773 9.43713 13.2985 9.45776 12.9932 9.17787L9.02256 5.5381L5.03033 9.53033Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span
                  className="arrow-down-icon p-0 ms-2"
                  onClick={() => handleChangeOrder('DOWN', index)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none">
                    <rect x="8.25" y="3.75" width="1.5" height="10.5" rx="0.75" fill="white" />
                    <path
                      d="M5.03033 8.46967C4.73744 8.17678 4.26256 8.17678 3.96967 8.46967C3.67678 8.76256 3.67678 9.23744 3.96967 9.53033L8.46967 14.0303C8.7536 14.3143 9.21079 14.3242 9.50679 14.0529L14.0068 9.92787C14.3121 9.64797 14.3328 9.17355 14.0529 8.86821C13.773 8.56287 13.2985 8.54224 12.9932 8.82213L9.02256 12.4619L5.03033 8.46967Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span
                  className="edit-icon p-0 ms-2"
                  onClick={() => setEditing({ id: index, name: item?.display_name })}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.37105 12.7025C5.61653 12.9283 5.9578 13.05 6.32302 13.05C6.38442 13.05 6.44337 13.0452 6.50458 13.0403C6.53887 13.0375 6.57386 13.0347 6.61041 13.0326L8.53829 12.7141C8.90351 12.6562 9.37051 12.4129 9.62197 12.1523L14.5375 7.11966C15.7649 5.86294 15.7289 4.6236 14.4297 3.43638C13.1305 2.24916 11.8492 2.28391 10.6218 3.54063L5.70634 8.57328C5.44889 8.8281 5.22736 9.29719 5.18545 9.65046L4.96392 11.5268C4.91004 11.9902 5.05972 12.4187 5.37105 12.7025ZM11.2744 4.12555C11.6816 3.70858 12.0827 3.43059 12.5437 3.43059C12.9149 3.43059 13.3281 3.61591 13.807 4.06185C14.8907 5.04637 14.6213 5.75291 13.8789 6.51736L13.2169 7.1951C11.9279 6.98343 10.9004 6.03457 10.6176 4.79883L11.2744 4.12555ZM9.90238 5.53193L6.35894 9.16399C6.2392 9.28561 6.10149 9.57518 6.07754 9.74312L5.85602 11.6195C5.83207 11.8106 5.87997 11.967 5.98774 12.0654C6.09551 12.1639 6.25716 12.1986 6.45474 12.1697L8.38262 11.8512C8.55625 11.8222 8.84364 11.6716 8.96338 11.55L12.5081 7.92086C11.3076 7.55376 10.3451 6.67234 9.90238 5.53193Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        ))}
      </CuratorAccordion.Body>
    </CuratorAccordion.Item>
  );
};

export default React.memo(MetadataSetting);
