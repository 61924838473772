export const MailIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64804 18.3907H15.3457C17.7904 18.3907 19.1969 16.9842 19.1969 14.5395V9.85116C19.1969 7.40651 17.7904 6 15.3457 6H8.64804C6.20339 6 4.79688 7.40651 4.79688 9.85116V14.5395C4.79688 16.9842 6.20339 18.3907 8.64804 18.3907ZM5.80153 9.85116C5.80153 7.93563 6.7325 7.00465 8.64804 7.00465H15.3457C17.2612 7.00465 18.1922 7.93563 18.1922 9.85116V14.5395C18.1922 16.4551 17.2612 17.386 15.3457 17.386H8.64804C6.7325 17.386 5.80153 16.4551 5.80153 14.5395V9.85116ZM10.4295 12.2489C10.8648 12.6039 11.4341 12.778 11.9967 12.778C12.5593 12.778 13.1286 12.6039 13.5573 12.2489L15.6537 10.5745C15.8747 10.4071 15.9082 10.0856 15.734 9.87123C15.5666 9.65021 15.2451 9.61673 15.0308 9.79087L12.9344 11.4653C12.4254 11.8738 11.5614 11.8738 11.0524 11.4653L8.95599 9.79087C8.74166 9.61673 8.42687 9.65691 8.25273 9.87123C8.07859 10.0856 8.11877 10.4004 8.3331 10.5745L10.4295 12.2489Z"
        fill="currentColor"
      />
    </svg>
  );
};
