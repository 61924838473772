import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProjectApi, getProjectsApi } from "../api/myprojects";
// import { PreviewUI } from "../pages/admin/myprojects/PreviewUI/PreviewUI";
import { PreviewUI } from "../common";
import { getCuratorPath } from "../helpers/urlHelper";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearMyProjectDetails,
  myProjectDetailsSelector,
} from "../redux/slicers/myProjectDetails/myProjectDetailsSlicer";
import { MY_PROJECTS_ROUTE } from "../constants/routePathConstants";

export default function ProjectRender() {
  // const dispatch = useDispatch();
  const { projectId } = useParams();
  // console.log("projectId", projectId);
  // const navigate = useNavigate();
  // const [detailsVisible, setDetailsVisible] = useState(false);
  const { data, loading, error } = useSelector(myProjectDetailsSelector);
  // const { getProjectCameraAnglesList } = useSelector(
  //   projectCameraAngleSelector
  // );

  // useEffect(() => {
  //   dispatch(getProjectApi(projectId));
  //   return () => dispatch(clearMyProjectDetails());
  // }, [projectId, dispatch]);

  useEffect(() => {}, []);

  const actions = [
    {
      label: "Project info",
      type: "info",
      // onClick: () => setDetailsVisible(true),
    },
    {
      type: "comment",
      // onClick: () => alert("comments"),
    },
    {
      type: "edit",
      to: getCuratorPath(projectId, "project"),
      target: "_blank",
    },
    {
      type: "share",
      // onClick: () => alert("share"),
    },
    {
      type: "collection",
      // onClick: () => alert("add to collection"),
    },
    {
      type: "favourite",
      // onClick: () => alert("favourite"),
    },
    {
      type: "fullscreen",
    },
    {
      type: "delete",
      // onClick: () => alert("info"),
    },
    {
      type: "help",
      // onClick: () => alert("info"),
    },
  ];

  const sidebarCards = [
    {
      id: 1,
      name: "Camera name 1",
      thumbnail:
        "http://images.astronet.ru/pubd/2003/08/18/0001192435/orionfull_jcc_big.jpg",
    },
    {
      id: 2,
      name: "Camera name 2",
      thumbnail:
        "https://1.cms.s81c.com/sites/default/files/2020-11-18/big-data-analytics-leadspace_0.jpg",
    },
    {
      id: 3,
      name: "Camera name 3",
      thumbnail:
        "http://localhost:3100/static/media/room-1.379dc9e83eb77cc71d6e.png",
    },
    {
      id: 4,
      name: "Camera name 4",
      thumbnail:
        "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/avatar-2-1583234102.jpg",
    },
    {
      id: 5,
      name: "Camera name 5",
      thumbnail:
        "http://images.astronet.ru/pubd/2003/08/18/0001192435/orionfull_jcc_big.jpg",
    },
    {
      id: 6,
      name: "Camera name 6",
      thumbnail:
        "http://images.astronet.ru/pubd/2003/08/18/0001192435/orionfull_jcc_big.jpg",
    },
  ];

  // const updatedAt = useMemo(() => {
  //   if (!data?.updated_at) return null;
  //   return new Date(data?.updated_at).getTime();
  // }, [data?.updated_at]);

  return (
    <>
      <PreviewUI
        // title={data?.name}
        actions={actions}
        // onClose={() => navigate(MY_PROJECTS_ROUTE)}
        // loading={loading}
        // updatedAt={updatedAt}
        userName="Sasha"
        sidebarCards={sidebarCards}
      />
    </>
  );
}
