const MoreIcon = (props) => {
  return (
    <svg
      width="24"
      height="7"
      viewBox="0 0 24 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.38462 6.76923C1.51385 6.76923 0 5.25538 0 3.38462C0 1.51385 1.51385 0 3.38462 0C5.25538 0 6.76923 1.51385 6.76923 3.38462C6.76923 5.25538 5.25538 6.76923 3.38462 6.76923ZM3.38462 1.84615C2.53538 1.84615 1.84615 2.53538 1.84615 3.38462C1.84615 4.23385 2.53538 4.92308 3.38462 4.92308C4.23385 4.92308 4.92308 4.23385 4.92308 3.38462C4.92308 2.53538 4.23385 1.84615 3.38462 1.84615ZM20.6154 6.76923C18.7446 6.76923 17.2308 5.25538 17.2308 3.38461C17.2308 1.51385 18.7446 0 20.6154 0C22.4862 0 24 1.51385 24 3.38461C24 5.25538 22.4862 6.76923 20.6154 6.76923ZM20.6154 1.84615C19.7662 1.84615 19.0769 2.53538 19.0769 3.38461C19.0769 4.23385 19.7662 4.92308 20.6154 4.92308C21.4646 4.92308 22.1538 4.23385 22.1538 3.38461C22.1538 2.53538 21.4646 1.84615 20.6154 1.84615ZM8.61539 3.38462C8.61539 5.25538 10.1292 6.76923 12 6.76923C13.8708 6.76923 15.3846 5.25538 15.3846 3.38462C15.3846 1.51385 13.8708 0 12 0C10.1292 0 8.61539 1.51385 8.61539 3.38462ZM10.4615 3.38462C10.4615 2.53538 11.1508 1.84615 12 1.84615C12.8492 1.84615 13.5385 2.53538 13.5385 3.38462C13.5385 4.23385 12.8492 4.92308 12 4.92308C11.1508 4.92308 10.4615 4.23385 10.4615 3.38462Z"
        fill="#A3A4A6"
      />
    </svg>
  );
};

export default MoreIcon;
