import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { icons } from '../assets'
const { EditIcon } = icons;
export default function ViewEditItem({label, children, onUpdate, disabled, value, editing, setEditing}) {
  const [editItem, setEditItem] = useState(editing);

  return (
    <div className="input-border">
      <Form.Label>
        <div>
          <label className="detail-label">{label}</label>
        </div>
      </Form.Label>
      {editing ? (
        <div className="input-parent">
          {children}
          <div className="btn-div">
            <Button variant="primary" className="cancel-btn" onClick={() => setEditing(false)}>
              Cancel{' '}
            </Button>
            <Button
              variant="primary"
              className="save-btn"
              onClick={() => {
                setEditing(false);
                onUpdate();
              }}
              disabled={disabled}>
              Save
            </Button>
          </div>
          <div className="adjust-div"></div>
        </div>
      ) : (
        <div className="viewEditRow">
          <span className="detail-info">{value || ""}</span>
          <Button className="greyEditBtn" onClick={() => setEditing(true)}>
            <img src={EditIcon} />Edit
          </Button>
        </div>
      )}
    </div>
  );
}
