import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  curatorSelector,
  setCuratorSettingData,
  SETTINGS_SECTION,
} from '../../../../../redux/slicers/admin/curatorSlicer';
import Button from '../../../../Button/Button';
import { useUnityContext } from '../../../../container/unityContainer';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import settingStyles from './Setting.module.scss';

const MeasureUnit = ({ onSectionToggle }) => {
  const dispatch = useDispatch();
  const { handleMeasureUnitChange } = useUnityContext();

  const { settingData } = useSelector(curatorSelector);

  const handleChangeData = (selectedItem) => {
    const tempData = settingData?.json_data?.measureUnit?.map((item) => {
      return item.id === selectedItem.id
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false };
    });

    dispatch(
      setCuratorSettingData({
        ...settingData,
        json_data: { ...settingData?.json_data, measureUnit: tempData },
      })
    );

    // update unity data
    handleMeasureUnitChange(selectedItem?.id - 1);
  };

  return (
    <CuratorAccordion.Item eventKey={SETTINGS_SECTION.DEFAULT_MEASURE_UNITS}>
      <CuratorAccordion.Header
        onClick={() => onSectionToggle(SETTINGS_SECTION.DEFAULT_MEASURE_UNITS)}>
        Default measure units
      </CuratorAccordion.Header>
      <CuratorAccordion.Body>
        <div className="d-flex justify-content-between gap-3">
          {settingData?.json_data?.measureUnit?.map((item, index) => (
            <Button
              key={index}
              color="light"
              className={settingStyles.selectButton}
              fullWidth
              active={item.isSelected}
              onClick={() => handleChangeData(item)}>
              {item.name}
            </Button>
          ))}
        </div>
      </CuratorAccordion.Body>
    </CuratorAccordion.Item>
  );
};

// background: #e9e9e9;
//               color: #2d3136;

export default React.memo(MeasureUnit);
