export const CommentIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7952 18.5586C11.1033 18.966 11.5386 19.1998 12.0008 19.1998C12.4629 19.1998 12.8983 18.966 13.2064 18.5586L14.211 17.2228C14.2445 17.1827 14.2914 17.156 14.345 17.156H14.6799C17.6402 17.156 19.2008 15.5998 19.2008 12.6477V9.30815C19.2008 6.35602 17.6402 4.7998 14.6799 4.7998H9.32171C6.36134 4.7998 4.80078 6.35602 4.80078 9.30815V12.6477C4.80078 16.4013 6.52878 17.156 9.32171 17.156H9.6566C9.69008 17.1627 9.77046 17.1961 9.79055 17.2228L10.7952 18.5586ZM5.80543 9.30815C5.80543 6.91706 6.92394 5.80166 9.32171 5.80166H14.6799C17.0776 5.80166 18.1961 6.91706 18.1961 9.30815V12.6477C18.1961 15.0388 17.0776 16.1542 14.6799 16.1542H14.345C13.9766 16.1542 13.6283 16.3278 13.4073 16.6217L12.4026 17.9575C12.1682 18.2647 11.8333 18.2647 11.5989 17.9575L10.5943 16.6217C10.3866 16.3479 9.99818 16.1542 9.6566 16.1542H9.32171C6.84357 16.1542 5.80543 15.6666 5.80543 12.6477V9.30815Z"
        fill="currentColor"
      />
    </svg>
  );
};
