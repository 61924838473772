import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HorizontalCarousel } from '../common';

export default function GridSection(props) {
  return (
    <Container className="px-3 pb-4 grid-section" fluid>
      {props.title ? (
        <Row className="mb-2">
          <Col className="carousel-card-header">
            <h5 className="mb-0 head-h5">{props.title}</h5>
            {props.seeAllLink && (
              <Link className="see_all_link" to={props.seeAllLink}>
                See all
              </Link>
            )}
          </Col>
        </Row>
      ) : null}
      <Row className="w-100">
        <Col>
          <HorizontalCarousel data={props.carouselData} />
        </Col>
      </Row>
    </Container>
  );
}
