import { createSlice } from "@reduxjs/toolkit";

const myRenderPreview = createSlice({
  name: "myRenderPreview",
  initialState: {
    myRenderPreviewData: {},
    data: {},
    list: [],
    hasData: false,
    page: 1,
    perPage: 20,
    loading: false,
  },
  reducers: {
    setMyRenderPreviewData: (state, { payload }) => {
      state.myRenderPreviewData = payload;
    },
    fetchMyRenderPreviewListStart: (state) => {
      state.loading = true;
    },
    fetchMyRenderPreviewListSuccess: (state, { payload }) => {
      state.page = payload.page;
      state.data = payload.data;
      state.list = [...state.list, ...payload.data.results]; // TODO: leave only unique items in the array
      state.loading = false;
      state.hasData = payload.data.count > 0;
    },
    fetchMyRenderPreviewListFailed: (state) => {
      state.loading = false;
    },
    resetMyRenderPreviewList: (state) => {
      state.list = [];
      state.page = 1;
    },
    addRenderPreviewSuccess: (state, { payload }) => {
      state.list = [payload, ...state.list];
    },
    updateRenderPreview: (state, { payload }) => {
      state.list = state.list.map((renderPreviews) => {
        if (renderPreviews.id === payload.id) {
          return {
            ...renderPreviews,
            ...payload,
          };
        }

        return renderPreviews;
      });
    },
  },
});

export const myRenderPreviewActions = myRenderPreview.actions;
export const { setMyRenderPreviewData } = myRenderPreview.actions;
export const myRenderPreviewSelector = (state) => state.myRenderData;
export const myRenderPreviewReducer = myRenderPreview.reducer;
export default myRenderPreviewReducer;
