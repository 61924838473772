// this component handle the search page filter sidebar
import cx from 'classnames';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, matchPath, useLocation } from 'react-router-dom';
import DownArrow from '../assets/images/myprojects/downarrow.png';
import OrgLogo from '../assets/images/organisation.svg';
import styles from '../assets/scss/modules/SideFilter.module.scss';
import { FilterItem } from '../common';
import constants from '../constants/constants';
import { SIDEBAR_MENU, SIDEBAR_MENU_2 } from '../constants/menu';
import { FilterContext, UserContext } from '../contexts';
import { apiCreateOrganization, apiGetAllMyOrgs, apiSwitchOrg } from '../helpers/api';
import { setLocalStorage } from '../helpers/jsHelper';

const { PROJECT_TYPE_OPTIONS, FILTER_TYPES, MODULE_TYPES } = constants;

function CommonFeatureMenu({ navList, showDivider }) {
  const location = useLocation();
  return (
    <Navbar expand="xs" className="sidemenu page-nav flex-column align-items-start ms-3 w-75">
      {showDivider && <NavDropdown.Divider className="w-100 me-3" />}
      <Nav as="ul" className="menu w-100 pb-2 ps-2">
        {navList.map((item, i) => (
          <Nav.Item
            as="li"
            key={item.path}
            className={cx(
              {
                active: matchPath({ path: item.path }, location?.pathname) !== null,
              },
              'pb-1 ',
              'sidebarNav  font16'
            )}>
            <Link to={item.path} className="nav-link my-2">
              <Image
                src={item.icon}
                className="me-3"
                style={{ height: '20px', width: '20px', borderRadius: '0px' }}
              />
              {item.name}
            </Link>
          </Nav.Item>
        ))}
      </Nav>
    </Navbar>
  );
}

export default function Sidemenu(props) {
  const filterCtx = useContext(FilterContext);
  const userCtx = useContext(UserContext);
  const location = useLocation();
  const [orgArr, setOrgArr] = useState([]);
  const [orgName, setOrgName] = useState('Organisation');
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const [showInput, setShowInput] = useState(false);
  // const [otherFilters, setOtherFilters] = useState([]);

  useEffect(() => {
    if (userCtx.currentUser && orgArr && orgArr.length < 1) {
      getOrgs();
    }
  }, [userCtx.currentUser, orgArr]);

  useEffect(() => {
    setShowOrgDropdown(false);
  }, [location]);

  async function getOrgs() {
    const res = await apiGetAllMyOrgs();
    setOrgArr(res.results);
    if (res && res.results) {
      setOrgArr(res.results);
    }
  }

  async function handleOrgClick(orgId) {
    const res = await apiSwitchOrg(orgId);
    setLocalStorage('token', res.access);
    userCtx.setToken(res.access);
    setLocalStorage('refresh_token', res.refresh);
    userCtx.setRefreshToken(res.refresh);
    setLocalStorage('current_user', res.info);
    userCtx.setCurrentUser(res.info);
    setShowInput(false);
    setShowOrgDropdown(false);
  }

  const handleToggle = () => {
    setShowOrgDropdown(!showOrgDropdown);
  };

  // let formData = {};
  // const handleChange = (a) => {
  //   const { name, value } = a;
  //   formData[name] = value
  // }

  async function handleSave() {
    const res = await apiCreateOrganization(newOrgName, userCtx.currentUser.id);
    getOrgs();
    setShowInput(false);
    setNewOrgName('');
  }

  const handleCancel = () => {
    setShowInput(false);
  };
  // const handleOrgName = (a) => {
  //   const { name, value } = a;
  //   if (value) {
  //       setOrgName(name)
  //   } else {
  //       setOrgName('Organisation')
  //   }
  // }

  if (!filterCtx || filterCtx.hideSidemenu) return null;
  const { showDrilldownMenu, globalFilters } = filterCtx;
  const { is_favourite, orderedFilters } = globalFilters;
  return (
    <aside className="sidebar">
      <div className="sidebar-wrapper filter-sidebar-wrapper">
        <div className="sidebar-filter fs-container filter_container">
          <>
            <header className="fullWidth cursorPointer">
              <a className="organization-toggle cursorPointer" onClick={() => handleToggle()}>
                <span>
                  <img src={OrgLogo} alt="" />
                </span>
                <span className="menu-label ps-2">
                  {' '}
                  {userCtx.currentUser?.organization_detail?.name}{' '}
                </span>
                <span role="button" className="downarrow ms-2">
                  <img
                    src={DownArrow}
                    alt=""
                    style={showOrgDropdown ? null : { transform: 'rotate(180deg)' }}
                  />
                </span>
              </a>
            </header>
            <nav
              className="menu w-100 p-2 org-switch"
              style={{ display: showOrgDropdown ? 'block' : 'none' }}>
              <Card>
                <Card.Header className="p-3">
                  <span className="text-muted">Organization</span>
                </Card.Header>
                <Card.Body>
                  <ul>
                    {showInput ? (
                      <div>
                        <Form.Control
                          type="text"
                          name="orgname"
                          value={newOrgName}
                          onChange={(e) => setNewOrgName(e.target.value)}
                          placeholder="Enter name..."
                        />
                        <div className="d-flex py-2">
                          <div className="d-grid w-100 pe-1">
                            <Button variant="outline-danger" onClick={handleCancel}>
                              Cancel
                            </Button>
                          </div>
                          <div className="d-grid w-100 ps-1">
                            <Button variant="warning" onClick={handleSave}>
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <li>
                        <div
                          role="button"
                          className="text-primary py-2"
                          onClick={() => setShowInput(true)}>
                          + Add Organization
                        </div>
                      </li>
                    )}
                    {orgArr.map((a, i) => (
                      <li
                        key={`orgItem${i}`}
                        role="button"
                        className="py-2"
                        onClick={() => handleOrgClick(a.id)}>
                        {a.name}
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </nav>
          </>
          {showDrilldownMenu ? (
            <>
              <div className={styles.root}>
                <FilterItem
                  title="My Favorites"
                  is_favourite={is_favourite}
                  type="is_favourite"
                  onChange={filterCtx.toggleFavorite}
                  key={`dropdownSidemenuFavorite`}
                />
              </div>
              <div className={styles.root}>
                {orderedFilters && orderedFilters.length > 0
                  ? _.map(orderedFilters, (filter, index) => {
                      return (
                        <FilterItem
                          title={_.startCase(filter.key)}
                          list={filter.list}
                          type={filter.key}
                          onChange={(item) => {
                            filterCtx.selectGlobalFilter(filter.key, item?.id);
                          }}
                          key={`dropdownSidemenu${filter.key}${index}`}
                        />
                      );
                    })
                  : null}
              </div>
            </>
          ) : (
            <div className="feature-nav">
              <CommonFeatureMenu navList={SIDEBAR_MENU} />
              <hr className="sidebarMenuLine mx-4" />
              <CommonFeatureMenu navList={SIDEBAR_MENU_2} />
            </div>
          )}
        </div>
      </div>
    </aside>
  );
}
