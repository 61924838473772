import { useEffect } from 'react';
import { setTitle } from '../helpers/jsHelper';
import { Row, Col } from 'react-bootstrap';

export default function ComingSoon(props) {
  useEffect(() => {
    setTitle(props.name);
  }, []);
  return (
    <Row className="p-5 p-5 fullWidth">
      <Col xs={9}>
        <h3>{props.name} Feature</h3>
        <h3> Coming Soon!</h3>
      </Col>
      <Col className="d-none d-xl-grid">{/* <SignInCarousal/> */}</Col>
    </Row>
  );
}
