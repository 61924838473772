/* eslint-disable no-debugger */
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { usePrevious } from 'react-use';
import { MAX_RESOLUTION } from '../../../../../config/constant/unityConstants';
import { stripNonNumbers } from '../../../../../helpers/jsHelper';
import { errorToast } from '../../../../../helpers/toastHelper';
import {
  selectResolutionPresets,
  setSelectedResolutionPreset,
  updateResolutionPreset,
} from '../../../../../redux/slicers/admin/curatorSlicer';
import {
  setGlobalHeight,
  setGlobalWidth,
} from '../../../../../redux/slicers/camera/cameraSettings';

export const useResolutionHandlers = ({
  data,
  keyX = 'width',
  keyY = 'height',
  defaultX,
  defaultY,
  updateCallback,
  min,
  max,
  valueFormatter = parseFloat,
}) => {
  const selectedItem = data;
  const ratioRef = useRef(1);
  const lock = data?.lock || false;
  const prevLock = usePrevious(data?.lock || false);

  const changeCallback = (data, eventDetails) => {
    const formattedData = {
      [keyX]:
        typeof data[keyX] === 'string' && data[keyX].trim() === ''
          ? data[keyX]
          : valueFormatter(data[keyX]),
      [keyY]:
        typeof data[keyY] === 'string' && data[keyY].trim() === ''
          ? data[keyY]
          : valueFormatter(data[keyY]),
    };
    updateCallback(formattedData, eventDetails);
  };

  useEffect(() => {
    if (!prevLock && lock) {
      ratioRef.current = data[keyX] / data[keyY];
    } else {
      ratioRef.current = 1;
    }
  }, [lock]);

  const handleResolutionChange = (e) => {
    let { name, value } = e.target;

    if (max && `${value}`.length > `${max}`.length) {
      return;
    }

    const data = {
      [keyX]: selectedItem[keyX],
      [keyY]: selectedItem[keyY],
    };

    if (value.trim() === '') {
      if (lock) {
        data[keyX] = value;
        data[keyY] = value;
      } else {
        data[name] = value;
      }
      changeCallback(data, { type: 'change' });
      return;
    }

    value = parseFloat(value, 10) || 0;

    if (lock) {
      if (name === keyX) {
        // const currentRatio = data[keyX] ? value / data[keyX] : ratio;
        const height = parseFloat(value / ratioRef.current);
        data[keyX] = value;
        // data[keyY] = height < 1 ? 1 : height;
        data[keyY] = height;
      } else {
        const width = parseFloat(value * ratioRef.current);
        data[keyY] = value;
        // data[keyX] = width < 1 ? 1 : width;
        data[keyX] = width;
      }
    } else {
      data[name] = value;
    }

    changeCallback(data, { type: 'change' });
  };

  const handleResolutionBlur = (e) => {
    let { name, value } = e.target;

    if (value.trim() === '' || parseFloat(value) === 0 || parseFloat(value) < min) {
      const data = {
        [keyX]: selectedItem[keyX] || defaultX,
        [keyY]: selectedItem[keyY] || defaultY,
      };

      if (data[keyX] < min) {
        data[keyX] = defaultX;
      }

      if (data[keyY] < min) {
        data[keyY] = defaultY;
      }

      if (lock) {
        if (ratioRef.current > 1) {
          data[keyX] = parseFloat(ratioRef.current * data[keyX]);
        } else {
          data[keyY] = parseFloat(data[keyX] / ratioRef.current);
        }
      }

      changeCallback(data, { type: 'blur' });
      return;
    }
    
    const data = {
      [keyX]: selectedItem[keyX],
      [keyY]: selectedItem[keyY],
    };

    changeCallback(data, { type: 'blur' });
  };

  return {
    handleResolutionBlur,
    handleResolutionChange,
  };
};

export const useResolutionPresets = () => {
  const dispatch = useDispatch();
  const resolutionPresets = useSelector(selectResolutionPresets);
  const selectedItem = resolutionPresets?.find((rp) => rp.isSelected);

  const updateResolution = (data) => {
    dispatch(
      updateResolutionPreset({
        id: selectedItem.id,
        ...data,
      })
    );

    dispatch(setGlobalWidth(data.width));
    dispatch(setGlobalHeight(data.height));
  };

  const { handleResolutionBlur, handleResolutionChange } = useResolutionHandlers({
    data: selectedItem,
    keyX: 'width',
    keyY: 'height',
    defaultX: 1,
    defaultY: 1,
    valueFormatter: parseInt,
    updateCallback: updateResolution,
  });

  const handleSelect = (item) => () => {
    if (!selectedItem.name.trim()) {
      errorToast('Resolution name is required', {
        toastId: 'RESOLUTION_NAME_REQUIRED' + selectedItem.id,
      });
      return;
    }

    dispatch(setSelectedResolutionPreset(item.id));
    dispatch(setGlobalWidth(item.width));
    dispatch(setGlobalHeight(item.height));
  };

  const toggleLock = () => {
    dispatch(
      updateResolutionPreset({
        id: selectedItem.id,
        lock: !selectedItem.lock,
      })
    );
  };

  return {
    resolutionPresets,
    selectedItem,
    toggleLock,
    handleBlur: handleResolutionBlur,
    handleChange: handleResolutionChange,
    handleSelect,
  };
};
