import { createContext } from "react";

const FilterContext = createContext({
  hideSidemenu: false,
  globalFilters: {},
  setGlobalFilters: () => {},
  showDrilldownMenu: false,
  refreshOptions: () => {},
  selectGlobalFilter: () => {},
  clearGlobalFilters: () => {},
  teamMembers: null,
  setTeamMembers: () => {},
  categoryOptions: null,
  handleFilterOptions: () => {},
  toggleFavorite: () => {},
  breadcrumbs: null,
  setBreadCrumbs: () => {},
  collections: null,
  setCollections: () => {},
  cleanGlobalFiltersOnNavigate: () => {},
});

export default FilterContext;
