import { AiFillUnlock } from "react-icons/ai";
import { LockIcon } from "../../../icons/LockIcon";

export const Lock = ({ active, className, onClick }) => {
  return active ? (
    <LockIcon className={className} color="#A3A4A6" onClick={onClick} />
  ) : (
    <AiFillUnlock
      className={className}
      onClick={onClick}
      style={{ fontSize: 24 }}
    />
  );
};
