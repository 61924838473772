const CloseCircleIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99687 15.1998C4.02515 15.1998 0.796875 11.9715 0.796875 7.9998C0.796875 4.02808 4.02515 0.799805 7.99687 0.799805C11.9686 0.799805 15.1969 4.02808 15.1969 7.9998C15.1969 11.9715 11.9686 15.1998 7.99687 15.1998ZM7.99687 1.80446C4.58106 1.80446 1.80153 4.58399 1.80153 7.9998C1.80153 11.4156 4.58106 14.1952 7.99687 14.1952C11.4127 14.1952 14.1922 11.4156 14.1922 7.9998C14.1922 4.58399 11.4127 1.80446 7.99687 1.80446ZM6.10134 10.3975C5.97408 10.3975 5.84683 10.3506 5.74636 10.2501C5.55213 10.0559 5.55213 9.73441 5.74636 9.54018L7.28683 7.99971L5.74636 6.45925C5.55213 6.26501 5.55213 5.94353 5.74636 5.74929C5.9406 5.55506 6.26208 5.55506 6.45632 5.74929L7.99678 7.28976L9.53725 5.74929C9.73148 5.55506 10.053 5.55506 10.2472 5.74929C10.4414 5.94353 10.4414 6.26501 10.2472 6.45925L8.70674 7.99971L10.2472 9.54018C10.4414 9.73441 10.4414 10.0559 10.2472 10.2501C10.1467 10.3506 10.0195 10.3975 9.89222 10.3975C9.76497 10.3975 9.63771 10.3506 9.53725 10.2501L7.99678 8.70967L6.45632 10.2501C6.36255 10.3506 6.2286 10.3975 6.10134 10.3975Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseCircleIcon;
