import { useSelector } from "react-redux";
import { curatorLightSelector } from "../../../../../redux/slicers/admin/curatorLightSlicer";
import { ColorPicker } from "../../ColorPicker/ColorPicker";
import FormRange from "../../FormElements/FormRange";
import { useUnityHDRI } from "./useUnityHDRI";


export const EnvironmentColor = () => {
  const { updateColorIntesity, updateColor } = useUnityHDRI();
  const intensity = useSelector(
    (state) => curatorLightSelector(state).environmentColorIntensity
  );
  const color = useSelector(
    (state) => curatorLightSelector(state).environmentColor
  );

  return (
    <>
      <ColorPicker 
        className="mb-3 w-100"
        color={color}
        onChange={color => {
          updateColor(color.hex)
        }}
      />

      <FormRange
        label="Intensity"
        value={intensity}
        min="0"
        step={1}
        max="5"
        name="colorIntensity"
        onChange={(e) => updateColorIntesity(e.target.value)}
      />
    </>
  );
};
