import { useDispatch } from 'react-redux';
import { unzip } from 'unzipit';
import { toast } from 'react-toastify';
import { setFile } from '../../../../redux/slicers/admin/curatorProjectArchiveSlicer';
import { useNavigate } from 'react-router-dom';
import { getCuratorPath } from '../../../../helpers/urlHelper';
import { OPEN_PROJECT_ID } from '../../../../config/constant/curatorConstants';
import { curatorLightActions } from '../../../../redux/slicers/admin/curatorLightSlicer';
import { curatorMetadataActions } from '../../../../redux/slicers/admin/curatorMetadataSlicer';
import { curatorActions, setCuratorLoader } from '../../../../redux/slicers/admin/curatorSlicer';
import { curatorStyleActions } from '../../../../redux/slicers/admin/curatorStylesSlicer';
import { curatorUnityObjectActions } from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { setProjectZip } from '../../../../helpers/idb';
import { errorToast } from '../../../../helpers/toastHelper';

export const useOpenProjectFromFile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const triggerFileSelection = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.zip';
    input.style.position = 'absolute';
    input.style.transform = 'translateX(-10000px)';
    input.addEventListener('change', (event)  => {
      const file = event.target?.files?.[0];
      if (!file || !file.name.endsWith('.zip')) {
        errorToast('Please select a zip file');
        return;
      }

      openProjectFromFile(file);
    })

    input.addEventListener('blur', () => {
      document.body.removeChild(input)
    })

    document.body.appendChild(input);
    input.click()
  };

  const openProjectFromFile = async (file) => {
    if (!file.name.endsWith('.zip')) {
      errorToast('File format not supported. Supported file format is .zip');
      return;
    }

    const { entries } = await unzip(file);
    const filePathList = Object.keys(entries);
    const jsonFilePath = filePathList.find((p) => p.endsWith('.json'));

    if (!jsonFilePath) {
      errorToast('Incorrect archive data. JSON configuration not found');
      return;
    }

    try {
      await setProjectZip(file);
    } catch (e) {
      // add logger here
      console.error(e);
      // fail silently, worst case scenario we will not show project to the user after refreshing the page
    }

    navigate(getCuratorPath(OPEN_PROJECT_ID, 'room'));
    // reset curator state
    dispatch(curatorLightActions.resetState());
    dispatch(curatorMetadataActions.resetState());
    dispatch(curatorActions.resetState());
    dispatch(curatorStyleActions.resetState());
    dispatch(curatorUnityObjectActions.resetState());
    dispatch(setCuratorLoader(false));
    dispatch(setFile(file));
  };

  return {
    openProjectFromFile,
    triggerFileSelection,
  };
};
