import { useRef } from 'react';

export const useUndoRedoInput = ({ value, onFocus, onBlur, data, undoRedoAction, name }) => {
  const valueRef = useRef(null);

  const handleFocus = (e) => {
    valueRef.current = value;
    onFocus?.(e);
  };

  const handleBlur = (e) => {
    undoRedoAction?.({
      data,
      name: name || e.target.name,
      prevValue: valueRef.current,
      nextValue: value,
    });
    onBlur?.(e);
  };

  return {
    handleBlur,
    handleFocus,
  };
};
