
import { useSelector } from 'react-redux';
import { selectExportProjectLoading } from '../../../redux/slicers/admin/curatorLoaderSlicer';
import { selectIsLoading } from '../../../redux/slicers/admin/curatorSlicer';
import Loader from '../../Loader/Loader';

export const CuratorLoader = () => {
  const loading = useSelector(selectIsLoading)
  const exportProjectLoading = useSelector(selectExportProjectLoading)

  if (!loading && !exportProjectLoading) return null;

  return <Loader loading={true} position="absolute" backdrop={false} />
}