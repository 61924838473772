import api from '../helpers/axiosHelper';

import {
  API_ROOT_URL,
  ADD_FAVOURITE,
  PROJECT_DELETE,
  ROOM_DELETE,
  ADD_FAVOURITE_PROJECTS,
  ADD_FAVOURITE_ROOM,
  UPDATE_RENDER,
  UPDATE_RENDER_PREVIEW,
  GET_FILTERS,
  CREATE_FILTER_OPTION,
} from '../config/constant/apiConstant';
import { setLayoutData, setRoomData } from '../redux/slicers/admin/curatorSlicer';
import { navigate } from '../helpers/projectHelper';
import { successToast, errorToast } from '../helpers/toastHelper';
import { getProjectsApi, getRoomsApi } from './myprojects';
import { setFilterData } from '../redux/slicers/filters/filterSlicer';
import { setRenderPreviewExpand } from '../redux/slicers/renders-previews/rendersPreviewsSlicer';
import { objectToSearchParams } from '../helpers/jsHelper';
import { paginatedRenderPreviewsActions } from '../redux/slicers/admin/paginatedRenderPreviewsSlicer';
import { toast } from 'react-toastify';
import { LOADING_KEY } from '../helpers/reduxHelper';

export const addToFavouriteProject =
  ({ isFavourite, id }) =>
  (dispatch) => {
    const formData = new FormData();
    formData.append('is_favourite', isFavourite);

    api()
      .root(API_ROOT_URL)
      .patch(`${ADD_FAVOURITE_PROJECTS}${id}/`)
      .data(formData)
      .success((data) => {
        dispatch(getProjectsApi());
        if (data.is_favourite) {
          successToast('Added To Favourite');
        } else {
          successToast('Removed From Favourite');
        }
      })
      .error(() => {})
      .upload(() => {});
  };
export const addToFavouriteRoom =
  ({ isFavourite, id }) =>
  (dispatch) => {
    const formData = new FormData();
    formData.append('is_favourite', isFavourite);

    api()
      .root(API_ROOT_URL)
      .patch(`${ADD_FAVOURITE_ROOM}${id}/`)
      .data(formData)
      .success((data) => {
        dispatch(getProjectsApi());
        console.log(data.data);
        if (data.data.is_favourite) {
          successToast('Added To Favourite');
        } else {
          successToast('Removed From Favourite');
        }
      })
      .error(() => {})
      .upload(() => {});
  };
// project delete
export const projectDeleteApi = (id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .delete(`${PROJECT_DELETE}${id}/`)
    .success((data) => {
      dispatch(getProjectsApi());
    })
    .error(() => {})
    .upload(() => {});
};

// room delete
export const roomDeleteApi = (id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .delete(`${ROOM_DELETE}${id}/`)
    .success((data) => {
      dispatch(getRoomsApi());
    })
    .error(() => {})
    .upload(() => {});
};

// render & preview
export const addToFavouriteRenderPreview = (formData, id) => (dispatch) => {
  dispatch(
    paginatedRenderPreviewsActions.setItemLoading({
      id,
      key: LOADING_KEY.FAVOURITE,
      value: true,
    })
  );

  api()
    .root(API_ROOT_URL)
    .patch(`${UPDATE_RENDER_PREVIEW}${id}/`)
    .data(formData)
    .success((data) => {
      // dispatch(getProjectsApi());
      console.log('render * preview fav data', data.data);
      dispatch(paginatedRenderPreviewsActions.update(data.data));

      if (data.data.is_favourite) {
        successToast('Added To Favourite');

        // if(data.data.is_main_render)
      } else {
        successToast('Removed From Favourite');
      }
    })
    .error((error) => {
      console.log('render * preview fav error', error);
    })

    .finally(() => {
      dispatch(
        paginatedRenderPreviewsActions.setItemLoading({
          id,
          key: LOADING_KEY.FAVOURITE,
          value: false,
        })
      );
    })
    .send();
};

// render & preview
export const deleteRenderPreviewAPI = (id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .delete(`${UPDATE_RENDER_PREVIEW}${id}/`)
    .success((data) => {
      console.log('delete data', data);
      successToast('Deleted');
    })
    .error((error) => {
      console.log('delete error', error);
      errorToast('Unable to delete.');
    })
    .send();
};

// Filter APIs
export const getFiltersApi =
  (queryParams = {}, cb) =>
  (dispatch) => {
    const params = objectToSearchParams(queryParams);
    api()
      .root(API_ROOT_URL)
      .get(`${GET_FILTERS}?${params}`)
      .success((data) => dispatch(setFilterData(data)))
      .error((e) => console.error(`Error while fetching filters: ${e.message}`))
      .send(cb);
  };

window.getFiltersApi = getFiltersApi;

export const addFilterOptionApi = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .post(CREATE_FILTER_OPTION)
    .data(formData)
    .success((data) => dispatch(getFiltersApi()))
    .error((e) => errorToast(`Error occured: ${e.message}`))
    .send();
};
