import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalSafeCameraFlag } from '../../../../redux/slicers/camera/cameraSettings';
import { useUnityContext } from '../../../container/unityContainer';
import styles from './CameraList.module.scss';

export const CameraList = ({
  cameraList,
  selected,
  allSelected,
  onToggleAll,
  onToggle,
  onCameraClick,
  className,
}) => {
  const dispatch = useDispatch()
  const { handleSelectedCamera } = useUnityContext();
  const manyItesm = cameraList?.length > 1;

  const handleCameaClick = (camera) => () => {
    handleSelectedCamera(camera)
    dispatch(setGlobalSafeCameraFlag(true))
    onCameraClick?.(camera)
  }

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.listItem}>
        <div className="form-check custom-checkbox d-flex flex-wrap align-items-center mb-0">
          <input
            className="form-check-input"
            type="checkbox"
            checked={allSelected}
            onChange={onToggleAll}
          />
          <label className={styles.label}>
            Select all <span className={styles.infoText}>({cameraList?.length} {manyItesm ? 'cameras' : 'camera'})</span>
          </label>
        </div>
      </div>
      {cameraList?.map((a, i) => (
        <div className={styles.listItem} key={a.id} onClick={handleCameaClick(a)}>
          <div className={`form-check custom-checkbox ${styles.formCheck}`}>
            <input
              className="form-check-input"
              type="checkbox"
              checked={selected.includes(a.id)}
              onChange={(e) => {
                e.stopPropagation();
                onToggle(a);
              }}
            />
          </div>
          <label className={styles.label}>{a.cameraName}</label>
          <span className={`${styles.resolution} ${styles.infoText}`}>
            {a.resoX} x {a.resoY}
          </span>
        </div>
      ))}
    </div>
  );
};
