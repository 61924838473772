export const LocationArrowIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.3222 18.2313L5.80278 4.67314C5.6965 4.3543 5.86882 4.00966 6.18766 3.90338C6.31258 3.86174 6.44763 3.86174 6.57254 3.90338L20.1307 8.42278C20.4496 8.52906 20.6219 8.87369 20.5156 9.19253C20.4636 9.34864 20.3505 9.47701 20.2023 9.5484L14.293 12.3936L11.4478 18.3029C11.302 18.6057 10.9383 18.733 10.6355 18.5872C10.4872 18.5158 10.3742 18.3874 10.3222 18.2313Z"
        stroke="currentColor"
      />
    </svg>
  );
};
