const CollectionIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0069 4.7998C8.29077 4.7998 6.94538 5.13965 6.043 6.04202C5.14062 6.9444 4.80078 8.2898 4.80078 10.006V13.9937C4.80078 15.7098 5.14062 17.0552 6.043 17.9576C6.94538 18.86 8.29077 19.1998 10.0069 19.1998H13.9946C15.7108 19.1998 17.0562 18.86 17.9586 17.9576C18.8609 17.0552 19.2008 15.7098 19.2008 13.9937V10.006C19.2008 8.2898 18.8609 6.9444 17.9586 6.04202C17.0562 5.13965 15.7108 4.7998 13.9946 4.7998H10.0069ZM5.90847 10.006C5.90847 8.39905 6.23325 7.41829 6.82626 6.82528C7.41926 6.23227 8.40002 5.9075 10.0069 5.9075H11.4008V11.3998H5.90847V10.006ZM5.90847 12.5998V13.9937C5.90847 15.6006 6.23325 16.5813 6.82626 17.1743C7.41926 17.7673 8.40002 18.0921 10.0069 18.0921H11.4008V12.5998H5.90847ZM12.6008 12.5998V18.0921H13.9946C15.6015 18.0921 16.5823 17.7673 17.1753 17.1743C17.7683 16.5813 18.0931 15.6006 18.0931 13.9937V12.5998H12.6008ZM18.0931 11.3998V10.006C18.0931 8.39905 17.7683 7.41829 17.1753 6.82528C16.5823 6.23227 15.6015 5.9075 13.9946 5.9075H12.6008V11.3998H18.0931Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CollectionIcon;
