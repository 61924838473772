import { uniqueId } from "lodash";
import { TEMP_CAMERA_ID_PREFIX } from "../../../../config/constant/unityConstants";
import { setProjectCameraAnglesList } from "../../../../redux/slicers/camera/projectCameraAngleSlicer";

export const parseV1ZipCameras = (cameraMap, dispatch, roomId) => {
  const parsedCameraList = Object.entries(cameraMap).map(([name, camera]) => {
    return {
      id: uniqueId(TEMP_CAMERA_ID_PREFIX),
      cameraName: name,
      distanceToSet: camera.distanceToSet,
      fov: parseInt(camera.fov),
      xTransRot: camera.xRotation,
      yTransRot: camera.yRotation,
      zTransRot: camera.zRotation,
      centerObjectxPos: camera.centerXPosition,
      centerObjectyPos: camera.centerYPosition,
      centerObjectzPos: camera.centerZPosition,
      isCameraCorrection: camera.isCameraCorrection,
      lensShiftY: camera.lens_shiftY,
      cameraHeight: 0,
      cameraRotX: camera.camera_RotX,
      cameraRotZ: camera.camera_RotZ,
      focalLength: camera.focalLength,
      isdepthOfField: false,
      selectedObjectName: '',
      blurIntensity: 0,
      resoX: camera.resoX,
      resoY: camera.resoY,
      xPos: camera.xPosition,
      yPos: camera.yPosition,
      zPos: camera.zPosition,
      room: roomId,
    };
  });

  dispatch(setProjectCameraAnglesList(parsedCameraList));
};
