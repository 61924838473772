/* eslint-disable no-debugger */
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AXIS, CURSOR_MODE, TRANSFORM_SPACE_MODE, UNITY_2D_VIEW_MODE } from '../../../../config/constant/unityConstants';
import { updateDbProp } from '../../../../helpers/idb';
import { errorToast, successToast } from '../../../../helpers/toastHelper';
import { getProjectsPath } from '../../../../helpers/urlHelper';
import { useInfoDialogaActions } from '../../../../hooks/useInfoDialogaActions';
import { useRenderPreviewPopup } from '../../../../hooks/useRenderPreviewPopup';
import * as curatorLight from '../../../../redux/slicers/admin/curatorLightSlicer';
import { CURATOR_MENU_SECTION } from '../../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import { curatorActions, curatorSelector, SAVE_PROJECT_MODE, selectAxisAvailability, selectCuratorInitialized, selectCursorMode, selectIs2DMode, selectIs3DMode, selectMeasureUnit, selectSelectedAxis, selectSidebarSection, selectTransformSpaceMode, setCuratorLoader, setCursorMode, setSaveProjectDialogOpen, setSaveProjectMode, setSidebarSection, setUnity2DViewMode, toggleDimension, toggleSidebarSection } from '../../../../redux/slicers/admin/curatorSlicer';
import { curatorStylesSelector, setActiveTab, TAB_NAME } from '../../../../redux/slicers/admin/curatorStylesSlicer';
import { resetObjectPositionAlongAxis, selectCanReplaceProp, selectHasSelectedObjectInfo, selectIsAnyObjectSelected, selectIsOneObjectSelected, selectIsSingleLightObjectSelected, selectIsStyleableObject, selectReplaceProp, selectSeclectedObjectList, selectSelectedLightObject, setSelectedObjectList } from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { paginatedProductActions } from '../../../../redux/slicers/admin/paginatedProductSlicer';
import { selectHighlightSelected, selectIsMaterialCopied, selectLightsVisibility, selectPopupOpen, selectShowDimensions, setHighlightSelected, setLightsVisibility, setMaterialCopied, setPopupOpen, setReplacePropActive, setShowDimensions } from '../../../../redux/slicers/admin/shortcutsModuleSlicer';
import { selectCameraSafeModeEnabled, setGlobalSafeCameraFlag } from '../../../../redux/slicers/camera/cameraSettings';
import { getAppState } from '../../../../redux/store';
import { useUnityContext } from '../../../container/unityContainer';
import { useUndoRedo } from '../UndoRedoContext';

export const useUnityShortcuts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const undoRedo = useUndoRedo();
  const unityContext = useUnityContext();
  const { openInfoDialog } = useInfoDialogaActions()
  const renderPreviewPopup = useRenderPreviewPopup();

  const getRequiredState = () => {
    const appState = getAppState();
    const anyObjectSelected = selectIsAnyObjectSelected(appState);
    const isOnlyOneObjectSelected = selectIsOneObjectSelected(appState);
    const hasSelectedObjectInfo = selectHasSelectedObjectInfo(appState);
    const isStyleableObject = selectIsStyleableObject(appState);

    return {
      anyObjectSelected,
      isOnlyOneObjectSelected,
      hasSelectedObjectInfo,
      isStyleableObject,
    }
  }

  const checkIsStyleable = () => {
    const { isStyleableObject } = getRequiredState();
    if (!isStyleableObject) {
      errorToast('Please select an object which can be styled')
      return false;
    }

    return true;
  }

  const resetData = () => {    
    dispatch(curatorActions.toggleSelectedAxis(AXIS.ALL));
    dispatch(curatorActions.setTransformSpaceMode(TRANSFORM_SPACE_MODE.LOCAL));
    dispatch(resetObjectPositionAlongAxis());
  }

  const changeCursorMode = (cursorMode) => {
    const currentCursorMode = selectCursorMode(getAppState());

    if (currentCursorMode === CURSOR_MODE.STYLE_ONLY) {
      unityContext.shortcutsModule.StyleOnlyReceiver(false);
    }

    switch (cursorMode) {
      case CURSOR_MODE.MOVE:
        unityContext.shortcutsModule.TransformTypeMoveReceiver();
        break;
      case CURSOR_MODE.ROTATE:
        unityContext.shortcutsModule.TransformTypeRotateReceiver();
        break;
      case CURSOR_MODE.SCALE:
        unityContext.shortcutsModule.TransformTypeScaleReceiver();
        break;
      case CURSOR_MODE.STYLE_ONLY:
        unityContext.shortcutsModule.StyleOnlyReceiver(true);
        break;
      default:
        throw new Error('Cursor mode not supported ' + cursorMode);
    }

    dispatch(setCursorMode(cursorMode));

    resetData();
  };

  const toggleTransformSpaceMode = (newMode) => {
    if (typeof newMode === 'undefined') {
      const transformSpaceMode = selectTransformSpaceMode(getAppState());
      newMode = transformSpaceMode === TRANSFORM_SPACE_MODE.GLOBAL ? TRANSFORM_SPACE_MODE.LOCAL : TRANSFORM_SPACE_MODE.GLOBAL;
    }

    unityContext.shortcutsModule.TransformSpaceReceiver(newMode);
    dispatch(curatorActions.setTransformSpaceMode(newMode));
  };

  const toggleSelectedAxis = (axis) => {
    const axisAvailability = selectAxisAvailability(getAppState());

    if (!axisAvailability[axis]) {
      // errorToast('It is not possible to switch to `${axis}` when 2d is activated')
      return;
    }
    dispatch(resetObjectPositionAlongAxis());
    unityContext.shortcutsModule.OnClickSelectAxisButtonReceiver(axis);
    dispatch(curatorActions.toggleSelectedAxis(axis));
  }
  
  const disableSelectedAxis = () => { 
    const selectedAxis = selectSelectedAxis(getAppState());

    if (selectedAxis !== AXIS.ALL) {
      unityContext.shortcutsModule.OnClickSelectAxisButtonReceiver(selectedAxis);
      dispatch(curatorActions.toggleSelectedAxis(AXIS.ALL));
    }
  }

  const changeDimension = () => {
    const is3DMode = selectIs3DMode(getAppState());
    const sidebarSection = selectSidebarSection(getAppState());
    const switchingTo2DMode = is3DMode;

    if (switchingTo2DMode) {
      // reset view state when we switch to 2D mode
      dispatch(setUnity2DViewMode(UNITY_2D_VIEW_MODE.TOP));
      disableSelectedAxis();
    }

    unityContext.shortcutsModule.Switch3d2DViewReceiver();
    dispatch(toggleDimension());


    if (switchingTo2DMode && sidebarSection === CURATOR_MENU_SECTION.CAMERAS) {
      dispatch(setSidebarSection(null));
    }
  };


  const snapObject = (snap) => {
    unityContext.shortcutsModule.snapObject(snap);
  }

  const removeSelection = (keyDown) => {
    unityContext.shortcutsModule.removeSelection(keyDown);
  }

  const switchToMove = () => {
    changeCursorMode(CURSOR_MODE.MOVE)
  } 

  const switchToRotate = () => {
    changeCursorMode(CURSOR_MODE.ROTATE)
  }
  
  const switchToScale = () => {
    changeCursorMode(CURSOR_MODE.SCALE)
  }

  const switchToStyleOnly = () => {
    changeCursorMode(CURSOR_MODE.STYLE_ONLY)
  }

  const duplicateObject = () => {
    const { anyObjectSelected } = getRequiredState();
    
    if (!anyObjectSelected) {
      errorToast('Please select an object');
      return;
    }

    unityContext.shortcutsModule.DuplicatePropReceiver();
  }

  const deleteObject = () => {
    unityContext.shortcutsModule.DeleteObjectReceiver();
    // openInfoDialog({
    //   title: 'Delete this Object?',
    //   buttonText: 'Yes',
    //   cancelButtonText: 'No',
    //   onButtonClick: () => {
    //     unityContext.shortcutsModule.DeleteObjectReceiver();
    //   }
    // })
  }

  const replaceProp = () => {
    const canReplaceProp = selectCanReplaceProp(getAppState());
    if (!canReplaceProp) {
      errorToast('Please select replaceable object')
      return;
    }
  
    const isLightSelected = selectIsSingleLightObjectSelected(getAppState())
    if (isLightSelected) {
      openEditSelectedLight();
      return;
    }

    dispatch(paginatedProductActions.fullResetList());
    dispatch(setReplacePropActive(true));
    dispatch(setSidebarSection(CURATOR_MENU_SECTION.PRODUCTS));
  }

  const replacePropConfirm = (newProp) => {
    openInfoDialog({
      title: `Replace prop with "${newProp.name}"?`,
      buttonText: 'Yes',
      cancelButtonText: 'No',
      onButtonClick: async () => {
        const selectedProp = selectReplaceProp(getAppState());

        if (!selectedProp) {
          errorToast('Prop to replace is missing')
          cancelReplaceProp();
          return;
        }

        dispatch(setCuratorLoader(false))
        const productDbData = await updateDbProp(newProp)
        dispatch(setCuratorLoader(true))
  

        unityContext.roomModule.ReplaceProp({
          propId: selectedProp.id,
          objectName: selectedProp.objectName,
          newPropId: newProp.id,
          newPropUrl: productDbData.file || newProp.product_file,
          isMatch: newProp.is_scale,
        })

        cancelReplaceProp();
        setSelectedObjectList({})
      }
    })
  }

  const is2DView = () => {
    return selectIs2DMode(getAppState());
  }

  const cancelReplaceProp = () => {
    dispatch(setSidebarSection(null));
    dispatch(setReplacePropActive(false));
  }

  const copyMaterial = () => {
    if (!checkIsStyleable()) return;
    unityContext.shortcutsModule.copyMaterial();
    dispatch(setMaterialCopied(true));
    successToast('Material copied')
  }

  const pasteMaterial = () => {
    const isMaterialCopied = selectIsMaterialCopied(getAppState());
    isMaterialCopied 
      ? unityContext.shortcutsModule.pasteMaterial()
      : errorToast('Please copy a material first')
  }

  const update2DViewMode = (viewMode) => {
    if (!is2DView()) {
      errorToast('Please switch to 2D view to use this shortcut')
      return;
    }

    // switch between 'bottom' | 'left' | 'right' | 'top'  in 2d mode
    dispatch(setUnity2DViewMode(viewMode));
    unityContext.shortcutsModule.OnSetup2DViewsReceiver(viewMode);
  };

  const closeProject = () => {
    const curatorInitialized = selectCuratorInitialized(getAppState())

    if (!curatorInitialized) {
      // curator still loading no need for save popup
      navigate(getProjectsPath());
      return;
    }

    openInfoDialog({
      title: 'Quit Project',
      description: `Do you want to save your changes?`,
      cancelButtonText: 'No',
      buttonText: 'Yes',
      onCancelClick: () => {
        navigate(getProjectsPath());
      },
      onButtonClick: () => {
        dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.CLOSE_CURATOR));
        dispatch(setSaveProjectDialogOpen(true));
      },
    });
  };

  const saveProject = () => {
    dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.SAVE));
    dispatch(setSaveProjectDialogOpen(true));
  };

  const saveProjectAsNew = () => {
    dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.NEW));
    dispatch(setSaveProjectDialogOpen(true));
  };

  const focusObject = () => {
    unityContext.shortcutsModule.focusObject();
  }

  const toggleStyleSection = (name) => {
    if (!checkIsStyleable()) return;
    const { activeTab } = curatorStylesSelector(getAppState());
    const sidebarActiveSection = curatorSelector(getAppState()).sidebarSection;

    if (activeTab === name && sidebarActiveSection === CURATOR_MENU_SECTION.STYLE) {
      // close style section
      dispatch(setSidebarSection(null));
      return;
    }

    // open style section with selected tab 
    dispatch(setSidebarSection(CURATOR_MENU_SECTION.STYLE));
    dispatch(setActiveTab(name));
  }

  const openEditSelectedLight = () => {
    const selectedLightObject = selectSelectedLightObject(getAppState())
    if (!selectedLightObject) {
      errorToast('Please select a light')
      return;
    }

    dispatch(setSidebarSection(CURATOR_MENU_SECTION.LIGHT));
    dispatch(curatorLight.setSelectedLightId(selectedLightObject.id));
    dispatch(curatorLight.setActiveTab(curatorLight.TAB_NAME.INDOOR));
  }

  const toggleProperties = () => {
    const isLightSelected = selectIsSingleLightObjectSelected(getAppState())

    if (isLightSelected) {
      openEditSelectedLight()
    } else {
      toggleStyleSection(TAB_NAME.PROPERTIES)
    }
  }

  const toggleTexture = () => {
    toggleStyleSection(TAB_NAME.TEXTURES)
  }
  
  const toggleMaterial = () => {
    toggleStyleSection(TAB_NAME.MATERIALS)
  }

  const toggleColor = () => {
    toggleStyleSection(TAB_NAME.COLORS)
  }

  const toggleImages = () => {
    dispatch(toggleSidebarSection(CURATOR_MENU_SECTION.RENDERS_AND_PREVIEWS))
  }

  const toggleHighlightSelected = () => {
    const highlightSelected = selectHighlightSelected(getAppState());
    const newValue = !highlightSelected;
    unityContext.shortcutsModule.toggleHighlightSelected(newValue);
    dispatch(setHighlightSelected(newValue))
  }

  const toggleDimensions = () => {
    const dimensions = selectShowDimensions(getAppState());
    const measureUnit = selectMeasureUnit(getAppState());
    const newValue = !dimensions;

    unityContext.shortcutsModule.toggleDimensions(measureUnit.id - 1);
    dispatch(setShowDimensions(newValue))
  }

  const toggleLightsVisibility = () => {
    const lightsVisibility = selectLightsVisibility(getAppState());
    const newValue = !lightsVisibility;
    unityContext.shortcutsModule.toggleLightsVisibility(newValue);
    dispatch(setLightsVisibility(newValue))
  }

  const toggleCameraSafeMode = () => {
    const cameraSafeMode = selectCameraSafeModeEnabled(getAppState());
    dispatch(setGlobalSafeCameraFlag(!cameraSafeMode))
  }

  const focusEverything = () => {
    unityContext.shortcutsModule.focusEverything();
  }

  const toggleShortcuts = () => {
    const open = selectPopupOpen(getAppState())
    dispatch(setPopupOpen(!open))
    dispatch(setSidebarSection(null));
  }

  return {
    changeCursorMode,
    toggleTransformSpaceMode,
    toggleSelectedAxis,
    changeDimension,
    switchToMove,
    switchToRotate,
    switchToScale,
    switchToStyleOnly,
    duplicateObject,
    deleteObject,
    copyMaterial,
    pasteMaterial,
    update2DViewMode,
    closeProject,
    saveProject,
    saveProjectAsNew,
    focusObject,
    toggleProperties,
    toggleTexture,
    toggleMaterial,
    toggleColor,
    toggleImages,
    renderPreviewPopup,
    toggleHighlightSelected,
    toggleDimensions,
    toggleLightsVisibility,
    toggleCameraSafeMode,
    focusEverything,
    toggleShortcuts,
    replaceProp,
    replacePropConfirm,
    cancelReplaceProp,
    snapObject,
    removeSelection,
    undo: undoRedo.undo,
    redo: undoRedo.redo,
  };
};
