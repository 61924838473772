import { useState } from "react";
import styles from './FilterSubmenu.module.scss';

export const FilterSubmenu = ({ filters, maxVisible = 6, value, onChange }) => {
  return (
    <div className={styles.filterSubmenu}>
      {filters.map((filter, idx) => {
        const { title, items } = filter;
        return (
          <FilterItem
            key={idx}
            title={title}
            items={items}
            value={value}
            onChange={onChange}
            maxVisible={maxVisible}
          />
        );
      })}
    </div>
  );
};

const FilterItem = ({ title, items, maxVisible, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const moreCount = items.length - maxVisible;
  const visibleItems = moreCount > 0 && !open ? items.slice(0, maxVisible) : items;

  return (
    <div className="filter-checkbox">
      {title && (
        <div>
          <h5>{title}</h5>
        </div>
      )}

      {visibleItems.map((item, idx) => {
        return (
          <div className="form-check custom-checkbox" key={`visibleFilterItem${idx}`}>
            <input
              className="form-check-input"
              type="checkbox"
              value={item.value}
              name={item.name}
              checked={value.includes(item.value)}
              onChange={(e)=> onChange(item.value, e)}
            />
            <label className="form-check-label">
              {item.label}
            </label>
          </div>
        );
      })}

      {moreCount > 0 && (
        <div className="more">
          <span onClick={() => setOpen(!open)}>{moreCount} {open ? 'less' : 'more'}</span>
        </div>
      )}
    </div>
  );
};
