import ReactLoading from 'react-loading';
import { THEME_DARK_BLUE } from '../constants/assetConstants.js';
import Logo from '../assets/images/lf-logo.png';

export default function SuspenseLoading() {
  return (
    <div className="centerElem" style={{ zIndex: '200' }}>
      <div className="centerElemOverlay" style={{ zIndex: '500' }} />
      <div className="visibleBox" style={{ zIndex: '1000' }}>
        <img src={Logo} alt="logo" />
        <ReactLoading
          type={'spinningBubbles'}
          color={THEME_DARK_BLUE}
          height={'20%'}
          width={'20%'}
        />
      </div>
    </div>
  );
}

export function ReactLoader() {
  return (
    <ReactLoading
      type={'spinningBubbles'}
      style={{ left: '50%', top: '76%', position: 'absolute' }}
      color={THEME_DARK_BLUE}
      height={'20%'}
      width={'6%'}
    />
  );
}

export function Loader({ width }) {
  return (
    <div className="unity-gl-loader" id="hide">
      <img
        style={{ margin: '0', transition: '300ms' }}
        src="https://www.livefurnish.com/hs-fs/hubfs/raw_assets/public/LiveFurnish_Feb_2021/images/Logo-light.png?width=431&#38;name=Logo-light.png"
      />
      <div className="loader-text">Hold on...Setting up your Environment...</div>
      <div className="unity-progress">
        <div className="unity-progress-value" style={{ width: `${width}%` }}></div>
      </div>
    </div>
  );
}
export function Loading(props) {
  if (props.suspense) {
    return <SuspenseLoading />;
  }
  if (props.regular) {
    return <ReactLoader />;
  }
  if (props.unity) {
    return <Loader {...props} />;
  }
}
