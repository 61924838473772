import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {activitySelector, setNavPath} from '../../redux/slicers/activitySlicer';
import '../../assets/css/__theme.scss'
import {getTheme, themeSwitch} from '../../helpers/projectHelper';
import {setSession} from "../../redux/slicers/sessionSlicer";
import {getLocalStorage} from "../../helpers/jsHelper";
import {AUTH_TOKEN} from "../../config/constant/projectConstant";

export const ActivityContainer = ({children}) => {
    const {navPath, theme} = useSelector(activitySelector);
    const [sessionCheck, setSessionCheck] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (navPath) {
            navigate(navPath);
            dispatch(setNavPath(null));
        }
    }, [navPath]);

    useEffect(() => {
        const lsSession = getLocalStorage(AUTH_TOKEN);
        // console.log(lsSession)
        dispatch(setSession(!!lsSession))
        setSessionCheck(true)
    }, [dispatch])

    useEffect(() => {
        const lsTheme = getTheme()
        if (!lsTheme || lsTheme == 'null') {
            themeSwitch(theme);
        }
    }, [])

    return (
        <div className={`theme-container app ${theme}`}>
            {sessionCheck && children}
        </div>
    )
};
