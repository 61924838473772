import { uniqueId } from 'lodash';
import {API_ROOT_URL, GET_RENDER_PREVIEW_DATA} from '../../config/constant/apiConstant';
import api from "../../helpers/axiosHelper";
import { objectToSearchParams } from '../../helpers/jsHelper';
import { errorToast, successToast } from '../../helpers/toastHelper';
import { paginatedRenderPreviewsActions } from '../../redux/slicers/admin/paginatedRenderPreviewsSlicer';
import {setCameraPresetAngles, setCreateCameraStatus, setDeleteCameraStatus} from '../../redux/slicers/camera/cameraAngleSlicer';
import { setPreviewData, setRenderData, setRenderPreviewExpand } from '../../redux/slicers/renders-previews/rendersPreviewsSlicer';

export const getRenderPreviewAPI = (is_preview = false, is_main_render = false, getType = 'project', id) => (dispatch) => {

    api().root(API_ROOT_URL).get(`${GET_RENDER_PREVIEW_DATA}?is_preview=${is_preview}&is_main_render=${is_main_render}&${getType}=${id}&sort=-created_at`).success((data)=>{
        
        console.log("get render & preview api success: ", data)
        if(data.results !== 0){
            if(is_preview){
                dispatch(setPreviewData(data.results))
                // dispatch(getRenderPreviewAPI(is_preview, is_main_render, getType, id))
            }
            else if(is_main_render){
                dispatch(setRenderData(data.results))
                // dispatch(getRenderPreviewAPI(is_preview, is_main_render, getType, id))
            }
            
        }

    }).error((error)=>{
        
        console.log("get render & preview api error: ", error)

    }).send()
        
}

export const getRenderPreviewListApi = ({ is_preview = false, is_main_render = false, project, room, page, perPage, sort = '-created_at' , ...rest}) => (dispatch) => {
    const searchParams = objectToSearchParams({ page, perPage, sort, is_preview, is_main_render, project, room, ...rest});
    const API_URL = `${GET_RENDER_PREVIEW_DATA}?${searchParams}`;
    const requestId = uniqueId();
  
    dispatch(paginatedRenderPreviewsActions.fetchListStart({ requestId }))
  
    api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => {
        dispatch(paginatedRenderPreviewsActions.fetchListSuccess({
            page: page,
            data,
            requestId,
        }))
        dispatch(paginatedRenderPreviewsActions.setFilter(data?.sidefilter))
      })
      .error((error) => {
        // TODO: add logger
        console.error(error);
        dispatch(paginatedRenderPreviewsActions.fetchListFailed({ error, requestId }))  
      })
      .send();
  }


export const updateRenderPreviewAPI = (formData, id) => (dispatch) => {

    api().root(API_ROOT_URL).patch(`${GET_RENDER_PREVIEW_DATA}${id}/`).data(formData).success((data)=>{
        
        console.log("update render & preview api success: ", data.data)
        dispatch(setRenderPreviewExpand(data.data))

        if(data.data.is_archived){
            successToast("Archived Successfully!")
            dispatch(setRenderPreviewExpand({}))
        }

    }).error((error)=>{
        
        errorToast("Unable to archive!")
        console.log("update render & preview api error: ", error)

    }).send()
        
}

// export const createCameraAngleAPI = (formData) => (dispatch) => {

//     const headers = { 'Content-Type': 'multipart/form-data' }

//     api().root(API_ROOT_URL).post(CREATE_CAMERA_ANGLE).data(formData).setHeaders(headers).success((data)=>{
        
//         dispatch(setCreateCameraStatus(data.status))
//         console.log("create camera angle api success: ", data)
        
//     }).error((error)=>{
//         dispatch(setCreateCameraStatus(error.status))
//         console.log("create camera angle api error: ", error)

//     }).send()
        
// }


// // export const updateCameraAngleAPI = (formdata) => {
// //     console.log(formdata);

// //     // cons

// //     api().root(API_ROOT_URL).post(UPDATE_CAMERA_ANGLE).data(formdata).success((data)=>{
        
// //         // dispatch(setSearchResult(data))
// //         console.log("update camera angle api success: ", data)

// //         // setLocalStorage("renderStatus", data.status)
        
// //     }).error((error)=>{
// //         // dispatch(setSearchResult(error))
// //         console.log("update camera angle api error: ", error)

// //         // setLocalStorage("renderStatus", error.status)
// //     }).send()
        
// // }


// export const deleteCameraAngleAPI = (id) => (dispatch) => {

//     api().root(API_ROOT_URL).delete(`${DELETE_CAMERA_ANGLE}${id}`).success((data)=>{
        
//         console.log("delete camera angle api success: ", true)
//         dispatch(setDeleteCameraStatus(true))
        
//     }).error((error)=>{
        
//         console.log("delete camera angle api error: ", false)
//         dispatch(setDeleteCameraStatus(false))

//     }).send()
        
// }