import ReactInfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from './';

export default function InfiniteScroll({ loading, ...rest }) {
  const hasData = rest.dataLength !== 0;
  const loader = loading ? (
    <Loader loading={loading} position="relative" backdrop={false} className="scroll-loader" />
  ) : null;

  return (
    <Loader loading={loading} position="relative" backdrop={false} className="scroll-container">
      <ReactInfiniteScroll loader={loader} {...rest} />
    </Loader>
  );
}
