import cx from 'classnames';
import { useEffect, useState } from 'react';
import Portal from 'react-overlays/esm/Portal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';
import ThumbnailCardUI from '../../../../../common/ThumbnailCardUI';
import { TILING_TYPE } from '../../../../../config/constant/unityConstants';
import { successToast } from '../../../../../helpers/toastHelper';
import {
  curatorStylesSelector,
  selectTextureInfoOpen,
  setTextureInfoOpen,
} from '../../../../../redux/slicers/admin/curatorStylesSlicer';
import { curatorUnityObjectSelector } from '../../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { InfoIcon2 } from '../../../../icons/InfoIconFlat2';
import Image from '../../../../Image/Image';
import { useProperties } from '../useUnityRedux';
import { DraggableTexture } from './DraggableTexture';
import styles from './TextureProperties.module.scss';
import { useTextureImages } from './useTextureImages';

export const TopImage = () => {
  const { propertiesData, properties, textureThumbnail, selectedTextureId, objectName, texture } =
    useProperties();
  const dispatch = useDispatch();
  const infoOpen = useSelector(selectTextureInfoOpen);
  const [state, copyToClipboard] = useCopyToClipboard();

  const [portalRef, setPortalRef] = useState();
  useEffect(() => {
    setPortalRef(document.querySelector('body'));
  }, []);

  const mapImage = useSelector((state) => curatorUnityObjectSelector(state).mapImage);
  const color = useSelector((state) => curatorStylesSelector(state).selectedColor);

  const { changedMapsPath, textureId, isTexture, isColor, isTextureChanged } = properties || {};

  const { data: texturesList } = useTextureImages(changedMapsPath, properties);


  const handleTextureClick = (texture) => () => {
    copyToClipboard(texture.name);
    successToast('Texture name copied');
  };

  const base64Image = mapImage?.[objectName]?.[TILING_TYPE.TEXTURE];
  // const imageFallback = propertiesData.texture?.mapImage || (base64Image ? `data:image/jpeg;base64, ${base64Image}` : undefined);
  const imageFallback = propertiesData.texture?.mapImage || (base64Image ? base64Image : undefined);

  const thumbnail =
    selectedTextureId === textureId
      ? textureThumbnail
      : changedMapsPath?.diffusePath || imageFallback;

  const showColor = !isTextureChanged && isColor;

  return (
    <div className={styles.thumbnailContainer}>
      {!showColor && (
        <DraggableTexture src={thumbnail || undefined} />
      )}
      {showColor && <div className={styles.texturePlaceholder} style={{ background: color }} />}
      <div className={styles.infoIcon} onClick={() => dispatch(setTextureInfoOpen(!infoOpen))}>
        <InfoIcon2 />
      </div>
      <Portal container={portalRef}>
        <div className={cx(styles.materialsInfo, infoOpen && styles.open)}>
          <div className={styles.innerContainer}>
            {texturesList.map((texture) => {
              return (
                <div className={styles.cardContainer}>
                  <ThumbnailCardUI
                    thumbnail={texture.thumbnail}
                    name={texture.name}
                    // secondLine={texture.organizationId}
                    onClick={handleTextureClick(texture)}
                  />
                  <div className={styles.cardLabel}>{texture.label}</div>
                </div>
              );
            })}
            {!texturesList.length && <div className={styles.cardLabel}>No textures found</div>}
          </div>
        </div>
      </Portal>
    </div>
  );
};
