import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { setTitle } from '../helpers/jsHelper';

export default function CommonGrid(props) {
  useEffect(() => {
    setTitle(props.title);
  }, []);

  return (
    <div className=" w-100 main-container" id="body">
      <Container fluid>
        {props.topContainer}
        {props.filterArea}
        {props.children}
      </Container>
    </div>
  );
}
