import React, { useState, useEffect, useContext } from 'react';
import { CONVERT_IN_METER, UNITY_REF } from '../../config/constant/projectConstant';
import Unity, { UnityContext, WebGLInput } from 'react-unity-webgl';
import { unitycontext } from '../../hooks/useUnityContext';
import { useDispatch, useSelector } from 'react-redux';
import { curatorSelector, setCuratorLoader } from '../../redux/slicers/admin/curatorSlicer';
import { inchesToUnitySize } from '../../helpers/unityHelper';
import { updateDbMaterial, updateDbProp, updateDbTexture } from '../../helpers/idb';
import { toast } from 'react-toastify';
import { setGlobalHeight, setGlobalWidth } from '../../redux/slicers/camera/cameraSettings';
import { getAppState } from '../../redux/store';
import { errorToast } from '../../helpers/toastHelper';

const unityContext = new UnityContext(UNITY_REF);

function UnityContainer({ children }) {
  const dispatch = useDispatch();

  const handleLoad = (data) => {
    const { roomData } = curatorSelector(getAppState());
    console.log(data, 'handleLoad clicked');
    let check = roomData[0]?.is_custom;
    if (check) {
      handleLoadProject(data);
    } else {
      unityContext.send('RoomModule', 'CreateRoomReceiver', JSON.stringify(data));
    }
  };
  const handleLoadProject = (data) => {
    console.log(data, 'clicked');
    unityContext.send('RoomModule', 'CreateProjectReceiver', JSON.stringify(data));
  };
  const handlePropsLoad = async (product) => {
    try {
      dispatch(setCuratorLoader(false));
      const productDbData = await updateDbProp(product);

      const data = {
        type: 'PropLibrary',
        id: product.id,
        locationUrl: productDbData?.file || product.product_file,
      };

      //  unityContext.send("RoomModule", "InstantiatePropReciever", JSON.stringify(data));
      unityContext.send('RoomModule', 'InstantiatePropFromLibrary', JSON.stringify(data));
    } catch (e) {
      console.error(e);
      errorToast('Error happened while loading product');
    } finally {
      dispatch(setCuratorLoader(true));
    }
  };

  const handleMovement = (data) => {
    unityContext.send('MainCameraPrefab(Clone)', 'SetTransformType', JSON.stringify(data));
  };

  const convertCameraToUnityModel = (camera) => {
    return {
      cameraId: camera.id,
      distanceToSet: camera.distanceToSet,
      fov: camera.fov,
      xTransRot: camera.xTransRot,
      yTransRot: camera.yTransRot,
      zTransRot: camera.zTransRot,
      centerObjectxPos: camera.centerObjectxPos,
      centerObjectyPos: camera.centerObjectyPos,
      centerObjectzPos: camera.centerObjectzPos,
      isCameraCorrection: camera.isCameraCorrection,
      lensShiftY: camera.lensShiftY,
      cameraHeight: camera.cameraHeight,
      cameraRotX: camera.cameraRotX,
      cameraRotZ: camera.cameraRotZ,
      focalLength: camera.focalLength,
      isdepthOfField: camera.isdepthOfField,
      selectedObjectName: camera.selectedObjectName,
      blurIntensity: camera.blurIntensity,
      xPos: camera.xPos,
      yPos: camera.yPos,
      zPos: camera.zPos,
      // resoX: camera.resoX,
      // resoY: camera.resoY,
      cameraName: camera.cameraName,
      isPredefinedCamera: camera.isPredefinedCamera,
    };
  };

  // create camera functions
  const handleSelectedCamera = (camera) => {
    const unityCamera = convertCameraToUnityModel(camera);
    console.log('handleSelectedCamera', unityCamera);
    unityContext.send('CameraModule', 'SelectedCameraReceiver', JSON.stringify(unityCamera));

    dispatch(setGlobalHeight(camera.resoY));
    dispatch(setGlobalWidth(camera.resoX));
  };
  const handleCreateCamera = () => {
    unityContext.send('CameraModule', 'CreateCameraAngleReceiver');
  };
  const handleCameraFov = (a) => {
    console.log('OnUpdateCameraFovReceiver a ', a);
    unityContext.send('CameraModule', 'OnUpdateCameraFovReceiver', a);
  };
  const handleCameraCorrection = (a) => {
    unityContext.send('CameraModule', 'OnCameraVerticalTiltReceiver', a);
    console.log('OnCameraVerticalTiltReceiver: ', a);
  };
  const handleCameraHeight = (a) => {
    // const
    // const meter = a / CONVERT_IN_METER
    unityContext.send('CameraModule', 'UpdateCameraHeightReceiver', a);
  };
  const handleDepthOfField = (a) => {
    unityContext.send('CameraModule', 'OnSelectDepthOfFieldReceiver', a);
  };
  const handleBlurIntensity = (a) => {
    unityContext.send('CameraModule', 'OnUpdateDepthOfFieldValueReceiver', a);
  };
  const handleCameraScreenshot = (a) => {
    unityContext.send('CameraModule', 'TakeScreenShotForRender', JSON.stringify(a));
  };
  const handleCameraPopupClose = () => {
    unityContext.send('CameraModule', 'CloseCameraPopupReceiver');
  };

  const booleanToText = (val) => (val ? 'true' : 'false');

  // material module
  const materialModule = {
    /**
     * @param {{
     *  id: number;
     *  textureUrl: string;
     *  mapType: number;
     * }} data
     * @returns void
     */
    OnSelectTextureReceiver(data) {
      unityContext.send('MaterialModule', 'OnSelectTextureReceiver', JSON.stringify(data));
    },

    /**
     * @param {{
     *  id: number;
     *  materialUrl: string;
     * }} data
     * @returns void
     */
    OnSelectMaterialReceiver(data) {
      unityContext.send('MaterialModule', 'OnSelectMaterialReceiver', JSON.stringify(data));
    },

    /**
     * @param {{
     *  width: number;
     *  height: number;
     *  tilingOptionType: number;
     * }} data
     * @returns void
     */
    OnUpdateTilingSizeReceiver({ width, height, tilingOptionType }) {
      console.log('OnUpdateTilingSizeReceiver', { 
        width, 
        height, 
        tilingOptionType,
        unityWidth: inchesToUnitySize(width),
        unityHeight: inchesToUnitySize(height),
      });

      const isWidthValid = typeof width === 'number' && !Number.isNaN(width)
      const isHeightValid = typeof height === 'number' && !Number.isNaN(height)

      if (!isWidthValid || !isHeightValid) {
        console.error('OnUpdateTilingSizeReceiver height or width not number ')
        return;
      }

      unityContext.send(
        'MaterialModule',
        'OnUpdateTilingSizeReceiver',
        JSON.stringify({
          tilingOptionType,
          xValue: inchesToUnitySize(width),
          yValue: inchesToUnitySize(height),
        })
      );
    },

    /**
     * @param {{
     *  x: number;
     *  y: number;
     *  tilingOptionType: number;
     * }} data
     * @returns void
     */
    OnUpdateTilingOffsetReceiver(data) {
      unityContext.send(
        'MaterialModule',
        'OnUpdateTilingOffsetReceiver',
        JSON.stringify({
          ...data,
          xValue: parseFloat(data.x),
          yValue: parseFloat(data.y),
        })
      );
    },

    /**
     * @param {{
     *  width: number;
     *  height: number;
     *  x: number;
     *  y: number;
     *  rotation: number;
     *  tilingOptionType: number;
     * }} data
     * @returns void
     */
    OnUpdateRotationReceiver({ width, height, x, y, rotation, tilingOptionType }) {
      console.log('OnUpdateRotationReceiver', {
        xSize: inchesToUnitySize(width),
        ySize: inchesToUnitySize(height),
        xPosition: x,
        yPosition: y,
        rotation,
        tilingOptionType,
      })

      unityContext.send(
        'MaterialModule',
        'OnUpdateRotationReceiver',
        JSON.stringify({
          xSize: inchesToUnitySize(width),
          ySize: inchesToUnitySize(height),
          xPosition: x,
          yPosition: y,
          rotation,
          tilingOptionType,
        })
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateRoughnessValueReceiver(value) {
      unityContext.send('MaterialModule', 'OnUpdateRoughnessValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    InvertRoughnessToggleReceiver(value) {
      unityContext.send('MaterialModule', 'InvertRoughnessToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomRoughnessMapToggleReceiver(value) {
      console.log('sasha toggle UseCustomRoughnessMapToggleReceiver', value);

      unityContext.send(
        'MaterialModule',
        'UseCustomRoughnessMapToggleReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param status: boolean;
     * @param tilingOptionType: string;
     * @returns void
     */
    AddRemoveSeparateTilingOptionReceiver({ status, tilingOptionType }) {
      console.log('AddRemoveSeparateTilingOptionReceiver', {
        status: booleanToText(status),
        tilingOptionType,
      });

      unityContext.send(
        'MaterialModule',
        'AddRemoveSeparateTilingOptionReceiver',
        JSON.stringify({
          status: booleanToText(status),
          tilingOptionType,
        })
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateNormalValueReceiver(value) {
      unityContext.send('MaterialModule', 'OnUpdateNormalValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    InvertNormalToggleReceiver(value) {
      unityContext.send('MaterialModule', 'InvertNormalToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomNormalMapToggleReceiver(value) {
      unityContext.send('MaterialModule', 'UseCustomNormalMapToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseGenerateNormalMapToggleReceiver(value) {
      unityContext.send(
        'MaterialModule',
        'UseGenerateNormalMapToggleReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateTransparencyValueReceiver(value) {
      unityContext.send('MaterialModule', 'OnUpdateTransparencyValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    InvertTransparencyToggleReceiver(value) {
      unityContext.send('MaterialModule', 'InvertTransparencyToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    SetTransparencyFromDesignReceiver(value) {
      unityContext.send(
        'MaterialModule',
        'SetTransparencyFromDesignReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomTransparencyMapToggleReceiver(value) {
      unityContext.send(
        'MaterialModule',
        'UseCustomTransparencyMapToggleReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateAoValueReceiver(value) {
      unityContext.send('MaterialModule', 'OnUpdateAoValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomAOMapToggleReceiver(value) {
      unityContext.send('MaterialModule', 'UseCustomAOMapToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateMetallicValueReceiver(value) {
      unityContext.send('MaterialModule', 'OnUpdateMetallicValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomMetallicMapToggleReceiver(value) {
      unityContext.send(
        'MaterialModule',
        'UseCustomMetallicMapToggleReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateEmissionValueReceiver(value) {
      unityContext.send('MaterialModule', 'OnUpdateEmissionValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    OnEffectLightColorBounceEnableDisableReceiver(value) {
      unityContext.send(
        'MaterialModule',
        'OnEffectLightColorBounceEnableDisableReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param tilingType: number; // 0 | 1
     * @returns void
     */
    OnSetFitReceiver(tilingType) {
      console.log('sasha OnSetFitReceiver', tilingType);
      unityContext.send('MaterialModule', 'OnSetFitReceiver', JSON.stringify(tilingType));
    },

    /**
     * @param tilingType: number;
     * @returns void
     */
    OnLockTilingReceiver(tilingType) {
      console.log(
        'before lock send ',
        'MaterialModule',
        'OnLockTilingReceiver',
        JSON.stringify(tilingType)
      );
      unityContext.send('MaterialModule', 'OnLockTilingReceiver', JSON.stringify(tilingType));
    },

    /**
     * @param color: string;
     * @returns void
     */
    OnUpdateColorReceiver(color) {
      unityContext.send('MaterialModule', 'OnUpdateColorReceiver', color);
      
      // unityContext.send('MaterialModule', 'OnUpdateColorReceiver', JSON.stringify({
      //   status: false,
      //   value: color,
      // }));
    },

    OnCallBase64ImageDataReceiver() {
      // gets bas64 images of current selected object
      window.tOnCallBase64ImageDataReceiver = performance.now();
      unityContext.send('MaterialModule', 'OnCallBase64ImageDataReceiver');
    },

    async selectTexture({ texture, mapType }) {
      const textureDbData = await updateDbTexture(texture);

      console.log('sasha selectTexture', textureDbData);

      this.OnSelectTextureReceiver({
        id: texture.id,
        textureUrl: textureDbData?.file || texture.texture_file,
        mapType,
        version: texture.version,
        skuId: texture.sku_id,
        content: null,
      });
    },

    async selectMaterial({ material }) {
      const materialDbData = await updateDbMaterial(material);
      this.OnSelectMaterialReceiver({
        id: material.id,
        materialUrl: materialDbData?.file || material.material_image,
      });
    },
  };

  window.materialModule = materialModule;


  const metadataModule = {
    GetRoomMetadataList() {
      unityContext.send('MetadataModule', 'GetRoomMetadataList');
    },
    GetRoomMetadataListReceiver() {
      unityContext.send('MetadataModule', 'GetRoomMetadataListReceiver');
    },
    AddMetadataReceiver({ sequenceNo, metadataName, objectName }, loadedProp = false) {
      unityContext.send(
        'MetadataModule',
        'AddMetadataReceiver',
        JSON.stringify({ metadataName, objectName }),
        loadedProp
      ); // TODO: work on this false
    },
    OnAddMetadataButtonClickReceiver() {
      // call it to know if meta data can be applied to the object
      unityContext.send('MetadataModule', 'OnAddMetadataButtonClickReceiver');
    },
    DeleteMetadataReceiver(title) {
      unityContext.send('MetadataModule', 'DeleteMetadataReceiver', title);
    },
    SelectMetadataReceiver(title) {
      console.log('sasha SelectMetadataReceiver', title);
      unityContext.send('MetadataModule', 'SelectMetadataReceiver', title);
    },
    EditMetadataReceiver(oldMetadataTitle, newMetadataTitle) {
      unityContext.send(
        'MetadataModule',
        'EditMetadataReceiver',
        JSON.stringify({
          oldMetadataName: oldMetadataTitle,
          newMetadataName: newMetadataTitle,
        })
      );
    },
    UpdateSequenceReceiver(title, newIndex, oldIndex) {
      console.log('sasha metadata sequence no update', {
        metadataTitle: title,
        newIndex,
        oldIndex,
      });
      unityContext.send(
        'MetadataModule',
        'UpdateSequenceReceiver',
        JSON.stringify({
          metadataTitle: title,
          newIndex,
          oldIndex,
        })
      );
    },
    UpdateMetadataStatusReceiver(title, enabled) {
      unityContext.send(
        'MetadataModule',
        'UpdateMetadataStatusReceiver',
        JSON.stringify({
          metadataTitle: title,
          status: enabled,
        })
      );
    },
    CloseFocusObjectReceiver() {
      unityContext.send('MetadataModule', 'CloseFocusObjectReceiver');
    }
  };


  const lightModule = {
    GenerateLightReceiver(lightName) {
      unityContext.send('LightModule', 'GenerateLightReceiver', lightName);
    },
    SelectLightObjectReceiver(lightId) {
      unityContext.send('LightModule', 'SelectLightObjectReceiver', lightId);
    },
    UpdateLightTypeReceiver(light) {
      unityContext.send('LightModule', 'UpdateLightTypeReceiver', JSON.stringify(light));
    },
    UpdateColorReceiver(color) {
      unityContext.send('LightModule', 'UpdateColorReceiver', color);
    },
    UpdateIntensityReceiver(intensity) {
      unityContext.send('LightModule', 'UpdateIntensityReceiver', intensity);
    },
    UpdateSizeReceiver(size) {
      unityContext.send('LightModule', 'UpdateSizeReceiver', size);
    },
    UpdateVisibleInReflectionReceiver(active) {
      unityContext.send('LightModule', 'UpdateVisibleInReflectionReceiver', booleanToText(active));
    },
    UpdateSpotAngleReceiver(spotAngle) {
      unityContext.send('LightModule', 'UpdateSpotAngleReceiver', spotAngle);
    },
    UpdateBlendSpotReceiver(blendSpot) {
      unityContext.send('LightModule', 'UpdateBlendSpotReceiver', blendSpot);
    },
    OnDeleteLightReceiver(lightId) {
      unityContext.send('LightModule', 'OnDeleteLightReceiver', lightId);
    },
    UpdateEnvironmentColorReceiver(color) {
      // toggle color
      unityContext.send('LightModule', 'UpdateEnvironmentColorReceiver', color);
    },
    UpdateColorIntensityReceiver(intensity) {
      unityContext.send('LightModule', 'UpdateColorIntensityReceiver', parseFloat(intensity));
    },
    UpdateEnvironmentSkyboxReceiver({ url, rotation, intensity }) {
      // toggle hdri
      unityContext.send(
        'LightModule',
        'UpdateEnvironmentSkyboxReceiver',
        JSON.stringify({ url, rotation, intensity })
      );
    },
    UpdateSkyboxRotationReceiver(rotation) {
      unityContext.send('LightModule', 'UpdateSkyboxRotationReceiver', rotation);
    },
    UpdateEnableInRenderingReceiver(id, active) {
      unityContext.send(
        'LightModule',
        'UpdateEnableInRenderingReceiver',
        JSON.stringify({
          lightId: `${id}`,
          status: active,
        })
      );
    },
    UpdateAreaWidthReceiver(width) {
      unityContext.send('LightModule', 'UpdateAreaWidthReceiver', parseFloat(width));
    },
    UpdateAreaHeightReceiver(width) {
      unityContext.send('LightModule', 'UpdateAreaHeightReceiver', parseFloat(width));
    },
    UpdateSkyboxIntensityReceiver(intensity) {
      // update hdri intenstiy
      unityContext.send('LightModule', 'UpdateSkyboxIntensityReceiver', parseFloat(intensity));
    },
    EnableDisableHDRSkyboxReceiver(enabled) {
      unityContext.send('LightModule', 'EnableDisableHDRSkyboxReceiver', booleanToText(enabled));
    },
    UpdateLightNameReceiver(lightName) {
      unityContext.send('LightModule', 'UpdateLightNameReceiver', lightName);
    },
    GetLightDatReceiver() {
      unityContext.send('LightModule', 'GetLightDatReceiver');
    },
  };

  const shortcutsModule = {
    Switch3d2DViewReceiver() {
      unityContext.send('ShortcutsModule', 'Switch3d2DViewReceiver');
    },
    OnSetup2DViewsReceiver(viewMode) {
      unityContext.send('ShortcutsModule', 'OnSetup2DViewsReceiver', viewMode);
    },
    TransformTypeMoveReceiver() {
      unityContext.send('ShortcutsModule', 'TransformTypeMoveReceiver');
    },
    TransformTypeRotateReceiver() {
      unityContext.send('ShortcutsModule', 'TransformTypeRotateReceiver');
    },
    TransformTypeScaleReceiver() {
      unityContext.send('ShortcutsModule', 'TransformTypeScaleReceiver');
    },
    StyleOnlyReceiver(active) {
      unityContext.send('ShortcutsModule', 'StyleOnlyReceiver', booleanToText(active));
    },
    TransformSpaceReceiver(mode) {
      unityContext.send('ShortcutsModule', 'TransformSpaceReceiver', parseInt(mode));
    },
    OnClickSelectAxisButtonReceiver(axis) {
      unityContext.send('ShortcutsModule', 'OnClickSelectAxisButtonReceiver', axis);
    },
    DuplicatePropReceiver() {
      unityContext.send('ShortcutsModule', 'DuplicatePropReceiver');
    },
    DeleteObjectReceiver() {
      unityContext.send('ShortcutsModule', 'DeleteObjectReceiver');
    },
    copyMaterial() {
      unityContext.send('ShortcutsModule', 'CopyMaterialReceiver');
    },
    pasteMaterial() {
      unityContext.send('ShortcutsModule', 'PasteMaterialReceiver');
    },
    focusObject() {
      unityContext.send('ShortcutsModule', 'FocusObjectReceiver');
    },
    toggleHighlightSelected(active) {
      unityContext.send('ShortcutsModule', 'HeightlightOnOffReceiver', booleanToText(active));
    },
    toggleDimensions(measureUnit) {
      unityContext.send('MeasureModule', 'OnSelectMeasurementToolReceiver', measureUnit);
    },
    toggleLightsVisibility(active) {
      unityContext.send('ShortcutsModule', 'LightsEnableDisableReceiver', booleanToText(active));
    },
    focusEverything() {
      unityContext.send('ShortcutsModule', 'FocusEverythingReceiver');
    },
    snapObject(snap) {
      unityContext.send('ShortcutsModule', 'SnapObjectReceiver', booleanToText(snap));
    },
    removeSelection(clicked) {
      unityContext.send('ShortcutsModule', 'RemoveSelectionReceiver', booleanToText(clicked));
    },
    addSelection(clicked) {
      unityContext.send('ShortcutsModule', 'AddSelectionReceiver', booleanToText(clicked));
    },
    SingleObjectSelectionReceiver(clicked) {
      unityContext.send('ShortcutsModule', 'SingleObjectSelectionReceiver', booleanToText(clicked));
    },
    SetPositionAlongAxis({ value, transformType }) {
      unityContext.send('ShortcutsModule', 'SetPositionAlongAxis', JSON.stringify({
        value,
        type: transformType,
      }));
    }
  };

  const handleGraphicQualityChange = (index) => {
    unityContext.send('SettingsModule', 'SetRoomQualityReceiver', index);
  };

  const handleVerticalRotationSpeed = (speed) => {
    unityContext.send('SettingsModule', 'OnUpdateVerticalRotationSpeedReceiver', parseFloat(speed));
  };

  const handleHorizontalRotationSpeed = (speed) => {
    unityContext.send(
      'SettingsModule',
      'OnUpdateHorizontalRotationSpeedReceiver',
      parseFloat(speed)
    );
  };

  const handleMeasureUnitChange = (index) => {
    console.log('handle measure unity sasha ', index);
    unityContext.send('MeasureModule', 'OnChangeSizeUnitReceviever', index);
  };

  const unityTigg = () => {
    unityContext.send('RoomModule', 'GetRoomInfo');
  };

  const roomModule = {
    GetRoomInfo() {
      // triggers get room info -> room info will come back in the callback -> GetRoomInfo
      unityContext.send('RoomModule', 'GetRoomInfo');
    },
    resetAll() {
      unityContext.send('RoomModule', 'ResetAllReceiver');
    },
    resetPosition() {
      unityContext.send('RoomModule', 'ResetPositionReceiver');
    },
    resetRotation() {
      unityContext.send('RoomModule', 'ResetRotationReceiver');
    },
    resetScale() {
      unityContext.send('RoomModule', 'ResetScaleReceiver');
    },
    ReplaceProp({ propId, objectName, newPropId, newPropUrl, isMatch }) {
      unityContext.send(
        'RoomModule',
        'ReplaceProp',
        JSON.stringify({
          propIdToReplace: propId,
          objectNameToReplace: objectName,
          propIdToReplaceWith: newPropId,
          propToReplaceWithLocationUrl: newPropUrl,
          isMatch,
        })
      );
    },
    InstantiatePropReceiever(prop) {
      console.log('send props data', prop)
      unityContext.send('RoomModule', 'InstantiatePropReceiever', JSON.stringify(prop));
    }
  };

  const projectModule = {
    ApplyMaterialReceiver(materailData) {
      console.log('ApplyMaterialReceiver', materailData)
      unityContext.send('ProjectModule', 'ApplyMaterialReceiver', JSON.stringify(materailData));
    },
  }

  const cameraModule = {
    OnSelectDepthOfFieldReceiver(enabled) {
      unityContext.send('CameraModule', 'OnSelectDepthOfFieldReceiver', booleanToText(enabled));
    },
    OnCameraVerticalTiltReceiver(enabled) {
      unityContext.send('CameraModule', 'OnCameraVerticalTiltReceiver', booleanToText(enabled));
    },
    UpdateCameraHeightReceiver(height) {
      unityContext.send('CameraModule', 'UpdateCameraHeightReceiver', height);
    },
    OnUpdateCameraFovReceiver(fovVale) {
      unityContext.send('CameraModule', 'OnUpdateCameraFovReceiver', fovVale);
    },
    OnUpdateDepthOfFieldValueReceiver(intensity) {
      // update blur intensity
      unityContext.send('CameraModule', 'OnUpdateDepthOfFieldValueReceiver', intensity);
    },

    OnSelectFocusObjectReceiver() {
      unityContext.send('CameraModule', 'OnSelectFocusObjectReceiver');
    },
    ClearSelectedObjectReceiver() {
      unityContext.send('CameraModule', 'ClearSelectedObjectReceiver');
    },
    importPredefinedCameraFromPresets(cameraList) {
      // const unityCameraList = cameraList.map(convertCameraToUnityModel);
      unityContext.send(
        'CameraModule',
        'OnImportPrefinedCamAsNormalCamReceiver',
        JSON.stringify(cameraList)
      );
    },
    OnUpdateCameraResoYReceiver(resoY) {
      console.log('OnUpdateCameraResoYReceiver', resoY);
      unityContext.send('CameraModule', 'OnUpdateCameraResoYReceiver', resoY);
    },
    OnUpdateCameraResoXReceiver(resoX) {
      console.log('OnUpdateCameraResoXReceiver', resoX);
      unityContext.send('CameraModule', 'OnUpdateCameraResoXReceiver', resoX);
    },
    CloseFocusObjectReceiver() {
      unityContext.send('CameraModule', 'CloseFocusObjectReceiver');
    },
    OnUpdateLockReceiver(lock) {
      unityContext.send('CameraModule', 'OnUpdateLockReceiver', booleanToText(lock));
    },
    GetCurrentCameraScreenshotReceiver() {
      unityContext.send('CameraModule', 'GetCurrentCameraScreenshotReceiver');
    },
  };

  window.roomModule = roomModule;

  return (
    <unitycontext.Provider
      value={{
        unityTigg,
        handleMovement,
        handleLoad,
        handleLoadProject,
        unityContext,
        handlePropsLoad,
        handleSelectedCamera,
        handleCreateCamera,
        handleCameraFov,
        handleCameraCorrection,
        handleCameraHeight,
        handleDepthOfField,
        handleBlurIntensity,
        handleCameraScreenshot,
        handleCameraPopupClose,
        handleGraphicQualityChange,
        handleVerticalRotationSpeed,
        handleHorizontalRotationSpeed,
        handleMeasureUnitChange,

        materialModule,
        metadataModule,
        lightModule,
        shortcutsModule,
        roomModule,
        projectModule,
        cameraModule,
      }}>
      {children}
    </unitycontext.Provider>
  );
}

export const useUnityContext = () => useContext(unitycontext);

export default UnityContainer;
