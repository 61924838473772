import { useDataList, useSimpleDataList } from "../../../../../hooks/useDataList";
import { getTextureListApi } from "../../../../../api/textures";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useFilterOption } from "./useFilterOption";
import { curatorStylesSelector } from "../../../../../redux/slicers/admin/curatorStylesSlicer";
import { paginatedTexturesActions, paginatedTexturesSelector } from "../../../../../redux/slicers/textures/paginatedTexturesSlicer";
import { FILTER } from "../../CuratorFilter/CuratorFilter";

export const useTextures = () => {
  const textureFilterValue = useSelector((state) => curatorStylesSelector(state).textureFilterValue)
  const { filter } = useFilterOption();

  const requestParams = useMemo(() => {
    if (!filter) return undefined;

    return {
      is_map: true,
      [`fl_${filter.filter_label}`]: filter.id,
    }
  }, [filter])


  const data = useDataList({
    selector: paginatedTexturesSelector,
    resetAction: paginatedTexturesActions.fullResetList,
    getAction: getTextureListApi,
    requestParams,
    disableFilters: Boolean(requestParams),

  });

  return data;
};
