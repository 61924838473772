import { uniqueId } from "lodash";
import { API_ROOT_URL, PROPS_API, PROPS_REPLACE_API } from "../../helpers/api";
import api from "../../helpers/axiosHelper";
import { objectToFormData, objectToSearchParams } from "../../helpers/jsHelper";
import { LOADING_KEY } from "../../helpers/reduxHelper";
import { errorToast, successToast } from "../../helpers/toastHelper";
import { paginatedProductActions } from "../../redux/slicers/admin/paginatedProductSlicer";

export const createGetListAction = ({
  endpoint,
  actions,
}) => ({ searchConfig, page, perPage, sort = 'name' , ...rest}) => (dispatch) => {
  const searchParams = objectToSearchParams({ 
    page, 
    perPage, 
    sort, 
    ...rest,
  });
  const API_URL = `${endpoint}?${searchParams}`;
  const requestId = uniqueId();

  const start = searchConfig ? actions.searchStart : actions.fetchListStart;
  const success = searchConfig ? actions.searchSuccess : actions.fetchListSuccess
  const failed = searchConfig ? actions.searchFailed : actions.fetchListFailed

  dispatch(start({ requestId }))

  api()
    .root(API_ROOT_URL)
    .get(API_URL)
    .success((data) => {
      dispatch(success({
          page: page,
          data,
          requestId,
      }))

      if (!searchConfig) {
        dispatch(actions.setFilter(data?.sidefilter))
      }
    })
    .error((error) => {
      // TODO: add logger
      console.error(error);
      dispatch(failed({ error, requestId }))  
    })
    .send();
}



export const getProductListApi = createGetListAction({
  endpoint: PROPS_API,
  actions: paginatedProductActions,
})

export const getProductReplaceListApi = createGetListAction({
  endpoint: PROPS_REPLACE_API,
  actions: paginatedProductActions,
})


export const ProductEditApi = ({ id, ...rest }) => (dispatch) => {
  const formData = objectToFormData(rest);

  
  const isFavouriteToggleAction = Object.keys(rest).length === 1 && typeof rest.is_favourite === 'boolean';
  if (isFavouriteToggleAction) {
    // we're toggling favourite
    dispatch(paginatedProductActions.setItemLoading({
      id,
      key: LOADING_KEY.FAVOURITE,
      value: true,
    }))
  }

  api()
    .root(API_ROOT_URL)
    .patch(`${PROPS_API}${id}/`)
    .data(formData)
    .success(({ data }) => {
      dispatch(paginatedProductActions.update(data));

      if (isFavouriteToggleAction) {
        if (data.is_favourite) {
          successToast("Added To Favourite");
        } else {
          successToast("Removed From Favourite");
        }
      }
    })
    .error((e) => {
      errorToast(e.message);
    })
    .finally(() => {

      if (isFavouriteToggleAction) {
        dispatch(paginatedProductActions.setItemLoading({
          id,
          key: LOADING_KEY.FAVOURITE,
          value: false,
        }))
      }
    })
    .send();
};