import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unitycontext } from '../../../../../hooks/useUnityContext';
import {
  curatorSelector,
  setCuratorSettingData,
  SETTINGS_SECTION,
} from '../../../../../redux/slicers/admin/curatorSlicer';
import Button from '../../../../Button/Button';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import settingStyles from './Setting.module.scss';

const GraphicQuality = ({ onSectionToggle }) => {
  const dispatch = useDispatch();

  const { settingData } = useSelector(curatorSelector);
  const { handleGraphicQualityChange } = useContext(unitycontext);

  const handleChangeData = (findIndex) => {
    let tempData = [...settingData?.json_data?.graphicQuality];
    tempData = tempData.map((item, index) =>
      index === findIndex ? { ...item, isSelected: true } : { ...item, isSelected: false }
    );
    dispatch(
      setCuratorSettingData({
        ...settingData,
        json_data: { ...settingData?.json_data, graphicQuality: [...tempData] },
      })
    );
  };

  const onLoadEffect = () => {
    const selected = settingData?.json_data?.graphicQuality?.find(
      (item) => item?.isSelected === true
    );
    if (selected) {
      handleGraphicQualityChange(selected?.id - 1);
    }
  };

  React.useEffect(onLoadEffect, [settingData]);

  return (
    <CuratorAccordion.Item eventKey={SETTINGS_SECTION.GRAPHIC_QUALITY}>
      <CuratorAccordion.Header onClick={() => onSectionToggle(SETTINGS_SECTION.GRAPHIC_QUALITY)}>
        Graphic Quality
      </CuratorAccordion.Header>
      <CuratorAccordion.Body>
        <div className="d-flex justify-content-center gap-3">
          {settingData?.json_data?.graphicQuality?.map((item, index) => {
            if (item.id === 3) return null;

            return (
              <Button
                color="light"
                className={settingStyles.selectButton}
                onClick={() => handleChangeData(index)}
                active={item.isSelected}
                fullWidth
                key={index}
              >
                {item?.name}
              </Button>
            );
          })}
        </div>
      </CuratorAccordion.Body>
    </CuratorAccordion.Item>
  );
};

export default React.memo(GraphicQuality);
