import React, { useState } from "react";
import cx from "classnames";
import { RiImageLine } from "react-icons/ri";
import styles from "./Image.module.scss";

// type TProps = {
//   ratio?: number;
//   containerProps?: BoxProps;
//   placeholderWhileLoading?: boolean;
// } & React.ImgHTMLAttributes<HTMLImageElement>;

const Image = ({
  containerProps,
  ratio,
  placeholderWhileLoading,
  placeholderColor = 'var(--image-placeholder)',
  rounded,
  ...imgProps
}) => {
  const [loaded, setLoaded] = useState(!placeholderWhileLoading);
  const containerClassName = cx(styles.root, containerProps?.className);

  if (!ratio) {
    return (
      <img
        {...imgProps}
        className={cx(styles.simpleImage, imgProps.className)}
      />
    );
  }

  const paddingBottom = `${(1 / ratio) * 100}%`;

  const handleImageLoad = (event) => {
    setLoaded(true);
    imgProps?.onLoad?.(event);
  };

  return (
    <div {...containerProps} className={containerClassName}>
      <div
        className={styles.placeholder}
        style={{
          paddingBottom,
          display: "flex",
          width: "100%",
          backgroundColor: placeholderWhileLoading ? placeholderColor : "transparent",
          
        }}
      >
        {!loaded && (
          <RiImageLine fontSize={24} className={styles.placeholderIcon} />
        )}
        <img
          loading="lazy"
          alt={imgProps.alt || "placeholder"}
          {...imgProps}
          className={cx(styles.image, imgProps.className)}
          onLoad={placeholderWhileLoading ? handleImageLoad : imgProps.onLoad}
          style={{
            ...imgProps.style,
            opacity: loaded ? 1 : 0,
          }}
        />
      </div>
    </div>
  );
};

export default Image;
