import { useDispatch } from 'react-redux';
import {API_ROOT_URL, CREATE_RENDER, DOWNLOAD_RENDER_IMAGE} from '../../../config/constant/apiConstant';
import { AUTH_TOKEN, REFRESH_TOKEN } from '../../../config/constant/projectConstant';
// import { CUSTOMIZE_EXPERIENCE, Customize_Experience, TERMS_CONDITION_ROUTE } from '../../../config/constant/routePathConstant';
import api from "../../../helpers/axiosHelper";
import { getLocalStorage, setLocalStorage } from '../../../helpers/jsHelper';
import { errorToast } from '../../../helpers/toastHelper';
import { saveAs } from 'file-saver';
// import { navigate } from '../../../helpers/projectHelper';
// import { successToast } from '../../../helpers/toastHelper';
// import {setSearchValue, setSearchResult} from "../../../redux/slicers/search/searchSlicer";

export const createRenderAPI = (formData, { onSuccess, onError, afterAll } = {}) => {
    // console.log(search);

    api().root(API_ROOT_URL).post(CREATE_RENDER).data(formData).success((data)=>{
        setLocalStorage("renderStatus", data.status)
        onSuccess?.(data?.data)
    }).error((error)=>{
        // dispatch(setSearchResult(error))
        console.log("create render api error: ", error)

        console.log("error.status : ", error.status)

        setLocalStorage("renderStatus", error.status)


        if(error.status === null || error.status === undefined){
            errorToast(`Unable to create Render/Preview.`)
        }

        onError?.()
    })
    .finally(() => {
        afterAll?.();
    })
    .send()
        
}

export const downloadRenderAPI = (id) => {
    
    // const headers = { 'Content-Type': 'application/zip' }
    const headers = {
        // 'Accept': 'application/zip',
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/zip'
      }

//     api().root(API_ROOT_URL).post(CREATE_CAMERA_ANGLE).data(formData).setHeaders(headers).success((data)=>{

    api().root(API_ROOT_URL).get(`${DOWNLOAD_RENDER_IMAGE}?ids=${id}`).setHeaders(headers).success((data)=>{
        
        // dispatch(setSearchResult(data))

        // const data = d

        // var blob = new Blob([data], {type: "Content-Type"}); 
        // var blob = new Blob([data]); 
        // var blob = new Blob([data], {type: "application/zip, application/octet-stream"}); 
        // var blob = new Blob([data], {type: "application/octet-stream"}); 
        // var blob = new Blob([data], {type: "octet/stream"}); 

        // console.log("download render api success: ", data)
        // console.log("download render api success length: ", data.length)
        // console.log("download render api success blob.size: ", blob.size)
        // console.log("download render api success string: ", data.toString())

        // const url = URL.createObjectURL(blob);
        //   saveAs(data, "file.zip");
        //   const link = document.createElement('a');
        //   link.href = url;
        //   link.setAttribute(
        //     'download',
        //     'FileName.zip',
        //   );
      
        //   document.body.appendChild(link);
      
        //   link.click();
      
        //   link.parentNode.removeChild(link);


        const blob = new Blob([data],{type:'application/zip'})
        console.log("download render blob: ", blob)

        console.log("download blob url:", URL.createObjectURL(blob))
        // saveAs(URL.createObjectURL(blob), "file.zip");

        const link = document.createElement('a');
link.href =  URL.createObjectURL(blob);
link.setAttribute('download', 'file.zip'); //set download attribute to link
document.body.appendChild(link);
link.click();

        // var reader = new FileReader();
        // reader.onload = function() {
        //   console.log(JSON.parse(this.result));

        //   window.location(JSON.parse(this.result))
        // };
        // reader.readAsText(blob);

        
    }).error((error)=>{
        // dispatch(setSearchResult(error))
        console.log("download render api error: ", error)

    }).send()
}
