import _ from 'lodash';

export default function MiniButton(props) {
  const { id, cardType, title, clickFunction, showModalFunction, alt, iconSrc, inPreview } = props;

  function handleClickEvent(e) {
    if (_.isFunction(clickFunction)) {
      clickFunction(id, cardType);
    } else if (_.isFunction(showModalFunction)) {
      showModalFunction(true);
    }
  }

  return (
    <li title={title}>
      <div onClick={handleClickEvent} style={{ width: '16px' }}>
        <img className="img-fluid" src={iconSrc} alt={alt || title} />
        {props.buttonLabel}
      </div>
    </li>
  );
}
