import uniqueId from "lodash/uniqueId";
import {
  API_ROOT_URL,
  LIGHT_API,
} from "../../config/constant/apiConstant";
import api from "../../helpers/axiosHelper";
import { objectToSearchParams } from "../../helpers/jsHelper";
import { errorToast, successToast } from "../../helpers/toastHelper";
import { addLightFailed, addLightStart, addLightSuccess, setAddLightLoading } from "../../redux/slicers/admin/curatorLightSlicer";
import { paginatedLightActions } from "../../redux/slicers/admin/paginatedLightSlicer";

export const LightAddApi = (formData, { successCallback } = {}) => (dispatch) => {
  dispatch(addLightStart())

  api()
    .root(API_ROOT_URL)
    .post(LIGHT_API)
    .data(formData)
    .success((res) => {
      dispatch(paginatedLightActions.addSuccess(res.data))
      dispatch(addLightSuccess(res.data))
      successToast("Light added successfully");
      successCallback?.()
    })
    .error((e) => {
      // TODO: add error logger
      errorToast("Some error occured");
      dispatch(addLightFailed(e))
    })
    .finally(() => dispatch(setAddLightLoading(false)))
    .send();
};

export const getLightListApi = ({ page, perPage, sort = 'name' , ...rest}) => (dispatch) => {
  const searchParams = objectToSearchParams({ page, perPage, sort, ...rest});
  const API_URL = `${LIGHT_API}?${searchParams}`;
  const requestId = uniqueId();

  dispatch(paginatedLightActions.fetchListStart({ requestId }))

  api()
    .root(API_ROOT_URL)
    .get(API_URL)
    .success((data) => {
      dispatch(paginatedLightActions.fetchListSuccess({
          page: page,
          data,
          requestId,
      }))
    })
    .error((error) => {
      // TODO: add logger
      console.error(error);
      dispatch(paginatedLightActions.fetchListFailed({ error, requestId }))  
    })
    .send();
}