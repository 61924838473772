import { TILING_TYPE } from "../../../../../config/constant/unityConstants";
import Button from "../../../../Button/Button";
import { useProperties, useUnityRedux } from "../useUnityRedux";
import { MapTextureForm } from "./MapTextureForm";
import styles from './TextureProperties.module.scss';

const TextureProperties = () => {
  const { propertiesData } = useProperties();
  const unityRedux = useUnityRedux();

  if (propertiesData?.texture?.hideUI) return null;

  return (
    <>
      <div className="properties-global ">
        <div className="texture-head d-flex justify-content-between mb-4">
          <h3 className="me-3 fw-400">Texture Properties</h3>
          <Button
            type="button"
            color="warning"
            className={styles.fitTextureButton}
            onClick={() => unityRedux.setFit(TILING_TYPE.TEXTURE)}
          >
            Fit Texture
          </Button>
        </div>

        <div className="row input-par">
          <div className="col-11">
            <MapTextureForm
              data={propertiesData?.texture}
              onChange={() => {}}
              onReset={() => unityRedux.reset()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TextureProperties;
