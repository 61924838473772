import React, { memo, useMemo } from 'react';
import { getProductListApi, getProductReplaceListApi } from '../../../../../api/products';
import { useDataList } from '../../../../../hooks/useDataList';
import { useProductActions } from '../../../../../hooks/useProductActions';
import {
  paginatedProductActions,
  paginatedProductSelector,
  paginatedProductSelectors,
  selectIsFavouriteLoading,
} from '../../../../../redux/slicers/admin/paginatedProductSlicer';
import ThumbnailCardUI from '../../../../../common/ThumbnailCardUI';
import { useUnityContext } from '../../../../container/unityContainer';
import InfiniteScroll from '../../../../InfiniteScroll/InfiniteScroll';
import { CuratorFilter } from '../../CuratorFilter/CuratorFilter';
import { CuratorGrid } from '../../CuratorGrid/CuratorGrid';
import { CuratorSidebarLayout } from '../../CuratorSidebarLayout/CuratorSidebarLayout';
import SearchBar from '../../SearchBar/SearchBar';
import { SelectedFilterList } from '../../SelectedFilterList/SelectedFilterList';
import { useProductsFilter } from './useProductsFilter';
import { PopupButtons } from '../lights/PopupButtons';
import { useUnityShortcuts } from '../../CuratorHeader/useUnityShortcuts';
import { useSelector } from 'react-redux';
import {
  selectReplacePropActive,
  selectReplacePropWith,
} from '../../../../../redux/slicers/admin/shortcutsModuleSlicer';
import styles from './Products.module.scss';
import { getAppState } from '../../../../../redux/store';
import { selectReplaceProp } from '../../../../../redux/slicers/admin/curatorUnityObjectSlicer';

const Products = memo(({ active }) => {
  const { handlePropsLoad } = useUnityContext();
  const { toggleFavourite } = useProductActions();
  const { replacePropConfirm, cancelReplaceProp } = useUnityShortcuts();
  const isReplacePropActive = useSelector(selectReplacePropActive);

  const {
    filterOpen,
    filter,
    filterValue,
    toggleFilterOpen,
    handleFilterChange,
    handleFilterClear,
    filterConfig,
  } = useProductsFilter();

  const requestParams = useMemo(() => {
    if (!isReplacePropActive) return;
    const selectedProp = selectReplaceProp(getAppState());
    return { id: selectedProp.id }
  }, [isReplacePropActive])

  let {
    list: productList,
    fetchMoreData,
    hasMore,
    loading,
    hasData,
    dataLoadedOnce,
    onSearchChagne,
  } = useDataList({
    getAction: isReplacePropActive ? getProductReplaceListApi : getProductListApi,
    resetAction: paginatedProductActions.resetList,
    selector: paginatedProductSelector,
    requestParams,
  });

  const handleProductClick = (product) => {
    if (isReplacePropActive) {
      replacePropConfirm(product);
    } else {
      handlePropsLoad(product);
    }
  };

  const handleFavouriteChange = (id) => (event) => {
    toggleFavourite({
      id,
      favourite: event.target.checked,
    });
  };

  return (
    <>
      <CuratorSidebarLayout.FixedHeader>
        <SearchBar
          filterActive={filterOpen}
          onChange={onSearchChagne}
          onFilterClick={toggleFilterOpen}
          placeholder="Search product"
        />

        <SelectedFilterList
          name="curatorProducts"
          className="mt-3"
          filter={filter}
          filterValue={filterValue}
          onDelete={handleFilterChange}
          onClear={handleFilterClear}
        />
      </CuratorSidebarLayout.FixedHeader>

      {isReplacePropActive && dataLoadedOnce && !hasData && <>There are no products which you can use to replace selected object</>}

      {(!dataLoadedOnce || (dataLoadedOnce && hasData)) && (
        <InfiniteScroll
          dataLength={productList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loading={loading}
          containerClassName={styles.scrollableContent}
          hasChildren={Boolean(productList.length)}
          id="curatorProductsList"
          scrollableTarget="curatorProductsList">
          <CuratorGrid>
            {productList.map((product) => {
              return (
                <ProductCard
                  key={product.id}
                  id={product.id}
                  onClick={() => handleProductClick(product)}
                  thumbnail={product?.thumbnail}
                  name={product.name}
                  isFavourite={product.is_favourite}
                  onFavouriteChange={handleFavouriteChange(product.id)}
                  size="small"
                />
              );
            })}
          </CuratorGrid>
        </InfiniteScroll>
      )}

      {isReplacePropActive && (
        <PopupButtons
          sticky
          className="mt-auto"
          onCancel={() => cancelReplaceProp()}
        />
      )}

      <CuratorFilter
        open={filterOpen}
        onChange={handleFilterChange}
        filter={filter}
        value={filterValue}
        config={filterConfig}
      />
    </>
  );
});

const ProductCard = (props) => {
  const favouriteLoading = useSelector((state) =>
    paginatedProductSelectors.selectIsFavouriteLoading(state, props.id)
  );
  return <ThumbnailCardUI {...props} favouriteLoading={favouriteLoading} />;
};

export default Products;
