/* eslint-disable no-debugger, no-unreachable */
import { toast } from 'react-toastify';
import { propsReadApi, propsV1ReadApi } from '../../../../api/curator';
import { duplicateArrayRemove } from '../../../../helpers/jsHelper';
import { selectUnityLoaded } from '../../../../redux/slicers/admin/curatorSlicer';
import store, { getAppState } from '../../../../redux/store';
import { simplifyPropData } from './simplifyPropData';

// loads unity props and sends it to unity as soon as prop data is loaded
export const loadUnityProps = async ({ unityContext, unityPropList }) => {
  console.log('sasha unityPropList', unityPropList)

  if (!unityPropList.length) return;
  const v1PropList = unityPropList.filter(prop => prop.zipVersion === 1);
  const propList = unityPropList.filter(prop => prop.zipVersion !== 1);
  
  const propIdList = duplicateArrayRemove(propList.map((p) => p.id));


  const propDetailsList = await propsReadApi(propIdList);

  if (propDetailsList?.results?.length !== propIdList.length) {
    // some of the props are missing
    toast.error('Unable to load all props, please try again');
    return;
  }
  // const simplifiedDetailsList = await Promise.all(propDetailsList.results.map(simplifyPropData));

  

  const initProp = prop => unityContext.roomModule.InstantiatePropReceiever(prop);
  const loadProp = getUnityItemLoader(initProp)
  const unityDebugArray = [];

  await Promise.all(unityPropList.map(async (a) => {
    let propDetails;

    if (a.zipVersion === 1) {
      // currently we can not load old props details in one request;
      const oldId = a.oldPropId;
      const response = await propsV1ReadApi([oldId]);
      propDetails = response.results[0];
      
      if (!propDetails) {
        toast.error('Unable to load prop details. ERROR_CODE: PP_237')
        return;
      }

    } else {
      propDetails = propDetailsList.results.find((prop) => Number(prop.id) === Number(a.id));
    }

    const simplifiedDetails = await simplifyPropData(propDetails);

    const propData = {
      ...a,
      ...simplifiedDetails,
      // ...simplifiedDetailsList.find((prop) => Number(prop.id) === a.id),
    };
    
    unityDebugArray.push(propData);
    loadProp(propData)
  }));

  console.log('UNITY_DEBUG ->> PROP LIST', unityDebugArray);
};


export const getUnityItemLoader = (load) => {
  // this function will load props immediately or as soon as unity is ready

  // unity might not be ready so we save props in this array and will send it to unity as soon as it's ready
  const delayedPropList = [];

  let alreadyHandled = false; // function is async so it might be called few times before unsubscribe will be called
  const unsubscribe = store.subscribe(async () => {
    const unityLoaded = selectUnityLoaded(getAppState());

    if (unityLoaded && !alreadyHandled) {
      alreadyHandled = true;
      await new Promise(resolve => setTimeout(resolve, 100)) // wait just in case
      delayedPropList.forEach(load)
      unsubscribe();
    }
  })

  return (item) => {
    const unityLoaded = selectUnityLoaded(getAppState());

    if (unityLoaded) {
      load(item);
    } else {
      delayedPropList.push(item)
    }
  }
}