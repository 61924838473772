import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, Container, Navbar, Form, Card, Carousel } from 'react-bootstrap';
import { BACK } from '../constants/assetConstants';
import { Link } from 'react-router-dom';
import { errorToast, successToast } from '../helpers/toastHelper';
import { UserContext } from '../contexts';
import { apiGetOrganizationBalance, apiPostPaymentIntentApi } from '../helpers/api';
import { Country, State, City } from 'country-state-city';
import { SelectFormGroup } from '../common';
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentRequestButtonElement, 
  useStripe, 
  useElements, 
  useOptions, 
  CardElement, 
  CardNumberElement, 
  CardExpiryElement, 
  CardCvcElement, 
  ElementsConsumer,
  PaymentElement
} from '@stripe/react-stripe-js';
import {
  setTitle
} from '../helpers/jsHelper';

let title = 'Add Credits';

export const PaymentForm = (props) => {

    const userCtx = useContext(UserContext);

    const stripe = useStripe();
    const elements = useElements();

    const [creditAmount, setCreditAmount] = useState(0);   
    const [balance, setBalance] = useState(0);
    const [clientSecret, setClientSecret] = useState('');
    const [fullName, setFullName] = useState('');
    const [country, setCountry] = useState(null);
    const [zipCode, setZipCode] = useState('');
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [cardSectionFilledOut, setCardSectionFilledOut] = useState(false);
    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);

    const countries = Country.getAllCountries()?.map((country) => ({
      label: country.name,
      value: country.name,
      ...country,
    }));
    
    
    // get balance to put into 'Your current balance'
    useEffect(() => {
      apiGetOrganizationBalance().then((res) => {
        if (res) {
          let { amount, borrowed_credit, effective_balance } = res;
    
          // is the balance shown on this page the same as the balance shown on transaction history page?
          if(effective_balance) {
            setBalance(effective_balance);
          }
        }
      });
      setTitle(title);
    }, [])

    // apple/google pay (this needs to be tested). Also need to always show apple and google pay, even if user does not have card on file
    useEffect(() => {
      if(stripe) {
        const pr = stripe.paymentRequest({    // creating payment request with dummy data to determine if user has google/apple pay
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Test',
            amount: creditAmount,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });       
        
        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
            if(pr) {
              pr.on('paymentmethod', async (ev) => {
                const {paymentIntent, error} = await stripe.confirmCardPayment(
                  clientSecret,
                  {payment_method: ev.paymentMethod.id},
                  {handleActions: false}
                );
              
                if (error) {
                  ev.complete('fail');
                  errorToast("Something went wrong");
                } else {
                  ev.complete("success");
                  if (paymentIntent.status === "requires_action") {
                    const {error} = await stripe.confirmCardPayment(clientSecret);
                    if (error) {
                      errorToast("Try a different payment method");
                    } else {
                      successToast("Payment successfull")
                    }
                  } else {
                    successToast("Payment successful");
                  }
                }
              });
            }
          }
        });

      }

    }, [stripe])


    // anytime 'Add money' has been changed, get new client secret 
    useEffect(() => {
      if(creditAmount > 0 && paymentRequest) {
        paymentRequest.update({
          total: {
            label: "Add credits",
            amount: creditAmount * 100    // stripe deals in pennies
          }
        })
        apiPostPaymentIntentApi(creditAmount, "card").then(async (res) => {
          setClientSecret(res);
        });
      }
    }, [creditAmount])


    // when hit 'Add money' (only for card)
    const handleSubmit = (e) => {
      e.preventDefault();

      const cardType = 'card';    

      if(validate()) {
        apiPostPaymentIntentApi(creditAmount, cardType).then(async (res) => {
          if (res) {

            let { clientSecret } = res;
            console.log("client secret: ", clientSecret);
            setClientSecret(clientSecret);

            const { error, paymentIntent } = await stripe.confirmCardPayment(
              clientSecret,
              {payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                  name: fullName,
                  address: {
                    "country": country?.isoCode,
                    "postal_code": zipCode
                  }
                },
              }},
            );

            if (error) {
              console.error(error);
              errorToast("Something went wrong");
              return;
            }
            if (paymentIntent.status === "processing") {
              successToast("Payment is processing");
            }
            else if (paymentIntent.status === 'succeeded') {
              successToast("Payment successfull");
            } else {
              console.error(
                `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
              );
            }

          }
        });
      }
    }

    useEffect(() => {
      validate();
    }, [fullName, country, zipCode, cardNumberComplete, cardExpiryComplete, cardCvcComplete])


    // used to validate the form when a Stripe element changes
    useEffect(() => {
      if(elements) {
        let cardNumber = elements.getElement(CardNumberElement);
        let expiry = elements.getElement(CardExpiryElement);
        let cvc = elements.getElement(CardCvcElement);
  
        cardNumber.on('change', function(e) {
          setCardNumberComplete(e.complete);
        })
        expiry.on('change', function(e) {
          setCardExpiryComplete(e.complete);
        })
        cvc.on('change', function(e) {
          setCardCvcComplete(e.complete);
        })
      }
    }, [elements])



    const validate = () => {
      let isValid = true;
      if(!country) {
        isValid = false;
      }
      if(!fullName || !fullName.trim()) {
        isValid = false;
      }
      if(!zipCode) {
        isValid = false;
      }
      if(!cardNumberComplete || !cardExpiryComplete || !cardCvcComplete) {
        isValid = false;
      }
      setCardSectionFilledOut(isValid);
      return isValid;
    }



    const cardOptions={
      showIcon: false,
    }

    const paymentRequestOptions = {
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: 'light-outline'
        }
      }
    }

  return (
    <div className="vw-100">
            <>
            <Row>
              <Col>
                <Navbar expand="lg" className="page-nav">
                  <Container fluid>
                    <Navbar>
                      <Navbar.Text>
                        <Link to={-1} className="back-button lf-link d-flex">
                          <img className="lf-link-img" src={BACK} alt="" />
                          Back
                        </Link>
                      </Navbar.Text>
                    </Navbar>
                  </Container>
                </Navbar>
              </Col>
            </Row>

            <div className="add-credits">
              <Container>
                <Row className="d-flex justify-content-center align-items-stretch pt-5">
                <Col lg={4} className="total-balance">
                    <Card className="py-5 px-4 h-100 border-0" style={{alignItems: "center"}}>
                      <div className="text-center">
                        <h1 className="m-bottom-1 appH2">Total balance</h1>
                      </div>
                      <div className="transaction-container">
                        <span>Your current balance</span>
                        <h1 className="appH2Light">${balance}</h1>
                      </div>
                      <h3 className="my-3 your-purchase appH3Bold">Your purchase</h3>
                      <Form action="" style={{width: "85%"}}>
                        <Form.Group className="py-3">
                            <Form.Label className="title-color float-left">Add money</Form.Label>
                          <Form.Control
                            type="number"
                            id="form2Example212"
                            name="creditAmount"
                            placeholder="Enter amount of money"
                            value={creditAmount || ''}
                            onChange={(e) => setCreditAmount(parseInt(e.target.value, 10))}
                            min="0"
                          />
                        </Form.Group>
                        
                      </Form>
                    </Card>
                  </Col>
                  <Col lg={8}>
                    <Card className="py-5 px-4 h-100 border-0 payment-info">
                        <div className="credit">
                          <div className="section-one">
                              {/* <div className="button-for-paynent">
                                <div className="apple-pay">
                                    <img src={APPLE_PAY} alt=""/>
                                </div>
                                <div className="google-pay">
                                    <img src={GOOGLE_PAY} alt=""/>
                                </div>
                             </div> */}
                            <div className='pb-4'>
                              {paymentRequest && <PaymentRequestButtonElement options={paymentRequestOptions}/> }
                            </div>
                            <div className="title-for-payment">
                                <p><span>Or pay with card</span></p>
                            </div>
                            <div className="credit-forms">
                                <div>
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="d-flex">
                                            <div className="d-flex flex-column w-100 m-2">
                                              <div className="py-3">
                                                <label className="title-color float-left form-label">Card number</label>
                                                <div className="form-control">
                                                  <CardNumberElement options={cardOptions}/>
                                                </div>
                                              </div>

                                                
                                                <Form action="">
                                                    <Form.Group className="py-3">
                                                        <Form.Label className="title-color float-left">Full name on card</Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        id="fullname"
                                                        name="fullName"
                                                        placeholder="Type name"
                                                        value={fullName || ''}
                                                        onChange={(e) => setFullName(e.target.value)}
                                                      />
                                                    </Form.Group>
                                                    
                                                  </Form>

                                                <Form action="">
                                                    <Form.Group className="py-3">
                                                        <Form.Label className="title-color float-left">Zipcode</Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        id="fullname"
                                                        name="zipcode"
                                                        placeholder="Type name"
                                                        value={zipCode || ''}
                                                        onChange={(e) => setZipCode(e.target.value)}
                                                      />
                                                    </Form.Group>  
                                                  </Form>

                                                </div>
                                                <div className="d-flex flex-column w-100 p-2">

                                                  <div className="d-flex flex-row">
                                                    <div className="py-3 pe-3 fullWidth">
                                                        <label className="title-color float-left form-label">Expiry date</label>
                                                        <div className="form-control">
                                                          <CardExpiryElement/>
                                                        </div>
                                                    </div>
                                                    <div className="py-3 fullWidth">
                                                        <label className="title-color float-left form-label">CVV</label>
                                                        <div className="form-control">
                                                          <CardCvcElement/>   
                                                        </div>
                                                    </div>

                                                  </div>
                                                  <SelectFormGroup
                                                    label="Country or region"
                                                    id="country"
                                                    name="country"
                                                    selectLabel="country"
                                                    options={countries}
                                                    value={country}
                                                    onChangeCallback={(selection) => {setCountry(selection);}}
                                                    placeholder="Select country"
                                                    fullWidth
                                                  />
                                                </div>
                                          </div>
                                          <Form.Group className="d-grid py-3 mt-2">
                                          <button onClick={handleSubmit} className={cardSectionFilledOut ? 'btn btn-warning' : 'btn light-btn'} type="submit">
                                            <span className="btn-font">Add money</span>
                                          </button>
                                        </Form.Group>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </Card>
                  </Col>
                
                </Row>
              </Container>
            </div>
            </>
      
    </div>
  );
}


export default function AddCredits() {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);   

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
      {({ stripe, elements }) => (
        <PaymentForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  </Elements>
  )
}
