const BinIcon = ({ size = 24, ...rest}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.23865 6.33357L9.0955 7.18597C8.46767 7.22005 7.84106 7.26805 7.2159 7.3315L5.84958 7.46546C5.57497 7.49225 5.37404 7.73336 5.40083 8.01466C5.42762 8.29597 5.67544 8.49689 5.95674 8.4701L6.4494 8.4218L7.27619 16.8624C7.34986 17.9072 7.44363 19.1999 9.78111 19.1999H14.081C16.4185 19.1999 16.5123 17.9072 16.5859 16.8624L17.2815 8.40993C17.4894 8.42919 17.6974 8.44927 17.9054 8.4701H17.9589C18.2135 8.4701 18.4345 8.27587 18.4546 8.01466C18.4814 7.74006 18.2804 7.49225 18.0058 7.46546C16.9267 7.35954 15.8481 7.2727 14.7712 7.2126L14.7709 7.21096L14.6236 6.34027L14.6229 6.33633C14.5225 5.72016 14.3724 4.7998 12.8085 4.7998H11.0537C9.49428 4.7998 9.34603 5.68932 9.23888 6.33219L9.23865 6.33357ZM16.2785 8.32356C13.3233 8.08899 10.3756 8.037 7.45097 8.32341L8.28084 16.7954L8.28097 16.7973C8.35454 17.8139 8.38214 18.1952 9.78111 18.1952H14.081C15.4875 18.1952 15.5143 17.8135 15.5813 16.7954L16.2785 8.32356ZM13.639 6.50771L13.7501 7.16384C12.538 7.11603 11.3285 7.10523 10.1231 7.14238L10.2366 6.50101L10.2376 6.49498C10.3372 5.90429 10.3529 5.81115 11.0604 5.81115H12.8152C13.5252 5.81115 13.5452 5.92501 13.639 6.50771ZM13.043 15.5161H10.8126C10.538 15.5161 10.3103 15.2883 10.3103 15.0137C10.3103 14.7391 10.538 14.5114 10.8126 14.5114H13.043C13.3176 14.5114 13.5453 14.7391 13.5453 15.0137C13.5453 15.2883 13.3176 15.5161 13.043 15.5161ZM10.2567 12.837H13.6055C13.8801 12.837 14.1078 12.6093 14.1078 12.3347C14.1078 12.0601 13.8801 11.8323 13.6055 11.8323H10.2567C9.98209 11.8323 9.75437 12.0601 9.75437 12.3347C9.75437 12.6093 9.98209 12.837 10.2567 12.837Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BinIcon;
