import { memo, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { curatorStylesSelector } from "../../../../../redux/slicers/admin/curatorStylesSlicer";
import { useUnityColors } from "../useUnityRedux";
import { ColorPicker } from "../../ColorPicker/ColorPicker";
import { ColorsTabSwatches } from "./ColorsTabSwatches";
import { CuratorBox } from "../../CuratorBox";
import { useColors } from "./useColors";

const ColorsTab = memo(({ active }) => {
  const selectedColor = useSelector(
    (state) => curatorStylesSelector(state).selectedColor
  );
  const { changeColor } = useUnityColors();
  const { colorList, addColor, deleteColor } = useColors();

  if (!active) return null;

  return (
    <>
      <CuratorBox>
        <ColorPicker
          color={selectedColor}
          onChange={(color) => {
            changeColor(color)
          }}
          className="w-100"
          disableAlpha
        />
      </CuratorBox>

      <ColorsTabSwatches
        selectedColor={selectedColor}
        colorList={colorList}
        onAdd={addColor}
        onDelete={deleteColor}
      />
    </>
  );
});

export default ColorsTab;
