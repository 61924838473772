import {
  ADMIN_CURATOR,
  PROJECT_RENDER,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH
} from "../config/constant/routePathConstant";
import pluralize from 'pluralize';

import { MY_PROJECTS_ROUTE } from "../constants/routePathConstants";

export const getProjectPath = ({ id }) => {
  return PROJECT_RENDER.replace(":projectId", id);
};

export const getCuratorPath = (id, type) => {
  let item = pluralize.singular(type);
  return `${ADMIN_CURATOR}/${item}/${id}?type=${item}`;
};

export function removeParams(path) {
  return path
    .split("/")
    .filter((x) => x.charAt(0) !== ":")
    .join("/");
}


export const getProjectsPath = () => {
  return MY_PROJECTS_ROUTE;
}