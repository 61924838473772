import {createSlice} from "@reduxjs/toolkit";

const settings = createSlice({
    name: 'settings',
    initialState: {
       userDetails:{},
       userBalance:{},
       creditAmount:0,
       clientSecret:'',
       transactionHistory:[],
       selectedIndustry:'',
       selectedPlatform:'',
       showSavePassword:false,
       selectedProfession:'',
       notificationSettings:{}
    },
    reducers: {
        setUserDetails: (state, {payload}) => {
            state.userDetails = payload
        },
        setUserBalance: (state, {payload}) => {
            state.userBalance = {...payload}
        },
        setCreditAmount: (state, {payload})=>{
            state.creditAmount = payload
        },
        setClientSecret:(state, {payload})=>{
            state.clientSecret = payload        
        },
        setTransactionHistory:(state, {payload})=>{
            state.transactionHistory = payload
        },
        setSelectedIndustry:(state, {payload})=>{
            state.selectedIndustry = payload
        },
        setSelectedPlatform:(state, {payload})=>{
            state.selectedPlatform = payload
        },
        setSavePassword:(state, {payload})=>{
            state.showSavePassword = payload
        },
        setSelectedProfession:(state,{payload})=>{
            state.selectedProfession = payload
        },
        setNotificationSettings:(state,{payload})=>{
            state.notificationSettings = {...payload}
        },
    }
})

export const {setUserDetails, setUserBalance, setCreditAmount, setClientSecret, setTransactionHistory, setSelectedIndustry, setSavePassword, setSelectedPlatform, setSelectedProfession, setNotificationSettings} = settings.actions;
export const settingsSelector = state => state.settings;
export const settingsReducer = settings.reducer;
export default settingsReducer


