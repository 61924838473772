import cx from 'classnames';
import { useSelector } from "react-redux";
import { curatorSelector } from "../../../redux/slicers/admin/curatorSlicer";

import MoreSection from "./moreSection/moreSection";
import StyleSection from "./stylesSection/stylesSection";
import Setting from './components/Setting';
import Images from './components/images/Images';
import Products from './components/products/Products';
import { Lights } from './components/lights/lights';
import { MetadataSection } from './MetadataSection/MetadataSection';
import { CuratorSidebarLayout } from './CuratorSidebarLayout/CuratorSidebarLayout';
import { CURATOR_MENU_SECTION } from '../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import { Camera } from './components/camera/Camera';

const SidebarSections = () => {
  const sidebarActiveSection = useSelector(
    (state) => curatorSelector(state).sidebarSection
  );


  const sections = {
    [CURATOR_MENU_SECTION.PRODUCTS]: {
      Component: Products,
      className: "product-menu",
      keepMounted: true,
    },
    [CURATOR_MENU_SECTION.STYLE]: {
      Component: StyleSection,
      className: "style-menu",
      keepMounted: true,
    },
    [CURATOR_MENU_SECTION.CAMERAS]: {
      Component: Camera,
      className: "camera-menu",
      keepMounted: true,
    },
    [CURATOR_MENU_SECTION.SETTINGS]: {
      Component: Setting,
      className: "setting-menu",
      keepMounted: true,
    },
    [CURATOR_MENU_SECTION.RENDERS_AND_PREVIEWS]: {
      Component: Images,
      className: "camera-menu",
      keepMounted: true,
    },
    [CURATOR_MENU_SECTION.METADATA]: {
      Component: MetadataSection,
      className: 'metadata-menu metadata',
      keepMounted: true,
    },
    [CURATOR_MENU_SECTION.LIGHT]: {
      Component: Lights,
      className: "light-menu",
      keepMounted: true,
    },
    [CURATOR_MENU_SECTION.MORE]: {
      Component: MoreSection,
      keepMounted: false,
    },
  };


  return Object.entries(sections).map(([sectionName, config]) => {
    if (!config) return null;

    const active = sectionName === sidebarActiveSection;
    
    if (!active && !config.keepMounted) return null;

    return (
      <CuratorSidebarLayout className={cx(config.className, !active  && 'hide')} key={sectionName}>
        <CuratorSidebarLayout.Inner name={sectionName}>
          <config.Component active={active} />
        </CuratorSidebarLayout.Inner>
        {active &&  <CuratorSidebarLayout.Button location="sidebar" />}
      </CuratorSidebarLayout>
    );
  });
};

export default SidebarSections;
