export const CloseIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5703 17.4852C16.0857 18.0006 16.9405 18.0006 17.4559 17.4852C17.9713 16.9698 17.9713 16.115 17.4559 15.5996L10.8563 9L17.4559 2.4004C17.9713 1.885 17.9713 1.03019 17.4559 0.514786C16.9405 -0.000616216 16.0857 -0.000616216 15.5703 0.514785L8.97069 7.11438L2.37096 0.514658C1.85556 -0.000744722 1.00075 -0.000744551 0.485347 0.514658C-0.0300558 1.03006 -0.0300557 1.88487 0.485346 2.40028L7.08507 9L0.485346 15.5997C-0.030056 16.1151 -0.030056 16.9699 0.485346 17.4853C1.00075 18.0007 1.85556 18.0007 2.37096 17.4853L8.97069 10.8856L15.5703 17.4852Z"
        fill="currentColor"
      />
    </svg>
  );
};
