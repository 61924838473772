/* eslint-disable */
import cx from 'classnames';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { unitycontext } from '../../../../hooks/useUnityContext';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorage } from '../../../../helpers/jsHelper';
import {
  getProjectCameraAnglesList,
  cameraAngleSelector,
} from '../../../../redux/slicers/camera/cameraAngleSlicer';
import {
  projectCameraAngleSelector,
  setProjectCameraAnglesList,
  updateCamera,
} from '../../../../redux/slicers/camera/projectCameraAngleSlicer';
import { warningToast, errorToast, successToast } from '../../../../helpers/toastHelper';
import { createRenderAPI } from '../../../../api/camera/renders/renderCamera';
import { CLOSE_ICON } from '../../../../config/constant/assetConstant';
import { useParams } from 'react-router-dom';
import { updateProjectCameraAngleAPI } from '../../../../api/camera/projectCamera';
import { setCuratorLoader } from '../../../../redux/slicers/admin/curatorSlicer';
import { useToggleListItem } from '../../../../hooks/useToggleListItem';
import { CameraList } from './CameraList';
import {
  selectOpen,
  selectPopupType,
} from '../../../../redux/slicers/camera/renderPreviewPoppupSlicer';
import { useRenderPreviewPopup } from '../../../../hooks/useRenderPreviewPopup';
import { useSaveRenderPreview } from '../components/camera/useSaveRenderPreview';
import { CloseIcon } from '../../../icons/CloseIcon';
import styles from './RenderPreviewDialog.module.scss';
import { PreviewRenderForm } from '../components/camera/PreviewRenderForm';
import { usePrevious } from 'react-use';
import { setGlobalHeight, setGlobalSafeCameraFlag, setGlobalWidth } from '../../../../redux/slicers/camera/cameraSettings';
import { LockIcon } from '../../../icons/LockIcon';
import { toast } from 'react-toastify';
import { RP_TYPE } from '../../../../config/constant/unityConstants';

export const RenderPreviewDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectOpen);
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (prevOpen && !open) {
      dispatch(setGlobalSafeCameraFlag(false));
    }
  }, [open]);

  if (!open) return null;
  console.log('sasha render dialong ', open);

  return <RenderPreviewDialogContent />;
};

const RenderPreviewDialogContent = (props) => {
  const dispatch = useDispatch();
  const saveSuccessRef = useRef(false);
  const { closePopup } = useRenderPreviewPopup();
  const popupType = useSelector(selectPopupType);
  const [selectedCameraId, setSelectedCameraId] = useState(null);
  const { getProjectCameraAnglesList: cameraList } = useSelector(projectCameraAngleSelector);
  const {
    ids: selectedCameraIds,
    toggleItem,
    toggleAll,
    resetSelectedItems,
    allSelected,
  } = useToggleListItem({ items: cameraList });

  const isPreview = popupType === RP_TYPE.PREVIEW;
  const isRender = popupType === RP_TYPE.RENDER;

  const { savePreview, saveRender } = useSaveRenderPreview();

  const handleSaveRenderPreview = async (values) => {
    const selectedCameraList = cameraList.filter((c) => selectedCameraIds.includes(c.id));

    if (!values.name) {
      errorToast('Please provide a name');
      return;
    }

    if (!selectedCameraList.length) {
      errorToast('Please add at least one camera')
      return;
    }

    dispatch(setCuratorLoader(false))

    await Promise.all(selectedCameraList.map(async (camera) => {
      const formData = new FormData();
      formData.append('resoX', camera.resoX);
      formData.append('resoY', camera.resoY);
      await dispatch(updateProjectCameraAngleAPI(camera.id, formData, { disableToast: true  }));
    }))

    const data = {
      data: values,
      cameraList: selectedCameraList,
      onSuccess: () => {
        saveSuccessRef.current = true; // save this status so camera list is not reset to initial value
        resetSelectedItems();
        setSelectedCameraId(null);
        closePopup();
        dispatch(setCuratorLoader(true))
      },
    };

    popupType === RP_TYPE.PREVIEW ? savePreview(data) : saveRender(data);
  };

  useEffect(() => {
    // reset camera list to previous values if modal was closed without saving
    const initialCameraList = cameraList;
    return () => {
      if (!saveSuccessRef.current) {
        dispatch(setProjectCameraAnglesList(initialCameraList))
      }
    }
  }, [])
  

  const handleCameraClick = (camera) => {
    setSelectedCameraId(camera.id);
  }

  const updateData = (name, value) => {
    dispatch(updateCamera({
      id: selectedCameraId,
      [name]: value,
    }))
    
    // update top bar
    name === 'resoX' 
      ? dispatch(setGlobalWidth(value))
      : dispatch(setGlobalHeight(value));
  }

  const  handleResolutionChange = (e) => {
    const { name, value } = e.target
    const valueInt = parseInt(value) || 0
    updateData(name, valueInt)
  }

  const handleResolutionBlur= (e) => {
    const { name, value } = e.target
    const valueInt = parseInt(value)
    const isPositiveInteger = valueInt > 0;
    if (!isPositiveInteger) {
      updateData(name, 1);
    }
  }


  const title = isPreview ? 'Preview camera' : 'Render settings';
  const selectedCamera = cameraList.find(c => c.id === selectedCameraId);

  return (
    <Modal
      show={true}
      backdrop={false}
      dialogClassName={cx(styles.modalDialog, isPreview && styles.small)}
      contentClassName={styles.modalContent}>
      <Modal.Body>
        <h5 className={cx(styles.title, 'mb-3')}>{title}</h5>
        <button className={styles.closeButton} onClick={closePopup}>
          <CloseIcon />
        </button>

        <div className={cx(styles.container, isPreview && styles.containerColumn)}>
          <CameraList
            cameraList={cameraList}
            selected={selectedCameraIds}
            allSelected={allSelected}
            onToggleAll={toggleAll}
            onToggle={toggleItem}
            onCameraClick={handleCameraClick}
            className={isRender ? styles.renderCameraList : styles.previewCameraList}
          />

          <div>
            {isRender && (
              <div className="row">
                <div className={`${styles.resolutionInput} col-5 mb-3`}>
                  <label className="form-label">Width (px)</label>
                  <input
                    type="number"
                    className="form-control"
                    step={1}
                    disabled={!selectedCamera}
                    value={selectedCamera?.resoX || ''}
                    name="resoX"
                    onChange={handleResolutionChange}
                    onBlur={handleResolutionBlur}
                  />
                </div>
                <div className="lock-icon col-2 pt-3 d-flex align-items-center justify-content-center">
                  <LockIcon  style={{ color: 'var(--neutral60-color)' }} />
                </div>
                <div className={`${styles.resolutionInput} col-5 mb-3`}>
                  <label className="form-label">Height (px)</label>
                  <input
                    type="number"
                    className="form-control"
                    step={1}
                    disabled={!selectedCamera}
                    value={selectedCamera?.resoY || ''}
                    name="resoY"
                    onChange={handleResolutionChange}
                    onBlur={handleResolutionBlur}
                  />
                </div>
              </div>
            )}

            <PreviewRenderForm
              className={styles.previewRenderForm}
              type={popupType}
              onSubmit={handleSaveRenderPreview}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
