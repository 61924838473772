import { useEffect, useRef, useState } from 'react';
import { CustomPicker } from 'react-color';
import styles from './RgbPicker.module.scss';

export const RgbPicker = (props) => {
  const { onChange, rgb, hsv, colorType, hex } = props;
  const { h, s, v } = hsv;
  const { r, g, b } = rgb;

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = Math.round(value);

    if (Number.isNaN(value) || value < 0) {
      value = 0;
    }

    if (colorType === 'RGB') {
      if (value > 255) {
        value = 255;
      }

      const newRgb = { r, g, b, [name]: value };
      console.log('sasha color value', newRgb);
      onChange(newRgb);
    } else {
      if (name === 'h' && value > 360) {
        value = 360;
      }
      if ((name === 's' || name === 'v') && value > 100) {
        value = 100;
      }

      const newHsv = {
        h,
        s,
        v,
        [name]: name === 'h' ? Math.round(value) : parseFloat((value / 100).toFixed(2)),
      };
      onChange(newHsv);
    }
  };

  if (colorType === 'HSV') {
    const sNormalized = Math.round(s * 100);
    const vNormalized = Math.round(v * 100);
    const hNormalized = Math.round(h);
    return (
      <div>
        <HsvItem name="h" h={hNormalized} s={sNormalized} v={vNormalized} onChange={handleChange} />
        <HsvItem name="s" h={hNormalized} s={sNormalized} v={vNormalized} onChange={handleChange} />
        <HsvItem name="v" h={hNormalized} s={sNormalized} v={vNormalized} onChange={handleChange} />
      </div>
    );
  }

  return (
    <div>
      <RgbItem name="r" r={r} g={g} b={b} onChange={handleChange} />
      <RgbItem name="g" r={r} g={g} b={b} onChange={handleChange} />
      <RgbItem name="b" r={r} g={g} b={b} onChange={handleChange} />
    </div>
  );
};

const RgbItem = ({ name, onChange, ...rest }) => {
  const value = rest[name];

  const start = {
    r: rest.r,
    g: rest.g,
    b: rest.b,
    [name]: 0,
  };

  const LETTERS = {
    r: 'R',
    g: 'G',
    b: 'B',
  };

  const letter = LETTERS[name];

  const end = { ...start, [name]: 255 };

  const gradientStart = [start.r, start.g, start.b];
  const gradientEnd = [end.r, end.g, end.b];

  const gradient = `linear-gradient(to right, rgb(${gradientStart.join(
    ', '
  )}) 0%, rgb(${gradientEnd.join(', ')}) 100%)`;

  return (
    <Row
      letter={letter}
      name={name}
      value={value}
      onChange={onChange}
      min={0}
      max={255}
      gradient={gradient}
    />
  );
};

const HsvItem = ({ name, onChange, ...rest }) => {
  const value = rest[name];
  const isHue = name === 'h';

  const start = {
    h: rest.h,
    s: `${rest.s}%`,
    v: `${rest.v}%`,
    [name]: isHue ? 0 : '0%',
  };

  const LETTERS = {
    h: 'H',
    s: 'S',
    v: 'V',
  };

  const letter = LETTERS[name];

  const end = { ...start, [name]: isHue ? 360 : '100%' };

  const gradientStart = [start.h, start.s, start.v];
  const gradientEnd = [end.h, end.s, end.v];

  const gradient = `linear-gradient(to right, hsl(${gradientStart.join(
    ', '
  )}) 0%, hsl(${gradientEnd.join(', ')}) 100%)`;

  return (
    <Row
      letter={letter}
      name={name}
      value={value}
      onChange={onChange}
      min={0}
      max={isHue ? 360 : 100}
      gradient={gradient}
      // afterValue={isHue ? "" : "%"}
    />
  );
};

const Row = ({ letter, name, value, onChange, min, max, gradient, afterValue }) => {
  const draggableWidth = 8;
  const draggablePosition = `calc(((100% - ${draggableWidth}px) / ${max}) * ${value})`;
  const [inputValue, setInputValue] = useState(value);
  const valueRef = useRef(value);

  valueRef.current = value;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = (e) => {
    onChange(e);

    setTimeout(() => {
      setInputValue(valueRef.current)
    }, 50);
  };

  return (
    <div className={styles.rgbItem}>
      <div className={styles.letterContainer}>{letter}</div>

      <div className={styles.colorLineContainer}>
        <div
          className={styles.draggablePointer}
          style={{ transform: `translateX(${draggablePosition})` }}
        />
        <input
          type="range"
          name={name}
          min={min}
          max={max}
          value={value}
          step={1}
          onChange={onChange}
        />
        <div
          className={styles.colorLine}
          style={{
            background: gradient,
          }}></div>
      </div>

      <div className={styles.colorValue}>
        {/* {value} */}
        <input
          type="text"
          name={name}
          value={inputValue}
          maxLength={4}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
      </div>
      {/* {afterValue} */}
    </div>
  );
};
