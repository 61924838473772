import { useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { UnionExclamation } from '../constants/assetConstants';
import makeAnimated from 'react-select/animated';

export default function SelectFormGroup(props) {
  const [options, setOptions] = useState(props.options);

  const selectBoxStyles = {
    container: (provided, state) => ({
      ...provided,
      height: 'fit-content',
      minHeight: 'fit-content',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: props.error ? '#FCEAE8' : '#e9e9e9',
      border: props.error ? '1px solid #e4291d' : '1px solid #ced4da',
      borderRadius: '8px',
      padding: '0.4rem 0.4rem',
      width: '100%',
      height: 'fit-content',
      minHeight: 'fit-content'
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxHeight: "2.4rem",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        //backgroundColor: isDisabled ? 'red' : 'blue',
        //color: 'green',
        //cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  function handleCreate(e) {
    let newList = Object.assign([], options);
    let newSelections = Object.assign([], props.value);
    let selection = {
      label: e,
      value: e,
    };
    newList.unshift(selection);
    newSelections.push(selection);
    setOptions(newList);
    props.onChangeCallback(newSelections);
  }
  let commonProps = {
    id: props.id,
    name: props.name,
    label: props.selectLabel,
    value: props.value,
    options: options,
    className: `${props.error ? 'input_error_style' : ''}`,
    placeholder: props.placeholder || '',
    isMulti: props.isMulti,
    makeAnimated: makeAnimated,
  };
  let multiOnlyControl = null;
  // let multiOnlyControl = props.isMulti
  //   ? {
  //       Control: ({ children, ...props }) => (
  //         <div className="lfSelectMulti__control" {...props}>
  //           {children}
  //         </div>
  //       ),
  //     }
  //   : {};
  return (
    <Form.Group
      className={
        props.groupClassName ||
        cx(
          {
            fullWidth: props.fullWidth,
          },
          'form-outline mb-2 py-3 col-md-6'
        )
      }>
      {props.error ? (
        <Form.Label className="title-error-color float-left">
          <img
            src={UnionExclamation}
            style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
          />
          {props.errorMessage || 'One option must be chosen'}
        </Form.Label>
      ) : (
        <Form.Label className="title-color float-left">{props.label}</Form.Label>
      )}
      {props.allowCreatable ? (
        <CreatableSelect
          onCreateOption={handleCreate}
          onChange={props.onChangeCallback}
          {...commonProps}
          onBlur={(e) => {
            if (_.isFunction(props.onBlurCallback)) {
              props.onBlurCallback(e.target.value);
            } else {
              console.log(
                `CreatableSelect: no onBlur function enabled for this item id: ${props.id}`
              );
            }
          }}
          onFocus={(e) => {
            if (_.isFunction(props.onFocusCallback)) {
              props.onFocusCallback(e.target.value);
            } else {
              console.log(
                `CreatableSelect: no onFocus function enabled for this item id: ${props.id}`
              );
            }
          }}
        />
      ) : (
        <Select
          {...commonProps}
          styles={selectBoxStyles}
          options={props.options}
          onChange={props.onChangeCallback}
          classNamePrefix={commonProps.isMulti ? 'lfSelectMulti' : ''}
          onBlur={(e) => {
            if (_.isFunction(props.onBlurCallback)) {
              props.onBlurCallback(e.target.value);
            } else {
              console.log(`no onBlur function enabled for this item id: ${props.id}`);
            }
          }}
          onFocus={(e) => {
            if (_.isFunction(props.onFocusCallback)) {
              props.onFocusCallback(e.target.value);
            } else {
              console.log(`no onFocus function enabled for this item id: ${props.id}`);
            }
          }}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
            ...multiOnlyControl,
          }}
        />
      )}
    </Form.Group>
  );
}
