import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { GridSection } from '../common';
import {
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
  REQUESTS_ROUTE,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_TEXTURE,
  MY_PRODUCTS_ROUTE,
} from '../constants/routePathConstants';
import { CommonGrid, TopContainer } from '../layouts';
import { ReactLoader } from '../layouts/Loading';
import {
  BigButton,
  TopButtonArea,
  ThumbnailContainer,
  ModalButtonArea,
  DesignTemplates,
  InfiniteScroll,
  ActionFilters,
  Image,
  SelectFormGroup,
  GlobalFilter,
  Input,
  RightSidebar,
  MiniButton,
  Download,
  AddToCollections,
  SharePopup,
  DeactivatePopup,
  EditButton,
  DropzoneUpload,
} from '../common';
import constants from '../constants/constants';
import _ from 'lodash';
import { icons } from '../assets';
import { FilterContext, UserContext } from '../contexts';
import {
  getCommonMetadata,
  addValueLabelToList,
  constructQueryParams,
  changeFilter,
  setTitle
} from '../helpers/jsHelper';
import { apiGetCachedList, apiErrorHandler } from '../helpers/axiosHelper';
import {
  apiGetTextures,
  apiGetTextureList,
  apiEditProject,
  parseBasePathname,
  apiDeleteProjects,
  parseProductTypePathname,
} from '../helpers/api';
import { getScreenSettings } from '../helpers/configureHelper';
import {
  EDIT_ICON,
  SHARE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
  DELETE_ICON,
} from '../constants/assetConstants';

const { PROJECT_TYPE_OPTIONS, FILTER_TYPES } = constants;
const { UploadIcon } = icons;

function TextureEdit(props) {
  const { files } = props;

  return null;
}

let title = 'My Products';

export default function MyProductsTextures(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = parseBasePathname(location);
  const productType = parseProductTypePathname(location);
  let cardType = props && props.cardType ? props.cardType : _.replace(pathname, '/', '');
  let routeParams = useParams();
  let createNew;
  if (routeParams) {
    createNew = parseInt(routeParams.projectId, 10) < 0;
  }
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && currentUser.name;
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sortField, setSortField] = useState();
  const [globalTags, setGlobalTags] = useState([]);
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [total, setTotal] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [importFiles, setImportFiles] = useState([]);

  const pageSettings = getScreenSettings(cardType);
  const { sortOptions, filterOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter } =
    pageSettings;

  useEffect(() => {
    setTitle(title);
  }, []);

  useEffect(() => {
    if (!sortField && defaultSortOption) {
      setSortField(defaultSortOption);
    }
  }, [sortOptions, sortField]);

  useEffect(() => {
    if (!props.carouselView) {
      let breadcrumbs = productType
        ? [
            {
              link: MY_PRODUCTS_ROUTE,
              label: 'My products',
            },
            {
              link: null,
              label: _.startCase(productType),
            },
          ]
        : null;
      filterCtx.setBreadcrumbs(breadcrumbs);
    }
  }, [productType, props.carouselView]);

  useEffect(async () => {
    refreshList();
    let tagList = await apiGetCachedList('global_tags');
    if (tagList && tagList.length > 0) {
      setGlobalTags(tagList);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (filterCtx) {
        filterCtx.cleanGlobalFiltersOnNavigate();
      }
    };
  }, []);

  useEffect(() => {
    let newFilters = Object.assign([], filters);
    const index = _.findIndex(newFilters, (f) => f.label === FILTER_TYPES.BRANDS);
    // let brandFilter = {};
    // if (index >= 0) {
    //   brandFilter = Object.assign({}, newFilters[index])
    // }
    // brandFilter.label = FILTER_TYPES.BRANDS;
    // brandFilter.value = currentUser?.organization
    // if (index >= 0) {
    //   newFilters[index] = brandFilter;
    // } else {
    //   newFilters.push(brandFilter)
    // }
    let params = constructQueryParams(
      sortField,
      newFilters,
      null,
      filterCtx?.globalFilters,
      filterCtx?.teamMembers
    );
    if (params !== query) {
      setQuery(params);
    }
  }, [sortField, filters, filterCtx?.globalFilters, filterCtx?.teamMembers]);

  useEffect(() => {
    refreshList();
  }, [query, filterCtx?.refListsReady]);

  useEffect(addToList, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    refreshList();
  }, [userCtx.currentUser.organization]);

  async function addToList() {
    if (!loading && filterCtx?.refListsReady) {
      const res = await apiGetTextureList(query + `&page=${pageNumber}`);
      if (res) {
        if (res.results) {
          let newList = _.map(res.results, (r) => {
            return r;
          });
          setTotal(res.count);
          setTotalPages(res.total_pages);
          setList([...list, ...newList]);
        }
        if (res.sidefilter) {
          filterCtx.handleFilterOptions(res.sidefilter);
        }
      }
    }
  }

  function refreshList() {
    if (!loading && filterCtx?.refListsReady) {
      setLoading(true);
      apiGetTextureList(query)
        .then((res) => {
          if (res) {
            if (res.total_pages) {
              setTotalPages(res.total_pages);
            }
            if (res.current_page_number) {
              setPageNumber(res.current_page_number);
            }
            setTotal(res.count);
            if (res.results) {
              let newList = _.map(res.results, (r) => {
                r.project_type = _.find(PROJECT_TYPE_OPTIONS, (t) => t.value === r.project_type);
                r.filteroptions = addValueLabelToList(r.filteroptions);
                r.tags =
                  r.tags && r.tags.length > 0
                    ? _.map(r.tags, (t) => {
                        return {
                          label: t,
                          value: t,
                        };
                      })
                    : [];

                return r;
              });
              setList(newList);
            }
            if (res.sidefilter) {
              filterCtx.handleFilterOptions(res.sidefilter);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  function handleCardSelect(id, allOrNone = null) {
    let newList = _.map(list, (item) => {
      if (item.id === id) {
        item.selected = item.selected ? false : true;
      }
      if (_.isBoolean(allOrNone)) {
        item.selected = allOrNone;
      }
      return item;
    });
    setList(newList);
  }

  function handleSort({ value }) {
    setPageNumber(1);
    setSortField(value);
  }

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function handleFilter(field, selection) {
    setPageNumber(1);
    setFilters(changeFilter(filters, field, selection));
  }

  function onChange(field, value) {
    let item = { ...editItem };
    item[field] = value;
    setEditItem(item);
  }

  const onEdit = async (id) => {
    let itemToEdit = _.find(list, (p) => {
      return p.id === id;
    });
    setEditItem(itemToEdit);
  };

  const saveProject = async () => {
    if (!editItem) return;
    setSaving(true);
    const { name, project_type, tags, filteroptions } = editItem;
    let payload = {
      name: name,
      project_type: project_type?.value,
      // file: filejson,
      // is_favourite: editItem.is_favourite,
      // thumb: thumbnailfile
    };
    if (filteroptions && filteroptions.length > 0) {
      payload.filteroptions = _.chain(filteroptions)
        .map((o) => o.value)
        .join(',')
        .value();
    }
    if (tags && tags.length > 0) {
      payload.tags = _.chain(tags)
        .map((o) => o.label)
        .toArray()
        .value();
    }

    await new Promise((resolve, reject) => {
      apiEditProject(editItem.id, payload)
        .then((res) => {})
        .catch(apiErrorHandler)
        .finally(() => {
          setSaving(false);
          setEditItem(null);
          refreshList();
        });
    });
  };

  function uploadFiles(files) {
    setModalOpen(false);
    let filesWithPreviewData = _.map(files, (f) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        var contents = e.target.result;

        const byteCharacters = window.atob(contents);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        f.thumbnail = blob;
      };
      return f;
    });
    navigate(ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD, {
      state: { files: filesWithPreviewData },
    });
  }

  function onClose() {
    refreshList();
    setModalOpen(false);
  }

  const { hasData, anySelected, allSelected, selectedList } = getCommonMetadata(
    list,
    pageNumber,
    totalPages
  );

  const hasMore = pageNumber < totalPages;

  if (!filterCtx) return <ReactLoader />;
  if (props.carouselView) {
    return (
      <GridSection title={props.title} seeAllLink={ADMIN_MY_PRODUCTS_TEXTURE} carouselData={list} />
    );
  }
  return (
    <CommonGrid
      topContainer={
        <TopContainer
          title={<>Textures {total && <small>({total})</small>}</>}
          item={cardType}
          loading={loading || !filterCtx?.refListsReady}
          hasData={hasData}
          buttonArea={
            <TopButtonArea
              hasData={hasData}
              item={cardType}
              topButtonList={[
                {
                  element: DeactivatePopup,
                  bigButton: true,
                  title: 'Delete',
                  iconSrc: DELETE_ICON,
                  item: cardType,
                  onSuccess: refreshList,
                  selectedList: selectedList,
                },
                {
                  element: EditButton,
                  title: 'Edit',
                  iconSrc: EDIT_ICON,
                },
                {
                  element: AddToCollections,
                  bigButton: true,
                  item: cardType,
                  onSuccess: refreshList,
                  selectedList: selectedList,
                },
                {
                  element: SharePopup,
                  bigButton: true,
                  onSuccess: refreshList,
                },
                {
                  element: Download,
                  title: 'Download',
                  iconSrc: DOWNLOAD_ICON,
                  onSuccess: refreshList,
                },
              ]}
              addNewButtonArea={
                <ModalButtonArea
                  bigButton={true}
                  variant="warning"
                  buttonTitle="Upload Texture"
                  buttonIcon={UploadIcon}
                  onClick={() => navigate(REQUESTS_ROUTE)}
                  title="Import textures"
                  subtitle="Add files [format & size file]"
                  body={
                    <div className="p-3">
                      <DropzoneUpload onUpload={(files) => uploadFiles(files)} />
                    </div>
                  }
                  onClose={onClose}
                  setShowModal={setModalOpen}
                  showModal={modalOpen}
                />
              }
              anySelected={anySelected}
              selectedList={selectedList}
              loading={!filterCtx?.refListsReady || loading}
            />
          }
        />
      }
      hasData={hasData}
      list={list}
      filterArea={
        <>
          <ActionFilters
            hasData={hasData}
            filterProps={{
              value: filters?.project_type?.selection,
              data: filterOptions,
              onChange: (e) => handleFilter('project_type', e),
            }}
            sortProps={{
              data: sortOptions,
              onChange: (e) => handleSort(e),
            }}
            selectedProps={{
              allSelected: allSelected,
              onSelectAll: (checked) => handleCardSelect(null, checked),
            }}
            showTeamMemberFilter={showTeamMemberFilter}
            loading={loading}
          />
          <GlobalFilter />
        </>
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={!filterCtx?.refListsReady || loading}
        hasChildren={hasData}
        scrollableTarget="layoutContentContainer">
        <ThumbnailContainer
          data={list}
          cardType={cardType}
          selectedList={selectedList}
          onEdit={onEdit}
          onSelect={handleCardSelect}
          clickUrl={ADMIN_MY_PRODUCTS_3DMODAL_EACH} // :id gets dynamically replaced when the list is iterated over
          showFavoriteIcon={showFavoriteIcon}
          cardButtonList={[
            {
              element: MiniButton,
              title: 'Edit',
              iconSrc: EDIT_ICON,
              clickFunction: onEdit,
              clickUrl: null,
              onSuccess: refreshList,
            },
            { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
            {
              element: AddToCollections,
              miniButton: true,
              iconSrc: COLLECTION_ICON,
              item: cardType,
              onSuccess: refreshList,
            },
          ]}
        />
      </InfiniteScroll>
      <RightSidebar
        initialShowOpen={!_.isNil(editItem)}
        onClose={() => setEditItem(null)}
        title={
          <header className="header">
            {editItem?.src && <Image src={editItem.src} alt="Avatar" className="avatar" rounded />}

            <div>
              <div className="title">Edit {editItem?.name}</div>
              <div className="headerInfo">
                {`${userName ? `${userName} · ` : ''}`}
                {editItem?.updated_at && <ReactTimeAgo date={Date.parse(editItem?.updated_at)} />}
              </div>
            </div>
          </header>
        }
        body={
          <>
            <Form className="d-block">
              <Form.Group className="search-form-group">
                <Input
                  label="Name"
                  name="name"
                  onChange={(e) => onChange(e.name, e.value)}
                  value={editItem?.name || ''}
                  placeholder="Project Name"
                />
              </Form.Group>
              <hr className="solid" />

              <hr className="solid" />

              <Row>
                <Col>
                  <div className="float-end">
                    <Button
                      variant="secondary"
                      className="m-2"
                      onClick={() => {
                        setEditItem(null);
                        setSaving(false);
                      }}>
                      Cancel
                    </Button>
                    <Button
                      onClick={saveProject}
                      variant="primary"
                      className="my-2"
                      disabled={saving}>
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        }
      />
    </CommonGrid>
  );
}
