import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unitycontext } from '../../../../../hooks/useUnityContext';
import {
  curatorSelector,
  setCuratorSettingData,
  SETTINGS_SECTION,
} from '../../../../../redux/slicers/admin/curatorSlicer';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import FormRange from '../../FormElements/FormRange';

const CameraSetting = ({ onSectionToggle }) => {
  const dispatch = useDispatch();
  const { handleVerticalRotationSpeed, handleHorizontalRotationSpeed } = useContext(unitycontext);

  const { settingData } = useSelector(curatorSelector);

  const handleChangeData = (key, value) => {
    dispatch(
      setCuratorSettingData({
        ...settingData,
        json_data: {
          ...settingData?.json_data,
          cameraSetting: { ...settingData?.json_data?.cameraSetting, [key]: value },
        },
      })
    );
  };

  const onVerticalCameraChange = () => {
    handleVerticalRotationSpeed(settingData?.json_data?.cameraSetting?.verticalRotationSpeed);
  };

  const onHorizontalCameraChange = () => {
    handleHorizontalRotationSpeed(settingData?.json_data?.cameraSetting?.horizontalRotationSpeed);
  };

  React.useEffect(onVerticalCameraChange, [
    settingData?.json_data?.cameraSetting?.verticalRotationSpeed,
  ]);
  React.useEffect(onHorizontalCameraChange, [
    settingData?.json_data?.cameraSetting?.horizontalRotationSpeed,
  ]);

  return (
    <CuratorAccordion.Item eventKey={SETTINGS_SECTION.CAMERA_SETTINGS}>
      <CuratorAccordion.Header onClick={() => onSectionToggle(SETTINGS_SECTION.CAMERA_SETTINGS)}>
        Camera settings
      </CuratorAccordion.Header>
      <CuratorAccordion.Body>
        <FormRange
          containerProps={{
            className: 'pb-1'
          }}
          labelProps={{
            className: 'mb-0',
          }}
          label="Vertical Rotation Speed"
          min="0"
          value={settingData?.json_data?.cameraSetting?.verticalRotationSpeed}
          onChange={(e) => handleChangeData('verticalRotationSpeed', e.target.value)}
          max="100"
        />
        <CuratorAccordion.Divider />
        <FormRange
          containerProps={{
            className: 'pb-1'
          }}
          labelProps={{
            className: 'mb-0',
          }}
          label="Horizontal Rotation Speed"
          min="0"
          value={settingData?.json_data?.cameraSetting?.horizontalRotationSpeed}
          onChange={(e) => handleChangeData('horizontalRotationSpeed', e.target.value)}
          max="100"
        />
      </CuratorAccordion.Body>
    </CuratorAccordion.Item>
  );
};

export default React.memo(CameraSetting);
