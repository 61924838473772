import { useEffect, useRef } from "react";
import Portal from "react-overlays/esm/Portal";
import { useDispatch, useSelector } from "react-redux";
import { PreviewUIDisplay } from "../../../../../pages/admin/myprojects/PreviewUI/PreviewUIDisplay";
import { curatorLightSelector, setPreviewHDRI } from "../../../../../redux/slicers/admin/curatorLightSlicer";
import styles from "./HDRIPreview.module.scss";

export const HDRIPreview = () => {
  const dispatch = useDispatch();
  const bodyRef = useRef(null);
  useEffect(() => {
    bodyRef.current = document.querySelector("body");
  }, []);

  const previewHDRI = useSelector(
    (state) => curatorLightSelector(state).previewHDRI
  );

  const popupSelectedHDRI = useSelector(
    (state) => curatorLightSelector(state).popupSelectedHDRI
  );

  const handleClose = () => {
    dispatch(setPreviewHDRI(null))
  }

  if (!previewHDRI) {
    return null;
  }

  return (
    <Portal container={bodyRef}>
      <PreviewUIDisplay
        title={popupSelectedHDRI.name}
        className={styles.preview}
        slides={[
          popupSelectedHDRI.file,
        ]}
        onClose={handleClose}
      />
    </Portal>
  );
};
