import React, { useState } from 'react';
import _ from 'lodash';
import styles from '../assets/scss/modules/FilterItem.module.scss';
import { Checkbox } from './';

const listItem = (item, index, props) => {
  return (
    <div className="form-check custom-checkbox" key={props.type + index}>
      <Checkbox
        name={item.name}
        onChange={() => props.onChange(item)}
        label={item.name}
        selected={item.selected}
        preview={props.showPreview ? item.preview : null}
      />
    </div>
  );
};

function buildDropdownItems(list, props) {
  return list.map((item, i) => listItem(item, i, props));
}

export default function FilterItem(props) {
  const [showRemaining, setShowRemaining] = useState(false);
  if (_.isBoolean(props.is_favourite)) {
    return (
      <div className={styles.root}>
        {listItem(
          {
            id: -1000,
            name: 'My Favorites',
            value: -1000,
            selected: props.is_favourite,
          },
          -1000,
          props
        )}
      </div>
    );
  }
  if (!props.list || props.list.length < 1) return null;
  const [showList, remainingList] = _.partition(props.list, (i) => {
    // showing only the first 3 items in any list
    return _.findIndex(props.list, i) < (props.maxVisible || 3);
  });
  return (
    <div className={styles.root}>
      {props.title && (
        <div>
          <h5>{props.title}</h5>
        </div>
      )}
      {buildDropdownItems(showList, props)}
      {showRemaining ? buildDropdownItems(remainingList, props) : null}
      {remainingList && remainingList.length > 0 ? (
        <div className={styles.more} onClick={() => setShowRemaining(!showRemaining)}>
          <span>
            {remainingList.length} {showRemaining ? 'less' : 'more'}
          </span>
        </div>
      ) : null}
    </div>
  );
}
