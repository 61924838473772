import _ from 'lodash';

export default function TopContainer({
  children,
  title,
  noDataTitle,
  item,
  hasData = true,
  subtitle,
  ...props
}) {
  if (item.includes('-')) {
    item = _.chain(item).split('-').join(' & ').value();
  }

  return (
    <>
      <div className="py-3 header-name-of-my-project row">
        <div className="col card-grid px-0 container-fluid button-header-upload-project justify-content-between">
          <div>
            <h2 className="fw-bolder ms-3">{props.loading ? 'Loading your results... ' : <>{hasData ? title : `You don't have any ${item.replace(' & ', ' or ')}`}</>}</h2>
          </div>
          {props.buttonArea}
        </div>
        {subtitle ? (
          <div className="font16">{subtitle}</div>
        ) : null}
      </div>
    </>
  );
}
