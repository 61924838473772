
import { React, useState, useEffect, useRef } from 'react';
import { Form, Card, Row } from 'react-bootstrap';
import {
  apiGetPlatforms,
  apiGetProfessions,
  apiGetIndustries,
  apiGetIndustriesPreferences,
  apiGetUserDetails,
} from '../helpers/api';
import { SelectFormGroup } from '../common';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Country, State, City } from 'country-state-city';
import _ from 'lodash';
import { addValueLabelToList } from '../helpers/jsHelper';

const emptyUserDetails = {
  industry: [],
  country: null,
  usecase: [],
  profession: {},
  city: null,
  mobile_number: '',
  state: null,
};

export default function UserInfo({
  //setIndustriesPreference,
  countryMessage,
  setCountryMessage,
  cityMessage,
  setCityMessage,
  contactNumberMessage,
  setContactNumberMessage,
  professionMessage,
  setProfessionMessage,
  //industryMessage,
  //setIndustryMessage,
  platformMessage,
  setPlatformMessage,
  onUserPreferenceDataChange,
  inSettings,
  userPreferenceData = {},
}) {
  const [professionOptions, setProfessionOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const countries = Country.getAllCountries()?.map((country) => ({
    label: country.name,
    value: country.name,
    ...country,
  }));
  const [updatedStates, setUpdatedStates] = useState([]);
  const [dataReady, setDataReady] = useState(inSettings ? false : true);
  const [updatedCities, setUpdatedCities] = useState([]);
  const [userDetails, setUserDetails] = useState(
    Object.assign(emptyUserDetails, userPreferenceData)
  );
  const isMounted = useRef(false);

  useEffect(() => {
    apiGetProfessions().then((res) => {
      if (res) {
        setProfessionOptions(addValueLabelToList(res.results));
      }
    });

    apiGetIndustries().then((res) => {
      if (res) {
        const { results } = res;
        let industries = [];
        results.forEach((item) => {
          industries.push({ label: item.name, value: item.id });
        });

        setIndustryOptions(industries);
      }
    });

    apiGetPlatforms().then((res) => {
      if (res) {
        const { results } = res;
        let platforms = [];
        results.forEach((item) => {
          platforms.push({ label: item.name, value: item.id });
        });
        setPlatformOptions(platforms);
      }
    });
  }, []);

  useEffect(() => {
    if (!inSettings && dataReady) {
      if (_.isFunction(onUserPreferenceDataChange)) {
        onUserPreferenceDataChange(userDetails);
      }
    }
  }, [userDetails, dataReady]);

  useEffect(() => {
    if (inSettings) {
      if (isMounted.current) {
        if (userDetails) {
          onUserPreferenceDataChange(userDetails);
        }
      } else {
        isMounted.current = true;
      }
    }
  }, [userDetails]);

  // useEffect(() => {
  //   updateCityList(); ///////////////////////////////// MERGE CHECK
  // }, [userDetails.country]);
  useEffect(() => {
    if (inSettings && !dataReady && platformOptions?.length > 0 && professionOptions?.length > 0) {
      apiGetUserDetails().then((response) => {
        let country = _.find(
          countries,
          (opt) => _.capitalize(opt.label) === _.capitalize(response.country)
        );
        let state = _.find(
          updateStates(country?.isoCode),
          (opt) => _.capitalize(opt.label) === _.capitalize(response.state)
        );
        let city = _.find(
          updateCities(country?.isoCode, state?.isoCode),
          (opt) => _.capitalize(opt.label) === _.capitalize(response.city)
        );
        let profession = _.find(professionOptions, { value: response.profession[0] });
        let usecase = _.chain(response.usecase)
          .map((x) => _.find(platformOptions, { value: x }))
          .filter((x) => x)
          .value();
        let mobileNumber = response.mobile_number;
        response.country = country;
        response.state = state;
        response.city = city;
        response.profession = profession;
        response.usecase = usecase;
        response.mobile_number = mobileNumber;
        setUserDetails(response);
        setDataReady(true);
      });
    }
  }, [professionOptions, platformOptions, dataReady]);

  // useEffect(() => {
  //   updateStateList();
  // }, [userDetails.country])

  const changeUserDetails = (key, value) => {
    let userDetailsTemp = Object.assign({}, userDetails);
    userDetailsTemp[key] = value;
    setUserDetails(userDetailsTemp);
  };

  const handleLocationChange = (obj) => {
    let userDetailsTemp = Object.assign({}, userDetails);
    userDetailsTemp = {
      ...userDetailsTemp,
      ...obj,
    };
    setUserDetails(userDetailsTemp);
  };

  // const handleIndustryChange = (e) => {
  //   changeUserDetails('industry', e);
  //   const industryIds = Array.isArray(e) ? e.map((x) => x.value) : [];

  //   apiGetIndustriesPreferences(industryIds).then((res) => {
  //     if (res) {
  //       let { results } = res;
  //       setIndustriesPreference(results);

  //     }
  //   })
  // };

  const handlePlatformChange = (e) => {
    changeUserDetails('usecase', e);
  };

  const handleProfessionChange = (obj) => {
    changeUserDetails('profession', obj);
  };

  const handleCountryChange = (selection) => {
    if (selection) {
      //selection.value = '';
      //changeUserDetails('country', selection);
    }

    if (selection) {
      selection.value = '';
      handleLocationChange({
        country: selection,
        state: null,
        city: null,
      });
    }
  };

  const handleStateChange = (selection) => {
    selection.value = '';
    if (selection) {
      handleLocationChange({
        state: selection,
        city: null,
      });
    }
  };

  const handleCityChange = (selection) => {
    selection.value = '';
    changeUserDetails('city', selection);
  };

  const handleContactInput = (contact) => {
    const re = /^[0-9\b]+$/;
    if (contact && re.test(contact)) {
      changeUserDetails('mobile_number', contact);
    }
  };

  // states of a country
  const updateStates = (countryId) => {
    return State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.name,
      ...state,
    }));
  };

  const updateCities = (countryId, stateId) => {
    if (stateId) {
      return City.getCitiesOfState(countryId, stateId).map((city) => ({
        label: city.name,
        value: city.name,
        ...city,
      }));
    } else {
      return City.getCitiesOfCountry(countryId).map((city) => ({
        label: city.name,
        value: city.name,
        ...city,
      }));
    }
  };

  let commonArea = (
    <div className="user-info-form container">
      <div className="row mx-auto pt-3">
        <SelectFormGroup
          error={countryMessage}
          label="Country"
          id="country"
          name="country"
          selectLabel="country"
          options={countries}
          value={userDetails.country}
          onChangeCallback={handleCountryChange}
          onBlurCallback={() => setCountryMessage('')}
          onFocusCallback={() => setCountryMessage('')}
          placeholder="Choose your country"
        />
        <SelectFormGroup
          // error={cityMessage}
          label="State/Province"
          id="state"
          name="state"
          selectLabel="state"
          //options={updatedStates}
          options={updateStates(userDetails?.country ? userDetails.country.isoCode : null)}
          value={userDetails.state}
          onChangeCallback={handleStateChange}
          onBlurCallback={() => setCityMessage('')}
          onFocusCallback={() => setCityMessage('')}
          placeholder="Choose your state"
        />
      </div>
      <div className="row mx-auto">
        <SelectFormGroup
          error={cityMessage}
          label="City"
          id="city"
          name="city"
          selectLabel="city"
          options={updateCities(
            userDetails?.country ? userDetails.country.isoCode : null,
            userDetails?.state ? userDetails.state.isoCode : null
          )}
          value={userDetails.city}
          onChangeCallback={handleCityChange}
          onBlurCallback={() => setCityMessage('')}
          onFocusCallback={() => setCityMessage('')}
          placeholder="Choose your city"
        />
        <Form.Group className="form-outline col-md-6 py-3">
          <Form.Label className={'title-color float-left'}>Contact number (optional)</Form.Label>
          <PhoneInput
            name="contact"
            placeholder="Type your contact number"
            country={userDetails?.country?.isoCode?.toLowerCase()}
            value={userDetails?.mobile_number}
            onChange={(contact) => handleContactInput(contact)}
            onBlur={() => setContactNumberMessage('')}
            inputClass={'phoneInput'}
            style={{width: "100%"}}
          />
        </Form.Group>
      </div>
      <div className="row mx-auto">
        <SelectFormGroup
          error={professionMessage}
          label="What's your profession?"
          id="profession"
          name="profession"
          selectLabel="profession"
          options={professionOptions}
          value={userDetails?.profession}
          onChangeCallback={handleProfessionChange}
          onBlurCallback={() => setProfessionMessage('')}
          onFocusCallback={() => setProfessionMessage('')}
          placeholder="Choose your profession"
        />
        {/* <SelectFormGroup
          error={industryMessage}
          label="What's your industry?"
          id="industry"
          name="industry"
          selectLabel="industry"
          options={industryOptions}
          value={userDetails?.industry}
          onChangeCallback={handleIndustryChange}
          onBlurCallback={() => setIndustryMessage('')}
          onFocusCallback={() => setIndustryMessage('')}
          placeholder="Choose your industry name"
          isMulti
        /> */}
        <SelectFormGroup
          error={platformMessage}
          label="What are you using the platform for?"
          id="platform"
          name="platform"
          selectLabel="platform"
          options={platformOptions}
          value={userDetails?.usecase}
          onChangeCallback={handlePlatformChange}
          onBlurCallback={() => setPlatformMessage('')}
          onFocusCallback={() => setPlatformMessage('')}
          placeholder="Choose your use case"
          isMulti
        />
      </div>
    </div>
  );
  return !inSettings ? (
    <Card className="border-0 container-fluid p-3" style={{ height: '100%' }}>
      <h2 className="text-center mt-1">Customize your experience</h2>
      {commonArea}
    </Card>
  ) : (
    <>
      <Row>{commonArea}</Row>
    </>
  );
}
