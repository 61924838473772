export const HeartLineIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0008 18.3269C11.7932 18.3269 11.5922 18.3001 11.4248 18.2398C8.86627 17.3624 4.80078 14.248 4.80078 9.64672C4.80078 7.30253 6.69622 5.40039 9.02701 5.40039C10.1589 5.40039 11.2172 5.84244 12.0008 6.63276C12.7844 5.84244 13.8426 5.40039 14.9745 5.40039C17.3053 5.40039 19.2008 7.30923 19.2008 9.64672C19.2008 14.2547 15.1353 17.3624 12.5768 18.2398C12.4093 18.3001 12.2084 18.3269 12.0008 18.3269ZM9.02701 6.40504C7.25213 6.40504 5.80543 7.85844 5.80543 9.64672C5.80543 14.2212 10.2058 16.7663 11.753 17.2955C11.8735 17.3356 12.1347 17.3356 12.2553 17.2955C13.7958 16.7663 18.2028 14.2279 18.2028 9.64672C18.2028 7.85844 16.7561 6.40504 14.9812 6.40504C13.9632 6.40504 13.0188 6.88058 12.4093 7.70439C12.2218 7.9589 11.7932 7.9589 11.6056 7.70439C10.9827 6.87388 10.0451 6.40504 9.02701 6.40504Z"
        fill="currentColor"
      />
    </svg>
  );
};
