import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMountedState, usePrevious } from 'react-use';
import { editCuratorSetting, getCuratorSettings } from '../../../../../api/curator';
import { errorToast } from '../../../../../helpers/toastHelper';
import {
  curatorSelector,
  selectOldSettingData,
  selectSettingData,
  selectSettingsSection,
  toggleSettingsSection,
} from '../../../../../redux/slicers/admin/curatorSlicer';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import CameraSetting from './CameraSetting';
import GraphicQuality from './GraphicQuality';
import MeasureUnit from './MeasureUnit';
import MetadataSetting from './MetaDataSetting';
import ResolutionPreset from './ResolutionPreset';

const Setting = memo(({ active }) => {
  const dispatch = useDispatch();
  const settingData = useSelector(selectSettingData);
  const oldSettingData = useSelector(selectOldSettingData);
  const prevActive = usePrevious(active);
  const activeSection = useSelector(selectSettingsSection);
  const prevActiveSection = usePrevious(activeSection);

  useEffect(() => {
    dispatch(getCuratorSettings());
  }, [dispatch]);

  const onSectionToggle = (sectionName) => {
    dispatch(toggleSettingsSection(sectionName));
  }

  const updateCuratorSettings = () => {
    if (!_.isEqual(oldSettingData, settingData)) {
      let invalidaData = false;
      settingData?.json_data?.resolutionPresent?.forEach((item) => {
        if (!item?.name || !item?.width || !item?.height) {
          errorToast('Resolution Preset Fields are required');
          invalidaData = true;
        }
      });
      if (invalidaData) return;
      const formData = new FormData();
      formData.append('json_data', JSON.stringify(settingData?.json_data));
      dispatch(editCuratorSetting(formData, settingData?.id));
    }
  };

  useEffect(() => {
    // save settings on tab close
    if (prevActive && !active) {
      updateCuratorSettings();
    }
  }, [active]);

  useEffect(() => {
    // save settings on section switch
    if (prevActiveSection !== activeSection && prevActiveSection) {
      updateCuratorSettings();
    }
  }, [activeSection]);

  useEffect(() => {
    // save on unmount
    return () => {
      updateCuratorSettings();
    }
  }, [])


  return (
    <section className={`setting-menu settings`} onMouseLeave={updateCuratorSettings}>
      <div className={`setting-submenu `}>
        <CuratorAccordion activeKey={activeSection}>
          <ResolutionPreset onSectionToggle={onSectionToggle} />
          <CameraSetting onSectionToggle={onSectionToggle} />
          <GraphicQuality onSectionToggle={onSectionToggle} />
          <MeasureUnit onSectionToggle={onSectionToggle} />
          <MetadataSetting onSectionToggle={onSectionToggle} />
        </CuratorAccordion>
      </div>
    </section>
  );
});

export default Setting;
