import { useState, useCallback } from 'react';
import { icons } from '../assets';
import { BigButton } from './';
import _ from 'lodash';
import api from '../helpers/axiosHelper';
import {warningToast} from '../helpers/toastHelper';
import { useDropzone } from 'react-dropzone';

const { BinIcon } = icons;


const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  width: 50,
  height: 50,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


export default function DropzoneUpload(props) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      let duplicatesExist = _.chain([...files, ...acceptedFiles]).groupBy(f => f.name).map((list, key) => list).filter(l => l.length > 1).value()?.length;
      if (duplicatesExist >= 1) {
        warningToast("A file with this same name is already in the list to import.")
      } else {
        let uploadList = _.chain([...files, ...acceptedFiles])
          .uniqBy(f =>  f.name)
          .map(f => {
            // add this back if you want to have a mini preview of the file upload
            // f = Object.assign(f, {
            //   preview: URL.createObjectURL(f)
            // })
            return f
          })
          .value();
        setFiles(uploadList);
      }
    },
    [files]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });


  function handleFileDelete(file) {
    const filtered = files.filter((f) => f.path !== file.path);
    setFiles(filtered);
  }

  return (
    <div>
      <div
        {...getRootProps({
          className: 'dropzone render-dropzone p-5 text-center',
        })}>
        <input {...getInputProps()} />
        <p className="text-primary">Select [file type] file</p>
        <p className="text-muted">
          <small>or drop [file type] here</small>
        </p>
      </div>
      {files && files.length > 0 && (
        <>
          <div>
            <ul className="render-upload-list">
              {files.map((file) => (
                <li key={file.path} className="d-flex justify-content-between align-items-center">
                  {file.preview && (
                    <div style={thumb} key={file.name}>
                    <div style={thumbInner}>
                      <img
                        src={file.preview}
                        style={img}
                        // Revoke data uri after image is loaded
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                      />
                    </div>
                  </div>
                  )}
                  {file.name}
                  <div role="button" onClick={() => handleFileDelete(file)}>
                    <BinIcon />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="d-grid py-4">
            <BigButton
              className="fullWidth"
              variant="warning"
              size={props.size || 'lg'}
              onClick={() => props.onUpload(files)}
              disabled={props.disabled}
              block={true}
              style={{ minHeight: '48px' }}>
              <div className="font16">Import</div>
            </BigButton>
          </div>
        </>
      )}
    </div>
  );
}
