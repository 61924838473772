import cx from 'classnames';
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContextMenu } from '../../../../hooks/useContextMenu';
import { CURATOR_MENU_SECTION } from '../../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import { IoCopy, IoCopyOutline, IoDuplicateOutline } from 'react-icons/io5';
import { FiRefreshCw } from 'react-icons/fi';
import {
  selectCuratorInitialized,
  setSidebarSection,
  toggleSidebarSection,
} from '../../../../redux/slicers/admin/curatorSlicer';
import {
  selectCanReplaceProp,
  selectHasSelectedObjectInfo,
  selectIsAnyObjectSelected,
  selectIsOneObjectSelected,
  selectIsStyleableObject,
} from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { MoveIcon } from '../../../icons/MoveIcon';
import ProductIcon from '../../../icons/productIcon';
import { RotateIcon } from '../../../icons/RotateIcon';
import { ScaleIcon } from '../../../icons/ScaleIcon';
import { useUnityShortcuts } from '../CuratorHeader/useUnityShortcuts';
import styles from './CuratorContextMenu.module.scss';
import BinIcon from '../../../icons/BinIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { RiArrowDropRightFill, RiArrowDropRightLine } from 'react-icons/ri';
import { useUnityContext } from '../../../container/unityContainer';

export const CuratorContextMenu = memo(() => {
  const dispatch = useDispatch();
  const { roomModule } = useUnityContext();
  const curatorInitialized = useSelector(selectCuratorInitialized);
  const { anchorPoint, show } = useContextMenu({ selector: '#unityUIContainer' });
  const anyObjectSelected = useSelector(selectIsAnyObjectSelected);
  const isOnlyOneObjectSelected = useSelector(selectIsOneObjectSelected);
  const hasSelectedObjectInfo = useSelector(selectHasSelectedObjectInfo);
  const isStyleableObject = useSelector(selectIsStyleableObject);
  const canReplaceProp = useSelector(selectCanReplaceProp);
  const {
    switchToMove,
    switchToRotate,
    switchToScale,
    duplicateObject,
    deleteObject,
    copyMaterial,
    pasteMaterial,
    replaceProp,
  } = useUnityShortcuts();

  if (!show || !curatorInitialized) return null;

  let menu = [];

  const productMenuItem = {
    icon: <ProductIcon className={styles.productIcon} />,
    label: 'Add prop',
    onClick: () => dispatch(setSidebarSection(CURATOR_MENU_SECTION.PRODUCTS)),
  };

  if (anyObjectSelected || hasSelectedObjectInfo) {
    menu = [
      {
        icon: <IoDuplicateOutline />,
        label: 'Duplicate',
        onClick: () => duplicateObject(),
        disabled: !anyObjectSelected,
      },
      productMenuItem,
      {
        icon: <FiRefreshCw />,
        label: 'Replace Prop',
        onClick: () => replaceProp(),
        disabled: !canReplaceProp,
      },
      {
        icon: <IoCopy />,
        label: 'Copy Material',
        onClick: () => copyMaterial(),
        disabled: !isStyleableObject,
      },

      {
        icon: <IoCopyOutline />,
        label: 'Paste Material',
        disabled: !isStyleableObject,
        onClick: () => pasteMaterial(),
      },
      {
        icon: <RefreshIcon />,
        label: 'Reset Selected',
        disabled: !anyObjectSelected,
        // onClick: () => alert('TODO: this button does not work :('),
        items: [
          {
            label: 'Reset Position',
            onClick: () => roomModule.resetPosition(),
          },
          {
            label: 'Reset Scale',
            onClick: () => roomModule.resetScale(),
          },
          {
            label: 'Reset Rotation',
            onClick: () => roomModule.resetRotation(),
          },
          {
            label: 'Reset to default',
            onClick: () => roomModule.resetAll(),
          },
        ],
      },
      {
        icon: <BinIcon />,
        label: 'Delete',
        onClick: () => deleteObject(),
        disabled: !anyObjectSelected,
      },
    ].filter(Boolean);
  } else {
    menu = [
      productMenuItem,
      {
        icon: <MoveIcon />,
        label: 'Move',
        onClick: () => switchToMove(),
      },
      {
        icon: <RotateIcon />,
        label: 'Rotate',
        onClick: () => switchToRotate(),
      },
      {
        icon: <ScaleIcon />,
        label: 'Scale',
        onClick: () => switchToScale(),
      },
    ];
  }

  return <MenuUI items={menu} left={anchorPoint.x} top={anchorPoint.y} />;
});

export const MenuUI = ({ left, top, items }) => {
  const [{ x, y }, setPosition] = useState({ x: left, y: top });
  const [submenuPositionX, setSubmenuPositionX] = useState('right');
  const [submenuPositionY, setSubmenuPositionY] = useState('top');
  const elementRef = useRef(null);

  useLayoutEffect(() => {
    if (!elementRef.current) return;

    let { left, top } = elementRef.current.getBoundingClientRect();
    const width = elementRef.current.clientWidth;
    const height = elementRef.current.clientHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const calculatedLeft = left + width > windowWidth ? windowWidth - width : left;
    const calculatedTop = top + height > windowHeight ? windowHeight - height : top;

    setPosition({ x: calculatedLeft, y: calculatedTop });

    // calclulate submenu position
    setSubmenuPositionX(
      calculatedLeft + width + 200 > windowWidth ? 'left' : 'right'
    );

    setSubmenuPositionY(
      calculatedTop + height + 120 > windowHeight ? 'bottom' : 'top'
    );

  }, [left, top]);

  return (
    <div
      ref={elementRef}
      className={cx(
        styles.root,
        submenuPositionX === 'left' && styles.submenuLeft,
        submenuPositionY === 'bottom' && styles.submenuBottom,
      )}
      style={{
        left: x,
        top: y,
      }}>
      {items.map(({ icon, onClick, label, items, disabled }) => {
        return (
          <div className={cx(styles.menuItem, disabled && styles.disabled)} onClick={onClick}>
            <span className={styles.icon}>{icon}</span>
            {label}

            {items && <RiArrowDropRightFill fontSize={24} />}

            {items && (
              <div className={styles.submenu}>
                {items.map((submenuItem) => {
                  return (
                    <div className={styles.submenuItem} onClick={submenuItem.onClick}>
                      {submenuItem.label}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
