export const FolderAddIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64804 19.1998H15.3457C18.2994 19.1998 19.1969 18.3023 19.1969 15.3486V11.33C19.1969 8.37636 18.2994 7.47887 15.3457 7.47887H13.3364C12.9212 7.47887 12.8877 7.43869 12.6666 7.14399L11.662 5.80446C11.1932 5.18157 10.8248 4.7998 9.65269 4.7998H8.64804C5.69436 4.7998 4.79688 5.69729 4.79688 8.65097V15.3486C4.79688 18.3023 5.69436 19.1998 8.64804 19.1998ZM5.80153 8.65097C5.80153 6.2599 6.25697 5.80446 8.64804 5.80446H9.65269C10.404 5.80446 10.5085 5.94308 10.8507 6.39726L10.8583 6.40725L11.8629 7.74678C12.1978 8.18883 12.4791 8.48353 13.3364 8.48353H15.3457C17.7368 8.48353 18.1922 8.93897 18.1922 11.33V15.3486C18.1922 17.7397 17.7368 18.1952 15.3457 18.1952H8.64804C6.25697 18.1952 5.80153 17.7397 5.80153 15.3486V8.65097ZM12.0374 15.5161C11.7628 15.5161 11.5351 15.2884 11.5351 15.0138V13.8417H10.3225C10.0479 13.8417 9.82013 13.6139 9.82013 13.3393C9.82013 13.0647 10.0479 12.837 10.3225 12.837H11.5351V11.6649C11.5351 11.3903 11.7628 11.1626 12.0374 11.1626C12.312 11.1626 12.5398 11.3903 12.5398 11.6649V12.837H13.6713C13.9459 12.837 14.1736 13.0647 14.1736 13.3393C14.1736 13.6139 13.9459 13.8417 13.6713 13.8417H12.5398V15.0138C12.5398 15.2884 12.312 15.5161 12.0374 15.5161Z"
        fill="currentColor"
      />
    </svg>
  );
};
