import {createSlice} from "@reduxjs/toolkit";
import {THEME_LIGHT} from '../../config/constant/projectConstant';

const activity = createSlice({
    name: 'activity',
    initialState: {
        theme: THEME_LIGHT,
        navPath: '',
        name:''
    },
    reducers: {
        setTheme: (state, {payload}) => {
            state.theme = payload
        },
        setNavPath: (state, {payload}) => {
            state.navPath = payload
        },
        setName:(state, {payload}) => {
            state.name = payload
        },
    }
})

export const {setTheme, setNavPath, setName} = activity.actions;
export const activitySelector = state => state.activity;
export const activityReducer = activity.reducer;
export default activityReducer