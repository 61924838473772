import { useEffect, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { RiImage2Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { CLOSE_ICON } from '../../../../../config/constant/assetConstant';
import { curatorLightSelector } from '../../../../../redux/slicers/admin/curatorLightSlicer';
import Button from '../../../../Button/Button';
import { DropZonePlaceholder } from '../../../../DropZonePlaceholder/DropZonePlaceholder';
import Loader from '../../../../Loader/Loader';
import styles from './UploadHDRIDialog.module.scss';
import { useHDRIUpload } from './useHDRIUpload';

export const UploadHDRIDialog = () => {
  const dialogOpen = useSelector((state) => curatorLightSelector(state).uploadDialogOpen);

  // we want to unmount UploadHDRIDialogUI because dropzone library doesn't provide a method to reset selected files :(
  if (!dialogOpen) return null;
  
  return <UploadHDRIDialogUI open={dialogOpen} />
}

export const UploadHDRIDialogUI = ({ open }) => {
  const { image, hdri, dialogOpen, closeDialog, loading, onSubmit } = useHDRIUpload();

  return (
    <Modal
      show={open}
      centered
      dialogClassName={styles.modal}
      contentClassName={styles.content}>
      <Modal.Body className={styles.body}>
        <Loader position="absolute" loading={loading} backdrop className={styles.loader} />
        <h2 className={styles.title}>Import HDRI</h2>

        <button className={styles.close} onClick={closeDialog}>
          <Image src={CLOSE_ICON} />
        </button>

        <div className="d-flex justify-content-between gap-3 mb-3">
          <DropZonePlaceholder
            title="Browse HDRI"
            fileFormats=".hdr"
            className={`w-50 ${styles.placeholder}`}
            {...hdri.getRootProps()}
            inputProps={hdri.getInputProps()}>
            {hdri.acceptedFiles.length > 0 && (
              <div className={styles.selectedFile}>
                <RiImage2Line fontSize={20} />
                {hdri.acceptedFiles[0].name}
              </div>
            )}
          </DropZonePlaceholder>

          <DropZonePlaceholder
            title="Add it’s output refernce"
            fileFormats=".jpg / .png"
            className={`w-50 ${styles.placeholder}`}
            {...image.getRootProps()}
            inputProps={image.getInputProps()}
            acceptedFiles={image.acceptedFiles}>
            {image.acceptedFiles.length > 0 && (
              <div className={styles.selectedFile}>
                <RiImage2Line fontSize={20} />
                {image.acceptedFiles[0].name}
              </div>
            )}
          </DropZonePlaceholder>
        </div>

        <div className="d-flex justify-content-center">
          <Button color="warning" onClick={onSubmit} className="w-50">
            Upload
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
