import cx from 'classnames';
import Loader from '../../../../Loader/Loader';
import styles from './NoHDRI.module.scss';
import { UploadHDRIButton } from './UploadHDRIButton';

export const NoHDRI = () => {
  return (
    <>
      <Loader loading={false} backdrop position="relative" className={cx(styles.container)}>
        <div className={styles.text}>
          <span>There is no uploaded HDRI</span>
        </div>
        <div className="m-5 w-100 d-flex justify-content-center">
          <UploadHDRIButton className={styles.uploadButton} />
        </div>
      </Loader>
    </>
  );
};
