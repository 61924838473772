export const CrownIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88643 17.1458C8.39263 17.1458 7.89216 16.7921 7.72534 16.3317L4.96273 8.5977C4.62241 7.62344 4.86931 7.17636 5.13623 6.97617C5.40315 6.77598 5.90362 6.65586 6.74441 7.25643L9.34687 9.11819C9.42694 9.1649 9.50035 9.18492 9.55373 9.17157C9.61379 9.15155 9.6605 9.09817 9.69386 9.00475L10.8683 5.87513C11.222 4.94091 11.7425 4.80078 12.0227 4.80078C12.303 4.80078 12.8235 4.94091 13.1771 5.87513L14.3516 9.00475C14.385 9.0915 14.4317 9.15155 14.4917 9.17157C14.5518 9.19159 14.6252 9.17157 14.6986 9.11152L17.1409 7.36987C18.0351 6.72927 18.5556 6.85605 18.8425 7.06292C19.1228 7.27645 19.383 7.75023 19.016 8.78454L16.3201 16.3317C16.1533 16.7921 15.6528 17.1458 15.159 17.1458H8.88643ZM5.80352 7.857C5.81687 7.95042 5.84356 8.08388 5.91029 8.25738L8.6729 15.9913C8.69959 16.0581 8.8197 16.1448 8.88643 16.1448H15.159C15.2324 16.1448 15.3525 16.0581 15.3726 15.9913L18.0684 8.45089C18.1618 8.19732 18.1885 8.02382 18.1952 7.92373C18.0951 7.95709 17.9416 8.0305 17.7214 8.19065L15.2791 9.93229C14.9455 10.1658 14.5518 10.2392 14.1981 10.1325C13.8444 10.0257 13.5575 9.74545 13.4107 9.36509L12.2363 6.23547C12.1495 6.00191 12.0694 5.88847 12.0227 5.83509C11.976 5.88847 11.8959 6.00191 11.8092 6.2288L10.6347 9.35842C10.4946 9.73877 10.2077 10.019 9.84734 10.1258C9.49367 10.2326 9.09329 10.1592 8.76632 9.92562L6.16386 8.06386C6.01039 7.95709 5.89027 7.89036 5.80352 7.857ZM15.6926 19.161H8.35231C8.07872 19.161 7.85184 18.9341 7.85184 18.6605C7.85184 18.3869 8.07872 18.1601 8.35231 18.1601H15.6926C15.9662 18.1601 16.193 18.3869 16.193 18.6605C16.193 18.9341 15.9662 19.161 15.6926 19.161ZM10.3542 13.8226H13.6907C13.9643 13.8226 14.1912 13.5958 14.1912 13.3222C14.1912 13.0486 13.9643 12.8217 13.6907 12.8217H10.3542C10.0806 12.8217 9.85373 13.0486 9.85373 13.3222C9.85373 13.5958 10.0806 13.8226 10.3542 13.8226Z"
        fill="currentColor"
      />
    </svg>
  );
};
