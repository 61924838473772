import cx from 'classnames';
import { useSelector } from 'react-redux';
import { curatorStylesSelector } from '../../../../../redux/slicers/admin/curatorStylesSlicer';
import { undoRedo } from '../../UndoRedo/UndoRedo';
import { useProperties, useUnityRedux } from '../useUnityRedux';
import { MapTextureForm } from './MapTextureForm';

export const MaterialProperties = () => {
  const unityRedux = useUnityRedux();
  const expertMode = useSelector((state) => curatorStylesSelector(state).propertiesExpertMode);
  const { propertiesData } = useProperties();

  if (propertiesData?.material?.hideUI) return null;

  const textureMissing = propertiesData?.texture?.hideUI === true;
  const isFullyVisible = textureMissing || expertMode;

  const handleExpertModeClick = () => {
    const newExpertMode = !expertMode;
    unityRedux.setExpertMode(newExpertMode)

    undoRedo.objectProperties.toggleExpertMode({
      prevValue: expertMode,
      nextValue: newExpertMode,
    })
  }

  return (
    <div className="properties-global ">
      <div className={cx('texture-head d-flex justify-content-between ', isFullyVisible && 'mb-4')}>
        <h3 className="me-3 fw-400">Material Properties</h3>
        <button
          type="button"
          onClick={handleExpertModeClick}
          className={cx(`btn btn-light btn-texture  `, expertMode && 'active')}>
          Expert Mode
        </button>
      </div>

      {isFullyVisible && <MapTextureForm data={propertiesData?.material} onChange={() => {}} />}
    </div>
  );
};
