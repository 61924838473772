import api, { apiErrorHandler } from './axiosHelper';
import { objectToFormData, objectToSearchParams, uniqueId } from './jsHelper';
import { successToast, errorToast } from './toastHelper';
import _ from 'lodash';
import { MODULE_TYPES } from '../constants/constants';
import { INVITE_ROUTE } from '../constants/routePathConstants';

export const API_ROOT_URL = process.env.REACT_APP_API_BASE_URL;

//login
export const LOGIN_API = '/users/api/v3/login/';
export const GOOGLE_LOGIN_API = '/users/api/v3/login/social/';
export const CAROUSEL_API = '/users/api/v3/images-carrousel/';
export const REGISTER_API = '/users/api/v3/register/';
export const VERIFY_OTP_API = '/users/api/v3/verify-email-otp/';
export const RESEND_EMAIL_API = '/users/api/v3/resend-email-for-verification/';
export const FORGOT_API = '/users/api/v3/forgot-password/';
export const PROFESSION_API = '/users/api/v3/profession/';
export const INDUSTRY_API = '/users/api/v3/industry/';
export const PLATFORM_API = '/users/api/v3/usecase/';
export const TERMS_API = '/tnc/latest/';
export const TERMS_ACCEPT_API = '/tnc/accept/';
export const USER_PREFERENCE_API = '/users/api/v3/user-preferences/';
export const ACCOUNT_STATUS_API = '/users/api/v3/check-account-status/';
export const LINK_VERIFY_API = '/users/api/v3/verify-email-with-link/';
export const CREATE_PASSWORD_API = '/users/api/v3/reset-password-confirmation/';
export const UPDATE_USER_PROFILE_API = '/users/api/v3/user-details/';
export const GET_ACCESS_REFRESH_TOKEN = '/users/v3/api/login/refresh/';
export const GLOBAL_SEARCH_API = '/organization/api/v3/search-api/';
export const AFTER_REGISTER_API = '/users/api/v3/after-register/';
export const REGISTER_INVITE_API = '/organization/api/v3/register-invite/';

//curator
export const ROOM_UPDATE = '/rooms/api/v3/room-detail/:roomId/';
export const ROOM_LAYOUT_API = '/rooms/api/v3/layout-detail/';
export const PROPS_API = '/assetbundles/api/v3/products/detail/';
export const PROPS_REPLACE_API = '/assetbundles/api/v3/products/sort/';
export const UPDATE_PROPS_API = '/assetbundles/api/v3/products/detail/:productId';
export const ROOM_DETAILS_API = '/rooms/api/v3/room-detail/';
export const CREATE_ROOM = '/rooms/api/v3/room-detail/';
export const TEXTURE_DETAILS_API = '/texture/api/v3/texture/detail/';
export const GET_SETTING_API = '/users/api/v3/get-user-curator-settings/';
export const EDIT_SETTING_API = '/users/api/v3/user-curator-settings-detail/';
export const SIDE_PANEL_OPTIONS = '/organization/api/v3/side-panel-options/';
export const GET_SIDE_PANEL_OPTIONS = '/organization/api/v3/get-side-panel-options/';
export const EXPORT_PROJECT = '/organization/api/v3/save-on-zip-file-on-local/';

export const ORGANISATION_DETAILS_API = '/organization/api/v3/get-all-organization/';

//projects
export const GET_PROJECT_API = '/organization/api/v3/projects/details/';
export const GET_ROOMS_API = '/rooms/api/v3/room-detail/';
export const GET_PROPS_API = '/assetbundles/api/v3/products/detail/';
export const PROJECT_UPDATE = '/organization/api/v3/projects/details/:projectId/';
export const PROJECT_UPLOAD = '/organization/api/v3/project-upload/';

//webexperience

export const GET_WEB_EXPERIENCE_API = '/organization/api/v3/webexperience/';

//teamsPage
export const GET_MEMBERS_API = '/organization/api/v3/members/details/';
export const SEND_INVITE_API = '/organization/api/v3/send-invite/';
export const GET_PENDING_LIST = '/organization/api/v3/invitation-pendding-list/';
export const DELETE_PENDING_INVITATION = '/organization/api/v3/delete-invitation-pendding-list/';

//settings
export const GET_USER_DETAILS = '/users/api/v3/user-details/';
export const CHECK_ORG_NAME = '/organization/api/v3/check_org/';
export const CREATE_ORGANIZATION = '/organization/api/v3/details/';

export const PERMISSIONS_API = '/organization/api/v3/permissions/';

// favourite
export const ADD_FAVOURITE_PROJECTS = '/organization/api/v3/my-projects/details/';
export const ADD_FAVOURITE_ROOM = '/rooms/api/v3/room-detail/';

// delete
export const PROJECT_DELETE = '/organization/api/v3/my-projects/details/';
export const PROJECT_MULTI_DELETE = '/organization/api/v3/delete-projects/';
export const ROOM_DELETE = '/organization/api/v3/room-detail/';

// material
// texture
export const GET_MATERIAL = '/organization/api/v3/material/detail/';
export const MATERIAL_API = '/material/api/v3/material/detail/';

// texture
export const GET_TEXTURE = '/organization/api/v3/texture/detail/';
export const TEXTURE_API = '/texture/api/v3/texture/detail/';
export const TEXTURE_BULK_UPLOAD = '/texture/api/v3/texture-buld-create/';
export const TEXTURE_BULK_UPDATE = '/texture/api/v3/texture/update/';
export const TEXTURE_BULK_DELETE = '/texture/api/v3/delete-textures/';
export const TEXTURE_CHECK_VERSION = '/texture/api/v3/texture/version_check/';

// texture admin

export const ADMIN_TEXTURE_API = '/assetbundles/api/v3/texture/products/detail/';

// preset camera (curator)
export const PRESET_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/';
export const PRESET_CAMERA_IMPORT = '/organization/api/v3/import_camera_angle/';
// export const GET_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const CREATE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const UPDATE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const DELETE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'

// project camera (curator)
export const PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const GET_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const CREATE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const UPDATE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const DELETE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';

// images (curator) - render

// rendering-system/api/v3/images-render-detail/?parent_id=148
export const DOWNLOAD_RENDER = '/rendering-system/api/v3/download-renders/';
export const GET_RENDER_PREVIEW_DATA = '/rendering-system/api/v3/project-render-images/';
export const UPDATE_RENDER_PREVIEW = '/rendering-system/api/v3/project-render-images/';
// rendering-system/api/v3/images-render-detail/?parent_id=148
export const GET_CAMERA_INFO = '/rendering-system/api/v3/images-render-detail/';
export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/';
export const DELETE_RENDER = '/rendering-system/api/v3/images-render-detail/';
export const DOWNLOAD_RENDER_IMAGE = '/rendering-system/api/v3/download-renders/';
// export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/'
// export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/'
export const GET_RENDER_BY_PROJ_RENDER_ID =
  '/rendering-system/api/v3/get-render-based-on-project-render-id/';
export const UPLOAD_RENDER_IMAGES = '/rendering-system/api/v3/upload-images/';

// render & preview//
export const GET_RENDER_PREVIEW = '/rendering-system/api/v3/project-render-images/';
export const GET_DOWNLOAD_RENDER_IMAGES = '/rendering-system/api/v3/download-renders/';
export const GET_RENDER_DATA = '/rendering-system/api/v3/get-render/';
// Filters
export const GET_FILTERS = '/users/api/v3/filters/';
export const CREATE_FILTER_OPTION = '/users/api/v3/filters/';

export const GET_GLOBAL_TAGS = '/organization/api/v3/global-tags/';

// share
export const CREATE_SHARE = '/share/api/v3/sharingmodels/';

//Transaction
export const GET_USER_BALANCE = '/images/api/organization-balance/';
export const PAYMENT_INTENT = '/images/api/create-payment-intent/';
export const TRANSACTION_HISTORY = '/images/api/organization-transaction-history/';
export const CHANGE_PASSWORD = '/users/api/v3/change-password/';

//Notification Settings
export const NOTIFICATION_SETTINGS = '/notifications/settings/';

// collection
export const COLLECTION_DETAILS_API = '/organization/api/v3/collections/details/';
export const ADD_TO_COLLECTIONS = '/organization/api/v3/multiple/collections/models/';
export const COLLECTION_UPDATE = '/organization/api/v3/collections/details/:collectionId/';
export const COLLECTION_DATA_API = '/organization/api/v3/collections/models/';
export const COLLECTION_MULTI_DELETE = '/organization/api/v3/delete-collections/';

// organization
export const GET_MY_ORGANIZATIONS = '/organization/api/v3/my-orgs/';
export const SWITCH_ORG = '/users/api/v3/change-org/';

// light
export const LIGHT_API = '/organization/api/v3/environment-light-detail/';

export const getDataList = async (apiCallback) => {
  let response = await apiCallback();
  return response;
  // const { data, perPage, page, list, loading, hasData } = useSelector(selector);
  // const { filterValues } = useSelector(sidebarSelector);
  // const hasMore = data.current_page_number < data.total_pages;

  // const filterToParams = (filterValues) => {
  //   const filterParams = {};

  //   filterValues.forEach((value) => {
  //     if (value === "favourite") {
  //       filterParams.is_favourite = true;
  //       return;
  //     }

  //     if (value.startsWith("option_")) {
  //       const [, optionId, labelId] = value.split("_");
  //       const optionKey = `fl_${optionId}`;

  //       if (!filterParams[optionKey]) {
  //         filterParams[optionKey] = labelId;
  //         return;
  //       }

  //       filterParams[optionKey] += `,${labelId}`;
  //       return;
  //     }

  //     if (value.startsWith("brand_")) {
  //       const [, brandId] = value.split("_");

  //       if (!filterParams.brands) {
  //         filterParams.brands = brandId;
  //         return;
  //       }

  //       filterParams.brands += `,${brandId}`;
  //       return;
  //     }
  //   });

  //   return filterParams
  // };

  // useEffect(() => {
  //   const filterParams = filterToParams(filterValues);

  //   dispatch(resetAction());
  //   dispatch(getAction({ page, perPage, ...filterParams  }));
  // }, [filterValues]);

  // const fetchMoreData = () => {
  //   const filterParams = filterToParams(filterValues);

  //   dispatch(
  //     getAction({ perPage, page: page + 1, ...filterParams })
  //   );
  // };

  // return {
  //   data,
  //   list,
  //   loading,
  //   fetchMoreData,
  //   hasMore,
  //   total: data.count,
  //   hasData,
  // };
};

function apiPatch(URL, data, label = null, successMessage = null, errorMessage = null) {
  let formData = objectToFormData(data);
  return api()
    .root(API_ROOT_URL)
    .patch(URL)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiMultiDelete(URL, formData, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .delete(URL)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiMultiRestore(URL, formData, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .patch(URL)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiMultiPost(URL, formData, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .post(URL)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiPut(URL, formData, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .put(URL)
    .data(formData)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiGet(URL, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .get(URL)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiGetBlob(URL, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .getBlob(URL)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiPost(
  URL,
  payload,
  label = null,
  successMessage = null,
  errorMessage = null,
  isFormData = true
) {
  let formData = payload;
  if (isFormData) {
    formData = objectToFormData(payload);
  }
  return api()
    .root(API_ROOT_URL)
    .post(URL)
    .data(formData)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
      return error;
    })
    .send();
}

export function apiFilterLabels(successMessage = null, errorMessage = null) {
  return apiGet(GET_FILTERS, 'apiFilterLabels', successMessage, errorMessage);
}

export function apiTeamMembers(payload, successMessage = null, errorMessage = null) {
  const searchParams = objectToSearchParams(payload);
  const API_URL = `${GET_MEMBERS_API}?${searchParams}`;
  return apiGet(API_URL, 'apiTeamMembers', successMessage, errorMessage);
}

export function apiEditProject(
  id,
  payload,
  successMessage = 'Project updated successfully.',
  errorMessage = 'There was an issue updating this project.'
) {
  return apiPatch(
    PROJECT_UPDATE.replace(':projectId', id),
    payload,
    'apiEditProject',
    successMessage,
    errorMessage
  );
}

export function apiEditRenderPreview(
  id,
  payload,
  successMessage = 'Render/Preview updated successfully.',
  errorMessage = 'There was an issue updating this Render/Preview.'
) {
  return apiPatch(
    `${UPDATE_RENDER_PREVIEW}${id}`,
    payload,
    'apiEditRenderPreview',
    successMessage,
    errorMessage
  );
}

export function apiEditCollection(
  id,
  payload,
  successMessage = 'Collection updated successfully.',
  errorMessage = 'There was an issue updating this Collection.'
) {
  return apiPatch(
    COLLECTION_UPDATE.replace(':collectionId', id),
    payload,
    'apiEditCollection',
    successMessage,
    errorMessage
  );
}

export function apiEditRoom(
  id,
  payload,
  successMessage = 'Room updated successfully.',
  errorMessage = 'There was an issue updating this room.'
) {
  return apiPatch(
    ROOM_UPDATE.replace(':roomId', id),
    payload,
    'apiEditRoom',
    successMessage,
    errorMessage
  );
}

export function apiEditProduct(
  id,
  payload,
  successMessage = 'Item updated successfully.',
  errorMessage = 'There was an issue updating this item.'
) {
  return apiPatch(
    UPDATE_PROPS_API.replace(':productId', id),
    payload,
    'apiEditRoom',
    successMessage,
    errorMessage
  );
}

export function apiToggleFavorite(id, is_favourite, path) {
  let payload = {
    is_favourite: is_favourite,
  };
  let functionCallback;
  switch (path) {
    case MODULE_TYPES.PROJECT:
    case MODULE_TYPES.PROJECTS:
      functionCallback = apiEditProject;
      break;
    case MODULE_TYPES.TEMPLATE:
    case MODULE_TYPES.TEMPLATES:
    case MODULE_TYPES.ROOM:
    case MODULE_TYPES.ROOMS:
      functionCallback = apiEditRoom;
      break;
    case MODULE_TYPES.PROP:
    case MODULE_TYPES.PROPS:
    case MODULE_TYPES.PRODUCT:
    case MODULE_TYPES.PRODUCTS:
      functionCallback = apiEditProduct;
      break;
    case MODULE_TYPES.COLLECTION:
    case MODULE_TYPES.COLLECTIONS:
      functionCallback = apiEditCollection;
      break;
    case MODULE_TYPES.PROJECT_RENDER:
    case MODULE_TYPES.RENDER:
    case MODULE_TYPES.RENDERS:
    case MODULE_TYPES.RENDER_DETAIL:
    case MODULE_TYPES.RENDER_PREVIEW:
      functionCallback = apiEditRenderPreview;
      break;
    case MODULE_TYPES.MODEL:
    case MODULE_TYPES.MODELS:
      // NEED ENDPOINT
      break;
    default:
      break;
  }
  if (_.isFunction(functionCallback)) {
    return functionCallback(id, payload, null, null);
  }
}

export function apiCreateCollection(payload, successMessage = null, errorMessage = null) {
  return apiPost(
    COLLECTION_DETAILS_API,
    payload,
    'apiCreateCollection',
    successMessage,
    errorMessage
  );
}

export function apiAddToCollection(formData, successMessage = null, errorMessage = null) {
  return apiMultiPost(
    ADD_TO_COLLECTIONS,
    formData,
    'apiAddToCollection',
    successMessage,
    errorMessage
  );
}

export function apiGetRooms(query, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_ROOMS_API}?${query}`, 'apiGetRooms', successMessage, errorMessage);
}

export function apiGetCollectionItems(id, query, successMessage = null, errorMessage = null) {
  return apiGet(
    `${COLLECTION_DATA_API}${id}?${query}`,
    'apiGetCollectionItems',
    successMessage,
    errorMessage
  );
}

export function apiGetCollectionDetail(id, successMessage = null, errorMessage = null) {
  return apiGet(
    `${COLLECTION_DETAILS_API}${id}`,
    'apiGetCollectionDetail',
    successMessage,
    errorMessage
  );
}

export function apiGetCollections(query, successMessage = null, errorMessage = null) {
  return apiGet(
    `${COLLECTION_DETAILS_API}${query ? `?${query}` : ''}`,
    'apiGetCollections',
    successMessage,
    errorMessage
  );
}
// may remove this one altogether in favor of getProjectsApiV2
export function apiGetProjects(query, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_PROJECT_API}?${query}`, 'apiGetProjects', successMessage, errorMessage);
}

export function apiDeleteProjects(
  payload,
  successMessage = 'These projects have been successfully deleted',
  errorMessage = 'There was an error deleting these projects.'
) {
  return apiMultiDelete(
    PROJECT_MULTI_DELETE,
    payload,
    'apiDeleteProjects',
    successMessage,
    errorMessage
  );
}

export function apiDeleteCollections(
  payload,
  successMessage = 'These collections have been successfully deleted',
  errorMessage = 'There was an error deleting these collections.'
) {
  return apiMultiDelete(
    COLLECTION_MULTI_DELETE,
    payload,
    'apiDeleteCollections',
    successMessage,
    errorMessage
  );
}

export function apiRemoveFromCollections(
  id,
  payload,
  successMessage = 'These items have been successfully removed from this collection',
  errorMessage = 'There was an error removing these items.'
) {
  return apiMultiDelete(
    `${COLLECTION_DATA_API}${id}`,
    payload,
    'apiRemoveFromCollections',
    successMessage,
    errorMessage
  );
}

export function apiGetMaterials(query, successMessage = null, errorMessage = null) {
  return apiGet(`${MATERIAL_API}?${query}`, 'apiGetMaterials', successMessage, errorMessage);
}

export function apiGetTextureList(query, successMessage = null, errorMessage = null) {
  return apiGet(`${ADMIN_TEXTURE_API}?${query}`, 'apiGetTextures', successMessage, errorMessage);
}

export function apiGetTextures(query, successMessage = null, errorMessage = null) {
  return apiGet(`${TEXTURE_DETAILS_API}?${query}`, 'apiGetTextures', successMessage, errorMessage);
}

export function apiGetTexture({ id, successMessage = null, errorMessage = null }) {
  return apiGet(`${TEXTURE_DETAILS_API}${id}/`, 'apiGetTexture', successMessage, errorMessage);
}

export const apiCheckTexture = ({ textureId, version }) => {
  let query = `texture_id=${textureId}`;

  if (version) {
    query += `&texture_ver=${version}`;
  }

  return api()
    .root(API_ROOT_URL)
    .get(`${TEXTURE_CHECK_VERSION}?${query}`)
    .success((data) => {
      return data;
    })
    .notFound404(() => {})
    .send();
};

export const apiUploadTexture = (payload) => {
  return apiPost(TEXTURE_DETAILS_API, payload, 'apiUploadTexture');
};

export function apiRestoreRenders(
  payload,
  successMessage = 'These renders/previews have been successfully restored',
  errorMessage = 'There was an error restoring these renders/previews.'
) {
  return apiMultiRestore(
    UPDATE_RENDER_PREVIEW,
    payload,
    'apiRestoreRenders',
    successMessage,
    errorMessage
  );
}

export function apiArchiveRenders(
  payload,
  successMessage = 'These renders have been successfully archived',
  errorMessage = 'There was an error archiving these renders.'
) {
  return apiMultiDelete(
    UPDATE_RENDER_PREVIEW,
    payload,
    'apiArchiveRenders',
    successMessage,
    errorMessage
  );
}

export function apiGetRoomDetails(paramsObj, successMessage = null, errorMessage = null) {
  const params = objectToSearchParams(paramsObj);
  return apiGet(`${ROOM_DETAILS_API}?${params}`, 'apiGetRoomDetails', successMessage, errorMessage);
}

export function apiGetCarouselImages(paramsObj, successMessage = null, errorMessage = null) {
  const params = objectToSearchParams(paramsObj);
  return apiGet(`${CAROUSEL_API}?${params}`, 'apiGetCarouselImages', successMessage, errorMessage);
}

export function apiGetPreviewAndMainRenders(params, successMessage = null, errorMessage = null) {
  return apiGet(
    `${GET_RENDER_PREVIEW_DATA}?${params}`,
    'apiGetPreviewAndMainRenders',
    successMessage,
    errorMessage
  );
}

export function apiGetPreviewAndMainRender(id, successMessage = null, errorMessage = null) {
  return apiGet(
    `${GET_RENDER_PREVIEW_DATA}${id}`,
    'apiGetPreviewAndMainRender',
    successMessage,
    errorMessage
  );
}

export function apiGetPresetCameraAngle(roomId) {
  return apiGet(`${PRESET_CAMERA_ANGLE}?room_id=${roomId}`);
}

export function apiGetDownloadRendersImages(query, includePasses, pass) {
  if (includePasses) {
    query += '&is_color_pass=true';
  }
  return apiGetBlob(`${DOWNLOAD_RENDER}?${query}`);
}

export function apiGetAcceptTermsAndConditions() {
  return apiGet(TERMS_ACCEPT_API);
}

export function apiGetVerifyEmailLink(token) {
  return apiGet(`${LINK_VERIFY_API}${token}`);
}

export function apiPostRegisterInvite(
  payload,
  token,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    `${REGISTER_INVITE_API}${token}/`,
    payload,
    'apiPostRegisterInvite',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiGetProps(params, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_PROPS_API}?${params}`, 'apiGetProps', successMessage, errorMessage);
}

export function apiGetProp(id, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_PROPS_API}${id}`, 'apiGetProp', successMessage, errorMessage);
}
export function apiGetProductWebExperience(id, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_WEB_EXPERIENCE_API}?product=${id}`, 'apiGetProductWebExperience', successMessage, errorMessage);
}
// handling google login and manual login differently because server error happens when email is not verified rather than handling the error
export function apiPostGoogleLogin(
  payload,
  isFormData = false,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    GOOGLE_LOGIN_API,
    payload,
    'apiPostGoogleLogin',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiPostLogin(
  payload,
  isFormData = false,
  successMessage = null,
  errorMessage = null
) {
  let formData = payload;
  try {
    return api()
      .root(API_ROOT_URL)
      .post(LOGIN_API)
      .data(formData)
      .success((data) => {
        if (successMessage) {
          successToast(successMessage);
        }
        return data;
      })
      .error((error) => {
        console.error('error: ', errorMessage);
        if (errorMessage) {
          errorToast(errorMessage);
        }
        apiErrorHandler(error, null);
        throw error;
      })
      .send();
  } catch (error) {
    console.error('error: ', error);
    throw error;
  }
}

export function apiPostRegister(
  payload,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    REGISTER_API,
    payload,
    'apiPostRegister',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiPostVerifyOtp(
  payload,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    VERIFY_OTP_API,
    payload,
    'apiPostVerifyOtp',
    successMessage,
    errorMessage,
    isFormData
  );
}

// export const LinkVerifyApi = () => (dispatch) => {
//   api().root(API_ROOT_URL).get(LINK_VERIFY_API).success(({status, data, message})=>{
//       setLocalStorage(AUTH_TOKEN, data.access)
//       setLocalStorage(REFRESH_TOKEN, data.refresh)
//       if(status){
//           navigate(CUSTOMIZE_EXPERIENCE)
//           successToast(message)
//       }
//   }).error(()=>{

//   }).send()
// }

export function apiPostResendOtpEmail(
  payload,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    RESEND_EMAIL_API,
    payload,
    'apiPostVerifyOtp',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiPostForgotPassword(
  payload,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    FORGOT_API,
    payload,
    'apiPostForgotPassword',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiPostResetPassword(
  payload,
  token,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    CREATE_PASSWORD_API + token + '/',
    payload,
    'apiPostForgotPassword',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiGetTNCLatest(params, successMessage = null, errorMessage = null) {
  // const params = objectToSearchParams(paramsObj);
  return apiGet(TERMS_API, 'apiGetTNCLatest', successMessage, errorMessage);
}

export function apiPatchUpdateUserProfile(payload, successMessage = null, errorMessage = null) {
  return apiPatch(
    UPDATE_USER_PROFILE_API,
    payload,
    'apiPatchUpdateUserProfile',
    successMessage,
    errorMessage
  );
}

export function apiPatchAfterRegister(payload, successMessage = null, errorMessage = null) {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (_.isArray(value)) {
      value.forEach((element) => formData.append(key, element));
    } else {
      formData.append(key, value);
    }
  });

  return api()
    .root(API_ROOT_URL)
    .patch(AFTER_REGISTER_API)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, 'apiPatchAfterRegister');
    })
    .send();
}

export function apiGetOrganizationBalance(successMessage = null, errorMessage = null) {
  return apiGet(GET_USER_BALANCE, 'apiGetOrganizationBalance', successMessage, errorMessage);
}

export function apiGetTransactionHistory(successMessage = null, errorMessage = null) {
  return apiGet(TRANSACTION_HISTORY, 'apiGetTransactionHistory', successMessage, errorMessage);
}

export function apiPostPaymentIntentApi(
  amount,
  paymentType,
  isFormData = false,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    PAYMENT_INTENT + `?amount=${amount}&payment_method_types=${paymentType}`,
    {},
    'apiPostPaymentIntentApi',
    successMessage,
    errorMessage,
    false
  );

  // return api()
  //   .root(API_ROOT_URL)
  //   .post(PAYMENT_INTENT + `?amount=${amount}&payment_method_types=${paymentType}`)
  //   .success((data) => {
  //     if (successMessage) {
  //       successToast(successMessage);
  //     }
  //     return data;
  //   })
  //   .error((error) => {
  //     if (errorMessage) {
  //       errorToast(errorMessage);
  //     }
  //     apiErrorHandler(error, 'apiPostPaymentIntent');
  //     return error;
  //   })
  //   .send();
}

export function apiGetIndustryPreferences(
  selectedIndustries,
  successMessage = null,
  errorMessage = null
) {
  return apiGet(
    USER_PREFERENCE_API + `?id=${selectedIndustries.toString()}`,
    'apiGetIndustryPreferences',
    successMessage,
    errorMessage
  );
}

export function apiGetLight(paramsObj, successMessage = null, errorMessage = null) {
  const { id, ...rest } = paramsObj;
  const params = objectToSearchParams(rest);
  return apiGet(
    `${LIGHT_API}${id ? `${id}/` : ''}?${params}`,
    'apiGetLight',
    successMessage,
    errorMessage
  );
}

export async function getProjectsApiV2(params, successMessage = null, errorMessage = null) {
  const searchParams = objectToSearchParams(params);
  const API_URL = `${GET_PROJECT_API}?${searchParams}`;
  const requestId = uniqueId();

  // need to replace this concept so no overloading the server
  // but do it with axios transaction handling instead of reducer
  // dispatch(myProjectsActions.fetchMyProjectsListStart({ requestId }))

  let results = await getDataList(
    apiGet(API_URL, 'getProjectsApiV2', successMessage, errorMessage)
  );
  return results;
}

export const parseBasePathname = (location) => {
  // /rooms/room-templates returns ['', 'rooms', 'room-templates']
  // value should return 'rooms'
  return _.find(location?.pathname.split('/'), (str) => str && str.length > 0);
};

export const parseProductTypePathname = (location) => {
  // /products/3dmodel returns ['', 'products', '3dmodel']
  // value should return '3dmodel'
  return _.chain(location?.pathname.split('/'))
    .filter((str) => str && str.length > 0)
    .last()
    .value();
};
export const createListApiRequest =
  ({ url, onStart, onSuccess, onError, startAction, successAction, errorAction }) =>
  ({ search, sort = '-created_at', perPage, page, ...rest } = {}) =>
  (dispatch) => {
    const searchParams = objectToSearchParams({
      search,
      sort,
      per_page: perPage,
      page,
      ...rest,
    });
    const API_URL = `${url}?${searchParams}`;

    onStart?.(dispatch);
    startAction && dispatch(startAction());

    api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => {
        onSuccess?.({
          data,
          dispatch,
          args: {
            search,
            sort,
            perPage,
            page,
          },
        });

        successAction &&
          dispatch(
            successAction({
              page,
              data,
            })
          );
      })
      .error((error) => {
        // TODO: add logger
        console.error(error);
        onError?.(error, dispatch);
        errorAction && dispatch(errorAction(error));
      })
      .send();
  };

export function apiGetIndustries() {
  return apiGet(INDUSTRY_API);
}

export function apiGetPlatforms() {
  return apiGet(PLATFORM_API);
}

export function apiGetProfessions() {
  return apiGet(PROFESSION_API);
}

export function apiGetIndustriesPreferences(selectedIndustries) {
  return apiGet(`${USER_PREFERENCE_API}?id=${selectedIndustries.toString()}`);
}

export function apiGetUserDetails() {
  return apiGet(GET_USER_DETAILS);
}

export function apiUpdateUserDetails(formData) {
  return api()
    .root(API_ROOT_URL)
    .patch(GET_USER_DETAILS)
    .data(formData)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
    })
    .send();
}

export function apiNotificationSettings() {
  return apiGet(NOTIFICATION_SETTINGS);
}

export function apiUpdateNotificationSettings(formData) {
  return api()
    .root(API_ROOT_URL)
    .put(NOTIFICATION_SETTINGS)
    .data(formData)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
    })
    .send();
}

export function apiUpdateOrganization(formData) {
  return api()
    .root(API_ROOT_URL)
    .patch(CREATE_ORGANIZATION)
    .data(formData)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
    })
    .send();
}

export function apiGetOrganizationDetails() {
  return apiGet(CREATE_ORGANIZATION);
}

export function apiGetMembers() {
  return apiGet(`${GET_MEMBERS_API}`);
}
export function apiGetPermissions() {
  return apiGet(`${PERMISSIONS_API}?per_page=10000`);
}

export function apiChangePassword(formData) {
  return api()
    .root(API_ROOT_URL)
    .put(CHANGE_PASSWORD)
    .data(formData)
    .success((res) => {
      if (res && res.success) {
        successToast(
          'Your password has been updated. Feel free to change them anytime',
          'Correctly saved'
        );
      }
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
    })
    .send();
}

export function apiGetRenderBasedOnProjectRenderId(projectRenderId) {
  return apiGet(`${GET_RENDER_BY_PROJ_RENDER_ID}?project_render_id=${projectRenderId}`);
}

export function apiGetAllMyOrgs() {
  return apiGet(GET_MY_ORGANIZATIONS);
}

export function apiSwitchOrg(orgId) {
  return apiPost(`${SWITCH_ORG}?org=${orgId}`, null, '', null, null, false);
}

export function apiCreateOrganization(name, createdBy) {
  return apiPost(CREATE_ORGANIZATION, { name: name, created_by: createdBy }, '', null, null, true);
}

export function apiCreateRender(payload) {
  return apiPost(CREATE_RENDER, payload, '', null, null, true);
}

export function apiUploadRenderImages(formData) {
  return api()
    .root(API_ROOT_URL)
    .post(UPLOAD_RENDER_IMAGES)
    .data(formData)
    .success((data) => {
      successToast('Image uplaod successful');
    })
    .error((error) => {
      errorToast('Error uploading images');
      apiErrorHandler(error, 'apiUploadRenderImages');
      return error;
    })
    .send();
}

export function apiGetMemberDetails(id) {
  return apiGet(GET_MEMBERS_API + id);
}

export function apiGetDownloadProjectData(query) {
  return apiGetBlob(`${PROJECT_UPLOAD}?${query}&is_generate=true`);
}