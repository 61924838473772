import { createSlice } from '@reduxjs/toolkit';

const renderPreviewPoppup = createSlice({
  name: 'renderPreviewPoppup',
  initialState: {
    popupType: false,
    selectedCameraIds: [],
  },
  reducers: {
    setOpen: (state, { payload }) => {
      state.popupType = payload
    }
  },
});

export const {
  setOpen,
} = renderPreviewPoppup.actions;
export const renderPreviewPoppupSelector = (state) => state.renderPreviewPoppup;
export const renderPreviewPoppupReducer = renderPreviewPoppup.reducer;
export default renderPreviewPoppupReducer;

export const selectOpen = (state) => Boolean(state.renderPreviewPoppup.popupType);
export const selectPopupType = (state) => state.renderPreviewPoppup.popupType;