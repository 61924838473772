import _ from 'lodash';
import { Suspense, useEffect, useState } from 'react';
import { initDB } from 'react-indexed-db';
import { Provider } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ActivityContainer } from './components/container/activityContainer';
import InfoDialog from './components/InfoDialog/InfoDialog';
import { FILTER_TYPES } from './constants/constants';
import { CURATOR_CONFIG } from './constants/indexDBSchema';
import {
  AUTH_TOKEN,
  CURRENT_USER,
  EMAIL_VERIFIED,
  IN_FULL_SCREEN,
  LATEST_TNC_ACCEPTED,
  MEMBER_ID,
  REFRESH_TOKEN,
} from './constants/projectConstants';
import { SIGN_IN_ROUTE } from './constants/routePathConstants';
import { FilterContext, UserContext } from './contexts';
import { apiFilterLabels, apiGetCollections, apiTeamMembers } from './helpers/api';
import { apiErrorHandler } from './helpers/axiosHelper';
import { warningToast } from './helpers/toastHelper.js';
import {
  addValueLabelToList,
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from './helpers/jsHelper';
import { sessionDestroy } from './helpers/projectHelper';
import { Loading } from './layouts';
import store from './redux/store';
import { onAuthPathOnly, PREVIEW_ROUTES, Routing, SHOW_DRILLDOWN_MENU_ROUTES } from './routes';

export const assessDrillDownSideMenuRoute = (pathname) =>
  _.some(SHOW_DRILLDOWN_MENU_ROUTES, (rte) => {
    return matchPath({ path: rte }, pathname) !== null;
  });

export const assessPreviewRoute = (pathname) =>
  _.some(PREVIEW_ROUTES, (rte) => {
    return matchPath({ path: rte }, pathname) !== null;
  });

initDB(CURATOR_CONFIG);

const emptyGlobalFilters = {
  is_favourite: false,
  orderedFilters: []
};

export default function App() {
  const location = useLocation();
  const pathname = location?.pathname;
  const authToken = getLocalStorage(AUTH_TOKEN);
  const refresh_token = getLocalStorage(REFRESH_TOKEN);
  const email_verified = getLocalStorage(EMAIL_VERIFIED);
  const latest_tnc_accepted = getLocalStorage(LATEST_TNC_ACCEPTED);
  const is_email_verified = getLocalStorage(EMAIL_VERIFIED);
  const is_in_full_screen = getLocalStorage(IN_FULL_SCREEN);

  const [token, setToken] = useState(authToken || null);
  const user = token ? getLocalStorage(CURRENT_USER, true) : null;
  const [refreshToken, setRefreshToken] = useState(refresh_token || null);
  const [emailVerified, setEmailVerified] = useState(email_verified || false);
  const [latestTNCAccepted, setLatestTNCAccepted] = useState(latest_tnc_accepted || false);
  //const [isEmailVerified, setIsEmailVerified] = useState(is_email_verified || false);
  const [currentUser, setCurrentUser] = useState(user);
  const [globalFilters, setGlobalFilters] = useState(Object.assign({}, emptyGlobalFilters));
  const [drillDownsReady, setDrillDownsReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDrilldownMenu, setShowDrilldownMenu] = useState(false);
  const [hideSidemenu, setHideSidemenu] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [collections, setCollections] = useState([]);
  const [verificationToken, setVerificationToken] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  const [onInviteRoute, setOnInviteRoute] = useState(
    localStorage && _.includes(['false', false], localStorage.getItem('on_invite_route'))
      ? false
      : true
  );

  // current user
  const requiredAuthKeys = token && refreshToken && emailVerified;

  useEffect(() => {
    window.addEventListener('click', runAuthCheck);
  }, []);

  useEffect(() => {
    setShowDrilldownMenu(assessDrillDownSideMenuRoute(pathname));
    setHideSidemenu(assessPreviewRoute(pathname));
  }, [pathname]);

  // useEffect(() => {
  //   localStorage.setItem("in_full_screen", inFullScreen);
  // }, [inFullScreen])

  useEffect(() => {
    if (showDrilldownMenu && !drillDownsReady && !loading && currentUser) {
      refreshOptions();
    }
  }, [showDrilldownMenu, drillDownsReady, loading, currentUser]);

  function runAuthCheck() {
    if (!getLocalStorage(AUTH_TOKEN) && onAuthPathOnly(pathname)) {
      warningToast('You are logged out. Redirecting...');
      window.location.pathname = SIGN_IN_ROUTE;
      return null;
    }
  }

  function refreshOptions() {
    if (!loading) {
      setLoading(true);
      const getTeammates = apiTeamMembers({
        page: 1,
        perPage: 15,
        sort: '',
      }).then((res) => {
        return { teamList: res?.results };
      });
      const getFilterOptions = apiFilterLabels().then((res) => {
        let results = {};
        if (res && res.results && res.results.length > 0) {
          _.map(res.results, (filter) => {
            filter.name = filter.label_name;
            filter.value = filter.id;
            filter.options = addValueLabelToList(filter.options);
            results[filter.name] = filter;
          });
          return {
            misc: results,
          };
        }
      });
      const getCollections = apiGetCollections().then((res) => {
        if (res && res.results && res.results.length > 0) {
          return {
            collectionList: _.map(res.results, (item) => {
              item.label = item.name;
              item.value = item.id;
              return item;
            }),
          };
        }
      });
      Promise.all([getFilterOptions, getTeammates, getCollections])
        .then((aggResults) => {
          const newStatus = {};
          aggResults.forEach((r) => Object.assign(newStatus, r));
          const { teamList, misc, collectionList } = newStatus;
          if (teamList && teamList.length > 0) {
            setTeamMembers(
              _.map(teamList, (t) => {
                t.value = t.id;
                t.label = t.user?.name;
                return t;
              })
            );
          }
          if (collectionList && collectionList.length > 0) {
            setCollections(collectionList);
          }
          if (misc) {
            const { categories } = misc;
            if (categories && categories.options && categories.options.length > 0) {
              setCategoryOptions(categories.options);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => {
          setLoading(false);
          setDrillDownsReady(true);
        });
    }
  }

  function shapeSidefilterList(type, oldList, addMoreList) {
    let list = _.find(oldList, opt => opt.key === type)?.list;
    const possiblePrevSelected = _.filter(list, (item) => item.selected);
    return _.map(addMoreList, (item) => {
      item.parentKey = type;
      item.selected = _.some(possiblePrevSelected, (pr) => pr.selected && pr.id === item.id);
      return item;
    });
  }

  function handleFilterOptions(sideFilter) {
    if (sideFilter) {
      let { filterlabels, order } = sideFilter;
      let modifiedOptList = Object.assign([], globalFilters?.orderedFilters);
      filterlabels = _.map(addValueLabelToList(filterlabels), f => {
        f.label = f.label_name;
        return f;
      });
      let orderedFilters = [];
      // i.e., order = ["collections", "brands", "filterlabels", "filteroptions"]
      _.map(order, (itemName) => {
        if (itemName === FILTER_TYPES.FILTER_OPTIONS) {
          let optionsList = sideFilter[itemName];
          _.chain(filterlabels)
            .sortBy('order')
            .forEach(opt => {
              let matchingList = _.filter(optionsList, l => {
                return parseInt(opt.value, 10) ===  parseInt(l.filter_label, 10)
              })
              if (matchingList) {
                let listItem = {
                  key: opt.label_name,
                  list: shapeSidefilterList(opt.label_name, modifiedOptList, matchingList),
                  filter_label_id: opt.id,
                  seq: order.length + opt.order // filteroptions are always at the end after the other items
                  // in the order list, so adding the length of the order list, since
                  // the opt.order is a 0-based order sequence
                };
                orderedFilters.push(listItem)
              }
            })
            .value();
       
        } else if (itemName !== FILTER_TYPES.FILTER_LABELS) {
          let list = addValueLabelToList(sideFilter[itemName]);
          let listItem = {
            key: itemName,
            list: shapeSidefilterList(itemName, modifiedOptList, list),
            filter_label_id: null,
          };
          orderedFilters.push(listItem);
        }
        // filter labels do not show up on the sidemenu
        // but are used to shape the filter options,
        // so ignoring them here
      });
      orderedFilters = _.chain(orderedFilters)
        .map((item, index) => {
          item.seq = index;
          return item;
        })
        .sortBy(orderedFilters, 'seq')
        .reverse()
        .value();
      let changes = Object.assign({}, globalFilters)
      changes.orderedFilters = orderedFilters;
      setGlobalFilters(changes);
    } else {
      setGlobalFilters(Object.assign({}, emptyGlobalFilters));
    }
  }

  function toggleFavorite() {
    let newFilters = { ...globalFilters };
    newFilters.is_favourite = !newFilters.is_favourite;
    setGlobalFilters(newFilters);
  }

  function selectGlobalFilter(list_type, itemId) {
    let newFilters = { ...globalFilters?.orderedFilters };
    let filterToEdit = _.find(newFilters, (f) => f.key === list_type);
    if (filterToEdit) {
      filterToEdit = _.map(filterToEdit.list, (f) => {
        if (f.id === itemId) {
          f.selected = f.selected ? false : true;
        }
        return f;
      });
    }
    newFilters.orderedFilters = filterToEdit;
    setGlobalFilters({
      orderedFilters: newFilters,
      ...globalFilters,
    });
  }

  function clearGlobalFilters() {
    let newList = {};
    newList.orderedFilters = _.map(globalFilters?.orderedFilters, (item) => {
      item.list =
        _.map(item.list, (item) => {
          item.selected = false;
          return item;
        }) || [];
      return item;
    });
    newList.is_favourite = false;
    setGlobalFilters(newList);
  }

  // useNavigate does not work outside of Routes switch, so dirty redirect method here
  function navigate(path) {
    window.history.pushState({
      pathname: path,
    });
  }
  function logout() {
    setCurrentUser(null);
    setToken(null);
    setRefreshToken(null);
    setEmailVerified(false);
    setLatestTNCAccepted(false);
    sessionDestroy(SIGN_IN_ROUTE);
    setOnInviteRoute(true);
    clearLocalStorage();
    //navigate(SIGN_IN_ROUTE);
  }

  function onUserChanges(user) {
    setCurrentUser(user);
    setLocalStorage(CURRENT_USER, user);
    setLocalStorage(MEMBER_ID, user.id);
  }

  function onLogin(
    user,
    token,
    refresh_token,
    is_email_verified = false,
    latest_tnc_accepted = false,
    on_invite_route = false
  ) {
    if (user) {
      onUserChanges(user);
    }
    if (token) {
      setToken(token);
      setLocalStorage(AUTH_TOKEN, token);
    }
    if (refresh_token) {
      setRefreshToken(refresh_token);
      setLocalStorage(REFRESH_TOKEN, refresh_token);
    }
    setEmailVerified(is_email_verified);
    setLocalStorage(EMAIL_VERIFIED, is_email_verified);
    setLatestTNCAccepted(latest_tnc_accepted);
    setLocalStorage(LATEST_TNC_ACCEPTED, latest_tnc_accepted);
    setLocalStorage('on_invite_route', on_invite_route);
    setOnInviteRoute(on_invite_route);
    //navigate(ADMIN_DASHBOARD);
  }

  function cleanGlobalFiltersOnNavigate() {
    handleFilterOptions(null);
    setTeamMembers(
      _.map(teamMembers, (m) => {
        m.selected = false;
        return m;
      })
    );
    setBreadcrumbs(null);
  }

  function changeProfilePic(image) {
    let changes = Object.assign({}, currentUser);
    let userChanges = Object.assign({}, changes.user);
    userChanges.profile_image = image;
    changes.user = userChanges;
    onUserChanges(changes);
  }

  const userInfo = {
    currentUser: currentUser,
    setCurrentUser: setCurrentUser,
    token: token,
    setToken: setToken,
    refreshToken: refreshToken,
    setRefreshToken: setRefreshToken,
    verificationToken: verificationToken,
    setVerificationToken: setVerificationToken,
    onLogin: onLogin,
    logout: logout,
    isInternalUser: Boolean(currentUser?.role?.is_internal_member),
    latestTNCAccepted: latestTNCAccepted,
    setLatestTNCAccepted: setLatestTNCAccepted,
    emailVerified: emailVerified,
    setEmailVerified: setEmailVerified,
    changeProfilePic: changeProfilePic,

    onInviteRoute: onInviteRoute,
    setOnInviteRoute: setOnInviteRoute,
  };

  const filterInfo = {
    hideSidemenu: hideSidemenu,
    globalFilters: globalFilters,
    setGlobalFilters: setGlobalFilters,
    showDrilldownMenu: showDrilldownMenu,
    refreshOptions: refreshOptions,
    selectGlobalFilter: selectGlobalFilter,
    clearGlobalFilters: clearGlobalFilters,
    teamMembers: teamMembers,
    setTeamMembers: setTeamMembers,
    categoryOptions: categoryOptions,
    refListsReady:
      !showDrilldownMenu ||
      (categoryOptions && categoryOptions.length > 0) ||
      (teamMembers && teamMembers.length > 0),
    handleFilterOptions: handleFilterOptions,
    toggleFavorite: toggleFavorite,
    breadcrumbs: breadcrumbs,
    setBreadcrumbs: setBreadcrumbs,
    collections: collections,
    setCollections: setCollections,
    cleanGlobalFiltersOnNavigate: cleanGlobalFiltersOnNavigate,
  };

  return (
    <Provider store={store}>
      <ActivityContainer>
        <Suspense fallback={<Loading suspense />}>
          <UserContext.Provider value={userInfo}>
            <FilterContext.Provider value={filterInfo}>
              <Routing authorized={requiredAuthKeys} token={token} />
            </FilterContext.Provider>
          </UserContext.Provider>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={5}
          />
          <InfoDialog />
        </Suspense>
      </ActivityContainer>
    </Provider>
  );
}
