import { createSlice } from "@reduxjs/toolkit";

const myProjectDetails = createSlice({
  name: "myProjectDetails",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchMyProjectDetailsStart: (state) => {
      state.data = null;
      state.loading = true;
    },
    fetchMyProjectDetailsSuccess: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    fetchMyProjectDetailsFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    clearMyProjectDetails: (state) => {
      state.data = null;
      state.loading = false;
    },
    updateProject: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
      }
    },
  },
});

export const myProjectDetailsActions = myProjectDetails.actions;
export const { 
  fetchMyProjectDetailsStart,
  fetchMyProjectDetailsFailed,
  fetchMyProjectDetailsSuccess,
  clearMyProjectDetails,
  updateProject,
 } = myProjectDetails.actions;
export const myProjectDetailsSelector = (state) => state.myProjectDetails;
export const myProjectDetailsReducer = myProjectDetails.reducer;
export default myProjectDetailsReducer;
