import PubSub from 'pubsub-js'
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../../../common';
import { StatelessCheckbox } from '../../../../common/Checkbox';
import { AppEvent } from '../../../../helpers/unityPubSub';
import { useToggleListItem } from '../../../../hooks/useToggleListItem';
import {
  selectImportProjectTexturesConflictList,
  selectTextureVersionSelectorDialogOpen,
  setImportProjectTexturesConflictList,
  setTextureVersionSelectorDialogOpen,
} from '../../../../redux/slicers/admin/curatorSlicer';
import Button from '../../../Button/Button';
import Image from '../../../Image/Image';
import InfoDialogUI from '../../../InfoDialog/InfoDialogUI';
import styles from './TextureVersionSelectorDialog.module.scss';

export const TextureVersionSelectorDialog = memo(() => {
  const dispatch = useDispatch();
  const show = useSelector(selectTextureVersionSelectorDialogOpen);
  const textureList = useSelector(selectImportProjectTexturesConflictList);
  const { ids, toggleItem } = useToggleListItem();

  const handleSubmit = () => {
    PubSub.publish(AppEvent.ResolveTextureConflicts, ids);

    dispatch(setTextureVersionSelectorDialogOpen(false))
    dispatch(setImportProjectTexturesConflictList([]));
  }

  return (
    <InfoDialogUI show={show} title="Newer textures available">
      <p>Please select if you want to use textures from the server or from zip file</p>
      <div className={styles.list}>
        {textureList.map((texture) => {
          return (
            <label key={texture.id} className={styles.listItem}>
              <div className={styles.imageContainer}>
                <Image ratio={1} src={texture.thumbnail} placeholderWhileLoading />
              </div>
              <div className={styles.textureName}>{texture.name}</div>
              <StatelessCheckbox 
                type="checkbox"
                checked={ids.includes(texture.id)}
                onChange={(e) => toggleItem(texture)}
                className={styles.checkbox}
              />
            </label>
          );
        })}
      </div>
      
      <Button fullWidth className="mt-3" color="warning" onClick={handleSubmit}> Ok</Button>
    </InfoDialogUI>
  );
});
