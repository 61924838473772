export const EditIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.35631 18.7223C5.68261 19.0326 6.13625 19.1998 6.62172 19.1998C6.70334 19.1998 6.78169 19.1932 6.86305 19.1864C6.90863 19.1826 6.95515 19.1788 7.00373 19.1759L9.56637 18.7382C10.0518 18.6586 10.6726 18.3243 11.0069 17.9662L17.5408 11.0503C19.1723 9.32325 19.1246 7.62013 17.3976 5.98863C15.6706 4.35714 13.9674 4.40489 12.3359 6.13189L5.80199 13.0478C5.45977 13.398 5.16531 14.0427 5.1096 14.5281L4.81513 17.1067C4.74351 17.7434 4.94247 18.3323 5.35631 18.7223ZM13.2034 6.9357C13.7446 6.36268 14.2778 5.98067 14.8906 5.98067C15.3841 5.98067 15.9332 6.23535 16.5699 6.84815C18.0104 8.2011 17.6522 9.17204 16.6654 10.2226L15.7857 11.1537C14.0723 10.8628 12.7065 9.55885 12.3305 7.86069L13.2034 6.9357ZM11.3798 8.86812L6.66947 13.8596C6.5103 14.0267 6.32725 14.4247 6.29542 14.6555L6.00095 17.234C5.96912 17.4967 6.03279 17.7115 6.17604 17.8468C6.31929 17.9821 6.53417 18.0299 6.79681 17.9901L9.35945 17.5524C9.59025 17.5126 9.97226 17.3057 10.1314 17.1385L14.8434 12.151C13.2477 11.6465 11.9683 10.4353 11.3798 8.86812Z"
        fill="currentColor"
      />
    </svg>
  );
};
