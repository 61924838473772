import React from 'react';
import {
    Row, 
} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from "react-select";
import {
    apiGetIndustries,
    apiGetIndustriesPreferences,
    apiGetUserDetails,
    apiUpdateUserDetails
} from '../helpers/api';
import makeAnimated from 'react-select/animated';
import { YourInterests } from './';
import { addValueLabelToList } from '../helpers/jsHelper';
import _ from 'lodash';

export default function Interests(props) {

    const animatedComponents = makeAnimated();
    const [userDetails, setUserDetails] = useState({});
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [objArr, setObjArr] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);

    const [industriesOptions, setIndustriesOptions] = useState([]);
    const [selectedIndustriesId, setSelectedIndustriesId] = useState([]);
    const [industriesPreference, setIndustriesPreference] = useState([]);
    const title = "Your Interests";
    const description = "Your selections help us identify your interests so we can show you relevant templates, props, and finishes";

    useEffect(() => {
        getIndustries();                
    }, [userDetails]);

    useEffect(() => {
        getUserDetails();
    }, []);

    useEffect(() => {        
        getIndustriesPreferences();
    }, [selectedIndustriesId, userDetails]);

    useEffect(() => {
        if (userDetails && industriesOptions && industriesOptions.length) {
            let listIndustry = [];
            let listId = [];
            userDetails?.industry?.forEach(item => {                
                let match = _.find(industriesOptions, {'value': item});                
                if (match) {
                    listIndustry.push(match);
                    listId.push(item);
                }
            });
            setSelectedIndustry(listIndustry);
            setSelectedIndustriesId(listId);                      
        }
    }, [industriesOptions]);

    useEffect(() => {
        updateUserPreferences();
    }, [selectedProduct]);


    function getIndustries() {
        apiGetIndustries().then((response) => {
            if (response && response.results) {              
                setIndustriesOptions(addValueLabelToList(response.results));
            }
        });     
    }

    function getUserDetails() {
        apiGetUserDetails().then((response) => {
            if (response) {                
                setUserDetails(response);
                setSelectedProduct(response.user_preferences);                
            }
        });
    }

    function getIndustriesPreferences() {        
        apiGetIndustriesPreferences(selectedIndustriesId).then((response) => {
            if (response && response.results) {                
                setIndustriesPreference(response.results);                
            }
        })
    }

    function updateUserPreferences() {        
        let formData = new FormData();
    
        selectedProduct.forEach(item => {            
            formData.append('user_preferences', item)
        });            
        apiUpdateUserDetails(formData).then((response) => {
            setUserDetails(response);            
        });
    }
            
    const handleIndustryChange = (e) => {                
        if (e && e.length) {
            setSelectedIndustry(e);
            const industries = e.map(x => x.value);            
                    
            let formData = new FormData;
            
            industries.forEach(item => {                
                formData.append('industry', item)
            });           
            apiUpdateUserDetails(formData).then((response) => {                
                setUserDetails(response);                
            });
        }
    }

    const handleProduct = (id, type) => {        
        objArr && objArr.length && objArr.indexOf(id) > -1
            ? setObjArr(objArr.filter((k) => k !== id))
            : setObjArr([]);            
        if (type === 'selected') {
            setSelectedProduct(selectedProduct.filter((k) => k !== id));
        }
        if (selectedProduct.length && selectedProduct.indexOf(id) > -1) {
            setSelectedProduct(selectedProduct.filter((k) => k !== id));
            updateUserPreferences();
        } else {
            setSelectedProduct([...selectedProduct, id]);
            updateUserPreferences();
        }
    }


    const handleButtonClick = (id) => {        
        if (props.handleButtonClick) {
            props.handleButtonClick(id)
        } else {
            selectedProduct.length && selectedProduct.indexOf(id) > -1
            ? setSelectedProduct(selectedProduct.filter((k) => k !== id))
            : setSelectedProduct([...selectedProduct, id]);       
            updateUserPreferences();

        }
    }

   
    return (
        <>
            <div className="interests-cont">                             
            <h1>Your industry</h1>
                <div>
                    <Row>
                        <div className="interest-section">
                            <div>
                                <div className="d-flex">                              
                                    <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    value={selectedIndustry}
                                    isMulti
                                    options={industriesOptions}
                                    onChange={handleIndustryChange}
                                    styles={{ height: "3em !important" }}
                                    />                    
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            <YourInterests 
                industriesPreference={industriesPreference}
                selectedProduct={selectedProduct}
                objArr={objArr}                  
                updateUserPreferences={updateUserPreferences}  
                handleProduct={handleProduct}
                handleButtonClick={handleButtonClick}            
                title={title}
                description={description}
            />
        </>
    );
}