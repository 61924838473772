import cx from "classnames";
import { FormLabel } from "./FormLabel";
import { EditableInput } from "react-color/lib/components/common";

import styles from "./ColorInput.module.scss";

export const ColorInput = ({
  label,
  value,
  name,
  className,
  InputContainerProps,
  readonly,
  onChange,
  ...rest
}) => {

  const handleChagne = (value, event) => {
    const normalizedValue = value.trim();
    if (normalizedValue.length <= 7) {
      onChange(value, event);
    } else {
      event.preventDefault();
    }
  }
  
  return (
    <div className={cx(styles.root, className)} {...rest}>
      {label && <FormLabel>{label}</FormLabel>}
      <div className={styles.inputContainer} {...InputContainerProps}>
        <EditableInput
          // className={styles.input}
          type="text"
          value={value}
          name={name}
          onChange={handleChagne}
          readonly
          color={value}
        />
        <div
          className={styles.colorPlaceholder}
          style={{ backgroundColor: value }}
        ></div>
      </div>
    </div>
  );
};
