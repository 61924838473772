import PubSub from 'pubsub-js'
import { useUnityObjectSelection } from "../../../hooks/useUnityObjectSelection";
import { useUnityLightModuleListeners } from "../../../components/admin/curator/components/lights/useUnityLightModuleListeners";
import { useUnityLightInit } from "../../../components/admin/curator/components/lights/useUnityLightInit";
import { useEffect } from "react";
import { UnityEvent } from '../../../helpers/unityPubSub';
import { CURSOR_MODE, SELECTED_OBJECT_COUNT_STR } from '../../../config/constant/unityConstants';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnityLoaded, setCuratorInitialized, setCursorMode, setRoomLightData, setSidebarSection } from '../../../redux/slicers/admin/curatorSlicer';
import { useUnityContext } from '../../../components/container/unityContainer';
import { setSelectedObjectList, setSelectedObjectsCountStr } from '../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { selectReplacePropActive, setReplacePropActive } from '../../../redux/slicers/admin/shortcutsModuleSlicer';
import { getAppState } from '../../../redux/store';
import { parseLight } from './projectParser/parseLight';

export const CuratorInitAndListeners = () => {
  const dispatch = useDispatch();
  const unityLoaded = useSelector(selectUnityLoaded)
  const { metadataModule } = useUnityContext();
  const unityContext = useUnityContext();

  useUnityLightInit();  
  useUnityObjectSelection();
  useUnityLightModuleListeners();

  
  useEffect(() => {
    const callback = (_, message) => {
      if (parseInt(message) === 0) {
        dispatch(setCursorMode(CURSOR_MODE.MOVE))
      }
    }
    PubSub.subscribe(UnityEvent.SwitchTransformTypeReceiver, callback)

    return () => PubSub.unsubscribe(callback)
  }, [])


  useEffect(() => {
    const allPropsLaded = () => {
      metadataModule.GetRoomMetadataListReceiver();
      dispatch(setCuratorInitialized(true))
    }
    PubSub.subscribe(UnityEvent.AllPropsLoaded, allPropsLaded)

    return () => {
      PubSub.unsubscribe(allPropsLaded)
      dispatch(setCuratorInitialized(false))
    }
  }, [])

  useEffect(() => {
    // wait for room light info
    const callback = (_, roomLightData) => {
      dispatch(setRoomLightData(roomLightData.map(parseLight)))
    }

    PubSub.subscribe(UnityEvent.GetLightData, callback)
    unityContext.lightModule.GetLightDatReceiver();

    return () => {
      PubSub.unsubscribe(callback)
    }
  }, [unityLoaded])

  useEffect(() => {
    // selected objects hook
    const callback = (_, objectList) => {
      let countStr = SELECTED_OBJECT_COUNT_STR.NONE;
      if (objectList?.length === 1) {
        countStr = SELECTED_OBJECT_COUNT_STR.SINGLE;
      } else if (objectList?.length > 1 ) {
        countStr = SELECTED_OBJECT_COUNT_STR.MULTIPLE;
      }

      console.log('sasha selected object list', objectList)
      dispatch(setSelectedObjectsCountStr(countStr))
      dispatch(setSelectedObjectList(objectList));

      // turn off replace prop flow
      const isReplacePropActive = selectReplacePropActive(getAppState());
      if (isReplacePropActive) {
        dispatch(setSidebarSection(null));
        dispatch(setReplacePropActive(false));
      }
    }

    PubSub.subscribe(UnityEvent.GetSelectedObjectInfoOnSelection, callback)

    return () => {
      PubSub.unsubscribe(callback)
    }
  }, [])


  return null;
}