import {React, useState, useEffect} from 'react';
import { PublicCarousel } from '../common';
import { CLOSE, UnionExclamation } from '../constants/assetConstants';
import { SIGN_IN_ROUTE } from '../constants/routePathConstants';
import { Col, Container, Navbar, Row, Card, Form, Carousel } from "react-bootstrap";
import { apiGetCarouselImages, apiPostResetPassword } from "../helpers/api";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useParams, Link, useNavigate } from "react-router-dom";
import { passwordIsValid } from '../helpers/jsHelper';
import { successToast } from '../helpers/toastHelper';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';


export default function ResetPassword() {
  
    const { token } = useParams();
    const navigate = useNavigate();
    const [carouselList, setCarouselList] = useState([]);
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const [confirmationPasswordMessage, setConfirmationPasswordMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const[success, setSuccess] = useState(false);


    useEffect(() => {
      apiGetCarouselImages(
        { name: "furnish" },
        null,
        "Unable to get carousel images."
      ).then((res) => setCarouselList(res?.results));
    }, []);

    
    const handleShowPassword = (e) => {
        e.preventDefault()
        setShowPassword(!showPassword); 
    }

    const handleShowConfirmationPassword = (e) => {
      e.preventDefault()
      setShowConfirmationPassword(!showConfirmationPassword); 
    }
 
    const handlePassword = (e) => {
        setPassword(e.target.value);
      
        if(!e.target.value || !e.target.value.trim()) {
          setPasswordMessage("Password Field is Required");
        }
        else if(passwordIsValid(e.target.value)){ 
            setPasswordMessage('');
        }
        else
        {
          setPasswordMessage("Must contain at least 8 characters and one number, one lowercase, one uppercase character.");
        } 
    }

    const handleConfirmPassword = (e) => {
        setConfirmationPassword(e.target.value);
        if(!e.target.value || !e.target.value.trim()) {
          setConfirmationPasswordMessage("Confirmation Password Field is Required");
        }
        else if(e.target.value !== password){
          setConfirmationPasswordMessage("Password and Confirmation Password do not match");
        }
        else{
          setConfirmationPasswordMessage('');
        }
    }

    const validate = () => {

        let passwordError = true;
        let confirmationPasswordError = true;
      
        if(!password || !password.trim()){
          setPasswordMessage("Password Field is Required");
        }
        else if(!passwordIsValid(password)){
            setPasswordMessage("Must contain at least 8 characters and one number, one lowercase, one uppercase character.");
        }
        else{
            setPasswordMessage("");
            passwordError = false;
        }

        if(!confirmationPassword || !confirmationPassword.trim()){
          setConfirmationPasswordMessage("Confirm Password field is required");
        }
        else if(password.trim() != confirmationPassword.trim()){
          setConfirmationPasswordMessage("Password and Confirmation Password do not match")
        }
                
        else
        {
          confirmationPasswordError = false;
        }

        if( passwordError || confirmationPasswordError){
          return false;
        }
          return true;
      }

  
    const handleSubmit= (e) => {
        e.preventDefault()
      
        if(validate()){
            const payload = {
              password: password,
            }
            apiPostResetPassword(payload, token, true, null, null).then((res) => {
              if (res) {
                let { success, message } = res;
                if(success === true) {
                  successToast(message);
                  navigate(SIGN_IN_ROUTE);
                }
              }
            })
        }
    }


    return (

      <div className="vw-100">
      <Row>
        <Col>
          <Navbar expand="lg" className="page-nav publicMenu">
            <Container fluid>
                <Navbar>
                  <Navbar.Text>
                    <Link to={SIGN_IN_ROUTE} className="back-button lf-link d-flex">
                      <img className="lf-link-img" src={CLOSE} alt=""/>
                      Close
                    </Link>
                  </Navbar.Text>
                </Navbar>
            </Container>
          </Navbar>
        </Col>
      </Row>

      <div className="publicContent">
        <Container>
          <Row className="d-flex justify-content-center align-items-stretch pt-5">
            <Col xs={5}>
                <Card className="py-5 px-4 h-100 border-0">
                  <div className="text-center">
                    <h1 className='m-bottom-1 appH1'>Create your new password</h1>
                  </div>
                  <Form action="" onSubmit={handleSubmit}>
                    <Form.Group className="px-5 py-3">
                      <div className="password-field">
                        {passwordMessage ? (
                          <Form.Label className="text-danger">
                            <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {passwordMessage}
                            </small>
                          </Form.Label>
                        ) : (
                          <Form.Label>Password</Form.Label>
                        )}
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          id="form2Example28"
                          name="password"
                          className={passwordMessage ? 'input-error' : ''}
                          placeholder="Type your password"
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={handlePassword}
                          onFocus={() => setPasswordMessage('')}
                        />
                        {showPassword ? (
                          <RiEyeLine className="eye-icon" onClick={handleShowPassword} />
                        ) : (
                          <RiEyeOffLine className="eye-icon" onClick={handleShowPassword} />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="px-5 py-3">
                      <div className="password-field">
                        {confirmationPasswordMessage ? (
                          <Form.Label className="text-danger">
                            <small>
                              <img
                              src={UnionExclamation}
                              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                              />
                              {confirmationPasswordMessage}
                            </small>
                          </Form.Label>
                        ) : (
                          <Form.Label>Confirm Password</Form.Label>
                        )}
                        <Form.Control
                          type={showConfirmationPassword ? 'text' : 'password'}
                          id="form2Example29"
                          className={confirmationPasswordMessage ? 'input-error' : ''}
                          placeholder="Repeat your password"
                          onChange={(e) => setConfirmationPassword(e.target.value)}
                          onBlur={handleConfirmPassword}
                          onFocus={() => setConfirmationPasswordMessage('')}
                        />
                        {showConfirmationPassword ? (
                          <RiEyeLine className="eye-icon" onClick={handleShowConfirmationPassword} />
                        ) : (
                          <RiEyeOffLine
                            className="eye-icon"
                            onClick={handleShowConfirmationPassword}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="d-grid px-5 py-3">
                      <button className="btn btn-warning" type="submit">
                        <span className="btn-font">Create Password</span>
                      </button>
                    </Form.Group>
                  </Form>
                </Card>
            </Col>
            <Col>
              <PublicCarousel data={carouselList}/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
   

     
    );
}
