import cx from 'classnames';
import { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { TextureAddToFavouriteApi } from '../../../../../api/textures';

import {
  changeTextureFilterValue,
  curatorStylesSelector,
  selectApplyTextureInProgress,
  selectSelectedTexture,
  toggleTextureFilterOpen,
} from '../../../../../redux/slicers/admin/curatorStylesSlicer';
import { textureSelector } from '../../../../../redux/slicers/textures/textureSlicer';
import { UploadTextureButton } from './UploadTextureButton';
import ThumbnailCardUI from '../../../../../common/ThumbnailCardUI';
import styles from '../stylesSection.module.scss';
import { SelectedFilterList } from '../../SelectedFilterList/SelectedFilterList';
import InfiniteScroll from '../../../../InfiniteScroll/InfiniteScroll';
import { CuratorFilter, FILTER } from '../../CuratorFilter/CuratorFilter';
import Loader from '../../../../Loader/Loader';
import SearchBar from '../../SearchBar/SearchBar';
import { useTextures } from './useTextures';
import { useUnityRedux } from '../useUnityRedux';
import { PopupButtons } from '../../components/lights/PopupButtons';
import { CuratorSidebarLayout } from '../../CuratorSidebarLayout/CuratorSidebarLayout';
import { useCuratorFilter } from './useCuratorFilter';
import {
  paginatedTexturesActions,
  paginatedTexturesSelector,
  paginatedTexturesSelectors,
} from '../../../../../redux/slicers/textures/paginatedTexturesSlicer';
import { CuratorGrid } from '../../CuratorGrid/CuratorGrid';

const filterConfig = [
  {
    items: [
      {
        label: 'My favorites',
        value: FILTER.MY_FAVOURITES,
      },
    ],
  },
  {
    title: 'Texture',
    items: [
      {
        label: 'My',
        value: FILTER.MY_TEXTURES,
        type: 'radioCheckbox',
        name: 'filterTexture',
      },
      {
        label: 'All memebers',
        value: FILTER.ALL_TEXTURES,
        type: 'radioCheckbox',
        name: 'filterTexture',
      },
    ],
  },
  {
    title: 'Uploads',
    items: [
      {
        label: 'My',
        value: FILTER.MY_UPLOADS,
        type: 'radioCheckbox',
        name: 'filterUploads',
      },
      {
        label: 'All uploads',
        value: FILTER.ALL_UPLOADS,
        type: 'radioCheckbox',
        name: 'filterUploads',
      },
    ],
  },
];

const TexturesTab = memo(({ active }) => {
  const unityRedux = useUnityRedux();
  const dispatch = useDispatch();
  const {
    filterOpen,
    filter,
    filterValue,
    toggleFilterOpen,
    handleFilterChange,
    handleFilterClear,
  } = useCuratorFilter({
    name: 'texture',
    actions: paginatedTexturesActions,
    filterSelector: paginatedTexturesSelector,
    additionalFilterList: filterConfig,
  });

  let {
    list: textureList,
    fetchMoreData,
    hasMore,
    loading,
    dataLoadedOnce,
    hasData,
    onSearchChagne,
  } = useTextures();

  const selectMapMode = useSelector((state) => curatorStylesSelector(state).selectMapMode);
  const isSelectMapMode = Boolean(selectMapMode);

  const selectedTexture = useSelector(selectSelectedTexture);

  useEffect(() => {
    // hide filters on switching tabs
    if (!active) {
      dispatch(toggleTextureFilterOpen(false));
    }
  }, [active, dispatch]);

  const [tempSelectedTexture, setTempSelectedTexture] = useState(null); // user needs to confirm this id before we store it in the store

  const applyTexture = (texture) => {
    unityRedux.applyTexture({ texture, switchToProperties: false });
  };

  const applyMap = () => {
    unityRedux.applyCustomMap(tempSelectedTexture);
    setTempSelectedTexture(null);
  };

  const cancelApplyMap = () => {
    setTempSelectedTexture(null);
    unityRedux.cancelMapSelection();
  };

  const handleTextureClick = (texture) => {
    if (isSelectMapMode) {
      setTempSelectedTexture(texture);
    } else {
      applyTexture(texture);
    }
  };

  const handleFavouriteChange = (id) => (e) => {
    //  TODO: work on useTextureHook
    const formData = new FormData();
    formData.append('is_favourite', e.target.checked);
    // TODO: add loader for favourites
    dispatch(TextureAddToFavouriteApi(formData, id));
  };

  const showNoDataMessage = dataLoadedOnce && !hasData;

  return (
    <>
      <div className={cx(!active && 'hide')}>
        <CuratorSidebarLayout.FixedHeader className={!isSelectMapMode && styles.searchBarContainer}>
          <div className={'d-flex gap-3 justify-content-center'}>
            <SearchBar
              filterActive={filterOpen}
              onChange={onSearchChagne}
              onFilterClick={toggleFilterOpen}
              placeholder="Search texture"
            />

            <UploadTextureButton />
          </div>
          <SelectedFilterList
            name="curatorTextures"
            className="mt-3"
            filter={filter}
            filterValue={filterValue}
            onDelete={handleFilterChange}
            onClear={handleFilterClear}
          />
        </CuratorSidebarLayout.FixedHeader>

        {/* <!-- Textures --> */}
        <div className={cx(`style-cards d-flex flex-wrap w-100 justify-content-center`, isSelectMapMode && 'style-cards-map-mode')}>
          <div className={cx(styles.cardContainer)}>
            {showNoDataMessage && !isSelectMapMode && <h4>No textures</h4>}
            {showNoDataMessage && isSelectMapMode && <h4>No maps found, please upload a map</h4>}
            <InfiniteScroll
              dataLength={textureList.length}
              next={fetchMoreData}
              className={styles.scrollableContent}
              hasMore={hasMore}
              loading={loading}
              scrollableTarget="styleSubmenu"
              Component={CuratorGrid}>
              {textureList.map((texture) => {
                const { id, name, is_favourite, thumbnail, images } = texture;
                const selected = selectMapMode
                  ? tempSelectedTexture?.id === id
                  : selectedTexture?.id === id;

                return (
                  <TextureCard
                    id={id}
                    key={id}
                    name={name}
                    isFavourite={is_favourite}
                    thumbnail={thumbnail}
                    images={images}
                    selectable={selected}
                    selected={selected}
                    onClick={() => handleTextureClick(texture)}
                    onFavouriteChange={handleFavouriteChange(id)}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      </div>

      {isSelectMapMode && (
        <PopupButtons
          onCancel={() => cancelApplyMap()}
          submitText="Apply"
          submitDisable={!tempSelectedTexture}
          onSubmit={() => applyMap()}
          fixed
        />
      )}

      <CuratorFilter
        open={filterOpen}
        onChange={handleFilterChange}
        filter={filter}
        value={filterValue}
        config={filterConfig}
      />
    </>
  );
});

export default TexturesTab;

const TextureCard = (props) => {
  const favouriteLoading = useSelector((state) =>
    paginatedTexturesSelectors.selectIsFavouriteLoading(state, props.id)
  );
  return <ThumbnailCardUI {...props} favouriteLoading={favouriteLoading} />;
};
