export const BrushIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7872 15.8525C11.6658 15.9411 11.5556 16.0136 11.4512 16.074C11.3799 16.8374 11.0504 17.5411 10.5082 18.0892C9.99258 18.5981 9.32964 18.9128 8.57963 19.0066L6.92561 19.1874C6.85195 19.1941 6.77829 19.2008 6.70463 19.2008C6.2024 19.2008 5.72025 18.9999 5.35865 18.6383C4.94347 18.2231 4.74257 17.6472 4.80954 17.0579L4.99034 15.4106C5.15281 13.8859 6.32982 12.7286 7.83584 12.5534C7.91702 12.4024 8.01239 12.264 8.11753 12.1426L9.14878 10.8435C10.9233 8.62701 14.5193 5.96183 17.151 4.90379C17.7068 4.68951 18.3028 4.81674 18.7113 5.21852C19.1332 5.6404 19.2604 6.24308 19.0394 6.79219C17.9814 9.43058 15.3229 13.0199 13.1064 14.7944L11.7872 15.8525ZM12.4836 14.0176L11.3557 14.9253C11.2141 14.39 10.9326 13.9064 10.5149 13.4887C10.0935 13.0673 9.58924 12.7723 9.02679 12.6248L9.92557 11.4797C10.0546 11.3189 10.1937 11.1557 10.3416 10.9909C11.7804 10.9913 12.9612 12.1391 13.0047 13.574C12.8289 13.7324 12.6549 13.8807 12.4836 14.0176ZM13.8818 12.7171C13.5401 11.4287 12.5128 10.4154 11.2164 10.0938C13.027 8.36809 15.5535 6.63795 17.5126 5.84799C17.7805 5.74754 17.9546 5.89486 18.0015 5.94174C18.0684 6.00201 18.2091 6.17612 18.1086 6.42388C17.3258 8.3888 15.6032 10.9102 13.8818 12.7171ZM5.98811 15.5244C6.11996 14.2719 7.16041 13.5839 8.15747 13.5437C8.17251 13.5439 8.18768 13.5435 8.20295 13.5423L8.20463 13.5423H8.29169H8.40798C8.44372 13.5441 8.47699 13.5465 8.50592 13.5489C8.98806 13.6092 9.43673 13.8368 9.79833 14.1985C10.1599 14.5601 10.3742 14.9752 10.4345 15.4507C10.4479 15.5578 10.4546 15.665 10.4546 15.7654L10.4546 15.7703C10.4516 16.382 10.2109 16.9534 9.78499 17.3793C9.43678 17.7276 8.97472 17.9419 8.4591 18.0088L6.81177 18.1896C6.53722 18.2231 6.26267 18.1227 6.06847 17.9285C5.87427 17.7276 5.77383 17.453 5.80731 17.1718L5.98811 15.5244Z"
        fill="currentColor"
      />
    </svg>
  );
};
