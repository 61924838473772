import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFullscreen } from '../hooks/useFullscreen';
import Portal from 'react-overlays/esm/Portal';
import { PreviewSidebar, PreviewDisplay } from './';

function Preview({
  title,
  actions,
  onClose,
  loading,
  updatedAt,
  userName,
  sidebarCards,
  theme,
  sidebarProps,
  previewProps,
  slides,
  activeIndex,
  setActiveIndex,
  passOptions,
  pass,
  setPass,
  memberDetails,
}) {
  const { projectId } = useParams();
  const [portalRef, setPortalRef] = useState();
  const { toggle, ref, isFullscreen } = useFullscreen();

  useEffect(() => {
    setPortalRef(document.querySelector('body'));
  }, []);

  const fs = actions.filter((x) => x.title === 'Full Screen')[0];
  fs.clickFunction = toggle;
  const newActions = [...actions.filter((x) => x.title !== 'Full Screen'), fs].sort(
    (a, b) => a.seq - b.seq
  );

  return (
    <Portal container={portalRef}>
      <div style={{ backgroundColor: '#f9f9f9' }} ref={ref}>
        <PreviewDisplay
          {...previewProps}
          theme={theme}
          actions={newActions}
          isFullscreen={isFullscreen}
          onClose={onClose}
          title={title}
          toggleFullscreen={toggle}
          slides={slides}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          passOptions={passOptions}
          pass={pass}
          setPass={setPass}
        />

        {!isFullscreen && (
          <PreviewSidebar
            {...sidebarProps}
            theme={theme}
            title={title}
            updatedAt={updatedAt}
            userName={userName}
            cards={sidebarCards}
            isFullscreen={isFullscreen}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            memberDetails={memberDetails}
          />
        )}
      </div>
    </Portal>
  );
}

export default Preview;
