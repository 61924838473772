import cx from 'classnames';
import Loader from '../Loader/Loader';
import styles from './InfiniteScroll.module.scss';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const InfiniteScroll = ({
  loading,
  containerClassName,
  id,
  dataLength,
  next,
  hasMore,
  className,
  children,
  Component = 'div',
}) => {
  const hasData = dataLength !== 0;
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: next,
    // disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  const loader = hasData ? (
    <Loader
      ref={sentryRef}
      loading={true}
      position="relative"
      backdrop={false}
      className={styles.loader}
    />
  ) : null;

  return (
    <Loader
      loading={hasData ? false : loading}
      position="relative"
      backdrop={false}
      className={cx(styles.container, containerClassName)}
      id={id}>
      <Component className={className}>{children}</Component>
      {(loading || hasMore) && loader}
    </Loader>
  );
};

export default InfiniteScroll;
