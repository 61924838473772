import {setNavPath, setTheme} from "../redux/slicers/activitySlicer";
import {setSession} from "../redux/slicers/sessionSlicer";
import {clearLocalStorage, getLocalStorage, setLocalStorage} from "./jsHelper";
import { getStore } from '../redux/store';
import {THEME} from "../config/constant/projectConstant";
import {SIGN_IN_ROUTE} from "../config/constant/routePathConstant";
import _ from "lodash";

export const isDev = () => {
    return process.env.NODE_ENV === 'development';
};

export const dispatcher = (a) => {
    getStore().dispatch(a);
};

export const themeSwitch = (a) => {
    dispatcher(setTheme(a))
    setLocalStorage(THEME, a)
};

export const getTheme = () => {
    return getLocalStorage(THEME)
}

export const sessionDestroy = (path = SIGN_IN_ROUTE) => {
    clearLocalStorage();
    dispatcher(setSession(false));
    navigate(path);
};


export const navigate = (path) => {
    dispatcher(setNavPath(path));
};

export const hasRenders = (data, activeIndex = null) => {
  return activeIndex ? data?.images[activeIndex]?.is_rendered : _.some(data?.images, image => image.is_rendered);
};