import cx from 'classnames';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { curatorActions } from '../../../../redux/slicers/admin/curatorSlicer';
import {
  selectActiveTab,
  selectSelectMapMode,
  setActiveTab,
  setTextureInfoOpen,
  TAB_NAME,
} from '../../../../redux/slicers/admin/curatorStylesSlicer';
import { Pagination } from '../../../Pagination/Pagination';
import { CuratorSidebarLayout } from '../CuratorSidebarLayout/CuratorSidebarLayout';
import ColorsTab from './ColorsTab/ColorsTab';

import MaterialTab from './MaterialTab';
import PropertiesTab from './PropertiesTab/PropertiesTab';
import TexturesTab from './TexturesTab/TexturesTab';

const StyleSection = memo(({ className, active }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectActiveTab);
  const selectMapMode = useSelector(selectSelectMapMode);
  const isSelectMapMode = Boolean(selectMapMode);

  const setTab = (tabName) => dispatch(setActiveTab(tabName));

  useEffect(() => {
    if (!active) {
      dispatch(curatorActions.toggleFilterOpen(null));
      dispatch(setTextureInfoOpen(false));
    }
  }, [active, dispatch]);

  useEffect(() => {
    dispatch(curatorActions.toggleFilterOpen(null));
  }, [activeTab]);

  return (
    <div className={cx('style-submenu', className)} id="styleSubmenu">
      {!isSelectMapMode && (
        <CuratorSidebarLayout.FixedHeader>
          <Pagination fullWidth className="mb-0">
            <Pagination.Item
              active={activeTab === TAB_NAME.TEXTURES}
              onClick={() => setTab(TAB_NAME.TEXTURES)}>
              Textures
            </Pagination.Item>
            <Pagination.Item
              active={activeTab === TAB_NAME.MATERIALS}
              onClick={() => setTab(TAB_NAME.MATERIALS)}>
              Materials
            </Pagination.Item>
            <Pagination.Item
              active={activeTab === TAB_NAME.COLORS}
              onClick={() => setTab(TAB_NAME.COLORS)}>
              Colors
            </Pagination.Item>
            <Pagination.Item
              active={activeTab === TAB_NAME.PROPERTIES}
              onClick={() => setTab(TAB_NAME.PROPERTIES)}>
              Properties
            </Pagination.Item>
          </Pagination>
        </CuratorSidebarLayout.FixedHeader>
      )}

      <TexturesTab active={activeTab === TAB_NAME.TEXTURES || isSelectMapMode} />
      <ColorsTab active={activeTab === TAB_NAME.COLORS && !isSelectMapMode} />
      <MaterialTab active={activeTab === TAB_NAME.MATERIALS && !isSelectMapMode} />
      {activeTab === TAB_NAME.PROPERTIES && !isSelectMapMode && (
        <PropertiesTab active={activeTab === TAB_NAME.PROPERTIES && !isSelectMapMode} />
      )}
    </div>
  );
});

export default StyleSection;
