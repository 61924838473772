import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLightListApi } from "../../../../../api/lights";
import { useUserContext } from "../../../../../contexts/UserContext";
import { useSimpleDataList } from "../../../../../hooks/useDataList";
import {
  curatorLightSelector,
  HDRI_POPUP,
  setPopupSelectedHDRI,
  setPreviewHDRI,
  toggleHdriPopup,
} from "../../../../../redux/slicers/admin/curatorLightSlicer";
import {
  paginatedLightActions,
  paginatedLightSelector,
} from "../../../../../redux/slicers/admin/paginatedLightSlicer";
import Button from "../../../../Button/Button";
import InfiniteScroll from "../../../../InfiniteScroll/InfiniteScroll";
import SearchBar from "../../SearchBar/SearchBar";
import { HDRIButtons } from "./HDRIButtons";
import { HDRIItem } from "./HDRIItem";
import styles from "./HDRIPopup.module.scss";
import { NoHDRI } from "./NoHDRI";
import { PopupButtons } from "./PopupButtons";
import { UploadHDRIButton } from "./UploadHDRIButton";
import { UploadHDRIDialog } from "./UploadHDRIDialog";
import { useUnityHDRI } from "./useUnityHDRI";

export const HDRIPopup = () => {
  const dispatch = useDispatch();
  const { currentUser } = useUserContext();
  const { selectHDRI } = useUnityHDRI();
  const hdriPopup = useSelector(
    (state) => curatorLightSelector(state).hdriPopup
  );

  const popupSelectedHDRI = useSelector(
    (state) => curatorLightSelector(state).popupSelectedHDRI
  );

  const isMyHdriOpen = hdriPopup === HDRI_POPUP.MY;

  const requestParams = useMemo(() => {
    return isMyHdriOpen 
      ? { created_by: currentUser.id }
      : { is_custom: false }
  }, [isMyHdriOpen])

  let {
    list: hdriList,
    fetchMoreData,
    hasMore,
    loading,
    hasData,
    dataLoadedOnce,
    onSearchChagne,
  } = useSimpleDataList({
    getAction: getLightListApi,
    resetAction: paginatedLightActions.softResetList,
    selector: paginatedLightSelector,
    requestParams,
  });

  // TODO: uncomment for debuggin
  // hdriList = []

  const closePopup = () => {
    dispatch(toggleHdriPopup());
  };

  const handleHDRIClick = (hdriItem) => () => {
    dispatch(setPopupSelectedHDRI(hdriItem));
    dispatch(setPreviewHDRI(hdriItem));
  };

  if (!hdriPopup) return null;

  return (
    <div className={styles.popup}>
      <h2 className="mb-3">Select Hdri</h2>
      <UploadHDRIDialog />

      <div className={styles.contentContainer}>
        <HDRIButtons />

        {dataLoadedOnce && hasData && (
          <div className="d-flex gap-3">
            <SearchBar
              className="mb-3"
              placeholder="Search HDRI"
              onChange={onSearchChagne}
            />
            {isMyHdriOpen && <UploadHDRIButton />}
          </div>
        )}

        {dataLoadedOnce && !hasData && isMyHdriOpen && <NoHDRI />}
        {dataLoadedOnce && !hasData && !isMyHdriOpen && 'No HDRI presets found'}

        {(!dataLoadedOnce || (dataLoadedOnce && hasData)) && (
          <InfiniteScroll
            dataLength={hdriList.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loading={loading}
            containerClassName={styles.scrollableContent}
            hasChildren={Boolean(hdriList.length)}
            scrollableTarget="layoutContentContainer"
          >
            {hdriList.map((hdriItem) => {
              return (
                <HDRIItem
                  key={hdriItem.id}
                  expanded={false}
                  hdriItem={hdriItem}
                  selected={popupSelectedHDRI?.id === hdriItem.id}
                  onClick={handleHDRIClick(hdriItem)}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>

      <PopupButtons
        onCancel={closePopup}
        submitText="Select"
        submitDisable={!popupSelectedHDRI}
        onSubmit={() => {
          selectHDRI(popupSelectedHDRI);
          closePopup();
        }}
      />
    </div>
  );
};
