export const HeadsetIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10.4445C5 6.5789 8.13444 3.5 12 3.5C15.8656 3.5 19 6.5789 19 10.4445V18.2222C19 18.4328 19.0077 18.6367 19.0151 18.8305C19.0529 19.8255 19.0806 20.5556 18 20.5556H12V19.5H18V18H15C14.4477 18 14 17.5523 14 17V13C14 12.4477 14.4477 12 15 12H18V10C18 6.99 15.01 4.5 12 4.5C8.99 4.5 6 6.99 6 10V12H9C9.55228 12 10 12.4477 10 13V17C10 17.5523 9.55228 18 9 18H7.33333C6.04222 18 5 17.18 5 15.8889V10.4445ZM9 13V17H7.5C6.675 17 6 16.55 6 16V13H9ZM18 13V17H15V13H18Z"
        fill="currentColor"
      />
    </svg>
  );
};
