export const InfoFilledIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40ZM20 29C21.1046 29 22 28.1941 22 27.2V18.2C22 17.2059 21.1046 16.4 20 16.4C18.8954 16.4 18 17.2059 18 18.2V27.2C18 28.1941 18.8954 29 20 29ZM20 11C18.8954 11 18 11.8059 18 12.8C18 13.7941 18.8954 14.6 20 14.6C21.1046 14.6 22 13.7941 22 12.8C22 11.8059 21.1046 11 20 11Z"
        fill="currentColor"
      />
    </svg>
  );
};
