export const FileSaveIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9808 10.487C11.9882 10.4874 11.9957 10.4875 12.0032 10.4875C12.0221 10.4875 12.0408 10.4865 12.0593 10.4844C12.1722 10.4717 12.2774 10.4208 12.3579 10.3403L13.6962 9.00203C13.8902 8.80798 13.8902 8.48679 13.6962 8.29274C13.5021 8.09869 13.1809 8.09869 12.9869 8.29274L12.5051 8.77452V5.30166C12.5051 5.02731 12.2776 4.7998 12.0032 4.7998C11.7289 4.7998 11.5014 5.02731 11.5014 5.30166V8.77485L11.0192 8.2927C10.8252 8.09865 10.504 8.09865 10.3099 8.2927C10.1159 8.48675 10.1159 8.80794 10.3099 9.00199L11.6266 10.3186C11.6604 10.3571 11.7003 10.3903 11.7452 10.4164C11.8184 10.4606 11.8993 10.4835 11.9808 10.487ZM13.1813 15.8407H10.8192C10.1166 15.8407 9.48758 15.4526 9.17308 14.8236L8.39018 13.2578C8.36341 13.1976 8.30319 13.1641 8.24297 13.1641H5.81366V14.0005C5.81366 17.0852 6.91106 18.1826 9.99581 18.1826H14.0107C17.0954 18.1826 18.1928 17.0852 18.1928 14.0005V13.1641H15.7775C15.7106 13.1641 15.6571 13.1976 15.6303 13.2578L14.8474 14.8236C14.5129 15.4526 13.8839 15.8407 13.1813 15.8407ZM18.1928 12.1604H15.7775C15.3292 12.1604 14.9277 12.408 14.727 12.8095L13.9441 14.3753C13.8036 14.663 13.5158 14.837 13.1946 14.837H10.8326C10.5114 14.837 10.2236 14.663 10.0831 14.3753L9.30022 12.8095C9.09947 12.408 8.69799 12.1604 8.24966 12.1604H5.81366V11.3239C5.81366 8.68079 6.63002 7.52986 8.73113 7.22206C9.00548 7.18191 9.19284 6.92763 9.15269 6.65329C9.11254 6.37894 8.86496 6.19158 8.58392 6.23172C5.97426 6.61314 4.80994 8.18563 4.80994 11.3239V12.5495C4.8014 12.5858 4.79688 12.6236 4.79688 12.6623C4.79688 12.7009 4.8014 12.7387 4.80994 12.775V14.0005C4.80994 17.6339 6.36236 19.1864 9.99581 19.1864H14.0107C17.6441 19.1864 19.1965 17.6339 19.1965 14.0005V12.6799C19.1968 12.674 19.1969 12.6682 19.1969 12.6623C19.1969 12.6563 19.1968 12.6505 19.1965 12.6446V11.3239C19.1965 8.18563 18.0322 6.61314 15.4226 6.23172C15.1482 6.19158 14.8939 6.37894 14.8538 6.65329C14.8137 6.92763 15.001 7.18191 15.2754 7.22206C17.3765 7.52986 18.1928 8.68079 18.1928 11.3239V12.1604Z"
        fill="currentColor"
      />
    </svg>
  );
};