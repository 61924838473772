import {CURATOR_DB} from "./projectConstants";

export const CURATOR_CONFIG = {
    name: CURATOR_DB,
    version: 1,
    objectStoresMeta: [
        {
            store: CURATOR_DB,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'product_id', keypath: 'product_id', options: { unique: false } },
                { name: 'elephants', keypath: 'elephants', options: { unique: false } },
                { name: 'name', keypath: 'name', options: { unique: false } },
                { name: 'thumbnail', keypath: 'thumbnail',options: { unique: false } },
                { name: 'product_file', keypath: 'product_file', options: { unique: true } },
                { name: 'product_json', keypath: 'product_json', options: { unique: false } },
            ]
        }
    ]
};

export const CURATOR_SETTING = {
    "resolutionPresent": [
        {
            "name": "Preset1",
            "isSelected": true,
            "width": 1000,
            "height": 1000
        },
        {
            "name": "Web Reso",
            "isSelected": false,
            "width": 1200,
            "height": 1400
        },
        {
            "name": "4k",
            "isSelected": false,
            "width": 1900,
            "height": 2200
        }
    ],
    "cameraSetting": {
        "verticalRotationSpeed": 30,
        "horizontalRotationSpeed": 50
    },
    "graphicQuality": [
        {
            "name": "Fantastic",
            "isSelected": false
        },
        {
            "name": "Good",
            "isSelected": false
        },
        {
            "name": "Performance",
            "isSelected": true
        }
    ],
    "measureUnit": [
        {
            "name": "inches",
            "isSelected": false
        },
        {
            "name": "feet",
            "isSelected": true
        },
        {
            "name": "meter",
            "isSelected": false
        },
        {
            "name": "cm",
            "isSelected": false
        }
    ],
    "metaData": [
        {
            "id": 3,
            "display_name": "Design",
            "key": "Design",
            "value": "Texture Name",
            "seq_no": 2,
            "is_render": true
        },
        {
            "id": 4,
            "display_name": "Part Name",
            "key": "Part Name",
            "value": "Object Name",
            "seq_no": 1,
            "is_render": true
        },
        {
            "id": 2,
            "display_name": "SKU ID",
            "key": "SKU ID",
            "value": "SKU ID",
            "seq_no": 3,
            "is_render": true
        },
        {
            "id": 1,
            "display_name": "Reapeat Size",
            "key": "Reapeat Size",
            "value": "Texture Dimention",
            "seq_no": 4,
            "is_render": true
        },
        {
            "id": 5,
            "display_name": "Offset",
            "key": "Offset",
            "value": "Texture Offset",
            "seq_no": 5,
            "is_render": true
        },
        {
            "id": 6,
            "display_name": "Rotation",
            "key": "Rotation",
            "value": "Texture Rotation",
            "seq_no": 6,
            "is_render": true
        },
        {
            "id": 7,
            "display_name": "Content",
            "key": "Content",
            "value": "Texture Descripton",
            "seq_no": 7,
            "is_render": true
        }
    ]
}