import React from "react";
import { Button, Image } from "react-bootstrap";

// TODO create new styles for buttons (Ask Vishal)

function LFButton({ icon, text, btnProps }) {
  return <Button {...btnProps}>{icon && <Image src={icon} />} {text}</Button>;
}

export default LFButton;
