import _ from 'lodash';
import { Download, AddToCollections, SharePopup, DeactivatePopup } from '../common';
import {
  EDIT_ICON,
  SHARE_ICON,
  DELETE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
  ARCHIVE,
} from '../constants/assetConstants';
import {
  PROJECT_TYPE,
  Filter_By_Value,
  Filter_By_Label,
  Sort_By_Value,
  Sort_By_Label,
  Room_Template_Label,
  Lifestyle_Label,
  Silo_Label,
  Vignette_Label,
  Web_Viewer_360_Label,
  Video_Label,
  Configurator_Label,
  Augmented_Reality_Label,
  Recent_First_Value,
  Oldest_First_Value,
  A_Z_Value,
  Z_A_Value,
  Recent_First_Label,
  Oldest_First_Label,
  A_Z_Label,
  Z_A_Label,
  My_Projects_Label,
  My_Projects_Value,
  Previews_Label,
  Previews_Value,
  Renders_Label,
  Renders_Value,
  Models_3d_Label,
  Models_3d_Value,
  Uploads_Label,
  Uploads_Value,
  Collections_Label,
  Collections_Value,
  Textures_Label,
  Textures_Value,
  Props_Label,
  Props_Value,
  Templates_Label,
  Templates_Value,
  Rooms_Label,
  Rooms_Value,
  Requests_Value,
  Requests_Label,
  Shared_Files_Value,
  Shared_Files_Label,
} from '../constants/filters';
import { MODULE_TYPE_OPTIONS } from '../constants/constants';

// Filter Options
const filterByOption = { label: Filter_By_Label, value: Filter_By_Value };
const roomFilter = { label: Room_Template_Label, value: PROJECT_TYPE.ROOM };
const lifestyleFilter = {
  label: Lifestyle_Label,
  value: PROJECT_TYPE.LIFESTYLE,
};
const siloFilter = { label: Silo_Label, value: PROJECT_TYPE.SILO };
const vignetteFilter = { label: Vignette_Label, value: PROJECT_TYPE.VIGNETTE };
const threeSixtyFilter = {
  label: Web_Viewer_360_Label,
  value: PROJECT_TYPE.THREESIXTY,
};
const videoFilter = { label: Video_Label, value: PROJECT_TYPE.VIDEO };
const configuratorFilter = {
  label: Configurator_Label,
  value: PROJECT_TYPE.CONFIGURATOR,
};
const myProjectsFilter = { label: My_Projects_Label, value: MODULE_TYPE_OPTIONS.PROJECT };
const previewsFilter = { label: Previews_Label, value: MODULE_TYPE_OPTIONS.PREVIEWS };
const rendersFilter = { label: Renders_Label, value: MODULE_TYPE_OPTIONS.RENDERS };
const models3DFilter = { label: Models_3d_Label, value: MODULE_TYPE_OPTIONS.THREEDMODELS };
const uploadsFilter = { label: Uploads_Label, value: MODULE_TYPE_OPTIONS.UPLOADS };
const collectionsFilter = {
  label: Collections_Label,
  value: Collections_Value,
};
const texturesFilter = { label: Textures_Label, value: MODULE_TYPE_OPTIONS.TEXTURES };
const propsFilter = { label: Props_Label, value: MODULE_TYPE_OPTIONS.PROPS };
const templatesFilter = { label: Templates_Label, value: MODULE_TYPE_OPTIONS.TEMPLATES };
const roomsFilter = { label: Rooms_Label, value: MODULE_TYPE_OPTIONS.ROOMS };
const requestsFilter = { label: Requests_Label, value: Requests_Value };
const shareFilesFilter = {
  label: Shared_Files_Label,
  value: Shared_Files_Value,
};
const materialFilter = { label: Rooms_Label, value: MODULE_TYPE_OPTIONS.MATERIAL }; // TODO
const servicesFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO
const newFeatureFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO
const fromLiveFurnishFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO
const archivedFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO
const createdFromAppFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO

// sort Options
const SortByOption = { label: Sort_By_Label, value: Sort_By_Value };
const SortRecentFirst = {
  label: Recent_First_Label,
  value: Recent_First_Value,
};
const SortOldestFirst = {
  label: Oldest_First_Label,
  value: Oldest_First_Value,
};
const SortAZ = { label: A_Z_Label, value: A_Z_Value };
const SortZA = { label: Z_A_Label, value: Z_A_Value };
const IncompleteFirst = { label: Z_A_Label, value: Z_A_Value }; // TODO
const GroupedSort = { label: Rooms_Label, value: Rooms_Value }; // TODO
const UngroupedSort = { label: Rooms_Label, value: Rooms_Value }; // TODO

const COMMON_FILTERS = [filterByOption, roomFilter, lifestyleFilter, siloFilter];

export const COMMON_SORT_OPTIONS = [SortByOption, SortRecentFirst, SortOldestFirst, SortAZ, SortZA];

// delete the card
// need to refactor api endpoints to be passed dynamically here
const handleCardDelAction = (e, id, endpoint) => {
  e.preventDefault();
  // if (cardType === "rooms") {
  //   // roomDeleteApi(id);
  // } else if (cardType === "projects" || cardType === "props") {
  //   // projectDeleteApi(id);
  // }
};

const onFullScreenClick = () => {
  
}

const SHARED_FILES_FILTER = [
  filterByOption,
  rendersFilter,
  previewsFilter,
  myProjectsFilter,
  models3DFilter,
  roomsFilter,
  texturesFilter,
  uploadsFilter,
  collectionsFilter,
  templatesFilter,
  propsFilter,
];

const COLLECTIONS_FILTER = [
  filterByOption,
  rendersFilter,
  previewsFilter,
  myProjectsFilter,
  models3DFilter,
  roomsFilter,
  texturesFilter,
  uploadsFilter,
  templatesFilter,
  propsFilter,
];

export const getScreenSettings = (cardType) => {
  const isTexture = cardType === 'texture';
  const isProject = cardType === 'projects';
  const target = isTexture || isProject ? '_self' : '_blank';
  /// need to implement these targets where necessary
  const pageSettings = {
    projects: {
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: COMMON_FILTERS,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    rooms: {     
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: COMMON_FILTERS,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    // rooms: {     
    //   sortOptions: COMMON_SORT_OPTIONS,
    //   filterOptions: COMMON_FILTERS,
    //   showFavoriteIcon: true,
    //   showTeamMemberFilter: false,
    // },
    templates: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
      //   {
      //     element: AddToCollections,
      //     miniButton: true,
      //     iconSrc: COLLECTION_ICON,
      //   },
      // ],
      // topButtonList: [
      //   {
      //     element: AddToCollections,
      //     bigButton: true,
      //     title: 'Edit',
      //     iconSrc: DOWNLOAD_ICON,
      //   },
      //   {
      //     element: SharePopup,
      //     bigButton: true,
      //   },
      // ],
      sortOptions: COMMON_SORT_OPTIONS,
      subSortOptions: [createdFromAppFilter],
      filterOptions: COMMON_FILTERS, // NEED TO ADD Created From App
      showFavoriteIcon: true,
      showTeamMemberFilter: false,
    },
    props: {
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: [],
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    products: {
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: [], // hide this
      clickUrl: `/products/3dmodel/product`,
      showFavoriteIcon: true,
      showTeamMemberFilter: false,
    },
    model: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   {
      //     element: MiniButton,
      //     title: 'Edit',
      //     iconSrc: EDIT_ICON,
      //   },
      //   { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
      //   {
      //     element: AddToCollections,
      //     miniButton: true,
      //     iconSrc: COLLECTION_ICON,
      //   },
      // ],
      // topButtonList: [{ button: downloadButton(id, cardType, false) }],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: [], // hide this
      clickUrl: `/products/3dmodel/product`,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    texture: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   {
      //     element: Download,
      //     miniButton: true,
      //     title: 'Edit',
      //     iconSrc: DOWNLOAD_ICON,
      //   },
      //   {
      //     element: MiniButton,
      //     title: 'Edit',
      //     iconSrc: EDIT_ICON,
      //   },
      //   { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
      //   {
      //     element: AddToCollections,
      //     miniButton: true,
      //     iconSrc: COLLECTION_ICON,
      //   },
      //   { element: DeactivatePopup, miniButton: true, item: cardType, onSuccess: refreshList },
      // ],
      // topButtonList: [{ button: <Download /> }],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: [],
      clickUrl: `/products/textures`,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    productroom: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   // TODO: ADD REnder Button here
      //   {
      //     element: MiniButton,
      //     title: 'Edit',
      //     iconSrc: EDIT_ICON,
      //   },
      //   { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
      //   {
      //     element: AddToCollections,
      //     miniButton: true,
      //     iconSrc: COLLECTION_ICON,
      //   },
      //   { element: DeactivatePopup, miniButton: true, item: cardType, onSuccess: refreshList },
      // ],
      // topButtonList: [{ button: <Download /> }],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: COMMON_FILTERS,
      clickUrl: `/products/rooms/preview`,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    sharedFiles: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   // TODO: ADD REnder Button here
      //   {
      //     element: MiniButton,
      //     title: 'Edit',
      //     iconSrc: EDIT_ICON,
      //   },
      //   { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
      //   {
      //     element: AddToCollections,
      //     miniButton: true,
      //     iconSrc: COLLECTION_ICON,
      //   },
      //   { element: DeactivatePopup, miniButton: true, item: cardType, onSuccess: refreshList },
      // ],
      // topButtonList: [
      //   {
      //     element: SharePopup,
      //     bigButton: true,
      //   },
      // ],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: SHARED_FILES_FILTER,
      clickUrl: `/products/rooms/preview`,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    [`renders-previews`]: {
      sortOptions: COMMON_SORT_OPTIONS,
      subSortOptions: [GroupedSort, UngroupedSort],
      filterOptions: [filterByOption, rendersFilter, previewsFilter, uploadsFilter],
      filterSubOptions: [archivedFilter],
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    uploads: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   {
      //     element: Download,
      //     miniButton: true,
      //     title: 'Download',
      //     iconSrc: DOWNLOAD_ICON,
      //   },
      //   { element: DeactivatePopup, miniButton: true, item: cardType, onSuccess: refreshList },
      // ],
      // topButtonList: [{ button: <Download /> }],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: SHARED_FILES_FILTER,
      clickUrl: ``, // to do
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    notifications: {
      // move these to the actual screen file now instead
      // cardButtonList: [],
      // topButtonList: [],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: [
        filterByOption,
        rendersFilter,
        previewsFilter,
        requestsFilter,
        shareFilesFilter,
        fromLiveFurnishFilter,
      ],
      clickUrl: ``, // to do
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    requests: {
      // move these to the actual screen file now instead
      // cardButtonList: [],
      // topButtonList: [],
      sortOptions: _.concat(COMMON_SORT_OPTIONS, [IncompleteFirst]),
      filterOptions: [
        filterByOption,
        models3DFilter,
        roomFilter,
        materialFilter,
        servicesFilter,
        newFeatureFilter,
      ],
      clickUrl: ``, // to do
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    collections: {
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    collection: {
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: COLLECTIONS_FILTER,
    },
    teams: {
      showTeamMemberFilter: false,
      showFavoriteIcon: false,
    },
    trash: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   {
      //     element: Download,
      //     miniButton: true,
      //     title: 'Download',
      //     iconSrc: DOWNLOAD_ICON,
      //   },
      //   { element: DeactivatePopup, miniButton: true, item: cardType, onSuccess: refreshList },
      //   // {
      //   //   button: (
      //   //     <MiniButton
      //   //       miniButton={true}
      //   //       cardType={cardType}
      //   //       title="Remove forever"
      //   //       iconSrc={DELETE_ICON}
      //   //       buttonLabel="Remove forever"
      //   //     />
      //   //   ),
      //   // },
      // ],
      // topButtonList: [
      //   {
      //     element: DeactivatePopup,
      //     bigButton: true,
      //     iconSrc: DELETE_ICON,
      //     title: 'Empty Trash',
      //     item: cardType,
      //   },
      // ],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: [
        myProjectsFilter,
        previewsFilter,
        texturesFilter,
        uploadsFilter,
        collectionsFilter,
      ],
      clickUrl: ``, // to do
      showTeamMemberFilter: true,
    },
  };
  return pageSettings[cardType];
};
