const StyleIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6505 18.4195C11.4481 18.5672 11.2645 18.688 11.0905 18.7887C10.9718 20.061 10.4225 21.2338 9.51889 22.1473C8.65951 22.9955 7.5546 23.5201 6.3046 23.6763L3.5479 23.9777C3.42513 23.9888 3.30236 24 3.17959 24C2.34254 24 1.53896 23.6652 0.936284 23.0625C0.244319 22.3705 -0.0905032 21.4107 0.0211041 20.4286L0.322444 17.683C0.593218 15.1419 2.55491 13.213 5.06495 12.9211C5.20024 12.6693 5.35919 12.4386 5.53442 12.2364L7.25317 10.0713C10.2108 6.37705 16.2041 1.93508 20.5903 0.171683C21.5166 -0.18546 22.5099 0.0265938 23.1907 0.696238C23.8938 1.39936 24.1059 2.40383 23.7376 3.31901C21.9742 7.71634 17.5434 13.6985 13.8492 16.6561L11.6505 18.4195ZM12.8112 15.3614L10.9315 16.8742C10.6953 15.982 10.2262 15.176 9.53005 14.4799C8.82768 13.7775 7.98728 13.2858 7.04986 13.04L8.54782 11.1315C8.76284 10.8636 8.99463 10.5916 9.24121 10.3169C11.6392 10.3176 13.6072 12.2305 13.6798 14.622C13.3868 14.886 13.0967 15.1333 12.8112 15.3614ZM15.1416 13.1938C14.5721 11.0465 12.8599 9.35764 10.6993 8.8217C13.7169 5.94552 17.9278 3.06195 21.1929 1.74535C21.6394 1.57794 21.9295 1.82347 22.0077 1.9016C22.1193 2.00204 22.3536 2.29222 22.1862 2.70517C20.8815 5.98003 18.0105 10.1823 15.1416 13.1938ZM1.98539 17.8728C2.20514 15.7851 3.93925 14.6386 5.60103 14.5715C5.62606 14.5719 5.65132 14.5711 5.67676 14.5692L5.6796 14.5692H5.82469H6.01855C6.07809 14.5722 6.13353 14.5762 6.18174 14.5802C6.98531 14.6806 7.73308 15.0601 8.33576 15.6628C8.93844 16.2655 9.29559 16.9574 9.39603 17.7498C9.41835 17.9284 9.42952 18.107 9.42952 18.2744L9.42955 18.2825C9.4246 19.3021 9.02338 20.2544 8.31353 20.9643C7.73317 21.5446 6.96308 21.9018 6.1037 22.0134L3.35816 22.3147C2.90057 22.3705 2.44298 22.2031 2.11932 21.8795C1.79566 21.5446 1.62825 21.087 1.68405 20.6183L1.98539 17.8728Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StyleIcon;
