// this is thumbnail component for product, room, projects, props, etc
import React, { useState, useEffect, useCallback, useRef } from 'react';
import 'react-stacked-carousel/dist/index.css';
import { Card } from 'react-bootstrap';
import cx from 'classnames';
import stackedCards from '../assets/images/stackedCards.png';
// need to move to scss directory
// import './_thumbnailCard.scss';
import useToggle from 'react-use/lib/useToggle';
import { Image } from './';
// clean up to use new icons index.js
import { NOT_FAVOURITE_ICON, FAVOURITE_ICON, ACTION_DOTS_ICON } from '../constants/assetConstants';
import constants, {
  MODULE_TYPES,
  GET_MODULE_KEY_BY_ID,
  MODULE_TYPE_OPTIONS,
  collectionFriendlyTitle,
} from '../constants/constants';
import { useNavigate } from 'react-router-dom';
import { paginationApi } from '../api/globalapi';
import { apiToggleFavorite } from '../helpers/api';
import pluralize from 'pluralize';
import moment from 'moment';
import _ from 'lodash';
import { successToast } from '../helpers/toastHelper';
const { RENDER_STATUS } = constants;

export default function ThumbnailCard(props) {
  const navigate = useNavigate();
  let {
    thumbnailData,
    cardType,
    last = {},
    arr = [],
    id,
    cardButtonList,
    clickUrl,
    showFavoriteIcon,
  } = props;
  const {
    thumbnail,
    name,
    tag,
    created_at,
    created_by,
    is_favourite,
    images,
    selected,
    owner_organization_detail,
    subtitle,
    data,
  } = thumbnailData;

  const [isFavourite, setIsFavourite] = useState(is_favourite);
  const [actionsOpen, toggleActionsOpen] = useToggle(false);

  let collectionCardType = null;
  if (cardType === MODULE_TYPES.COLLECTION) {
    collectionCardType = collectionFriendlyTitle(thumbnailData.model_type);
  }

  useEffect(() => {
    if (is_favourite === true) {
      setIsFavourite(true);
    } else {
      setIsFavourite(false);
    }
  }, [is_favourite]);

  const handleCardClick = () => {
    if (clickUrl) {
      if (props.newTab) {
        window.open(clickUrl, '_blank');
      } else {
        navigate(clickUrl, { state: thumbnailData });
      }
    }
    if (_.isFunction(props.onClick)) {
      props.onClick(id);
    }
  };

  const observer = useRef();
  const lastele = useCallback((node) => {
    let loading;
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        let link = arr?.next_link;
        if (link) {
          paginationApi(link, cardType, arr);
        }
      }
    });
    if (node) observer.current.observe(node);
  });

  const handleFavourite = () => {
    let newValue = !isFavourite;
    let favoriteItemType = cardType;
    let favoriteItemId = id;
    if (collectionCardType) {
      favoriteItemType = GET_MODULE_KEY_BY_ID(thumbnailData?.model_type);
      favoriteItemId = thumbnailData?.data?.id;
    }
    if (favoriteItemType) {
      apiToggleFavorite(favoriteItemId, newValue, favoriteItemType).then((res) => {
        if (res) {
          setIsFavourite(newValue);
          successToast(
            `This ${pluralize.singular(favoriteItemType)} has been ${
              newValue ? 'favorited' : 'unfavorited'
            }.`
          );
        }
      });
    } else {
      console.log('A MISSING MODEL NEEDS TO BE ADDED TO FAVORITES... ');
    }
  };

  const preventPropagation = (e) => {
    e.stopPropagation();
  };
  // TODO: handle case with no image
  const thumbnailSrc = thumbnail || images?.[0]?.thumbnail || '';

  return (
    <Card
      className={cx('thumbnail-card mb-0', props.fullWidth && ' fullWidth', props.className)}
      id={`thumbnail-card-${id}`}
      ref={last.id === id ? lastele : null}>
      <div role="button" onClick={handleCardClick} className="thumbnail-card-top-section">
        <div className="thumbnail-img">
          <div
            className={cx(
              'thumbnail-action',
              selected && 'thumbnail-action--selected',
              isFavourite && 'thumbnail-action--favourite'
            )}>
            <ul className="action-list">
              <li className="thumbnail-action-checkbox">
                {_.isFunction(props.onSelect) && (
                  <>
                    <input
                      type="checkbox"
                      name="selectCard"
                      id={`selectCard-${id}`}
                      className="selectCard"
                      value={id}
                      onChange={() => props.onSelect(id)}
                      checked={selected || false}
                      onClick={preventPropagation}
                    />
                    <label htmlFor={`selectCard-${id}`} onClick={preventPropagation}></label>
                  </>
                )}
              </li>
              {showFavoriteIcon && (
                <li className="thumbnail-action-favourite">
                  <input
                    type="checkbox"
                    name="favouriteCard"
                    id={`favouriteCard-${id}`}
                    className="favouriteCard"
                    onChange={handleFavourite}
                    checked={isFavourite}
                    onClick={preventPropagation}
                  />
                  <label htmlFor={`favouriteCard-${id}`} onClick={preventPropagation}>
                    <img
                      src={isFavourite ? FAVOURITE_ICON : NOT_FAVOURITE_ICON}
                      width="13px"
                      alt="favourite"
                    />
                  </label>
                </li>
              )}
            </ul>
          </div>
          {/* {props.stackedCardImages ? (
            <div className="p-3 pt-5 stackedCarousel">
              <div className="collectionCardCount pe-5"><h4>{props.stackedCardImages.length} items</h4></div>
              {_.map(_.take(props.stackedCardImages, 4), (img, idx) => {
                if (idx < 1) return null;
                const itemValue = (idx - 1) * 3.25;
                const height = (idx + 12) * 0.95;
                const leftValue = itemValue > 2 ? itemValue - 2 : itemValue
                const bottomValue = itemValue > 2 ? itemValue + 7 : itemValue
                return (
                  <div
                    key={`child${idx}${img.id}`}
                    style={{
                      position: 'relative',
                      bottom: `${bottomValue}em`,
                      left: `${leftValue}em`,
                      zIndex: itemValue,
                      // padding: `${itemValue}em`,
                      height: `${height}em`,
                      width: `${height}em`,
                    }}
                    className={`greyCard${idx % 3}`}>
                    
                    <Image
                        clickLink={clickUrl}
                        onClick={handleCardClick}
                        src={testPath}
                        placeholderWhileLoading
                        ratio={1.259}
                      />
                  </div>
                );
              })}
            </div>
          ) : ( */}
          <Image
            src={props.stackedCardImages ? stackedCards : thumbnailSrc}
            placeholderWhileLoading
            ratio={1.259}
          />
          {/* )} */}
          {props.stackedCardImages && (
            <div
              className="itemCount"
              style={{
                userSelect: 'none',
                cursor: 'pointer',
              }}>{`${thumbnailData.item_count} items`}</div>
          )}
        </div>
      </div>
      {/* TO DO need to add similar checks on status types for requests whenever Aakash provides me with those reference lists */}
      <Card.Body
        className={cx('thumbnail-info render-status', {
          // bottomStatusBarBlue: selected, // Removed per clickup ticket. Personally I liked this feature! -fonz
          bottomStatusBarRed:
            thumbnailData.status && thumbnailData.status === RENDER_STATUS.STATUS_FAILED,
          bottomStatusBarGreen:
            thumbnailData.status && thumbnailData.status === RENDER_STATUS.STATUS_DONE,
        })}>
        <Card.Title className="inner-info">
          <h2 className="thumbnail-title">{name}</h2>
          {collectionCardType ? (
            <h5 className="thumbnail-etime fontSize85">
              <i>{collectionCardType}</i>
            </h5>
          ) : (
            <>
              {created_at && (
                <h5 className="thumbnail-etime mt-1 d-flex justify-content-between align-items-end pe-2">
                  <div className="d-flex align-items-end">
                    {data?.profile_image ? (
                      <img className="card-profile-thumbnail me-2" src={data.profile_image} />
                    ) : null}
                    <span>{`${moment.utc(created_at).local().fromNow()}`}</span>
                  </div>
                  {thumbnailData.status >= 0 && cardType === 'renders-previews' ? (
                    <span>Status: {thumbnailData.status}/5</span>
                  ) : null}
                </h5>
              )}
              {owner_organization_detail && (
                <h5 className="thumbnail-etime fontSize85">
                  <i>{owner_organization_detail.name}</i>
                </h5>
              )}
              {subtitle && (
                <h5 className="thumbnail-etime fontSize85">
                  <i>{subtitle}</i>
                </h5>
              )}
            </>
          )}
        </Card.Title>
        {cardButtonList && cardButtonList.length > 0 && (
          <div className="inner-action">
            <div className="card-action" onClick={() => toggleActionsOpen()}>
              <img src={ACTION_DOTS_ICON} alt="more" />
            </div>
            {actionsOpen ? (
              <ul className={cx(props.buttonAreaClass, 'action-list ced-action')}>
                {_.map(cardButtonList, (item, index) => {
                  const { element, ...cardProps } = item;
                  const Component = element;
                  return (
                    <Component
                      key={`cardElement${_.startCase(cardType)}${id}${index}}`}
                      id={id}
                      {...cardProps}
                      selectedList={selected}
                      cardType={cardType}
                    />
                  );
                })}
              </ul>
            ) : null}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
