import { createSlice } from "@reduxjs/toolkit";

const material = createSlice({
  name: "material",
  initialState: {
    materialData: {},
  },
  reducers: {
    setMaterialData: (state, { payload }) => {
      state.materialData = payload;
    },
  },
});

export const { setMaterialData } = material.actions;
export const materialSelector = (state) => state.material;
export const materialReducer = material.reducer;
export default materialReducer;
