import {createSlice} from "@reduxjs/toolkit";

const ui = createSlice({
    name: 'ui',
    initialState: {
      rightOffcanvasOpen: false,   
    },
    reducers: {
        setRightOffcanvasOpen: (state, {payload}) => {
            state.rightOffcanvasOpen = payload;
        },
    }
})

export const { setRightOffcanvasOpen } = ui.actions;
export const uiSelector = state => state.ui;
export const uiReducer = ui.reducer;
export default uiReducer