import { Form, FormControl, Image } from 'react-bootstrap';
import cx from 'classnames';
import _ from 'lodash';
import { SEARCH_ICON } from '../constants/assetConstants';

export default function SearchInput(props) {
  function handleSearchQuery(e) {
    if (_.isFunction(props.onSubmit)) {
      props.onSubmit(e);
    }
  }
  return (
    <Form className={cx(props.className, 'd-block search-form')} onSubmit={handleSearchQuery}>
      <Form.Group className="search-form-group">
        <Form.Label className="search-icon">
          <Image src={SEARCH_ICON} />
        </Form.Label>
        <FormControl
          type="search"
          value={props.value}
          placeholder={props.placeholder || 'Search'}
          onChange={props.onChange}
        />
      </Form.Group>
    </Form>
  );
}
