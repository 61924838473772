/* eslint-disable no-debugger */
import { apiGetLight } from "../../../../helpers/api";
import { curatorLightActions, ENVIRONMENT_LIGHT, setDefaultEnvironmentLightData } from "../../../../redux/slicers/admin/curatorLightSlicer";

export const parseHDRIData = async ({
  defaultHDRIData,
  selectedHDRIData,
  hdriFile,
  hdriFileThumbnail,
  hdriFileName,
  dispatch,
}) => {
  let hdriConfig = {};
  let colorConfig = {};

  console.log('TODO: sasha set loading here and show it in light environment tab');

  if (selectedHDRIData) {
    const { hdri, color } = selectedHDRIData;

    if (hdri) {
      hdriConfig.enabled = Boolean(hdri.ishdriEnabled);
      hdriConfig.intensity = parseInt(hdri.hdriIntensity);
      hdriConfig.rotation = parseInt(hdri.hdriRotation);

      if (hdri.hdriId) {
        try {
          const hdriData = await apiGetLight({ id: hdri.hdriId });
          hdriConfig.data = hdriData;
        } catch (error) {
          console.log('TODO: sasha save error in store and show it to the user', error);
        }
      }
    }

    if (color) {
      colorConfig.enabled = color.isEnvironmentColorEnabled;
      colorConfig.intensity = parseInt(color.environmentIntensity);
      colorConfig.color = color.environmentColor;
    }
  }

  // fill all missing fields with default data
  if (defaultHDRIData) {
    const { hdri, color } = defaultHDRIData;
    const hdr = {};
    const c = {};

    const defaultHDRI = {
      file: hdriFile, // 'https://cdn.pixabay.com/photo/2019/07/30/18/26/surface-4373559__480.jpg', // hdriFile, // TODO: add file here
      thumbnail: hdriFileThumbnail, // 'https://cdn.pixabay.com/photo/2019/07/30/18/26/surface-4373559__480.jpg', // hdriFile, // TODO: add file here
      id: 'DEFAULT',
      name: hdriFileName,
    };

    const fallback = (obj, key, fallback1, fallback2) => {
      return typeof obj[key] === 'undefined' ? fallback1 || fallback2 : obj[key];
    };

    hdr.enabled = fallback(hdr, 'enabled', hdri.ishdriEnabled, false);
    hdr.intensity = fallback(hdr, 'intensity', parseInt(hdri.hdriIntensity), 1);
    hdr.rotation = fallback(hdr, 'rotation', parseInt(hdri.hdriRotation), 0);
    hdr.data = fallback(hdr, 'data', defaultHDRI, null);

    // handle default color data
    c.enabled = fallback(c, 'enabled', color.isEnvironmentColorEnabled, false);
    c.intensity = fallback(c, 'intensity', parseInt(color.environmentIntensity), 1);
    c.color = fallback(
      c,
      'color',
      typeof color.environmentColor === 'string' ? color.environmentColor : undefined,
      '#fff'
    );

    // assign default data to configs
    hdriConfig = { ...hdr, ...hdriConfig };
    colorConfig = { ...c, ...colorConfig };

    // save default data to store to use it later for reset button
    dispatch(
      setDefaultEnvironmentLightData({
        hdri: hdr,
        color: c,
      })
    );
  }

  // store hdri data in redux store
  dispatch(curatorLightActions.setHDRIIntensity(hdriConfig.intensity));
  dispatch(curatorLightActions.setHDRIRotation(hdriConfig.rotation));
  if (hdriConfig.data?.file) {
    dispatch(curatorLightActions.setSelectedHDRI(hdriConfig.data));
  }

  if (hdriConfig.enabled) {
    dispatch(curatorLightActions.setEnvironmentLightType(ENVIRONMENT_LIGHT.HDRI));
  }

  // store color data in redux store
  dispatch(curatorLightActions.setEnvironmentColorIntensity(colorConfig.intensity));
  dispatch(curatorLightActions.setEnvironmentColor(colorConfig.color));

  if (colorConfig.enabled) {
    dispatch(curatorLightActions.setEnvironmentLightType(ENVIRONMENT_LIGHT.COLOR));
  }
};