import { v4 as uuidv4 } from 'uuid'
import { propsV1ReadApi } from "../../../../api/curator";
import { apiGetMaterials } from "../../../../helpers/api";
import { updateDbMaterial } from "../../../../helpers/idb";
import { parseArchiveProjectTextures } from "./parseArchiveProjectTextures";
import { simplifyPropData } from "./simplifyPropData";

export const parseArchiveV1 = async ({ data, dispatch, projectJson, entries, roomId }) => {
  const propIdList = Object.values(data.blender_assetbundles_data).map((prop) => prop.id);
  let propList = await Promise.all(
    propIdList.map(async (id) => {
      const response = await propsV1ReadApi([id]);
      console.log('sasha response');
      return response?.results?.[0];
    })
  );

  propList = propList.filter(Boolean);

  // console.log('sashas propList', propList);

  // uncomment it after API is fixed
  // if (propList.results.length !== propIdList) {
  //   toast.error('Error happened while getting project props')
  //   throw new Error('Unable to fetch all props')
  // }

  // const simplifiedPropList = await Promise.all(
  //   propList.map(async (prop) => ({
  //     oldId: prop.old_product_id,
  //     simplified: await simplifyPropData(prop),
  //   }))
  // );

  const unityPropList = Object.values(data.blender_assetbundles_data)
    .map((d) => {
      // const simplifiedData = simplifiedPropList.find((p) => p.oldId === d.id)?.simplified;

      // if (!simplifiedData) return;

      return {
        // ...simplifiedPropList.find((p) => p.oldId === d.id)?.simplified,
        id: propList.find(p => p.old_product_id === d.id)?.id,
        object_name: d.object_name,
        indexName: d.object_index,
        xPosition: 0,
        yPosition: 0,
        zPosition: 0,
        xRotation: 0,
        yRotation: 0,
        zRotation: 0,
        xScale: 1,
        yScale: 1,
        zScale: 1,
        oldPropId: d.id,
        type: 'ZipProp',
        zipVersion: 1,
      };
    })
    .filter(Boolean);

  // parse light data
  let lightData = data.LightData ? Object.values(data.LightData) : [];
  lightData = lightData.map((light) => {
    light.lightName = light.lightname;
    delete light.lightname;

    light.lightDetails = light.saveAllFeildsData;
    delete light.saveAllFeildsData;

    light.is_portal = false;

    return light;
  });

  // parse image assets
  const imagesMap = await parseArchiveProjectTextures({
    entries,
    projectJson,
    projectJsonVersion: 1,
  })

  console.log('imagesMap', imagesMap);

  const getFileExtension = (str) => str.match(/\.[0-9a-z]+$/i)[0];

  // parse objects data
  const materialDetails = await Promise.all(
    Object.values(data.ObjectsData).map(async (objectData) => {
      if (objectData.textureChanged) {
        const texture = imagesMap[objectData.diffusePath];
        objectData.diffusePath = texture.file;
        objectData.diffuseId = texture.id;
      }

      if (objectData.AOMapChanged) {
        const texture = imagesMap[objectData.AOPath];
        objectData.AOPath = texture.file;
        objectData.AOId = texture.id;
        objectData.AOImageFormat = getFileExtension(objectData.AOPath);
      }

      if (objectData.roughnessMapChanged) {
        const texture = imagesMap[objectData.roughnessPath];
        objectData.roughnessPath = texture.file;
        objectData.roughnessId = texture.id;
        objectData.roughnessImageFormat = getFileExtension(objectData.roughnessPath);
      }

      if (objectData.normalChanged) {
        const texture = imagesMap[objectData.normalPath];
        objectData.normalPath = texture.file;
        objectData.normalId = texture.id;
        objectData.normalMapId = texture.id;
        objectData.normalImageFormat = getFileExtension(objectData.normalPath);
      }

      if (objectData.isTransparencyMapChanged) {
        const texture = imagesMap[objectData.transparencyPath];
        objectData.transparencyPath = texture.file;
        objectData.transparencyId = texture.id;
        objectData.transparencyImageFormat = getFileExtension(objectData.transparencyPath);
      }

      if (objectData.isMaterialChanged) {
        const materialsResponse = await apiGetMaterials(
          `old_material_id=${objectData.loadedMaterialId}`
        );
        const material = materialsResponse.results[0];
        const dbMaterial = await updateDbMaterial(material);
        objectData.loadedMaterialId = material.id;
        objectData.materialId = material.id;
        objectData.loadedMatName = material.name;
        objectData.loadedMaterialPath = dbMaterial.file;
      }

      return formatV1MaterialData(objectData);
    })
  );

  return {
    roomId,
    data,
    json: projectJson,
    unityPropList,
    lightData,
    materialDetails,
  };
}



const formatV1MaterialData = (d) => {
  return {
    diffusePath: d.diffusePath,
    loadedMaterialPath: d.loadedMaterialPath,
    materialTexturePath: null,
    AOPath: d.AOPath,
    normalPath: d.normalPath,
    roughnessPath: d.roughnessPath,
    transparencyPath: d.transparencyPath,
    normalId: d.normalId,
    AOId: d.AOId,
    metallicId: '',
    metallicPath: '',
    materialId: d.materialId,
    version: null,
    isMaterialChanged: d.isMaterialChanged,
    objectName: d.ObjectName,
    diffuseId: d.diffuseId,
    emissionIntensity: 0,
    effectLightColorBounce: false,
    isMetallicMap: false,
    isMetallicMapChanged: false,
    isCustomMetallicMap: false,
    MetallicMapChangeable: false,
    aoIntensity: d.aoIntensity || 0,
    copiedFromMaterialName: '',
    copiedFromObjectName: '',
    loadedMaterialId: d.loadedMaterialId,
    loadedMatName: d.loadedMatName,
    materialTextureName: '',
    materialMainAssetName: '',
    materialTextureId: d.materialTextureId,
    isTexture: d.isTexture,
    isTextureChanged: d.textureChanged,
    textureFormat: d.textureFormat,
    diffuseName: d.diffuseName,
    isAOMap: d.isAOMap,
    AOMapChanged: d.AOMapChanged,
    isCustomAOMap: d.isCustomAOMap,
    AOImageFormat: d.AOImageFormat,
    isNormal: d.isNormal,
    normalChanged: d.normalChanged,
    normalIntensity: d.normalIntensity,
    isNormalInvert: d.isNormalInvert,
    isCustomNormalMap: d.isCustomNormalMap,
    normalMapId: d.normalMapId,
    normalImageFormat: d.normalImageFormat,
    isRoughnessMap: d.isRoughnessMap,
    roughnessMapChanged: d.roughnessMapChanged,
    roughnessIntensity: d.roughnessIntensity,
    isRoughnessInvert: d.isRoughnessInvert,
    isCustomRoughnessMap: d.isCustomRoughnessMap,
    roughnessId: d.roughnessId,
    roughnessImageFormat: d.roughnessImageFormat,
    isTransparencyMapChanged: d.isTransparencyMapChanged,
    isTransparency: d.isTransparency,
    isTransparencyInvert: d.isTransparencyInvert,
    transparencyIntensity: d.transparencyIntensity,
    isCustomTransparencyMap: d.isCustomTransparencyMap,
    isTransparencyFromAlbedo: d.isTransparencyFromAlbedo,
    transparencyId: d.transparencyId,
    transparencyImageFormat: d.transparencyImageFormat,
    metalIntensity: d.metalIntensity,
    initialMetalIntensity: d.initialMetalIntensity,
    matName: d.matName,
    UV0: d.UV0,
    isAdvanced: d.isAdvanced,
    isCurrentMapsGenerated: d.isCurrentMapsGenerated,
    isTexturefromMaterialPreset: d.isTexturefromMaterialPreset,
    isColor: d.isColor,
    color: d.color,
    colorBlender: d.color_blender,
    OtherObjectName: d.OtherObjectName,
    tilingOffsetValues: d.tilingOffsetValues,
    tilingOptionsData: d.tilingOptionsData,
    togetherTilingKeys: d.togetherTilingKeys,
    objectUniqueIdentifier: uuidv4(),
  };
};