import { useEffect, useState } from 'react';
import { Modal, Button, ModalTitle, Row, Col } from 'react-bootstrap';
import { BigButton, MiniButton } from './';
import { icons } from '../assets';
import _ from 'lodash';
import FooterButton from './FooterButton';
const { CloseIcon } = icons;

const ModalArea = (props) => {
  function handleClose() {

    if (props.ownerModal) {
      props.handleOwnerClose();
    }

    props.setShowModal(false);
  }
  return (
    <Modal
      show={props.showModal}
      animation={props.animation || false}
      onHide={handleClose}
      dialogClassName={props.className || 'new-item-modal'}>
      <Modal.Header className="commonPopupHeader justify-content-end pb-0">
        <Button onClick={handleClose} className="modalCloseButton">
          <CloseIcon />
        </Button>
      </Modal.Header>
      {props.title && (
        <ModalTitle className="text-center">
          <h2 className="appH2">{props.title}</h2>
        </ModalTitle>
      )}
      {props.subtitle && <h3 className="text-center appH3">{props.subtitle}</h3>}
      <Modal.Body>{props.body}</Modal.Body>
      {props.includeCancelButton || props.promptButton ? (
        <Modal.Footer className="commonPopupFooter spaceEvenly">
          {props.includeCancelButton && (
            <BigButton buttonTitle={props.cancelTitle || 'Cancel'} onClick={handleClose} />
          )}
          {props.promptButton}
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default function ModalButtonArea(props) {
  const [showModal, setShowModal] = useState(
    _.isBoolean(props.startOpen) ? props.startOpen : false
  );
  // need to get styling working properly here

  function toggleModal(overrideValue) {
    if (_.isBoolean(props.showModal)) {
      props.setShowModal(overrideValue);
    } else {
      setShowModal(overrideValue);
    }
    if (_.isFunction(props.onClose) && !overrideValue) {
      props.onClose()
    }
  }

  return (
    <>
      {props.bigButton && <BigButton {...props} onClick={() => toggleModal(true)} type="button" />}
      {props.miniButton && (
        <MiniButton
          title={props.buttonTitle}
          clickFunction={() => toggleModal(true)}
          iconSrc={props.iconSrc}
          buttonLabel={props.buttonLabel}
        />
      )}
      {props.footerButton && <FooterButton icon={props.buttonIcon} {...props} clickFunction={() => toggleModal(true)} />}
      {props.button}
      <ModalArea
        handleOwnerClose={props.handleOwnerClose}
        ownerModal={props.ownerModal}
        showModal={_.isBoolean(props.showModal) ? props.showModal : showModal}
        setShowModal={toggleModal}
        className={props.className}
        title={props.title}
        subtitle={props.subtitle}
        body={props.body}
        animation={props.animation}
        includeCancelButton={props.includeCancelButton}
        promptButton={props.promptButton}
      />
    </>
  );
}
