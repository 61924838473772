import React, { forwardRef, useEffect, useRef, useState } from 'react';
export const Input = forwardRef(
    (
      {
        maskPlaceholder = null,
        mask = null,
        required = false,
        className = null,
        limit = 1000,
        label = null,
        type = 'text',
        errorData = [],
        multiline = false,
        name = null,
        defaultData = {},
        onChange = null,
        ...props
      },
      ref,
    ) => {
      const [value, setValue] = useState('');
      const [errorText, setErrorText] = useState('');
  
      className = className === false ? '' : `form-control ${className}`;
      useEffect(() => {
        if (maskPlaceholder) {
          setValue(maskPlaceholder);
        }
      }, [maskPlaceholder]);
      useEffect(() => {
        if (defaultData?.[name]) {
          setValue(defaultData[name]);
        }
      }, [defaultData]);
  
      useEffect(() => {
        if (errorData?.[name]) {
          setErrorText(errorData[name]);
        } else {
          setErrorText('');
        }
      }, [errorData]);
      function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
      const handleChange = ({ target }) => {
        const { value: val = '' } = target;
        console.log(value);
        if (val.length <= limit) {
          setValue(type === 'number' ? parseFloat(val) : val);
          onChange?.({ name, value: type === 'number' ? val : val });
        }
      };
  
      return (
        <>
          {label !== false && <label>{label}</label>}
          {multiline ? (
            <textarea
              required={required}
              className={` ${className} ${errorText && 'is-invalid'}`}
              name={name}
              value={value}
              onChange={handleChange}
              {...props}
            />
          ) : (
            
                  <>
                  <input
                    required={required}
                    type={type}
                    ref={ref}
                    className={`${className} ${errorText && 'is-invalid'}`}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    {...props}
                  />
                  {errorText && <div className="invalid-feedback">{errorText}</div>}
                
           </>
         
          )}
        </>
      );
    },
  );
  export const Select = forwardRef(
    (
      {
        className = '',
        label = null,
        emptyText = '-select-',
        fullWidth = false,
        name = null,
        defaultData = {},
        onChange = null,
        optionClassName = '',
        options = [],
        ...props
      },
      ref,
    ) => {
      const [value, setValue] = useState('');
  
      className = fullWidth ? className + ' ' + 'full-width' : className;
      useEffect(() => {
        if (defaultData?.[name]) {
          setValue(defaultData[name]);
        }
      }, [defaultData]);
  
      const handleChange = ({ target }) => {
        const { value: val = null } = target;
        setValue(val);
        onChange?.({ name, value: val });
      };
      return (
        <>
          <label>{label}</label>
          <select
            ref={ref}
            className={`form-control ${className}`}
            name={name}
            value={value}
            onChange={handleChange}
            {...props}
          >
            <option value={null}>{emptyText} </option>
            {options.length > 0 &&
              options.map(({ value, text }) => (
                <option className={optionClassName} key={text} value={value}>
                  {text}
                </option>
              ))}
          </select>
        </>
      );
    },
  );
  export const RadioButton = ({
    label = null,
    successValue = '',
    checked = null,
    name = null,
    onChange = null,
    defaultData = {},
    ...props
  }) => {
    const ref = useRef();
  
    const handleChange = ({ target: { checked } }) => {
      onChange?.({ name, value: checked ? successValue : false });
    };
    useEffect(() => {
      if (defaultData?.[name]) {
        let check = defaultData[name] === successValue;
        if (check) {
          ref.current.checked = check;
          handleChange({ target: { checked: check } });
        }
      }
    }, [defaultData]);
  
    useEffect(() => {
      if (checked !== null) {
        ref.current.checked = checked;
        handleChange({ target: { checked } });
      }
    }, [checked]);
  
    return (
      <label className="selected-label">
        <input ref={ref} type="radio" onChange={handleChange} name={name} />
        {label}
        {props.children}
      </label>
    );
  };
  
  export const Checkbox = ({
    successValue = true,
    label = '',
    name = null,
    className = '',
    defaultData = {},
    onChange = null,
  }) => {
    const [checked, setChecked] = useState(false);
  
    useEffect(() => {
       console.log(defaultData,name);
      if (defaultData?.[name] != undefined) {
        setChecked(defaultData[name]);
      
      }
    }, [defaultData]);
  
    const handleChange = () => {
      let check = !checked;
      setChecked(check);
      onChange?.({ name, value: check && successValue });
    };
  
    return (
      <>
        <input
          className={`styled-checkbox w-auto ${className}`}
          type="checkbox"
          onChange={handleChange}
          checked={checked}
          name={name}
          value={checked}
          id="chk"
        />
        <label onClick={handleChange}>{label}</label>
      </>
    );
  };