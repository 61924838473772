import cx from "classnames";
import BootstrapPagination from "react-bootstrap/Pagination";
import styles from "./Pagination.module.scss";

export const Pagination = ({ className, theme, fullWidth, size, withSpacing, ...rest }) => {
  return (
    <BootstrapPagination
      {...rest}
      className={cx(
        styles.pagination,
        className,
        fullWidth && styles.fullWidth,
        theme === 'dark' && styles.dark,
        size === 'small' && styles.small,
        withSpacing && styles.withSpacing,
      )}
    />
  );
};

Pagination.Item = BootstrapPagination.Item;
