/* eslint-disable  no-debugger */
import cx from 'classnames';
import PubSub from 'pubsub-js';
import { useContext, useEffect, useRef, useState } from 'react';
import { RiCheckLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState, usePrevious } from 'react-use';
import { CLOSE_WHITE } from '../../../../../config/constant/assetConstant';
import {
  BLUR_INTENSITY_MAX_VALUE,
  BLUR_INTENSITY_MIN_VALUE,
  CAMERA_HEIGHT_MAX_VALUE,
  CAMERA_HEIGHT_MIN_VALUE,
  FOV_MAX_VALUE,
  FOV_MIN_VALUE,
} from '../../../../../config/constant/projectConstant';
import {
  DEFAULT_CAMERA_BLUR_INTENSITY,
  MAX_RESOLUTION,
  MIN_RESOLUTION,
} from '../../../../../config/constant/unityConstants';
import { stripNonNumbers } from '../../../../../helpers/jsHelper';
import { successToast } from '../../../../../helpers/toastHelper';
import { normalizeResSize } from '../../../../../helpers/unityHelper';
import { KEYBOARD_EVENT, UnityEvent } from '../../../../../helpers/unityPubSub';
import { curatorSelector } from '../../../../../redux/slicers/admin/curatorSlicer';
import {
  setGlobalFov,
  setGlobalHeight,
  setGlobalWidth,
} from '../../../../../redux/slicers/camera/cameraSettings';
import Button from '../../../../Button/Button';
import { useUnityContext } from '../../../../container/unityContainer';
import BinIcon2 from '../../../../icons/BinIcon2';
import { SaveIcon } from '../../../../icons/SaveIcon';
import { SettingsIconV2 } from '../../../../icons/SettingsIconV2';
import { CuratorAccordion } from '../../CuratorAcordion/CuratorAcordion';
import FormRange from '../../FormElements/FormRange';
import { Lock } from '../../Lock/Lock';
import { LockedInput } from '../../LockedInput/LockedInput';
import { NumberInput } from '../../NumberInput';
import {
  createResolutionChangeHandler,
  useResolutionHandlers,
  useResolutionPresets,
} from '../Setting/useResolutionPresets';
import styles from './CameraItem.module.scss';

export const CameraItem = ({
  camera,
  dataRef,
  onDelete,
  onEditToggle,
  onSaveToPressets,
  selected,
  expandable,
  disabled,
  onSelect,
  onClick,
  editMode,
  onSave,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ ...camera });
  const { id, resoX, resoY } = camera;
  const settingData = useSelector((state) => curatorSelector(state).settingData);
  const { selectedObjectName } = formData;
  const { cameraModule } = useUnityContext();
  const unityContext = useUnityContext();
  const prevEditMode = usePrevious(editMode);
  const formDataRef = useRef(null);
  const escSubscriptionRef = useRef(null)
  formDataRef.current = formData;

  if (dataRef) {
    dataRef.current = formData;
  }

  const updateResolution = (resolution) => {
    setFormData({ ...formData, ...resolution });
    const resoX = normalizeResSize(resolution.resoX);
    const resoY = normalizeResSize(resolution.resoY);

    dispatch(setGlobalWidth(resoX));
    dispatch(setGlobalHeight(resoY));

    unityContext.cameraModule.OnUpdateCameraResoXReceiver(resoX);
    unityContext.cameraModule.OnUpdateCameraResoYReceiver(resoY);
  };

  const handleResolutionChange = (data) => {
    updateResolution(data);
  };

  const actions = [];
  if (onEditToggle) {
    actions.push({
      icon: <SettingsIconV2 />,
      onClick: onEditToggle,
    });
  }

  if (onSaveToPressets) {
    actions.push({
      icon: <SaveIcon />,
      onClick: (e) => {
        e.stopPropagation();
        onSaveToPressets();
      },
    });
  }

  if (onDelete) {
    actions.push({
      icon: <BinIcon2 />,
      onClick: (e) => {
        e.stopPropagation();
        onDelete();
      },
    });
  }

  useEffect(() => {
    const justOpened = editMode && !prevEditMode;
    const justClosed = prevEditMode && !editMode;
    if (justClosed) {
      unityContext.handleCameraPopupClose();
      clearEscSubscription()
    }

    if (justOpened) {
      const token = PubSub.subscribe(UnityEvent.CameraFocalLengthReceiver, (_, focalLength) => {
        setFormData({ ...formDataRef.current, focalLength });
      });

      return () => {
        PubSub.unsubscribe(token);
      };
    }
  }, [editMode]);

  useEffect(() => {
    return clearEscSubscription();
  }, [])

  const saveButton = (
    <Button
      type="button"
      className={styles.saveButton}
      onClick={(e) => {
        e.stopPropagation();
        onSave(formDataRef.current);
      }}>
      <RiCheckLine fontSize={20} />
    </Button>
  );

  const clearEscSubscription = () => {
    PubSub.unsubscribe(escSubscriptionRef.current);
    cameraModule.CloseFocusObjectReceiver();
  }

  const waitForEscEvent = () => {
    escSubscriptionRef.current = PubSub.subscribe(KEYBOARD_EVENT.ESC_PRESSED, () => {
      setFormData({ ...formDataRef.current, isdepthOfField: false })
      cameraModule.CloseFocusObjectReceiver();
    })
  }

  const onChange = ({ name, value, checked }) => {
    const onlyNumbers = stripNonNumbers(value);
    const numberValue = parseFloat(onlyNumbers);
    const numberValueOrEmptyString = Number.isNaN(numberValue) ? '' : numberValue;
    const isNumberField = ['fov',  'blurIntensity'].includes(name);

    if (name === 'isCameraCorrection' || name === 'isdepthOfField') {
      setFormData({
        ...formDataRef.current,
        [name]: checked,
        ...(name === 'isdepthOfField' && !checked ? { selectedObjectName: '' } : {}),
      });
    } else if (name === 'cameraHeight') {
      setFormData({
        ...formDataRef.current,
        [name]: parseFloat(value),
      });
    }
     else {
      setFormData({
        ...formDataRef.current,
        [name]: isNumberField && value.trim() !== '' ? numberValueOrEmptyString : value,
      });
    }

    switch (name) {
      case 'isdepthOfField':
        cameraModule.OnSelectDepthOfFieldReceiver(checked);
        if (!checked) {
          cameraModule.OnUpdateDepthOfFieldValueReceiver(BLUR_INTENSITY_MIN_VALUE);
        } else {
          // TODO: replace it with info toast
          successToast('Please select an object');
          waitForEscEvent()
        }
        break;
      case 'isCameraCorrection':
        cameraModule.OnCameraVerticalTiltReceiver(checked);
        break;
      case 'cameraHeight': {
        const height = parseFloat(value);
        if (!Number.isNaN(height)) {
          cameraModule.UpdateCameraHeightReceiver(height);
        }
        break;
      }
      case 'fov': {
        let fov = numberValue;
        if (!Number.isNaN(fov)) {
          fov = fov > FOV_MAX_VALUE ? FOV_MAX_VALUE : fov < FOV_MIN_VALUE ? FOV_MIN_VALUE : fov;
          dispatch(setGlobalFov(fov));
          cameraModule.OnUpdateCameraFovReceiver(fov);
        }
        break;
      }
      case 'blurIntensity': {
        const intensity = parseFloat(value);
        if (!Number.isNaN(intensity)) {
          cameraModule.OnUpdateDepthOfFieldValueReceiver(intensity);
        }
        break;
      }

      default:
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    onChange({ name, value, checked });
  };

  useEffect(() => {
    if (!editMode) return;

    const token = PubSub.subscribe(UnityEvent.FocusObjectNameReceiver, (_, objectName) => {
      clearEscSubscription()
      setFormData({
        ...formDataRef.current,
        selectedObjectName: objectName,
        blurIntensity: DEFAULT_CAMERA_BLUR_INTENSITY,
      });
    });

    return () => PubSub.unsubscribe(token);
  }, [editMode]);

  const handleDeleteObj = (e) => {
    e.stopPropagation();
    setFormData({ ...formData, selectedObjectName: '', isdepthOfField: false });
    cameraModule.ClearSelectedObjectReceiver();
  };

  const toggleLock = () => {
    const newLock = !formData.is_lock;
    setFormData({ ...formData, is_lock: newLock });
    cameraModule.OnUpdateLockReceiver(newLock);
  };

  return (
    <CuratorAccordion.Item eventKey={id} disabled={disabled}>
      <CuratorAccordion.Header
        onClick={onClick}
        checkboxStyle="checkbox"
        onCheckboxChange={editMode ? undefined : onSelect}
        selected={selected}
        // icon={lightType}
        actions={editMode ? undefined : actions}
        icon={editMode ? saveButton : `${resoX} x ${resoY}`}>
        {!editMode ? (
          camera.cameraName
        ) : (
          <input
            type="text"
            className={`form-control ${styles.nameInput}`}
            name="cameraName"
            value={formData.cameraName}
            onChange={handleChange}
          />
        )}
      </CuratorAccordion.Header>

      {expandable && (
        <CuratorAccordion.Body>
          <div className="d-flex justify-content-between accordion-btn mb-3 gap-2">
            {settingData?.json_data?.resolutionPresent?.map((a, i) => {
              const active = a.width === formData.resoX && a.height === formData.resoY;

              return (
                <button
                  type="button"
                  key={a.id}
                  className={`btn btn-default btn ${active ? 'active' : ''} ${
                    styles.resolutionButton
                  }`}
                  onClick={(e) => {
                    setFormData({
                      ...formData,
                      resoX: a.width,
                      resoY: a.height,
                    });
                  }}>
                  {a.name}
                </button>
              );
            })}
          </div>

          <LockedInput
            id={camera.id}
            className="mb-3"
            inputClassName={styles.textInput}
            lock={formData.is_lock}
            onLockToggle={toggleLock}
            value={[
              { name: 'resoX', value: formData.resoX, label: 'Width (px)' },
              { name: 'resoY', value: formData.resoY, label: 'Height (px)' },
            ]}
            onChange={handleResolutionChange}
            min={MIN_RESOLUTION}
            max={MAX_RESOLUTION}
            minMaxWarning={`Camera width and height must be min: ${MIN_RESOLUTION} and max: ${MAX_RESOLUTION} `}
            valueFormatter={parseInt}
            onlyIntegers
          />

          <div>FOV</div>
          <div className={styles.fovContainer}>
            <FormRange
              value={formData.fov}
              min={FOV_MIN_VALUE}
              max={FOV_MAX_VALUE}
              // onChange={(e) => cameraFov(e)}
              onChange={handleChange}
              name="fov"
              hideValue
              step="1"
              containerProps={{
                className: 'w-100',
              }}
            />
            <input
              type="text"
              pattern="[0-9]+"
              name="fov"
              maxLength={3}
              minLength={1}
              step="1"
              min={FOV_MIN_VALUE}
              max={FOV_MAX_VALUE}
              value={formData.fov}
              onChange={handleChange}
              className={styles.fovInput}
              // onBlur={handleInputBlur}
            />
          </div>
          <div className="form-check custom-checkbox d-flex align-items-center mb-3 mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="isCameraCorrection"
              checked={formData.isCameraCorrection}
              // onChange={(e) => cameraCorrection(e)}
              onChange={handleChange}
            />
            <span className="mt-1">Correct camera vertical tilt</span>
          </div>
          {formData.isCameraCorrection && (
            <>
              <div>Slide camera</div>
              <div className={styles.fovContainer}>
                <FormRange
                  value={formData.cameraHeight}
                  min={CAMERA_HEIGHT_MIN_VALUE}
                  max={CAMERA_HEIGHT_MAX_VALUE}
                  onChange={handleChange}
                  step="0.01"
                  name="cameraHeight"
                  hideValue
                  containerProps={{
                    className: 'w-100',
                  }}
                />
                 <NumberInput
                  step={0.01}
                  enableNegative
                  type="text"
                  name="cameraHeight"
                  min={CAMERA_HEIGHT_MIN_VALUE}
                  max={CAMERA_HEIGHT_MAX_VALUE}
                  value={formData.cameraHeight}
                  onChange={handleChange}
                  className={styles.fovInput}
                />
              </div>
            </>
          )}

          <div className="form-check custom-checkbox d-flex align-items-center justify-content-between mt-4">
            <div className="d-flex">
              <input
                className="form-check-input"
                type="checkbox"
                checked={formData.isdepthOfField}
                name="isdepthOfField"
                onChange={handleChange}
              />
              <span className="mt-1">Depth of field</span>
            </div>
            {formData.isdepthOfField && selectedObjectName && (
              <button className={cx('btn', styles.objectButton, styles.selected)}>
                <span className={styles.text}>
                  <span>{selectedObjectName}</span>
                </span>
                <img src={CLOSE_WHITE} onClick={handleDeleteObj} className="mx-0" />
              </button>
            )}
          </div>
          {formData.isdepthOfField && selectedObjectName && (
            <>
              <label className="form-label mb-0">Blur Intensity</label>
              <div className={styles.fovContainer}>
                <FormRange
                  value={formData.blurIntensity}
                  min={BLUR_INTENSITY_MIN_VALUE}
                  max={BLUR_INTENSITY_MAX_VALUE}
                  onChange={handleChange}
                  name="blurIntensity"
                  hideValue
                  containerProps={{
                    className: 'w-100',
                  }}
                />
                <input
                  type="text"
                  pattern="[0-9]+"
                  name="blurIntensity"
                  min={BLUR_INTENSITY_MIN_VALUE}
                  max={BLUR_INTENSITY_MAX_VALUE}
                  value={formData.blurIntensity}
                  onChange={handleChange}
                  className={styles.fovInput}
                />
              </div>
            </>
          )}
        </CuratorAccordion.Body>
      )}
    </CuratorAccordion.Item>
  );
};

