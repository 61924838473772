import { Link } from "react-router-dom";
import BinIcon from "../../../../components/icons/BinIcon";
import CollectionIcon from "../../../../components/icons/CollectionIcon";
import { CommentIcon } from "../../../../components/icons/CommentIcon";
import { EditIcon } from "../../../../components/icons/EditIcon";
import { FullScreenIcon } from "../../../../components/icons/FullScreenIcon";
import { HeadsetIcon } from "../../../../components/icons/HeadsetIcon";
import { HeartLineIcon } from "../../../../components/icons/HeartLineIcon";
import { InfoIcon } from "../../../../components/icons/InfoIcon";
import ShareIcon from "../../../../components/icons/ShareIcon";
import styles from './PreviewUI.module.scss';

export const ActionsButtons = ({ items, onFullScreenClick }) => {
  const ACTION_CONFIG = {
    info: {
      label: "Info",
      icon: <InfoIcon />,
    },
    comment: {
      label: "Comments",
      icon: <CommentIcon />,
    },
    edit: {
      label: "Edit file",
      icon: <EditIcon />,
    },
    share: {
      label: "Share",
      icon: <ShareIcon />,
    },
    collection: {
      label: "Add to collection",
      icon: <CollectionIcon />,
    },
    favourite: {
      label: "Favourite",
      icon: <HeartLineIcon />,
    },
    fullscreen: {
      label: "Full screen",
      icon: <FullScreenIcon />,
      onClick: onFullScreenClick,
    },
    delete: {
      label: "Delete",
      icon: <BinIcon />,
    },
    help: {
      label: "Help",
      icon: <HeadsetIcon />,
    },
  };

  return (
    <div className={styles.actions}>
      {items.map((action, idx) => {
        const { type, label, onClick, to, target, icon } = action;

        const config = ACTION_CONFIG[type];
        const actionLabel = label || config?.label;
        const actionIcon = icon || config?.icon;
        const Component = to ? Link : "button";

        const handleClick = () => {
          config?.onClick?.()
          onClick?.();
        }

        return (
          <Component
            to={to}
            onClick={handleClick}
            target={target}
            key={idx}
            className={styles.actionButton}
          >
            {actionIcon}
            <span className={styles.actionText}>{actionLabel}</span>
          </Component>
        );
      })}
    </div>
  );
};
