import { TILING_TYPE } from "./unityConstants";

export const UPLOAD_MAP_FILTER_TYPE = 6;

export const UPLOAD_MAP_FILTER_NAME = {
  [TILING_TYPE.GLOSSY]: 'glossiness',
  [TILING_TYPE.GLOSSY]: 'design',
  [TILING_TYPE.AO]: 'ao',
  [TILING_TYPE.TRANSPARENCY]: 'transparency',
  [TILING_TYPE.DEPTH]: 'normal',
  [TILING_TYPE.METALLIC]: 'metallic',
}

export const OPEN_PROJECT_ID = 'open';