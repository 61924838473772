import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DIMENSION, UNITY_2D_VIEW_MODE } from '../../../../config/constant/unityConstants';
import {
  selectCuratorInitialized,
  selectDimension,
  selectUnity2DViewMode,
  setUnity2DViewMode,
  toggleDimension,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { useUnityContext } from '../../../container/unityContainer';
import { Pagination } from '../../../Pagination/Pagination';
import styles from './DimensionSwitcher.module.scss';
import { useUnityShortcuts } from './useUnityShortcuts';

export const DimensionSwitcher = memo(() => {
  const dispatch = useDispatch();
  const { changeDimension, update2DViewMode } = useUnityShortcuts();
  const dimension = useSelector(selectDimension);
  const unity2DViewMode = useSelector(selectUnity2DViewMode);
  const curatorInitialized = useSelector(selectCuratorInitialized);

  const handleDimensionChange = (event) => {
    changeDimension();
  };

  const handleTabChange = (viewMode) => () => {
    update2DViewMode(viewMode)
  }

  const is2DActive = dimension === DIMENSION._2D;

  return (
    <>
      {is2DActive && (
        <Pagination theme="dark" className={styles.pagination} size="small">
          <Pagination.Item
            active={unity2DViewMode === UNITY_2D_VIEW_MODE.TOP}
            onClick={handleTabChange(UNITY_2D_VIEW_MODE.TOP)}>
            Top
          </Pagination.Item>
          <Pagination.Item
            active={unity2DViewMode === UNITY_2D_VIEW_MODE.FRONT}
            onClick={handleTabChange(UNITY_2D_VIEW_MODE.FRONT)}>
            Front
          </Pagination.Item>
          <Pagination.Item
            active={unity2DViewMode === UNITY_2D_VIEW_MODE.BACK}
            onClick={handleTabChange(UNITY_2D_VIEW_MODE.BACK)}>
            Back
          </Pagination.Item>
          <Pagination.Item
            active={unity2DViewMode === UNITY_2D_VIEW_MODE.LEFT}
            onClick={handleTabChange(UNITY_2D_VIEW_MODE.LEFT)}>
            Left
          </Pagination.Item>
          <Pagination.Item
            active={unity2DViewMode === UNITY_2D_VIEW_MODE.RIGHT}
            onClick={handleTabChange(UNITY_2D_VIEW_MODE.RIGHT)}>
            Right
          </Pagination.Item>
        </Pagination>
      )}

          <div className="divider ms-0"></div>


      <div className={`form-check form-switch fs-2 px-0 ${styles.toggleInputContainer}`}>
        <input
          className="form-check-input m-0"
          type="checkbox"
          checked={is2DActive}
          disabled={!curatorInitialized}
          onChange={handleDimensionChange}
        />
        <div className={styles.textBefore}>2D</div>
        <div className={styles.textAfter}>2D</div>
        <div className={styles.checkboxCircle}></div>
      </div>
    </>
  );
});
