import { createSlice } from "@reduxjs/toolkit";

const tag = createSlice({
  name: "tags",
  initialState: {
    tagData: {},
  },
  reducers: {
    setTagData: (state, { payload }) => {
      state.tagData = payload;
    },
  },
});

export const { setTagData } = tag.actions;
export const tagSelector = (state) => state.tag;
export const tagReducer = tag.reducer;
export default tagReducer;
