import { useEffect, useMemo, useRef } from "react";
import Portal from "react-overlays/Portal";
import { FilterSubmenu } from "./FilterSubmenu";
import { CuratorSidebarLayout } from "../CuratorSidebarLayout/CuratorSidebarLayout";

export const FILTER = {
  MY_FAVOURITES: "favourite",
  MY_TEXTURES: "MY_TEXTURES",
  ALL_TEXTURES: "ALL_TEXTURES",
  MY_UPLOADS: "MY_UPLOADS",
  ALL_UPLOADS: "ALL_UPLOADS",
  MY_3D_MODELS: "MY_3D_MODELS",
  PROPS: "PROPS",
};

export const CuratorFilter = ({ open, onChange, filter, value, config }) => {
  const bodyRef = useRef(null);

  const filters = [...config]

  const apiFilters = useMemo(() => {
    if (!filter) return [];

    let result = filter.filterlabels.map((label) => {
      return {
        title: label.label_name,
        items: filter.filteroptions
          .filter((item) => item.filter_label === label.id)
          .map((item) => ({
            label: item.name,
            value: `option_${item.filter_label}_${item.id}`,
          })),
      };
    });

    if (filter.brands && filter.brands.length > 0) {
      const brands = {
        title: "Brands",
        items: filter.brands.map((brand) => ({
          label: brand.name,
          value: `brand_${brand.id}`,
        })),
      };

      result = [brands, ...result];
    }

    return result;
  }, [filter]);

  filters.push(...apiFilters);

  useEffect(() => {
    bodyRef.current = document.querySelector("body");
  }, []);

  if (!open) return null;

  return (
    <Portal container={bodyRef}>
      <CuratorSidebarLayout.Filter>
        <FilterSubmenu filters={filters} value={value} onChange={onChange} />

        <CuratorSidebarLayout.Button location="filter" />
      </CuratorSidebarLayout.Filter>
    </Portal>
  );
};
