/* eslint-disable no-debugger */
import { dispatcher } from '../../../../helpers/projectHelper';
import { setGlobalHeight, setGlobalSafeCameraFlag, setGlobalWidth } from '../../../../redux/slicers/camera/cameraSettings';

export class UndoRedoSafeCameraResolution {
  constructor({ undoRedoInstance }) {
    this.undoRedo = undoRedoInstance;

    this.init();
  }

  init = () => {
    this.changeSafeCameraFlag = this.undoRedo.createAction(({ prevValue, nextValue }) => {
      return {
        undo: () => {
          dispatcher(setGlobalSafeCameraFlag(prevValue))
        },
        redo: () => {
          dispatcher(setGlobalSafeCameraFlag(nextValue))
        },
      }
    })

    this.updateResolution = this.undoRedo.createAction(({ prevValue, nextValue }) => {
      console.log('sasha undoredo updateResolution ', prevValue, nextValue)
      return {
        undo: () => {
          dispatcher(setGlobalWidth(prevValue.width));
          dispatcher(setGlobalHeight(prevValue.height));
        },
        redo: () => {
          dispatcher(setGlobalWidth(nextValue.width));
          dispatcher(setGlobalHeight(nextValue.height));
        },
      }
    })
    
  };
}
