import Slider from 'react-slick';
import { ThumbnailCard } from './';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function HorizontalCarousel({ data, time = 3000, path = null }) {
  var config = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: true
  };

  return (
    <Slider {...config}>
      {data && data.length > 0 &&
        data.map((item, i) => (
          <ThumbnailCard key={item.id} thumbnailData={item} path={path} />
        ))}
    </Slider>
  );
};
