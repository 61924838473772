export const UndoRedoCheckbox = ({ undoRedoAction, onChange, ...rest }) => {
  const handleChange = (e) => {
    const { name, checked } = e.target;
    undoRedoAction({
      name,
      prevValue: !checked,
      nextValue: checked,
    })
    
    onChange(e)
  }

  return <input type="checkbox" {...rest} onChange={handleChange} />;
};
