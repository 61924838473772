import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  AddToCollections,
  Download,
  FooterButton,
  Preview,
  SharePopup,
  DeactivatePopup,
  SelectableTableList,
  Checkbox,
  Input,
  BigButton,
} from '../common';
import { RENDERS_PREVIEWS_ROUTE } from '../constants/routePathConstants';
import { getCuratorPath } from '../helpers/urlHelper';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  apiGetDownloadRendersImages,
  apiGetPreviewAndMainRender,
  apiToggleFavorite,
  parseBasePathname,
  apiArchiveRenders,
  apiGetRenderBasedOnProjectRenderId,
  apiCreateRender,
  apiGetMemberDetails,
} from '../helpers/api';
import _ from 'lodash';
import { UserContext } from '../contexts';
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
  Table,
} from 'react-bootstrap';
import {
  ARCHIVE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
  EDIT_ICON,
  SHARE_ICON,
} from '../constants/assetConstants';
import { icons } from '../assets';
import axios from 'axios';
import { MODULE_TYPES } from '../constants/constants';
import { hasRenders } from '../helpers/projectHelper';
const {
  ArchiveIcon,
  CollectionIcon,
  EditIcon,
  FullScreenIcon,
  HeartLineIcon,
  HeadsetIcon,
  ShareIcon,
  HeartIcon,
  BinIcon,
  RestoreIcon,
  RenderIcon,
  CloseIcon,
} = icons;
import { useFullscreen } from '../hooks/useFullscreen';
import pluralize from 'pluralize';
import { successToast } from '../helpers/toastHelper';

export default function RenderDetails() {
  const navigate = useNavigate();
  let cardType = 'render-details';
  const { id } = useParams();
  const userCtx = useContext(UserContext);
  const currentUser = userCtx?.currentUser?.user;
  const [data, setData] = useState(null);
  const [sidebarCards, setSidebarCards] = useState([]);
  const [slides, setSlides] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [pass, setPass] = useState('1');
  const [showDownload, setShowDownload] = useState(false);
  const [showRender, setShowRender] = useState(false);
  const [downloadXPos, setDownloadXPos] = useState(0);
  const [downloadYPos, setDownloadYPos] = useState(0);
  const [includePasses, setIncludePasses] = useState(false);
  const [cameraOptions, setCameraOptions] = useState([]);

  // render settings
  const [renderWidth, setRenderWidth] = useState(0);
  const [renderHeight, setRenderHeight] = useState(0);
  const [renderName, setRenderName] = useState('');
  const [renderFiletype, setRenderFiletype] = useState('JPG');
  const [metadata, setMetadata] = useState(false);
  const [watermark, setWatermark] = useState(false);
  const [transparentBg, setTransparentBg] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [memberDetails, setMemberDetails] = useState({});

  let passOptions = [];

  if (data?.images && data.images.length > 0) {
    if (data?.images?.[activeIndex].file) passOptions.push({ name: 'Standard *', value: '1' });
    if (data?.images?.[activeIndex].filmic_file) passOptions.push({ name: 'Filmic', value: '2' });
    if (data?.images?.[activeIndex].color_pass_file)
      passOptions.push({ name: 'Color', value: '3' });
  }

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (data?.images) {
      setSidebarCards(
        _.map(data.images, (i) => ({
          ...i,
          name: i.angle_name,
        }))
      );
      setSlidesByPass();
    }
    if (data?.room && (!cameraOptions || cameraOptions.length < 1)) {
      getCameras();
    }
  }, [data]);

  useEffect(setSlidesByPass, [pass]);

  useEffect(async () => {
    if (data.member) {
      const res = await apiGetMemberDetails(data.member);
      setMemberDetails(res);
    }
  }, [data]);

  function handleSelect(camera, value) {
    const newCameraOpts = [...cameraOptions];
    const match = _.find(newCameraOpts, (c) => c.id === camera.id);
    const filtered = _.filter(newCameraOpts, (c) => c.id !== camera.id);
    match.isSelected = value;
    setCameraOptions(_.orderBy([...filtered, match], ['cameraName']));
  }

  function handleSelectAll() {
    const newCameraOpts = [...cameraOptions];
    const allSelected = _.every(newCameraOpts, (c) => c.isSelected);
    newCameraOpts.forEach((c) => (c.isSelected = allSelected ? false : true));
    setCameraOptions(_.orderBy(newCameraOpts, ['cameraName']));
  }

  function setSlidesByPass() {
    if (pass === '1') {
      setSlides(_.map(data?.images, (i) => i.file));
    } else if (pass === '2') {
      setSlides(_.map(data?.images, (i) => i.filmic_file));
    } else if (pass === '3') {
      setSlides(_.map(data?.images, (i) => i.color_pass_file));
    }
  }

  function handleDownloadClick(e) {
    setDownloadXPos(e.clientX);
    setDownloadYPos(e.clientY);
    setShowDownload(!showDownload);
  }

  async function handleCurrentImageClick() {
    const res = await apiGetDownloadRendersImages(`ids=${data?.id}`, includePasses);
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'render.zip');
    document.body.appendChild(link);
    link.click();
  }

  async function handleAllCameraRendersDownloadClick() {
    const ids = data.images.map((i) => i.id);
    const csv = ids.join(',');
    const res = await apiGetDownloadRendersImages(`ids=${csv}`, includePasses);
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'render.zip');
    document.body.appendChild(link);
    link.click();
  }

  async function refreshData() {
    if (!loading) {
      setLoading(true);
      const res = await apiGetPreviewAndMainRender(id);
      if (res) {
        setData(res);
        setLoading(false);
      }
    }
  }

  async function getCameras() {
    if (!loading && data) {
      setLoading(true);
      const res = await apiGetRenderBasedOnProjectRenderId(data.id);
      setCameraOptions(_.orderBy(res.data.result.room_angle_data, ['cameraName']));
    }
  }

  function handleFavorite() {
    const favorite = !data?.is_favourite;
    apiToggleFavorite(id, favorite, MODULE_TYPES.RENDER).then((res) => {
      if (res.status) {
        let changes = Object.assign({}, data);
        changes.is_favourite = favorite;
        setData(changes);
        successToast(
          `This ${pluralize.singular(MODULE_TYPES.RENDER)} has been ${
            favorite ? 'favorited' : 'unfavorited'
          }.`
        );
      }
    });
  }

  async function handleRender() {
    const selectedCameras = cameraOptions.filter((c) => c.isSelected);
    const cameraObj = selectedCameras.map((c) => ({
      id: Number(c.id),
      resoX: Number(renderWidth),
      resoY: Number(renderHeight),
    }));
    const payload = {
      count: cameraObj.length,
      render_name: renderName,
      render_extension: renderFiletype.toLowerCase(),
      render_camera: JSON.stringify(cameraObj),
      render_id: data.main_render,
      is_meta_data: metadata,
      is_water_mark: watermark,
      is_shadow_on_floor: shadow,
      is_transparent_bg: transparentBg,
    };
    const res = await apiCreateRender(payload);
  }

  function toggleRenderArea(e) {
    setDownloadXPos(e.clientX);
    setDownloadYPos(e.clientY);
    setShowRender(!showRender);
  }

  let deactivateButton = {
    element: DeactivatePopup,
    footerButton: true,
    icon: data?.is_preview ? BinIcon : ArchiveIcon,
    isPlural: false,
    item: MODULE_TYPES.RENDER,
    title: data?.is_preview ? 'Delete' : 'Archive',
    deleteAction: apiArchiveRenders,
    actionWord: data?.is_preview ? 'delete' : 'archive',
    id: id,
    onSuccess: () => navigate(),
  };

  // need to come back to this when we work on the trash module
  // if (data?.is_archived) {
  //   deactivateButton.deleteAction = apiRestoreRenders;
  //   deactivateButton.title = 'Restore';
  //   deactivateButton.is_inactive = true;
  //   deactivateButton.icon = RestoreIcon;
  // }

  const currentImageIsRendered = hasRenders(data, activeIndex);

  let actions = [
    currentImageIsRendered
      ? {
          element: Download,
          footerButton: true,
          title: 'Download',
          iconSrc: DOWNLOAD_ICON,
          clickFunction: (e) => handleDownloadClick(e),
          seq: 1,
        }
      : {
          element: FooterButton,
          title: 'Render',
          icon: RenderIcon,
          clickFunction: (e) => toggleRenderArea(e),
          seq: 1,
        },
    {
      element: FooterButton,
      title: 'Edit',
      icon: EditIcon,
      clickFunction: () => navigate(getCuratorPath(data.id, 'room')),
      seq: 2,
    },
    {
      element: SharePopup,
      footerButton: true,
      icon: ShareIcon,
      title: 'Share',
      seq: 3,
    },
    {
      element: AddToCollections,
      footerButton: true,
      icon: CollectionIcon,
      title: 'Add to Collection',
      onSuccess: () => console.log('successfully added to collections'),
      cardType,
      id: id,
      seq: 4,
    },
    {
      element: FooterButton,
      footerButton: true,
      title: 'Favorite',
      icon: data?.is_favourite ? HeartIcon : HeartLineIcon,
      clickFunction: handleFavorite,
      is_favourite: data?.is_favourite,
      seq: 5,
    },
    {
      element: FooterButton,
      footerButton: true,
      icon: FullScreenIcon,
      title: 'Full Screen',
      seq: 6,
    },
    {
      ...deactivateButton,
      seq: 7,
    },
    {
      element: FooterButton,
      footerButton: true,
      title: 'Help',
      icon: HeadsetIcon,
      cardType,
      // onClick: () => alert("info"),
      seq: 8,
    },
  ];

  const updatedAt = useMemo(() => {
    if (!data?.updated_at) return null;
    return new Date(data?.updated_at).getTime();
  }, [data?.updated_at]);

  return (
    <>
      <Preview
        title={data?.name}
        actions={actions}
        onClose={() => navigate(RENDERS_PREVIEWS_ROUTE)}
        loading={loading}
        updatedAt={updatedAt}
        userName={currentUser?.name || null}
        sidebarCards={sidebarCards}
        slides={_.map(slides, (s) => (s ? s : ''))}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        passOptions={passOptions}
        pass={pass}
        setPass={setPass}
        memberDetails={memberDetails}
      />
      {showDownload && (
        <div
          className="download-popup text-light p-2"
          style={{ top: downloadYPos - 180, left: downloadXPos - 64 }}>
          <div role="button" onClick={handleCurrentImageClick} className="mb-3">
            Current Image
          </div>
          <div role="button" className="mb-3" onClick={handleAllCameraRendersDownloadClick}>
            All Camera Renders
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="me-3">
              <a>Include Passes</a>
            </div>
            <Form.Check
              type="switch"
              id="custom-switch"
              value={includePasses}
              onChange={(e) => setIncludePasses(e.target.checked)}
            />
          </div>
        </div>
      )}
      {showRender && (
        <div
          className="render-popup text-light p-3"
          style={{ bottom: '4rem', right: '0.5rem' }}>
          <div className="header">
            <h5 className="m-0">Render settings</h5>
            <Button onClick={() => setShowRender(false)} className="footerCloseButton">
              <CloseIcon />
            </Button>
          </div>
          <Row>
            <Col>
              <div className="h-100">
                <div style={{ position: 'relative', height: '100%' }}>
                  <div className="render-camera-picker">
                    <div className="head d-flex align-items-center">
                      <Checkbox
                        name=""
                        onChange={handleSelectAll}
                        selected={_.every(cameraOptions, (c) => c.isSelected)}
                        label=""
                        className=""
                      />
                      Select all{' '}
                      <small className="text-muted ms-2"> ({cameraOptions.length} cameras)</small>
                    </div>
                    <div className="list">
                      {cameraOptions.map((c, i) => (
                        <div key={i} className="d-flex justify-content-between">
                          <div className="d-flex">
                            <Checkbox
                              selected={c.isSelected}
                              onChange={(e) => handleSelect(c, !c.isSelected)}
                            />
                            {c.cameraName}
                          </div>
                          <small className="ms-2 text-muted">
                            {c.resoX} x {c.resoY}
                          </small>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="render-inputs">
                <Row className="mb-3">
                  <Col xs="5">
                    <div className="pb-2">Width (px)</div>
                    <Form.Control
                      type="number"
                      className="py-2"
                      value={renderWidth}
                      onChange={(x) => setRenderWidth(x.target.value)}
                    />
                  </Col>
                  <Col xs="2" />
                  <Col xs="5">
                    <div className="pb-2">Height (px)</div>
                    <Form.Control
                      type="number"
                      className="py-2"
                      value={renderHeight}
                      onChange={(x) => setRenderHeight(x.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <div className="pb-2">File name</div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Enter file name..."
                        value={renderName}
                        onChange={(x) => setRenderName(x.target.value)}
                      />
                      <DropdownButton title={renderFiletype} variant="outline-secondary bg-body">
                        <Dropdown.Item as="button" onClick={() => setRenderFiletype('JPG')}>
                          JPG
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => setRenderFiletype('PNG')}>
                          PNG
                        </Dropdown.Item>
                      </DropdownButton>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs="6">
                    <Checkbox
                      name={'metadata'}
                      onChange={(x) => setMetadata(x.value)}
                      selected={metadata}
                      label="Metadata"
                    />
                  </Col>
                  <Col xs="6">
                    <Checkbox
                      name={'watermark'}
                      onChange={(x) => setWatermark(x.value)}
                      selected={watermark}
                      label="Watermark"
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="6">
                    <Checkbox
                      name={'transparent_bg'}
                      onChange={(x) => setTransparentBg(x.value)}
                      selected={transparentBg}
                      label="Transparent Bg"
                    />
                  </Col>
                  <Col xs="6">
                    <Checkbox
                      name={'shadow_on_floor'}
                      onChange={(x) => setShadow(x.value)}
                      selected={shadow}
                      label="Shadow on floor"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-grid pt-3">
                      <Button
                        variant="warning"
                        size="lg"
                        onClick={handleRender}
                        style={{ padding: '0.8rem 0' }}>
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
