/* eslint-disable no-debugger */
import { useEffect } from "react";
import rgbHex from 'rgb-hex';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CHANGED_MAP_PATHS_MAP, REVERSE_TILING_MAP, TEXTURE_MAP, TILING_MAP, TILING_NAME_MAP, TILING_TEXTURE_MAP, TILING_TYPE, TILING_TYPE_LABEL } from "../../../../config/constant/unityConstants";
import { inchesToUnitySize, sizeDecimals, unitySizeToInches } from "../../../../helpers/unityHelper";
import {
  curatorSelector, setCuratorLoader,
} from "../../../../redux/slicers/admin/curatorSlicer";
import {
  curatorStylesSelector,
  selectApplyTextureInProgress,
  selectMaterial,
  selectSelectedTexture,
  selectTexture,
  setActiveTab,
  setApplyTextureInProgress,
  setPropertiesExpertMode,
  setSelectedColor,
  setSelectedTextureId,
  setSelectMapMode,
  TAB_NAME,
} from "../../../../redux/slicers/admin/curatorStylesSlicer";
import { curatorUnityObjectSelector, selectParsedObject, selectUnitySelectedObjectInfo, setBase64Data, setTextureMap, setUnitySelectedObjectInfo, updateRawObject, updateUnityObject } from "../../../../redux/slicers/admin/curatorUnityObjectSlicer";
import { useUnityContext } from "../../../container/unityContainer";
import { getTexture } from "../../../../helpers/idb";
import { getAppState } from "../../../../redux/store";
import { errorToast } from "../../../../helpers/toastHelper";
import { isValidNumber } from "../../../../helpers/jsHelper";

export const useUnityColors = () => {
  const dispatch = useDispatch();
  const unity = useUnityContext();

  const changeColor = (color) => {
    const hex = typeof color === 'string' ? color : color.hex;
    // console.log('onChange, changeCOlor', { hex, hsv })
    unity.materialModule.OnUpdateColorReceiver(hex);
    dispatch(setSelectedColor(color));
    dispatch(updateRawObject({
      isColor: true,
      isTextureChanged: false,
    }))
  }

  return {
    changeColor,
  }
}

export const useUnityMaterial = () => {
  const dispatch = useDispatch();
  const unity = useUnityContext();

  const applyMaterial = async ({ material, switchToProperties = false }) => {
    if (selectApplyTextureInProgress(getAppState())) {
      errorToast('Please wait, previous texture is still in progress');
      return;
    }    

    dispatch(setApplyTextureInProgress(true))
    dispatch(setCuratorLoader(false))
    dispatch(selectMaterial(material));
    await unity.materialModule.selectMaterial({ material });

    if (switchToProperties) {
      dispatch(setActiveTab(TAB_NAME.PROPERTIES));
    }

    dispatch(setCuratorLoader(true))
  };

  return {
    applyMaterial,
  }
}

export const useUnityRedux = () => {
  const dispatch = useDispatch();
  const unity = useUnityContext();
  const selectMapMode = useSelector(
    (state) => curatorStylesSelector(state).selectMapMode
  );

  const isApplyTextureInProgress = () => {
    return selectApplyTextureInProgress(getAppState())
  }

  const applyTexture = async ({ texture, mapType = TEXTURE_MAP.DIFFUSE, switchToProperties = false }) => {
    if (isApplyTextureInProgress()) {
      errorToast('Please wait, previous texture is still in progress')
      return;
    }

    dispatch(setApplyTextureInProgress(true))
    dispatch(setCuratorLoader(false))
    console.log('SAHA APPLY TEXTURE !!!', { texture, mapType })

    dispatch(setSelectedTextureId(parseInt(texture.id)));
    await unity.materialModule.selectTexture({ texture, mapType });

    if (switchToProperties) {
      dispatch(setActiveTab(TAB_NAME.PROPERTIES));
    }

    dispatch(setCuratorLoader(true))
  };

  const getUpdateObject = (tilingOptionType, props) => {
    const parsedObject = selectParsedObject(getAppState())
    const key = TILING_NAME_MAP[tilingOptionType];
    const result = Object.entries(props).reduce((acc, [propKey, value]) => {
      if (propKey === 'width' || propKey === 'height') {
        if (typeof value === 'string' && value.trim() === '') {
          acc[propKey] = '';
          return acc;
        }
      }

      acc[propKey] =
        typeof value !== "undefined"  ? parseFloat(value) : parsedObject[key][propKey];
      return acc;
    }, {});

    return result;
  };

  const update = (tilingType, data) => {
    dispatch(
      updateUnityObject({
        [TILING_NAME_MAP[tilingType]]: data,
      })
    );
  };


  const reset = () => {
    const { initialTextureData } = curatorUnityObjectSelector(getAppState())

    changeSize({
      width: initialTextureData.width,
      height: initialTextureData.height,
      tilingOptionType: TILING_TYPE.TEXTURE,
    })
  }

  const changeSize = ({ width, height, tilingOptionType }) => {
    if (typeof height === 'number') {
      height = sizeDecimals(height)
    }

    if (typeof width === 'number') {
      width = sizeDecimals(width)
    }

    const upd = getUpdateObject(tilingOptionType, { width, height })

    unity.materialModule.OnUpdateTilingSizeReceiver({
      ...upd,
      tilingOptionType,
    });

    update(tilingOptionType, upd);
  };

  const changePosition = ({ x, y, tilingOptionType }) => {
    const upd = getUpdateObject(tilingOptionType, { 
      x, 
      y,
    })


    upd.x = sizeDecimals(upd.x)
    upd.y = sizeDecimals(upd.y)


    unity.materialModule.OnUpdateTilingOffsetReceiver({
      ...upd,
      tilingOptionType,
    });

    update(tilingOptionType, upd);
  };

  const changeRotation = ({
    width,
    height,
    x,
    y,
    rotation,
    tilingOptionType,
  }) => {
    const parsedObject = selectParsedObject(getAppState())
    const key = TILING_NAME_MAP[tilingOptionType];
    const currentRotation = parsedObject[key].rotation;
    const upd = getUpdateObject(tilingOptionType, { width, height, x, y, rotation  })

    if (
      !isValidNumber(upd.width)
      || !isValidNumber(upd.height)
      || !isValidNumber(upd.x)
      || !isValidNumber(upd.y)
    ) {
      errorToast('Please provide data before rotating an object')
      return;
    }

    if (
      (currentRotation%20 === 0 && rotation%20 !== 0)
      || (currentRotation%20 !== 0 && rotation%20 === 0)
    ) {
      // switch data
      const tempUpd = {...upd};
      upd.width = tempUpd.height;
      upd.height = tempUpd.width;
      upd.x = tempUpd.y;
      upd.y = tempUpd.x;
    }

    unity.materialModule.OnUpdateRotationReceiver({
      ...upd,
      width: upd.width,
      height: upd.height,
      tilingOptionType,
    });

    update(tilingOptionType, upd);
  };

  const setFit = (tilingType) => {
    unity.materialModule.OnSetFitReceiver(tilingType);
  };

  const setLock = (tilingType, value) => {
    console.log("set lock ", tilingType, value);
    unity.materialModule.OnLockTilingReceiver(tilingType);
    dispatch(
      updateUnityObject({
        [TILING_NAME_MAP[tilingType]]: {
          lock: value,
        },
      })
    );
  };

  const setExpertMode = (val) => dispatch(setPropertiesExpertMode(val));

  const glossiness = {
    changeIntensity: unity.materialModule.OnUpdateRoughnessValueReceiver,
    invertMap: unity.materialModule.InvertRoughnessToggleReceiver,
    useCustomMap: unity.materialModule.UseCustomRoughnessMapToggleReceiver,
  }

  const normal = {
    changeIntensity: unity.materialModule.OnUpdateNormalValueReceiver,
    invertMap: unity.materialModule.InvertNormalToggleReceiver,
    useCustomMap: unity.materialModule.UseCustomNormalMapToggleReceiver,
    gereateFromDesign: unity.materialModule.UseGenerateNormalMapToggleReceiver,
  }

  const transparency = {
    changeIntensity: unity.materialModule.OnUpdateTransparencyValueReceiver,
    invertMap: unity.materialModule.InvertTransparencyToggleReceiver,
    useCustomMap: unity.materialModule.UseCustomTransparencyMapToggleReceiver,
    textureAlpha: unity.materialModule.SetTransparencyFromDesignReceiver,
  }

  const ao = {
    changeIntensity: unity.materialModule.OnUpdateAoValueReceiver,
    useCustomMap: unity.materialModule.UseCustomAOMapToggleReceiver,
  }

  const metallic = {
    changeIntensity: unity.materialModule.OnUpdateMetallicValueReceiver,
    useCustomMap: unity.materialModule.UseCustomMetallicMapToggleReceiver,
  }

  const emission = {
    changeIntensity: unity.materialModule.OnUpdateEmissionValueReceiver,
  }
  
  const updaters = {
    [TILING_TYPE.GLOSSY]: glossiness,
    [TILING_TYPE.DEPTH]: normal,
    [TILING_TYPE.TRANSPARENCY]: transparency,
    [TILING_TYPE.AO]: ao,
    [TILING_TYPE.METALLIC]: metallic,
    [TILING_TYPE.EMISSION]: emission,
  };

  const changeIntensity = (tilingOptionType, intensity) => {
    const label = TILING_TYPE_LABEL[tilingOptionType];
    const parsedObject = selectParsedObject(getAppState())
    if (tilingOptionType === TILING_TYPE.AO && !parsedObject.ao.customMap) {
      errorToast(`Please add a custom ${label} map`, {
        toastId: 'ADD_CUSTOM_MAP_AO_ERROR',
      })
      return;
    }

    if (tilingOptionType === TILING_TYPE.DEPTH && !parsedObject.normal.customMap) {
      errorToast(`Please add a custom ${label} map`, {
        toastId: 'ADD_CUSTOM_MAP_DEPTH_ERROR',
      })
      return;
    }

    updaters[tilingOptionType].changeIntensity(intensity);
    update(tilingOptionType, { intensity })
  };

  const toggleCustomMap = (tilingOptionType, active) => {
    console.log('sasha toggleCustomMap')
    updaters[tilingOptionType].useCustomMap(active);
    update(tilingOptionType, { 
      customMap: active,
      ...(tilingOptionType === TILING_TYPE.DEPTH && { gereateFromDesign: false, intensity: 0 }),
      ...(tilingOptionType === TILING_TYPE.AO && { intensity: 0 }),
      ...(tilingOptionType === TILING_TYPE.TRANSPARENCY && { textureAlpha: false }),
      ...(!active && { mapImage: null })
    })

    if (!active) {
      toggleSeparateTiling(tilingOptionType, false)

      const t = TILING_NAME_MAP[tilingOptionType]
      const idKey = CHANGED_MAP_PATHS_MAP[t];
      if (idKey) {
        dispatch(updateRawObject({
          changedMapsPath: {
            [idKey]: '',
          }
        }))
      }
    }
  };

  const getDataByTilingOptionType = (tilingOptionType) => {
    const objectInfo = selectUnitySelectedObjectInfo(getAppState());
    const key = TILING_NAME_MAP[tilingOptionType];
    return objectInfo.parsed?.[key];
  }

  const toggleInvertMap = (tilingOptionType) => {
    const data = getDataByTilingOptionType(tilingOptionType);
    const active =  !data.invertMap;
    updaters[tilingOptionType].invertMap(active);
    update(tilingOptionType, { invertMap: active })
  }

  const toggleSeparateTiling = (tilingOptionType, active) => {
    console.log('sasha toggleSeparateTiling')
    unity.materialModule.AddRemoveSeparateTilingOptionReceiver({ status: active, tilingOptionType });
    // redux data will be updated on unity callback also Mat37 -> ReceiveSeparateTilingProperties
    update(tilingOptionType, { useSeparateTiling: active })
  }

  const toggleGenerateFromDesign = (active) => {
    console.log('sasha toggleGenerateFromDesign')
    normal.gereateFromDesign(active)
    update(TILING_TYPE.DEPTH, { gereateFromDesign: active })
  }

  const toggleTextureAlpha = (active) => {
    console.log('sasha toggleTextureAlpha')
    transparency.textureAlpha(active)
    update(TILING_TYPE.TRANSPARENCY, { textureAlpha: active })
  }

  const toggleEffectLightColorBounce = (active) => {
    console.log('sasha toggleEffectLightColorBounce')
    unity.materialModule.OnEffectLightColorBounceEnableDisableReceiver(active);
    update(TILING_TYPE.OTHER, { effectLightColorBounce: active })
  }

  const toggleMapSelection = (tilingOptionType) => {
    dispatch(setSelectMapMode(tilingOptionType));
  }

  const cancelMapSelection = () => {
    dispatch(setSelectMapMode(null));
    dispatch(setActiveTab(TAB_NAME.PROPERTIES))
  }

  const applyCustomMap = (texture) => {
    if (isApplyTextureInProgress()) {
      errorToast('Please wait, texture or custom map is still in progress')
      return;
    }

    const mapType = TILING_TEXTURE_MAP[selectMapMode]
    const tilingTypeName = TILING_NAME_MAP[selectMapMode]

    console.log('sasha applyCustomMap', {
      tilingTypeName,
      texture,
    })

    dispatch(setTextureMap({
      tilingTypeName,
      texture,
      selectMapMode,
    }))
    toggleMapSelection(null);

    
    applyTexture({
      texture,
      mapType,
    })

    // toggleCustomMap(selectMapMode, true)
  }

  const handleWidthHeightChange = (tilingOptionType, { width, height }) => {
    changeSize({
      tilingOptionType,
      width,
      height,
    })
  }


  const modifyTiling = (tilingOptionType, name, value) => {

    switch (name) {
      case "x":
      case "y":
        return changePosition({ [name]: Number(value), tilingOptionType });
      case "width":
      case "height":
        return changeSize({ [name]: Number(value), tilingOptionType });
      case "intensity":
        return changeIntensity(tilingOptionType, Number(value));
      case 'invertMap': 
        return toggleInvertMap(tilingOptionType)
      case 'customMap': 
        if (value) {
          return toggleMapSelection(tilingOptionType);
        }
        return toggleCustomMap(tilingOptionType, value)
      case 'lock':
        return setLock(tilingOptionType, value);
      case 'useSeparateTiling': 
        return toggleSeparateTiling(tilingOptionType, value)
      case 'generateFromDesign': 
        return toggleGenerateFromDesign(value)
      case 'textureAlpha':
        return toggleTextureAlpha(value)
      case 'effectLightColorBounce': 
        return toggleEffectLightColorBounce(value);
    
      case "rotation":
        return changeRotation({ [name]: parseInt(value), tilingOptionType });

      default:
        throw new Error("Property change handler is missing");
    }
  };

  const onChange = (tilingOptionType) => (event) => {
    let { name, value, type, checked } = event.target;
    value = type === 'checkbox' ? checked : value;
    modifyTiling(tilingOptionType, name, value)
  }

  window.changeRotation = changeRotation;

  // {
  //   xSize: 0.5,
  //   ySize: 0.5,
  //   xPosition: 0.5,
  //   yPosition: 0.5,
  //   rotation: 90,
  //   tilingOptionType: 2,
  // }

  return {
    applyTexture,
    changePosition,
    changeRotation,
    setFit,
    setLock,
    changeSize,
    setExpertMode,
    onChange,
    reset,
    toggleMapSelection,
    applyCustomMap,
    cancelMapSelection,
    handleWidthHeightChange,
    modifyTiling,
  };
};

export const useProperties = () => {
  const { parsed, raw } = useSelector(selectUnitySelectedObjectInfo);

  let texture = useSelector(selectSelectedTexture);

  // if (properties?.textureId !== texture?.id) {
  //   texture = null
  // }

  return {
    objectName: raw?.objectName,
    properties: raw,
    propertiesData: parsed,
    textureThumbnail: texture?.thumbnail,
    selectedTextureId: texture?.id,
    texture,
  };
};

export const initialTextures = {} // map { [`objectName_mapType`]: [...args] }

export const useUnityEvents = () => {
  const dispatch = useDispatch();
  

  // TOOD: replace it with helper
  const update = (tilingType, data) => {
    dispatch(
      updateUnityObject({
        [TILING_NAME_MAP[tilingType]]: data,
      })
    );
  };

  useEffect(() => {
    async function ReceiveSelectedObjectInfo(data) {
      try {
        const t0 = performance.now();
        const raw = JSON.parse(data);
        console.log(`Call to doSomething JSON.parse ${performance.now() - t0} milliseconds.`);
        const parsed = await parseUnityObject(raw);
        console.log(`Call to doSomething parseUnityObject ${performance.now() - t0} milliseconds.`);
        console.log('sasha received object from unity ReceiveSelectedObjectInfo', raw)

        const d = {
          parsed,
          raw,
        };


        
        dispatch(setSelectedTextureId(parseInt(parsed.texture.materialId) || null))
        dispatch(setUnitySelectedObjectInfo(data ? d : null));

        const { colorValue } = raw;
        // let [r, g, b] = colorValue.split("|")
        // const color = rgbHex(parseInt(r), parseInt(g), parseInt(b));
        dispatch(setSelectedColor(colorValue))
        const t1 = performance.now();
        console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);

      } catch (error) {
        // TODO: error logger here
        dispatch(setUnitySelectedObjectInfo({}));
        dispatch(setSelectedTextureId(null))
        console.error("Unable to parse ReceiveSelectedObjectInfo data", error);
      } finally {
        if (selectApplyTextureInProgress(getAppState())) {
          dispatch(setApplyTextureInProgress(false))
        }
      }
    }

    window.ReceiveSelectedObjectInfo = ReceiveSelectedObjectInfo;


    window.ReceiveBase64String = (objectName, mapType, imageInfo, blobUrl) => {   
      const key = `${objectName}__${mapType}`;
      initialTextures[key] = [objectName, mapType, imageInfo, blobUrl] // store data, we will use it next time instead of calling unity as unity blocks thread when it generates images

      const tReceive = performance.now();
      console.log(`Call to doSomething OnCallBase64ImageDataReceiver -> ReceiveBase64String ${tReceive - window.tOnCallBase64ImageDataReceiver} milliseconds.`);

      console.log('sasha ReceiveBase64String', objectName, mapType, imageInfo, blobUrl)
      const t0 = performance.now();
      dispatch(
        setBase64Data({ objectName, mapType, image: blobUrl })
      );

      const t1 = performance.now();
        console.log(`Call to doSomething ReceiveBase64String ${t1 - t0} milliseconds.`);
    }

    window.ReceiveSeparateTilingProperties = (data) => {
      try {
        const { keyName, ...rest } = JSON.parse(data);
        const tilingType = REVERSE_TILING_MAP[keyName];
        console.log('sasha updateUnityObject before', tilingType, convertTilingtypeData(rest))
        update(tilingType, {
          ...convertTilingtypeData(rest),
          
        });
      } catch (error) {
        errorToast('There was an issue while updating separate tiling')
      }

    }

  }, []);
};

const convertTilingtypeData = (data) => ({
  width: unitySizeToInches(data.xMatTiling),
  height: unitySizeToInches(data.yMatTiling),
  x: data.xMatOffset,
  y: data.yMatOffset,
  rotation: data.rotation,
})

const parseUnityObject = async (data) => {
  const getTilingOption = (tilingType) => {
    return (
      data.tilingOptions.find((o) => o.tilingOptionType === tilingType)
    );
  };

  const getMaterialId = (idKey) => {
    return data.changedMapsPath[idKey];
  }

  const getMapImage = async (idKey) => {
    const id = data.changedMapsPath[idKey];
    if (!id) return;

    const t0 = performance.now();
    
    const texture = await getTexture(parseInt(id));


    const t1 = performance.now();
    console.log(`Call to doSomething took GET_TEXTURE ${t1 - t0} milliseconds.`);
    return texture?.file;
  }

  const getTilingProperties = (tilingType) => {
    const key = TILING_MAP[tilingType];

    if (!key || !data.tilingOffsetValue[key]) return {};

    return convertTilingtypeData(data.tilingOffsetValue[key]);
  };

  const getUst = (tilingType) => {
    const key = TILING_MAP[tilingType];
    if (!key || !data.tilingOffsetValue[key]) return false;
    return !data.materialTilingKeys?.includes(key);
  }

  const materialTilingType = REVERSE_TILING_MAP[data.materialTilingKeys[0]];
  const materialTilingOption = getTilingOption(TILING_TYPE.MATERIAL);
  const textureTilingOption  = getTilingOption(TILING_TYPE.TEXTURE);

  const [
    diffuseMapImage,
    roughnessMapImage,
    normalMapImage,
    transparencyMapImage,
    aoMapImage,
    metallicMapImage,
  ] = await Promise.all([
    getMapImage('diffuseId'),
    getMapImage('roughnessId'),
    getMapImage('normalId'),
    getMapImage('transparencyId'),
    getMapImage('AOId'),
    getMapImage('metallicId')
  ])

  return {
    isTexture: data.isTexture, // to check if  texture is applied or not on the object
    colorValue: data.colorValue,

    material: {
      hideUI: !materialTilingOption || !materialTilingType, // hide if material is missing
      tilingType: TILING_TYPE.MATERIAL,
      // width: unitySizeToInches(data.xMatTiling),
      // height: unitySizeToInches(data.yMatTiling),
      // x: data.xMatOffset,
      // y: data.yMatOffset,
      ...getTilingProperties(materialTilingType),
      lock: materialTilingOption?.isLocked || false,
    },
    texture: {
      hideUI: !textureTilingOption, // hide if material is missing
      // mapImage: data.changedMapsPath?.diffusePath,
      mapImage: diffuseMapImage,
      materialId: getMaterialId('diffuseId'),
      tilingType: TILING_TYPE.TEXTURE,
      ...getTilingProperties(TILING_TYPE.TEXTURE),
      lock: textureTilingOption?.isLocked  || false,
    },


    // check materialTilingKeys -> to know if "Use separate tiliing is enabled"
    glossiness: {
      // mapImage: data.changedMapsPath?.roughnessPath,
      mapImage: roughnessMapImage,
      materialId: getMaterialId('roughnessId'),
      tilingType: TILING_TYPE.GLOSSY,
      applicable: true,
      intensity: data.roughnessIntensity,
      invertMap: data.isRoughnessInvert,
      customMap: data.isCustomRoughnessMap,//  -> use custom map is enabled when it's true
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.GLOSSY),
      lock: getTilingOption(TILING_TYPE.GLOSSY)?.isLocked  || false,
      texture: null,
      // TODO: separateTiling????

      ...getTilingProperties(TILING_TYPE.GLOSSY),
    },

    normal: {
      // mapImage: data.changedMapsPath?.normalPath,
      mapImage: normalMapImage,
      materialId: getMaterialId('normalId'),
      tilingType: TILING_TYPE.DEPTH,
      applicable: true, // data.isNormal && data.normalIntensity > 0,
      intensity: data.normalIntensity,
      invertMap: data.isNormalInvert,
      customMap: data.isCustomNormalMap, // => 1111 if this is true -> normal map is applied on the object
      // gereateFromDesign:  -> isNormal && !isCustomNormalMap
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.DEPTH),
      lock: getTilingOption(TILING_TYPE.DEPTH)?.isLocked  || false,
      texture: null,
      // separateTiling: 
      // TODO: separateTiling????
      // TODO; generate from design??? -> isNormal === true && isCustomNormalMap === false
      // can not be customMap && generate from design at the same time -> it's radio button

      // use seaparate tiling should be disabled until custom map or generate from design isenabled

      ...getTilingProperties(TILING_TYPE.DEPTH),
    },

    transparency: {
      // mapImage: data.changedMapsPath?.transparencyPath,
      mapImage: transparencyMapImage,
      materialId: getMaterialId('transparencyId'),
      tilingType: TILING_TYPE.TRANSPARENCY,
      applicable: data.transparencyIntensity > 0,
      intensity: data.transparencyIntensity,
      invertMap: data.isTransparencyInvert,
      textureAlpha: data.isTransparencyFromAlbedo,
      customMap: data.isCustomTransparencyMap,
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.TRANSPARENCY),
      lock: getTilingOption(TILING_TYPE.TRANSPARENCY)?.isLocked  || false,
      texture: null,
      // TODO: separateTiling????

      ...getTilingProperties(TILING_TYPE.TRANSPARENCY),
    },

    ao: {
      // mapImage: data.changedMapsPath?.AOPath,
      mapImage: aoMapImage,
      materialId: getMaterialId('AOId'),
      tilingType: TILING_TYPE.AO,
      applicable: data.isAOMap && data.aoIntensity > 0,
      intensity: data.aoIntensity,
      customMap: data.isCustomAOMap,
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.AO),
      lock: getTilingOption(TILING_TYPE.AO)?.isLocked  || false,

      ...getTilingProperties(TILING_TYPE.AO),
    },

    metallic: {
      // mapImage: data.changedMapsPath?.metallicPath,
      mapImage: metallicMapImage,
      materialId: getMaterialId('metallicId'),
      tilingType: TILING_TYPE.METALLIC,
      applicable: data.metalIntensity > 0,
      intensity: data.aoIntensity,
      customMap: data.isCustomMetallicMap,
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.METALLIC),
      lock: getTilingOption(TILING_TYPE.METALLIC)?.isLocked  || false,

      ...getTilingProperties(TILING_TYPE.METALLIC),
    },

    emission: {
      tilingType: TILING_TYPE.EMISSION,
      intensity: data.emissionIntensity,
    },

    other: {
      tilingType: TILING_TYPE.OTHER,
      effectLightColorBounce: data.effectLightColorBounce,
    },
  };
};
