import { lazy, useContext, useState } from 'react';
import { UserContext } from './contexts';
import { Navigate, Route, Routes, useLocation, matchPath } from 'react-router-dom';
import _ from 'lodash';
import {
  // public login routes
  CUSTOMIZE_EXPERIENCE,
  FORGET_ROUTE,
  FORGET_ROUTE_SETTINGS,
  INVITE_ROUTE,
  LOGIN_HOME,
  OTP_VERIFY_ROUTE,
  LINK_VERIFY_ROUTE,
  RESET_PASSWORD_ROUTE,
  SIGN_IN_ROUTE,
  SIGNUP_ROUTE,
  TERMS_CONDITION_ROUTE,
  COMMON_HOME,
  // session only routes
  MY_PRODUCTS_ROUTE,
  ADMIN_CURATOR,
  ADMIN_CURATOR_LAYOUT,
  ADMIN_CURATOR_ROOM_ID,
  ADMIN_CURATOR_TYPE_ID,
  ADMIN_DASHBOARD,
  ADMIN_HOME,
  ADMIN_HOME_PATH,
  ADMIN_MY_PROJECTS,
  RENDERS_PREVIEWS_ROUTE,
  ADMIN_MY_PROPS,
  ADMIN_MY_ROOMS,
  ADMIN_SETTINGS,
  ADMIN_TEAMS,
  COLLECTIONS_ROUTE,
  SHARED_FILES_ROUTE,
  CALENDAR_ROUTE,
  COMMUNITY_ROUTE,
  TRASH_ROUTE,
  PLAY_TO_LEARN_ROUTE,
  REQUESTS_ROUTE,
  SUPPORT_ROUTE,
  MY_ACCOUNT_ROUTE,
  ADD_CREDITS_ROUTE,
  QUIT_ROUTE,
  SEARCH,
  RENDER_DETAILS,
  SEARCH_TEXT,
  COLLECTION_ID,
  ADMIN_MY_PRODUCTS_3DMODAL,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_ROOMS,
  ADMIN_MY_PRODUCTS_TEXTURE,
  ADMIN_MY_PRODUCTS_UPLOADS,
  ROOM_RENDER,
  PROJECT_RENDER,
  PRODUCT_RENDER,
  ADMIN_MY_ROOMS_PARAMS,
  TEMPLATES_ROUTE,
  NEW_PROJECTS_ROUTE,
  MY_PROJECTS_ROUTE,
  TEMPLATES_DETAILS,
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
} from './constants/routePathConstants';
import { LayoutWrapper } from './layouts';

import { NotFound, TermsAndPrivacyPolicy } from './screens';
import { navigate } from './helpers/projectHelper';

// public routes
const SignIn = lazy(() => import('./screens/Login'));
const SignUp = lazy(() => import('./screens/Register'));
const Forget = lazy(() => import('./screens/ForgotPassword'));
const CreatePassword = lazy(() => import('./screens/ResetPassword'));
const OtpVerify = lazy(() => import('./screens/Verify'));
const CustomizeExperience = lazy(() => import('./screens/CustomizeExperience'));
const InvitePage = lazy(() => import('./screens/Invitation'));
//const InvitePage = lazy(() => import('./pages/invitation'));

// admin routes
const AddCredits = lazy(() => import('./screens/AddCredits'));
const AdminDashboard = lazy(() => import('./screens/AdminDashboard'));
const AdminTeams = lazy(() => import('./screens/AdminTeams'));
const Calendar = lazy(() => import('./screens/Calendar'));
const Collection = lazy(() => import('./screens/Collection'));
const Collections = lazy(() => import('./screens/Collections'));
const Community = lazy(() => import('./screens/Community'));
const Curator = lazy(() => import('./screens/Curator'));
const MyAccount = lazy(() => import('./screens/MyAccount'));
const MyProducts = lazy(() => import('./screens/MyProducts'));
const MyProducts3dModel = lazy(() => import('./screens/MyProducts3dModel.js'));
const MyProductsRooms = lazy(() => import('./screens/MyProductsRooms.js'));
const MyProductsTextures = lazy(() => import('./screens/MyProductsTextures'));
const MyProductsTexturesEdit = lazy(() => import('./screens/MyProductsTexturesEdit'));
const MyProductsUploads = lazy(() => import('./screens/MyProductsUploads.js'));
const MyProductsWebView = lazy(() => import('./screens/MyProductsWebView.js'));
const MyProjects = lazy(() => import('./screens/MyProjects'));
const MyProps = lazy(() => import('./screens/MyProps'));
const MyRooms = lazy(() => import('./screens/MyRooms'));
const PlayToLearn = lazy(() => import('./screens/PlayToLearn'));
const ProductRender = lazy(() => import('./screens/ProductRender'));
const ProjectRender = lazy(() => import('./screens/ProjectRender'));
const Quit = lazy(() => import('./screens/Quit'));
const RenderDetails = lazy(() => import('./screens/RenderDetails'));
const RendersPreviews = lazy(() => import('./screens/RendersPreviews'));
const Requests = lazy(() => import('./screens/Requests'));
const RoomRender = lazy(() => import('./screens/RoomRender'));
const Search = lazy(() => import('./screens/Search'));
const Settings = lazy(() => import('./screens/Settings'));
const SharedFiles = lazy(() => import('./screens/SharedFiles'));
const Support = lazy(() => import('./screens/Support'));
const Trash = lazy(() => import('./screens/Trash'));
// const AppleMerchant = lazy(() => import('./screens/AppleMerchant'));
const RendersPreviewsDashboard = lazy(() => import('./screens/RendersPreviews'));
const TemplatesDetails = lazy(() => import('./screens/TemplatesDetails'));

//public routes
const LOGIN_ROUTES = [
  { path: LOGIN_HOME, component: SignIn, key: 'login' },
  { path: SIGN_IN_ROUTE, component: SignIn, key: 'sign-in' },
  { path: SIGNUP_ROUTE, component: SignUp, key: 'register' },
  { path: FORGET_ROUTE_SETTINGS, component: Forget, key: 'forget' }, // this one is slightly different so we can redirect back to MyDetails instead of Sign-in on success
  { path: FORGET_ROUTE, component: Forget, key: 'forget' },
  { path: RESET_PASSWORD_ROUTE, component: CreatePassword, key: 'reset' },
  { path: OTP_VERIFY_ROUTE, component: OtpVerify, key: 'otp-verify' },
  { path: LINK_VERIFY_ROUTE, component: OtpVerify, key: 'otp-verify' }, // click on link in email to verify route
  { path: CUSTOMIZE_EXPERIENCE, component: CustomizeExperience, key: 'customize-experience' },
  // { path: TERMS_CONDITION_ROUTE, component: TermsAndConditionsPage, key: 'terms-privacy' },
  { path: INVITE_ROUTE, component: InvitePage, key: 'invite' },
];

export const ADMIN_ROUTES = [
  { path: ADMIN_HOME, component: AdminDashboard, key: 'home' },
  { path: ADMIN_HOME_PATH, component: AdminDashboard, key: 'home_path' },
  { path: ADMIN_DASHBOARD, component: AdminDashboard, key: 'dashboard' },
  { path: MY_PRODUCTS_ROUTE, component: MyProducts, key: 'products' },
  { path: ADMIN_SETTINGS, component: Settings, key: 'settings' },
  { path: ADMIN_TEAMS, component: AdminTeams, key: 'teams' },
  // this path needs to be before Render because it is a special /project/createNew/:projectId so it catches the dynamic :projectId path first
  { path: NEW_PROJECTS_ROUTE, component: MyProjects, key: 'project' },
  // this path needs to be before MyProjects so it catches the dynamic :projectId path first
  { path: PROJECT_RENDER, component: ProjectRender, key: 'ProjectRender' },
  { path: ADMIN_MY_PROJECTS, component: MyProjects, key: 'project' },
  { path: ADMIN_MY_PRODUCTS_3DMODAL, component: MyProducts3dModel, key: 'products/3dmodel' },
  { path: ADMIN_MY_PRODUCTS_ROOMS, component: MyProductsRooms, key: 'products/rooms' },
  {
    path: ADMIN_MY_PRODUCTS_TEXTURE,
    component: MyProductsTextures,
    key: 'products/textures',
  },
  {
    path: ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
    component: MyProductsTexturesEdit,
    key: 'products/textures/edit',
  },
  {
    path: ADMIN_MY_PRODUCTS_UPLOADS,
    component: MyProductsUploads,
    key: 'products/uploads',
  },
  {
    path: ADMIN_MY_PRODUCTS_3DMODAL_EACH,
    component: MyProductsWebView,
    key: 'products/each-model',
  },
  { path: RENDERS_PREVIEWS_ROUTE, component: RendersPreviews, key: 'renders-previews' },
  { path: ADMIN_MY_PROPS, component: MyProps, key: 'list-members' },
  { path: ADMIN_MY_ROOMS, component: MyRooms, key: 'rooms' },
  { path: TEMPLATES_ROUTE, component: MyRooms, key: 'templates' },
  // {path: TEXTURES_ROUTE, component: Textures, key: 'textures'},
  { path: COLLECTIONS_ROUTE, component: Collections, key: 'collections' },
  { path: SHARED_FILES_ROUTE, component: SharedFiles, key: 'shared-files' },
  { path: CALENDAR_ROUTE, component: Calendar, key: 'calendar' },
  { path: COMMUNITY_ROUTE, component: Community, key: 'community' },
  { path: COLLECTION_ID, component: Collection, key: 'collectionsId' },
  { path: TRASH_ROUTE, component: Trash, key: 'trash' },
  { path: PLAY_TO_LEARN_ROUTE, component: PlayToLearn, key: 'play-to-learn' },
  { path: REQUESTS_ROUTE, component: Requests, key: 'requests' },
  { path: SUPPORT_ROUTE, component: Support, key: 'support' },
  { path: MY_ACCOUNT_ROUTE, component: MyAccount, key: 'my-account' },
  { path: QUIT_ROUTE, component: Quit, key: 'quit' },
  { path: SEARCH, component: Search, key: 'search' },
  { path: SEARCH_TEXT, component: Search, key: 'search_text' },
  { path: RENDERS_PREVIEWS_ROUTE, component: RendersPreviewsDashboard, key: 'search_text' },
  { path: RENDER_DETAILS, component: RenderDetails, key: 'RenderDetails' },
  { path: ROOM_RENDER, component: RoomRender, key: 'RoomRender' },
  { path: PRODUCT_RENDER, component: ProductRender, key: 'ProductRender' },
  { path: TEMPLATES_DETAILS, component: TemplatesDetails, key: 'TemplatesDetails' },

  { path: ADMIN_MY_ROOMS_PARAMS, component: MyRooms, key: 'rooms_param' },
];

export const NO_LAYOUT_ROUTES = [
  { path: ADMIN_CURATOR, component: Curator, key: 'curator' },
  { path: ADMIN_CURATOR_LAYOUT, component: Curator, key: 'curator_layout' },
  { path: ADMIN_CURATOR_ROOM_ID, component: Curator, key: 'curator_room' },
  { path: ADMIN_CURATOR_TYPE_ID, component: Curator, key: 'curator_type' },
  { path: FORGET_ROUTE, component: Forget, key: 'forget' },
  { path: ADD_CREDITS_ROUTE, component: AddCredits, key: 'add-credits' },
  { path: FORGET_ROUTE_SETTINGS, component: Forget, key: 'forget' }, // this one is slightly different so we can redirect back to MyDetails instead of Sign-in on success
];

export const SHOW_DRILLDOWN_MENU_ROUTES = [
  RENDERS_PREVIEWS_ROUTE,
  ADMIN_MY_PROPS,
  ADMIN_MY_ROOMS,
  ADMIN_MY_ROOMS_PARAMS,
  MY_PRODUCTS_ROUTE,
  ADMIN_MY_PROJECTS,
  MY_PROJECTS_ROUTE,
  COLLECTIONS_ROUTE,
  TEMPLATES_ROUTE,
  NEW_PROJECTS_ROUTE,
  ADMIN_MY_PRODUCTS_3DMODAL,
  ADMIN_MY_PRODUCTS_ROOMS,
  ADMIN_MY_PRODUCTS_TEXTURE,
  ADMIN_MY_PRODUCTS_UPLOADS,
  COLLECTION_ID,
];

export const FULLSCREEN_PRODUCT_VIEWS = [
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
];

export const PREVIEW_ROUTES = [
  RENDER_DETAILS,
  PRODUCT_RENDER,
  PROJECT_RENDER,
  TEMPLATES_DETAILS,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
];

const pathsRequiringLogin = _.chain(
    _.map(_.concat(NO_LAYOUT_ROUTES, ADMIN_ROUTES), (p) => p.path)
  )
  .concat(SHOW_DRILLDOWN_MENU_ROUTES, PREVIEW_ROUTES)
  .filter((p) => p.key !== 'forget')
  .value();

export function onAuthPathOnly(currentPathname) {
  return _.includes(pathsRequiringLogin, (rte) => {
    return matchPath({ path: rte }, currentPathname) !== null;
  })
}

export function Routing(props) {
  const userCtx = useContext(UserContext);
  const location = useLocation();
  const currentPathname = location?.pathname;

  const [isOnTermsAndConditions, setIsOnTermsAndConditions] = useState(true);

  const { authorized, token } = props;
  const routeMapFunc = ({
    exact = true,
    path = '',
    subRoutes = '',
    component: Component = null,
    ...a
  }) => {
    const navPath = subRoutes ? [path, `${path}/${subRoutes}`] : path;
    return <Route key={a.key} path={navPath} exact={exact} element={<Component />} />;
  };
  const userAccessConfirmed =
    userCtx.token && userCtx.refreshToken && userCtx.emailVerified && userCtx.currentUser;
  const displayInfoIcon =
    userAccessConfirmed &&
    _.some(
      _.concat(
        SHOW_DRILLDOWN_MENU_ROUTES,
        _.map(NO_LAYOUT_ROUTES, (r) => r.path)
      ),
      (rte) => {
        return matchPath({ path: rte }, currentPathname) !== null;
      }
    );
  const displayFullScreen =
    userAccessConfirmed &&
    _.some(FULLSCREEN_PRODUCT_VIEWS, (rte) => {
      return matchPath({ path: rte }, currentPathname) !== null;
    });

  if (onAuthPathOnly(currentPathname) &&
    !(
      userCtx.token &&
      userCtx.refreshToken &&
      userCtx.emailVerified &&
      userCtx.currentUser &&
      !userCtx.onInviteRoute
    )
  ) {
    navigate(SIGN_IN_ROUTE);
  }
  return userCtx.token &&
    userCtx.refreshToken &&
    userCtx.emailVerified &&
    userCtx.currentUser &&
    !userCtx.onInviteRoute ? (
    <>
      {(userCtx.latestTNCAccepted === false || userCtx.latestTNCAccepted === 'false') && (
        <TermsAndPrivacyPolicy
          isOnTermsAndConditions={isOnTermsAndConditions}
          setIsOnTermsAndConditions={setIsOnTermsAndConditions}
          isModalVersion={true}
        />
      )}
      <Routes>
        <Route
          element={
            <LayoutWrapper
              token={token}
              refreshToken={userCtx.refreshToken}
              isEmailVerified={userCtx.emailVerified}
              currentUser={userCtx.currentUser}
              displayInfoIcon={displayInfoIcon}
              fullScreen={displayFullScreen}
            />
          }>
          {ADMIN_ROUTES.map(routeMapFunc)}
          <Route path={COMMON_HOME} element={<AdminDashboard />} />
          <Route path={'*'} element={<NotFound />} />
        </Route>
        {NO_LAYOUT_ROUTES.map(routeMapFunc)}
      </Routes>
    </>
  ) : (
    <Routes>
      <Route
        element={
          <LayoutWrapper
            token={userCtx.token}
            refreshToken={userCtx.refreshToken}
            isEmailVerified={userCtx.emailVerified}
            currentUser={userCtx.currentUser}
            fullScreen={displayFullScreen}
          />
        }>
        {LOGIN_ROUTES.map(routeMapFunc)}
        <Route path={COMMON_HOME} element={<SignIn />} />
        <Route path={'*'} element={<Navigate to={LOGIN_HOME} replace={true} />} />
      </Route>
    </Routes>
  );
}