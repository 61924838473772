import { createSlice } from '@reduxjs/toolkit';

export const SHORTCUT_TYPE = {
  BASIC: 'Basic',
  VIEWPORT: 'Viewport',
  EDIT: 'Edit',
  TOOLS: 'Tools',
  SELECTION: 'Selection',
  VIEW: 'View',
};

const shortcutsModule = createSlice({
  name: 'shortcutsModule',
  initialState: {
    popupOpen: false,
    materialCopied: false,
    activeTab: SHORTCUT_TYPE.BASIC,

    highlightSelected: true,
    showDimensions: false,
    lightsVisibility: true,

    replacePropActive: false,
    replacePropWith: null,
  },
  reducers: {
    setMaterialCopied: (state, { payload }) => {
      state.materialCopied = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setPopupOpen: (state, { payload }) => {
      state.popupOpen = payload;
    },
    setHighlightSelected: (state, { payload }) => {
      state.highlightSelected = payload;
    },
    setShowDimensions: (state, { payload }) => {
      state.showDimensions = payload;
    },
    setLightsVisibility: (state, { payload }) => {
      state.lightsVisibility = payload;
    },
    setReplacePropActive: (state, { payload }) => {
      state.replacePropActive = payload;
    },
    setReplacePropWith: (state, { payload }) => {
      state.replacePropWith = payload;
    }
  },
});

export const {
  setMaterialCopied,
  setActiveTab,
  setPopupOpen,
  setHighlightSelected,
  setShowDimensions,
  setLightsVisibility,
  setReplacePropActive,
  setReplacePropWith,
} = shortcutsModule.actions;
export const shortcutsModuleActions = shortcutsModule.actions
export const shortcutsModuleSelector = (state) => state.shortcutsModule;
export const shortcutsModuleReducer = shortcutsModule.reducer;
export default shortcutsModuleReducer;

export const selectIsMaterialCopied = (state) => state.shortcutsModule.materialCopied;
export const selectActiveTab = (state) => state.shortcutsModule.activeTab;
export const selectPopupOpen = (state) => state.shortcutsModule.popupOpen;
export const selectHighlightSelected = (state) => state.shortcutsModule.highlightSelected;
export const selectShowDimensions = (state) => state.shortcutsModule.showDimensions;
export const selectLightsVisibility = (state) => state.shortcutsModule.lightsVisibility;
export const selectReplacePropActive = (state) => state.shortcutsModule.replacePropActive;
export const selectReplacePropWith = (state) => state.shortcutsModule.replacePropWith;