/* eslint-disable no-debugger */
import { updateDbProp } from "../../../../helpers/idb";

export const simplifyPropData = async (prop) => {
  const propDbData = await updateDbProp(prop);
  const fileUrl = propDbData?.file || prop?.product_file;

  return {
    id: Number(prop.id),
    propName: prop.name,
    locationUrl: fileUrl,
    main_asset_name: prop.main_asset_name,
    blender_id: prop.blender_id,
    ...(prop.is_metadata && prop.product_meta_info
      ? { preDefinedMetadata: prop.product_meta_info }
      : {}),
  };
};