const FileUploadIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0062 19.1998H9.98757C6.35074 19.1998 4.79688 17.6459 4.79688 14.0091V9.9905C4.79688 6.35367 6.35074 4.7998 9.98757 4.7998H13.3364C13.453 4.7998 13.5611 4.84082 13.6469 4.90904C13.6623 4.92085 13.6772 4.93356 13.6914 4.94715L19.0495 10.3053C19.0877 10.3434 19.1189 10.3874 19.1426 10.4351C19.1772 10.5031 19.1969 10.5797 19.1969 10.6603V14.0091C19.1969 17.6459 17.643 19.1998 14.0062 19.1998ZM18.1922 11.1626V14.0091C18.1922 17.0967 17.0938 18.1952 14.0062 18.1952H9.98757C6.89994 18.1952 5.80153 17.0967 5.80153 14.0091V9.9905C5.80153 6.90287 6.89994 5.80446 9.98757 5.80446H12.8341V7.98119C12.8341 10.2718 13.7249 11.1626 16.0155 11.1626H18.1922ZM9.48521 15.3486C9.48521 15.6232 9.71293 15.851 9.98753 15.851C10.2621 15.851 10.4899 15.6232 10.4899 15.3486V12.5423L10.9721 13.0245C11.0726 13.125 11.1998 13.1719 11.3271 13.1719C11.4543 13.1719 11.5816 13.125 11.682 13.0245C11.8763 12.8303 11.8763 12.5088 11.682 12.3146L10.3666 10.9991C10.319 10.9443 10.2592 10.8999 10.1899 10.8712C10.1834 10.8683 10.1768 10.8657 10.1702 10.8631C10.066 10.8216 9.95222 10.8187 9.84811 10.8487C9.76885 10.8712 9.69428 10.9133 9.63256 10.975L9.62917 10.9784L8.29302 12.3146C8.09879 12.5088 8.09879 12.8303 8.29302 13.0245C8.48726 13.2188 8.80874 13.2188 9.00298 13.0245L9.48521 12.5423V15.3486ZM13.8387 7.98119V6.5144L17.4823 10.1579H16.0155C14.2875 10.1579 13.8387 9.70919 13.8387 7.98119Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FileUploadIcon;
