const PlusIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2008 18.3998C11.2008 18.8371 11.5634 19.1998 12.0008 19.1998C12.4381 19.1998 12.8008 18.8371 12.8008 18.3998V12.7998H18.4008C18.8381 12.7998 19.2008 12.4371 19.2008 11.9998C19.2008 11.5625 18.8381 11.1998 18.4008 11.1998H12.8008V5.5998C12.8008 5.16247 12.4381 4.7998 12.0008 4.7998C11.5634 4.7998 11.2008 5.16247 11.2008 5.5998V11.1998H5.60078C5.16345 11.1998 4.80078 11.5625 4.80078 11.9998C4.80078 12.4371 5.16345 12.7998 5.60078 12.7998H11.2008V18.3998Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusIcon;
