import Select from 'react-select';
import makeAnimated from 'react-select/animated';

// TODO add the sub options under the line in FIGMA,
// like Archived Filter on Renders & Previews
export default function StyledSelect(props) {
  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder || 'Select'}
      classNamePrefix="lfSelect"
      makeAnimated={makeAnimated}
      components={{
        IndicatorSeparator: () => null,
      }}
      isMulti={props.isMulti}
      isDisabled={props.disabled}
  />
  );
}
