import { CURATOR_MENU_SECTION } from "../../redux/slicers/admin/curatorMenuPreferencesSlicer";

export const MAX_RESOLUTION = 5000;
export const MIN_RESOLUTION = 1;

export const TEXTURE_MAP = {
  DIFFUSE:            0,  // for Select texture from gallery
  NORMAL:             1,  // for custom normal map
  ROUGHNESS:          2,  // for custom roughness map
  AMBIENTOCCLUSION:   3,  // for custom ocullsion map
  TRANSPARENCY:       4 , // for custom transparency map
  METALLIC:           5,  // for custom metallic map
}

export const TILING_TYPE = {
  TEXTURE: 0, // for texture tiling  
  MATERIAL: 1, 
  DEPTH: 2, // for normal tiling
  GLOSSY: 3, // for Glossy tiling
  TRANSPARENCY: 4, // for Transparency tiling
  AO: 5,
  METALLIC: 6,



  EMISSION: 999, // TODO: what is the real number of emission???
  OTHER: 1000,
}

export const TILING_TYPE_LABEL = {
  [TILING_TYPE.TEXTURE]: 'texture',
  [TILING_TYPE.MATERIAL]: 'material',
  [TILING_TYPE.DEPTH]: 'normal',
  [TILING_TYPE.GLOSSY]: 'glossy',
  [TILING_TYPE.TRANSPARENCY]: 'transparency',
  [TILING_TYPE.AO]: 'ao',
  [TILING_TYPE.METALLIC]: 'metallic',
}

export const CHANGED_MAP_PATHS_MAP = {
  texture: 'diffuseId',
  glossiness: 'roughnessId',
  normal: 'normalId',
  transparency: 'transparencyId',
  ao: 'AOId',
  metallic: "metallicId",
}

export const DIMENSION = {
  _2D: '2D',
  _3D: '3D',
}

export const UNITY_2D_VIEW_MODE = {
  TOP: 0,
  LEFT: 1,
  RIGHT: 2,
  FRONT: 3,
  BACK: 4,
}

export const CURSOR_MODE = {
  MOVE: 'MOVE',
  ROTATE: 'ROTATE',
  SCALE: 'SCALE',
  STYLE_ONLY: 'STYLE_ONLY',
}

export const TRANSFORM_SPACE_MODE = {
  GLOBAL: 0,
  LOCAL: 1,
}

export const AXIS = {
  X: 'X',
  Y: 'Y',
  Z: 'Z',
  ALL: 'All',
}

export const ROTATE_MODE = {
  GLOBAL: 'GLOBAL',
  X: 'X',
  Y: 'Y',
  Z: 'Z',
}

export const DECIMAL_NUM_COUNT = 2;

export const TILING_TEXTURE_MAP = {
  [TILING_TYPE.DEPTH]: TEXTURE_MAP.NORMAL,
  [TILING_TYPE.GLOSSY]: TEXTURE_MAP.ROUGHNESS,
  [TILING_TYPE.TRANSPARENCY]: TEXTURE_MAP.TRANSPARENCY,
  [TILING_TYPE.METALLIC]: TEXTURE_MAP.METALLIC,
  [TILING_TYPE.AO]: TEXTURE_MAP.AMBIENTOCCLUSION,
}

export const TILING_MAP = {
  [TILING_TYPE.TEXTURE]: "_MainTex",
  [TILING_TYPE.GLOSSY]: "_SmoothnessMap",
  [TILING_TYPE.DEPTH]: "_BumpMap",
  [TILING_TYPE.TRANSPARENCY]: "_AlphaTexture",
  [TILING_TYPE.AO]: "_OcclusionMap",
  [TILING_TYPE.METALLIC]: "_MetallicGlossMap",
};

export const TILING_NAME_MAP = {
  [TILING_TYPE.TEXTURE]: "texture",
  [TILING_TYPE.MATERIAL]: "material",
  [TILING_TYPE.DEPTH]: "normal",
  [TILING_TYPE.GLOSSY]: "glossiness",
  [TILING_TYPE.TRANSPARENCY]: "transparency",
  [TILING_TYPE.AO]: "ao",
  [TILING_TYPE.METALLIC]: "metallic",
  [TILING_TYPE.EMISSION]: "emission",
  [TILING_TYPE.OTHER]: "other",
}

export const REVERSE_TILING_MAP = Object.entries(TILING_MAP).reduce((acc, [key, value]) => {
  acc[value] = key
  return acc
}, {})

export const PROPERTY_MAP = {
  texture: "_MainTex",
  glossiness: "_SmoothnessMap",
  normal: "_BumpMap",
  transparency: "_AlphaTexture",
  ao: "_OcclusionMap",
  metallic: "_MetallicGlossMap",
};

export const REVERSE_PROPERTY_MAP = Object.entries(PROPERTY_MAP).reduce((acc, [key, value]) => {
  acc[value] = key
  return acc
}, {})


export const SELECTED_OBJECT_COUNT_STR = {
  NONE: 'NoneObject',
  SINGLE: 'SingleObject',
  MULTIPLE: 'MultipleObject',
}

export const UNITY_OBJECT_TYPE = {
  PROP: 'Prop',
  LIGHT: 'Light',
}

export const TEMP_CAMERA_ID_PREFIX = 'temp_camera_';

export const RP_TYPE = {
  RENDER: 'RENDER',
  PREVIEW: 'PREVIEW',
};

export const DEFAULT_CAMERA_BLUR_INTENSITY = 5; // when we select object this blur intensity is applied by default in unity so we need to reset camera blur intensity to this value

export const HIDDEN_SECTIONS = [CURATOR_MENU_SECTION.MORE, CURATOR_MENU_SECTION.AUTOMATION];