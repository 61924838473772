// this is thumbnail component for product, room, projects, props, etc

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Card } from 'react-bootstrap';
import './_thumbnailCard.scss';
import { ADMIN_CURATOR } from '../../config/constant/routePathConstant';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  NOT_FAVOURITE_ICON,
  FAVOURITE_ICON,
  EDIT_ICON,
  SHARE_ICON,
  DELETE_ICON,
  ACTION_DOTS_ICON,
} from '../../config/constant/assetConstant';
import { Link } from 'react-router-dom';
import {
  addToFavouriteProject,
  projectDeleteApi,
  roomDeleteApi,
  addToFavouriteRoom,
} from '../../api/commonApi';
import { useDispatch, useSelector } from 'react-redux';
import { loadavg } from 'os';
import { paginationApi } from '../../api/globalapi';
import moment from 'moment';
import { setLocalStorage } from '../../helpers/jsHelper';

export default function ThumbnailCard({
  thumbnailData,
  path = null,
  last = {},
  arr = [],
  pathurl = null,
  isAllChecked,
  ischeckedd,
  setCheckedd,
  ...props
}) {
  const dispatch = useDispatch();
  const { id, thumbnail, name, tag, created_at, created_by, is_favourite } = thumbnailData;
  const [selectCard, setSelectCard] = useState(false);

  const [actionShow, setActionShow] = useState(false);
  const [showActions, setActionVisibility] = useState({ display: 'none' });

  const [isFavourite, setFavourite] = useState(false);
  const [favouriteImg, setFavouriteImg] = useState(NOT_FAVOURITE_ICON);

  const SelectAllCheck = (values) => {
    // if (values?.length === props?.data.results?.length) {
    //     props.setSelectAll(true)
    // } else {
    //     props.setSelectAll(false)
    // }
  };
  // work for the card selection
  const handleCardSelector = (id, section) => {
    if ('checkedData' in props) {
      // if (section === "onChange") {
      //     if (props?.checkedData.includes(id)) {
      //         const FilterData = props?.checkedData.filter((value) => value !== id);
      //         props?.setCheckedData(FilterData);
      //         SelectAllCheck(FilterData);
      //     } else {
      //         props?.setCheckedData([...props?.checkedData, id]);
      //         SelectAllCheck([...props?.checkedData, id]);
      //     }
      // } else if (section === "check") {
      //     if (props?.checkedData.includes(id)) {
      //         return true;
      //     } else {
      //         return false;
      //     }
      // }
    }
  };

  useEffect(() => {
    if (is_favourite === true) {
      setFavouriteImg(FAVOURITE_ICON);
      setFavourite(true);
    } else {
      setFavourite(false);
      setFavouriteImg(NOT_FAVOURITE_ICON);
    }
  }, []);

  // to show/hide the action bar
  const handleCardAction = (e) => {
    e.preventDefault();
    setActionShow(!actionShow);
    if (actionShow === false) {
      setActionVisibility({});
    } else {
      setActionVisibility({ display: 'none' });
    }
  };

  // favourite selector
  const handleFavouriteSelector = () => {
    const updatedFavourite = !isFavourite;
    setFavouriteImg(updatedFavourite ? FAVOURITE_ICON : NOT_FAVOURITE_ICON);
    switch (path) {
      case 'project':
        dispatch(addToFavouriteProject({ isFavourite: updatedFavourite, id }));
        break;
      case 'room':
        dispatch(addToFavouriteRoom({ isFavourite: updatedFavourite, id }));
        break;
      default:
        break;
    }
    setFavourite(!isFavourite);
  };

  // edit the card
  const handleCardEditAction = (id, path) => {
    switch (path) {
      case 'project':
        return `${ADMIN_CURATOR}/${path}/${id}?type=${path}`;
      case 'room':
        return `${ADMIN_CURATOR}/${path}/${id}?type=${path}`;
      case 'productroom':
        return `${ADMIN_CURATOR}/${path}/${id}?type=${path}`;
      // case "render":
      //     return `/render-details`
      // case "room":
      //     return `/products/rooms/preview`
      // case "myproject":
      //     return `projects/project-details`
      default:
        return '';
    }
  };
  // share the card
  const handleCardShareAction = (e) => {
    e.preventDefault();
  };

  // delete the card
  const handleCardDelAction = (e) => {
    e.preventDefault();
    if (path === 'rooms') {
      dispatch(roomDeleteApi(id));
    } else if (path === 'projects' || path === 'props') {
      dispatch(projectDeleteApi(id));
    }
  };
  const observer = useRef();
  const lastele = useCallback((node) => {
    let loading;
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        console.log('visible');
        let link = arr?.next_link;
        if (link) {
          dispatch(paginationApi(link, path, arr));
        }
      }
    });
    if (node) observer.current.observe(node);
  });

  const urlHandler = (id, path) => {
    switch (path) {
      case 'project':
        return `${ADMIN_CURATOR}/${path}/${id}?type=${path}`;
      case 'room':
        return `/templates/${id}`;
      case 'modal':
        return `/products/3dmodel/product`;
      case 'texture':
        return `/products/textures`;
      case 'productroom':
        return `/products/rooms/preview`;
      case 'render':
        return `/render-details`;
      default:
        return '';
    }
  };
  return (
    // <Col xs={12} sm={6} md={4} lg={3} className="mb-0">
    <Card
      className="thumbnail-card mb-0"
      id={`thumbnail-card-${id}`}
      ref={last.id === id ? lastele : null}>
      <Link
        to={urlHandler(id, path)}
        target={'_blank'}
        // onClick={() => {
        //     path === "room" || path === "project"
        //     ?
        //     setLocalStorage("activeProjectId", id)
        //     :
        //     setLocalStorage("activeCard", id)
        // }}
      >
        <div className="thumbnail-img">
          <div className="thumbnail-action">
            <ul className="action-list">
              <li>
                <input
                  type="checkbox"
                  name="selectCard"
                  id={`selectCard-${id}`}
                  className="selectCard"
                  onChange={handleCardSelector}
                />
                <label htmlFor={`selectCard-${id}`}></label>
              </li>
              <li>
                <input
                  type="checkbox"
                  name="favouriteCard"
                  id={`favouriteCard-${id}`}
                  className="favouriteCard"
                  onChange={handleFavouriteSelector}
                  checked={is_favourite}
                />
                <label htmlFor={`favouriteCard-${id}`}>
                  <img src={favouriteImg} width="13px" alt="favourite" />
                </label>
              </li>
            </ul>
          </div>
          <figure>
            <Card.Img
              variant="top"
              src={thumbnail || (thumbnailData?.images ? thumbnailData?.images[0]?.thumbnail : '')}
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>

        <Card.Body className="thumbnail-info render-status">
          <Card.Title className="inner-info">
            <h2 className="thumbnail-title">{name}</h2>
            <h5 className="thumbnail-etime">{moment(created_at).format('DD/MM/YY')}</h5>
          </Card.Title>
          <div className="inner-action">
            {/* <a className="card-action" href="#" onClick={handleCardAction}><span></span></a> */}
            <a className="card-action" href="#" onClick={handleCardAction}>
              <img src={ACTION_DOTS_ICON} />
            </a>
            <ul className="action-list ced-action" style={showActions}>
              <li>
                <Link to={handleCardEditAction(id, path)} target={'_blank'}>
                  {/* <a href="javascript:void(0)" className="edit"> */}
                  <img src={EDIT_ICON} alt="edit" className="img-fluid" />
                  {/* </a> */}
                </Link>
              </li>
              <li>
                <a href="#" className="share" onClick={handleCardShareAction}>
                  <img src={SHARE_ICON} alt="share" className="img-fluid" />
                </a>
              </li>
              <li>
                <a href="#" className="delete" onClick={handleCardDelAction}>
                  <img src={DELETE_ICON} alt="delete" className="img-fluid" />
                </a>
              </li>
            </ul>
          </div>
        </Card.Body>
      </Link>
    </Card>
    // </Col>
  );
}
