import cx from 'classnames';
import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import styles from '../assets/scss/modules/_previewUI.module.scss';
import { ThumbnailCard } from './';
import ThumbnailContainer from './ThumbnailContainer';

export default function PreviewSidebar({
  theme,
  title,
  userName,
  updatedAt,
  cards,
  isFullscreen,
  className,
  classNameFullscreen,
  activeIndex,
  setActiveIndex,
  memberDetails,
}) {
  return (
    <div
      className={cx(
        styles.sidebar,
        className,
        isFullscreen && styles.fullscreen,
        isFullscreen && classNameFullscreen,
        styles[theme]
      )}>
      {/* Top block */}
      <div className={styles.sidebarTop}>
        {memberDetails ? (
          <img src={memberDetails.user?.profile_image} alt="Avatar" className={styles.avatar} />
        ) : null}

        <div className={styles.textContainer}>
          <div className={styles.textContainerTitle}>{title}</div>
          {memberDetails ? (
            <div className={styles.editedInfo}>
              {memberDetails.user?.name ? memberDetails.user?.name : memberDetails.user?.email}
              {' · '}
              <span className={styles.timeAgo}>
                {updatedAt && <ReactTimeAgo date={updatedAt} />}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {/* End Top Block */}
      <div className="px-3">
        <ThumbnailContainer data={cards} setActiveIndex={setActiveIndex} />
      </div>
    </div>
  );
}
