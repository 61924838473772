import { createSlice } from "@reduxjs/toolkit";

const infoDialog = createSlice({
  name: "infoDialog",
  initialState: {
      config: {
        show: false,
      },
  },
  reducers: {
    openInfoDialog: (state, { payload }) => {
      // pass props to the InfoDialog component
      const { onButtonClick, onClose, cancelButtonText,  onCancelClick, dispatch, ...rest } = payload;
      
      state.config = {
        onButtonClick: async () => {
          await onButtonClick?.();
          dispatch(closeInfoDialog());
        },
        onClose: () => {
          onClose?.();
          dispatch(closeInfoDialog());
        },
        onCancelClick: !cancelButtonText
          ? undefined
          : () => {
              onCancelClick?.();
              dispatch(closeInfoDialog());
            },
        cancelButtonText,
        ...rest,
        show: true,
      };
    },
    closeInfoDialog: (state) => {
      state.config.show = false;
    },
    reset: (state) => {
      state.config = {
        show: false,
      }
    }
  },
});

export const {
  closeInfoDialog,
  openInfoDialog,
  reset
} = infoDialog.actions;
export const infoDialogActions = infoDialog.actions;
export const infoDialogSelector = (state) => state.infoDialog;
export const infoDialogReducer = infoDialog.reducer;
export default infoDialogReducer;
