import axios from 'axios';
import React, { useEffect, useContext, useState, useMemo, memo } from 'react';
import {
  API_ROOT_URL,
  GET_PROJECT_API,
  GET_RENDER_PREVIEW_DATA,
} from '../../../../../config/constant/apiConstant';
import { AUTH_TOKEN } from '../../../../../config/constant/projectConstant';
import api from '../../../../../helpers/axiosHelper';
import { getLocalStorage } from '../../../../../helpers/jsHelper';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  renderPreviewExpand,
  rendersPreviewsExpSelector,
  setRenderPreviewExpand,
} from '../../../../../redux/slicers/renders-previews/rendersPreviewsSlicer';
import { useDispatch, useSelector } from 'react-redux';
import { errorToast } from '../../../../../helpers/toastHelper';
import { unitycontext } from '../../../../../hooks/useUnityContext';
import ThumbnailContainer from '../../../../container/ThumbnailContainer';
import { setCuratorPanel } from '../../../../../redux/slicers/utilities/utilitiesSlicer';
import {
  EDIT_ICON,
  SHARE_ICON,
  DELETE_ICON,
  NOT_FAVOURITE_ICON,
  FAVOURITE_ICON,
  ACTION_DOTS_ICON,
} from '../../../../../config/constant/assetConstant';
import { Card } from 'react-bootstrap';
import '../../../../../components/card/_thumbnailCard.scss';
import {
  addToFavouriteProject,
  addToFavouriteRenderPreview,
  deleteRenderPreviewAPI,
} from '../../../../../api/commonApi';
import {
  getRenderPreviewAPI,
  getRenderPreviewListApi,
} from '../../../../../api/renders-previews/renders-previews';
import { Pagination } from '../../../../Pagination/Pagination';
import InfiniteScroll from '../../../../InfiniteScroll/InfiniteScroll';
import { useSimpleDataList } from '../../../../../hooks/useDataList';
import {
  paginatedRenderPreviewsActions,
  paginatedRenderPreviewsSelector,
} from '../../../../../redux/slicers/admin/paginatedRenderPreviewsSlicer';
import { CuratorGrid } from '../../CuratorGrid/CuratorGrid';
import { ThumbnailCardRenderPreviews } from '../../../../../common/ThumbnailCardRenderPreviews';
import { CuratorSidebarLayout } from '../../CuratorSidebarLayout/CuratorSidebarLayout';
import { CURATOR_MENU_SECTION } from '../../../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import { useCuratorData } from '../../../../../hooks/useCuratorData';

const TAB_NAME = {
  PREVIEWS: '1',
  RENDERS: '2',
};

const Images = memo((props) => {
  const [tab, setTab] = useState(TAB_NAME.PREVIEWS);
  const { type, roomId } = useCuratorData();

  const requestParams = useMemo(
    () => ({
      is_preview: tab === TAB_NAME.PREVIEWS,
      is_main_render: tab === TAB_NAME.RENDERS,
      [type]: roomId,
    }),
    [tab, type, roomId]
  );

  let {
    list: renderPreviewsList,
    fetchMoreData,
    hasMore,
    loading,
    hasData,
    dataLoadedOnce,
  } = useSimpleDataList({
    getAction: getRenderPreviewListApi,
    resetAction: paginatedRenderPreviewsActions.fullResetList,
    selector: paginatedRenderPreviewsSelector,
    requestParams,
    skip: !roomId,
  });

  return (
    <>
      <CuratorSidebarLayout.FixedHeader>
        <Pagination fullWidth className="mb-0">
          <Pagination.Item
            active={tab === TAB_NAME.PREVIEWS}
            onClick={() => setTab(TAB_NAME.PREVIEWS)}>
            Previews
          </Pagination.Item>
          <Pagination.Item
            active={tab === TAB_NAME.RENDERS}
            onClick={() => setTab(TAB_NAME.RENDERS)}>
            Renders
          </Pagination.Item>
        </Pagination>
      </CuratorSidebarLayout.FixedHeader>

      {(!dataLoadedOnce || (dataLoadedOnce && hasData)) && (
        <InfiniteScroll
          dataLength={renderPreviewsList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loading={loading}
          // containerClassName={styles.scrollableContent}
          hasChildren={Boolean(renderPreviewsList.length)}
          scrollableTarget={`CuratorSidebarLayoutScrollableContainer_${CURATOR_MENU_SECTION.RENDERS_AND_PREVIEWS}`}>
          <CuratorGrid>
            {renderPreviewsList.map((renderPreview) => {
              return <ThumbnailCardRenderPreviews data={renderPreview} key={renderPreview.id} size="small" />;
            })}
          </CuratorGrid>
        </InfiniteScroll>
      )}

      {dataLoadedOnce && !hasData && (
        <>{tab === TAB_NAME.PREVIEWS ? 'No previews produced yet' : 'No renders produced yet'}</>
      )}
    </>
  );
})

export default Images;
