import { useEffect } from 'react';
import { ComingSoon } from '../common';
import {
  setTitle
} from '../helpers/jsHelper';
let title = 'Support';

export default function Support() {
  useEffect(() => {
    setTitle(title);
  }, []);
  
  return <ComingSoon name={title} />;
}
