import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useUnityContext } from '../../container/unityContainer';
import { useUnityLight } from './components/lights/useUnityLight';
import { useUnityRedux } from './stylesSection/useUnityRedux';
import { undoRedo } from './UndoRedo/UndoRedo';

export const UndoRedoContext = createContext({});

export const UndoRedoContextProvider = (props) => {
  const unityContext = useUnityContext();
  const unityLight = useUnityLight();
  const undoRedoRef = useRef(undoRedo);
  const unityRedux = useUnityRedux();

  useEffect(() => {
    undoRedoRef.current.setUnityContext(unityContext);
    undoRedoRef.current.setUnityLight(unityLight);
    undoRedoRef.current.setUnityRedux(unityRedux);
    undoRedoRef.current.init();
  }, [])  

  return (
    <UndoRedoContext.Provider
      value={undoRedoRef.current}
    >
      {props.children}
    </UndoRedoContext.Provider>
  );
};

export const useUndoRedo = () => useContext(UndoRedoContext);

