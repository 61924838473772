/* eslint-disable no-debugger */
import PubSub from 'pubsub-js';
import {
  apiCheckTexture,
  apiGetTexture,
  apiGetTextures,
  apiUploadTexture,
} from '../../../../helpers/api';
import { updateDbTexture } from '../../../../helpers/idb';
import { dispatcher } from '../../../../helpers/projectHelper';
import { AppEvent } from '../../../../helpers/unityPubSub';
import {
  setImportProjectTexturesConflictList,
  setTextureVersionSelectorDialogOpen,
} from '../../../../redux/slicers/admin/curatorSlicer';

export const parseArchiveProjectTextures = async ({ entries, projectJson, projectJsonVersion, textureVersionsMap }) => {
  // get version from materail data and use that versin to generate unique id => 153_V${version} -> 153_V5, 153_V4, 153_V233
  // use this id to upload item or check if it exists


  // debugger;
  const isV1 = projectJsonVersion === 1;

  const imageEntries = Object.entries(entries)
    .map(([path, entry]) => {
      return { path, entry };
    })
    .filter((o) => {
      if (isV1) {
        return o.path.endsWith('.jpeg') || o.path.endsWith('.jpg') || o.path.endsWith('.png');
      }

      return o.path.includes('/textures/');
    });


  const getIdFromPath = (path) => {
    const pathArray = path.split('/');
    const fileName = pathArray[pathArray.length - 1];

    if (isV1) {
      const id = `${fileName}_${projectJson.datetime}_${projectJson.file_name}`;
      return id;
    } else {
      // TODO: return it from path
      // filename for v2 -> '${id}.jpeg' etc
      const pathArr = path.split('/');
      const texturesIdx = pathArr.findIndex(p => p === 'textures');
      const idIndex = texturesIdx + 1;
      
      // const id = parseInt(fileName.split('.')[0])
      const id = pathArr[idIndex];
      return id;
    }
  };

  const getVersionedId = (path) => {
    const id = getIdFromPath(path);
    if (isV1) {
      return `${id}_V1`;
    } else {
      const version = textureVersionsMap[id];
      return `${id}_V${version}`;
    }
  }

  const uploadTexture = async ({ oldId, id, entry, name }) => {
    const uploadPayload = {
      old_texture_id: oldId,
      name: isV1 ? id : name, // we don't hae name for v1
      version: isV1 ? 1 : textureVersionsMap[id],
      texture_file: await entry.blob(),
    };
    const uploadResponse = await apiUploadTexture(uploadPayload);
    return uploadResponse.data;
  };

  // get textures
  let textureList = await Promise.all(
    imageEntries.map(async ({ path, entry }) => {
      const id = getIdFromPath(path);

      // zip files on mac OS duplicate entries
      // for duplicated entries id will not be generated correctly so we can skip it
      if (!id) return; 

      const query = isV1 ? `old_texture_id=${id}` : `id=${id}`;
      const textures = await apiGetTextures(query);
      let texture = textures.results?.[0];

      // debugger;
      console.log('saha texture', texture);

      if (!texture && !isV1) {
        // for v2 texture might be deleted, so it's might be missing in db
        const textures = await apiGetTextures(`old_texture_id=${getVersionedId(path)}`);
        texture = textures.results?.[0];
      }

      if (!texture) {
        texture = await uploadTexture({
          oldId: isV1 ? id : getVersionedId(path),
          id,
          entry,
          name: `${Date.now()}__${id}`, // TODO: this should be unique
        });
      }

      return { path, texture, entry };
    })
  );

  // remove empty items if there are any
  textureList = textureList.filter(Boolean)

  // check if user wants to use latest version or not
  const biggerVersionTextureList = textureList
    .filter(({ path, texture }) => {
      if (isV1) {
        return texture.version > 1;
      } else {
        return texture.version > textureVersionsMap[getIdFromPath(path)]
      }
    }) // TODO: compare version from json for v2 here
    .map(({ texture }) => texture);

  if (biggerVersionTextureList.length) {
    dispatcher(setTextureVersionSelectorDialogOpen(true));
    dispatcher(setImportProjectTexturesConflictList(biggerVersionTextureList));
    // wait for the user submits popup
    const latestVersionIds = await new Promise((resolve) => {
      PubSub.subscribeOnce(AppEvent.ResolveTextureConflicts, (_, ids) => {
        resolve(ids);
      });
    });

    textureList = await Promise.all(
      textureList.map(async ({ path, texture, entry }) => {
        if (latestVersionIds.includes(texture.id)) {
          // we already have latest version
          return { path, texture }
        }

        // handle ones with old version
        const version = isV1 ? 1 : textureVersionsMap[getIdFromPath(path)]
        const hasNewerVersion = texture.version > version

        if (!hasNewerVersion) {
          // we already fetched or uploaded it previously
          return { path, texture }
        }

        const id = getVersionedId(path);
        texture = await apiCheckTexture({ textureId: id, version });

        if (!texture) {
          texture = await uploadTexture({
            oldId: id, 
            id: getIdFromPath(path),
            entry,
            name: `${Date.now()}__${id}`,
          }); 
        }

        return { path, texture };
      })
    );
  }

  const dbTextureList = await Promise.all(
    textureList.map(async ({ path, texture }) => {
      if (isV1) {
        const dbTexture = await updateDbTexture(texture);
        return { path, texture: dbTexture };
      }

      return { path, texture }
    })
  );

  const imagesMap = dbTextureList.reduce((acc, { path, texture }) => {
    if (!texture) return acc;

    if (isV1) {
      acc[path] = texture;
    } else {
      acc[getIdFromPath(path)] = texture;
    }

    return acc;
  }, {});

  console.log('imagesMap', imagesMap);

  return imagesMap;
};
