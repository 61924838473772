import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Portal } from '../../../../../common/Portal';
import { curatorLightSelector } from '../../../../../redux/slicers/admin/curatorLightSlicer';
import { curatorSelector } from '../../../../../redux/slicers/admin/curatorSlicer';
import { ColorPicker } from '../../ColorPicker/ColorPicker';
import { CuratorSidebarLayout } from '../../CuratorSidebarLayout/CuratorSidebarLayout';
import { undoRedo } from '../../UndoRedo/UndoRedo';
import styles from './ColorPopup.module.scss';
import { PopupButtons } from './PopupButtons';
import { useUnityLight } from './useUnityLight';

export const ColorPopup = () => {
  const { closeColorPopup, updateLightColor } = useUnityLight();
  const { open, light, type } = useSelector((state) => curatorLightSelector(state).ligthColorPoup);
  const selectedLight = useSelector((state) =>
    curatorSelector(state).unityData.roomLightData?.find((l) => l.id === light?.id)
  );
  const lastColorRef = useRef(null)

  useEffect(() => {
    if (selectedLight) {
      // save color for undo redo
      lastColorRef.current = selectedLight.lightDetails[type].lightColor;
    }
  }, [selectedLight?.id])

  if (!open) return null;

  const onChange = (color) => {
    updateLightColor(light, type, color.hex);
  };

  const onChangeComplete = (color) => {
    if (lastColorRef.current === color.hex) return;

    undoRedo.light.updateLight({ 
      name: `lightDetails.${type}.lightColor`, 
      prevValue: lastColorRef.current, 
      nextValue: color.hex,
    })

    lastColorRef.current = color.hex;
  }

  const color = selectedLight?.lightDetails[type]?.lightColor;

  return (
    <Portal>
      <div className={styles.popup}>
        <div>
          <h2 className="mb-3">Select light color</h2>

          <ColorPicker color={color} onChange={onChange} onChangeComplete={onChangeComplete} />
        </div>

        <PopupButtons
          onCancel={() => closeColorPopup(light, type)}
          onSubmit={() => closeColorPopup()}
        />
      </div>
    </Portal>
  );
};
