import { useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { CommonGrid, TopContainer } from '../layouts';
import Switch from '../components/admin/switch/index';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  TopButtonArea,
  ThumbnailContainer,
  InfiniteScroll,
  ActionFilters,
  Image,
  SelectFormGroup,
  Input,
  RightSidebar,
  BigButton,
} from '../common';
import _ from 'lodash';
import { FilterContext, UserContext } from '../contexts';
import { getCommonMetadata, addValueLabelToList, constructQueryParams, changeFilter, setTitle } from '../helpers/jsHelper';
import { apiGetCachedList, apiErrorHandler } from '../helpers/axiosHelper';
import { FiMapPin, FiPhone } from 'react-icons/fi';
import { BsBriefcase } from 'react-icons/bs';
import { Country } from 'country-state-city';
import {
  apiTeamMembers,
  parseBasePathname,
  apiGetPermissions,
  apiGetUserDetails,
  apiGetProfessions,
} from '../helpers/api';
import { getScreenSettings } from '../helpers/configureHelper';
import { ReactLoader } from '../layouts/Loading';
import { icons } from '../assets';
import { EDIT_ICON, SHARE_ICON, DELETE_ICON, DOWNLOAD_ICON } from '../constants/assetConstants';
import { getCuratorPath } from '../helpers/urlHelper';
import { USER_ROLES } from '../constants/constants';
const { UserIcon, BinIcon } = icons;

let title = 'My Team';

export default function AdminTeams() {
  const location = useLocation();
  const pathname = parseBasePathname(location);
  let cardType = _.replace(pathname, '/', '');
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const currentUser = userCtx && userCtx.currentUser?.user;
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [dataReady, setDataReady] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [professions, setProfessions] = useState([]);

  const pageSettings = getScreenSettings(cardType);
  const { showTeamMemberFilter, showFavoriteIcon } = pageSettings;

  const countries = addValueLabelToList(Country.getAllCountries());

  useEffect(() => {
    setTitle(title);
  }, []);

  useEffect(async () => {
    if (!dataReady && !loading && currentUser) {
      setLoading(true);
      const getPermissions = apiGetPermissions().then((res) => {
        return { permissionsList: res?.results };
      });
      const getProfessions = apiGetProfessions().then((res) => {
        return { profList: res?.results };
      });
      Promise.all([getPermissions, getProfessions])
        .then((aggResults) => {
          const newStatus = {};
          aggResults.forEach((r) => Object.assign(newStatus, r));
          const { permissionsList, profList } = newStatus;
          if (permissionsList && permissionsList.length > 0) {
            setPermissions(addValueLabelToList(permissionsList));
          }
          if (profList && profList.length > 0) {
            setProfessions(addValueLabelToList(profList));
          }
        })
        .catch(apiErrorHandler)
        .finally(() => {
          setLoading(false);
          setDataReady(true);
          refreshList();
        });
    }
  }, [dataReady, loading, currentUser]);

  useEffect(() => {
    let params = constructQueryParams(
      sortField,
      filters,
      null,
      filterCtx?.globalFilters,
      filterCtx?.teamMembers
    );
    if (params !== query) {
      setQuery(params);
    }
  }, [sortField, filters, filterCtx?.globalFilters, filterCtx?.teamMembers]);

  useEffect(() => {
    refreshList();
  }, [query]);

  function refreshList() {
    if (!loading) {
      setLoading(true);
      apiTeamMembers(query)
        .then((res) => {
          if (res) {
            if (res.total_pages) {
              setTotalPages(res.total_pages);
            }
            if (res.current_page_number) {
              setPageNumber(res.current_page_number);
            }
            if (res.results) {
              let newList = addValueLabelToList(res.results);
              newList = _.map(newList, (item) => {
                if (item.user) {
                  let role = _.find(USER_ROLES, (rl) => rl.value === item.role);
                  item.thumbnail = item.user.profile_image;
                  item.name = item.user.name || item.user.email;
                  item.label = item.user.name || item.user.email;
                  item.subtitle = role?.label;
                  item.role = role;
                }
                return item;
              });
              // testing only
              // setEditItem(newList[0]);
              setList(newList);
            }
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    }
  }

  function handleSort({ value }) {
    setPageNumber(1);
    setSortField(value);
  }

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function handleFilter(field, selection) {
    setPageNumber(1);
    setFilters(changeFilter(filters, field, selection));
  }

  function onChange(field, value) {
    let item = { ...editItem };
    item[field] = value;
    setEditItem(item);
  }

  const onEdit = async (id) => {
    let index = _.findIndex(list, (p) => {
      return p.id === id;
    });
    let itemToEdit = list[index];
    let userInfo = await apiGetUserDetails();
    itemToEdit = _.merge(itemToEdit, userInfo);
    let profession_id = itemToEdit.profession ? itemToEdit.profession[0] : null;
    let profession = null;
    if (profession_id) {
      profession = _.find(professions, (rl) => rl.value === profession_id);
    }
    let country = _.find(countries, (rl) => rl.label === itemToEdit.country);
    itemToEdit.professionSelection = profession;
    itemToEdit.profession_label = profession?.label || '';
    setEditItem(itemToEdit);
  };

  function onToggle() {}

  function deleteUser() {}

  function saveUser() {}

  const { total, hasData, hasMore } = getCommonMetadata(list, pageNumber, totalPages);

  if (!filterCtx || !dataReady) return <ReactLoader />;

  return (
    <CommonGrid
      topContainer={
        <TopContainer
          title={<>My Team {total && <small>({total})</small>}</>}
          item={cardType}
          loading={loading || !filterCtx?.refListsReady}
          hasData={hasData}
          buttonArea={
            <TopButtonArea
              hasData={hasData}
              item={cardType}
              topButtonList={[]}
              addNewButtonArea={null}
              loading={!filterCtx?.refListsReady || loading}
            />
          }
        />
      }
      hasData={hasData}
      list={list}
      filterArea={
        <>
          <ActionFilters
            showTeamMemberFilter={showTeamMemberFilter}
            searchBar={null}
            hasData={hasData}
          />
        </>
      }>
      <InfiniteScroll
        dataLength={total}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={!filterCtx?.refListsReady || loading}
        hasChildren={hasData}
        scrollableTarget="layoutContentContainer">
        <ThumbnailContainer
          data={list}
          cardType={cardType}
          onEdit={onEdit}
          onClick={onEdit}
          showFavoriteIcon={showFavoriteIcon}
          cardButtonList={[]}
        />
      </InfiniteScroll>
      <RightSidebar
        initialShowOpen={!_.isNil(editItem)}
        onClose={() => setEditItem(null)}
        className='adminUserSidebar'
        title={
          <header className="header profileSidebar">
            <Row className="fullWidth">
              <Col xs="3">
                {editItem?.profile_image ? (
                  <Image
                    src={editItem.profile_image}
                    alt="Avatar"
                    className="profileSidebarImg  d-inline avatar"
                  />
                ) : (
                  <UserIcon className="profileSidebarImg d-inline" />
                )}
              </Col>
              <Col xs="9">
                <div className="headerInfo d-inline text-left">
                  {`${editItem ? `${editItem.first_name} ${editItem.last_name}` : ''}`}
                </div>
                <div className="d-inline text-left">
                  {editItem?.email && (
                    <>
                      <br />
                      <div className="fontSize85 fontLightWeight">{editItem.email} </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </header>
        }
        body={
          <div>
            <Form className="d-block">
              <Form.Group className="search-form-group">
                <Row>
                  <Col className="align-self-center">
                    <div className="me-2 d-inline">Active</div>
                    <Switch
                      checked={editItem?.is_active || 'false'}
                      onToggle={onToggle}
                      toggleId={'update_or_announcement_from_live_furnish_app'}
                    />
                  </Col>
                  <Col>
                    <BigButton
                      buttonIcon={BinIcon}
                      onClick={() => deleteUser(editItem?.id)}
                      buttonTitle={'Delete user'}
                      disabled={saving}
                      variant={saving ? 'outline-secondary' : 'outline-dark'}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <hr className="solid" />

              <h5 className="me-2">Additional Info</h5>
              <Row className="px-2">
                <Col xs="auto">
                  <BsBriefcase className="me-2 d-inline" />
                  {editItem?.profession_label}
                </Col>
                <Col xs="auto">
                  {/* <FiPhone className="me-2 d-inline" /> */}
                  
                  <PhoneInput
                    name="mobile_number"
                    placeholder="Type your contact number"
                    country={editItem?.country?.isoCode?.toLowerCase()}
                    value={editItem?.mobile_number}
                    // onChange={(contact) => handleContactInput(contact)}
                    // onBlur={() => setContactNumberMessage('')}
                    inputClass={'phoneInputReadOnly d-inline'}
                    disableDropdown
                  />
                </Col>
                <Col xs="auto">
                  <FiMapPin className="me-2 d-inline" />
                  {_.startCase(editItem?.country)}
                </Col>
              </Row>
              <h5 className="me-2 mt-5">Roles and Permissions</h5>
              <SelectFormGroup
                label="Role"
                id="role"
                name="role"
                onChangeCallback={(e) => onChange('role', e)}
                value={editItem?.role}
                options={USER_ROLES}
                selectLabel="role"
              />
              {/* <hr className="solid" />
              <Row>
                <Col>
                  <div className="float-end">
                    <Button
                      variant="secondary"
                      className="m-2"
                      onClick={() => {
                        setEditItem(null);
                        setSaving(false);
                      }}>
                      Cancel
                    </Button>
                    <Button onClick={saveUser} variant="primary" className="my-2" disabled={saving}>
                      Save
                    </Button>
                  </div>
                </Col>
              </Row> */}
            </Form>
          </div>
        }
      />
    </CommonGrid>
  );
}
