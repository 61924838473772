import { React, Col, Row, Container, Image, Navbar, Carousel, Card, Form } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setTitle, setLocalStorage, keyFromLocationState } from '../helpers/jsHelper';
import { PublicCarousel } from '../common';
import { LOGO } from '../constants/assetConstants';
import {
  apiGetCarouselImages,
  apiPostVerifyOtp,
  apiPostResendOtpEmail,
  apiGetVerifyEmailLink,
} from '../helpers/api';
import { BsExclamationTriangle } from 'react-icons/bs';
import { successToast } from '../helpers/toastHelper';
import { CUSTOMIZE_EXPERIENCE, ADMIN_DASHBOARD } from '../constants/routePathConstants';
import { AUTH_TOKEN, EMAIL_VERIFIED, LATEST_TNC_ACCEPTED, REFRESH_TOKEN } from '../constants/projectConstants';
import { UserContext } from '../contexts';
import _ from 'lodash';

export default function Verify() {
  const userCtx = useContext(UserContext);
  const { token } = useParams();    // token comes from email verification link (if user goes that route)

  useEffect(() => {
    if(token) {
      apiGetVerifyEmailLink(token).then((res) => {
        if (res) {
          let { status, data, message } = res;
          const { is_email_verified, access, refresh } = data;
          if(status === true) {
            successToast(message);
            setLocalStorage(AUTH_TOKEN, access);
            setLocalStorage(REFRESH_TOKEN, refresh);
            setLocalStorage(EMAIL_VERIFIED, true);
            userCtx.setToken(access);
            userCtx.setRefreshToken(refresh);
            userCtx.setEmailVerified(true);
            navigate(CUSTOMIZE_EXPERIENCE);
          }
  
        }
      });
    }
  }, [token])


  const location = useLocation();
  const navigate = useNavigate();

  const verificationToken = keyFromLocationState(location, 'verificationToken');
  const email = keyFromLocationState(location, 'email');

  const [carouselList, setCarouselList] = useState([]);
  const [otpStatus, setOtpStatus] = useState(true);


  useEffect(() => {
    setTitle('Login');
    apiGetCarouselImages({ name: 'furnish' }, null, 'Unable to get carousel images.').then((res) =>
      setCarouselList(res?.results)
    );
  }, []);

  // checks every 2 seconds to see if the email has been verified; if it has been, this returns the jwt token (this is probably what is causing refresh logs user in)
  // not sure if we actually need to use this. only concern is won't necessarily have verification token if don't directly come from registration page, so do not want to use this api call
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (verificationToken) {
  //       //dispatch(AccountStatusApi(verificationToken))
  //       const payload = {
  //         verification_token: verificationToken,
  //       };
  //       apiPostCheckAccountStatus(payload, true).then((res) => {
  //         if (res) {
  //           let { status, data, message } = res;
  //           let { is_email_verified } = data;
  //         }
  //       });
  //     }
  //   }, 2000);
  //   return () => clearInterval(interval);
  // }, []);


  // const checkCode = () => {
  //   let elements = document.getElementsByTagName('input');
  //   let i=0;
  //   setOtpStatus(true);
  //   while(i < elements.length) {
  //     if(elements[i].match(/[a-z]/i)) {
  //       setOtpStatus(false);
  //     }
  //   }
  // }


  // used to move focus to next input box
  const handleInput = (e) => {
    const { maxLength, value, name } = e.target;

    
    // checkCode()


    const [fieldName, fieldIndex] = name.split('-');

    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name=field-${fieldIntIndex + 1}]`);
        const prevfield = document.querySelector(`input[name=field-${fieldIntIndex - 1}]`);
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let otp_list = [];
    let elements = document.getElementsByTagName('input');
    let i = 0;
    while (i < elements.length) {
      otp_list.push(elements[i].value);
      i++;
    }
    otp_list = _.reject(otp_list, o => o.length < 1)
    if (otp_list.length < 6) {
      setOtpStatus(false)
      return;
    } else {
      setOtpStatus(true)
    }

    const payload = {
      email: email,
      otp: otp_list.join(''),
    };

    apiPostVerifyOtp(payload, true).then((res) => {
      if (res) {
        let { status, data, message } = res;
        const { is_email_verified, access, refresh, latest_tnc_accepted, info } = data;
        if(status === true) {
          successToast(message);
          setLocalStorage(AUTH_TOKEN, access);
          setLocalStorage(REFRESH_TOKEN, refresh);
          setLocalStorage(EMAIL_VERIFIED, true);
          setLocalStorage(LATEST_TNC_ACCEPTED, latest_tnc_accepted);
          userCtx.setToken(access);
          userCtx.setRefreshToken(refresh);
          userCtx.setEmailVerified(true);
          if(info) {
            userCtx.onLogin(info, access, refresh, is_email_verified, latest_tnc_accepted);
            navigate(ADMIN_DASHBOARD);
          } else {
            navigate(CUSTOMIZE_EXPERIENCE);
          }
        }

      }
    });
  };

  const handleResend = (e) => {
    e.preventDefault();
    const payload = {
      email: email,
    };

    apiPostResendOtpEmail(payload, true).then((res) => {
      if (res) {
        let { status, data, message } = res;
        if (status === true) {
          successToast(message);
          let { access, refresh } = data;
        }
      }
    });
  };

  return (

    <div className="vw-100">
      <Navbar expand="lg" className="bg-body">
        <Container>
          <div className="w-100 d-flex align-items-center justify-content-between mx-auto py-2">
            <Navbar.Brand>
              <Image src={LOGO} responsive="true" />
            </Navbar.Brand>
          </div>
        </Container>
      </Navbar>
      <div >
        <Container >
          <Row className="d-flex justify-content-center align-items-stretch pt-5">
            <Col >
              <PublicCarousel data={carouselList}/>
            </Col>
            <Col xs={5} >
              <Card className="py-5 px-4 h-100 border-0">
                <div className="text-center">
                  <h1 className="m-bottom-1 appH1">One last thing</h1>
                  <h5 className="m-bottom-2 py-0 fw-light appH3">
                    You&apos;ve successfully registered your account. Please check your email for the
                    activation link so can make full use of your account.
                  </h5>

                </div>
                <Form action="" className="mt-3" onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group className="col ps-5">
                      {otpStatus ? (
                        <span className="text-size-1">Type your code</span>
                      ) : (
                        <span className="title-error-color ml-2" style={{ marginLeft: '0.5rem' }}>
                          <BsExclamationTriangle
                            style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                          />
                          Invalid Code
                        </span>
                      )}
                  <div className="form-outline row mb-4 mt-2 mx-auto">
                    <input
                      type="text"
                      name="field-1"
                      id="form2Example28"
                      className={`col text-center form-control form-control-lg input-box px-2 py-3 mx-1 otp ${
                        otpStatus ? '' : 'input_error_style'
                      }`}
                      maxLength={1}
                      onInput={handleInput}
                      autoFocus={true}
                    />
                    <input
                      type="text"
                      name="field-2"
                      id="form2Example29"
                      className={`col text-center form-control form-control-lg input-box px-2 py-3 mx-1 otp ${
                        otpStatus ? '' : 'input_error_style'
                      }`}
                      maxLength={1}
                      onInput={handleInput}
                    />
                    <input
                      type="text"
                      name="field-3"
                      id="form2Example28"
                      className={`col text-center form-control form-control-lg input-box px-2 py-3 mx-1 otp ${
                        otpStatus ? '' : 'input_error_style'
                      }`}
                      maxLength={1}
                      onInput={handleInput}
                    />
                    <input
                      type="text"
                      name="field-4"
                      id="form2Example28"
                      className={`col text-center form-control form-control-lg input-box px-2 py-3 mx-1 otp ${
                        otpStatus ? '' : 'input_error_style'
                      }`}
                      maxLength={1}
                      onInput={handleInput}
                    />
                    <input
                      type="text"
                      name="field-5"
                      id="form2Example28"
                      className={`col text-center form-control form-control-lg input-box px-2 py-3 mx-1 otp ${
                        otpStatus ? '' : 'input_error_style'
                      }`}
                      maxLength={1}
                      onInput={handleInput}
                    />
                    <input
                      type="text"
                      name="field-6"
                      id="form2Example28"
                      className={`col text-center form-control form-control-lg input-box px-2 py-3 mx-1 otp ${
                        otpStatus ? '' : 'input_error_style'
                      }`}
                      maxLength={1}
                      onInput={handleInput}
                    />
                  </div>
                  <div className="mb-4">
                    <button
                      className="btn btn-action btn-md btn-block text-dark w-100"
                      type="submit"
                      style={{ backgroundColor: '#FFA200' }}>
                      <span className="btn-font">Continue</span>
                    </button>
                  </div>
                  <p className="text-size-2 text-center title-color">
                    {' '}
                    Didn&apos;t receive anything?{' '}
                    <a className="lf-link text-decoration-none cursorPointer" onClick={handleResend}>
                      Resend email
                    </a>
                  </p>
                </Form.Group>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>

  );
}













