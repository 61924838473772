import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  metadata: [],
  addMetadataObjectName: null,
}

const curatorMetadataSlicer = createSlice({
  name: "curatorMetadata",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setMetadata: (state, { payload }) => {
      state.metadata = payload;
    },
    addMetadata: (state, { payload }) => {
      state.metadata.push(payload)
    },
    setAddMetadataObjectName: (state, { payload }) => {
      state.addMetadataObjectName = payload;
    },
    deleteMetadata: (state, { payload }) => {
      state.metadata = state.metadata.filter(md => md.title !== payload.title);
    },
    toggleEditMetadata: (state, { payload }) => {
      state.metadata = state.metadata.map(md => {
        return md.title === payload.title
          ? { ...md, edit: !md.edit }
          : md;
      });
    },
    setMetadataStatus: (state, { payload }) => {
      state.metadata = state.metadata.map(md => {
        return md.title === payload.title
          ? { ...md, status: payload.status }
          : md
      })
    },
    updateMetadataTitle: (state, { payload }) => {
      state.metadata = state.metadata = state.metadata.map(md => {
        return md.title === payload.title
          ? { ...md, title: payload.newTitle }
          : md;
      });
    },
    changeMetadataOrder: (state, { payload }) => {
      const { title, destinationIndex } = payload
      const metadataItem = state.metadata.find(md => md.title === title);
      const reorderedBefore = destinationIndex < metadataItem.sequenceNo

      console.log('metadataList before update', destinationIndex)
      state.metadata = state.metadata.map(md => {
        if (md.title === title) {
          return { ...md, sequenceNo: destinationIndex }
        }

        if (reorderedBefore) {
          if (metadataItem.sequenceNo > md.sequenceNo && md.sequenceNo >= destinationIndex) {
            return { ...md, sequenceNo: md.sequenceNo + 1 }
          }
        } else {
          if (metadataItem.sequenceNo < md.sequenceNo && md.sequenceNo <= destinationIndex) {
            return { ...md, sequenceNo: md.sequenceNo - 1 }
          }
        }

        return md;
      })
    }
    
  },
});

export const {
  setMetadata,
  addMetadata,
  deleteMetadata,
  toggleEditMetadata,
  updateMetadataTitle,
  changeMetadataOrder,
  setMetadataStatus,
  setAddMetadataObjectName,
} = curatorMetadataSlicer.actions;
export const curatorMetadataActions = curatorMetadataSlicer.actions;
export const actions = curatorMetadataSlicer.actions;

export const curatorMetadataSelector = (state) => state.curatorMetadata;
export const curatorMetadataReducer = curatorMetadataSlicer.reducer;
export default curatorMetadataReducer;

export const selectAddMetadataObjectName = (state) => state.curatorMetadata.addMetadataObjectName;