export const ChatIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7746 18.5596C11.0818 18.967 11.516 19.2008 11.9768 19.2008C12.4377 19.2008 12.8718 18.967 13.1791 18.5596L14.1809 17.2238C14.2143 17.1837 14.2611 17.157 14.3145 17.157H14.6485C17.6006 17.157 19.1568 15.6008 19.1568 12.6486V9.30913C19.1568 6.357 17.6006 4.80078 14.6485 4.80078H9.30522C6.35309 4.80078 4.79688 6.357 4.79688 9.30913V12.6486C4.79688 16.4023 6.52007 17.157 9.30522 17.157H9.63918C9.67257 17.1637 9.75272 17.1971 9.77276 17.2238L10.7746 18.5596ZM5.79873 9.30913C5.79873 6.91804 6.91413 5.80264 9.30522 5.80264H14.6485C17.0395 5.80264 18.1549 6.91804 18.1549 9.30913V12.6486C18.1549 15.0397 17.0395 16.1551 14.6485 16.1551H14.3145C13.9472 16.1551 13.5998 16.3288 13.3794 16.6227L12.3776 17.9585C12.1438 18.2657 11.8099 18.2657 11.5761 17.9585L10.5742 16.6227C10.3672 16.3488 9.97981 16.1551 9.63918 16.1551H9.30522C6.83398 16.1551 5.79873 15.6676 5.79873 12.6486V9.30913ZM11.3089 11.3128C11.3089 11.6802 11.6028 11.9807 11.9768 11.9807C12.3509 11.9807 12.6447 11.6802 12.6447 11.3128C12.6447 10.9455 12.3442 10.6449 11.9768 10.6449C11.6095 10.6449 11.3089 10.9455 11.3089 11.3128ZM14.6485 11.9807C14.2744 11.9807 13.9805 11.6802 13.9805 11.3128C13.9805 10.9455 14.2811 10.6449 14.6485 10.6449C15.0158 10.6449 15.3164 10.9455 15.3164 11.3128C15.3164 11.6802 15.0225 11.9807 14.6485 11.9807ZM8.63732 11.3128C8.63732 11.6802 8.9312 11.9807 9.30522 11.9807C9.67925 11.9807 9.97313 11.6802 9.97313 11.3128C9.97313 10.9455 9.67257 10.6449 9.30522 10.6449C8.93788 10.6449 8.63732 10.9455 8.63732 11.3128Z"
        fill="currentColor"
      />
    </svg>
  );
};
