import cx from 'classnames';
import { useMemo } from 'react';
import { useSelector } from "react-redux";
import { UNITY_OBJECT_TYPE } from '../../../../config/constant/unityConstants';
import { selecteIsFilterOpen, selecteIsSidebarOpen } from '../../../../redux/slicers/admin/curatorSlicer';
import { selectSeclectedObjectList } from "../../../../redux/slicers/admin/curatorUnityObjectSlicer";
import styles from './SelectedPropName.module.scss';

export const SelectedPropName = () => {
  const selectedObjectList = useSelector(selectSeclectedObjectList);
  const sidebarOpen = useSelector(selecteIsSidebarOpen);
  const filterOpen = useSelector(selecteIsFilterOpen);

  const selectedObjectNames = useMemo(() => {
    return selectedObjectList.map(o => {
      return o.type === UNITY_OBJECT_TYPE.PROP || o.type === UNITY_OBJECT_TYPE.LIGHT
        ? o.name
        : undefined;
    }).filter(Boolean)
    .join(', ')
  }, [selectedObjectList])

  if (!selectedObjectNames) {
    return null;
  }

  return (
    <div className={cx(
        styles.root, 
        sidebarOpen && styles.sidebarOpen,
        filterOpen && styles.filterOpen,
      )}
    >
      {selectedObjectNames}
    </div>
  )
}