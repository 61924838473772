import cx from 'classnames';
import { NavDropdown } from 'react-bootstrap';
import { MoveIcon } from '../../../icons/MoveIcon';
import { ScaleIcon } from '../../../icons/ScaleIcon';
import { useSelector } from 'react-redux';
import {
  selectAxisAvailability,
  selectCuratorInitialized,
  selectCursorMode,
  selectSelectedAxis,
  selectTransformSpaceMode,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { Pagination } from '../../../Pagination/Pagination';
import {
  AXIS,
  CURSOR_MODE,
  TRANSFORM_SPACE_MODE,
} from '../../../../config/constant/unityConstants';
import { useUnityShortcuts } from './useUnityShortcuts';
import { LocationArrowIcon } from '../../../icons/LocationArrowIcon';
import { selectIsAnyObjectSelected, selectObjectPositionAlongAxis } from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { RefreshIcon2 } from '../../../icons/RefreshIcon2';
import styles from './NavigationMove.module.scss';
import { ObjectPositionAlongAxis } from './ObjectPositionAlongAxis';

export const NavigationMove = () => {
  const { changeCursorMode, toggleTransformSpaceMode, toggleSelectedAxis } = useUnityShortcuts();
  const anyObjectSelected = useSelector(selectIsAnyObjectSelected);
  const cursorMode = useSelector(selectCursorMode);
  const selectedAxis = useSelector(selectSelectedAxis);
  const transformSpaceMode = useSelector(selectTransformSpaceMode);
  const curatorInitialized = useSelector(selectCuratorInitialized);
  const axisAvailability = useSelector(selectAxisAvailability);
  // const rotateMode = useSelector(selectRotateMode);

  const handleCursorModeChange = (newCursorMode) => () => {
    changeCursorMode(newCursorMode);
  };

  const handleToggleTransformSpaceMode = () => {
    const newMode =
      transformSpaceMode === TRANSFORM_SPACE_MODE.LOCAL
        ? TRANSFORM_SPACE_MODE.GLOBAL
        : TRANSFORM_SPACE_MODE.LOCAL;
    toggleTransformSpaceMode(newMode);
  };

  const handleSelectAxis = (axis) => () => {
    // let newAxis = selectedAxis === axis ? AXIS.ALL : axis;
    toggleSelectedAxis(axis);
  };

  const showGlobalMode = cursorMode === CURSOR_MODE.MOVE || cursorMode === CURSOR_MODE.ROTATE;

  const menuConfig = {
    [CURSOR_MODE.MOVE]: {
      icon: MoveIcon,
      label: 'Move',
    },
    [CURSOR_MODE.ROTATE]: {
      icon: RefreshIcon2,
      label: 'Rotate',
    },
    [CURSOR_MODE.SCALE]: {
      icon: ScaleIcon,
      label: 'Scale',
    },
    [CURSOR_MODE.STYLE_ONLY]: {
      icon: LocationArrowIcon,
      label: 'Style Only',
    },
  };

  const selected = menuConfig[cursorMode];
  const Icon = selected.icon;
  const isStyleOnly = cursorMode === CURSOR_MODE.STYLE_ONLY;

  return (
    <>
      <NavDropdown
        className={cx(styles.moveDropdown, isStyleOnly && styles.dropdownStyleOnly)}
        title={
          <>
            <Icon className="me-1" />
            {selected.label}
          </>
        }
        disabled={!curatorInitialized}>
        <NavDropdown.Item
          onClick={handleCursorModeChange(CURSOR_MODE.MOVE)}
          active={cursorMode === CURSOR_MODE.MOVE}>
          <MoveIcon /> Move
        </NavDropdown.Item>
        <NavDropdown.Item
          onClick={handleCursorModeChange(CURSOR_MODE.ROTATE)}
          active={cursorMode === CURSOR_MODE.ROTATE}>
          {/* <RotateIcon /> Rotate */}
          <RefreshIcon2 /> Rotate
        </NavDropdown.Item>
        <NavDropdown.Item
          onClick={handleCursorModeChange(CURSOR_MODE.SCALE)}
          active={cursorMode === CURSOR_MODE.SCALE}>
          <ScaleIcon /> Scale
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={handleCursorModeChange(CURSOR_MODE.STYLE_ONLY)}
          active={cursorMode === CURSOR_MODE.STYLE_ONLY}
          className={styles.styleOnlyLink}
        >
          <LocationArrowIcon /> Style Only
        </NavDropdown.Item>
      </NavDropdown>
      <div className="divider mx-0"></div>

      {cursorMode !== CURSOR_MODE.STYLE_ONLY && anyObjectSelected && (
        <Pagination theme="dark" size="small" className="px-3 my-auto" withSpacing>
          {showGlobalMode && (
            <Pagination.Item
              active={transformSpaceMode === TRANSFORM_SPACE_MODE.GLOBAL}
              onClick={handleToggleTransformSpaceMode}>
              Global
            </Pagination.Item>
          )}

          {axisAvailability.X && (
            <Pagination.Item active={selectedAxis === AXIS.X} onClick={handleSelectAxis(AXIS.X)}>
              X
            </Pagination.Item>
          )}
          {axisAvailability.Y && (
            <Pagination.Item active={selectedAxis === AXIS.Y} onClick={handleSelectAxis(AXIS.Y)}>
              Y
            </Pagination.Item>
          )}
          {axisAvailability.Z && (
            <Pagination.Item active={selectedAxis === AXIS.Z} onClick={handleSelectAxis(AXIS.Z)}>
              Z
            </Pagination.Item>
          )}
        </Pagination>
      )}
      {anyObjectSelected && <ObjectPositionAlongAxis />}
    </>
  );
};
